<template>
  <section>
    <div v-if="loading" class="text-center"><i class="fas fa-spinner fa-spin fa-2x fa-fw"></i></div>
    <div v-else class="monitoring-plan" style="margin: 8px auto 60px; max-width: 1000px">
      <div style="position: relative;">
        <img :alt="structure.title" :src="structure.img || defaultBanner" style="width: 100%; max-height: 150px" @error="structure.img = defaultBanner">
        <h1 class="text-center white--text" style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; padding: 0 15px">{{ structure.title }}</h1>
        <div class="export-hidden display-flex align-center" style="position: absolute; left: 0; bottom:10px">
          <v-btn :title="exporting.error || `${ $t('NAV.EXPORT') } PDF`" icon class="my-0" @click="! exporting.inprogress && pdfExport()">
            <v-icon :color="exporting.error ? 'errorred' : 'white'" size="20">
              {{ exporting.inprogress ? 'fas fa-spinner fa-spin' : exporting.error ? 'fas fa-exclamation-triangle' : 'far fa-file-pdf' }}
            </v-icon>
          </v-btn>
          <a v-if="exporting.link" :href="exporting.link" class="exporting-link" target="_blank">Télécharger le pdf</a>
        </div>
        <div v-if="userIsAdmin" class="export-hidden" style="position: absolute; bottom: 5px; right: 5px">
          <v-btn to="/dashboards/monitoringplan/edit" rounded icon large class="my-0"><v-icon color="white" large>fas fa-cog</v-icon></v-btn>
        </div>
      </div>

      <div v-for="(block, index) in structure.blocks" :key="index" class="monitoring-plan-block white-bg" style="padding: 15px 30px 30px 50px;">
        <div v-for="article in block" :key="getUuid(article)" :style="{'margin-left': (article.level || 0) * 30 + 'px'}" class="block-article">
          <h3 v-if="article.type == 'title'">{{ article.value }}</h3>
          <div v-if="article.type == 'text'" style="margin: 8px 0" v-html="article.value"></div>
          <div v-if="article.type == 'planning'">
            <monitoring-plan-consult-planning :article="article" :plannings="plannings"
                                              @open-edit-lane="openEditLane(article.planning_id, $event.id)" @update-lane="updateLane($event)">
            </monitoring-plan-consult-planning>
          </div>
          <div v-if="article.type =='divider'" style="border-bottom: 1px solid lightgray; height: 1px; margin-bottom: 10px"></div>
        </div>
      </div>

      <modal v-if="editingMonitoringLane" @close="editingMonitoringLane = null">
        <monitoring-plan-lane-modal :monitoring-lane="editingMonitoringLane" @close="editingMonitoringLane = null" @update="saveEditingLane"></monitoring-plan-lane-modal>
      </modal>
    </div>
  </section>
</template>

<style>
  .monitoring-plan .card {
    border-radius: 0;
  }
  .monitoring-plan .modal .card {
    border-radius: 10px;
  }

  .block-article + .block-article {
    page-break-inside: avoid;
  }

  .exporting-link {
    color: white !important;
  }
  .monitoring-plan .title-underline {
    height: 2px;
    background: linear-gradient(to right, #0169aa 0%,#7db9e8 100%);
    margin-bottom: 4px;
  }
</style>

<script>
  import MonitoringPlanConsultPlanning from './MonitoringPlanConsultPlanning';
  import MonitoringPlanLaneModal from './MonitoringPlanLaneModal';

  const defaultBanner = require('./img/monitoringplan.jpg');

  export default {
    components: {
      MonitoringPlanConsultPlanning,
      MonitoringPlanLaneModal,
    },
    data() {
      return {
        structure: {},
        plannings: [],
        loading: null,
        exporting: { inprogress: false, success: false, error: false, link: null },
        editingMonitoringPlanning: null,
        editingMonitoringLane: null,
        defaultBanner,
      };
    },
    computed: {
      userIsAdmin() { return this.$store.state.users.accessRight.isAdmin; },
    },
    watch: {
      editingMonitoringLane(newVal) {
        if (newVal) {
          $("body").addClass("modal-open");
        } else {
          $("body").removeClass("modal-open");
        }
      },
    },
    created() {
      this.loading = true;
      window.apiSrv.call('dashboards/monitoringplan', 'index').then((response) => {
        this.$store.getters['users/getOrganizationPromise'].then(() => {
          const { structure, plannings } = response && response.data || {};
          this.structure = structure || {};
          this.plannings = plannings || [];
          this.loading = false;
        });
      });
    },
    methods: {
      openEditLane(planningId, laneId) {
        const planning = this.plannings && this.plannings.filter(item => item.id == planningId)[0];
        this.editingMonitoringLane = planning && planning.monitoring_lanes && planning.monitoring_lanes.filter(item => item.id == laneId)[0];
        this.editingMonitoringPlanning = planning;
      },
      saveEditingLane(data) {
        this.updateLane({
          planning_id: this.editingMonitoringPlanning.id,
          lane_id: this.editingMonitoringLane.id,
          steps: data.steps || this.editingMonitoringLane.steps,
          comments: data.comments || this.editingMonitoringLane.comments,
        });
        this.editingMonitoringLane = null;
      },
      getUuid(item) {
        if (! item.uuid) item.uuid = window.uuid();
        return item.uuid;
      },
      updateLane(data) {
        if (! data.planning_id || ! data.lane_id) return Promise.reject('Missing planning_id or lane_id');
        const planning = this.plannings && this.plannings.filter(item => item.id == data.planning_id)[0];
        const monitoringlane = planning && planning.monitoring_lanes && planning.monitoring_lanes.filter(item => item.id == data.lane_id)[0];
        const originalLane = angular.copy(monitoringlane);
        if (data.steps) this.$set(monitoringlane, 'steps', data.steps);
        if (data.comments) this.$set(monitoringlane, 'comments', data.comments);
        return window.apiSrv.call('dashboards/monitoringplan', 'store', {
          planning_id: data.planning_id,
          lane_id: data.lane_id,
          steps: monitoringlane.steps,
          comments: monitoringlane.comments,
        }).catch((error) => {
          this.$store.dispatch('ui/msgbox/open', { title: "MONITORING_PLAN.SAVE_ERROR", body: error || "MONITORING_PLAN.CHANGES_NOT_SAVED" });
          _.extend(monitoringlane, { steps: [], comments: [] }, originalLane);
        });
      },
      pdfExport() {
        const vm = this;
        vm.exporting = { inprogress: true };

        const $html = document.querySelector("html").cloneNode(true);
        const queryEl = $html.querySelector('.monitoring-plan') || document.createElement("div");
        $html.querySelector("body").innerHTML = `<div class="monitoring-plan content" style="padding: 0 20px; margin-bottom: 0">
        ${queryEl.innerHTML}
        </div>`;
        $html.querySelector('body').classList.add('v-application');
        $html.querySelectorAll(".monitoring-plan-block").forEach(el => el.style.setProperty('padding', '0 0 0 20px'));
        $html.querySelectorAll(".export-hidden").forEach((el) => { el.parentNode.removeChild(el); });
        $html.querySelectorAll(".export-invisible").forEach((el) => { el.style.setProperty('visibility', 'hidden'); });
        $html.querySelectorAll(".v-text-field__slot input").forEach(el => el.setAttribute('value', el.value));
        $html.querySelectorAll(".export-hidden-if-empty").forEach((el) => {
          if (! el.textContent.trim()) el.parentNode.removeChild(el);
        });

        window.apiSrv.call('pdf', 'store', { html: $html.innerHTML, footer: vm.structure.title, orientation: "portrait" }).then((response) => {
          if (response && response.data && response.data.pdfurl) {
            vm.exporting.inprogress = false;
            vm.exporting.success = true;
            setTimeout(() => { vm.exporting.success = false; }, 3000);
            vm.exporting.link = `${response.data.pdfurl}/${vm.structure.title.replace(/\//g, '')}.pdf`;
          }
        }).catch((message) => {
          vm.exporting.inprogress = false;
          vm.exporting.error = message || "Error : not exported";
        });
      },
    },
  };
</script>
