import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import store from '@/store';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'fa',
    // values: {},
  },
  theme: {
    themes: {
      light: {
        primary: "#0169aa",
        secondary: "#484848",
        accent: "#00b100",
        successgreen: "#00b100", // charted : #43c63e
        warningorange: "#ff9b1d",
        errorred: "#f82223",
        info: "#8a92eb",
        meetingprimary: "#ff9b1d",
        midgrey: "#9e9e9e",
      },
    },
  },
  lang: {
    t: (key, ...params) => store.state.lang.i18n.t(key, params),
  },
});
