<template>
  <v-card>
    <v-card-title class="pl-0">
      <div class="display-flex justify-center align-center mx-2" style="flex: 0 0 32px">
        <v-icon color="primary" dense>fa icon-diamonds</v-icon>
      </div>
      <span class="text-h6 primary--text">{{ $t('HOME.MILESTONES_TO_COME') }}</span>
      <v-tooltip left max-width="200">
        <template #activator="{ on }">
          <v-icon color="info" class="ml-auto" v-on="on">far fa-question-circle</v-icon>
        </template>
        <span>{{ $t('HOME.MILESTONES_EXPLANATIONS') }}</span>
      </v-tooltip>
    </v-card-title>
    <div class="ml-12" style="border-bottom: 2px solid #0169aa;"></div>
    <v-card-text class="pl-12">
      <div v-if="! milestones.length" class="muted--text">
        {{ $t('HOME.NO_MILESTONE_ACTIVITY') }}
      </div>
      <div v-if="milestones.length">
        <div style="margin-left: 1px">
          <transition-group name="list-fade" tag="ul">
            <li v-for="el in displayedMilestones" :key="el.id" class="list-fade-item">
              <v-hover v-slot="{ hover: hoverElement }">
                <div class="link black--text display-flex align-center" style="position: relative">
                  <v-icon :color="el.getIcon().color" small style="flex: 0 0 28px">{{ el.getIcon().name }}</v-icon>
                  <span class="ml-1">
                    <b>{{ el.getStartTime() | moment('mediumDateNoYear') }}</b>
                    <span v-if="el.getSchedule() && (el.getSchedule().start || el.getSchedule().end)" class="small">
                      <span v-if="el.getSchedule().start">{{ el.getSchedule().start | moment('LT', true) }}</span>
                      <span v-else>...</span>
                      <span v-if="el.getSchedule().end">- {{ el.getSchedule().end | moment('LT', true) }}</span>
                    </span>
                    &nbsp;{{ el.getTitle() || $t('PLANNING.MILESTONE') }}
                    <span class="ml-2 muted--text">
                      <v-icon small>fas fa-folder-open</v-icon>
                      <router-link :to="projectUrl(el)" style="color: inherit">
                        <i class="small">{{ el.project_title }}</i>
                      </router-link>
                    </span>
                  </span>
                  <v-scale-transition origin="top right">
                    <div v-if="hoverElement && canEditEl(el)" class="element-menu display-flex align-center" style="right: 100%; width: 32px; transition-duration: .2s !important;">
                      <v-btn :title="$t('HOME.OPEN_IN_PLANNING')" :to="elementUrl(el)"
                             icon x-small outlined color="midgrey lighten-1">
                        <v-icon color="primary" size="14">fas fa-external-link</v-icon>
                      </v-btn>
                    </div>
                  </v-scale-transition>
                </div>
              </v-hover>
            </li>
            <li v-if="milestones.length > showItemsLimit" key="seemore" class="pointer mt-2 text-center primary--text list-fade-item-fast"
                @click="showAllItems = ! showAllItems">
              <i :class="showAllItems ? 'fa-chevron-circle-up' : 'fa-chevron-circle-down'" class="fa"></i>
              {{ showAllItems ? $t('HOME.REDUCE') : $tc('HOME.SEE_MORE', milestones.length - showItemsLimit) }}
            </li>
          </transition-group>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    props: {
      elements: { type: Array, required: true },
    },
    data() {
      return {
        showAllItems: false,
        showItemsLimit: 5,
      };
    },
    computed: {
      milestones() {
        return this.elements.filter(item => item.isType('milestone')).sort((a, b) => {
          const aStartTime = a.getStartTime();
          const bStartTime = b.getStartTime();
          if (aStartTime.isSame(bStartTime, 'day')) return a.project_id < b.project_id ? -1 : 1;
          return aStartTime.isBefore(bStartTime) ? -1 : 1;
        });
      },
      displayedMilestones() {
        return this.showAllItems ? this.milestones : this.milestones.slice(0, this.showItemsLimit);
      },
    },
    methods: {
      canEditEl(el) {
        const planning = el.getPlanning();
        return planning && planning.meta && planning.meta.access_right && planning.meta.access_right != 'consult';
      },
      projectUrl(el) {
        const { project_id: projectId, project_title: projectTitle, project_access_right: projectAccessRight } = el;
        const isConsult = projectAccessRight == 'consult';
        return `/${isConsult ? 'viewer/' : 'planning/'}${projectId}/${window.slugify(projectTitle || "New project")}`;
      },
      elementUrl(el) {
        return `${this.projectUrl(el)}?el=${parseInt(el.id, 10)}`;
      },
    },
  };
</script>
