<template>
  <div v-if="dataCount" :class="isSmallWidth ? 'small-width' : null" style="display: flex; flex-wrap: wrap; align-items: center; justify-content: center; padding: 0">
    <div v-if="dataCount.description">
      <v-menu :disabled="! hasPopover" :close-on-content-click="false" open-on-hover auto offset-y
              z-index="130" open-delay="150" content-class="synthesis-popover-container">
        <template #activator="{ on }">
          <div v-on="on">
            <div class="synthesis-item"><i class="fa fa-align-center fa-lg"></i></div>
          </div>
        </template>
        <div class="synthesis-popover">
          <span class="pre">{{ description }}</span>
        </div>
      </v-menu>
    </div>
    <div v-if="dataCount.customDescription">
      <v-menu :disabled="! hasPopover" :close-on-content-click="false" open-on-hover auto offset-y
              z-index="130" open-delay="150" content-class="synthesis-popover-container">
        <template #activator="{ on }">
          <div v-on="on">
            <div class="synthesis-item"><i class="fa fa-align-center fa-lg"></i></div>
          </div>
        </template>
        <div class="synthesis-popover" v-html="customDescription"></div>
      </v-menu>
    </div>
    <div v-if="dataCount.checklist">
      <v-menu :disabled="! hasPopover" :close-on-content-click="false" open-on-hover auto offset-y
              z-index="130" open-delay="150" content-class="synthesis-popover-container">
        <template #activator="{ on }">
          <div v-on="on">
            <div class="synthesis-item">{{ dataCount.checklist }}&nbsp;<i class="fa fa-check-square"></i></div>
          </div>
        </template>
        <div class="synthesis-popover">
          <div v-for="item in checklist" v-if="! item.checked">
            <i :class="el.getChecklistItemClass(item)" class="far fa-square"></i>
            <span class="ml-1">{{ item.title }}</span>
          </div>
        </div>
      </v-menu>
    </div>
    <div v-if="dataCount.users">
      <v-menu :disabled="! hasPopover" :close-on-content-click="false" open-on-hover auto offset-y
              z-index="130" open-delay="150" content-class="synthesis-popover-container">
        <template #activator="{ on }">
          <div v-on="on">
            <div class="synthesis-item">{{ dataCount.users }}&nbsp;<i class="fas fa-user" style="margin-left: -1px;"></i></div>
          </div>
        </template>
        <div class="synthesis-popover">
          <div v-for="user in users" class="nobr">
            <img v-if="user.avatar" :src="user.avatar" style="height: 18px; width: 18px; border-radius: 50%;">
            <span v-else :class="user.group_id ? 'far fa-users' : 'fas fa-user'" class="fa-fw" style="font-size: 14px; vertical-align: middle; top: 0"></span>
            <span class="ml-1" style="vertical-align: middle">
              <span v-if="user.group_id">{{ user.title }}</span>
              <span v-else>{{ user | username('short') }}</span>
              <span v-if="user.percent"> ({{ user.percent }}%)</span>
            </span>
          </div>
        </div>
      </v-menu>
    </div>
    <div v-if="dataCount.attachments">
      <v-menu :disabled="! hasPopover" :close-on-content-click="false" open-on-hover auto offset-y
              z-index="130" open-delay="150" content-class="synthesis-popover-container">
        <template #activator="{ on }">
          <div v-on="on">
            <div class="synthesis-item">{{ dataCount.attachments }}&nbsp;<i class="fa fa-paperclip fa-lg"></i></div>
          </div>
        </template>
        <div class="synthesis-popover">
          <div v-for="link in attachments" class="nobr text-ellipsis" style="max-width:120px">
            <a v-if="link.url" :href="link.url" target="_blank" class="white--text" @click.stop>
              <link-icon :link="link"></link-icon>
              <span :style="{'vertical-align': link.icon ? 'middle' : ''}">{{ link.name || link.url }}</span>
            </a>
          </div>
        </div>
      </v-menu>
    </div>
    <div v-if="dataCount.budgets" class="display-flex">
      <v-menu :disabled="! hasPopover" :close-on-content-click="false" open-on-hover auto offset-y
              z-index="130" open-delay="150" content-class="synthesis-popover-container">
        <template #activator="{ on }">
          <div v-on="on">
            <div class="synthesis-item">{{ dataCount.budgets }}&nbsp;<i :class="`budget-${budgets[0].icon || ''} fa-lg`"></i></div>
          </div>
        </template>
        <div class="synthesis-popover">
          <template v-for="(elBudgets, state) in elBudgetsByStates">
            <div v-for="(budget, icon) in elBudgets" class="nobr">
              <span :class="`budget-${icon || ''}`"></span>
              <span v-if="budget.amount_inprogress && budget.amount">{{ budget.amount_inprogress | number }}&nbsp;/</span>
              <span v-if="budget.amount_inprogress && ! budget.amount">{{ budget.amount_inprogress | number }}</span>
              <span v-if="budget.amount">{{ budget.amount | number }}</span>
              <span v-if="budgetStates[state]">({{ budgetStates[state] | lowercase }})</span>
            </div>
          </template>
        </div>
      </v-menu>
    </div>
  </div>
</template>

<style>
  .element .synthesis-item {
    margin: 2px 3px;
    font-size: 9px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1px;
    transition: all .3s;
  }

  .element .small-width .synthesis-item {
    margin: 2px 1px;
  }

  .synthesis-popover-container {
    padding-top: 4px;
    box-shadow: none;
  }

  .synthesis-popover {
    max-width: 300px;
    background: #616161;
    color: #fff;
    font-size: 12px;
    padding: 5px 8px;
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    border-radius: 2px;
  }
</style>

<script>
  import clientConfig from '@/client_customs/config';
  import LinkIcon from '@/components/Planning/partials/LinkIcon';

  function hasNonFalseKey(object) {
    return Object.keys(object).reduce((prev, key) => ((object[key] || prev)), false);
  }

  export default {
    components: {
      LinkIcon,
    },
    props: {
      el: { required: true, type: Object },
    },
    data() {
      return {
      };
    },
    computed: {
      description() { return this.el.getDescription(); },
      customDescription() {
        return clientConfig.elements && clientConfig.elements.customDescription && this.el.data.customFields && this.el.data.customFields[clientConfig.elements.customDescription.dataField];
      },
      checklist() { return this.el.getChecklist(); },
      users() { return this.el.getUsers(); },
      attachments() { return this.el.getLinks(); },
      budgets() { return this.el.getBudgets() && this.el.getBudgets().filter(budget => budget.amount || budget.amount_inprogress); },
      dataCount() {
        const ret = {
          description: !! this.description,
          customDescription: !! this.customDescription,
          checklist: this.checklist && this.checklist.filter(checklist => ! checklist.checked).length,
          users: this.users && this.users.length,
          attachments: this.attachments && this.attachments.length,
          budgets: this.budgets && this.budgets.length,
        };
        const hiddenFields = clientConfig.subprojects && clientConfig.subprojects.synthesisHidden;
        (hiddenFields || []).forEach((hiddenField) => { ret[hiddenField] = false; });
        return hasNonFalseKey(ret) ? ret : null;
      },
      budgetStates() {
        return this.$store.getters['users/accessRight/config'].budgetStates;
      },
      elBudgetsByStates() {
        const elBudgets = this.budgets;
        if (! elBudgets) return null;
        const budgetStateKeysSet = new Set(Object.keys(this.budgetStates));
        const budgetsByStates = {};
        elBudgets.forEach((budget) => {
          if (! budget.amount && ! budget.amount_inprogress) return;
          const state = budgetStateKeysSet.has(budget.state) ? budget.state : '';
          const icon = budget.icon || '';
          if (! budgetsByStates[state]) budgetsByStates[state] = {};
          if (! budgetsByStates[state][icon]) budgetsByStates[state][icon] = { amount: 0, amount_inprogress: 0 };
          budgetsByStates[state][icon].amount += budget.amount || 0;
          budgetsByStates[state][icon].amount_inprogress += budget.amount_inprogress || 0;
        });
        return budgetsByStates;
      },
      isSmallWidth() {
        return this.el.getWidth() < 60;
      },
      hasPopover() {
        return this.$store.getters['users/accessRight/config'].subprojectsSynthesisPopover;
      },
    },
  };
</script>
