import navigation from './navigation';
import planning from './planning';
import subplanning from './subplanning';
import kanban from './kanban';
import msgbox from './msgbox';
import saving from './saving';

export default {
  namespaced: true,
  modules: {
    navigation,
    planning,
    subplanning,
    kanban,
    msgbox,
    saving,
  },
  state: {
    mobileview: null,
    displayLoginWindow: false,
    displayPremiumSuspended: false,
    displayPremiumExpired: false,
  },
  mutations: {
    setLoginWindow(state, status) {
      state.displayLoginWindow = status;
    },
    setPremiumSuspended(state, status) {
      state.displayPremiumSuspended = status;
    },
    setPremiumExpired(state, status) {
      state.displayPremiumExpired = status;
    },
  },
};
