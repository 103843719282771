<template>
  <div class="mb-2">
    <!-- Budgets Header -->
    <div class="display-flex align-center mb-2">
      <div class="mr-2 text-center" style="flex: 0 0 24px;">
        <v-icon color="primary" small>fas fa-euro-sign</v-icon>
      </div>
      <label for="budget0" class="uppercase primary--text ma-0">{{ $t('ELEMENT-DETAIL.BUDGET') }}</label>
      <v-spacer></v-spacer>
      <v-divider vertical class="mx-2"></v-divider>
      <template v-if="! isConsult">
        <v-btn :title="$t('ELEMENT-DETAIL.ADD_BUDGET_ITEM')" icon small tabindex="-1" @click="addBudgetItem()">
          <v-icon small>fas fa-plus-circle</v-icon>
        </v-btn>
        <v-divider vertical class="mx-2"></v-divider>
      </template>
      <v-btn :title="$t(el.getConfig('show-budgets') ? 'ELEMENT-DETAIL.HIDE_ON_PLANNING' : 'ELEMENT-DETAIL.SHOW_ON_PLANNING')" icon small tabindex="-1"
             @click="el.getConfig()['show-budgets'] = ! el.getConfig()['show-budgets']">
        <v-icon small>{{ el.getConfig('show-budgets') ? 'fa-eye' : 'fa-eye-slash' }}</v-icon>
      </v-btn>
    </div>

    <!-- Budgets Items -->
    <template v-if="! isConsult">
      <div v-for="(budget, index) in budgets" :key="index" class="ml-6 pl-2 display-flex align-end hovervisible-activator">
        <v-text-field v-model.number="budget.amount_inprogress" :label="$t('ELEMENT-DETAIL.AMOUNT_INPROGRESS')" type="number" hide-details
                      @change.once="updateBudgets">
        </v-text-field>
        <span class="mx-2 mt-auto">/</span>
        <v-text-field :id="`budget${index}`" v-model.number="budget.amount" :label="$t('ELEMENT-DETAIL.AMOUNT')" type="number" hide-details
                      @change.once="updateBudgets">
        </v-text-field>
        <v-select v-model="budget.icon" :items="budgetIcons" hide-details class="mx-2" style="flex: 0 0 40px" @change.once="updateBudgets">
        </v-select>
        <v-select v-model="budget.state" :items="budgetStatesItems" hide-details style="flex: 0 0 85px" @change.once="updateBudgets">
        </v-select>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn :style="budgets.length == 1 ? 'visibility: hidden' : ''" icon small outlined color="errorred" class="hovervisible-item ml-2" v-on="on"
                   @click="removeBudgetItem(index)">
              <v-icon small>far fa-trash-alt</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('ELEMENT-DETAIL.DELETE_BUDGET_ITEM') }}</span>
        </v-tooltip>
      </div>
    </template>
    <template v-else>
      <div v-for="(elBudgets, state) in elBudgetsByStates" class="ml-6 pl-2">
        <div v-for="(budget, icon) in elBudgets" style="padding: 1px">
          <span>
            <span :class="`budget-${icon || ''}`"></span>
            <span v-if="budget.amount_inprogress && budget.amount">{{ budget.amount_inprogress | number }}&nbsp;/</span>
            <span v-if="budget.amount_inprogress && ! budget.amount">{{ budget.amount_inprogress | number }}</span>
            <span v-if="budget.amount">{{ budget.amount | number }}</span>
          </span>
          <span v-if="state">({{ $t('ELEMENT-DETAIL.' + uppercase(state)) | lowercase }})</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  const newDefaultBudgetItem = function () { return { amount: null, amount_inprogress: null, icon: '', state: '' }; };

  export default {
    props: {
      el: { type: Object, required: true },
    },
    data() {
      return {
        budgets: this.el.getBudgets() || [newDefaultBudgetItem()],
        budgetIcons: [{ text: '€', value: '' }, { text: '$', value: 'usd' }, { text: '£', value: 'gbp' }, { text: 'F CFP', value: 'xpf' }],
      };
    },
    computed: {
      isConsult() { return this.el.isType('macro'); },
      budgetStatesItems() {
        const budgetStatesConfig = this.$store.getters['users/accessRight/config'].budgetStates;
        return [{ text: '', value: '' }, ...Object.keys(budgetStatesConfig).map(key => ({ text: budgetStatesConfig[key], value: key }))];
      },
      elBudgetsByStates() {
        // compute el budgets to organize as { [state]: { [icon] : budgetObj }}
        const elBudgets = this.el.getBudgets();
        if (! elBudgets) return null;
        const budgetsByStates = {};
        elBudgets.forEach((budget) => {
          if (! budget.amount && ! budget.amount_inprogress) return;
          const { state } = budget;
          if (! budgetsByStates[state]) budgetsByStates[state] = {};
          const icon = budget.icon || '';
          if (! budgetsByStates[state][icon]) budgetsByStates[state][icon] = { amount: 0, amount_inprogress: 0 };
          budgetsByStates[state][icon].amount += budget.amount || 0;
          budgetsByStates[state][icon].amount_inprogress += budget.amount_inprogress || 0;
        });
        return budgetsByStates;
      },
    },
    methods: {
      addBudgetItem() {
        this.budgets.push(newDefaultBudgetItem());
        this.updateBudgets();
      },
      removeBudgetItem(index) {
        if (index > -1 && index < this.budgets.length) {
          this.budgets.splice(index, 1);
          this.updateBudgets();
        }
      },
      updateBudgets() {
        this.el.setBudgets(this.budgets);
      },
      uppercase(str) {
        return str && str.toUpperCase();
      },
    },
  };
</script>
