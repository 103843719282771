<template>
  <div v-if="planning.visibleTimeline" :class="{'single-timeline': ! planning.visibleTimeline.subtimelinecols.length}" class="lane-header-column">
    <div v-if="canModifyPlanning || dashboard">
      <v-tooltip bottom z-index="1000">
        <template #activator="{ on }">
          <v-btn :style="{ right: hasSubprojects ? '-150px' : '5px' }" fab small class="tour-timelineconfig export-hidden ma-0"
                 style="height: 36px; width: 36px; position: absolute; top: -40px" v-on="on"
                 @mouseover="hovertimelineconfig = true" @mouseout="hovertimelineconfig = false" @click="openConfig">
            <v-icon :color="($store.state.ui.planning.hovertimeline || hovertimelineconfig) ? '' : 'midgrey'" size="20">fas fa-cog</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('PLANNING.PLANNING_CONFIG') }}</span>
      </v-tooltip>
      <!-- MODAL -->
      <modal v-if="configIsOpened" dark @close="configIsOpened = false">
        <config-window :planning="planning" :tabs="configTabs" @open-element="openElement" @close="configIsOpened = false"></config-window>
      </modal>
    </div>
    <template v-if="canModifyPlanning">
      <draggable :list="planning.lanes" :options="{ handle: '.draggable-anchor' }"
                 @start="startSortLanes()" @end="updateSortLanes()">
        <div v-for="(lane, laneIndex) in planning.lanes" :id="`laneheader${lane.id}`" :key="lane.id"
             :style="{ height: `${lane.height}px`, 'background-color': lane.getMainColor(), color: lane.getMainColorContrastedTextColor() }"
             class="lane-header designcolor-main-bg">
          <div :style="{ width: `${laneLevelWidth * lane.level}px` }" class="lane-level-gap"></div>
          <div :title="$t('PLANNING.DRAG_AND_DROP_LANES')" class="draggable-anchor grab"></div>
          <v-hover v-if="! lane.hidden" v-slot="{ hover: hoverLaneLabel }">
            <div class="lane-label">
              <v-fade-transition>
                <div v-show="hoverLaneLabel && ! laneLabelSelected[lane.id]" class="lane-menu display-flex align-center px-1" style="width: 100%;">
                  <v-fade-transition>
                    <div v-if="hoverLaneLabel && ! laneLabelSelected[lane.id]" class="display-flex align-center flex-grow-1">
                      <v-btn :title="$t('PLANNING.INDENT_LANE')" icon x-small style="width: 8px"
                             @click="setLaneLevel(lane, lane.level ? lane.level - 1 : 1)">
                        <v-icon small color="white" style="width: 8px">{{ lane.level ? 'fas fa-caret-left' : 'fas fa-caret-right' }}</v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <template v-if="lane.level == 1">
                        <v-btn :title="$t('PLANNING.INDENT_LANE')" icon x-small style="width: 8px"
                               @click="setLaneLevel(lane, 2)">
                          <v-icon small color="white" style="width: 8px">fas fa-caret-right</v-icon>
                        </v-btn>
                        <v-spacer></v-spacer>
                      </template>
                      <v-btn :title="$t('PLANNING.HIDE_LANE')" icon x-small class="ml-1"
                             @click="hideLane(lane)">
                        <v-icon size="13" color="white">fas fa-eye-slash</v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn :title="$t('PLANNING.SELECT_COLOR')" icon x-small @click="selectLaneColor(lane)">
                        <v-icon color="successgreen" size="14">fas fa-tint</v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn-toggle :value="(lane.getLabelStyle() || {})['font-weight']"
                                    @change="toggleLaneLabelStyle(lane, 'font-weight')">
                        <v-btn value="bold" text x-small class="pa-0" style="min-width: 20px">
                          <v-icon size="13">fas fa-bold</v-icon>
                        </v-btn>
                      </v-btn-toggle>
                      <v-btn-toggle :value="(lane.getLabelStyle() || {})['font-style']" class="ml-1"
                                    @change="toggleLaneLabelStyle(lane, 'font-style')">
                        <v-btn value="italic" text x-small class="pa-0" style="min-width: 20px">
                          <v-icon size="13">fas fa-italic</v-icon>
                        </v-btn>
                      </v-btn-toggle>
                      <v-spacer></v-spacer>
                    </div>
                  </v-fade-transition>
                  <delete-lane-popover :lane="lane" @delete="deleteLane(laneIndex)"></delete-lane-popover>
                </div>
              </v-fade-transition>
              <v-fade-transition v-if="hasMonitoringPlan">
                <div v-if="hoverLaneLabel && ! laneLabelSelected[lane.id]" class="lane-menu" style="left:100%; top: 0; padding: 6px 3px; z-index: 111">
                  <div class="white--text pointer" style="min-width: 40px" @click="monitoringPlanEditingLane = lane">
                    {{ $t('MONITORING_PLAN.TITLE') }}
                  </div>
                </div>
              </v-fade-transition>
              <monitoring-plan-in-planning-meteo :lane="lane" class="pointer"
                                                 @click.native="monitoringPlanEditingLane = lane">
              </monitoring-plan-in-planning-meteo>
              <textarea-autosize v-if="! lane.hidden" v-model="lane.label" v-contentautoselect :style="lane.getLabelStyle()" spellcheck="false" rows="1"
                                 style="width: 100%; background: transparent; resize: none; border: none; text-align: center"
                                 @focus.native="startChangingContent(lane); setLaneLabelSelected(lane.id, true)"
                                 @blur.native="changingContent(lane); setLaneLabelSelected(lane.id, false)">
              </textarea-autosize>
              <router-link v-if="lane.project_id" :to="`/planning/${lane.project_id}`">
                <div class="subprojects-header-chip">
                  {{ $t('PLANNING.SUBPROJECT') }}
                </div>
              </router-link>
            </div>
          </v-hover>
          <v-hover v-if="lane.hidden" v-slot="{ hover: hoverLaneLabel }">
            <div :title="lane.label" class="lane-label" style="padding: 0 0 0 5px">
              <div class="nobr" style="overflow: hidden; text-overflow: ellipsis; align-self: flex-start; width: 100%;">{{ lane.label }}</div>
              <v-fade-transition>
                <div v-show="hoverLaneLabel" class="lane-menu px-1">
                  <v-btn :title="$t('PLANNING.SHOW_LANE')" icon x-small @click="showLane(lane)">
                    <v-icon size="13" color="white">fas fa-eye</v-icon>
                  </v-btn>
                </div>
              </v-fade-transition>
            </div>
          </v-hover>
        </div>
      </draggable>

      <!-- ADD LANE-->
      <div>
        <v-btn color="accent" class="tour-addlane mt-1" rounded block
               @click="addLane(null)">
          <v-icon size="14" class="mr-1">fas fa-plus-circle</v-icon> {{ $t('PLANNING.NEW_LANE') }}
        </v-btn>
        <v-btn color="secondary" class="tour-subprojects mt-3" small rounded outlined block
               @click="isPremium ? openNewSubproject() : openMsgBox({ title: 'PREMIUM.PREMIUM_FEATURE', body: hidePremiumFeatures ? '' : 'PREMIUM.SUBPROJECT_IS_PREMIUM' })">
          <span class="mr-auto display-flex flex-align"><v-icon size="14" class="mr-1">fas fa-object-group</v-icon> {{ $t('PLANNING.NEW_SUBPROJECT') }}</span>
        </v-btn>
        <v-btn color="secondary" class="my-1" small rounded outlined block
               @click="isPremium ? openImportTemplate() : openMsgBox({ title: 'PREMIUM.PREMIUM_FEATURE', body: hidePremiumFeatures ? '' : 'PREMIUM.IMPORT_TEMPLATE_IS_PREMIUM' })">
          <span class="mr-auto display-flex flex-align"><v-icon size="14" class="mr-1">fas fa-copy</v-icon> {{ $t('PLANNING.IMPORT_TEMPLATE') }}</span>
        </v-btn>
      </div>

      <!-- MODALS -->
      <modal v-if="laneColorEditingLane.id" :backdrop="false" :target="`#laneheader${laneColorEditingLane.id}`" movable size="sm" @close="closeColorEditingModal">
        <color-picker :planning="planning" :target="laneColorEditingLane" @close="closeColorEditingModal"></color-picker>
      </modal>
      <modal v-if="newSubprojectIsOpened" @close="newSubprojectIsOpened = false">
        <new-subproject @close="newSubprojectIsOpened = false"></new-subproject>
      </modal>
      <modal v-if="importTemplateIsOpened" @close="importTemplateIsOpened = false">
        <import-template :planning="planning" @close="importTemplateIsOpened = false"></import-template>
      </modal>
      <monitoring-plan-in-planning-modal v-if="hasMonitoringPlan" :planning_id="planning.id" :lane="monitoringPlanEditingLane"
                                         @close="monitoringPlanEditingLane = {}">
      </monitoring-plan-in-planning-modal>
      <!-- END MODALS-->
    </template>

    <template v-if="! canModifyPlanning">
      <a href="https://bubbleplan.net" target="_blank">
        <div v-if="! planning.meta.isOwnerPremium" class="bubbleplanlogo">
          <img src="../../img/bubble-plan-logo-color.png">
        </div>
      </a>
      <div v-for="lane in planning.lanes" :id="`laneheader${lane.id}`" :key="lane.id"
           :style="{ height: `${lane.height}px`, 'background-color': lane.getMainColor(), color: lane.getMainColorContrastedTextColor() }"
           class="lane-header designcolor-main-bg">
        <div :style="{ width: laneLevelWidth * lane.level + 'px'}" class="lane-level-gap"></div>
        <div class="draggable-anchor"></div>
        <div v-if="! lane.hidden" class="lane-label">
          <monitoring-plan-in-planning-meteo :lane="lane"></monitoring-plan-in-planning-meteo>
          <div :style="lane.getLabelStyle()" class="pre" style="width: 100%; word-wrap: break-word;">{{ lane.label }}</div>
        </div>
        <div v-if="lane.hidden" :title="lane.label" class="lane-label" style="padding: 0 0 0 5px">
          <div class="nobr" style="overflow: hidden; text-overflow: ellipsis; align-self: flex-start; width: 100%;">{{ lane.label }}</div>
        </div>
      </div>
    </template>
  </div>
</template>

<style>
  .lane-header-column .lane-level-gap {
    flex-shrink: 0;
    background: #eee;
    transition: width .3s;
  }

  .lane-header-column .subprojects-header-chip {
    background: rgb(100, 100, 100);
    padding: 3px 7px;
    border-radius: 30px;
    display: inline-block;
    color: white;
    width: auto;
  }
</style>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import draggable from 'vuedraggable';
  import clientConfig from '@/client_customs/config';
  import constants from '@/js/constants.js';
  import TextareaAutosize from '@/components/Reusables/TextareaAutosize';
  import ColorPicker from '@/components/ColorPicker/ColorPicker';
  import ConfigWindow from '@/components/Config/Config';
  import NewSubproject from '@/components/Subprojects/NewSubproject';
  import ImportTemplate from '@/components/Subprojects/ImportTemplate';
  import MonitoringPlanInPlanningMeteo from '@/components/MonitoringPlan/MonitoringPlanInPlanningMeteo';
  import MonitoringPlanInPlanningModal from '@/components/MonitoringPlan/MonitoringPlanInPlanningModal';
  import DeleteLanePopover from './partials/DeleteLanePopover';
  import monitoringplan from './mixins/monitoringplan';

  export default {
    components: {
      draggable,
      TextareaAutosize,
      ColorPicker,
      ConfigWindow,
      NewSubproject,
      ImportTemplate,
      MonitoringPlanInPlanningMeteo,
      MonitoringPlanInPlanningModal,
      DeleteLanePopover,
    },
    mixins: [monitoringplan],
    props: {
      planning: { type: Object, required: true },
      dynamic: { type: Boolean, default: false },
      view: { type: Boolean, default: false },
      dashboard: { type: Boolean, default: false },
    },
    data() {
      return {
        hovertimelineconfig: false,
        laneLabelSelected: {},
        laneColorEditingLane: {},
        monitoringPlanEditingLane: {},
        configIsOpened: false,
        newSubprojectIsOpened: false,
        importTemplateIsOpened: false,
        laneLevelWidth: constants.laneLevelWidth,
        hidePremiumFeatures: clientConfig.hidePremiumFeatures,
      };
    },
    computed: {
      canModifyPlanning() {
        return this.dynamic && ! this.view;
      },
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
      configTabs() {
        return this.dashboard ? ['timeline', 'design'] : ['timeline', 'lanes', 'design'];
      },
      ...mapGetters('planning', ['hasSubprojects']),
    },
    methods: {
      selectLaneColor(lane) {
        this.startChangingConfig();
        this.startChangingContent(lane);
        this.laneColorEditingLane = lane;
      },
      closeColorEditingModal() {
        this.changingConfig();
        this.changingContent(this.laneColorEditingLane);
        this.laneColorEditingLane = {};
      },
      setLaneLevel(lane, level) {
        this.startChangingContent(lane);
        lane.level = level;
        this.changingContent(lane);
      },
      toggleLaneLabelStyle(lane, prop) {
        const activeValue = { 'font-weight': 'bold', 'font-style': 'italic' }[prop] || '';
        this.startChangingContent(lane);
        const laneLabelStyle = lane.getLabelStyle() || {};
        this.$set(laneLabelStyle, prop, laneLabelStyle[prop] == activeValue ? 'normal' : activeValue);
        lane.setLabelStyle(laneLabelStyle);
        this.changingContent(lane);
      },
      hideLane(lane) {
        this.startChangingContent(lane);
        this.setLaneHidden({ lane, hidden: true });
        this.changingContent(lane);
      },
      showLane(lane) {
        this.startChangingContent(lane);
        this.setLaneHidden({ lane, hidden: false });
        this.changingContent(lane);
      },
      setLaneLabelSelected(laneId, val) {
        this.$set(this.laneLabelSelected, laneId, val);
      },
      openConfig() {
        this.configIsOpened = true;
      },
      openNewSubproject() {
        this.newSubprojectIsOpened = true;
      },
      openImportTemplate() {
        this.importTemplateIsOpened = true;
      },
      openElement(el) {
        this.$store.dispatch('ui/planning/scrollToEl', el);
        this.$store.dispatch('ui/planning/openElementDetails', el);
      },
      openMsgBox(data) {
        this.$store.dispatch('ui/msgbox/open', data);
      },
      ...mapActions('planning/lanes', ['startChangingContent', 'changingContent', 'addLane', 'deleteLane', 'startSortLanes', 'updateSortLanes', 'setLaneHidden']),
      ...mapActions('planning/config', ['startChangingConfig', 'changingConfig']),
    },
  };
</script>
