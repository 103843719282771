<template>
  <div style="padding: 0 10px 15px 2px;">
    <div v-if="! completePlanning" class="mt-2 text-center">
      <i class="fa fa-2x fa-spinner fa-spin"></i>
    </div>
    <div v-else style="display: flex; flex-wrap: wrap;">
      <v-card v-if="projectsheetDisplay.description && hasContent(projectsheet.description)" class="ml-2 mt-2 pa-4" style="width: 40%; flex-grow: 1;">
        <a class="pointer" @click="showDescription = ! showDescription">
          <i :class="showDescription ? 'fa-caret-down' : 'fa-caret-right'" class="fa" style="width: .8em"></i>
          <v-icon color="primary" small class="ml-2 mr-1">fas fa-align-justify</v-icon>
          <b class="primary--text">{{ $t('PROJECT_SHEET.DESCRIPTION') }}</b>
        </a>
        <div v-if="showDescription" class="mt-1">
          <div class="summernote-html" v-html="projectsheet.description"></div>
          <div v-if="projectsheet.target_endtime || projectsheet.target_budget">
            <v-divider></v-divider>
            <v-row align="center">
              <v-col v-if="projectsheet.target_endtime" class="display-flex align-center">
                <v-icon small class="mr-2" style="width: 12px; height: 32px;">fas fa-hourglass-end</v-icon>
                <div>{{ $t('PROJECT_SHEET.TARGET_ENDTIME') }} : <span class="nobr">{{ projectsheet.target_endtime | moment('date') }}</span></div>
              </v-col>
              <v-col v-if="projectsheet.target_budget" class="display-flex align-center">
                <v-icon small class="mr-2" style="width: 12px; height: 32px;">fas fa-euro-sign</v-icon>
                <div>{{ $t('PROJECT_SHEET.TARGET_BUDGET') }} : <span class="nobr">{{ projectsheet.target_budget | number }}</span></div>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-card>
      <v-card v-if="projectsheetDisplay.steps && projectsheetCurrentStep" class="ml-2 mt-2 pa-4" style="width: 40%; flex-grow: 1;">
        <div>
          <a class="pointer" @click="showSteps = ! showSteps">
            <i :class="showSteps ? 'fa-caret-down' : 'fa-caret-right'" class="fa" style="width: .8em"></i>
            <v-icon color="primary" small class="ml-2 mr-1">fas fa-flag</v-icon>
            <b class="primary--text">{{ $t('PROJECT_SHEET.WAYPOINT') }}<span v-if="projectsheetCurrentStep"> : {{ projectsheetCurrentStep.date | moment('mediumDate') }}</span></b>
          </a>
        </div>
        <div v-if="showSteps" class="mt-2">
          <div class="summernote-html" v-html="projectsheetCurrentStep.waypoint"></div>
          <div v-if="stepsExtraFields" class="mt-2" style="display: flex; flex-wrap: wrap; margin: 8px -8px 0">
            <div v-for="field in stepsExtraFields" v-if="hasContent(projectsheetCurrentStep[field.name])" :key="field.name"
                 :style="{ width: (field.width || 1) * 100 + '%' }" style="padding: 0 8px; flex-grow: 1;">
              <i :class="field.icon"></i>&nbsp;
              <b>{{ field.title }}</b>
              <div class="title-underline"></div>
              <div class="summernote-html" v-html="projectsheetCurrentStep[field.name]"></div>
            </div>
          </div>
        </div>
      </v-card>
      <v-card v-if="projectsheetDisplay.files" class="ml-2 mt-2 pa-4">
        <v-icon color="primary" small class="mr-1">fas fa-paperclip</v-icon>
        <b class="primary--text">{{ $t('PROJECT_SHEET.PROJECT_SHEET_FILES') }}</b>
        <div class="mt-2">
          <ul class="list-unstyled">
            <li v-for="file in projectsheet.files" style="margin-bottom: 2px">
              <i :class="file.mime | mimeIcon" class="fa-fw"></i> &nbsp;<a :href="file.url" target="_blank">{{ file.display_filename }}</a>
            </li>
          </ul>
        </div>
      </v-card>
      <v-card v-if="projectsheetDisplay.project_users" class="ml-2 mt-2 pa-4">
        <v-icon color="primary" small class="mr-1">fas fa-user</v-icon>
        <b class="primary--text">{{ $t('PROJECT_SHEET.USERS') }}</b>
        <div class="mt-2">
          <div v-for="(user, id) in projectUsers" :key="id" class="vcenter" style="margin-right: 8px; margin-bottom: 8px; background: rgba(0,0,0,.07); padding: 2px 6px; border-radius: 10px">
            <img v-if="user.avatar" :src="user.avatar" style="max-height: 20px;border-radius: 50%;">
            <i v-else class="fas fa-user" style="margin: 0 3px"></i>
            &nbsp;<span class="vcenter">{{ user | username }}</span>
          </div>
        </div>
      </v-card>
      <v-card v-if="projectsheetDisplay.activity" class="ml-2 mt-2 pa-4" style="width: 100%">
        <v-icon color="primary" small class="mr-1">fas fa-chart-bar</v-icon>
        <b class="primary--text">{{ $t('PROJECT_SHEET.ACTIVITY') }}</b>
        <project-sheet-activity :planning="completePlanning" class="mt-2"></project-sheet-activity>
      </v-card>
    </div>
    <!-- MODALS-->
    <modal v-if="editProjectSheetIsOpened" @close="editProjectSheetIsOpened = false">
      <project-sheet :planning="completePlanning" @update="updateProjectSheet"></project-sheet>
    </modal>
  </div>
</template>

<script>
  import ProjectSheet from '@/components/ProjectSheet/ProjectSheet';
  import ProjectSheetActivity from '@/components/ProjectSheet/ProjectSheetActivity';

  export default {
    components: {
      ProjectSheet,
      ProjectSheetActivity,
    },
    props: {
      projectId: { type: Number, required: true },
      projectsheetDisplay: { type: Object, required: true },
    },
    data() {
      return {
        completePlanning: null,
        showDescription: true,
        showSteps: true,
        editProjectSheetIsOpened: false,
      };
    },
    computed: {
      projectsheet() {
        return this.completePlanning && this.completePlanning.projectsheet;
      },
      projectsheetCurrentStep() {
        return this.projectsheet && this.projectsheet.steps && this.projectsheet.steps.last();
      },
      stepsExtraFields() {
        return this.$store.getters['users/accessRight/config'].projectsheet_steps_extrafields;
      },
      projectUsers() {
        const users = {};
        if (! this.completePlanning) return users;
        this.completePlanning.elements.forEach((item) => {
          if (! item.getUsers()) return;
          item.getUsers().forEach((user) => {
            const id = user.id || user.username || user.email;
            users[id] = user;
          });
        });
        return users;
      },
      userIsAdmin() { return this.$store.state.users.accessRight.isAdmin; },
    },
    created() {
      this.refreshProject();
    },
    methods: {
      refreshProject() {
        this.completePlanning = null;
        this.$store.dispatch('multiprojects/plannings/load', { planningId: this.projectId }).then((planning) => {
          this.completePlanning = angular.copy(planning);
          if (this.userIsAdmin) {
            if (! this.completePlanning.meta) this.$set(this.completePlanning, 'meta', {});
            this.$set(this.completePlanning.meta, 'access_right', 'admin');
            this.$emit('set-projectsheet-edit-rights', true);
          }
        });
      },
      openEditProjectSheet() {
        this.editProjectSheetIsOpened = true;
      },
      updateProjectSheet(values) {
        if (! this.completePlanning) return;
        const oldValues = angular.copy(this.projectsheet);
        this.completePlanning.projectsheet = { ...this.completePlanning.projectsheet, ...values };
        this.completePlanning.saveAdmin({ projectsheet: this.completePlanning.projectsheet }).catch((error) => {
          this.completePlanning.projectsheet = oldValues;
          this.editProjectSheetIsOpened = false;
          this.$store.dispatch('ui/msgbox/open', { title: "GLOBAL.ERROR", body: error });
        });
      },
      hasContent(html) {
        return !! window.html2text(html || "").trim();
      },
    },
  };
</script>
