<template>
  <div class="checklist">
    <div v-if="isMiniElement"><i class="fa fa-check-square"></i> {{ elCompletedCount }}<span style="margin: 0 1px">/</span>{{ elChecklist.length }}</div>
    <template v-else>
      <div v-for="item in elChecklist">
        <span :class="el.getChecklistItemClass(item)" :title="checklistItemTitle(item)">
          <i :class="item.checked ? 'fa-check-square' : 'fa-square'" class="far"
             @click.stop="$emit('dyn-subtasks-click', item)">
          </i>
          <span>{{ item.title }}</span>
        </span>
      </div>
    </template>
  </div>
</template>

<style>
  .element.task .checklist .far.fa-square, .element.task .checklist .far.fa-check-square {
    margin-right: 2px;
  }
</style>

<script>
  const momentMediumDateNoYearFormat = moment.localeData().custom && moment.localeData().custom('mediumDateNoYear') || 'MMM D';

  export default {
    props: {
      el: { type: Object, required: true },
      isMiniElement: { type: Boolean, default: false },
    },
    computed: {
      elChecklist() {
        return (this.el.getCustomFields() || {}).dyn_subtasks || [];
      },
      elCompletedCount() {
        return this.elChecklist.filter(item => item.checked).length;
      },
    },
    methods: {
      checklistItemTitle(item) {
        const duedate = item.duedate && `${moment(item.duedate).format(momentMediumDateNoYearFormat)}\n`;
        return (duedate || '');
      },
    },
  };
</script>
