const getDefaultTimeline = () => ({
  hidden: {
    before: moment("2022-01-01").format(),
    after: moment().format(),
  },
  steps: ['quarters', 'months'],
  timelocale: "en-gb",
  colwidth: 160,
  workdays: false,
  show_presenttime: true,
  show_timegrid: false,
});

const getDefaultLane = () => ({
  id: 1,
  label: "New lane",
  labelStyle: null,
  contentHeight: null,
  height: null,
  color: null,
  colorShade: null,
  level: null,
  hidden: null,
  project_id: null,
  project_rotoken: null,
  project_hidden_sublanes: null,
});

const getDefaultMeta = () => ({
  title: "",
  imgurl: "",
  category: "",
  status: "",
  isOwnerPremium: null,
  access_right: null,
  notifications: null,
  companylogo: "",
  read_only_token: "",
  owner_id: null,
  updated_by: null,
});

const getDefaultConfig = () => ({
  nameStyle: "font-size:16px;font-weight:bold;",
  design: 'flat',
  colors: null,
  designcolor: null,
  icons: null,
  logo: "",
  displayDependencies: true,
  displayCriticalPath: true,
  lockDragAndDrop: null,
  customFields: null,
});

const getDefaultProject = () => ({
  meta: getDefaultMeta(),
  config: getDefaultConfig(),
  timeline: getDefaultTimeline(),
  lanes: [getDefaultLane()],
  elements: [],
});

export { getDefaultTimeline, getDefaultLane, getDefaultMeta, getDefaultConfig, getDefaultProject };
