<template>
  <v-navigation-drawer id="planningdrawer"
                       :mini-variant="miniVariant" :height="miniVariant ? 'auto' : '80%'" :width="planningDrawer.size == 'normal' ? 380 : 600"
                       hide-overlay temporary right permanent mini-variant-width="40" enable-resize-watcher fixed class="pb-0 elevation-4"
                       style="top: calc(50% + 24px); transform: translateY(-50%); margin: auto; border-top-left-radius: 12px; border-bottom-left-radius: 12px; overflow: hidden">
    <div class="display-flex" style="height: 100%">
      <div v-show="! miniVariant" class="py-4 pl-6" style="flex: 1 1 100%">
        <exchange-center v-if="planningDrawer.isOpened" v-show="planningDrawer.tab == 'exchanges'"
                         :options="{ planning_id: planning.id, autofocus: true }" :parentvisible="planningDrawer.tab == 'exchanges'"
                         class="mb-4">
        </exchange-center>
        <div v-show="planningDrawer.tab != 'exchanges'" style="height:100%; overflow-y: auto;">
          <div style="height:100%;">
            <div style="overflow-y:auto; height:100%; width:100%">
              <div v-if="planningDrawer.tab == 'actions'">
                <div class="display-flex align-center">
                  <b class="primary--text">{{ $t('PLANNINGDRAWER.FIND_HERE_ACTIONS') }}</b>
                  <template v-if="isPremium">
                    <v-spacer></v-spacer>
                    <v-divider vertical class="mr-2"></v-divider>
                    <v-btn-toggle v-model="actionsScope.onlyMine" mandatory class="elevation-1 mr-2">
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <v-btn :value="false" text small v-on="on"><v-icon small>fa fa-users</v-icon></v-btn>
                        </template>
                        {{ $t('PLANNINGDRAWER.ALL_ACTIONS') }}
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <v-btn :value="true" text small class="px-1" v-on="on">
                            <v-avatar size="24"><img :src="me.avatar"></v-avatar>
                          </v-btn>
                        </template>
                        {{ $t('PLANNINGDRAWER.ONLY_MY_ACTIONS') }}
                      </v-tooltip>
                    </v-btn-toggle>
                  </template>
                </div>
                <div v-if="isPremium">
                  <div v-for="el in actionsElements" style="margin-top: 10px;">
                    <div class="small">
                      <strong :class="el.getChecklistItemClass()">{{ el.getEndTime() | moment('mediumDateNoYear') }}</strong>
                      <span :title="`↪ ${laneLabels[el.getLaneId()] || ''}`" style="font-style: italic;" class="pointer" @click="openElement(el)">
                        &nbsp;{{ el.getTitle() || $t('PLANNING.NEW_BUBBLE') }}
                      </span>
                    </div>
                    <div v-for="item in elChecklist(el)" v-if="! actionsScope.onlyMine || item.user_id == me.id" style="margin-left: 20px;">
                      <i class="far fa-square pointer" @click="checklistClick(el, item)"></i>&nbsp;
                      <strong v-if="item.duedate" :class="el.getChecklistItemClass(item)" class="small text-bottom">{{ item.duedate | moment('mediumDateNoYear') }}</strong>&nbsp;
                      <span v-if="item.user_id" :title="(user = getUserById(item.user_id)) | username">
                        <img v-if="user && user.avatar" :src="user.avatar" class="text-bottom" style="max-height: 20px;border-radius: 50%;margin: -1px 0;">
                        <span v-else class="fas fa-user fa-fw" style="font-size: 14px; vertical-align: middle; top:0"></span>
                      </span>
                      {{ item.title }}
                    </div>
                  </div>
                </div>
                <div v-if="! isPremium" style="margin-top: 15px;">
                  <span>{{ $t('PREMIUM.SECTION_IS_PREMIUM') }}</span><br>
                  <a :href="$t('PREMIUM.OFFERS_LINK')" target="_blank">{{ $t('PREMIUM.GO_PREMIUM') }}</a>
                </div>
              </div>
              <planning-drawer-notifications v-if="isPlanningLoaded && planningDrawer.tab == 'notifications'" :planning="planning"
                                             @open-tab="open" @open-element="openElement">
              </planning-drawer-notifications>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center" style="overflow-y: auto; overflow-x: hidden; flex: 0 0 40px">
        <template v-if="! miniVariant">
          <v-btn color="secondary" text icon small class="mt-2" @click="close"><v-icon small>fas fa-times-circle</v-icon></v-btn>
          <v-btn v-if="planningDrawer.size == 'normal'" color="secondary" text icon small @click="planningDrawer.size = 'large'">
            <v-icon small>fas fa-expand-arrows-alt</v-icon>
          </v-btn>
          <v-btn v-else color="secondary" text icon small @click="planningDrawer.size = 'normal'">
            <v-icon small>fas fa-compress-arrows-alt</v-icon>
          </v-btn>
          <v-divider class="mb-12" style="width: 80%"></v-divider>
        </template>
        <v-tooltip v-if="! hideExchangeCenter" left open-delay="0">
          <template #activator="{ on }">
            <v-btn :color="! miniVariant && planningDrawer.tab == 'exchanges' ? 'primary' : 'secondary'" text icon class="mt-2 mb-1" v-on="on"
                   @click="open('exchanges')">
              <v-icon size="20" style="height: auto">fas fa-comments</v-icon>
              <span v-show="unread.exchanges" class="label red-bg" style="border-radius: 50%;position: absolute;padding: 4px 6px;top: -8px;right: -2px;">{{ unread.exchanges }}</span>
            </v-btn>
          </template>
          <span>{{ $t('PLANNINGDRAWER.OPEN_EXCHANGECENTER') }}</span>
        </v-tooltip>
        <v-tooltip left open-delay="0">
          <template #activator="{ on }">
            <v-btn :color="! miniVariant && planningDrawer.tab == 'actions' ? 'primary' : 'secondary'" text icon v-on="on"
                   @click="open('actions')">
              <v-icon size="20" style="height: auto">fas fa-check-square</v-icon>
              <span v-show="unread.actions = countLateActions" class="label red-bg" style="border-radius: 50%;position: absolute;padding: 4px 6px;top: -8px;right: -2px;">{{ unread.actions }}</span>
            </v-btn>
          </template>
          <span>{{ $t('PLANNINGDRAWER.OPEN_ACTIONS') }}</span>
        </v-tooltip>
        <v-tooltip left open-delay="0">
          <template #activator="{ on }">
            <v-btn :color="! miniVariant && planningDrawer.tab == 'notifications' ? 'primary' : 'secondary'" text icon class="mt-1 mb-2" v-on="on"
                   @click="open('notifications')">
              <v-icon size="20" style="height: auto">fas fa-bell</v-icon>
              <span v-show="unread.notifications" class="label red-bg" style="border-radius: 50%;position: absolute;padding: 4px 6px;top: -8px;right: -2px;">{{ unread.notifications }}</span>
            </v-btn>
          </template>
          <span>{{ $t('PLANNINGDRAWER.OPEN_NOTIFICATIONS') }}</span>
        </v-tooltip>
        <template v-if="logoutButton">
          <!-- MICROSOFT TEAMS WITH NO NAVBAR -->
          <v-divider></v-divider>
          <v-tooltip left open-delay="0">
            <template #activator="{ on }">
              <v-btn color="secondary" text icon class="mt-1 mb-1" v-on="on"
                     @click="logout">
                <v-icon size="20" style="height: auto">fas fa-sign-out-alt</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('ACCOUNT.LOG_OUT') }}</span>
          </v-tooltip>
        </template>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<style>
  #planningdrawer {
    z-index: 500;
  }
</style>

<script>
  import { mapActions } from 'vuex';
  import clientConfig from '@/client_customs/config';
  import ExchangeCenter from '@/components/ExchangeCenter/ExchangeCenter';
  import PlanningDrawerNotifications from './PlanningDrawerNotifications';

  export default {
    components: {
      ExchangeCenter,
      PlanningDrawerNotifications,
    },
    props: {
      planning: { type: Object, required: true },
    },
    data() {
      return {
        planningDrawer: { size: 'small', tab: 'exchanges', isOpened: false },
        actionsScope: { onlyMine: false },
        hideExchangeCenter: clientConfig.planningDrawer && clientConfig.planningDrawer.hideExchangeCenter || false,
        logoutButton: clientConfig.planningDrawer && clientConfig.planningDrawer.logoutButton || false,
      };
    },
    computed: {
      miniVariant() {
        return this.planningDrawer.size == 'small';
      },
      notifications() {
        return this.$store.state.notifications.all;
      },
      unreadNotifications() {
        if (! this.isPlanningLoaded) return [];
        return this.notifications.filter(notif => notif.section_type == 'planning' && notif.section_id == this.planning.id && ! notif.seen_at);
      },
      unread() {
        if (! this.isPlanningLoaded) return {};
        return {
          exchanges: this.unreadNotifications.filter(notif => notif.target_type == 'exchangeCenter').length,
          notifications: this.unreadNotifications.length,
        };
      },
      actionsElements() {
        const checklistItemIsActive = item => ! item.checked && (! this.actionsScope.onlyMine || item.user_id == this.me.id);
        return this.planning.elements.filter(el => el.getChecklist() && el.getChecklist().filter(checklistItemIsActive).length).sort((a, b) => (a.getEndTime() < b.getEndTime() ? -1 : 1));
      },
      countLateActions() {
        if (! this.isPlanningLoaded) return 0;
        return this.planning.elements.reduce((count, el) => {
          if (! el.getChecklist()) return count;
          return count + el.getChecklist().filter(item => ! item.checked && item.user_id == this.me.id && moment(item.duedate || el.getEndTime()).isBefore()).length;
        }, 0);
      },
      laneLabels() {
        return this.planning.lanes.reduce((acc, lane) => {
          acc[lane.id] = lane.label;
          return acc;
        }, {});
      },
      isPlanningLoaded() {
        return this.planning && this.planning.visibleTimeline;
      },
      me() { return this.$store.state.users.user; },
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
      getUserById() { return this.$store.getters['users/getUserById']; },
    },
    watch: {
      'planningDrawer.size': function (newVal) {
        if (newVal && newVal !== 'small') this.planningDrawer.isOpened = true;
      },
      'planningDrawer.tab': function (newVal, oldVal) {
        if (newVal == oldVal) return;
        if (oldVal == 'notifications') {
          if (! this.isPlanningLoaded) return;
          if (this.unread.notifications) {
            window.notificationsSrv.seenNotifs({ section_type: 'planning', section_id: this.planning.id });
          }
        }
        if (newVal == 'exchanges') {
          setTimeout(() => {
            $(".chatContent").each(function () { $(this).scrollTop(this.scrollHeight); });
          });
        }
      },
    },
    updated() {
      if (! this.isPlanningLoaded) return;
      if (['exchanges', 'actions', 'notifications'].indexOf(this.$route.query.open) > -1) {
        const tab = this.$route.query.open;
        this.$router.replace({ query: { ...this.$route.query, open: undefined } });
        if (tab == 'actions') this.actionsScope.onlyMine = true;
        this.open(tab);
      }
      if (this.$route.query.el) {
        const el = this.planning.elements.find(item => item.id == this.$route.query.el);
        if (el) setTimeout(() => { this.openElement(el); });
        this.$router.replace({ query: { ...this.$route.query, el: undefined } });
      }
    },
    methods: {
      open(tab) {
        if (this.planningDrawer.size == 'small') this.planningDrawer.size = 'normal';
        this.planningDrawer.tab = tab || 'exchanges';
      },
      close() {
        this.planningDrawer.size = 'small';
        this.planningDrawer.tab = 'exchanges';
      },
      openElement(el) {
        this.$store.dispatch('ui/planning/scrollToEl', el);
        this.$store.dispatch('ui/planning/openElementDetails', el);
      },
      getElementLaneId(el) { return el.getLaneId(); },
      elChecklist(el) {
        return el.getChecklist().filter(item => ! item.checked).sort((a, b) => (a.duedate < b.duedate ? -1 : 1));
      },
      checklistClick(el, item) {
        this.startChangingElement();
        this.$set(item, 'checked', ! item.checked);
        // el.updateChecklistProgress();
        this.changingElement(el);
      },
      logout() {
        window.apiSrv.logout().then(() => {
          this.$store.commit('users/reset');
          window.location.reload();
        }).catch(() => {
          this.$store.commit('users/reset');
          window.location.reload();
        });
      },
      ...mapActions('planning/elements', ['startChangingElement', 'changingElement']),
    },
  };
</script>
