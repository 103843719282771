import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import clientConfig from '@/client_customs/config';
import Navigation from '@/components/Navigation/Navigation';
import Home from '@/components/Home/Home';
import Planning from '@/components/Planning/Planning';
import PlanningUsers from '@/components/Dashboards/PlanningUsers/Planningusers';
import Kanban from '@/components/Dashboards/Kanban/Kanban';
import ActionsDashboard from '@/components/Dashboards/Actions/ActionsDashboard';
import Portfolio from '@/components/Dashboards/Portfolio/Portfolio';
import PersonalCalendar from '@/components/Dashboards/PersonalCalendar/PersonalCalendar';
import Manage from '@/components/Manage/Manage';
import MonitoringPlanConsult from '@/components/MonitoringPlan/MonitoringPlanConsult';
import MonitoringPlanEdit from '@/components/MonitoringPlan/MonitoringPlanEdit';
import MicrosoftTeamsConfig from '@/components/Integrations/MicrosoftTeams/MicrosoftTeamsConfig';
import MicrosoftTeamsDeletion from '@/components/Integrations/MicrosoftTeams/MicrosoftTeamsDeletion';

Vue.use(VueRouter);

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/auth_token/:auth_token',
      beforeEnter(to, from, next) {
        const token = to.params.auth_token;
        if (token) {
          window.apiSrv.setAuthToken(token);
          window.location.href = "";
        } else {
          next('/');
        }
      },
    },
    {
      path: '/home',
      components: { default: Home, navigation: Navigation },
      props: { default: { section: 'home' }, navigation: { config: 'home' } },
    },
    {
      path: '/projects',
      components: { default: Home, navigation: Navigation },
      props: { default: { section: 'projects' }, navigation: { config: 'home', section: 'projects' } },
    },
    {
      path: '/dashboards',
      components: { default: Home, navigation: Navigation },
      props: { default: { section: 'dashboards' }, navigation: { config: 'home', section: 'dashboards' } },
    },
    {
      path: '/meetings',
      components: { default: Home, navigation: Navigation },
      props: { default: { section: 'meetings' }, navigation: { config: 'home', section: 'meetings' } },
    },

    { path: '/planning/:id/:prettyname?', component: Planning, props: { dynamic: true } },
    { path: '/viewer/:id/:prettyname?', component: Planning, props: {} },
    { path: '/planningview/:id/:prettyname?', component: Planning, props: { dynamic: true, view: true } },
    { path: '/mobilewebview/:id', component: Planning, props: {} },

    { path: '/analysis/planningusers', component: PlanningUsers },
    { path: '/analysis/progress', component: Kanban },
    { path: '/analysis/actions', component: ActionsDashboard },
    { path: '/analysis/portfolio', component: Portfolio },
    { path: '/analysis/personalcalendar', component: PersonalCalendar },

    {
      path: '/manage',
      components: { default: Manage, navigation: Navigation },
      props: { navigation: { config: 'manage' } },
    },

    {
      path: '/dashboards/monitoringplan',
      components: { default: MonitoringPlanConsult, navigation: Navigation },
      props: { navigation: { config: 'manage' } },
    },
    {
      path: '/dashboards/monitoringplan/edit',
      components: { default: MonitoringPlanEdit, navigation: Navigation },
      props: { navigation: { config: 'manage' } },
    },
    ...(clientConfig.dashboards && clientConfig.dashboards.router || []),

    { path: '/integrations/microsoftteams/config', component: MicrosoftTeamsConfig },
    { path: '/integrations/microsoftteams/deletion', component: MicrosoftTeamsDeletion },

    { path: '*', redirect: '/home', alias: '/id_token*' }, // alias to keep openid id_token in url (protocol jwt)
  ],
});

router.beforeEach((to, from, next) => {
  // Mobile api token
  const { mobiletoken } = to.query;
  if (mobiletoken) {
    window.apiSrv.setAuthToken(mobiletoken, 'mobileapp');
  }

  // mobileview
  if (to.query.mobileview || clientConfig.mobileview) {
    store.state.ui.mobileview = true;
    document.body.classList.add('mobileview');
  }

  if (clientConfig.router && clientConfig.router.beforeEach) {
    clientConfig.router.beforeEach(to, from, next);
  }

  next();
});

export default router;
