<template>
  <v-navigation-drawer v-if="planningNotifications.length" id="planningdrawer"
                       :mini-variant="miniVariant" :height="miniVariant ? 'auto' : '80%'" :width="planningDrawer.size == 'normal' ? 380 : 600"
                       hide-overlay temporary right permanent mini-variant-width="40" enable-resize-watcher fixed class="pb-0 elevation-4"
                       style="top: calc(50% + 24px); transform: translateY(-50%); margin: auto; border-top-left-radius: 12px; border-bottom-left-radius: 12px; overflow: hidden">
    <div class="display-flex" style="height: 100%">
      <div v-show="! miniVariant" class="py-4 pl-6" style="flex: 1 1 100%">
        <div v-show="planningDrawer.tab != 'exchanges'" style="height:100%; overflow-y: auto;">
          <div style="height:100%;">
            <div style="overflow-y:auto; height:100%; width:100%">
              <planning-drawer-notifications v-if="isPlanningLoaded && planningDrawer.tab == 'notifications'" :planning="planning"
                                             @open-element="openElement">
              </planning-drawer-notifications>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center" style="overflow-y: auto; overflow-x: hidden; flex: 0 0 40px">
        <template v-if="! miniVariant">
          <v-btn color="secondary" text icon small class="mb-0" @click="close"><v-icon small>fas fa-times-circle</v-icon></v-btn>
          <v-btn v-if="planningDrawer.size == 'normal'" color="secondary" text icon small @click="planningDrawer.size = 'large'">
            <v-icon small>fas fa-expand-arrows-alt</v-icon>
          </v-btn>
          <v-btn v-else color="secondary" text icon small @click="planningDrawer.size = 'normal'">
            <v-icon small>fas fa-compress-arrows-alt</v-icon>
          </v-btn>
          <v-divider class="mb-12" style="width: 80%"></v-divider>
        </template>
        <v-tooltip left open-delay="0">
          <template #activator="{ on }">
            <v-btn :color="! miniVariant && planningDrawer.tab == 'notifications' ? 'primary' : 'secondary'" text icon class="mt-1" v-on="on"
                   @click="open('notifications')">
              <v-icon size="20" style="height: auto">fas fa-bell</v-icon>
              <span v-show="unread.notifications" class="label red-bg" style="border-radius: 50%;position: absolute;padding: 4px 6px;top: -8px;right: -2px;">{{ unread.notifications }}</span>
            </v-btn>
          </template>
          <span>{{ $t('PLANNINGDRAWER.OPEN_NOTIFICATIONS') }}</span>
        </v-tooltip>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<style>
  #planningdrawer {
    z-index: 500;
  }
</style>

<script>
  import PlanningDrawerNotifications from './PlanningDrawerNotifications';

  export default {
    components: {
      PlanningDrawerNotifications,
    },
    props: {
      planning: { type: Object, required: true },
    },
    data() {
      return {
        planningDrawer: { size: 'small', tab: 'notifications' },
      };
    },
    computed: {
      miniVariant() {
        return this.planningDrawer.size == 'small';
      },
      notifications() {
        return this.$store.state.notifications.all;
      },
      planningNotifications() {
        if (! this.isPlanningLoaded) return [];
        return this.notifications.filter(notif => notif.section_type == 'planning' && notif.section_id == this.planning.id);
      },
      unreadNotifications() {
        if (! this.isPlanningLoaded) return [];
        return this.planningNotifications.filter(notif => ! notif.seen_at);
      },
      unread() {
        if (! this.isPlanningLoaded) return {};
        return {
          notifications: this.unreadNotifications.length,
        };
      },
      isPlanningLoaded() {
        return this.planning && this.planning.visibleTimeline;
      },
    },
    watch: {
      'planningDrawer.size': function (newVal) {
        if (newVal == 'small') {
          if (! this.isPlanningLoaded) return;
          if (this.unread.notifications) {
            window.notificationsSrv.seenNotifs({ section_type: 'planning', section_id: this.planning.id });
          }
        }
      },
    },
    updated() {
      if (! this.isPlanningLoaded) return;
      if (['notifications'].indexOf(this.$route.query.open) > -1) {
        const tab = this.$route.query.open;
        this.$router.replace({ query: { ...this.$route.query, open: undefined } });
        this.open(tab);
      }
      if (this.$route.query.el) {
        // do nothing - cannot open el
        this.$router.replace({ query: { ...this.$route.query, el: undefined } });
      }
    },
    methods: {
      open(tab) {
        if (this.planningDrawer.size == 'small') this.planningDrawer.size = 'normal';
        this.planningDrawer.tab = tab || 'notifications';
      },
      close() {
        this.planningDrawer.size = 'small';
        this.planningDrawer.tab = 'notifications';
      },
      openElement(el) {
        this.$store.dispatch('ui/planning/scrollToEl', el);
      },
    },
  };
</script>
