<template>
  <div>
    <div v-for="workload in workloadsData" :class="workload.bg" :title="`${$t('ELEMENT-DETAIL.WORKLOAD')} : ${workload.percent} %`"
         :style="{ left: `${workload.xposition}px`, width: `${workload.width}px`, height: `${workload.height}%`, opacity: workload.opacity }"
         style="position: absolute; bottom: 0">
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      planning: { type: Object, required: true },
      lane: { type: Object, required: true },
      adaptWorkloadsHeight: { type: Boolean, default: false },
    },
    data() {
      return {
      };
    },
    computed: {
      workloadsData() {
        const workloads = this.lane && this.lane.getWorkloads && this.lane.getWorkloads() || [];
        return workloads.map((workload) => {
          const { visibleTimeline } = this.planning; // inside the .map to NOT be reactive to visibleTimeline when there is no workload
          const percent = Math.round(100 * workload.daily_workload);
          const bg = this.$store.getters['workloads/getPercentColorClass'](percent);

          const xposition = Math.round(Math.max(0, moment(workload.starttime).diffWithWorkdays(moment(visibleTimeline.starttime), 'minutes', visibleTimeline.workdays) / visibleTimeline.minuteperpx));
          const starttime = moment.max(workload.starttime, moment(visibleTimeline.starttime));
          const endtime = moment.min(workload.endtime, moment(visibleTimeline.endtime).add(-1 * visibleTimeline.minuteperpx, 'minutes'));
          const duration = endtime.diffWithWorkdays(starttime, 'minutes', visibleTimeline.workdays);
          const width = Math.round(Math.max(0, duration / visibleTimeline.minuteperpx));

          return {
            bg,
            percent,
            xposition,
            width,
            height: (this.adaptWorkloadsHeight && percent < 100) ? percent : 100,
            opacity: Math.min(1, 0.25 + Math.max(0, percent - 80) / 75),
          };
        });
      },
    },
  };
</script>
