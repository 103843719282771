<template>
  <section class="px-4 pt-6 pb-0">
    <div class="mb-4"><b class="primary--text text-uppercase">{{ $t('INTEGRATIONS.CONNECT_TO_OTHER_APPLICATIONS') }}</b></div>
    <div v-for="(integrationName, integrationIndex) in availableIntegrations" :key="integrationIndex">
      <div class="display-flex align-center">
        <img v-if="integrationName == 'microsoft'"
             :style="{ filter: integrations[integrationName].state == 'connected' ? '' : 'grayscale(100%)',
                       opacity: ['loading', 'logging'].indexOf(integrations[integrationName].state) > -1 ? .5 : 1 }"
             src="../../img/integrations/outlook.png" style="width: 40px">
        <img v-else
             :style="{ filter: integrations[integrationName].state == 'connected' ? '' : 'grayscale(100%)',
                       opacity: ['loading', 'logging'].indexOf(integrations[integrationName].state) > -1 ? .5 : 1 }"
             src="../../img/integrations/gcal.png" style="width: 40px">
        <b class="primary--text text-uppercase ml-2">{{ $t(`INTEGRATIONS.${integrationName == 'microsoft' ? 'MICROSOFT_OUTLOOK' : 'GOOGLE_CALENDAR'}`) }}</b>
      </div>
      <div class="ml-12 mb-4">
        <div v-if="integrations[integrationName].state == 'inactive'">
          <v-btn color="accent" rounded @click="loginIntegration(integrationName)">
            <v-icon class="mr-2" small>fas fa-plus</v-icon> {{ $t(`INTEGRATIONS.CONNECT_${integrationName == 'microsoft' ? 'MICROSOFT' : 'GOOGLE'}`) }}
          </v-btn>
        </div>
        <div v-if="integrations[integrationName].state == 'loading'">
          <v-icon color="primary">fas fa-spinner fa-spin fa-2x fa-fw</v-icon>
        </div>
        <div v-if="integrations[integrationName].state == 'logging'" class="display-flex align-center">
          <v-icon color="primary">fas fa-spinner fa-spin fa-2x fa-fw</v-icon>
          <b class="mx-2">{{ $t('INTEGRATIONS.CONNECT_IN_NEW_WINDOW') }}</b> <a class="pointer" @click="loadIntegration(integrationName)">{{ $t('INTEGRATIONS.REFRESH') }}</a>
        </div>
        <div v-if="integrations[integrationName].state == 'connected'" class="display-flex align-center">
          <v-icon color="successgreen">fa fa-check</v-icon>
          <span class="ml-2">{{ $t('INTEGRATIONS.YOU_ARE_CONNECTED') }}</span>
          <v-spacer></v-spacer>
          <v-divider vertical></v-divider>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn icon small class="my-0" v-on="on" @click="logoutIntegration(integrationName)"><v-icon color="errorred" small>fas fa-sign-out-alt</v-icon></v-btn>
            </template>
            {{ $t('INTEGRATIONS.DISCONNECT') }}
          </v-tooltip>
        </div>
        <div v-if="integrations[integrationName].state == 'error'" class="display-flex align-center">
          <v-icon color="errorred">fas fa-exclamation-triangle</v-icon>
          <span class="ml-2" style="line-height: 1.2;">{{ integrations[integrationName].error }}</span>
        </div>
      </div>
      <div v-if="isConnected[integrationName]" class="ml-12">
        <b class="primary--text">{{ $t('GLOBAL.OPTIONS') }}</b>
        <div :style="{ opacity: isLoading[integrationName] ? '.5' : '' }" class="mt-2" style="display: flex; align-items: center"
             @click="! isLoading[integrationName] && toggleInteractionOption(integrationName, 'sync_milestones')">
          <i :class="integrationConfig[integrationName].sync_milestones ? 'fa-toggle-on successgreen--text' : 'fa-toggle-off'" class="fa fa-2x pointer"></i>
          <span class="ml-2" v-html="$t('INTEGRATIONS.SYNC_MILESTONES')"></span>
        </div>
        <div :style="{ opacity: isLoading[integrationName] ? '.5' : '' }" style="display: flex; align-items: center"
             @click="! isLoading[integrationName] && toggleInteractionOption(integrationName, 'sync_tasks')">
          <i :class="integrationConfig[integrationName].sync_tasks ? 'fa-toggle-on successgreen--text' : 'fa-toggle-off'" class="fa fa-2x pointer"></i>
          <span class="ml-2" v-html="$t('INTEGRATIONS.SYNC_TASKS')"></span>
        </div>
        <div class="mt-2">{{ $t('INTEGRATIONS.SYNC_DESCRIPTION') }}</div>
      </div>
      <div v-else class="ml-12">
        {{ $t(`INTEGRATIONS.${integrationName == 'microsoft' ? 'MICROSOFT_BENEFITS' : 'GOOGLE_BENEFITS'}`) }}
        <div v-if="! isPremium" class="mt-2 strong" v-html="$t('PREMIUM.INTEGRATIONS_IS_PREMIUM')"></div>
      </div>

      <v-divider class="my-6"></v-divider>
    </div>
    <web-calendar></web-calendar>
    <div class="pt-12"></div>
  </section>
</template>

<script>
  import WebCalendar from './WebCalendar';

  export default {
    components: {
      WebCalendar,
    },
    data() {
      return {
        integrations: [],
      };
    },
    computed: {
      availableIntegrations() {
        return this.$store.getters['users/accessRight/config'].availableIntegrations;
      },
      isConnected() {
        return {
          microsoft: this.$store.state.integrations.microsoft.outlook.connected,
          google: this.$store.state.integrations.google.gcal.connected,
        };
      },
      isLoading() {
        return {
          microsoft: this.$store.state.integrations.microsoft.outlook.loading,
          google: this.$store.state.integrations.google.gcal.loading,
        };
      },
      integrationConfig() {
        return {
          microsoft: this.$store.state.integrations.microsoft.outlook.config,
          google: this.$store.state.integrations.google.gcal.config,
        };
      },
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
    },
    created() {
      this.integrations = this.availableIntegrations.reduce((acc, integrationName) => { acc[integrationName] = this.newIntegration(); return acc; }, {});
      if (this.isPremium) {
        this.integrations = this.availableIntegrations.reduce((acc, integrationName) => { acc[integrationName] = this.newIntegration(); return acc; }, {});
        _.each(this.integrations, (integration, integrationName) => { this.loadIntegration(integrationName); });
      }
    },
    destroyed() {
      _.each(this.integrations, (integration) => {
        if (integration.pollWindowClosing) clearInterval(integration.pollWindowClosing);
      });
    },
    methods: {
      loadIntegration(integrationName) {
        const integration = this.integrations[integrationName];
        if (integration.pollWindowClosing) {
          clearInterval(integration.pollWindowClosing);
          integration.pollWindowClosing = null;
        }
        integration.state = 'loading';

        this.$store.dispatch(`integrations/${integrationName}/refreshConnection`).then((responseData) => {
          this.integrations[integrationName] = this.newIntegration({
            state: responseData.connected ? 'connected' : 'inactive',
            url: responseData.url,
          });
        }).catch((error) => {
          this.integrations[integrationName] = this.newIntegration({
            state: 'error',
            error,
          });
        });
      },
      loginIntegration(integrationName) {
        const integration = this.integrations[integrationName];
        if (integration.url) {
          integration.state = 'logging';
          const loginWindow = window.open(integration.url, "_blank");
          integration.pollWindowClosing = setInterval(() => {
            if (loginWindow && loginWindow.closed) this.loadIntegration(integrationName);
          }, 1000);
        }
      },
      toggleInteractionOption(integrationName, optionName) {
        const newConfig = { ...this.integrationConfig[integrationName] };
        newConfig[optionName] = ! newConfig[optionName];
        this.$store.dispatch(`integrations/${integrationName}/setConfig`, newConfig).catch((error) => {
          console.log(error);
        });
      },
      newIntegration(params) {
        return _.extend({ state: null, url: null, error: "", pollWindowClosing: null }, params);
      },
      logoutIntegration(integrationName) {
        this.integrations[integrationName].state = 'loading';
        this.$store.dispatch(`integrations/${integrationName}/logout`).then(() => {
          this.loadIntegration(integrationName);
        }).catch((error) => {
          this.integrations[integrationName] = this.newIntegration({
            state: 'error',
            error,
          });
        });
      },
    },
  };
</script>
