<template>
  <div>
    <div class="display-flex align-center mb-2">
      <div class="mr-2 text-center" style="flex: 0 0 24px;">
        <v-icon color="primary" small>{{ customField.icon }}</v-icon>
      </div>
      <label class="uppercase primary--text ma-0">{{ customField.label }}</label>
    </div>
    <div style="margin-left: 8px;">
      <draggable :list="checklist" :options="{ handle: '.checklist-draggable-anchor' }" @update="updateEl">
        <div v-for="(item, itemIndex) in checklist" class="mb-1 pl-2" style="position: relative">
          <div style="position: absolute; top: 32px; left: 36px; bottom: 0; width: 1px; background: rgba(0, 0, 0, 0.54)"></div>
          <div class="display-flex align-center hovervisible-activator">
            <div class="hovervisible-item checklist-draggable-anchor grab mr-2"></div>
            <v-checkbox v-model="item.checked" :ripple="false" hide-details class="pa-0 ma-0" color="successgreen" tabindex="-1" @change="itemChecked(item); updateEl()"></v-checkbox>
            <v-text-field v-model="item.title" :readonly="el.isFromTemplate()" hide-details class="pa-0 ma-0" style="flex: 1 1 100%">
            </v-text-field>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon small outlined color="errorred" class="hovervisible-item ml-2" v-on="on" @click="removeChecklistItem(itemIndex)">
                  <v-icon small>far fa-trash-alt</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('ELEMENT-DETAIL.DELETE_CHECKLIST_ITEM') }}</span>
            </v-tooltip>
          </div>
          <div class="display-flex ml-8 pl-4 mt-1">
            <v-textarea v-model="item.description" label="Description" persistent-placeholder rows="1" auto-grow hide-details></v-textarea>
          </div>
          <div class="display-flex ml-8 pl-4 mt-1">
            <date-field v-model="item.start" :label="$t('ELEMENT-DETAIL.FROM')" return-string hide-details></date-field>
            <date-field v-model="item.duedate" :label="$t('ELEMENT-DETAIL.TO')" return-string hide-details input-class="ml-4"></date-field>
          </div>
          <div class="ml-8 pl-4">
            <select-user v-model="item.users" :items="selectUserItems" placeholder="Responsables" class="pt-2" hide-details multiple
                         @input="addChecklistResponsibles($event)">
            </select-user>
          </div>
          <v-spacer class="mt-6"></v-spacer>
        </div>
      </draggable>
    </div>
    <form class="display-flex align-center ml-6 pl-2" @submit.prevent="addChecklistItem()">
      <v-checkbox :disabled="true" hide-details class="pa-0 ma-0"></v-checkbox>
      <v-text-field id="checklist" v-model="addChecklistItemTitle" label="Nouvelle sous-tâche" style="flex: 1 1 100%"></v-text-field>
      <v-btn :disabled="! addChecklistItemTitle" color="accent" small rounded type="submit">{{ $t('ELEMENT-DETAIL.ADD') }}</v-btn>
    </form>
  </div>
</template>

<script>
  import draggable from 'vuedraggable';

  export default {
    components: {
      draggable,
    },
    props: {
      el: { type: Object, required: true },
      customField: { type: Object, required: true },
    },
    data() {
      const { dataField } = this.customField;
      return {
        dataField,
        checklist: this.el.getCustomFields()?.[dataField] || [],
        addChecklistItemTitle: "",
      };
    },
    computed: {
      dynamicsContacts() {
        return this.el.getPlanning()?.config?.customFields?.dyn_contacts || [];
      },
      selectUserItems() {
        return this.$store.getters['users/getOrganizationUsers'].concat(this.dynamicsContacts);
      },
    },
    methods: {
      addChecklistItem() {
        if (! this.addChecklistItemTitle) return;
        const checklistItem = {
          msdyn_projecttaskid: window.crypto.randomUUID(),
          title: this.addChecklistItemTitle,
          description: '',
          start: '',
          duedate: '',
          checked: false,
          users: [],
        };
        this.checklist.push(checklistItem);
        this.updateEl();
        this.addChecklistItemTitle = "";
      },
      removeChecklistItem(index) {
        if (index > -1 && index < this.checklist.length) {
          this.checklist.splice(index, 1);
          this.updateEl();
        }
      },
      addChecklistResponsibles(users) {
        if (! users || ! users.length) return; // click on input clear button
        this.$emit('add-users', users);
      },
      itemChecked(item) {
        if (! item.checked) return;
        if (item.start) {
          if (! item.duedate) item.duedate = moment.max(moment(), moment(item.start)).format();
        } else {
          item.start = moment().format();
          item.duedate = moment().format();
        }
      },
      updateEl() {
        const customFields = this.el.getCustomFields() || {};
        this.$set(customFields, this.dataField, this.checklist);
        this.el.setCustomFields(customFields);
        this.$emit('update-progress');
      },
    },
  };
</script>
