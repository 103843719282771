<template>
  <section class="px-12 pt-6 pb-4">
    <div v-if="! user.id" class="pb-6 text-center">
      <v-icon large>fas fa-spinner fa-spin</v-icon>
    </div>
    <template v-else>
      <div class="pb-6 text-center">
        <div class="avatar-cropper" style="display: inline-block; position: relative">
          <croppa v-model="croppa" :placeholder="$t('ACCOUNT.SELECT_IMAGE')" :placeholder-font-size="16" :zoom-speed="7" :file-size-limit="20971520" :quality="1"
                  accept="image/*" prevent-white-space>
          </croppa>
          <div style="position: absolute; bottom: 0; right: -8px">
            <i :class="chosenFile ? 'pointer' : 'text-muted'" class="fa fa-lg fa-search-plus" @click="zoomIn()">
            </i><i :class="chosenFile ? 'pointer' : 'text-muted'" class="fa fa-lg fa-search-minus ml-2" @click="zoomOut()"></i>
          </div>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="display-flex justify-end mt-4">
        <v-btn color="secondary" rounded large outlined class="mx-2" @click="close">{{ $t('GLOBAL.CANCEL') }}</v-btn>
        <v-btn :disabled="! chosenFile" color="accent" rounded large class="mx-2" @click="uploadAvatar">
          <v-icon small class="mr-2">fas fa-upload</v-icon> {{ $t('ACCOUNT.SEND') }}
        </v-btn>
      </div>
    </template>
  </section>
</template>

<style>
  .avatar-cropper canvas, .avatar-cropper > * {
    border-radius: 50%;
  }
</style>

<script>
  import Croppa from 'vue-croppa';
  import 'vue-croppa/dist/vue-croppa.css';

  export default {
    components: {
      Croppa: Croppa.component,
    },
    data() {
      return {
        croppa: {},
      };
    },
    computed: {
      chosenFile() {
        return this.croppa.getChosenFile && this.croppa.getChosenFile();
      },
      user() { return this.$store.state.users.user; },
    },
    methods: {
      zoomIn() {
        if (! this.chosenFile) return;
        this.croppa.zoomIn();
      },
      zoomOut() {
        if (! this.chosenFile) return;
        this.croppa.zoomOut();
      },
      uploadAvatar() {
        if (! this.chosenFile) return;
        this.croppa.generateBlob((blob) => {
          if (! blob) return;
          blob.name = `${this.chosenFile.name.replace(/\..{3,4}$/, '')}.png`;
          window.apiSrv.uploadResource(blob).then((response) => {
            const id = response && response.data && response.data.file && response.data.file.id;
            if (! id) return Promise.reject(new Error('invalid upload'));
            return window.apiSrv.call('users', 'update', { id: this.user.id, avatar: response.data.file.url }).then(() => {
              this.user.avatar = response.data.file.url;
            });
          });
          this.close();
        }, 'image/png');
      },
      close() {
        this.$emit('close');
      },
    },
  };
</script>
