<template>
  <div>
    <div style="position: sticky; top: 50px; z-index: 1">
      <v-tabs v-model="currentTabIndex" background-color="white">
        <v-tab href="#projects">
          <span class="d-inline-flex" style="text-transform: none; font-size: 16px">
            <v-icon size="16" class="mr-1" style="color: inherit; transition: none; width: 24px">fas fa-list</v-icon>
            {{ $t('MANAGE.PROJECTS') }}
          </span>
        </v-tab>
        <v-tab v-for="project in openedProjects" :key="project.id" :href="`#project${project.id}`">
          <span style="text-transform: none;">{{ project.title || $t('PLANNING.UNNAMED_PROJECT') }}</span>
        </v-tab>
      </v-tabs>

      <v-divider class="mb-5"></v-divider>
    </div>

    <v-tabs-items v-model="currentTabIndex" class="mx-3" style="overflow: unset">
      <!-- TAB PROJECTS -->
      <v-tab-item :transition="false" :reverse-transition="false" value="projects">
        <manage-projects-list :data-loaded="dataLoaded" :projects="projects"
                              @open-project="openProject">
        </manage-projects-list>
      </v-tab-item>

      <!-- TAB OPEN PROJECTS-->
      <v-tab-item v-for="project in openedProjects" :key="project.id" :transition="false" :reverse-transition="false"
                  :value="`project${project.id}`">
        <button class="close" style="position: absolute; top: -16px; right: 0; z-index: 1; transition: opacity .3s" @click="closeProject(project)"><i class="fas fa-2x fa-times"></i></button>
        <manage-projects-project-acr ref="projectTabs" :project="project"></manage-projects-project-acr>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
  import ManageProjectsList from './ManageProjectsList';
  import ManageProjectsProjectAcr from './ManageProjectsProjectAcr';
  import ManageEventBus from './manage-event-bus';

  export default {
    components: {
      ManageProjectsList,
      ManageProjectsProjectAcr,
    },
    props: {
      dataLoaded: { type: Object, default: () => {} },
      projects: { type: Array, default: () => [] },
      openedProjects: { type: Array, default: () => [] },
    },
    data() {
      return {
        currentTabIndex: null,
      };
    },
    created() {
      ManageEventBus.$on('open-project-from-id', (projectId) => {
        this.openProjectFromId(projectId);
      });
      ManageEventBus.$on('on-update-project-tabs', (projectInfo) => {
        this.updateProjectTabs(projectInfo);
      });
    },
    methods: {
      openProject(project) {
        $(window).scrollTop(0);
        const projectIndex = this.openedProjects.indexOf(project);
        if (projectIndex == -1) this.openedProjects.push(project);
        this.$nextTick(() => {
          this.currentTabIndex = `project${project.id}`;
        });
        ManageEventBus.$emit('update-opened-group');
      },
      openProjectFromId(projectId) {
        if (! this.dataLoaded.projects) {
          setTimeout(() => { this.openProjectFromId(projectId); }, 300);
          return;
        }
        const project = this.projects.find(item => item.id == projectId);
        if (project) this.openProject(project);
      },
      closeProject(project) {
        const projectIndex = this.openedProjects.indexOf(project);
        if (projectIndex != -1) this.openedProjects.splice(projectIndex, 1);
        if (this.openedProjects[projectIndex]) {
          this.currentTabIndex = `project${this.openedProjects[projectIndex].id}`;
        } else if (this.openedProjects[projectIndex - 1]) {
          this.currentTabIndex = `project${this.openedProjects[projectIndex - 1].id}`;
        } else {
          this.currentTabIndex = `projects`;
        }
      },
      updateProjectTabs({ userId, projectId, data }) {
        const projectIndex = this.openedProjects.findIndex(item => item.id == projectId);
        if (projectIndex == -1) return;
        const projectTab = this.$refs.projectTabs[projectIndex];
        if (projectTab.onUpdateUserRightsInProject) projectTab.onUpdateUserRightsInProject({ userId, projectId, data });
      },
    },
  };
</script>
