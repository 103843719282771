import * as jsonpatch from 'fast-json-patch';
import ProjectSrv from '@/components/Projects/ProjectSrv';
import PlanningMeta from '@/models/PlanningMeta';
import PlanningConfig from '@/models/PlanningConfig';
import PlanningTimeline from '@/models/PlanningTimeline';
import PlanningLane from '@/models/PlanningLane';
import PlanningElement from '@/models/PlanningElement';
import { getDefaultProject } from '@/models/helpers/defaultValues';

class Planning {
  constructor(srcData) {
    let data;
    if (srcData instanceof Planning) {
      data = angular.copy(srcData) || {};
      _.extend(this, data);
    } else {
      data = { ...getDefaultProject(), ...srcData }; // avoiding bad performing angular.copy(srcData). srcData is still angular.copy in each submodel
      const topLevelProps = _.omit(data, 'meta', 'config', 'timeline', 'lanes', 'elements', 'lastSavedProjectVersion'); // keep id, projectsheet, ...
      _.extend(this, angular.copy(topLevelProps));
    }

    this.meta = new PlanningMeta(data.meta);
    this.config = new PlanningConfig(data.config);
    this.timeline = new PlanningTimeline(data.timeline);

    this.lanes = [];
    (data.lanes || []).forEach((lane) => {
      this.lanes.push(new PlanningLane(this, lane));
    });

    this.elements = [];
    (data.elements || []).forEach((elData) => {
      this.elements.push(new PlanningElement(this, elData));
    });

    this.lastSavedProjectVersion = this.toProject();
    if (! (data instanceof Planning)) {
      _.extend(this.lastSavedProjectVersion.content, {
        config: data.config, timeline: data.timeline, lanes: data.lanes, elements: data.elements,
      });
    }
  }

  update(content) {
    const modifications = { elements: {}, lanes: {} };
    if (! content) return modifications;
    if (content.id != this.id) {
      console.error(`trying to update planning ${this.id} with planning ${content.id}`);
      return modifications;
    }
    if (content.meta) angular.merge(this.meta, content.meta);
    const newProject = (new Planning(content)).toProject(); // ensure consistent format for lastSavedProjectVersion
    const contentPatch = this.getLastSavedVersionDiff(newProject);
    const elementIdRegex = /elements\/id=([^/]*)([/]?.*)/;
    const laneIdRegex = /lanes\/id=([^/]*)([/]?.*)/;
    const jsonPatchPlanningBase = {
      config: this.config,
      timeline: this.timeline,
      elements: {},
      lanes: {},
    };
    contentPatch.forEach((patch) => {
      if (patch.path.startsWith('/config/')) {
        if (patch.op == 'replace' && patch.path.match(/^\/config\/colors\/[0-9]+$/) && patch.value === null) return; // ignore model change {}->null
        if (patch.op == 'remove' && patch.path == '/config/icons') return; // ignore model change {}->undefined
        jsonpatch.applyOperation(jsonPatchPlanningBase, patch);
        modifications.config = true;
      }
      if (patch.path.startsWith('/timeline/')) {
        jsonpatch.applyOperation(jsonPatchPlanningBase, patch);
        modifications.timeline = true;
      }
      if (patch.path.startsWith('/elements/')) {
        const [, id, propertyPath] = elementIdRegex.exec(patch.path);
        if (! id) return;
        if (propertyPath) { // update el
          const element = this.elements.find(item => item.id == id);
          jsonPatchPlanningBase.elements[`id=${id}`] = element.getAll();
          // if (patch.op == 'remove') _.extend(patch, { op: 'replace', value: null }); // keep vuejs reactivity -> BUG in arrays
          jsonpatch.applyOperation(jsonPatchPlanningBase, patch);
          element.set(jsonPatchPlanningBase.elements[`id=${id}`]);
        } else if (patch.op == 'remove') { // remove el
          const elementIndex = this.elements.findIndex(item => item.id == id);
          if (elementIndex > -1) this.elements.splice(elementIndex, 1);
        } else if (patch.op == 'add') { // add el
          this.elements.push(new PlanningElement(this, patch.value));
        }
        modifications.elements[id] = true;
      }
      if (patch.path.startsWith('/lanes/')) {
        const [, id, propertyPath] = laneIdRegex.exec(patch.path);
        if (! id) return;
        if (propertyPath) { // update lane
          const lane = this.lanes.find(item => item.id == id);
          jsonPatchPlanningBase.lanes[`id=${id}`] = lane.getAll();
          // if (patch.op == 'remove') _.extend(patch, { op: 'replace', value: null }); // keep vuejs reactivity -> BUG in arrays
          jsonpatch.applyOperation(jsonPatchPlanningBase, patch);
          lane.set(jsonPatchPlanningBase.lanes[`id=${id}`]);
        } else if (patch.op == 'remove') { // remove lane
          const laneIndex = this.lanes.findIndex(item => item.id == id);
          if (laneIndex > -1) this.lanes.splice(laneIndex, 1);
        } else if (patch.op == 'add') { // add lane
          this.lanes.push(new PlanningLane(this, patch.value));
        }
        modifications.lanes[id] = true;
      }
      if (patch.op == 'order' && patch.path == '/lanes') {
        this.lanes.sort((a, b) => {
          const indexA = patch.value.indexOf(a.id);
          const indexB = patch.value.indexOf(b.id);
          if (indexA == -1 || indexB == -1) return 0;
          return indexA < indexB ? -1 : 1;
        });
      }
    });

    this.lastSavedProjectVersion = newProject; // must be data from content as planning (this) can be different (eg element opened and in modification)
    return modifications;
  }

  clone() {
    return new Planning(this);
  }

  /** ******** */
  /* ACTIONS */
  /** ******** */
  toProject() {
    return {
      id: this.id,
      title: this.getTitle(),
      content: {
        config: angular.copy(this.config.getAll()),
        timeline: angular.copy(this.timeline.getAll()),
        lanes: this.lanes.map(lane => angular.copy(lane.getAll())),
        elements: this.elements.filter(el => ! el.isDragPlaceholder && ! el.isResizePlaceholder).map(el => angular.copy(el.getAll())),
      },
    };
  }

  getLastSavedVersionDiff(currentProject) {
    const beforeVersion = angular.copy(this.lastSavedProjectVersion);
    const currentVersion = currentProject ? angular.copy(currentProject) : this.toProject();

    const beforeVersionLanesIds = beforeVersion.content.lanes.map(item => item.id);
    const currentVersionLanesIds = currentVersion.content.lanes.map(item => item.id);
    [beforeVersion, currentVersion].forEach((version) => {
      let acc = {};
      for (let i = 0, n = version.content.elements.length; i < n; i++) {
        const item = version.content.elements[i];
        if (item.type == 'task') delete item.width;
        acc[`id=${item.id}`] = item;
      }
      version.content.elements = acc;
      acc = {};
      for (let i = 0, n = version.content.lanes.length; i < n; i++) {
        const item = version.content.lanes[i];
        acc[`id=${item.id}`] = item;
      }
      version.content.lanes = acc;
    });

    const patch = jsonpatch.compare(beforeVersion.content, currentVersion.content);
    if (! angular.equals(beforeVersionLanesIds, currentVersionLanesIds)) patch.push({ op: "order", path: "/lanes", value: currentVersionLanesIds });
    return patch;
  }

  save() {
    this.elements.forEach((el) => {
      (el.alerts || []).forEach((alert) => {
        alert.updateAndSave(el);
      });
    });

    const currentProject = this.toProject();
    const contentPatch = this.getLastSavedVersionDiff(currentProject);

    if (! contentPatch.length && this.lastSavedProjectVersion.title == currentProject.title) return Promise.resolve(false);

    return ProjectSrv.save(currentProject, { contentPatch }).then(() => {
      this.lastSavedProjectVersion = currentProject;
      return true;
    });
  }

  saveView(viewId) {
    const promises = [];
    const currentProject = this.toProject();
    const contentPatch = this.getLastSavedVersionDiff(currentProject);

    const elementIdRegex = /elements\/id=([^/]*)([/]?.*)/;
    contentPatch.forEach((patch) => {
      if (patch.path.startsWith('/elements/')) {
        const [, id, propertyPath] = elementIdRegex.exec(patch.path);
        if (! id) return;

        if (propertyPath) { // update el
          const element = this.elements.find(item => item.id == id);
          if (element.access_right != 'modify') return;
          const promise = element.save(null, viewId, 'update').then(() => {
            const elInCurrentProject = currentProject.content.elements.find(item => item.id == id);
            if (elInCurrentProject) _.extend(elInCurrentProject, element.getAll()); // element.save may update the element with the api response
          });
          promises.push(promise);
        } else if (patch.op == 'remove') { // remove el
          const element = new PlanningElement(this, this.lastSavedProjectVersion.content.elements.find(item => item.id == id));
          const promise = element.save(null, viewId, 'destroy');
          promises.push(promise);
        } else if (patch.op == 'add') { // add el
          const element = this.elements.find(item => item.id == id);
          if (element.access_right != 'modify') return;
          const promise = element.save(null, viewId, 'store').then(() => {
            const elInCurrentProject = currentProject.content.elements.find(item => item.id == id);
            if (elInCurrentProject) _.extend(elInCurrentProject, element.getAll()); // element.save may update the element with the api response
          });
          promises.push(promise);
        }
      }
    });

    if (! promises.length) return Promise.resolve(false);

    return Promise.all(promises).then(() => {
      this.lastSavedProjectVersion = currentProject;
      return true;
    });
  }

  saveAdmin(data) {
    return ProjectSrv.saveAdmin(_.extend(angular.copy(data), { id: this.id }));
  }

  getTitle() {
    return this.meta && this.meta.title;
  }

  getUrl() {
    const title = this.getTitle() || "New project";
    const baseUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname || '/'}`;
    return `${baseUrl}#/planning/${this.id}/${window.slugify(title)}`;
  }

  toCsv(fields, separator = ';') {
    const lanes = {};
    const lanesIndex = {};
    this.lanes.forEach((lane, index) => {
      lanes[lane.id] = lane;
      lanesIndex[lane.id] = index;
    });
    let csvContent = "";
    const elements = this.elements.filter(el => lanes[el.getLaneId()]).sort(
      (a, b) => (lanesIndex[a.getLaneId()] - lanesIndex[b.getLaneId()]) || (a.getStartTime() < b.getStartTime() ? -1 : 1),
    );
    elements.forEach((el) => {
      const dataString = fields.map((field) => {
        let val = field.split('.').reduce((acc, subfield) => acc && acc[subfield] || "", el.data);
        if (field == 'lane') val = (lanes[el.getLaneId()] || {}).label || "";
        if (field == 'starttime' || field == 'endtime') val = moment(val).format('L');
        if (el.isType('milestone') && field == 'endtime') val = "";
        if (field.startsWith('schedule.')) val = val && moment(val).format('HH:mm');
        if (field == 'checklist' && val) val = val.map(a => a && a.title && `- ${a.title}` || '').join('\n');
        if (field == 'users' && val) val = val.map(a => a && a.email || a.title || '').join('\n');
        if (field == 'links' && val) val = val.map(a => a && a.url || '').join('\n');
        if (field == 'budgets' && val) {
          val = val.map((a) => {
            const inProgress = a && a.amount_inprogress && `${a.amount_inprogress}/` || '';
            return a && (inProgress + (a.amount || '') + (a.icon ? ` ${a.icon}` : '') + (a.state ? ` (${a.state})` : '')) || '';
          }).join('\n');
        }
        val = val.toString();
        return `"${["-", "+"].indexOf(val[0]) > -1 ? ' ' : ''}${val.replace(/"/g, '""')}"`;
      }).join(separator);
      csvContent += `${dataString}\n`;
    });
    return csvContent;
  }
}

export default Planning;
