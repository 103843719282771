import Vue from 'vue';

export default new Vue();

/*
  LIST OF EVENTS
  ManageEventBus.$emit('on-update-project-tabs', (projectInfo)); in Manage.vue
  ManageEventBus.$emit('on-update-user-tabs', (userInfo)); in Manage.vue
  ManageEventBus.$emit('on-update-opened-group'); in Manage.vue

  ManageEventBus.$emit('on-remove-user', user); in ManageUsers

  ManageEventBus.$emit('data-loaded-company', false); in ManageUsersList.vue
  ManageEventBus.$emit('data-loaded-company', true); in ManageUsersList.vue
  ManageEventBus.$emit('reload'); in ManageUsersList.vue

  ManageEventBus.$emit('update-opened-group'); in ManageProjects.vue

  ManageEventBus.$emit('remove-user', user); in ManageProjectsOrganization.vue
  ManageEventBus.$emit('reload'); in ManageProjectsOrganization.vue

  ManageEventBus.$emit('update-project-tabs', { projectId: project.id, userId: this.user.id, data: { right: newRight } }); in ManageUsersUserAcr.vue
  ManageEventBus.$emit('update-project-tabs', { projectId: project.id, userId: this.user.id, data: { notifications: project.notifications } }); in ManageUsersUserAcr.vue
  ManageEventBus.$emit('update-project-tabs', { projectId, userId: this.user.id, data: { right: refreshedProject.access_right, special_role: refreshedProject.special_role || "" } }); in ManageUsersUserAcr.vue
  ManageEventBus.$emit('update-project-tabs', { projectId, userId: ownerId, data: { right: "admin", special_role: "owner" } }); in ManageUsersUserAcr.vue
  ManageEventBus.$emit('update-user-tabs', { userId: ownerId, projectId, data: { right: "admin", special_role: "owner" } }); in ManageUsersUserAcr.vue
  ManageEventBus.$emit('change-project-owner', { projectId, ownerId }); in ManageUsersUserAcr.vue
  ManageEventBus.$emit('open-project-from-id', project.id); in ManageUsersUserAcr.vue

  ManageEventBus.$emit('update-user-tabs', { userId: user.id, projectId: this.project.id, data: { right: newRight } }); in ManageProjectsProjectAcr.vue
  ManageEventBus.$emit('update-user-tabs', { userId: user.id, projectId: this.project.id, data: { right: 'none' } }); in ManageProjectsProjectAcr.vue
  ManageEventBus.$emit('update-user-tabs', { userId: ownerId, projectId: this.project.id, data: { right: "admin", special_role: "owner" } }); in ManageProjectsProjectAcr.vue
  ManageEventBus.$emit('update-user-tabs', { userId: formerOwnerId, projectId: this.project.id, data: { right: formerOwner.access_right, special_role: formerOwner.special_role || "" } }); in ManageProjectsProjectAcr.vue
  ManageEventBus.$emit('change-project-owner', { projectId: this.project.id, ownerId }); in ManageProjectsProjectAcr.vue
  ManageEventBus.$emit('update-user-tabs', { userId: user.id, projectId: this.project.id, data: { notifications: user.notifications } }); in ManageProjectsProjectAcr.vue

  ManageEventBus.$emit('reload-projects'); in ManageConfigToolsSplitPlanning.vue
*/
