<template>
  <v-card class="home-user-tasks">
    <v-card-title class="pl-0">
      <div class="display-flex justify-center align-center mx-2" style="flex: 0 0 32px; font-size: 0.875rem;">
        <div style="width: 24px; position: relative; display: flex: flex-direction: column; border-radius: 4px; overflow: hidden">
          <div class="white--text text-center task-default-blue" style="height: 8px; width: 100%; line-height: 0;">...</div>
          <div style="height: 14px; width: 100%; background: rgba(216, 216, 253, 0.7);"></div>
          <div></div>
        </div>
      </div>
      <template v-if="myTasks.length || ! showNonAssignedTasks">
        <span class="text-h6 primary--text">{{ $t('HOME.MY_BUBBLES_NEWS') }}</span>
        <v-tooltip left max-width="200">
          <template #activator="{ on }">
            <v-icon color="info" class="ml-auto" v-on="on">far fa-question-circle</v-icon>
          </template>
          <span>{{ $t('HOME.BUBBLES_EXPLANATIONS') }}</span>
        </v-tooltip>
      </template>
      <template v-else>
        <span class="text-h6 primary--text">{{ $t('HOME.NON_ASSIGNED_BUBBLES_NEWS') }}</span>
        <v-tooltip left max-width="200">
          <template #activator="{ on }">
            <v-icon color="info" class="ml-auto" v-on="on">far fa-question-circle</v-icon>
          </template>
          <span>{{ $t('HOME.NON_ASSIGNED_BUBBLES_EXPLANATIONS') }}</span>
        </v-tooltip>
      </template>
    </v-card-title>
    <div class="ml-12" style="border-bottom: 2px solid #0169aa;"></div>
    <v-card-text class="pl-12">
      <div v-if="! myTasks.length && ! showNonAssignedTasks">
        <div class="muted--text">
          {{ $t('HOME.NO_ASSIGNED_BUBBLE_ACTIVITY') }}
        </div>
        <div class="py-2"><a @click="showNonAssignedTasks = true">{{ $t('HOME.SHOW_NON_ASSIGNED_BUBBLES_NEWS') }}</a></div>
      </div>
      <div v-else-if="! tasks.length" class="muted--text">
        {{ $t('HOME.NO_BUBBLE_ACTIVITY') }}
      </div>
      <div v-else>
        <div class="display-flex flex-wrap pb-4">
          <div v-for="col in taskLists" v-if="tasksPerCol[col.id].length" :key="col.id" class="px-1" style="flex: 0 0 50%; position: relative">
            <div :class="`${col.color}--text`"
                 class="ml-1 mb-1 pb-1 display-flex align-center" style="border-bottom-width: 1px; border-bottom-style: solid">
              <v-icon small class="mr-1" style="color: inherit; flex: 0 0 28px">{{ col.icon }}</v-icon>
              {{ $t(col.label) }}
            </div>
            <transition-group name="list-fade" tag="ul">
              <template v-for="(el, index) in filteredOrderedTasksPerCol[col.id]">
                <li v-if="! index || el.project_id != filteredOrderedTasksPerCol[col.id][index - 1].project_id" :key="`project${el.project_id}`"
                    class="ml-2 muted--text">
                  <v-icon small>fas fa-folder-open</v-icon>
                  <router-link :to="projectUrl(el)" style="color: inherit">
                    <i class="small">{{ el.project_title }}</i>
                  </router-link>
                </li>
                <li :key="el.id" class="text-center list-fade-item mb-2" style="width: 100%">
                  <v-row no-gutters>
                    <v-col :class="{ strong: col.id == 'starts_soon' }" cols="2" class="nobr" style="font-size: 75%">
                      {{ el.getStartTime() | moment('mediumDateNoYear') }}
                    </v-col>
                    <v-col cols="8">
                      <planning-element-view :id="`el${el.id}`" :el="el" :title="el.display_title || ''" :class="{ 'can-edit': canEditEl(el) }"
                                             style="cursor: default; position: relative; font-size: 75%" disable-mini-element
                                             @checklist-click="checklistClick(el, $event)" @progress-click="progressClick(el, $event)"
                                             @dyn-subtasks-click="dynSubtasksClick(el, $event)">
                        <template v-if="canEditEl(el)" #hover-menu>
                          <div class="element-menu" style="left: 100%; width:52px">
                            <div class="display-flex" style="position: absolute; bottom: 0;">
                              <v-btn v-if="el.getProgress() != 100" :title="$t('MONITORING_PROGRESS.VALIDATE_PROGRESS')"
                                     icon x-small outlined color="midgrey lighten-1" style="margin-right: 2px !important"
                                     @click="validateProgress(el)">
                                <v-icon color="accent" size="14">fas fa-check</v-icon>
                              </v-btn>
                              <v-btn :title="$t('HOME.OPEN_IN_PLANNING')" :to="elementUrl(el)"
                                     icon x-small outlined color="midgrey lighten-1">
                                <v-icon color="primary" size="14">fas fa-external-link</v-icon>
                              </v-btn>
                            </div>
                          </div>
                        </template>
                      </planning-element-view>
                    </v-col>
                    <v-col :class="{ strong: col.id == 'ends_soon' }" cols="2" class="nobr" style="font-size: 75%">
                      {{ el.getEndTime() | moment('mediumDateNoYear') }}
                    </v-col>
                  </v-row>
                </li>
              </template>
              <li v-if="tasksPerCol[col.id].length > showItemsLimit" key="seemore" class="pointer mt-2 text-center primary--text list-fade-item-fast"
                  @click="showAllItems[col.id] = ! showAllItems[col.id]">
                <i :class="showAllItems[col.id] ? 'fa-chevron-circle-up' : 'fa-chevron-circle-down'" class="fa"></i>
                {{ showAllItems[col.id] ? $t('HOME.REDUCE') : $tc('HOME.SEE_MORE', tasksPerCol[col.id].length - showItemsLimit) }}
              </li>
            </transition-group>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<style>
  .home-user-tasks .element.can-edit .checklist .far, .home-user-tasks .element.can-edit .users-bar .v-progress-linear {
    cursor: pointer;
  }
</style>

<script>
  import PlanningElementView from '@/components/Planning/PlanningElementView';

  export default {
    components: {
      PlanningElementView,
    },
    props: {
      elements: { type: Array, required: true },
      nonAssignedTasks: { type: Array, default: () => ([]) },
    },
    data() {
      return {
        taskLists: [
          { id: 'starts_soon', label: "HOME.STARTS_SOON", color: 'successgreen', icon: 'fas fa-rocket', orderBy: 'data.starttime' },
          { id: 'ends_soon', label: "HOME.ENDS_SOON", color: 'warningorange', icon: 'fas fa-clock', orderBy: 'data.endtime' },
          { id: 'current', label: "HOME.CURRENT", color: 'primary', icon: 'fas fa-hourglass-half', orderBy: 'data.endtime' },
        ],
        showAllItems: { starts_soon: false, ends_soon: false, current: false },
        showItemsLimit: 3,
        showNonAssignedTasks: false,
      };
    },
    computed: {
      myTasks() {
        return this.elements.filter(item => item.isType('task'));
      },
      tasks() {
        return this.myTasks.length ? this.myTasks : this.nonAssignedTasks;
      },
      tasksPerCol() {
        return this.taskLists.reduce((acc, col) => {
          acc[col.id] = this.tasks.filter(item => item.status == col.id).sort((a, b) => {
            if (col.orderBy == 'data.starttime') return (a.getStartTime().isBefore(b.getStartTime())) ? -1 : 1;
            if (col.orderBy == '-data.starttime') return (b.getStartTime().isBefore(a.getStartTime())) ? -1 : 1;
            if (col.orderBy == 'data.endtime') return (a.getEndTime().isBefore(b.getEndTime())) ? -1 : 1;
            if (col.orderBy == '-data.endtime') return (b.getEndTime().isBefore(a.getEndTime())) ? -1 : 1;
            return 0;
          });
          return acc;
        }, {});
      },
      filteredOrderedTasksPerCol() {
        return this.taskLists.reduce((acc, col) => {
          acc[col.id] = this.showAllItems[col.id] ? this.tasksPerCol[col.id].slice() : this.tasksPerCol[col.id].slice(0, this.showItemsLimit);
          const projectIds = acc[col.id].map(item => item.project_id);
          acc[col.id].sort((a, b) => (projectIds.indexOf(a.project_id) < projectIds.indexOf(b.project_id) ? -1 : 1));
          return acc;
        }, {});
      },
    },
    created() {
      if (this.tasks.length) {
        const planning = this.tasks[0].getPlanning();
        if (planning) planning.config.setDesign();
        moment.locale(this.$store.state.lang.lang);

        this.tasks.forEach((el) => {
          el.display_title = `${el.project_title}\n↪ ${el.lane_label}`;
          if (this.$store.state.users.user.id) el.filter_user = this.$store.state.users.user.id; // used to hide other users actions
        });
      }
    },
    methods: {
      canEditEl(el) {
        const planning = el.getPlanning();
        return planning && planning.meta && planning.meta.access_right && planning.meta.access_right != 'consult';
      },
      checklistClick(el, item) {
        if (! this.canEditEl(el)) return;
        const oldStateParam = { checklist: angular.copy(el.getChecklist()), progress: el.getProgress() };
        this.$set(item, 'checked', ! item.checked);
        // el.updateChecklistProgress();
        this.$emit('save-el', { el, props: ['checklist', 'progress'], oldStateParam });
      },
      dynSubtasksClick(el, item) {
        if (! this.canEditEl(el)) return;
        const oldStateParam = { customFields: angular.copy(el.getCustomFields()), progress: el.getProgress() };
        this.$set(item, 'checked', ! item.checked);
        el.updateChecklistProgress();
        this.$emit('save-el', { el, props: ['customFields', 'progress'], oldStateParam });
      },
      progressClick(el, event) {
        if (! this.canEditEl(el)) return;
        const oldStateParam = { progress: el.getProgress() };
        const fullWidth = $(event.currentTarget).width();
        if (! fullWidth) return;
        el.setProgress(Math.min(Math.round(event.offsetX / fullWidth * 10) * 10, 100));
        if (el.getProgress() != oldStateParam.progress) {
          this.$emit('save-el', { el, props: ['progress'], oldStateParam });
        }
      },
      validateProgress(el) {
        if (! this.canEditEl(el)) return;
        const oldStateParam = { progress: el.getProgress() };
        el.setProgress(100);
        this.$emit('save-el', { el, props: ['progress'], oldStateParam });
      },
      projectUrl(el) {
        const { project_id: projectId, project_title: projectTitle, project_access_right: projectAccessRight } = el;
        const isConsult = projectAccessRight == 'consult';
        return `/${isConsult ? 'viewer/' : 'planning/'}${projectId}/${window.slugify(projectTitle || "New project")}`;
      },
      elementUrl(el) {
        return `${this.projectUrl(el)}?el=${parseInt(el.id, 10)}`;
      },
    },
  };
</script>
