<template>
  <v-card class="actions-group" style="page-break-inside: avoid;" @click.native="closeAllEditing">
    <!-- CARD TITLE  -->
    <draggable :options="{ disabled: ! dragEnabled, handle: 'nope', group: { name: 'draggableActions', put: true } }">
      <div class="primary--text py-2 pl-6 pr-2" style="border-bottom: 3px solid;">
        <div class="display-flex align-center">
          <v-avatar v-if="groupByUser && userAvatar(firstLevelId)" size="24" class="mr-2"><img :src="userAvatar(firstLevelId)"></v-avatar>
          <v-icon v-else-if="groupByUser && isDeletedUser" size="16" class="mr-2 color-inherit">fas fa-user-slash</v-icon>
          <v-icon v-else-if="groupByUser" size="16" class="mr-2 color-inherit">fas fa-user</v-icon>
          <span class="text-subtitle-1">{{ groupByUser ? userTitle(firstLevelId) : groupByGroup ? companyGroupTitle(firstLevelId) : planningTitle(firstLevelId) }}</span>
          <v-spacer></v-spacer>
          <template v-if="groupByUser && ! isActionVirtualUser && ! isDeletedUser || firstLevelId == 'none'">
            <v-divider vertical class="mx-2 export-hidden"></v-divider>
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn icon class="export-hidden" v-on="on"
                       @click="$emit('create-action', { userId: groupByUser && firstLevelId != 'none' && firstLevelId })">
                  <v-icon color="primary">fas fa-plus-circle</v-icon>
                </v-btn>
              </template>
              {{ $t(firstLevelId == 'none' ? 'MONITORING_ACTIONS.ADD_ACTION' : 'MONITORING_ACTIONS.ADD_ACTION_TO_USER') }}
            </v-tooltip>
          </template>
          <template v-if="groupByUser || firstLevelId != 'none'">
            <v-divider vertical class="mx-2 export-hidden"></v-divider>
            <v-btn class="export-hidden ma-0" icon @click="hideCard">
              <v-icon color="primary" small>fas fa-eye-slash</v-icon>
            </v-btn>
          </template>
        </div>
        <v-expand-transition>
          <div v-if="dragEnabled && selectedAction && selectedActionUserId != firstLevelId" class="drop-zone text-center white primary--text mb-4 mr-4 mt-4">
            <div style="position: absolute;">
              <i class="fa fa-arrow-right"></i><i :class="groupByGroup ? 'fas fa-users fa-lg ml-2' : 'fas fa-user fa-lg ml-2'"></i>
            </div>
            <draggable :options="{ group: { name: 'draggableActions', put: true } }" :data-id="firstLevelId" class="drop-zone-item"
                       @add="groupByGroup ? dropActionOnGroup($event) : dropActionOnUser($event)">
            </draggable>
          </div>
        </v-expand-transition>
      </div>
    </draggable>

    <!-- ACTIONS LIST -->
    <div class="pb-2" style="height: 100%; overflow-y: auto; background: #fff">
      <div v-for="(secondLevelActions, secondLevelId) in actionsGroup" :key="secondLevelId" class="white pt-2 px-2">
        <div class="primary--text py-1">
          <span class="pointer" @click="hiddenTiles[secondLevelId] = ! hiddenTiles[secondLevelId]">
            <i :class="hiddenTiles[secondLevelId] ? 'fa-plus-square' : 'fa-minus-square'" class="far fa-fw pointer"></i>
            {{ groupByPlanning ? userTitle(secondLevelId) : planningTitle(secondLevelId) }}
          </span>
        </div>
        <draggable v-if="! hiddenTiles[secondLevelId]" :list="draggableCopy(secondLevelActions)"
                   :options="{ disabled: ! dragEnabled, handle: '.draggable-anchor', group: { name: 'draggableActions', put: false }, sort: false }"
                   style="min-height: 10px"
                   @start="startDragAction($event)" @end="endDragAction()">
          <actions-dashboard-card-item v-for="action in secondLevelActions" :key="action.id" :is-draggable="dragEnabled"
                                       :action="action" :editing="editing" :data-id="action.id" style="page-break-inside: avoid;"
                                       @set-editing="setEditing" @open-action="$emit('open-action', action)">
          </actions-dashboard-card-item>
        </draggable>
        <div v-if="groupStats.workload_used || groupStats.workload" class="mt-2 mx-auto" style="width: 90%;">
          <div class="primary--text text-right small pt-1" style="border-top: 1px solid #ccc">
            {{ $t('GLOBAL.SUBTOTAL') }} : {{ groupStats[secondLevelId].workload || 0 | number('0.[00]') }}
            &nbsp;<i class="fas fa-caret-right"></i>&nbsp;
            {{ groupStats[secondLevelId].workload_used || 0 | number('0.[00]') }}
          </div>
        </div>
      </div>
      <div v-if="! hasActions" class="text-center text-muted pa-4">{{ $t('MONITORING_ACTIONS.NO_ACTION') }}</div>
    </div>

    <!-- GROUP USERS LIST -->
    <draggable v-if="groupByGroup && usersByGroup.length && ! groupsOptions.hideUsers"
               :options="{ disabled: ! dragEnabled, handle: 'nope', group: { name: 'draggableActions', put: true } }">
      <v-divider class="mx-4"></v-divider>
      <div class="display-flex align-center flex-wrap">
        <div v-for="user in usersByGroup" :key="user.id">
          <v-expand-transition>
            <div :class="dragEnabled && selectedAction ? 'drop-zone' : ''" class="white primary--text my-1 mx-2 pa-1">
              <draggable :options="{ group: { name: 'draggableActions', put: true } }" :data-id="user.id" class="drop-zone-item" style="height: 35px;"
                         @add="dropActionOnUser">
                <div class="drop-zone-username display-flex align-center">
                  <span :style="{ visibility : dragEnabled && selectedAction ? 'visible' : 'hidden' }" class="mr-1"><i class="fa fa-arrow-right"></i></span>
                  <user-workload-indicator :user="user" :starttime="selectedAction ? selectedAction.getStartTime() : null" :endtime="selectedAction ? selectedAction.getEndTime() : null"
                                           small bottom hide-zero class="mr-1">
                  </user-workload-indicator>
                  <img v-if="user.avatar" :src="user.avatar" style="max-height: 30px; border-radius: 50%;">
                  <v-icon v-else class="color-inherit">fas fa-user</v-icon>
                  <span class="mx-1">{{ user | username('short') }}</span>
                </div>
              </draggable>
            </div>
          </v-expand-transition>
        </div>
      </div>
    </draggable>

    <!-- TOTAL WORKLOAD -->
    <div class="mx-4">
      <v-divider></v-divider>
      <div class="primary--text text-right py-2">
        <strong>{{ $t('GLOBAL.TOTAL') | uppercase }} : {{ groupStats.workload || 0 | number('0.[00]') }}</strong>
        <i class="mx-1 fas fa-caret-right"></i>
        <strong>{{ groupStats.workload_used || 0 | number('0.[00]') }}</strong>
      </div>
    </div>
  </v-card>
</template>

<style>
  #actionsdashboard .actions-group {
    width: 460px;
    max-width: 600px;
    margin-right: 15px;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
  }
  #actionsdashboard .actions-group:last-child {
    margin-right: 0;
  }

  #actionsdashboard .drop-zone {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 2px dashed #999;
  }
  #actionsdashboard .drop-zone-item {
    height: 50px;
    flex: 1 0 100%;
    display: flex;
    align-items: center;
  }
  #actionsdashboard .drop-zone-item > * {
    flex: 1 0 100%;
    background: white;
    z-index: 1;
  }
  #actionsdashboard .actions-group > div > .sortable-ghost {
    display: none !important;
  }
  #actionsdashboard .drop-zone-item .sortable-ghost + .drop-zone-username {
    display: none !important;
  }
  #actionsdashboard .drop-zone-item .action-item .action-item-text {
    white-space: nowrap;
  }
</style>

<script>
  import { mapState } from 'vuex';
  import draggable from 'vuedraggable';
  import workloadMixin from '@/components/Workload/workloadMixin';
  import UserWorkloadIndicator from '@/components/Workload/UserWorkloadIndicator';
  import ActionsDashboardCardItem from './ActionsDashboardCardItem';

  export default {
    components: {
      draggable,
      UserWorkloadIndicator,
      ActionsDashboardCardItem,
    },
    mixins: [workloadMixin],
    props: {
      planningActions: { type: Array, required: true },
      firstLevelId: { type: String, required: true },
      groupBy: { type: String, default: 'user' },
      selectedAction: { type: Object, default: null },
    },
    data() {
      return {
        hiddenTiles: {},
        editing: { title: null, workload: null },
      };
    },
    computed: {
      groupByUser() {
        return this.groupBy == 'user';
      },
      groupByPlanning() {
        return this.groupBy == 'planning';
      },
      groupByGroup() {
        return this.groupBy == 'group';
      },
      isActionVirtualUser() {
        return this.selectedActionVirtualParticipants.includes(this.firstLevelId);
      },
      isDeletedUser() {
        return this.selectedActionDeletedUsers.includes(+this.firstLevelId);
      },
      usersByGroup() {
        return this.getGroupById(this.firstLevelId).users;
      },
      selectedActionUserId() {
        return this.selectedAction ? this.selectedAction.getUserId() || this.selectedAction.getGroupId() : null;
      },
      actionsGroup() {
        const colorsOrder = ["orange", "red"];
        const actions = {};
        this.planningActions.filter((action) => {
          if (this.groupByUser) {
            if (action.getGroupId()) return false;
            const userId = action.getUserId() || action.getUsername() || 'none';
            return this.firstLevelId == userId;
          }
          if (this.groupByGroup) {
            if (! action.getGroupId()) return false;
            const groupId = action.getGroupId() || 'none';
            return this.firstLevelId == groupId;
          }
          if (action.getGroupId()) return false;
          const planningId = action.getPlanningId() || 'none';
          return this.firstLevelId == planningId;
        }).forEach((action) => {
          const userId = action.getUserId() || action.getUsername() || 'none';
          const planningId = action.getPlanningId() || 'none';
          const secondLevelId = this.groupByPlanning ? userId : planningId;
          if (! actions[secondLevelId]) actions[secondLevelId] = [];
          actions[secondLevelId].push(action);
        });
        Object.keys(actions).forEach((key) => {
          actions[key].sort((actionA, actionB) => {
            const colorSort = colorsOrder.indexOf(actionB.getCssClass()) - colorsOrder.indexOf(actionA.getCssClass());
            if (colorSort) return colorSort;
            const actionBEndTime = actionB.getEndTime();
            const actionAEndTime = actionA.getEndTime();
            if (! actionBEndTime) return -1;
            if (! actionAEndTime) return 1;
            return actionBEndTime.isAfter(actionAEndTime) ? -1 : 1;
          });
        });
        return actions;
      },
      hasActions() {
        return ! _.isEmpty(this.actionsGroup);
      },
      groupStats() {
        const stats = { workload: 0, workload_used: 0 };
        Object.keys(this.actionsGroup).forEach((secondLevelKey) => {
          stats[secondLevelKey] = { workload: 0, workload_used: 0 };
          this.actionsGroup[secondLevelKey].forEach((action) => {
            stats[secondLevelKey].workload += parseFloat(this.workloadToDisplay(action.getWorkload()), 10) || 0;
            stats[secondLevelKey].workload_used += parseFloat(this.workloadToDisplay(action.getWorkloadUsed()), 10) || 0;
          });
          stats.workload += stats[secondLevelKey].workload;
          stats.workload_used += stats[secondLevelKey].workload_used;
        });
        return stats;
      },
      dragEnabled() {
        return ! this.groupByPlanning;
      },
      getGroupById() {
        return this.$store.getters['users/groups/getGroupById'];
      },
      ...mapState('multiprojects', ['projects', 'selectedProjects', 'companyUsers', 'actionDeletedUsers', 'selectedCompanyUsers', 'selectedActionDeletedUsers',
                                    'selectedCompanyGroups', 'selectedVirtualParticipants', 'selectedActionVirtualParticipants', 'filterOptions', 'groupsOptions']),
    },
    created() {
      this.setHiddenTiles();
    },
    updated() {
      this.setHiddenTiles();
    },
    methods: {
      userTitle(id) {
        if (id == 'none') return this.$t('MONITORING_ACTIONS.NOT_ASSIGNED');
        let companyUser = this.companyUsers.find(item => item.id == id);
        if (! companyUser) companyUser = this.actionDeletedUsers.find(item => item.id == id);
        return companyUser ? companyUser.username : id;
      },
      companyGroupTitle(id) {
        const companyGroup = this.getGroupById(id);
        return companyGroup ? companyGroup.title : id;
      },
      planningTitle(id) {
        if (id == 'none') return this.$t('MONITORING_ACTIONS.NOT_IN_PLANNING');
        const project = this.projects.find(item => item.id == id);
        return project ? (project.getTitle() || this.$t('PLANNING.UNNAMED_PROJECT')) : this.$t('MONITORING_ACTIONS.NOT_IN_PLANNING');
      },
      userAvatar(id) {
        if (id == 'none') return null;
        const companyUser = this.companyUsers.find(item => item.id == id);
        return companyUser ? companyUser.avatar : null;
      },
      setHiddenTiles() {
        Object.keys(this.actionsGroup).forEach((secondLevelKey, index) => {
          if (typeof this.hiddenTiles[secondLevelKey] == 'undefined') {
            this.$set(this.hiddenTiles, secondLevelKey, index && true || false);
          }
        });
      },
      setEditing({ prop, id }) {
        this.closeAllEditing();
        this.editing[prop] = id;
      },
      closeAllEditing() {
        Object.keys(this.editing).forEach((key) => { this.editing[key] = null; });
      },
      hideCard() {
        if (this.groupByUser) {
          if (this.firstLevelId == 'none') {
            this.filterOptions.unassigned = false;
            return;
          }
          let index = this.selectedCompanyUsers.findIndex(item => item == this.firstLevelId);
          if (index > -1) {
            this.selectedCompanyUsers.splice(index, 1);
            return;
          }
          index = this.selectedActionVirtualParticipants.findIndex(item => item == this.firstLevelId);
          if (index > -1) {
            this.selectedActionVirtualParticipants.splice(index, 1);
            return;
          }
          index = this.selectedActionDeletedUsers.findIndex(item => item == this.firstLevelId);
          if (index > -1) this.selectedActionDeletedUsers.splice(index, 1);
        } else if (this.groupByGroup) {
          const index = this.selectedCompanyGroups.findIndex(item => item == this.firstLevelId);
          if (index > -1) this.selectedCompanyGroups.splice(index, 1);
        } else {
          const index = this.selectedProjects.findIndex(item => item == this.firstLevelId);
          if (index > -1) this.selectedProjects.splice(index, 1);
        }
      },
      draggableCopy(array) {
        return array.slice();
      },
      startDragAction(event) {
        const actionId = event.item.dataset && event.item.dataset.id;
        const action = this.planningActions.find(item => item.id == actionId);
        this.$emit('selected-action', action);
      },
      endDragAction() {
        this.$emit('selected-action', null);
      },
      dropActionOnUser(event) {
        const actionId = event.item.dataset && event.item.dataset.id;
        const action = this.planningActions.find(item => item.id == actionId);
        const ownerId = event.target.dataset && event.target.dataset.id;
        action.setUser({ id: ownerId, username: this.$store.getters['users/getUsername']({ id: ownerId }, 'short') });
        this.saveAction(action);
      },
      dropActionOnGroup(event) {
        const actionId = event.item.dataset && event.item.dataset.id;
        const action = this.planningActions.find(item => item.id == actionId);
        const ownerId = event.target.dataset && event.target.dataset.id;
        action.setGroup({ id: ownerId, username: this.getGroupById(ownerId)?.title });
        this.saveAction(action);
      },
      saveAction(action) {
        this.$store.commit('ui/saving/start');
        action.save().then(() => {
          this.$store.commit('ui/saving/success');
          this.$emit('update-original-el', action);
        }).catch((message) => {
          this.$store.commit('ui/saving/error', message);
        });
      },
    },
  };
</script>
