<template>
  <v-tooltip :disabled="dropdownIsOpen" bottom>
    <template #activator="{ on: onTooltip }">
      <v-menu v-model="dropdownIsOpen" :close-on-content-click="false" offset-y left>
        <template #activator="{ on: onMenu }">
          <v-btn icon class="tour-navigate-timeline" v-on="{ ...onTooltip, ...onMenu }">
            <v-icon size="18">fas fa-search</v-icon>
          </v-btn>
        </template>
        <v-list class="pt-4 pb-8">
          <v-list-item class="d-block">
            <b>{{ $t('NAV.NAVIGATE_TIMELINE') }}</b>
            <v-select v-model="selectedCol" :items="visibleTimelineCols" :item-text="(col) => col.label"
                      :placeholder="$t('NAV.GO_TO_DATE')" menu-props="offset-y" return-object hide-details dense class="pt-0"
                      @change="gotoDate()">
            </v-select>
          </v-list-item>
          <v-divider class="my-4"></v-divider>
          <v-list-item class="d-block">
            <b>{{ $t('NAV.LOOK_FOR_BUBBLE') }}</b>
            <v-autocomplete v-model="selectedElement" :items="planning.elements" :item-text="(el) => el.getTitle() || defaultTitle(el)" item-value="id"
                            :filter="inliFilterElements"
                            :placeholder="$t('GLOBAL.SEARCH') + '...'" return-object hide-details dense autofocus class="pt-0"
                            @change="openSelectedElement()">
              <template #item="data">
                <span class="text-truncate" style="max-width: 200px" v-html="data.parent.genFilteredText(data.item.getTitle() || defaultTitle(data.item))"></span>
                <small class="ml-2 text-muted">
                  {{ data.item.getStartTime() | moment('mediumDateNoYear', true) }}
                </small>
                <small v-if="! data.item.isType('milestone')" class="text-muted">
                  - {{ data.item.getEndTime() | moment('mediumDateNoYear', true) }}
                </small>
              </template>
            </v-autocomplete>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <span>{{ $t('NAV.SEARCH_ON_PLANNING') }}</span>
  </v-tooltip>
</template>

<script>
  export default {
    props: {
      planning: { type: Object, required: true },
    },
    data() {
      return {
        selectedCol: null,
        selectedElement: null,
        dropdownIsOpen: null,
      };
    },
    computed: {
      visibleTimelineCols() {
        return this.planning.visibleTimeline && this.planning.visibleTimeline.timelinecols || null;
      },
    },
    methods: {
      gotoDate() {
        const col = this.selectedCol;
        const { visibleTimeline } = this.planning;
        if (! col || ! visibleTimeline) return;
        const duration = moment(col.starttime).diffWithWorkdays(visibleTimeline.starttime, 'minutes', visibleTimeline.workdays);
        $("#table-wrapper").scrollLeft(duration / visibleTimeline.minuteperpx - 200);
        this.dropdownIsOpen = false;
        this.$nextTick(() => {
          this.selectedCol = null;
        });
      },
      openSelectedElement() {
        this.$emit('open-element', this.selectedElement);
        this.dropdownIsOpen = false;
        this.$nextTick(() => {
          this.selectedElement = null;
        });
      },
      // INLI
      inliFilterElements(item, queryText, itemText) {
        if (item.getCustomFields()?.mcs_tachemodeleid && item.getCustomFields()?.mcs_tachemodeleid.includes(queryText)) return true;
        if (item.getCustomFields()?.msdyn_projecttaskid && item.getCustomFields()?.msdyn_projecttaskid.includes(queryText)) return true;
        return itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
      },
      // /INLI
      defaultTitle(el) {
        if (el.isType('macro')) return this.$t('PLANNING.NEW_MACRO_BUBBLE');
        return el.isType('milestone') ? this.$t('PLANNING.MILESTONE') : this.$t('PLANNING.NEW_BUBBLE');
      },
    },
  };
</script>
