<template>
  <div>
    <div class="display-flex align-center mb-2">
      <div class="mr-2 text-center" style="flex: 0 0 24px;">
        <v-icon color="primary" small>fa fa-sitemap</v-icon>
      </div>
      <label class="uppercase primary--text ma-0">{{ $t('ELEMENT-DETAIL.SUB_TASKS') }}</label>
      <v-spacer></v-spacer>
      <v-divider vertical class="mx-2"></v-divider>
      <v-btn :title="$t(el.getConfig()['show-subTasks'] ? 'ELEMENT-DETAIL.HIDE_ON_PLANNING' : 'ELEMENT-DETAIL.SHOW_ON_PLANNING')" icon small tabindex="-1"
             @click="el.getConfig()['show-subTasks'] = ! el.getConfig()['show-subTasks']">
        <v-icon small>{{ el.getConfig()['show-subTasks'] ? 'fa-eye' : 'fa-eye-slash' }}</v-icon>
      </v-btn>
    </div>
    <div class="ml-6 pl-2">
      <div class="display-flex mb-5">
        <!-- LANES -->
        <v-select v-model="selectedLanesList" :items="availableLanes" :label="$t('EXPORT.LANES')" :loading="! planning"
                  :placeholder="selectedLanesList.length ? '' : $t('IMPORT_TEMPLATE.SELECT_LANES')"
                  item-value="id" item-text="label"
                  prepend-icon="fas fa-filter" multiple hide-details>
          <template #prepend-item>
            <filter-toggle :selected-ids="selectedLanesList" :sublist-ids="availableLanesIds"
                           @update="selectedLanesList = $event">
              {{ $t('EXPORT.ALL_LANES') }} ({{ availableLanesIds.length }})
            </filter-toggle>
            <v-divider></v-divider>
          </template>
          <template #selection="{ item, index }">
            <span v-if="selectedLanesList.length == 1">{{ item.label }}</span>
            <span v-else-if="selectedLanesList.length == availableLanes.length && index === 0">{{ $t('EXPORT.ALL_LANES') }} ({{ availableLanes.length }})</span>
            <span v-else-if="index === 0">{{ selectedLanesList.length }}/{{ availableLanes.length }}</span>
          </template>
        </v-select>
        <!-- DATES -->
        <div class="display-flex ml-5">
          <date-field v-model="subTaskStartTime" :label="$t('ELEMENT-DETAIL.FROM')" hide-details></date-field>
          <date-field v-model="subTaskEndTime" :label="$t('ELEMENT-DETAIL.TO')" hide-details input-class="ml-1"></date-field>
        </div>
      </div>
      <!-- SUB TASKS -->
      <v-autocomplete v-model="newSubTask" :items="filteredSubTasks" :item-text="(el) => el.getTitle() || defaultTitle(el)" item-value="id"
                      :placeholder="$t('ELEMENT-DETAIL.LOOK_FOR_BUBBLE')" hide-details dense class="pt-0" return-object
                      @change="addSubTask">
        <template #item="data">
          <span v-if="data.item.isType('macro')" class="mr-1">(macro)</span>
          <span class="text-truncate" style="max-width: 200px"
                v-html="data.parent.genFilteredText(data.item.getTitle() || defaultTitle(data.item))">
          </span>
          <small class="ml-2 text-muted">
            {{ data.item.getStartTime() | moment('mediumDateNoYear', true) }}
          </small>
          <small class="text-muted">
            - {{ data.item.getEndTime() | moment('mediumDateNoYear', true) }}
          </small>
        </template>
      </v-autocomplete>
      <template v-if="elSubTasks.length">
        <table class="table table-condensed table-noborder mt-3">
          <tr v-for="(subTask, subTaskIndex) in elSubTasks">
            <td>
              <span v-if="subTask.isType('macro')" class="mr-1">(macro)</span>
              <span>{{ subTask.getTitle() || defaultTitle(subTask) }}</span>
              <span v-if="subTask.getProgress()"> ({{ subTask.getProgress() }}%)</span>
              <small class="ml-2 text-muted">
                {{ subTask.getStartTime() | moment('mediumDateNoYear', true) }}
              </small>
              <small class="text-muted">
                - {{ subTask.getEndTime() | moment('mediumDateNoYear', true) }}
              </small>
            </td>
            <td class="text-right mt-2" style="width: 0px">
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn icon small outlined color="errorred" class="sub-task-menu ml-2" v-on="on" @click.stop="removeSubTask(subTaskIndex)">
                    <v-icon small>far fa-trash-alt</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('ELEMENT-DETAIL.REMOVE_TASK') }}</span>
              </v-tooltip>
            </td>
          </tr>
        </table>
        <hr class="mx-auto my-0" style="border-top: 1px solid #ccc">
      </template>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      el: { type: Object, required: true },
    },
    data() {
      return {
        newSubTask: null,
        planning: this.el.getPlanning(),
        selectedLanesList: [],
        subTaskStartTime: null,
        subTaskEndTime: null,
      };
    },
    computed: {
      elSubTasks() {
        if (! this.el.isType('macro')) return null;
        return this.planning.elements.filter(item => this.elSubTasksId.has(parseInt(item.id, 10)));
      },
      elSubTasksId() {
        return new Set(this.el.getSubTasks());
      },
      availableLanes() {
        if (! this.planning) return [];
        return this.planning.lanes.map(item => ({ id: item.id, label: item.label }));
      },
      availableLanesIds() {
        return this.availableLanes.map(item => item.id);
      },
      elsChildren() {
        const elsChildren = {};
        this.planning.elements.forEach((el) => {
          if (! el.getSubTasks()) return; // take only el with children
          elsChildren[el.id] = el.getSubTasks();
        });
        return elsChildren;
      },
      elsParents() {
        const elsParents = {};
        Object.keys(this.elsChildren).forEach((key) => {
          const parent = parseInt(key, 10);
          const children = this.elsChildren[key];

          children.forEach((child) => {
            if (! elsParents[child]) elsParents[child] = new Set();
            elsParents[child].add(parent);
          });
        });
        return elsParents;
      },
      elParentsIds() {
        const parentsIds = this.macroParents(this.el.id, new Set());
        return parentsIds;
      },
      filteredSubTasks() {
        const filteredSubTasks = this.planning.elements.filter((el) => {
          if (! el.isType('macro', 'task')) return false;
          if (this.elParentsIds.has(el.id)) return false; // ignore parents of selected macro
          if (! this.selectedLanesList.includes(el.getLaneId())) return false;
          if (this.subTaskStartTime && el.getEndTime().isBefore(this.subTaskStartTime)) return false;
          if (this.subTaskEndTime && el.getStartTime().isAfter(this.subTaskEndTime)) return false;
          if (this.elSubTasks.find(item => item.id == el.id)) return false;
          return true;
        });
        return filteredSubTasks.sort((a, b) => {
          if (a.isType('macro') < b.isType('macro')) return -1;
          if (a.isType('macro') > b.isType('macro')) return 1;
          return 0;
        });
      },
    },
    created() {
      this.selectedLanesList = this.availableLanes.map(item => item.id);
    },
    methods: {
      macroParents(macroId, macroParents) {
        if (macroParents.has(macroId)) return null;
        macroParents.add(macroId);

        const parentsIds = new Set(this.elsParents[macroId]);
        parentsIds.forEach((parentId) => {
          if (macroParents.has(parentId)) return null;
          return this.macroParents(parentId, macroParents);
        });
        return macroParents;
      },
      addSubTask() {
        this.elSubTasks.push(this.newSubTask);
        const subTasksId = this.elSubTasks.map(item => item.id);
        this.el.setSubTasks(subTasksId);
        this.$nextTick(() => {
          this.newSubTask = null;
        });
      },
      removeSubTask(index) {
        if (index > -1 && index < this.elSubTasks.length) {
          this.elSubTasks.splice(index, 1);
          const subTasksId = this.elSubTasks.map(item => item.id);
          this.el.setSubTasks(subTasksId);
          this.$nextTick(() => {
            this.newSubTask = null;
          });
        }
      },
      defaultTitle(el) {
        return el.isType('macro') ? this.$t('PLANNING.NEW_MACRO_BUBBLE') : this.$t('PLANNING.NEW_BUBBLE');
      },
    },
  };
</script>
