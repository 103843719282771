<template>
  <v-container class="pa-4">
    <div v-if="! user.id" style="text-align:center; padding:15px; font-size:18px">
      <i class="fas fa-spinner fa-spin fa-2x fa-fw"></i>
    </div>
    <template v-else>
      <div v-if="! isPremium">
        <a :href="`https://bubbleplan.net/auth.php?token=${api_token}&redirect=mon-compte%23premium`" target="_blank"><img src="../../img/go-premium.jpg" style="max-width:100%"></a>
        <v-btn :href="$t('PREMIUM.OFFERS_LINK')" target="_blank" color="accent" rounded large block class="my-4">{{ $t('PREMIUM.DISCOVER_ON_WEBSITE') }}</v-btn>
        <div class="text-center mt-12"><v-btn color="secondary" rounded outlined @click="close">{{ $t('GLOBAL.CLOSE') }}</v-btn></div>
      </div>
      <div v-else-if="! isAdmin">
        <p>{{ $t('PREMIUM.ONLY_FOR_ADMIN') }}</p>
        <div class="text-center"><v-btn color="secondary" rounded large outlined @click="close">{{ $t('GLOBAL.CLOSE') }}</v-btn></div>
      </div>
      <div v-else-if="canChangeQuantity">
        <b class="primary--text text-uppercase">{{ $t('PREMIUM.ADJUST_USERS_QUOTA') }}</b>
        <div class="display-flex align-center mx-auto" style="max-width: 180px">
          <v-btn color="secondary" small icon outlined @click="maxusers = maxusers - 1"><v-icon small>fas fa-minus</v-icon></v-btn>
          <v-text-field v-model.number="maxusers" type="number" :min="packageMinUsers" max="50" class="text-center"></v-text-field>
          <v-btn color="secondary" small icon outlined @click="maxusers = maxusers + 1"><v-icon small>fas fa-plus</v-icon></v-btn>
        </div>
        <div class="px-2">{{ $t('PREMIUM.WILL_PRORATE') }}</div>
        <div class="display-flex justify-space-around mt-2">
          <v-btn color="secondary" rounded large outlined @click="close">{{ $t('GLOBAL.CANCEL') }}</v-btn>
          <v-btn :disabled="saving.inprogress" color="accent" rounded large @click="adjustUsersQuota()">{{ $t('GLOBAL.VALIDATE') }}</v-btn>
        </div>
        <div class="errorred--text">{{ saving.error }}</div>
      </div>
      <div v-else>
        <p class="pre mt-4">{{ $t('PREMIUM.CANNOT_CHANGE_PLAN') }}</p>
        <div class="text-center"><v-btn color="secondary" rounded large outlined @click="close">{{ $t('GLOBAL.CLOSE') }}</v-btn></div>
      </div>
    </template>
  </v-container>
</template>

<script>
  export default {
    data() {
      return {
        maxusers: null,
        packageMinUsers: 1,
        saving: { inprogress: false, error: "" },
        api_token: window.apiSrv.getAuthToken(),
      };
    },
    computed: {
      canChangeQuantity() {
        return this.package == 'TEAM' || this.package == 'TEAM12M' || this.package == 'BUSINESS' || this.package == 'BUSINESS12M';
      },
      user() { return this.$store.state.users.user; },
      package() { return this.user.company && this.user.company.billing_plan; },
      isAdmin() { return this.$store.state.users.accessRight.isAdmin; },
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
    },
    watch: {
      maxusers(newVal) {
        if (newVal < this.packageMinUsers) this.$nextTick(() => { this.maxusers = this.packageMinUsers; });
        if (newVal > 50) this.$nextTick(() => { this.maxusers = 50; });
      },
    },
    created() {
      this.$store.state.users.userPromise.then(() => {
        this.maxusers = this.user.company && this.user.company.maxusers;
      });
    },
    methods: {
      adjustUsersQuota() {
        this.saving = { inprogress: true, error: "" };
        window.apiSrv.call('buy/plan/quantity', 'update', { id: this.maxusers }).then(() => {
          this.close();
          window.location.reload();
        }).catch((message) => {
          this.saving = { inprogress: false, error: `Error : ${message}` };
        });
      },
      close() {
        this.$emit('close');
      },
    },
  };

</script>
