<template>
  <span v-if="category && categoryTags.length" class="d-inline-block">
    <span v-for="tag in categoryTags" :key="tag" class="d-inline-block" style="max-width: calc(100% - 4px); margin: 2px">
      <v-chip :title="tag" :class="{ pointer: pointer }" :style="{ 'background-color': categoryTagsColor[tag].bg, color: categoryTagsColor[tag].color }"
              small>
        <span class="text-ellipsis">{{ tag }}</span>
      </v-chip>
    </span>
  </span>
</template>

<script>
  export default {
    props: {
      category: { type: String, default: '' },
      pointer: { type: Boolean, default: false },
    },
    computed: {
      categoryTags() {
        return this.categories2tags([this.category]);
      },
      categoryTagsColor() {
        return this.categoryTags.reduce((acc, tag) => {
          const tagColor = this.$store.getters['lang/getCategoryColor'](tag);
          acc[tag] = { bg: `#${tagColor}`, color: window.contrastedTextColor(tagColor) };
          return acc;
        }, {});
      },
    },
    methods: {
      categories2tags(categories) {
        const tags = new Set();
        categories.forEach((item) => {
          (item || '').split(',').forEach((subitem) => {
            const tag = subitem && subitem.trim();
            if (tag) tags.add(tag);
          });
        });
        return [...tags];
      },
    },
  };
</script>
