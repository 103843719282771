import Vue from 'vue';
import Planning from '@/models/Planning';
import ProjectSrv from '@/components/Projects/ProjectSrv';

export default {
  namespaced: true,
  modules: {},
  state: {
    byLaneId: {},
    promises: [],
  },
  getters: {
    getAll: state => Object.keys(state.byLaneId).map(id => state.byLaneId[id]),
    getSubprojectByLaneId: state => id => state.byLaneId[id],
    getLaneSubProject: state => (laneId) => {
      const planIds = Object.keys(state.byLaneId);
      for (let i = 0; i < planIds.length; i++) {
        const plan = state.byLaneId[planIds[i]];
        const lane = plan.lanes.find(item => item.id == laneId);
        if (lane) return { planning: plan, lane };
      }
      return null;
    },
    getAllElements: (state) => {
      const keys = Object.keys(state.byLaneId);
      return keys.reduce((prevEls, key) => prevEls.concat(state.byLaneId[key].elements), []);
    },
  },
  mutations: {
    add(state, { laneId, projectContent, timeline, visibleTimeline }) {
      if (state.byLaneId[laneId] && projectContent.id) { // keep ref
        const subplanning = state.byLaneId[laneId];
        subplanning.lanes.forEach((lane) => {
          lane.dashboard_id = lane.id;
          lane.id = lane.o_id;
        });
        subplanning.elements.forEach((el) => {
          el.dashboard_id = el.id;
          el.id = el.o_id;
          // Warning : el.getLaneId() is lane.dashboard_id and not lane.o_id
          // No impact on update as planning patches compare projectContents, except when lane_id changes
        });
        subplanning.update(projectContent);
        subplanning.lanes.forEach((subLane) => {
          if (subLane.dashboard_id) {
            subLane.id = subLane.dashboard_id;
          } else { // new lane
            const newId = `${subLane.id}.${window.uuid()}`;
            subLane.o_id = subLane.id;
            subLane.id = newId;
            subLane.hidden = false;
          }
          delete subLane.dashboard_id;
        });
        subplanning.elements.forEach((el) => {
          if (el.dashboard_id) {
            el.id = el.dashboard_id;
          } else { // new el
            el.o_id = el.id;
            el.id = `${el.id}.${window.uuid()}`;
            el.project_id = subplanning.id;
          }
          const subplanningLane = subplanning.lanes.find(lane => lane.o_id == el.getLaneId());
          if (subplanningLane) el.setLaneId(subplanningLane.id); // new el or change lane
          delete el.dashboard_id;
        });
      } else {
        const subplanning = new Planning(projectContent);

        const lanesMatchIndex = {};
        subplanning.lanes.forEach((subLane) => {
          const newId = `${subLane.id}.${window.uuid()}`;
          lanesMatchIndex[subLane.id] = newId;
          subLane.o_id = subLane.id;
          subLane.id = newId;
          subLane.hidden = false;
        });

        subplanning.elements.forEach((subEl) => {
          subEl.o_id = subEl.id;
          subEl.id = `${subEl.id}.${window.uuid()}`;
          subEl.project_id = subplanning.id;
          const newLaneId = lanesMatchIndex[subEl.getLaneId()];
          subEl.setLaneId(newLaneId);
        });

        if (timeline) subplanning.timeline = timeline;
        if (visibleTimeline) subplanning.visibleTimeline = visibleTimeline;
        Vue.set(state.byLaneId, laneId, subplanning);
      }
    },
    addSubplanningElement(state, { projectId, el }) {
      const laneId = Object.keys(state.byLaneId).find(item => state.byLaneId[item].id == projectId);
      const subplanning = state.byLaneId[laneId];
      if (! subplanning) return;
      const subplanningLane = subplanning.lanes.find(lane => lane.o_id == el.getLaneId());
      if (! subplanningLane) return;
      el.o_id = el.id;
      el.id = `${el.id}.${window.uuid()}`;
      el.project_id = subplanning.id;
      el.setLaneId(subplanningLane.id);
      subplanning.elements.push(el);
    },
    delete(state, id) {
      Vue.delete(state.byLaneId, id);
    },
    reset(state) {
      state.byLaneId = {};
      state.promises = [];
    },
  },
  actions: {
    fetchSubproject({ state, rootState, dispatch, commit }, { id, laneId, viewId }) {
      const lane = rootState.planning.lanes.find(item => item.id == laneId);
      if (! lane) return Promise.reject(new Error('lane not found'));
      let request;
      if (viewId) {
        request = ProjectSrv.getView({ viewId, planningId: id, planningRotoken: lane.project_rotoken });
      } else {
        request = ProjectSrv.get(id, lane.project_rotoken);
      }
      request = request.then((content) => {
        commit('add', { laneId, projectContent: content, timeline: rootState.planning.timeline, visibleTimeline: rootState.planning.visibleTimeline });
        const subplanning = state.byLaneId[laneId];
        // update rotoken
        if (subplanning.meta && subplanning.meta.read_only_token && subplanning.meta.read_only_token != lane.project_rotoken) {
          lane.project_rotoken = subplanning.meta.read_only_token;
          dispatch('planning/save', null, { root: true });
        }
        return subplanning;
      }).catch((error) => {
        commit('add', { laneId, projectContent: { lanes: [{ label: String(error) }] } });
        const subplanning = state.byLaneId[laneId];
        return subplanning;
      });

      state.promises.push(request);
      return request;
    },
    removeByLaneId({ state, commit }, id) {
      if (! state.byLaneId[id]) return;
      commit('delete', id);
    },
    toggleHiddenSublane({ rootGetters }, { lane, sublane }) {
      if (! lane.project_hidden_sublanes) lane.project_hidden_sublanes = [];
      const subprojectLaneOriginalId = parseInt(sublane.id, 10);
      const index = lane.project_hidden_sublanes.indexOf(subprojectLaneOriginalId);
      if (index > -1) {
        lane.project_hidden_sublanes.splice(index, 1);
        const sublaneElements = rootGetters['planning/lanes/getLaneElements']({ planning: sublane.getPlanning(), laneId: sublane.id }) || [];
        setTimeout(() => {
          sublaneElements.forEach((el) => {
            el.update();
            el.updateHeight();
          });
        });
      } else {
        lane.project_hidden_sublanes.push(subprojectLaneOriginalId);
      }
    },
    onChangeTimeline({ state, rootState }) {
      const keys = Object.keys(state.byLaneId);
      keys.forEach((key) => {
        state.byLaneId[key].timeline = rootState.planning.timeline;
        state.byLaneId[key].visibleTimeline = rootState.planning.visibleTimeline;
        state.byLaneId[key].elements.forEach(el => el.update());
      });
    },
  },
};
