<template>
  <tr :class="{ 'portfolio-lane': isLane }">
    <td class="px-0">
      <v-btn v-if="! rowData.archived_at && ! isLane" :title="isExpanded ? $t('PORTFOLIO.HIDE_LANES') : $t('PORTFOLIO.SHOW_LANES')" icon small class="export-hidden"
             @click="$emit('expand')">
        <v-icon small>fas {{ isExpanded ? 'fa-caret-down' : 'fa-caret-right' }}</v-icon>
      </v-btn>
    </td>
    <td v-for="col in filteredHeaders" :key="col.value" :class="[col.value == 'mood' ? 'px-2' : 'px-3', `portfolio-col-${col.value}`]"
        :colspan="isLane && col.value == 'title' ? laneTitleColspan : 1">
      <template v-if="! isLane && col.value == 'mood'">
        <v-menu v-if="rowData.moodIcon" :disabled="! rowData.waypoint" :close-on-content-click="false" open-on-hover right offset-x>
          <template #activator="{ on }">
            <div class="white-bg pointer" style="width: 40px; height: 40px; box-shadow: 0 0px 4px 2px #ccc; border-radius: 50%; margin: auto"
                 v-on="on" @click="openedProjectSheetProject = rowData">
              <div style="width: 40px; height: 40px; padding: 0px 2px 2px"><div :class="'mood-icon-' + rowData.moodIcon"></div></div>
            </div>
          </template>
          <v-card class="pa-2" style="max-width: 300px">
            <div v-html="rowData.waypoint"></div>
          </v-card>
        </v-menu>
        <div v-else class="hovervisible-activator">
          <div class="hovervisible-item white-bg text-center pointer" style="width: 40px; height: 40px; box-shadow: 0 0px 4px 2px #ccc; border-radius: 50%; margin: auto"
               @click="openedProjectSheetProject = rowData">
            <div class="display-flex align-center justify-center" style="width: 40px; height: 40px; font-size: 75%; line-height: 1.3">{{ $t('PROJECT_SHEET.PROJECT_SHEET') }}</div>
          </div>
        </div>
      </template>
      <template v-else-if="col.value == 'category'">
        <project-category :category="rowData.category" class="my-1" style="max-width: 100px"></project-category>
      </template>
      <template v-else-if="col.value == 'title'">
        <div v-if="! isLane" class="text-subtitle-1 display-flex align-center" style="height: 100%; line-height: normal">
          <router-link v-if="! rowData.archived_at" :to="projectLink">{{ rowData.title }}</router-link>
          <span v-else>{{ rowData.title }}</span>
        </div>
        <div v-else class="ml-8">{{ rowData.title }}</div>
      </template>
      <template v-else-if="! isLane && col.value == 'owner'">
        <div class="display-flex align-center">
          <v-tooltip left>
            <template #activator="{ on }">
              <img v-if="rowData.ownerData && rowData.ownerData.avatar" :src="rowData.ownerData.avatar"
                   style="max-height: 24px; min-height: 24px; min-width: 24px; border-radius: 50%;" v-on="on">
              <i v-else-if="rowData.ownerData" class="fas fa-user" style="margin: 0 6px" v-on="on"></i>
            </template>
            <span>{{ rowData.ownerData | username }}</span>
          </v-tooltip>
          <i v-if="rowData.company" class="ml-2" style="line-height: 1">{{ rowData.company }}</i>
          <span v-else class="ml-2" style="line-height: 1">{{ ownerUsername }}</span>
        </div>
      </template>
      <template v-else-if="! isLane && col.value == 'status'">
        <div v-if="rowData.archived_at" class="display-flex align-center"><v-icon :title="$t('PORTFOLIO.ARCHIVED')" small>fas fa-archive</v-icon>&nbsp; {{ rowData.archived_at | moment('L') }}</div>
        <div v-else class="display-flex align-center">
          <v-icon v-if="isActive" :title="$t('PORTFOLIO.RECENT_MODIFICATION')" small color="successgreen">fas fa-circle</v-icon>
          <v-icon v-else :title="$t('PORTFOLIO.NO_RECENT_MODIFICATION')" small color="warningorange">fas fa-circle</v-icon>
          <span class="ml-2 nobr">{{ rowData.updated_at | moment('L') }}</span>
        </div>
      </template>
      <template v-else-if="col.value == 'dates'">
        <div v-if="rowData.duration" class="nobr">{{ rowData.duration.locale(lang).humanize() }}</div>
      </template>
      <template v-else-if="col.value == 'endtime'">
        <div v-if="rowData.datesData.end" class="nobr">{{ rowData.datesData.end | moment('L') }}</div>
        <div v-if="rowData.endtimeTarget" :class="endtimeTargetClass" class="nobr px-1">{{ $t('PORTFOLIO.TARGET') }} : {{ rowData.endtimeTarget | moment('L') }}</div>
      </template>
      <template v-else-if="col.value == 'actions'">
        <template v-if="rowData.actionsData.total">
          <div style="display: flex;">
            <div v-for="(actionStat, id) in actionStats" v-if="actionStat.number" :key="id" :class="actionStat.class" :style="{width: actionStat.percent + '%'}"
                 class="text-center" style="flex: 1 1 auto; height: 20px; justify-content: center; display: flex; flex-direction: column; min-width: 22px">
              <small>{{ actionStat.percent | number }}%</small>
            </div>
          </div>
          <div class="small nobr text-center">/ {{ $tc('GLOBAL.ACTIONS_COUNT', rowData.actionsData.total) }}</div>
        </template>
      </template>
      <template v-else-if="col.value == 'workload'">
        <template v-if="rowData.workloadData.estimated">
          <div style="display: flex;">
            <div v-for="(workloadStat, id) in workloadStats" v-if="workloadStat.number" :key="id" :class="workloadStat.class" :style="{width: workloadStat.percent + '%'}"
                 class="text-center" style="flex: 1 1 auto; height: 20px; justify-content: center; display: flex; flex-direction: column; min-width: 22px">
              <small>{{ workloadStat.percent | number }}%</small>
            </div>
          </div>
          <div class="small nobr text-center">/ {{ $tc(workloadInHours ? 'GLOBAL.HOURS_COUNT' : 'GLOBAL.DAYS_COUNT', rowData.workloadData.estimated) }}</div>
        </template>
      </template>
      <template v-else-if="col.value == 'progress'">
        <template v-if="rowData.progressData.total">
          <div style="display: flex;">
            <div v-for="(progressStat, id) in progressStats" v-if="progressStat.number" :key="id" :class="progressStat.class" :style="{width: progressStat.percent + '%'}"
                 class="text-center" style="flex: 1 1 auto; height: 20px; justify-content: center; display: flex; flex-direction: column; min-width: 22px">
              <small>{{ progressStat.percent | number }}%</small>
            </div>
          </div>
          <div class="small nobr text-center">/ {{ $tc('GLOBAL.BUBBLES_COUNT', rowData.progressData.total) }}</div>
        </template>
      </template>
      <template v-else-if="col.value == 'users'">
        <div class="display-flex" style="flex-wrap: wrap">
          <div v-for="user in projectUsers" style="margin-right: 2px">
            <v-tooltip left>
              <template #activator="{ on }">
                <img v-if="user.avatar" :src="user.avatar" alt=" " style="max-height: 20px; min-height: 20px; min-width: 20px; border-radius: 50%;" v-on="on">
                <i v-else class="fas fa-user" style="margin: 0 3px" v-on="on"></i>
              </template>
              <span>{{ user | username }}</span>
            </v-tooltip>
          </div>
        </div>
      </template>
      <template v-else-if="col.value == 'budget'">
        <div v-for="(budget, icon) in rowData.budgetData" :key="icon" class="nobr text-ellipsis">
          <span :class="`budget-${icon}`"></span>
          <span v-if="budget.amount_inprogress && budget.amount">{{ budget.amount_inprogress | number }}&nbsp;/</span>
          <span v-if="budget.amount_inprogress && ! budget.amount">{{ budget.amount_inprogress | number }}</span>
          <span v-if="budget.amount">{{ budget.amount | number }}</span>
        </div>
        <div v-if="rowData.budgetTarget" :class="budgetTargetClass" class="nobr px-1">{{ $t('PORTFOLIO.TARGET') }} : {{ rowData.budgetTarget | number }}</div>
      </template>
    </td>
    <modal-project-sheet v-if="openedProjectSheetProject" :project="openedProjectSheetProject" @close="openedProjectSheetProject = null">
    </modal-project-sheet>
  </tr>
</template>

<style>
  .portfolio-lane .portfolio-col-title + .portfolio-col-owner,
  .portfolio-lane .portfolio-col-title + .portfolio-col-status,
  .portfolio-lane .portfolio-col-title + .portfolio-col-owner + .portfolio-col-status {
    display: none;
  }
</style>

<script>
  import ProjectCategory from '@/components/Projects/ProjectCategory';
  import ModalProjectSheet from '@/components/ProjectSheet/ModalProjectSheet';

  export default {
    components: {
      ProjectCategory,
      ModalProjectSheet,
    },
    props: {
      filteredHeaders: { type: Array, required: true },
      rowData: { type: Object, required: true },
      isExpanded: { type: Boolean, default: false },
      isLane: { type: Boolean, default: false },
    },
    data() {
      return {
        openedProjectSheetProject: null,
      };
    },
    computed: {
      laneTitleColspan() {
        const headersVals = this.filteredHeaders.map(col => col.value);
        return 1 + (headersVals.includes('owner') ? 1 : 0) + (headersVals.includes('status') ? 1 : 0);
      },
      ownerUsername() {
        return this.$store.getters['users/getUsername'](this.rowData.ownerData, 'short').replace(' ', '\xa0'); // first whitespace made unsecable
      },
      workloadInHours() {
        return this.$store.getters['users/accessRight/config'].workloadInHours;
      },
      projectLink() {
        if (this.rowData.archived_at) return '';
        const isConsult = this.rowData.access_right == 'consult';
        return `/${(isConsult ? 'viewer/' : 'planning/') + this.rowData.id}/${window.slugify(this.rowData.title || "New project")}`;
      },
      isActive() {
        return this.rowData.updated_at.isAfter(moment().add(-3, 'month'));
      },
      actionStats() {
        const actionStats = {
          finished: { label: "PROJECT_SHEET.FINISHED", class: "successgreen white--text" },
          urgent: { label: "PROJECT_SHEET.URGENT", class: "warningorange  white--text" },
          overdue: { label: "PROJECT_SHEET.OVERDUE", class: "errorred  white--text" },
          future: { label: "PROJECT_SHEET.FUTURE", class: "midgrey" },
        };
        Object.keys(actionStats).forEach((status) => {
          actionStats[status].number = this.rowData.actionsData[status];
          actionStats[status].percent = Math.round(this.rowData.actionsData[status] / this.rowData.actionsData.total * 100) || 0;
        });
        return actionStats;
      },
      workloadStats() {
        const { used, estimated } = this.rowData.workloadData;
        const workloadStats = {
          used: { label: "PROJECT_SHEET.DAYS_USED", class: "successgreen white--text", number: Math.min(used, estimated) },
          remaining: { label: "PROJECT_SHEET.DAYS_REMAINING", class: "midgrey", number: Math.max(0, estimated - used) },
          exceeded: { label: "PROJECT_SHEET.DAYS_EXCEEDED", class: "errorred  white--text", number: Math.max(0, used - estimated) },
        };
        Object.keys(workloadStats).forEach((status) => {
          workloadStats[status].percent = Math.round(workloadStats[status].number / estimated * 100) || 0;
        });
        return workloadStats;
      },
      progressStats() {
        const progressStats = {
          finished: { label: "PROJECT_SHEET.FINISHED", class: "successgreen white--text" },
          inprogress: { label: "PROJECT_SHEET.IN_PROGRESS", class: "warningorange  white--text" },
          overdue: { label: "PROJECT_SHEET.OVERDUE", class: "errorred  white--text" },
          future: { label: "PROJECT_SHEET.FUTURE", class: "midgrey" },
        };
        Object.keys(progressStats).forEach((status) => {
          progressStats[status].number = this.rowData.progressData[status];
          progressStats[status].percent = Math.round(this.rowData.progressData[status] / this.rowData.progressData.total * 100) || 0;
        });
        return progressStats;
      },
      projectUsers() {
        return Object.keys(this.rowData.usersData).map(userId => this.rowData.usersData[userId]).sort((a, b) => {
          const aUsername = this.$store.getters['users/getUsername'](a);
          const bUsername = this.$store.getters['users/getUsername'](b);
          return (aUsername.toLowerCase() < bUsername.toLowerCase()) ? -1 : 1;
        });
      },
      budgetTargetClass() {
        const { budgetData, budgetTarget } = this.rowData;
        const maxBudget = Object.keys(budgetData).reduce((acc, icon) => Math.max(acc, budgetData[icon].amount_inprogress, budgetData[icon].amount), 0);
        return maxBudget <= budgetTarget ? 'successgreen white--text' : 'errorred white--text';
      },
      endtimeTargetClass() {
        return this.rowData.datesData.end && this.rowData.datesData.end.isAfter(this.rowData.endtimeTarget) ? 'errorred white--text' : 'successgreen white--text';
      },
      lang() {
        return this.$store.state.lang.lang;
      },
    },
  };
</script>
