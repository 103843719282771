<template>
  <div>
    <div v-selectable-area="{ disabled: ! dynamic, filter: '.element.selectable', start: selectableStart, selected: selectableSelected }" class="table-body notextselect" @click="resetSelection()">
      <template v-for="(lane, laneIndex) in planning.lanes">
        <planning-subproject-view v-if="lane.project_id && ! lane.hidden" :id="'lane' + lane.id" :key="lane.id" :dynamic="dynamic" :view="view"
                                  :style="{ background: laneIndex % 2 ? '#fff' : '#f9f9f9' }"
                                  :planning="planning" :lane="lane">
        </planning-subproject-view>
        <planning-lane-view v-else :id="'lane' + lane.id" :key="lane.id" :dynamic="dynamic" :view="view"
                            :style="{ background: laneIndex % 2 ? '#fff' : '#f9f9f9' }"
                            :planning="planning" :lane="lane" :options="options" :events="events"
                            @open-dependencies="openDependencies" @open-meeting="openMeeting">
        </planning-lane-view>
      </template>
      <planning-timeline-grid :planning="planning"></planning-timeline-grid>
      <div v-if="showPresentTime" :style="{ left: planning.visibleTimeline.presenttimeWidth + .5 + 'px' }" class="presenttime-line"></div>
      <dependencies-svg :planning="planning"></dependencies-svg>
    </div>

    <!-- MODALS -->
    <modal v-if="openedElement" :backdrop="false" :target="modalTarget(openedElement)" movable @close="closeElementDetails">
      <macro-element-details v-if="openedElement.isType('macro')" :element="openedElement" :options="options.elementDetails" @close="closeElementDetails($event)"></macro-element-details>
      <element-details v-else :element="openedElement" :options="openedElementOptions" @close="closeElementDetails($event)"></element-details>
    </modal>
    <modal v-if="openedColorPickerElement" :backdrop="false" :target="modalTarget(openedColorPickerElement)"
           :size="openedColorPickerElement.isType('milestone') ? 'sm' : 'md'" movable
           @close="closeColorPicker">
      <color-picker :target="openedColorPickerElement" :planning="planning" @close="closeColorPicker"></color-picker>
    </modal>
    <modal v-if="openedDependenciesElement" :backdrop="false" :target="modalTarget(openedDependenciesElement)" movable
           @close="closeDependencies">
      <dependencies-manager :planning="planning" :element="openedDependenciesElement" @close="closeDependencies"></dependencies-manager>
    </modal>

    <client-custom-component v-for="compName in clientCustomComponentsList" :key="compName" :name="compName"></client-custom-component>

    <modal v-if="openedMeetingElement" :backdrop="false" size="md" movable @close="closeMeeting">
      <meeting-in-planning-window :element="openedMeetingElement" @close="closeMeeting"></meeting-in-planning-window>
    </modal>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import clientConfig from '@/client_customs/config';
  import MacroElementDetails from '@/components/ElementDetails/Macro/MacroElementDetails';
  import ElementDetails from '@/components/ElementDetails/ElementDetails';
  import ColorPicker from '@/components/ColorPicker/ColorPicker';
  import DependenciesManager from '@/components/Dependencies/DependenciesManager';
  import DependenciesSvg from '@/components/Dependencies/DependenciesSvg';
  import MeetingInPlanningWindow from '@/components/BubbleMeeting/MeetingInPlanningWindow';
  import PlanningLaneView from './PlanningLaneView';
  import PlanningSubprojectView from './PlanningSubprojectView';
  import PlanningTimelineGrid from './PlanningTimelineGrid';
  import selectable from './mixins/selectable';

  export default {
    components: {
      PlanningLaneView,
      PlanningSubprojectView,
      PlanningTimelineGrid,
      DependenciesSvg,
      MeetingInPlanningWindow,
      MacroElementDetails,
      ElementDetails,
      ColorPicker,
      DependenciesManager,
    },
    mixins: [selectable],
    props: {
      planning: { type: Object, required: true },
      dynamic: { type: Boolean, default: false },
      view: { type: Boolean, default: false },
      options: { type: Object, default: () => ({}) },
      events: { type: Object, default: () => ({}) },
    },
    data() {
      return {
        clientCustomComponentsList: clientConfig.pool && clientConfig.pool.customComponentsList,
      };
    },
    computed: {
      showPresentTime() {
        const { visibleTimeline } = this.planning;
        return visibleTimeline && visibleTimeline.show_presenttime && visibleTimeline.presenttimeWidth > 0 && visibleTimeline.presenttimeWidth <= visibleTimeline.pxwidth;
      },
      openedElementOptions() {
        // enable duplication for subproject elements
        const elOptions = this.openedElement?.dashboard_el?.project_id ? { canDuplicateEl: true } : null;
        return { ...this.options.elementDetails, ...elOptions };
      },
      ...mapState('ui/planning', ['openedElement', 'openedColorPickerElement', 'openedDependenciesElement', 'openedMeetingElement']),
    },
    mounted() {
      if (this.dynamic && ! this.view) {
        window.Mousetrap.bind('ctrl+d', (event) => { event.preventDefault(); this.$store.dispatch('selection/duplicateSelection'); });
        window.Mousetrap.bind('del', (event) => { event.preventDefault(); this.$store.dispatch('selection/deleteSelection'); });
        window.Mousetrap.bind('esc', (event) => { event.preventDefault(); this.resetSelection(); });
        window.Mousetrap.bind('ctrl+s', (event) => { event.preventDefault(); this.$store.dispatch('planning/save'); });
      }
      window.Mousetrap.bind('left', (event) => { event.preventDefault(); this.scrollRight(-20); });
      window.Mousetrap.bind('right', (event) => { event.preventDefault(); this.scrollRight(20); });
    },
    destroyed() {
      const hotkeys = ['ctrl+d', 'del', 'esc', 'ctrl+s', 'left', 'right'];
      for (let i = 0; i < hotkeys.length; i++) {
        window.Mousetrap.unbind(hotkeys[i]);
      }
    },
    methods: {
      resetSelection(data) {
        if (! this.dynamic) return;
        this.$store.dispatch('selection/resetSelection', data);
      },
      scrollRight(howmuch) {
        if (! parseInt(howmuch, 10)) return;
        const $tableWrapper = $("#table-wrapper");
        $tableWrapper.scrollLeft($tableWrapper.scrollLeft() + howmuch);
      },
      closeColorPicker() {
        if (this.events.closeColorPicker) {
          this.events.closeColorPicker();
          return;
        }
        this.$store.dispatch('ui/planning/closeColorPicker');
      },
      closeElementDetails(reason) {
        if (this.events.closeElementDetails) {
          this.events.closeElementDetails(reason);
          return;
        }
        this.$store.dispatch('ui/planning/closeElementDetails', reason);
      },
      modalTarget(el) {
        const dashboardElEscapedId = el.dashboard_el?.id && `${el.dashboard_el.id}`.replace(/\./g, '\\.'); // query selector #N.xxx escaped #N\\.xxx
        return `#el${dashboardElEscapedId || el.id}`;
      },
      ...mapActions('planning/elements', ['startChangingElement', 'changingElement']),
      ...mapActions('planning/config', ['startChangingConfig', 'changingConfig']),
      ...mapActions('ui/planning', ['openDependencies', 'closeDependencies', 'openMeeting', 'closeMeeting']),
    },
  };
</script>
