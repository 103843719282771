<template>
  <v-container class="pa-6">
    <b v-if="isNewAction">{{ $t('MONITORING_ACTIONS.ADD_ACTION') }}</b>
    <b v-else-if="actionBreadcrumb" class="text-muted">
      <v-icon small class="mr-1" style="vertical-align: baseline;">fas {{ isMeetingAction ? 'fa-users' : 'fa-folder-open' }}</v-icon>
      {{ actionBreadcrumb }}
    </b>
    <b v-else>&nbsp;</b>
    <v-row class="mt-0">
      <v-col md="6" cols="12">
        <label for="openActionTitle"><i :class="action.getChecked() ? 'fa-check-square' : 'fa-square'" class="far fa-fw mr-2"></i>{{ $t('MONITORING_ACTIONS.ACTION') }}</label>
        <v-text-field id="openActionTitle" v-model="actionTitle" :placeholder="$t('MONITORING_ACTIONS.ACTION_TITLE')" hide-details class="pt-0">
        </v-text-field>
      </v-col>
      <v-col md="6" cols="12">
        <label for="openActionDueDate"><i class="far fa-calendar-plus fa-fw mr-2"></i>{{ $t('MONITORING_ACTIONS.DUE_DATE') }}</label>
        <div class="display-flex align-center">
          <date-field id="openActionDueDate" v-model="actionDueDate" hide-details input-class="pt-0" style="flex: 1 1 100%"></date-field>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn :class="{ invisible: ! action.getDuedate() }" icon small class="mr-0" v-on="on" @click="removeDueDate">
                <v-icon color="errorred" small>fa fa-times</v-icon>
              </v-btn>
            </template>
            {{ $t('ELEMENT-DETAIL.REMOVE_DUE_DATE') }}
          </v-tooltip>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6" cols="12">
        <label for="openActionResponsible" class="ma-0"><i class="fas fa-user fa-fw mr-2"></i>{{ $t('ELEMENT-DETAIL.RESPONSIBLE') }}</label>
        <select-user v-model="actionResponsible" :with-groups="! isMeetingAction" :with-text-users="! isMeetingAction" hide-details
                     @input="setResponsible($event)">
        </select-user>
      </v-col>
      <v-col md="6" cols="12">
        <label for="openActionWorkload" class="ma-0">
          <i class="fas fa-weight-hanging fa-fw mr-2"></i>
          {{ workloadInHours ? $t('MONITORING_ACTIONS.WORKLOAD_IN_HOURS') : $t('MONITORING_ACTIONS.WORKLOAD') }}
        </label>
        <div class="display-flex align-center">
          <div style="flex: 1 1 50%">
            <v-text-field v-model.number="actionWorkload" :label="$t('ELEMENT-DETAIL.WORKLOAD_ESTIMATED')" type="number" step="any" min="0" hide-details>
            </v-text-field>
          </div>
          <v-spacer class="mx-2 mt-4"><i class="fa fa-lg fa-caret-right"></i></v-spacer>
          <div style="flex: 1 1 50%">
            <v-text-field id="openActionWorkload" v-model.number="actionWorkloadUsed" :label="$t('ELEMENT-DETAIL.WORKLOAD_USED')" type="number" step="any" min="0" hide-details>
            </v-text-field>
          </div>
        </div>
      </v-col>
    </v-row>
    <open-action-extra-fields v-if="hasExtraFields && isMeetingAction" :action="action"></open-action-extra-fields>
    <div class="mt-4 display-flex align-center">
      <v-menu v-if="! isNewAction" offset-y>
        <template #activator="{ on }">
          <v-btn icon small outlined color="errorred" v-on="on">
            <v-icon small>far fa-trash-alt</v-icon>
          </v-btn>
        </template>
        <div class="white">
          <v-btn rounded color="errorred white--text" class="ma-4" @click="deleteAction(); close('delete')">
            {{ $t('MONITORING_ACTIONS.DELETE_ACTION_PERMANENTLY') }}
          </v-btn>
        </div>
      </v-menu>
      <v-spacer></v-spacer>
      <v-btn color="secondary" rounded large outlined class="ma-2" @click="close">{{ $t('GLOBAL.CANCEL') }}</v-btn>
      <v-btn color="accent" rounded large class="ma-2" @click="saveAction(); close('save')">{{ $t('GLOBAL.VALIDATE') }}</v-btn>
    </div>
  </v-container>
</template>

<script>
  import workloadMixin from '@/components/Workload/workloadMixin';
  import OpenActionExtraFields from './OpenActionExtraFields';

  export default {
    components: {
      OpenActionExtraFields,
    },
    mixins: [workloadMixin],
    props: {
      action: { type: Object, required: true },
      users: { type: Array, default: () => [] },
    },
    data() {
      return {
        actionResponsible: null,
      };
    },
    computed: {
      workloadInHours() {
        return this.$store.getters['users/accessRight/config'].workloadInHours;
      },
      isMeetingAction() {
        return this.action.type == 'meeting';
      },
      isNewAction() {
        return this.action.isNew();
      },
      actionBreadcrumb() {
        if (this.action.type == 'meeting') {
          const meeting = this.action.getMeeting();
          return meeting && meeting.title || '';
        }
        const el = this.action.getElement();
        const planning = el && el.getPlanning();
        return planning && `${planning.getTitle() || this.$t('PLANNING.UNNAMED_PROJECT')} > ${el.getTitle() || this.$t('PLANNING.NEW_BUBBLE')}` || '';
      },
      actionTitle: {
        get() { return this.action.getTitle(); },
        set(newVal) { this.action.setTitle(newVal); },
      },
      actionUserId: {
        get() { return this.action.getUserId(); },
        set(newVal) {
          this.action.setUser({ id: newVal, username: this.$store.getters['users/getUsername']({ id: newVal }, 'short') });
        },
      },
      actionGroupId: {
        get() { return this.action.getGroupId(); },
        set(newVal) {
          this.action.setGroup({ id: newVal, username: this.$store.getters['users/groups/getGroupById'](newVal)?.title });
        },
      },
      actionUsername: {
        get() { return this.action.getUsername(); },
        set(newVal) { this.action.setUsername(newVal); },
      },
      actionDueDate: {
        get() { return this.action.getDuedate(); },
        set(newVal) {
          if (! newVal) return;
          this.action.setDuedate(newVal.format());
        },
      },
      actionWorkload: {
        get() {
          return this.workloadToDisplay(this.action.getWorkload());
        },
        set(newVal) {
          this.action.setWorkload(this.workloadToStore(newVal));
        },
      },
      actionWorkloadUsed: {
        get() {
          return this.workloadToDisplay(this.action.getWorkloadUsed());
        },
        set(newVal) {
          this.action.setWorkloadUsed(this.workloadToStore(newVal));
        },
      },
      hasExtraFields() {
        return this.$store.getters['users/accessRight/config'].meetingactions_extrafields;
      },
    },
    created() {
      this.$store.state.users.userPromise.then(() => {
        if (! this.actionResponsible && this.actionUserId) {
          this.actionResponsible = this.$store.getters['users/getUsername']({ id: this.actionUserId });
        } else if (! this.actionResponsible && this.actionGroupId) {
          this.actionResponsible = this.$store.getters['users/groups/getGroupById'](this.actionGroupId)?.title;
        } else if (! this.actionResponsible && this.actionUsername) {
          this.actionResponsible = this.actionUsername;
        }
      });
    },
    methods: {
      setResponsible(user) {
        if (user && user.group_id) {
          this.actionGroupId = user.group_id;
        } else if (user && user.id) {
          this.actionUserId = user.id;
        } else {
          this.actionUsername = user && user.username || '';
        }
      },
      removeDueDate() {
        this.action.setDuedate(null);
      },
      saveAction() {
        this.$store.commit('ui/saving/start');
        this.action.save().then(() => {
          this.$store.commit('ui/saving/success');
        }).catch((message) => {
          this.$store.commit('ui/saving/error', message);
        });
      },
      deleteAction() {
        this.$store.commit('ui/saving/start');
        this.action.delete().then(() => {
          this.$store.commit('ui/saving/success');
        }).catch((message) => {
          this.$store.commit('ui/saving/error', message);
        });
      },
      close(reason) {
        this.$emit('close', reason);
      },
    },
  };
</script>
