import Vue from 'vue';
import ClientCustomComponent from '@/client_customs/ClientCustomComponent';
import Modal from '@/components/Reusables/Modal';
import DateField from '@/components/Reusables/DateField';
import TimeField from '@/components/Reusables/TimeField';
import SelectUser from '@/components/Reusables/SelectUser';
import FilterToggle from '@/components/Reusables/FilterToggle';

/* GLOBAL COMPONENTS */
Vue.component('ClientCustomComponent', ClientCustomComponent.default || ClientCustomComponent);
Vue.component('Modal', Modal.default || Modal);
Vue.component('DateField', DateField.default || DateField);
Vue.component('TimeField', TimeField.default || TimeField);
Vue.component('SelectUser', SelectUser.default || SelectUser);
Vue.component('FilterToggle', FilterToggle.default || FilterToggle);
