<template>
  <v-menu ref="menu" v-model="menuIsOpen" :close-on-content-click="false" transition="scale-transition"
          offset-y max-width="290px" min-width="290px">
    <template #activator="{ on }">
      <v-text-field v-model="calendarTime" v-bind="$attrs" :rules="[validateFormat]" v-on="on"></v-text-field>
    </template>
    <v-time-picker v-if="menuIsOpen" v-model="calendarTime" format="24hr" @click:minute="closeMenu"></v-time-picker>
  </v-menu>
</template>

<script>
  /*  Input: v-model (date) can be set to a moment or string
      Output: v-model (@input) will be a moment or null

      null = empty date string // Invalid formats (ie 35/12/2018) are not emitted
  */

  export default {
    inheritAttrs: false,
    model: {
      prop: 'time',
      event: 'input',
    },
    props: {
      time: { type: [String, Object], default: null },
      noNull: { type: Boolean, default: false },
    },
    data() {
      return {
        updatedTime: null,
        menuIsOpen: false,
        format: 'HH:mm',
        formatRegex: /\d\d:\d\d/gi,
      };
    },
    computed: {
      locale() {
        return this.$store.state.lang.lang;
      },
      calendarTime: {
        get() {
          return this.updatedTime ? this.updatedTime.format(this.format) : null;
        },
        set(newVal) {
          if (! newVal) {
            this.setUpdatedTime(null);
            return;
          }
          if (! newVal.match(this.formatRegex)) return;
          const [hours, minutes] = newVal.split(':');
          if (! (hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59)) return;
          const newdate = this.time ? moment(this.time) : moment();
          this.setUpdatedTime(newdate.hours(hours).minutes(minutes).seconds(0));
        },
      },
    },
    watch: {
      time: {
        handler(newVal) {
          if (! newVal) { this.updatedTime = null; return; }
          this.updatedTime = moment(newVal).locale(this.locale);
        },
        immediate: true,
      },
    },
    methods: {
      setUpdatedTime(newVal) {
        this.updatedTime = newVal;
        if (this.noNull && newVal === null) return;
        this.$emit('input', this.updatedTime);
      },
      closeMenu() {
        this.$refs.menu.save(this.updatedTime);
      },
      validateFormat(str) {
        if (! str) {
          return ! this.noNull || 'Time required';
        }
        if (! str.match(this.formatRegex)) return `Format : ${this.format}`;
        const [hours, minutes] = str.split(':');
        return (hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59) || 'Invalid time';
      },
    },
  };
</script>
