import InliDynamicsSubTasks from '@/client_customs/Inli/InliDynamicsSubTasks';
import InliMcsTypecode from '@/client_customs/Inli/InliMcsTypecode';
import InliPlanningElementViewSubTasks from '@/client_customs/Inli/InliPlanningElementViewSubTasks';

export default {
  clientCustomComponents: { InliDynamicsSubTasks, InliMcsTypecode, InliPlanningElementViewSubTasks },
  sso: {
    protocol: 'openIDConnect',
    login: `https://login.microsoftonline.com/01a91ab5-c50b-4c0d-8cfb-59bc713898ab/oauth2/v2.0/authorize?client_id=67dd58ec-60ab-43b8-a20e-50ceda12acb0&response_type=code&scope=openid%20email%20profile&redirect_uri=https%3A%2F%2F${window.location.hostname}`,
    response_type: 'code',
  },
  hideManageAccount: true,
  integrations: {
    availableIntegrations: [],
  },
  links: {
    hideOneDrive: true,
    hideDropbox: true,
    hideGoogleDrive: true,
    hideFileUpload: true,
  },
  projects: {
    defaultPrivacy: 'company visible',
  },
  elements: {
    customTypecode: { dataField: 'mcs_typecode', component: 'InliMcsTypecode' },
    customFields: [
      { dataField: 'dyn_subtasks', component: 'InliDynamicsSubTasks', label: "Sous-tâches", icon: 'icon-dynamics', active: true, inMenu: true },
    ],
  },
  lang: {
    fr: {
      NAV: {
        PROJECT_SHEET_DETAILS: "Fiche projet, fichiers",
      },
    },
    en: {
      NAV: {
        PROJECT_SHEET_DETAILS: "Project sheet, files",
      },
    },
  },
};
