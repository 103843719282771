<template>
  <textarea :name="name"></textarea>
</template>

<style>
  .note-editor.note-frame .note-status-output {
    height: 11px;
    padding-top: 0;
  }
</style>

<script>
  export default {
    model: {
      prop: 'content',
      event: 'change',
    },
    props: {
      content: { type: String, default: '' },
      name: { type: String, default: 'editor' },
      config: { type: Object, default: () => ({}) },
      lang: { type: String, default: '' },
      defaultContent: { type: String, default: "" },
    },
    watch: {
      content() {
        const $el = $(this.$el);
        if ($el.summernote('code') != this.content) { // model has changed (ex : navigation in waypoints)
          let initialContent = this.content;
          if (this.defaultContent && ! this.hasContent(initialContent)) {
            initialContent = this.defaultContent;
          }
          $el.summernote('code', initialContent);
        }
      },
    },
    mounted() {
      const vm = this;
      const config = {
        height: 350,
        focus: true,
        toolbar: [
          ['header', ['style']],
          ['style', ['bold', 'italic', 'underline', 'strikethrough', 'clear']],
          ['fontsize', ['fontsize']],
          ['color', ['color']],
          ['para', ['ul', 'ol', 'paragraph']],
          // ['media', ['link', 'picture', 'video']],
          // ['undoredo', ['undo', 'redo']]
        ],
        styleTags: ['p', 'h1', 'h2', 'h3'],
        fontSizes: ['10', '12', '14', '18', '24', '36', '48', '72'],
        popover: {
          image: [
            ['imagesize', ['imageSize100', 'imageSize50', 'imageSize25']],
            ['float', ['floatLeft', 'floatRight', 'floatNone']],
            ['remove', ['removeMedia']],
          ],
          link: [
            ['link', ['linkDialogShow', 'unlink']],
          ],
        },
        lang: "en",
        dialogsInBody: true,
        ...this.config,
      };

      if (this.lang) config.lang = this.lang;

      config.callbacks = {
        onInit() {
          let initialContent = vm.content;
          if (vm.defaultContent && ! vm.hasContent(initialContent)) {
            initialContent = vm.defaultContent;
          }
          $(vm.$el).summernote('code', initialContent);
        },
        onChange() {
          vm.$emit('change', $(vm.$el).summernote('code'));
        },
        onBlur() {
          vm.$emit('change', $(vm.$el).summernote('code'));
        },
      };
      $(this.$el).summernote(config);
    },
    destroyed() {
      $(this.$el).summernote('destroy');
    },
    methods: {
      hasContent(html) {
        return !! window.html2text(html || "").trim();
      },
    },
  };
</script>
