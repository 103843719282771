<template>
  <!-- USERS WITH VIRTUAL PARTICIPANTS (for PLANNING USERS & KANBAN) -->
  <v-autocomplete v-model="selectedAllUsers"
                  :items="allUsersList"
                  :label="$t('MULTIPROJECTS.PARTICIPANTS')" :placeholder="selectedAllUsers.length ? '' : $t('MULTIPROJECTS.NO_USER_SELECTED')"
                  item-text="username" item-value="id"
                  prepend-icon="fas fa-user" hide-details multiple menu-props="offsetY"
                  return-object
                  @change="update">
    <template #prepend-item>
      <v-list-item @click.stop>
        <v-switch v-model="filterOptions.unassigned" :label="$t('MULTIPROJECTS.INCLUDE_UNASSIGNED')" color="primary" hide-details class="mt-0" @change="update">
        </v-switch>
      </v-list-item>
      <filter-toggle :selected-ids="selectedCompanyUsers" :sublist-ids="companyUserIds"
                     @update="selectedCompanyUsers = $event; update()">
        {{ $t('MULTIPROJECTS.ALL_COMPANY_USERS') }} ({{ companyUserIds.length }})
      </filter-toggle>
      <filter-toggle v-if="deletedUserIds.length" :selected-ids="selectedDeletedUsers" :sublist-ids="deletedUserIds"
                     @update="selectedDeletedUsers = $event; update()">
        {{ $tc('MULTIPROJECTS.ALL_DELETED_USERS') }} ({{ deletedUserIds.length }})
      </filter-toggle>
      <filter-toggle :selected-ids="selectedVirtualParticipants" :sublist-ids="virtualParticipantIds"
                     @update="selectedVirtualParticipants = $event; update()">
        {{ $t('MULTIPROJECTS.ALL_VIRTUAL_PARTICIPANTS') }} ({{ virtualParticipantIds.length }})
      </filter-toggle>
      <v-divider></v-divider>
      <template v-if="companyGroups.length">
        <v-list-item @click="expandCompanyGroups = ! expandCompanyGroups">
          <v-list-item-action class="justify-center">
            <v-icon :color="expandCompanyGroups ? 'primary' : ''">
              {{ expandCompanyGroups ? 'fas fa-caret-down' : 'fas fa-caret-right' }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title :class="expandCompanyGroups ? 'primary--text' : ''">{{ $t('GLOBAL.GROUPS') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <template v-if="expandCompanyGroups">
          <filter-toggle v-for="group in companyGroups" :key="group.id"
                         :selected-ids="selectedCompanyUsers" :sublist-ids="userIdsByGroupId[group.id]" indent
                         @update="selectedCompanyUsers = $event; update()">
            <span>{{ group.title }} ({{ group.users.length }})</span>
          </filter-toggle>
        </template>
        <v-divider></v-divider>
      </template>
      <template v-if="organizationCompanies.length">
        <v-list-item @click="expandOrganizationCompanies = ! expandOrganizationCompanies">
          <v-list-item-action class="justify-center">
            <v-icon :color="expandOrganizationCompanies ? 'primary' : ''">
              {{ expandOrganizationCompanies ? 'fas fa-caret-down' : 'fas fa-caret-right' }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title :class="expandOrganizationCompanies ? 'primary--text' : ''">{{ $t('PROJECTS.ORGANIZATION') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <template v-if="expandOrganizationCompanies">
          <filter-toggle v-for="company in organizationCompanies" :key="company.id"
                         :selected-ids="selectedCompanyUsers" :sublist-ids="userIdsByOrganizationCompany[company.id]" indent
                         @update="selectedCompanyUsers = $event; update()">
            {{ company.name }} ({{ userIdsByOrganizationCompany[company.id].length }})
          </filter-toggle>
        </template>
        <v-divider></v-divider>
      </template>
    </template>
    <template #item="{ parent, item }">
      <v-list-item-avatar class="mr-3">
        <img v-if="item.avatar" :src="item.avatar">
        <v-icon v-else class="color-inherit">{{ item.icon || 'fas fa-user' }}</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-html="parent.genFilteredText(item.username)"></v-list-item-title>
      </v-list-item-content>
    </template>
    <template #selection="{ item, index }">
      <span v-if="selectedAllUsers.length == 1">{{ item.username }}</span>
      <span v-else-if="selectedAllUsers.length == allUsersCount && index === 0" class="mr-2">{{ $t('MULTIPROJECTS.ALL_PARTICIPANTS') }} ({{ allUsersCount }})</span>
      <span v-else-if="index === 0" class="mr-2">{{ selectedAllUsers.length }}/{{ allUsersCount }}</span>
    </template>
  </v-autocomplete>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    props: {
      useActionUsers: { type: Boolean, default: false },
    },
    data() {
      return {
        expandCompanyGroups: false,
        expandOrganizationCompanies: false,
      };
    },
    computed: {
      selectedCompanyUsers: {
        get() {
          return this.$store.state.multiprojects.selectedCompanyUsers;
        },
        set(newVal) {
          this.$store.state.multiprojects.selectedCompanyUsers.splice(0, this.$store.state.multiprojects.selectedCompanyUsers.length, ...newVal);
        },
      },
      selectedVirtualParticipants: {
        get() {
          return this.useActionUsers ? this.$store.state.multiprojects.selectedActionVirtualParticipants : this.$store.state.multiprojects.selectedVirtualParticipants;
        },
        set(newVal) {
          if (this.useActionUsers) {
            this.$store.state.multiprojects.selectedActionVirtualParticipants.splice(0, this.$store.state.multiprojects.selectedActionVirtualParticipants.length, ...newVal);
          } else {
            this.$store.state.multiprojects.selectedVirtualParticipants.splice(0, this.$store.state.multiprojects.selectedVirtualParticipants.length, ...newVal);
          }
        },
      },
      selectedDeletedUsers: {
        get() {
          return this.useActionUsers ? this.$store.state.multiprojects.selectedActionDeletedUsers : this.$store.state.multiprojects.selectedDeletedUsers;
        },
        set(newVal) {
          if (this.useActionUsers) {
            this.$store.state.multiprojects.selectedActionDeletedUsers.splice(0, this.$store.state.multiprojects.selectedActionDeletedUsers.length, ...newVal);
          } else {
            this.$store.state.multiprojects.selectedDeletedUsers.splice(0, this.$store.state.multiprojects.selectedDeletedUsers.length, ...newVal);
          }
        },
      },
      selectedAllUsers: {
        get() {
          const selectedAllUsers = [];
          this.companyUsers.forEach((user) => {
            if (this.selectedCompanyUsers.indexOf(user.id) == -1) return;
            selectedAllUsers.push(user);
          });
          this.deletedUsers.forEach((user) => {
            if (this.selectedDeletedUsers.indexOf(user.id) == -1) return;
            selectedAllUsers.push(user);
          });
          this.virtualParticipants.forEach((user) => {
            if (this.selectedVirtualParticipants.indexOf(user.username) == -1) return;
            selectedAllUsers.push(user);
          });
          return selectedAllUsers;
        },
        set(newVal) {
          const newValArray = newVal === "" ? this.selectedAllUsers : newVal; // IE is sometimes receiving empty string for newVal. No idea why.
          this.selectedCompanyUsers = [];
          this.selectedDeletedUsers = [];
          this.selectedVirtualParticipants = [];
          (newValArray || []).forEach((user) => {
            if (user.id && ! user.isDeleted) {
              this.selectedCompanyUsers.push(user.id);
            } else if (user.id && user.isDeleted) {
              this.selectedDeletedUsers.push(user.id);
            } else {
              this.selectedVirtualParticipants.push(user.username);
            }
          });
        },
      },
      organizationCompanies() {
        const { organization } = this.$store.state.users.user;
        if (organization && organization.getCompanies().length > 1) return organization.getCompanies().filter(item => item.users.length);
        return [];
      },
      userIdsByOrganizationCompany() {
        return this.organizationCompanies.reduce((acc, company) => {
          acc[company.id] = company.users.map(item => item.id);
          return acc;
        }, {});
      },
      allUsersList() {
        let allUsersList = this.companyUsers;
        if (this.organizationCompanies.length) {
          allUsersList = [];
          for (let i = 0; i < this.organizationCompanies.length; i++) {
            const company = this.organizationCompanies[i];
            const companyUsers = company.users.map(user => this.companyUsers.find(item => item.id == user.id)).filter(item => item);
            if (! companyUsers.length) continue;
            allUsersList.push({ header: company.name }, { divider: true }, ...companyUsers);
          }
        }
        if (this.deletedUsers.length) {
          allUsersList = allUsersList.concat([{ header: this.$tc('MULTIPROJECTS.DELETED_USER', 2) }, { divider: true }]).concat(this.deletedUsers);
        }
        if (this.virtualParticipants.length) {
          allUsersList = allUsersList.concat([{ header: this.$t('MULTIPROJECTS.VIRTUAL_PARTICIPANTS') }, { divider: true }]).concat(this.virtualParticipants);
        }
        return allUsersList;
      },
      allUsersCount() {
        return this.companyUsers.length + this.deletedUsers.length + this.virtualParticipants.length;
      },
      companyUserIds() {
        return this.companyUsers.map(item => item.id);
      },
      virtualParticipants() {
        return this.useActionUsers ? this.$store.state.multiprojects.actionVirtualParticipants : this.$store.state.multiprojects.virtualParticipants;
      },
      virtualParticipantIds() {
        return this.virtualParticipants.map(item => item.username);
      },
      companyGroups() {
        return (this.$store.getters['users/groups/getCompanyGroups'] || []).filter(group => group.users.length);
      },
      userIdsByGroupId() {
        return this.companyGroups.reduce((acc, group) => {
          acc[group.id] = group.users.map(user => user.id);
          return acc;
        }, {});
      },
      deletedUsers() {
        return this.useActionUsers ? this.$store.state.multiprojects.actionDeletedUsers : this.$store.state.multiprojects.deletedUsers;
      },
      deletedUserIds() {
        return this.deletedUsers.map(item => item.id);
      },
      ...mapState('multiprojects', ['companyUsers', 'filterOptions']),
    },
    methods: {
      update() {
        this.$emit('update');
      },
    },
  };
</script>
