<template>
  <v-fade-transition>
    <v-menu v-model="menuIsOpened" offset-y left content-class="elevation-0">
      <template #activator="{ on }">
        <v-btn :title="$t('PERSONAL_CALENDAR.ADD_EVENT')" :class="menuIsOpened ? 'is-opened' : ''" class="personal-calendar-add-button" icon v-on="on">
          <v-icon small>fas fa-plus-circle</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item class="px-1">
          <v-btn color="accent" rounded @click.stop="createAction(date)">
            <v-icon small class="mr-2">fas fa-plus-circle</v-icon>{{ $t('PERSONAL_CALENDAR.NEW_MEETING_ACTION') }}
          </v-btn>
        </v-list-item>
        <!-- <v-list-item class="px-1">
          <v-btn color="warningorange" dark rounded @click.stop="createHoliday(date)">
            <v-icon small class="mr-2">fas fa-plus-circle</v-icon>{{ $t('PERSONAL_CALENDAR.NEW_HOLIDAY') }}
          </v-btn>
        </v-list-item> -->
      </v-list>
    </v-menu>
  </v-fade-transition>
</template>

<style>
  #personal-calendar .v-calendar-weekly__day, #personal-calendar .v-calendar-daily_head-day {
    position: relative;
  }

  .personal-calendar-add-button {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    transition: opacity .3s;
  }

  .v-calendar-weekly__day:hover .personal-calendar-add-button, .v-calendar-daily_head-day:hover .personal-calendar-add-button, .personal-calendar-add-button.is-opened {
    visibility: visible;
    opacity: 1;
  }
</style>

<script>
  import { mapState } from 'vuex';
  import Action from '@/models/Action';

  export default {
    props: {
      date: { type: String, required: true },
    },
    data() {
      return {
        menuIsOpened: false,
      };
    },
    computed: {
      selectedUser() {
        return this.selectedCompanyUsers.concat(this.selectedVirtualParticipants)[0];
      },
      ...mapState('multiprojects', ['selectedCompanyUsers', 'selectedVirtualParticipants']),
    },
    methods: {
      createAction(date) {
        const newAction = new Action();
        if (this.selectedUser) {
          newAction.setUser({ id: this.selectedUser, username: this.$store.getters['users/getUsername']({ id: this.selectedUser }, 'short') });
        }
        if (date) newAction.setDuedate(date);
        this.$emit('open-action', newAction);
      },
      // createHoliday(date) {
      //   const newHoliday = {};
      //   newHoliday.start = date;
      //   newHoliday.end = date;
      //   this.$emit('open-holiday', newHoliday);
      // },
    },
  };
</script>
