export default {
  data() {
    return {
    };
  },
  computed: {
    hasMonitoringPlan() { return this.$store.state.users.accessRight.hasMonitoringPlan; },
  },
  watch: {
    'planning.id': function () {
      this.$store.dispatch('monitoringplan/loadMonitoringPlan', { planning: this.planning });
    },
  },
};
