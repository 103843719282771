var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('navigation',{attrs:{"config":_vm.config}},[(_vm.config == 'planning' || _vm.config == 'planningview')?[_c('v-divider',{attrs:{"vertical":""}}),_vm._v(" "),_c('v-tooltip',{attrs:{"value":_vm.saving.error || null,"color":_vm.saving.error ? 'errorred' : null,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"tour-save mx-0",staticStyle:{"cursor":"default"},attrs:{"icon":""},on:{"click":function($event){return _vm.save()}}},on),[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(! _vm.saving.inprogress && ! _vm.saving.success && ! _vm.saving.error),expression:"! saving.inprogress && ! saving.success && ! saving.error"}],attrs:{"size":"18"}},[_vm._v("fas fa-save")]),_vm._v(" "),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.saving.inprogress),expression:"saving.inprogress"}],attrs:{"size":"18"}},[_vm._v("fas fa-spinner fa-spin")]),_vm._v(" "),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.saving.success),expression:"saving.success"}],attrs:{"size":"18"}},[_vm._v("fas fa-check success--text")]),_vm._v(" "),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.saving.error),expression:"saving.error"}],staticClass:"pointer"},[_vm._v("fas fa-exclamation-triangle errorred--text")])],1)]}}],null,false,693531634)},[_vm._v(" "),_c('span',[_c('span',{directives:[{name:"show",rawName:"v-show",value:(! _vm.saving.inprogress && ! _vm.saving.success && ! _vm.saving.error),expression:"! saving.inprogress && ! saving.success && ! saving.error"}]},[_vm._v(_vm._s(_vm.$t('NAV.AUTOSAVE')))]),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.saving.inprogress),expression:"saving.inprogress"}]},[_vm._v(_vm._s(_vm.$t('AUTOSAVE.SAVING')))]),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.saving.success),expression:"saving.success"}]},[_vm._v(_vm._s(_vm.$t('AUTOSAVE.SAVED')))]),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.saving.error),expression:"saving.error"}]},[_vm._v(_vm._s(_vm.saving.error))])])]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"tour-save",staticStyle:{"width":"32px","height":"32px"},attrs:{"disabled":! _vm.undoable,"icon":""},on:{"click":function($event){return _vm.undo()}}},on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("fas fa-undo")])],1)]}}],null,false,2071330608)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('NAV.UNDO')))])]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"tour-save",staticStyle:{"width":"32px","height":"32px"},attrs:{"disabled":! _vm.redoable,"icon":""},on:{"click":function($event){return _vm.redo()}}},on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("fas fa-redo")])],1)]}}],null,false,92937116)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('NAV.REDO')))])]),_vm._v(" "),_c('planning-navigation-search',{attrs:{"planning":_vm.planning},on:{"open-element":_vm.openElement}}),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"draggable-button",rawName:"v-draggable-button",value:({ type: 'task', start: _vm.draggableButtonStart, stop: _vm.draggableButtonStop }),expression:"{ type: 'task', start: draggableButtonStart, stop: draggableButtonStop }"}],staticClass:"my-1 ml-2 mr-1 tour-add-bubble grab",staticStyle:{"height":"36px","width":"36px"},attrs:{"ripple":false,"fab":"","small":""}},on),[_c('div',{staticStyle:{"width":"24px","position":"relative","display":"flex: flex-direction: column","border-radius":"4px","overflow":"hidden"}},[_c('div',{staticClass:"white--text task-default-blue",staticStyle:{"height":"8px","width":"100%","line-height":"0"}},[_vm._v("...")]),_vm._v(" "),_c('div',{staticStyle:{"height":"14px","width":"100%","background":"rgba(216, 216, 253, 0.7)"}}),_vm._v(" "),_c('div')])])]}}],null,false,2736654265)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('NAV.DRAG_AND_DROP_TO_ADD_BUBBLE')))])]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"draggable-button",rawName:"v-draggable-button",value:({ type: 'milestone', start: _vm.draggableButtonStart, stop: _vm.draggableButtonStop }),expression:"{ type: 'milestone', start: draggableButtonStart, stop: draggableButtonStop }"}],staticClass:"my-1 ml-1 tour-add-milestone grab",staticStyle:{"height":"36px","width":"36px"},attrs:{"ripple":false,"fab":"","small":""}},on),[_c('div',{staticClass:"milestone-default-blue"},[_c('v-icon',{staticStyle:{"vertical-align":"middle"}},[_vm._v("fa icon-diamonds")])],1)])]}}],null,false,923723052)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('NAV.DRAG_AND_DROP_TO_ADD_MILESTONE')))])]),_vm._v(" "),_c('v-divider',{staticClass:"ml-4",attrs:{"vertical":""}})]:_vm._e(),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",staticStyle:{"height":"32px","min-width":"0"},attrs:{"rounded":"","small":"","depressed":""},on:{"click":function($event){return _vm.openProjectSheet()}}},on),[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("fas fa-flag")]),_vm._v(" "),_c('span',{staticClass:"hidden-md-and-down ml-2"},[_vm._v(_vm._s(_vm.$t('NAV.PROJECT_SHEET')))])],1)]}}])},[_vm._v("\n    "+_vm._s(_vm.$t('NAV.PROJECT_SHEET_DETAILS'))+"\n  ")]),_vm._v(" "),(_vm.config == 'planning')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"tour-share mx-1",staticStyle:{"height":"32px","min-width":"0"},attrs:{"rounded":"","small":"","depressed":""},on:{"click":function($event){return _vm.openShare()}}},on),[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("fas fa-share-square")]),_vm._v(" "),_c('span',{staticClass:"hidden-md-and-down ml-2"},[_vm._v(_vm._s(_vm.$t('NAV.SHARE')))])],1)]}}],null,false,3825660888)},[_vm._v("\n    "+_vm._s(_vm.$t('NAV.SHARE_THIS_PROJECT'))+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.config == 'planning' || _vm.config == 'planningview')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"tour-export mx-1",staticStyle:{"height":"32px","min-width":"0"},attrs:{"rounded":"","small":"","depressed":""},on:{"click":function($event){return _vm.openExport()}}},on),[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("far fa-image")]),_vm._v(" "),_c('span',{staticClass:"hidden-md-and-down ml-2"},[_vm._v(_vm._s(_vm.$t('NAV.EXPORT')))])],1)]}}],null,false,986906646)},[_vm._v("\n    "+_vm._s(_vm.$t('NAV.EXPORT_TO_IMAGE_FILE'))+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.configIsOpened)?_c('modal',{attrs:{"dark":""},on:{"close":function($event){_vm.configIsOpened = false}}},[_c('config-window',{attrs:{"planning":_vm.planning,"tabs":_vm.config == 'planning' ? null : ['projectsheet']},on:{"open-element":_vm.openElement,"close":function($event){_vm.configIsOpened = false}}})],1):_vm._e(),_vm._v(" "),(_vm.shareIsOpened)?_c('modal',{attrs:{"dark":""},on:{"close":function($event){_vm.shareIsOpened = false}}},[_c('share-window',{attrs:{"planning":_vm.planning},on:{"close":function($event){_vm.shareIsOpened = false}}})],1):_vm._e(),_vm._v(" "),(_vm.exportIsOpened)?_c('modal',{attrs:{"size":"lg","dark":""},on:{"close":function($event){_vm.exportIsOpened = false}}},[_c('export-window',{attrs:{"planning":_vm.planning,"planning-view-id":_vm.planningViewId},on:{"close":function($event){_vm.exportIsOpened = false}}})],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }