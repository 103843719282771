<template>
  <v-menu v-model="isOpened" :close-on-content-click="false" bottom offset-y nudge-bottom="10" nudge-left="20" content-class="delete-lane-popover">
    <template #activator="{ on }">
      <v-btn :title="$t('PLANNING.DELETE_LANE')" icon x-small v-on="on">
        <v-icon color="errorred" size="14">far fa-trash-alt</v-icon>
      </v-btn>
    </template>
    <v-card class="pa-2">
      <div>{{ deleteConfirmationMessage }}</div>
      <v-divider class="my-1"></v-divider>
      <v-card-actions>
        <v-btn rounded outlined color="grey darken-2" @click="isOpened = false">{{ $t('GLOBAL.CANCEL') }}</v-btn>
        <v-spacer class="mx-2"></v-spacer>
        <v-btn rounded color="errorred white--text" @click="$emit('delete')">
          <v-icon size="14" class="mr-2">far fa-trash-alt</v-icon>
          {{ $t('PLANNING.DELETE') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<style>
  .delete-lane-popover {
    z-index: 9999 !important;
    max-width: 250px;
  }
</style>

<script>
  export default {
    props: {
      lane: { type: Object, required: true },
    },
    data() {
      return {
        isOpened: false,
      };
    },
    computed: {
      laneElementsCount() {
        const laneElements = this.$store.getters['planning/lanes/getLaneElements']({ laneId: this.lane.id });
        return laneElements.length;
      },
      deleteConfirmationMessage() {
        if (this.lane.project_id) return this.$t('PLANNING.DELETE_LANE_SUBPROJECT');
        return this.$tc('PLANNING.DELETE_LANE_CONFIRMATION', this.laneElementsCount, { label: this.lane.label });
      },
    },
  };
</script>
