import Group from '@/models/Group';

export default {
  namespaced: true,
  modules: {},
  state: {
    companyGroups: [],
    loaded: false,
  },
  getters: {
    getCompanyGroups(state) { // return false while not loaded
      return state.loaded && state.companyGroups.sort((a, b) => (a.title < b.title ? -1 : 1));
    },
    groupsById(state) {
      return state.companyGroups.reduce((acc, item) => { acc[item.id] = item; return acc; }, {});
    },
    getGroupById(state, getters) {
      return groupId => getters.groupsById[groupId];
    },
    getUserGroups(state) {
      return user => state.companyGroups.filter(group => group.users.find(groupUser => groupUser.id == user.id));
    },
  },
  mutations: {
  },
  actions: {
    load({ state }) {
      return window.apiSrv.call('groups', 'index').then((response) => {
        state.companyGroups.splice(0, state.companyGroups.length);
        (response?.data?.groups || []).forEach((groupData) => {
          state.companyGroups.push(new Group(groupData));
        });
        state.loaded = true;
        return state.companyGroups;
      });
    },
    createGroup({ state }, title) {
      if (! title) return Promise.reject('title is required');
      return window.apiSrv.call('groups', 'store', { title }).then((response) => {
        const group = new Group(response?.data?.group);
        state.companyGroups.push(group);
        return group;
      });
    },
    updateGroup({ state }, { groupId, title }) {
      return window.apiSrv.call('groups', 'update', { id: groupId, title }).then(() => {
        const index = state.companyGroups.findIndex(item => item.id == groupId);
        if (index > -1) state.companyGroups[index].title = title;
      });
    },
    deleteGroup({ state }, groupId) {
      return window.apiSrv.call('groups', 'destroy', { id: groupId }).then(() => {
        const index = state.companyGroups.findIndex(item => item.id == groupId);
        if (index > -1) state.companyGroups.splice(index, 1);
      });
    },
  },
};
