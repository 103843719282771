<template>
  <div>
    <canvas ref="myChart" :width="width" :height="height"></canvas>
  </div>
</template>

<script>
  export default {
    props: {
      config: { type: Object, required: true },
      height: { type: Number, default: 400 },
      width: { type: Number, default: 400 },
    },
    data() {
      return {
        Chart: null,
        chart: null,
      };
    },
    watch: {
      config() {
        if (this.Chart) {
          const ctx = this.$refs.myChart.getContext('2d');
          this.chart = new this.Chart(ctx, this.config);
        }
      },
    },
    mounted() {
      import(/* webpackChunkName: "chartjs" */ 'chart.js').then((Chart) => {
        import(/* webpackChunkName: "chartjs" */ 'chartjs-plugin-datalabels').then(() => {
          this.Chart = Chart.default;
          const ctx = this.$refs.myChart.getContext('2d');
          this.chart = new this.Chart(ctx, this.config);
        });
      });
    },
  };
</script>
