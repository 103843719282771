<template>
  <v-container class="color-picker pa-4">
    <v-row v-if="el" no-gutters class="justify-center">
      <v-col sm="6" class="mt-2">
        <article :class="el.getType()" class="element" style="position: static; cursor: default; box-shadow: none; background: none">
          <div v-if="el.isType('macro', 'task')" class="mb-2">
            <div :style="{ 'border-color': colorCodes.main, visibility: icon ? 'visible' : 'hidden' }"
                 :class="el.isType('macro') ? 'macro-icon' : 'task-icon'" style="box-shadow: none">
              <v-icon :color="elIcon.color" size="16" class="fa-fw" style="vertical-align: initial; transition-duration: .1s">{{ elIcon.name }}</v-icon>
            </div>
            <div :style="{ background: colorCodes.secondary }" style="border-radius: 4px; overflow: hidden;">
              <div :style="{ 'box-shadow': 'none', background: colorCodes.main }" class="element-title">
                <div style="font-weight:bold">
                  <span v-if="el.isType('macro')">{{ $t('COLORPICKER.MY_MACRO_BUBBLE') }}</span>
                  <span v-else>{{ $t('COLORPICKER.MY_BUBBLE') }}</span>
                </div>
              </div>
              <div class="elementcontent"><div>{{ $t('COLORPICKER.DESCRIPTION') }}</div></div>
            </div>
          </div>
          <div v-if="el.isType('milestone')">
            <v-icon :color="elIcon.color" size="18" class="fa-fw" style="transition-duration: .1s">{{ elIcon.name }}</v-icon>
            <div>{{ $t('COLORPICKER.MILESTONE') }}</div>
          </div>
        </article>
      </v-col>
    </v-row>
    <v-row no-gutters class="flex-sm-nowrap">
      <v-col v-if="! el || el.isType('macro', 'task')" :sm="el ? 6 : 12" :class="el ? 'pr-1' : 'mt-6'" class="flex-shrink-1">
        <!--      COLOR PICKER-->
        <div class="pl-1 py-2" style="height: 100%;">
          <div v-for="(colorData, colorIndex) in planning.config.colors" :key="colorIndex" class="display-flex mb-3">
            <div class="pr-2">
              <input v-model="colorData.label" :placeholder="$t(`COLORS.SET_${colorIndex + 1}`)"
                     class="input-underlined" spellcheck="false" style="width: 100%; border-width: 0 0 1px;">
            </div>
            <div class="display-flex">
              <div class="color-block">
                <div :style="{ background: colorData.main }" class="color-square pointer"
                     @mouseover="color = colorIndex; colorShade = ''" @mouseout="resetColors()" @click="saveAndQuit()">
                </div>
                <div style="margin-top: -2px">
                  <input v-model="colorData.sublabels[0]" class="input-underlined text-center" spellcheck="false" style="width: 100%; border-width: 0 0 1px; font-size: 10px;">
                </div>
              </div>
              <div v-for="(shadeData, shadeIndex) in colorData.shades" :key="shadeIndex" class="color-block">
                <div :style="{ background: shadeData }" class="color-square pointer"
                     @mouseover="color = colorIndex; colorShade = shadeIndex" @mouseout="resetColors()" @click="saveAndQuit()">
                </div>
                <div style="margin-top: -2px">
                  <input v-model="colorData.sublabels[shadeIndex + 1]" class="input-underlined text-center" spellcheck="false" style="width: 100%; border-width: 0 0 1px; font-size: 10px">
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-divider v-if="el && el.isType('macro', 'task')" vertical class="mx-2"></v-divider>
      <v-col v-if="el" :sm="el.isType('milestone') ? 12 : 6" :class="el.isType('milestone') ? '' : 'pl-2'" class="pb-2 flex-shrink-1">
        <!--      ICON PICKER-->
        <div>
          <v-btn v-if="el.isType('macro', 'task')" block small rounded outlined color="errorred" class="my-2" @mouseover="icon = ''" @mouseout="resetIcon()" @click="saveAndQuit()">
            {{ $t('COLORPICKER.REMOVE_ICON') }}
          </v-btn>
          <v-row class="ma-0 pt-2">
            <v-col v-for="iconCode in displayIconCodes" :key="iconCode" :cols="colorPickerIconCol" class="text-center pt-1 px-1 pb-2" style="overflow: hidden">
              <v-icon :color="planningIcons[iconCode].color" size="18" class="pointer" @mouseover="icon = iconCode" @mouseout="resetIcon()" @click="saveAndQuit()">
                {{ planningIcons[iconCode].name }}
              </v-icon>
              <div v-if="disableIconRenaming" style="font-size: 10px; line-height: 1.4">{{ planningIcons[iconCode].label || $t(`ICONS.${uppercase(iconCode)}`) }}</div>
              <div v-else style="margin-top: -2px">
                <input v-model="planningIcons[iconCode].label" :placeholder="$t(`ICONS.${uppercase(iconCode)}`)"
                       class="input-underlined text-center" spellcheck="false" style="width: 100%; border-width: 0 0 1px; font-size: 10px">
              </div>
            </v-col>
          </v-row>
        </div>
        <div v-if="iconsTotalPages > 1" class="text-right pt-2">
          <v-pagination v-model="iconsCurrentPage" :length="iconsTotalPages" circle></v-pagination>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
  .color-picker .color-block {
    margin-right: 5px;
    margin-top: 2px;
    width: 32px;
  }
  .color-picker .color-square {
    height: 20px;
  }
</style>

<script>
  import clientConfig from '@/client_customs/config';

  export default {
    props: {
      target: { type: Object, required: true }, // el or lane
      planning: { type: Object, required: true },
    },
    data() {
      const el = (this.target.isType && this.target.isType('macro', 'milestone', 'task')) ? this.target : null;
      return {
        el,
        color: this.target.getColorId(),
        colorShade: this.target.getColorShadeId(),
        icon: el && el.getIconId(),
        iconsCurrentPage: 1,
        iconsPerPage: clientConfig.icons && clientConfig.icons.iconsPerPage || 36,
        planningIcons: this.planning.config.icons,
        disableIconRenaming: clientConfig.icons && clientConfig.icons.disableRenaming,
        colorPickerIconCol: clientConfig.icons && clientConfig.icons.colorPickerIconCol || 2,
      };
    },
    computed: {
      elIcon() {
        return this.planningIcons[this.icon] || {};
      },
      iconsTotalPages() {
        return Math.ceil(Object.keys(this.planningIcons).length / this.iconsPerPage);
      },
      displayIconCodes() {
        return Object.keys(this.planningIcons).slice(this.iconsPerPage * (this.iconsCurrentPage - 1), this.iconsPerPage * this.iconsCurrentPage);
      },
      colorCodes() {
        return {
          main: (typeof this.colorShade == 'number' ? this.planning.config.colors[this.color].shades[this.colorShade] : this.planning.config.colors[this.color].main) || '#0169aa',
          secondary: this.planning.config.colors[this.color].secondary || 'rgba(216,216,253,.5)',
        };
      },
    },
    methods: {
      resetColors() {
        this.color = this.target.getColorId();
        this.colorShade = this.target.getColorShadeId();
      },
      resetIcon() {
        if (! this.el) return;
        this.icon = this.el.getIconId();
      },
      saveAndQuit() {
        this.target.setColorId(this.color);
        this.target.setColorShadeId(this.colorShade);
        if (this.el) {
          this.el.setIconId(this.icon);
        }
        this.$emit('close');
      },
      uppercase(str) {
        return str && str.toUpperCase();
      },
    },
  };
</script>
