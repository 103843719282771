<template>
  <div class="pb-5">
    <b class="primary--text text-uppercase">{{ $t('MANAGE.CONFIG.PLANNING_SHARE') }}</b>
    <div class="ml-6 pt-1">
      <v-switch v-model="hasViews" hide-details>
        <template #label>{{ $t('SHARE.CUSTOM_VIEWS') }}</template>
      </v-switch>
      <v-switch v-model="hideExtenalLink" :true-value="false" :false-value="true"
                :disabled="clientConfig.planningShare && clientConfig.planningShare.hideExtenalLink">
        <template #label>{{ $t('SHARE.INVITE_CUSTOMERS') }}</template>
      </v-switch>
    </div>
    <v-divider class="my-4"></v-divider>
    <b class="primary--text text-uppercase">{{ $t('MANAGE.CONFIG.FILES') }}</b>
    <div class="ml-6 pt-1 display-flex">
      <v-checkbox v-model="links.hideOneDrive" :true-value="false" :false-value="true"
                  @change="setParam('links', links)">
        <template #label>{{ $t('MANAGE.CONFIG.ONEDRIVE') }}</template>
      </v-checkbox>
      <v-checkbox v-model="links.hideGoogleDrive" :true-value="false" :false-value="true" class="ml-4"
                  @change="setParam('links', links)">
        <template #label>{{ $t('MANAGE.CONFIG.GOOGLE_DRIVE') }}</template>
      </v-checkbox>
      <v-checkbox v-model="links.hideDropbox" :true-value="false" :false-value="true" class="ml-4"
                  @change="setParam('links', links)">
        <template #label>{{ $t('MANAGE.CONFIG.DROPBOX') }}</template>
      </v-checkbox>
      <v-checkbox v-model="links.hideFileUpload" :true-value="false" :false-value="true" class="ml-4"
                  @change="setParam('links', links)">
        <template #label>{{ $t('MANAGE.CONFIG.FILE_UPLOAD') }}</template>
      </v-checkbox>
    </div>
    <v-divider class="my-4"></v-divider>
    <b class="primary--text text-uppercase">{{ $t('MANAGE.CONFIG.CALENDAR_INTEGRATIONS') }}</b>
    <div class="ml-6 pt-1 display-flex">
      <v-checkbox v-model="availableIntegrations" value="microsoft">
        <template #label>{{ $t('INTEGRATIONS.MICROSOFT_OUTLOOK') }}</template>
      </v-checkbox>
      <v-checkbox v-model="availableIntegrations" value="google" class="ml-4">
        <template #label>{{ $t('INTEGRATIONS.GOOGLE_CALENDAR') }}</template>
      </v-checkbox>
    </div>
    <v-divider class="my-4"></v-divider>
    <b class="primary--text text-uppercase">{{ $tc('MANAGE.CONFIG.VIRTUAL_PARTICIPANT', 2) }}</b>
    <div class="ml-6 pt-1">
      <v-row style="line-height: 1.3;">
        <v-col :cols="6" :lg="4">
          <small>{{ $t('MANAGE.CONFIG.BUSINESS_PARTICIPANT_DESC') }}</small>
          <v-textarea v-model="textUsers" filled class="pt-2" style="border-radius: 4px 4px 0 0; flex: 0 1 50%"></v-textarea>
        </v-col>
        <v-col v-if="clientConfigTextUsers" :cols="6" :lg="4">
          <small>{{ $t('MANAGE.CONFIG.CLIENTCONFIG_PARTICIPANT_DESC') }}</small>
          <v-textarea :value="clientConfigTextUsers" filled readonly class="mr-4 pt-2" style="border-radius: 4px 4px 0 0; flex: 0 1 50%"></v-textarea>
        </v-col>
      </v-row>
    </div>
    <v-divider class="my-4"></v-divider>
    <b class="primary--text text-uppercase">{{ $t('MANAGE.CONFIG.SUBPROJECTS_DISPLAYED_CONTENT') }}</b>
    <div class="ml-6 pt-1">
      <div class="display-flex flex-wrap">
        <v-checkbox v-model="subprojectsVisibleDetails" value="synthesis">
          <template #label>{{ $t('MANAGE.CONFIG.SYNTHESIS') }}</template>
        </v-checkbox>
        <v-checkbox v-model="subprojectsVisibleDetails" value="progress" class="ml-4">
          <template #label>{{ $t('ELEMENT-DETAIL.PROGRESS') }}</template>
        </v-checkbox>
        <v-checkbox v-model="subprojectsVisibleDetails" value="date" class="ml-4">
          <template #label>{{ $t('MANAGE.CONFIG.MILESTONE_DATE') }}</template>
        </v-checkbox>
        <v-checkbox v-model="subprojectsVisibleDetails" value="dates" class="ml-4">
          <template #label>{{ $t('MANAGE.CONFIG.TASK_DATES') }}</template>
        </v-checkbox>
        <v-checkbox v-model="subprojectsVisibleDetails" value="links" class="ml-4">
          <template #label>{{ $t('ELEMENT-DETAIL.ATTACHMENTS') }}</template>
        </v-checkbox>
        <v-checkbox v-model="subprojectsVisibleDetails" value="budgets" class="ml-4">
          <template #label>{{ $t('ELEMENT-DETAIL.BUDGET') }}</template>
        </v-checkbox>
      </div>
      <v-switch v-model="subprojectsSynthesisPopover" :disabled="! subprojectsVisibleDetails.includes('synthesis')">
        <template #label>{{ $t('MANAGE.CONFIG.DISPLAY_SYNTHESIS_POPOVER') }}</template>
      </v-switch>
    </div>
    <v-divider class="my-4"></v-divider>
    <b class="primary--text text-uppercase">{{ $t('ELEMENT-DETAIL.CHECKLIST') }}</b>
    <div class="ml-6 pt-1 display-flex align-center">
      <v-switch v-model="assignActionsToCreator">
        <template #label>{{ $t('MANAGE.CONFIG.ASSIGN_ACTIONS_TO_CREATOR') }}</template>
      </v-switch>
    </div>
    <v-divider class="my-4"></v-divider>
    <b class="primary--text text-uppercase">{{ $t('ELEMENT-DETAIL.WORKLOAD') }}</b>
    <div class="ml-6 pt-1 display-flex align-center">
      <v-switch v-model="workloadInHours" class="mr-2">
        <template #label>{{ $t('MANAGE.CONFIG.DISPLAY_WORKLOAD_IN_HOURS') }}</template>
      </v-switch>
      <div class="ml-4">
        <v-text-field v-model.number="workloadHoursPerDay" :disabled="! workloadInHours"
                      :label="$t('MANAGE.CONFIG.NUMBER_OF_HOURS')" :title="$t('MANAGE.CONFIG.NUMBER_OF_HOURS')"
                      hide-details type="number" step="any" min="0" :max="maxHoursPerDay" class="pa-0 ma-0"
                      @change="setParam('workloadHoursPerDay', $event)">
        </v-text-field>
      </div>
    </div>
    <v-divider class="my-4"></v-divider>
    <b class="primary--text text-uppercase">{{ $t('MANAGE.CONFIG.BUDGET_STATES') }}</b>
    <div class="ml-6 pt-1">
      <manage-config-params-budget></manage-config-params-budget>
    </div>
  </div>
</template>

<script>
  import ManageConfigParamsBudget from './ManageConfigParamsBudget';

  export default {
    components: {
      ManageConfigParamsBudget,
    },
    data() {
      return {
        maxHoursPerDay: 24,
        workloadHoursPerDay: null,
      };
    },
    computed: {
      hasViews: {
        get() {
          return this.computedConfig.hasViews;
        },
        set(newVal) {
          this.setParam('hasViews', newVal);
        },
      },
      hideExtenalLink: {
        get() {
          return this.computedConfig.hideExtenalLink;
        },
        set(newVal) {
          this.setParam('hideExtenalLink', newVal);
        },
      },
      links() {
        return this.computedConfig.links;
      },
      availableIntegrations: {
        get() {
          return this.computedConfig.availableIntegrations;
        },
        set(newVal) {
          this.setParam('availableIntegrations', newVal);
        },
      },
      textUsers: {
        get() {
          return (this.computedConfig.businessTextUsers || []).join("\n");
        },
        set(newVal) {
          const textUsers = newVal.split('\n').map(item => item.trim()).filter(item => item);
          this.debounceSetParam('textUsers', textUsers.length ? textUsers : null);
        },
      },
      clientConfigTextUsers() {
        return (this.computedConfig.clientConfigTextUsers || []).join("\n");
      },
      subprojectsVisibleDetails: {
        get() {
          return this.computedConfig.subprojectsVisibleDetails;
        },
        set(newVal) {
          this.setParam('subprojectsVisibleDetails', newVal);
        },
      },
      subprojectsSynthesisPopover: {
        get() {
          return this.computedConfig.subprojectsSynthesisPopover;
        },
        set(newVal) {
          this.setParam('subprojectsSynthesisPopover', newVal);
        },
      },
      assignActionsToCreator: {
        get() {
          return this.computedConfig.assignActionsToCreator;
        },
        set(newVal) {
          this.setParam('assignActionsToCreator', newVal);
        },
      },
      workloadInHours: {
        get() {
          return this.computedConfig.workloadInHours;
        },
        set(newVal) {
          this.setParam('workloadInHours', newVal);
        },
      },
      computedConfig() {
        return this.$store.getters['users/accessRight/config'];
      },
      clientConfig() {
        return this.$store.state.users.accessRight.clientConfig;
      },
    },
    watch: {
      workloadHoursPerDay(newVal) {
        if (newVal > this.maxHoursPerDay) this.$nextTick(() => { this.workloadHoursPerDay = this.maxHoursPerDay; });
      },
    },
    created() {
      this.workloadHoursPerDay = this.computedConfig.workloadHoursPerDay;
    },
    methods: {
      setParam(prop, val) {
        this.$store.dispatch('users/accessRight/updateBusinessConfig', { [prop]: val });
      },
      debounceSetParam: _.debounce(function (prop, val) {
        this.setParam(prop, val);
      }, 5000),
    },
  };
</script>
