<template>
  <div style="padding: 0 10px 0 2px; overflow-x: auto; overflow-y: hidden; max-height: 400px;">
    <div v-if="! completePlanning" class="my-2 text-center">
      <i class="fa fa-2x fa-spinner fa-spin"></i>
    </div>
    <div v-else style="display: flex; margin: 0 -15px; padding: 15px; width: 100%; max-height: 100%">
      <kanban-col v-for="col in kanbancols" :key="col.id" :planning="kanbanPlanning" :col="col"
                  @save-el="saveEl">
      </kanban-col>
    </div>

    <!-- MODALS -->
    <modal v-if="openedElement" :backdrop="false" movable @close="closeElementDetails">
      <element-details :element="openedElement" @close="closeElementDetails($event)"></element-details>
    </modal>
    <modal v-if="openedColorPickerElement" :backdrop="false"
           :size="openedColorPickerElement.isType('milestone') ? 'sm' : 'md'" movable
           @close="closeColorPicker">
      <color-picker :target="openedColorPickerElement" :planning="openedColorPickerElement.getPlanning()" @close="closeColorPicker"></color-picker>
    </modal>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import ElementDetails from '@/components/ElementDetails/ElementDetails';
  import ColorPicker from '@/components/ColorPicker/ColorPicker';
  import KanbanCol from '@/components/Dashboards/Kanban/KanbanCol';

  export default {
    components: {
      ElementDetails,
      ColorPicker,
      KanbanCol,
    },
    props: {
      projectId: { type: Number, required: true },
      kanbanDisplay: { type: Object, required: true },
    },
    data() {
      return {
        completePlanning: null,
      };
    },
    computed: {
      kanbanPlanning() {
        const kanbanPlanning = this.completePlanning;
        kanbanPlanning.elements.forEach((el) => {
          this.$store.dispatch('multiprojects/formatEl', { el, projectData: kanbanPlanning });
          el.setConfig({
            "show-description": false,
            "show-progress": true,
            "show-checklist": true,
            "show-users": false,
            "show-date": false,
            "show-dates": false,
            "show-links": false,
            "show-budgets": false,
            "show-projecttitle": false,
          });
        });
        return kanbanPlanning;
      },
      kanbancols() {
        return [
          { id: 'finished', label: "MONITORING_PROGRESS.FINISHED", color: 'successgreen', icon: 'fas fa-check-circle', orderBy: '-data.endtime' },
          { id: 'inprogress', label: "MONITORING_PROGRESS.IN_PROGRESS", color: 'primary', icon: 'fas fa-hourglass-half', orderBy: 'data.endtime' },
          { id: 'overdue', label: "MONITORING_PROGRESS.OVERDUE", color: 'errorred', icon: 'fas fa-exclamation-triangle', orderBy: '-data.endtime' },
          { id: 'future', label: "MONITORING_PROGRESS.FUTURE", color: 'secondary', icon: 'fas fa-rocket', orderBy: 'data.starttime' },
        ].filter(col => this.kanbanDisplay[col.id]);
      },
      ...mapState('ui/kanban', ['openedElement', 'openedColorPickerElement']),
    },
    created() {
      this.refreshProject();
      this.$store.commit('ui/kanban/reset');
    },
    methods: {
      refreshProject() {
        this.completePlanning = null;
        this.$store.dispatch('multiprojects/plannings/load', { planningId: this.projectId }).then((planning) => {
          this.completePlanning = planning.clone();
        });
      },
      ...mapActions('ui/kanban', ['closeElementDetails', 'openColorPicker', 'closeColorPicker', 'saveEl']),
    },
  };
</script>
