<template>
  <div>
    <div v-if="isMiniElement">
      <i class="fas fa-user"></i> {{ elUsers.length }}
    </div>
    <template v-else>
      <div v-for="user in elUsers" class="vcenter" style="padding: 1px">
        <span :title="user.email" class="nobr">
          <template v-if="user.id">
            <template v-if="! isDeletedUser(user.id)">
              <img v-if="user.avatar" :src="user.avatar" class="user-avatar-img">
              <i v-else class="fas fa-user fa-fw" style="font-size: 14px; vertical-align: middle;"></i>
            </template>
            <i v-else :title="$tc('MULTIPROJECTS.DELETED_USER', 1)" class="fas fa-user-slash fa-fw" style="font-size: 14px; vertical-align: middle;"></i>
          </template>
          <template v-else-if="user.group_id">
            <i v-if="! isDeletedGroup(user.group_id)" class="far fa-users fa-fw" style="font-size: 14px; vertical-align: middle;"></i>
            <i v-else :title="$t('MULTIPROJECTS.DELETED_GROUP')" class="far fa-users-slash fa-fw" style="font-size: 14px; vertical-align: middle;"></i>
          </template>
          <i v-else class="fas fa-user fa-fw" style="font-size: 14px; vertical-align: middle;"></i>
          <span style="vertical-align: middle">
            <span v-if="user.group_id">{{ user.title }}</span>
            <span v-else>{{ user | username('short') }}</span>
            <span v-if="user.percent"> ({{ user.percent }}%)</span>
          </span>
        </span>
        <span v-if="! isLast(user, elUsers)"></span>
      </div>
    </template>
  </div>
</template>

<style>
  article.element .user-avatar-img {
    min-height: 20px; /* define size before img loading */
    max-height: 20px;
    min-width: 20px;
    max-width: 20px;
    border-radius: 50%;
    margin-right: 2px;
  }
</style>

<script>
  export default {
    props: {
      el: { type: Object, required: true },
      isMiniElement: { type: Boolean, default: false },
    },
    computed: {
      elUsers() {
        return (this.el.getUsers() || []).map((user) => {
          const companyUser = user.id && this.$store.getters['users/getUserById'](user.id);
          if (companyUser) {
            return {
              ...user,
              firstname: companyUser.firstname,
              lastname: companyUser.lastname,
              email: companyUser.email,
              avatar: companyUser.avatar,
            };
          }
          const companyGroup = user.group_id && this.$store.getters['users/groups/getGroupById'](user.group_id);
          if (companyGroup) return { ...user, title: companyGroup.title };
          return user;
        });
      },
      isOrganizationLoaded() {
        const storeUsers = this.$store.state.users;
        return storeUsers.user.id && (! storeUsers.accessRight.hasOrganization || storeUsers.user.organization);
      },
    },
    methods: {
      isDeletedUser(userId) {
        return this.isOrganizationLoaded && ! this.$store.getters['users/getUserById'](userId); // isOrganizationLoaded avoid blinking for organization users outside user company
      },
      isDeletedGroup(groupId) {
        return this.$store.state.users.groups.loaded && ! this.$store.getters['users/groups/getGroupById'](groupId);
      },
      isLast(item, tab) {
        return item == tab[tab.length - 1];
      },
    },
  };
</script>
