<template>
  <div class="element-details-alert">
    <div class="display-flex align-center mb-2">
      <div class="mr-2 text-center" style="flex: 0 0 24px;">
        <v-icon color="primary" small>fas fa-alarm-exclamation</v-icon>
      </div>
      <label for="alerts" class="uppercase primary--text ma-0">{{ $t('ELEMENT-DETAIL.ALERTS') }}</label>
    </div>
    <div class="ml-6 pl-2">
      <div v-for="(alert, alertIndex) in el.alerts" class="hovervisible-activator display-flex align-start mb-2">
        <v-icon x-small class="mr-2 mt-1" style="color: inherit">fas fa-alarm-exclamation</v-icon>
        <div>
          {{ $tc('GLOBAL.DAYS_COUNT', alert.days) }}
          {{ $t(alert.relative == 'before' ? 'ELEMENT-DETAIL.BEFORE' : 'ELEMENT-DETAIL.AFTER') }}
          {{ $t(alert.relativeto == 'starttime' ? 'ELEMENT-DETAIL.START' : 'ELEMENT-DETAIL.END') }}
          {{ $t('ELEMENT-DETAIL.AT') }} {{ alert.hour }}{{ $t('ELEMENT-DETAIL.OCLOCK') }}
          <small class="ml-1 font-italic">({{ alert.getSendDate().format('llll') }})</small>
          <v-switch v-model="alert.sendothers" :label="$t('ELEMENT-DETAIL.SEND_OTHERS')" hide-details class="mt-0 pt-0 shrinked-component" tabindex="-1"
                    @click="toggleAlertSendothers(alert)">
          </v-switch>
        </div>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn icon small outlined color="errorred" class="hovervisible-item ml-auto" v-on="on" @click="removeAlert(alertIndex)">
              <v-icon small>far fa-trash-alt</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('ELEMENT-DETAIL.REMOVE_ALERT') }}</span>
        </v-tooltip>
      </div>
      <hr v-show="el.alerts && el.alerts.length" class="mx-auto my-4" style="border-top: 1px solid #ccc">
      <div class="display-flex align-end">
        <v-text-field id="alerts" v-model.number="newalert.days" hide-details type="number" min="0" max="99" class="pt-0 mt-0" style="flex: 0 0 40px !important"></v-text-field>
        <span class="mx-2 mb-1">{{ $tc('GLOBAL.DAYS', newalert.days) }}</span>
        <v-select v-model="newalert.relative" :items="[{ text: $t('ELEMENT-DETAIL.BEFORE'), value: 'before' }, { text: $t('ELEMENT-DETAIL.AFTER'), value: 'after' }]"
                  hide-details class="pt-0 mt-0">
        </v-select>
        <v-select v-if="! el.isType('milestone')" v-model="newalert.relativeto"
                  :items="[{ text: $t('ELEMENT-DETAIL.START'), value: 'starttime' }, { text: $t('ELEMENT-DETAIL.END'), value: 'endtime' }]"
                  hide-details class="pt-0 mt-0 ml-2">
        </v-select>
        <span class="mx-2 mb-1">{{ $t('ELEMENT-DETAIL.AT') }}</span>
        <v-text-field v-model.number="newalert.hour" hide-details type="number" min="0" max="23" class="pt-0 mt-0" style="flex: 0 0 40px !important"></v-text-field>
        <span class="ml-2 mb-1">{{ $t('ELEMENT-DETAIL.OCLOCK') }}</span>
      </div>
      <div class="align-center mt-2" style="display: flex">
        <strong class="mx-2">↪</strong>{{ newalertTime }}
        <v-btn color="accent" small rounded class="ml-auto" @click="addAlert()">{{ $t('ELEMENT-DETAIL.ADD') }}</v-btn>
      </div>
    </div>
  </div>
</template>

<style>
  .element-details-alert .v-input--switch, .element-details-alert .v-input--switch .theme--light.v-label {
    color: #333;
    font-size: inherit;
  }
</style>

<script>
  import Alert from '@/models/Alert';

  export default {
    props: {
      el: { type: Object, required: true },
    },
    data() {
      return {
        newalert: new Alert(this.el),
      };
    },
    computed: {
      newalertTime() {
        return this.newalert.getSendDate().format('llll');
      },
    },
    watch: {
      newalert: {
        handler() {
          this.newalert.updateEl(this.el);
        },
        deep: true,
      },
      'el.data.starttime': function () {
        this.newalert.updateEl(this.el);
      },
      'el.data.endtime': function () {
        this.newalert.updateEl(this.el);
      },
    },
    methods: {
      removeAlert(index) {
        if (index > -1 && index < this.el.alerts.length) {
          this.el.alerts[index].remove();
          this.el.alerts.splice(index, 1);
        }
      },
      toggleAlertSendothers(alert) {
        alert.save();
      },
      addAlert() {
        const newAlert = angular.copy(this.newalert);
        if (! this.el.alerts) this.el.alerts = [];
        this.el.alerts.push(newAlert);
        newAlert.save();
      },
    },
  };
</script>
