<template>
  <div v-if="invites.length">
    <b class="primary--text text-uppercase">{{ $t('MANAGE.INVITES.COMPANY_INVITES') }}</b>
    <div class="ml-6 mb-6 mt-1">
      <b>{{ $t('MANAGE.INVITES.WARNING') }}</b>
      <div v-for="invite in invites" class="mt-2 mb-4">
        {{ invite.inviter_name }} {{ $t('MANAGE.INVITES.INVITES_YOU_TO_JOIN') }} « {{ invite.company_name }} »
        <div class="mt-1">
          <v-btn :loading="isLoading" color="accent" rounded @click="joinCompany(invite.company_id)">{{ $t('MANAGE.INVITES.ACCEPT_INVITE') }}</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        invites: [],
        isLoading: false,
      };
    },
    created() {
      window.apiSrv.call('companyinvites', 'index').then((response) => {
        this.invites = response.data && response.data.invites || [];
      });
    },
    methods: {
      joinCompany(id) {
        this.isLoading = true;
        window.apiSrv.call(`companyinvites/accept/${id}`).then(() => {
          window.location.reload();
        }).catch(() => {
          this.isLoading = false;
        });
      },
    },
  };
</script>
