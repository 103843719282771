<template>
  <v-container fluid class="mx-auto" style="width: 80%">
    <v-row>
      <v-col class="display-flex" cols="6">
        <div class="text-center mt-6" style="width: 32px; flex: 0 0 auto !important;">
          <v-icon small color="primary">fas fa-arrows-alt-h</v-icon>
        </div>
        <v-select v-model="timelineStep" :items="availableTimelineOptions" :label="$t('TIMELINE.TIMELINE')" hide-details
                  @change="updateSubTimeline(); updateTimelineWidth()">
        </v-select>
      </v-col>
      <v-col class="display-flex" cols="6">
        <div style="width: 32px; flex: 0 0 auto !important;"></div>
        <v-select v-model="subtimelineStep" :items="availableSubtimelineOptions" :label="$t('TIMELINE.SUB_TIMELINE')" hide-details
                  @change="updateTimelineWidth()">
        </v-select>
      </v-col>
      <v-col cols="6" class="pt-0 display-flex">
        <div style="width: 32px; flex: 0 0 auto !important;"></div>
        <date-field v-model="hiddenBefore" :label="$t('ELEMENT-DETAIL.FROM')" no-null start-screen="MONTH" hide-details @input="updateTimelineWidth()"></date-field>
      </v-col>
      <v-col cols="6" class="pt-0 display-flex">
        <div style="width: 32px; flex: 0 0 auto !important;"></div>
        <date-field v-model="hiddenAfter" :label="$t('ELEMENT-DETAIL.TO')" no-null start-screen="MONTH" hide-details @input="updateTimelineWidth()"></date-field>
      </v-col>
      <v-col class="display-flex" cols="12">
        <div class="text-center mt-6" style="width: 32px; flex: 0 0 auto !important;">
          <v-icon small color="primary">fas fa-search</v-icon>
        </div>
        <v-select v-model.number="colwidth" :items="availableColWidthOptions" :label="$t('TIMELINE.TIMELINE_WIDTH')" hide-details></v-select>
      </v-col>
      <v-col class="display-flex" cols="12">
        <div class="text-center mt-6" style="width: 32px; flex: 0 0 auto !important;">
          <v-icon small color="primary">fas fa-globe-europe</v-icon>
        </div>
        <v-select v-model="timelocale" :label="$t('CONFIG.TIME_FORMAT')" :items="timelocaleOptions" hide-details></v-select>
      </v-col>
      <v-col cols="12">
        <v-divider class="mt-1"></v-divider>
      </v-col>
      <v-col class="display-flex" cols="12">
        <div class="text-center" style="width: 32px; flex: 0 0 auto !important;">
        </div>
        <div style="position: relative">
          <v-checkbox v-model="workdays" :label="$t('CONFIG.USE_WORKDAYS')" class="mt-0" hide-details></v-checkbox>
          <v-checkbox v-model="show_presenttime" :label="$t('CONFIG.SHOW_PRESENTTIME')" hide-details></v-checkbox>
          <v-checkbox v-model="show_timegrid" :label="$t('CONFIG.SHOW_TIMEGRID')" hide-details></v-checkbox>
        </div>
      </v-col>
      <v-btn color="accent" rounded large class="mt-6 mx-auto" @click="update()">{{ $t('CONFIG.CHANGE_TIMELINE') }}</v-btn>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    props: {
      timeline: { type: Object, required: true },
    },
    data() {
      const steps = this.timeline.getSteps();
      return {
        timelineStep: steps[0],
        subtimelineStep: steps.length >= 2 ? this.timeline.getLastStep() : 'none',
        hiddenBefore: this.timeline.getHiddenBefore(),
        hiddenAfter: this.timeline.getHiddenAfter(),
        timelocale: this.timeline.getTimelocale(),
        colwidth: this.timeline.getColWidth(),
        workdays: this.timeline.getWorkdays(),
        show_presenttime: this.timeline.getShowPresenttime(),
        show_timegrid: this.timeline.getShowTimegrid(),
        timelineOptions: [
          { value: 'years', text: this.$t('TIMELINE.YEARS') },
          { value: 'quarters', text: this.$t('TIMELINE.QUARTERS') },
          { value: 'months', text: this.$t('TIMELINE.MONTHS') },
          { value: 'weeks', text: this.$t('TIMELINE.WEEKS') },
          { value: 'days', text: this.$t('TIMELINE.DAYS') },
          { value: 'none', text: this.$t('TIMELINE.NONE') },
        ],
        availableColWidthOptions: [
          { value: 300, text: this.$t('TIMELINE.X-LARGE') },
          { value: 230, text: this.$t('TIMELINE.LARGE') },
          { value: 160, text: this.$t('TIMELINE.MEDIUM') },
          { value: 100, text: this.$t('TIMELINE.SMALL') },
          { value: 50, text: this.$t('TIMELINE.X-SMALL') },
        ],
        timelocaleOptions: [
          { value: 'en', text: "US English" },
          { value: 'en-gb', text: "UK English" },
          { value: 'fr', text: "Français" },
          { value: 'fr-ca', text: "Fr Canadien" },
        ],
      };
    },
    computed: {
      availableTimelineOptions() {
        return this.timelineOptions.filter(item => item.value != 'none');
      },
      availableSubtimelineOptions() {
        return this.timelineOptions.slice(this.timelineOptions.findIndex(item => item.value == this.timelineStep) + 1);
      },
    },
    watch: {
      hiddenBefore(newVal) {
        const newdateLimit = moment(newVal).startOf('day').add(1, this.timelineStep);
        if (this.hiddenAfter.isBefore(newdateLimit)) this.hiddenAfter = newdateLimit;
      },
      hiddenAfter(newVal) {
        const newdateLimit = moment(newVal).endOf('day').add(-1, this.timelineStep);
        if (newdateLimit.isBefore(this.hiddenBefore)) this.hiddenBefore = newdateLimit;
      },
    },
    methods: {
      updateSubTimeline() {
        this.subtimelineStep = this.availableSubtimelineOptions[0].value;
      },
      updateTimelineWidth() {
        const steps = [this.timelineStep];
        if (this.subtimelineStep !== 'none') steps.push(this.subtimelineStep);
        this.colwidth = this.timeline.bestColWidth(this.hiddenBefore, this.hiddenAfter, steps);
      },
      update() {
        const steps = [this.timelineStep];
        if (this.subtimelineStep !== 'none') steps.push(this.subtimelineStep);
        this.$emit('update', {
          hidden: { before: this.hiddenBefore.format(), after: this.hiddenAfter.format() },
          steps,
          timelocale: this.timelocale,
          colwidth: this.colwidth,
          workdays: this.workdays,
          show_presenttime: this.show_presenttime,
          show_timegrid: this.show_timegrid,
        });
      },
    },
  };
</script>
