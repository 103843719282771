<template>
  <modal @close="close">
    <v-container v-if="! planning" fluid class="pa-6">
      <div class="ml-8">
        <div class="display-flex align-center">
          <b class="text-h6 primary--text">{{ project.title || $t('PLANNING.UNNAMED_PROJECT') }}</b>
        </div>
        <div class="text-center py-12"><i class="fas fa-spinner fa-spin fa-2x fa-fw"></i></div>
      </div>
    </v-container>
    <project-sheet v-else :planning="planning" @update="updateProjectSheet"></project-sheet>
  </modal>
</template>

<script>
  import ProjectSrv from '@/components/Projects/ProjectSrv';
  import Planning from '@/models/Planning';
  import ProjectSheet from '@/components/ProjectSheet/ProjectSheet';

  export default {
    components: {
      ProjectSheet,
    },
    props: {
      project: { type: Object, required: true },
      readonly: { type: Boolean, default: false },
    },
    data() {
      return {
        planning: null,
      };
    },
    watch: {
      project: {
        handler() {
          ProjectSrv.get(this.project.id).then((content) => {
            this.planning = new Planning(content);
            if (this.readonly) this.planning.meta.access_right = 'consult';
          });
        },
        immediate: true,
      },
    },
    methods: {
      updateProjectSheet(values) {
        _.extend(this.planning.projectsheet, values);
        this.planning.saveAdmin({ projectsheet: this.planning.projectsheet });
      },
      close() {
        if (this.planning) {
          this.project.category = this.planning.meta.category;
          this.project.imgurl = this.planning.meta.imgurl;
        }
        this.$emit('close');
      },
    },
  };
</script>
