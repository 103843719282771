<template>
  <div>
    <v-list-item @click="isOpened = ! isOpened">
      <v-icon v-if="unseenNotifications.length" color="errorred" size="14" class="mr-2">fas fa-bell</v-icon>
      {{ $tc('NOTIFICATIONS.NOTIFICATIONS_COUNT', unseenNotifications.length) }}
    </v-list-item>
    <template v-if="isOpened">
      <v-list-item v-for="sectionNotif in notificationsBySection" :key="`${sectionNotif.section_type}|${sectionNotif.section_id}`"
                   :to="notifLink(sectionNotif)">
        <v-divider vertical class="ml-4 mr-2"></v-divider>
        <template v-if="sectionNotif.section_type == 'planning'">
          <v-icon size="14" class="mr-2">fas fa-folder-open</v-icon>
          <div style="max-height: 40px; overflow: hidden;">{{ projectTitles[sectionNotif.section_id] || '...' }}</div>
        </template>
        <template v-else>
          <v-icon size="14" class="mr-2">fas fa-check-square</v-icon>
        </template>
        <v-spacer></v-spacer>
        <v-icon color="errorred" size="14">fas fa-bell</v-icon>&nbsp;<span class="errorred--text mr-2">{{ sectionNotif.count }}</span>
      </v-list-item>
      <v-list-item @click="openNotificationsManager">
        <div class="ml-4"></div>
        <v-icon size="14" class="mr-2">fas fa-cog</v-icon>{{ $t('ACCOUNT.CONFIGURE_EMAILS') }}
      </v-list-item>
    </template>
    <v-divider></v-divider>
  </div>
</template>

<script>
  import ProjectSrv from '@/components/Projects/ProjectSrv';

  export default {
    data() {
      return {
        isOpened: false,
        projects: [],
      };
    },
    computed: {
      unseenNotifications() {
        return this.$store.getters['notifications/unseen'];
      },
      notificationsBySection() {
        const bySection = this.unseenNotifications.reduce((acc, notif) => {
          if (! acc[`${notif.section_type}|${notif.section_id}`]) acc[`${notif.section_type}|${notif.section_id}`] = { section_type: notif.section_type, section_id: notif.section_id, count: 0 };
          acc[`${notif.section_type}|${notif.section_id}`].count++;
          return acc;
        }, {});
        return Object.values(bySection);
      },
      projectTitles() {
        return this.projects.reduce((acc, project) => { acc[project.id] = project.title; return acc; }, {});
      },
    },
    watch: {
      isOpened() {
        if (this.projects.length || ! this.unseenNotifications.length) return;
        ProjectSrv.list(true).then((projects) => {
          this.projects = projects;
        });
      },
    },
    methods: {
      notifLink(sectionNotif) {
        if (sectionNotif.section_type == 'planning') {
          const project = this.projects.find(item => item.id == sectionNotif.section_id);
          if (! project) return `/planning/${sectionNotif.section_id}?open=notifications`;
          return `/${(project.access_right == 'consult' ? 'viewer/' : 'planning/') + project.id}/${window.slugify(project.title || "New project")}?open=notifications`;
        }
        return '/analysis/actions';
      },
      openNotificationsManager() {
        this.$emit('close');
        this.$store.commit('ui/navigation/setOpenedModal', 'notifications-manager');
      },
      closeModals() {
        this.$store.commit('ui/navigation/setOpenedModal', null);
      },
    },
  };
</script>
