<template>
  <v-toolbar flat color="white" extension-height="32" class="mb-2" style="position: relative; z-index: 200">
    <slot name="prepend"></slot>
    <template v-if="filters.includes('Projects')">
      <projects-filter @update="update"></projects-filter>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn small icon class="align-self-start mt-1 ml-1" style="margin-right: -4px" v-on="on" @click="reload()">
            <v-icon small color="rgba(0,0,0,0.54)">fas fa-sync-alt</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('MULTIPROJECTS.REFRESH_PROJECTS') }}</span>
      </v-tooltip>
      <slot name="append-projects"></slot>
      <v-divider vertical class="mx-2 mx-lg-4"></v-divider>
    </template>
    <template v-if="isBusiness && filters.includes('Groups')">
      <v-switch v-model="groupsOptions.useGroups" hide-details @change="update">
        <template #label>
          <small class="nobr" style="line-height: 1.2em">{{ $t('MULTIPROJECTS.GROUP_BY_TEAMS') }}</small>
        </template>
      </v-switch>
      <v-divider vertical class="mx-2 mx-lg-4"></v-divider>
    </template>
    <template v-if="isBusiness && filters.includes('GroupUsers')">
      <group-users-filter @update="update"></group-users-filter>
      <v-divider vertical class="mx-2 mx-lg-4"></v-divider>
    </template>
    <template v-if="filters.includes('Users')">
      <users-filter :use-action-users="useActionUsers" @update="update"></users-filter>
      <v-divider vertical class="mx-2 mx-lg-4"></v-divider>
    </template>
    <template v-if="filters.includes('Dates')">
      <div style="flex: 0 1 150px">
        <date-field v-model="selectedDates.starttime" :label="$t('MULTIPROJECTS.START')" prepend-icon="fas fa-calendar-alt" hide-details clearable
                    @input="setDates()">
        </date-field>
      </div>
      <div class="ml-4" style="flex: 0 1 150px">
        <date-field v-model="selectedDates.endtime" :label="$t('MULTIPROJECTS.END')" hide-details clearable
                    @input="setDates()">
        </date-field>
      </div>
      <v-divider v-if="filters.includes('Display')" vertical class="mx-2 mx-lg-4"></v-divider>
    </template>
    <template v-if="filters.includes('Display')">
      <v-select v-model="selectedDisplayList"
                :items="selectedDisplayOptions" :label="$t('MULTIPROJECTS.DISPLAY')"
                prepend-icon="fas fa-eye" hide-details multiple menu-props="offsetY"
                @change="updateDisplay">
        <template #prepend-item>
          <v-list-item class="d-block">
            <div v-if="canHideElements" class="display-flex mb-n1">
              <v-switch v-model="filterOptions.hide_elements" :label="$t('MULTIPROJECTS.HIDE_ELEMENTS')"
                        color="primary" hide-details class="pt-0"
                        @change="updateDisplay">
              </v-switch>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-icon color="info" class="mt-4 ml-2" v-on="on">far fa-question-circle</v-icon>
                </template>
                <span>{{ $t('MULTIPROJECTS.DISPLAY_WORKLOAD_PLAN_ONLY') }}</span>
              </v-tooltip>
            </div>
            <v-radio-group v-model="filterOptions.el_type" :disabled="canHideElements && filterOptions.hide_elements" row hide-details class="flex-nowrap mb-3"
                           @change="update">
              <v-radio :label="$t('MULTIPROJECTS.BUBBLES')" value="task"></v-radio>
              <v-radio :label="$t('MULTIPROJECTS.MILESTONES')" value="milestone"></v-radio>
              <v-radio :label="$t('MULTIPROJECTS.BOTH')" value="both" class="mr-0"></v-radio>
            </v-radio-group>
          </v-list-item>
          <v-divider></v-divider>
        </template>
        <template #selection="{ index }">
          <span v-if="index === 0">{{ selectedDisplayList.length }}/{{ selectedDisplayOptions.length }}</span>
        </template>
      </v-select>
    </template>
    <slot></slot>
    <template v-if="! hideSaveFilters">
      <v-divider vertical class="ml-2 ml-xl-4"></v-divider>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <div style="margin-right: -12px">
            <v-btn small icon class="ml-1" v-on="on" @click="saveFilters"><v-icon small color="secondary">{{ filtersSaved ? 'fas fa-check successgreen--text' : 'fas fa-save' }}</v-icon></v-btn>
          </div>
        </template>
        <span>{{ $t('MULTIPROJECTS.SAVE_DEFAULT_FILTERS') }}</span>
      </v-tooltip>
    </template>

    <template v-if="showUpdateButton" #extension>
      <v-btn color="accent" small rounded class="mt-n2 mx-12 flex-grow-1" @click="$emit('update'); showUpdateButton = false">
        {{ $t('MULTIPROJECTS.APPLY_FILTERS') }}
      </v-btn>
    </template>
  </v-toolbar>
</template>

<style>
  .v-input--radio-group--row.flex-nowrap .v-input--radio-group__input {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
</style>

<script>
  import { mapState, mapActions } from 'vuex';
  import ProjectsFilter from './ProjectsFilter';
  import GroupUsersFilter from './GroupUsersFilter';
  import UsersFilter from './UsersFilter';

  export default {
    components: {
      ProjectsFilter,
      GroupUsersFilter,
      UsersFilter,
    },
    props: {
      filters: { type: Array, default: () => [] },
      useActionUsers: { type: Boolean, default: false },
      hideSaveFilters: { type: Boolean, default: false },
      canHideElements: { type: Boolean, default: false },
      manualUpdate: { type: Boolean, default: false }, // user will have to click the update button to send update event
    },
    data() {
      return {
        selectedDisplayOptions: [
          { value: 'projecttitle', text: this.$t('MULTIPROJECTS.PROJECT_TITLE') },
          { value: 'description', text: this.$t('MULTIPROJECTS.DESCRIPTION') },
          { value: 'checklist', text: this.$t('MULTIPROJECTS.CHECKLIST') },
          { value: 'progress', text: this.$t('MULTIPROJECTS.PROGRESS') },
          { value: 'users', text: this.$t('MULTIPROJECTS.PARTICIPANTS') },
          { value: 'dates', text: this.$t('MULTIPROJECTS.DATES') },
          { value: 'links', text: this.$t('MULTIPROJECTS.LINKS') },
          { value: 'budgets', text: this.$t('MULTIPROJECTS.BUDGET') },
        ],
        filtersSaved: false,
        showUpdateButton: false,
      };
    },
    computed: {
      selectedDisplayList: {
        get() {
          return Object.keys(this.selectedDisplay).filter(item => this.selectedDisplay[item]);
        },
        set(newVal) {
          Object.keys(this.selectedDisplay).forEach((item) => {
            this.selectedDisplay[item] = newVal.includes(item);
          });
        },
      },
      isBusiness() { return this.$store.state.users.accessRight.isBusiness; },
      ...mapState('multiprojects', ['selectedDates', 'selectedDisplay', 'filterOptions', 'groupsOptions']),
    },
    methods: {
      update() {
        if (this.manualUpdate) {
          this.showUpdateButton = true;
        } else {
          this.updateDebounced();
        }
      },
      updateDebounced: _.debounce(function () {
        this.$emit('update');
      }, 1000),
      updateDisplay() {
        this.updateDisplayDebounced();
      },
      updateDisplayDebounced: _.debounce(function () {
        this.$emit('update-display');
      }, 1000),
      setDates() {
        this.update();
      },
      saveFilters() {
        const dashboardName = this.$route.path.slice(1).replace(/\//g, '_');
        this.$store.commit('multiprojects/saveFilters', dashboardName);
        this.filtersSaved = true;
        setTimeout(() => { this.filtersSaved = false; }, 5000);
      },
      ...mapActions('multiprojects', ['reload']),
    },
  };
</script>
