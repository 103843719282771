<template>
  <navigation :config="config">
    <!--    PLANNING TOOLBAR-->
    <template v-if="config == 'planning' || config == 'planningview'">
      <v-divider vertical></v-divider>
      <v-tooltip :value="saving.error || null" :color="saving.error ? 'errorred' : null" bottom>
        <template #activator="{ on }">
          <v-btn icon class="tour-save mx-0" style="cursor: default;" v-on="on" @click="save()">
            <v-icon v-show="! saving.inprogress && ! saving.success && ! saving.error" size="18">fas fa-save</v-icon>
            <v-icon v-show="saving.inprogress" size="18">fas fa-spinner fa-spin</v-icon>
            <v-icon v-show="saving.success" size="18">fas fa-check success--text</v-icon>
            <v-icon v-show="saving.error" class="pointer">fas fa-exclamation-triangle errorred--text</v-icon>
          </v-btn>
        </template>
        <span>
          <span v-show="! saving.inprogress && ! saving.success && ! saving.error">{{ $t('NAV.AUTOSAVE') }}</span>
          <span v-show="saving.inprogress">{{ $t('AUTOSAVE.SAVING') }}</span>
          <span v-show="saving.success">{{ $t('AUTOSAVE.SAVED') }}</span>
          <span v-show="saving.error">{{ saving.error }}</span>
        </span>
      </v-tooltip>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn :disabled="! undoable" icon class="tour-save" style="width: 32px; height: 32px;" v-on="on" @click="undo()">
            <v-icon size="18">fas fa-undo</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('NAV.UNDO') }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn :disabled="! redoable" icon class="tour-save" style="width: 32px; height: 32px;" v-on="on" @click="redo()">
            <v-icon size="18">fas fa-redo</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('NAV.REDO') }}</span>
      </v-tooltip>
      <planning-navigation-search :planning="planning" @open-element="openElement"></planning-navigation-search>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn v-draggable-button="{ type: 'task', start: draggableButtonStart, stop: draggableButtonStop }"
                 :ripple="false" fab small class="my-1 ml-2 mr-1 tour-add-bubble grab" style="height: 36px; width: 36px;" v-on="on">
            <div style="width: 24px; position: relative; display: flex: flex-direction: column; border-radius: 4px; overflow: hidden">
              <div class="white--text task-default-blue" style="height: 8px; width: 100%; line-height: 0;">...</div>
              <div style="height: 14px; width: 100%; background: rgba(216, 216, 253, 0.7);"></div>
              <div></div>
            </div>
          </v-btn>
        </template>
        <span>{{ $t('NAV.DRAG_AND_DROP_TO_ADD_BUBBLE') }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn v-draggable-button="{ type: 'milestone', start: draggableButtonStart, stop: draggableButtonStop }"
                 :ripple="false" fab small class="my-1 ml-1 tour-add-milestone grab" style="height: 36px; width: 36px;" v-on="on">
            <div class="milestone-default-blue">
              <v-icon style="vertical-align: middle;">fa icon-diamonds</v-icon>
            </div>
          </v-btn>
        </template>
        <span>{{ $t('NAV.DRAG_AND_DROP_TO_ADD_MILESTONE') }}</span>
      </v-tooltip>
      <v-divider vertical class="ml-4"></v-divider>
    </template>

    <v-spacer></v-spacer>

    <!--    PLANNING ACTIONS-->
    <v-tooltip bottom>
      <template #activator="{ on }">
        <v-btn rounded small depressed class="mx-1" style="height: 32px; min-width: 0" v-on="on" @click="openProjectSheet()">
          <v-icon color="primary" small>fas fa-flag</v-icon>
          <span class="hidden-md-and-down ml-2">{{ $t('NAV.PROJECT_SHEET') }}</span>
        </v-btn>
      </template>
      {{ $t('NAV.PROJECT_SHEET_DETAILS') }}
    </v-tooltip>
    <v-tooltip v-if="config == 'planning'" bottom>
      <template #activator="{ on }">
        <v-btn rounded small depressed class="tour-share mx-1" style="height: 32px; min-width: 0" v-on="on" @click="openShare()">
          <v-icon color="primary" small>fas fa-share-square</v-icon>
          <span class="hidden-md-and-down ml-2">{{ $t('NAV.SHARE') }}</span>
        </v-btn>
      </template>
      {{ $t('NAV.SHARE_THIS_PROJECT') }}
    </v-tooltip>
    <v-tooltip v-if="config == 'planning' || config == 'planningview'" bottom>
      <template #activator="{ on }">
        <v-btn rounded small depressed class="tour-export mx-1" style="height: 32px; min-width: 0" v-on="on" @click="openExport()">
          <v-icon color="primary" small>far fa-image</v-icon>
          <span class="hidden-md-and-down ml-2">{{ $t('NAV.EXPORT') }}</span>
        </v-btn>
      </template>
      {{ $t('NAV.EXPORT_TO_IMAGE_FILE') }}
    </v-tooltip>

    <!-- MODALS-->
    <modal v-if="configIsOpened" dark @close="configIsOpened = false">
      <config-window :planning="planning" :tabs="config == 'planning' ? null : ['projectsheet']" @open-element="openElement" @close="configIsOpened = false">
      </config-window>
    </modal>
    <modal v-if="shareIsOpened" dark @close="shareIsOpened = false">
      <share-window :planning="planning" @close="shareIsOpened = false"></share-window>
    </modal>
    <modal v-if="exportIsOpened" size="lg" dark @close="exportIsOpened = false">
      <export-window :planning="planning" :planning-view-id="planningViewId" @close="exportIsOpened = false"></export-window>
    </modal>
  </navigation>
</template>

<script>
  import { mapActions } from 'vuex';
  import Navigation from '@/components/Navigation/Navigation';
  import ConfigWindow from '@/components/Config/Config';
  import ShareWindow from '@/components/Share/Share';
  import ExportWindow from '@/components/Export/Export';
  import PlanningNavigationSearch from './PlanningNavigationSearch';

  export default {
    components: {
      Navigation,
      ConfigWindow,
      ShareWindow,
      ExportWindow,
      PlanningNavigationSearch,
    },
    props: {
      config: { type: String, default: '' },
      planning: { type: Object, required: true },
      planningViewId: { type: Number, default: 0 },
    },
    data() {
      return {
        configIsOpened: null,
        shareIsOpened: null,
        exportIsOpened: null,
      };
    },
    computed: {
      undoable() {
        return this.$store.getters['undoredo/isUndoable'];
      },
      redoable() {
        return this.$store.getters['undoredo/isRedoable'];
      },
      saving() {
        return this.$store.state.ui.planning.saving;
      },
      planningLoaded() {
        return this.planning.id;
      },
    },
    mounted() {
      if (this.config == 'planning' || this.config == 'planningview') {
        window.Mousetrap.bind('ctrl+z', (event) => { event.preventDefault(); this.undo(); });
        window.Mousetrap.bind('ctrl+y', (event) => { event.preventDefault(); this.redo(); });
      }
    },
    destroyed() {
      const hotkeys = ['ctrl+z', 'ctrl+y'];
      for (let i = 0; i < hotkeys.length; i++) {
        window.Mousetrap.unbind(hotkeys[i]);
      }
    },
    methods: {
      undo() {
        if (! this.planningLoaded || ! this.undoable) return;
        this.$store.dispatch('undoredo/undo');
      },
      redo() {
        if (! this.planningLoaded || ! this.redoable) return;
        this.$store.dispatch('undoredo/redo');
      },
      openProjectSheet() {
        if (! this.planningLoaded) return;
        this.configIsOpened = true;
      },
      openShare() {
        if (! this.planningLoaded) return;
        this.shareIsOpened = true;
      },
      openExport() {
        if (! this.planningLoaded) return;
        this.exportIsOpened = true;
      },
      draggableButtonStart() {
        this.$store.dispatch('selection/resetSelection');
      },
      draggableButtonStop(event, ui, type) {
        this.addElement({ type, el: null, x: ui.offset.left + ui.helper.width() / 2, y: ui.offset.top + ui.helper.height() / 2 });
      },
      openElement(el) {
        this.$store.dispatch('ui/planning/scrollToEl', el);
        this.$store.dispatch('ui/planning/openElementDetails', el);
      },
      ...mapActions('planning', ['save']),
      ...mapActions('ui/planning', ['addElement']),
    },
  };
</script>
