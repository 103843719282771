<template>
  <section id="export" class="modal-with-tabs">
    <div class="modal-with-tabs-header export-window">
      <div class="pt-6 px-4" style="width: 100%; background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,.5) 100%);">
        <v-tabs v-model="openedTabIndex" background-color="transparent" dark slider-color="primary" style="margin-bottom: -2px;">
          <v-tab href="#image">{{ $t('EXPORT.IMAGE_EXPORT') }}</v-tab>
          <v-tab href="#csv">{{ $t('EXPORT.CSV_EXPORT') }}</v-tab>
        </v-tabs>
      </div>
    </div>
    <div class="modal-with-tabs-body">
      <v-tabs-items v-model="openedTabIndex">
        <v-tab-item value="image">
          <v-container fluid class="px-6 pb-6 pt-3">
            <v-row>
              <v-col md="4" cols="12">
                <v-row>
                  <v-col class="display-flex" cols="12">
                    <div class="text-center mt-6" style="width: 32px; flex: 0 0 auto !important;">
                      <v-icon small color="primary">fas fa-calendar-alt</v-icon>
                    </div>
                    <date-field v-model="startdate" :label="$t('TIMELINE.START_DATE')" format="dddd DD MMMM YYYY" readonly no-null hide-details></date-field>
                  </v-col>
                  <v-col class="display-flex" cols="12">
                    <div class="text-center mt-6" style="width: 32px; flex: 0 0 auto !important;"></div>
                    <date-field v-model="enddate" :label="$t('TIMELINE.HORIZON_DATE')" format="dddd DD MMMM YYYY" readonly no-null hide-details></date-field>
                  </v-col>
                  <v-col class="display-flex" cols="12">
                    <div class="text-center mt-6" style="width: 32px; flex: 0 0 auto !important;">
                      <v-icon small color="primary">fas fa-arrows-alt-h</v-icon>
                    </div>
                    <v-select v-model="timeline" :items="availableTimelineOptions" :label="$t('TIMELINE.TIMELINE')" hide-details></v-select>
                  </v-col>
                  <v-col class="display-flex" cols="12">
                    <div style="width: 32px; flex: 0 0 auto !important;"></div>
                    <v-select v-model="subtimeline" :items="availableSubtimelineOptions" :label="$t('TIMELINE.SUB_TIMELINE')" hide-details></v-select>
                  </v-col>
                  <v-col class="display-flex" cols="12">
                    <div class="text-center mt-6" style="width: 32px; flex: 0 0 auto !important;">
                      <v-icon small color="primary">fas fa-search</v-icon>
                    </div>
                    <v-select v-model.number="colwidth" :items="availableColWidthOptions" :label="$t('TIMELINE.TIMELINE_WIDTH')" hide-details></v-select>
                  </v-col>
                  <v-col class="display-flex" cols="12">
                    <div class="text-center mt-6" style="width: 32px; flex: 0 0 auto !important;">
                      <v-icon small color="primary">fas fa-th-list</v-icon>
                    </div>
                    <v-select v-model="selectedLanesList" :items="availableLanes" :label="$t('EXPORT.LANES')" item-value="id" item-text="label" multiple hide-details>
                      <template #prepend-item>
                        <filter-toggle :selected-ids="selectedLanesList" :sublist-ids="availableLanesIds"
                                       @update="selectedLanesList = $event">
                          {{ $t('EXPORT.ALL_LANES') }} ({{ availableLanesIds.length }})
                        </filter-toggle>
                        <v-divider></v-divider>
                      </template>
                      <template #selection="{ item, index }">
                        <span v-if="selectedLanesList.length == 1">{{ item.label }}</span>
                        <span v-else-if="selectedLanesList.length == availableLanes.length && index === 0">{{ $t('EXPORT.ALL_LANES') }} ({{ availableLanes.length }})</span>
                        <span v-else-if="index === 0">{{ selectedLanesList.length }}/{{ availableLanes.length }}</span>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col class="display-flex" cols="12">
                    <div class="text-center mt-6" style="width: 32px; flex: 0 0 auto !important;">
                      <v-icon small color="primary">fas fa-image</v-icon>
                    </div>
                    <v-radio-group v-model="format" row hide-details>
                      <label aria-hidden="true" class="v-label v-label--active theme--light"
                             style="left: 0px; right: auto; position: absolute; transform: translateY(-18px) scale(0.75); transform-origin: top left;">
                        {{ $t('EXPORT.QUALITY') }}
                      </label>
                      <v-radio :label="$t('EXPORT.STANDARD')" value="img"></v-radio>
                      <v-tooltip :disabled="isPremium" bottom>
                        <template #activator="{ on }">
                          <v-radio :label="$t('EXPORT.HD')" :disabled="! isPremium" value="hd" v-on="on"></v-radio>
                        </template>
                        <span>{{ $t('PREMIUM.PREMIUM_FEATURE') }}</span>
                      </v-tooltip>
                      <v-tooltip :disabled="isPremium" bottom>
                        <template #activator="{ on }">
                          <v-radio :label="$t('EXPORT.PDF')" :disabled="! isPremium" value="pdf" v-on="on"></v-radio>
                        </template>
                        <span>{{ $t('PREMIUM.PREMIUM_FEATURE') }}</span>
                      </v-tooltip>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-col>

              <v-col md="8" cols="12">
                <v-card class="pa-4" style="height: 100%; display: flex; flex-direction: column;">
                  <div class="display-flex align-center">
                    <v-btn color="accent" rounded large @click="refreshExport()">
                      <span v-if="format == 'img'">{{ $t('EXPORT.GENERATE_IMAGE') }}</span>
                      <span v-else-if="format == 'hd'">{{ $t('EXPORT.GENERATE_HD') }}</span>
                      <span v-else-if="format == 'pdf'">{{ $t('EXPORT.GENERATE_PDF') }}</span>
                    </v-btn>
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <v-btn :href="imgurl" :disabled="! imgurl" fab small elevation="2" color="primary" target="_blank" class="ml-auto" v-on="on">
                          <v-icon size="20">fas fa-file-download</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('EXPORT.SAVE_FILE') }}</span>
                    </v-tooltip>
                  </div>
                  <v-divider class="my-3"></v-divider>
                  <div v-show="errormsg" class="pa-4">{{ errormsg }}</div>
                  <div style="min-height:300px;position:relative;margin-bottom: 15px;flex:1 0 auto">
                    <a v-if="imgformat != 'pdf'" v-show="imgurl" :href="imgurl" target="_blank">
                      <img :src="imgurl" style="width:100%">
                    </a>
                    <!-- object needs to be destroyed with v-if to reload correctly with new data -->
                    <object v-if="imgformat == 'pdf' && imgurl && ! imgloading" :data="imgurl" type="application/pdf" width="100%" height="100%"
                            style="height: 100%; width: 100%; min-height: 300px;">
                      <p>This browser does not support PDFs. Please download the PDF to view it.</p>
                    </object>
                    <div v-show="imgloading" style="position:absolute;top:0;left:0;width:100%;height:100%;background:#eee;background:rgba(200,200,200,.8)">
                      <span style="position: absolute;top:50%;left:50%;margin-top: -15px;"><i class="fas fa-spinner fa-spin fa-2x fa-fw"></i></span>
                    </div>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="csv">
          <export-csv :planning="planning"></export-csv>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <div class="modal-with-tabs-footer"></div>
  </section>
</template>

<style>
  .modal-with-tabs-header.export-window {
    background-image: url('../../img/export.jpg');
    background-position: top center;
  }
</style>

<script>
  import ExportCsv from './ExportCsv';

  export default {
    components: {
      ExportCsv,
    },
    props: {
      planning: { type: Object, required: true },
      planningViewId: { type: Number, default: 0 },
    },
    data() {
      return {
        openedTabIndex: null,
        options: { id: this.planningViewId || this.planning.id, rotoken: this.planning.meta.read_only_token, hd: '', pdf: '', urlparams: '' },
        imgloading: false,
        imgformat: '',
        imgurl: null,
        errormsg: "",
        timeline: this.planning.timeline.steps[0],
        subtimeline: this.planning.timeline.steps.length >= 2 ? this.planning.timeline.steps.last() : 'none',
        timelocale: this.planning.timeline.timelocale,
        colwidth: this.planning.timeline.colwidth,
        selectedLanesList: [],
        format: 'img',
        timelineOptions: [
          { value: 'years', text: this.$t('TIMELINE.YEARS') },
          { value: 'quarters', text: this.$t('TIMELINE.QUARTERS') },
          { value: 'months', text: this.$t('TIMELINE.MONTHS') },
          { value: 'weeks', text: this.$t('TIMELINE.WEEKS') },
          { value: 'days', text: this.$t('TIMELINE.DAYS') },
          { value: 'none', text: this.$t('TIMELINE.NONE') },
        ],
        availableColWidthOptions: [
          { value: 300, text: this.$t('TIMELINE.X-LARGE') },
          { value: 230, text: this.$t('TIMELINE.LARGE') },
          { value: 160, text: this.$t('TIMELINE.MEDIUM') },
          { value: 100, text: this.$t('TIMELINE.SMALL') },
          { value: 50, text: this.$t('TIMELINE.X-SMALL') },
        ],
        startdate: this.planning.visibleTimeline.starttime,
        enddate: this.planning.visibleTimeline.endtime,
      };
    },
    computed: {
      availableLanes() {
        return this.planning.lanes;
      },
      availableLanesIds() {
        return this.availableLanes.map(item => item.id);
      },
      availableTimelineOptions() {
        return this.timelineOptions.filter(item => item.value != 'none');
      },
      availableSubtimelineOptions() {
        return this.timelineOptions.slice(this.timelineOptions.findIndex(item => item.value == this.timeline) + 1);
      },
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
    },
    watch: {
      timeline() {
        this.subtimeline = this.availableSubtimelineOptions[0].value;
        this.updateTimelineWidth();
      },
      subtimeline() {
        this.updateTimelineWidth();
      },
      startdate() {
        this.updateTimelineWidth();
      },
      enddate() {
        this.updateTimelineWidth();
      },
    },
    created() {
      const subdomain = window.location.hostname.replace(/^(.*)\.inli\.fr/, '$1');
      if (subdomain != window.location.hostname) this.options.subdomain = subdomain;
      this.selectedLanesList = this.availableLanesIds.slice();
    },
    methods: {
      close() {
        this.$emit('close');
      },
      safeProjectName() {
        return this.planning.meta.title.replace(/<(?:.|\n)*?>/gm, ' ').replace(/&nbsp;/g, " ").replace(/&amp;/g, "&").replace(/&gt;/g, ">")
          .replace(/&lt;/g, "<")
          .replace(/\//g, ' ');
      },
      generateExport() {
        this.imgloading = true;
        const api = this.planningViewId ? 'view' : 'planning';
        this.imgformat = this.options.pdf ? 'pdf' : 'png';
        window.apiSrv.call(api, 'export', this.options).then((result) => {
          if (result.data) this.imgurl = `${result.data.imgurl}/${this.safeProjectName()}.${this.imgformat}`;
          this.imgloading = false;
        }).catch((message) => {
          this.errormsg = message || "Unable to load export : Please try again";
          this.imgloading = false;
        });
      },
      refreshExport() {
        this.errormsg = "";
        this.options.hd = this.format == 'hd' ? "1" : "";
        this.options.pdf = this.format == 'pdf' ? "1" : "";
        this.options.urlparams = `hiddenbefore=${moment(this.startdate).toISOString()}`
          + `&hiddenafter=${moment(this.enddate).toISOString()}`
          + `&steps=${this.timeline}${this.subtimeline !== 'none' ? `,${this.subtimeline}` : ""}`
          + `&colwidth=${this.colwidth}`
          + `&laneshidden=${_.without(this.availableLanesIds, ...this.selectedLanesList).join(",")}`;
        this.generateExport();
      },
      updateTimelineWidth() {
        const steps = [this.timeline];
        if (this.subtimeline !== 'none') steps.push(this.subtimeline);
        this.colwidth = this.planning.timeline.bestColWidth(this.startdate, this.enddate, steps);
      },
    },
  };
</script>
