function getInitialState() {
  return {
    inprogress: false,
    success: false,
    error: false,
    saveagain: false,
  };
}

export default {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    reset(state, values) {
      _.extend(state, getInitialState(), values);
    },
    start(state) {
      _.extend(state, { inprogress: true, success: false, error: false, saveagain: false });
    },
    success(state) {
      _.extend(state, { inprogress: false, success: true, error: false });
    },
    error(state, message) {
      _.extend(state, { inprogress: false, success: false, error: message || true });
    },
  },
};
