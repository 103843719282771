export default {
  methods: {
    workloadToDisplay(workload) { // Convert workload to hours if needed
      const { workloadInHours, workloadHoursPerDay } = this.$store.getters['users/accessRight/config'];
      if (! workload || ! workloadInHours) return workload;
      return Math.round(workload * workloadHoursPerDay * 100) / 100;
    },
    workloadToStore(workload) { // Convert workload to days if needed
      const { workloadInHours, workloadHoursPerDay } = this.$store.getters['users/accessRight/config'];
      if (! workload || ! workloadInHours) return workload;
      return Math.round(workload / workloadHoursPerDay * 10000) / 10000;
    },
  },
};
