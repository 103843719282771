function getInitialState() {
  return {
    view: null,
    dynamic: null,
    hovertimeline: false,
    newElementMenuPosition: { x: 0, y: 0 },
    newElementMenuLane: null,
    saving: {},
    snapshot: false,
    doNotUpdateSelection: null,
    doNotResetSelection: null,
    displayDependenciesErrors: false,
    errors: null,
    openedElement: null,
    openedColorPickerElement: null,
    openedDependenciesElement: null,
    openedMeetingElement: null,
    wrapperData: {},
  };
}

export default {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    reset(state, values) {
      _.extend(state, getInitialState(), values);
    },
    openElement(state, el = null) {
      state.openedElement = el;
    },
    openColorPickerElement(state, el = null) {
      state.openedColorPickerElement = el;
    },
    openDependenciesElement(state, el = null) {
      state.openedDependenciesElement = el;
    },
    openMeetingElement(state, el = null) {
      state.openedMeetingElement = el;
    },
    updateWrapperData(state, $wrapper) {
      state.wrapperData = { width: $wrapper.width(), position: $wrapper.position(), scrollLeft: $wrapper.scrollLeft() };
    },
    setDisplayDependenciesErrors(state, newVal) {
      state.displayDependenciesErrors = newVal;
    },
  },
  actions: {
    addElement({ state, dispatch, rootState: { planning } }, { type, el, x, y, laneId }) {
      let positionLeft;
      const helperwidth = (type == 'milestone') ? 70 : 130;
      if (! planning.lanes.length) return;
      // get lane
      let lane;
      if (laneId) {
        lane = planning.lanes.find(item => item.id == laneId);
      } else if (el) {
        lane = planning.lanes.find(item => item.id == el.getLaneId());
      } else if (! y) {
        [lane] = planning.lanes;
      } else { // draggableButton
        let i = 0;
        while (planning.lanes[i] && $(`#lane${planning.lanes[i].id}`).offset().top < y) i += 1;
        i -= 1;
        if (i < 0) return;
        lane = planning.lanes[i];
      }
      if (state.view && lane.access_right != 'modify') return;
      if (x) {
        const $col = $(`#lane${lane.id}`);
        positionLeft = x - helperwidth / 2 - $col.offset().left;
        if (positionLeft < -helperwidth || positionLeft > $col.width()) return;
      }
      const extraProps = state.view ? { access_right: 'modify' } : null;
      dispatch('planning/elements/addElements', { argstab: [{ type, lane, el, positionLeft }], extraProps }, { root: true }).then((newels) => {
        dispatch('selection/resetSelection', newels.map(item => item.id), { root: true });
      });
    },
    deleteElement({ state, commit, dispatch }, el) {
      if (state.openedElement && state.openedElement.id == el.id) commit('openElement', null);
      dispatch('planning/elements/deleteElements', { els: [el] }, { root: true });
      dispatch('selection/removeFromSelection', el.id, { root: true });
    },
    scrollToEl(context, el) {
      if (! el) return;
      const $el = $(`#el${el.id}`);
      if (! $el[0]) return;
      $(window).scrollTop($el.offset().top - 200);
      $("#table-wrapper").scrollLeft($el.position().left - $("#planningdrawer").offset().left + $("#table-wrapper").offset().left + 250);
    },
    openElementDetails({ commit, dispatch }, el) {
      const elNode = document.getElementById(`el${el.id}`);
      if (elNode && (elNode.classList.contains('is-dragging') || elNode.classList.contains('is-resizing'))) return; // do not open details after dragging / resize
      dispatch('planning/elements/startChangingElement', null, { root: true });
      dispatch('planning/config/startChangingConfig', null, { root: true });
      commit('openElement', el);
    },
    closeElementDetails({ state, commit, dispatch }, reason) {
      if (! state.openedElement) return;
      if (state.openedElement.dashboard_el) { // subproject
        dispatch('ui/subplanning/closeElementDetails', reason, { root: true });
        return;
      }
      if (reason == 'delete') {
        dispatch('deleteElement', state.openedElement);
      } else {
        dispatch('planning/config/changingConfig', null, { root: true });
        dispatch('planning/elements/changingElement', state.openedElement, { root: true });
        if (reason == 'select-color') {
          dispatch('openColorPicker', state.openedElement);
        }
      }
      commit('openElement', null);
    },
    openColorPicker({ commit, dispatch }, el) {
      dispatch('planning/elements/startChangingElement', null, { root: true });
      dispatch('planning/config/startChangingConfig', null, { root: true });
      commit('openColorPickerElement', el);
    },
    closeColorPicker({ state, commit, dispatch }) {
      if (! state.openedColorPickerElement) return;
      if (state.openedColorPickerElement.dashboard_el) { // subproject
        dispatch('ui/subplanning/closeColorPicker', null, { root: true });
        return;
      }
      dispatch('planning/config/changingConfig', null, { root: true });
      dispatch('planning/elements/changingElement', state.openedColorPickerElement, { root: true });
      commit('openColorPickerElement', null);
    },
    openDependencies({ commit, dispatch }, el) {
      dispatch('planning/elements/startChangingElement', null, { root: true });
      dispatch('planning/config/startChangingConfig', null, { root: true });
      commit('openDependenciesElement', el);
    },
    closeDependencies({ state, commit, dispatch }) {
      if (! state.openedDependenciesElement) return;
      dispatch('planning/config/changingConfig', null, { root: true });
      dispatch('planning/elements/changingElement', null, { root: true });
      commit('openDependenciesElement', null);
    },
    openMeeting({ commit }, el) {
      commit('openMeetingElement', el);
    },
    closeMeeting({ commit }) {
      commit('openMeetingElement', null);
    },
    resetSaving({ state }) {
      state.saving = { inprogress: false, saveagain: false, success: false, error: false };
    },
    startSaving({ state }) {
      state.saving = { inprogress: true, saveagain: false, success: false, error: false };
    },
    savingSuccess({ state }) {
      state.saving.success = true;
      state.saving.error = false;
      state.saving.inprogress = false;
    },
    savingError({ state }, message) {
      state.saving.success = false;
      state.saving.error = message || true;
      state.saving.inprogress = false;
    },
  },
};
