<template>
  <dashboards-filters :filters="dashboardFilters" use-action-users>
    <template #prepend>
      <client-custom-component v-if="customFilter" :custom-filter="customFilter" :name="customFilter.component"></client-custom-component>

      <v-select v-model="actionsOptions.groupBy"
                :items="groupByOptions"
                :label="$t('MONITORING_ACTIONS.FILTERS.GROUP_BY')" hide-details menu-props="offsetY" style="flex: 1 1 120px">
      </v-select>
      <div>
        <v-switch v-model="actionsOptions.displayCompleted" hide-details class="ml-4">
          <template #label>
            <small style="line-height: 1.2em">{{ $t('MONITORING_ACTIONS.FILTERS.DISPLAY_COMPLETED_ACTIONS') }}</small>
          </template>
        </v-switch>
        <v-switch v-model="actionsOptions.withWorkloadOnly" hide-details class="ml-4">
          <template #label>
            <small style="line-height: 1.2em">{{ $t('MONITORING_ACTIONS.FILTERS.WITH_WORKLOAD_ONLY') }}</small>
          </template>
        </v-switch>
      </div>
      <v-tooltip v-if="! (customFilter && customFilter.component == 'MetroActionsDashboardFilter')" :disabled="dropdownIsOpen" bottom>
        <template #activator="{ on: onTooltip }">
          <v-menu v-model="dropdownIsOpen" :close-on-content-click="false" offset-y left>
            <template #activator="{ on: onMenu }">
              <v-btn icon v-on="{ ...onTooltip, ...onMenu }">
                <v-badge :value="actionsOptions.title" bordered color="successgreen" dot>
                  <v-icon size="18">fas fa-search</v-icon>
                </v-badge>
              </v-btn>
            </template>
            <v-list class="pt-4 pb-8">
              <v-list-item class="d-block">
                <v-text-field :value="actionsOptions.title" :label="$t('MONITORING_ACTIONS.FILTERS.FILTER_ACTIONS')" :placeholder="$t('MONITORING_ACTIONS.FILTERS.SEARCH_TITLE')"
                              clearable hide-details autofocus
                              @change="actionsOptions.title = $event" @click:clear="actionsOptions.title = ''">
                </v-text-field>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <span>{{ $t('GLOBAL.SEARCH') }}</span>
      </v-tooltip>
      <v-divider vertical class="mx-2 mx-lg-4"></v-divider>
    </template>
  </dashboards-filters>
</template>

<script>
  import { mapState } from 'vuex';
  import clientConfig from '@/client_customs/config';
  import DashboardsFilters from '../DashboardsFilters/DashboardsFilters.vue';

  export default {
    components: {
      DashboardsFilters,
    },
    data() {
      return {
        dropdownIsOpen: null,
        customFilter: clientConfig.dashboards?.actionsDashboard?.customFilter,
      };
    },
    computed: {
      dashboardFilters() {
        return ['Projects', this.actionsOptions.groupBy == 'group' ? 'GroupUsers' : 'Users', 'Dates'];
      },
      groupByOptions() {
        const options = [{ value: 'user', text: this.$t('MULTIPROJECTS.USERS') }, { value: 'planning', text: this.$t('MULTIPROJECTS.PROJECTS') }];
        if (this.isBusiness) options.push({ value: 'group', text: this.$t('GLOBAL.GROUPS') });
        return options;
      },
      isBusiness() { return this.$store.state.users.accessRight.isBusiness; },
      ...mapState('multiprojects', ['actionsOptions']),
    },
    watch: {
      'actionsOptions.title': function () {
        this.dropdownIsOpen = false;
      },
    },
  };
</script>
