<template>
  <v-app :class="{ 'hidden-vue-app': $route.meta.isAngular }">
    <router-view name="navigation"></router-view>
    <v-main v-show="! $route.meta.isAngular">
      <v-alert v-if="isSlowBrowser" color="warningorange" type="warning" icon="fas fa-exclamation-triangle" prominent dismissible class="px-12 ma-0" style="border-radius: 0">
        Vous utilisez Internet Explorer, un navigateur ancien qui offre des performances bien en dessous des standards actuels.<br>
        Pour une meilleure expérience, <b>nous vous recommandons vivement de choisir un autre navigateur</b>.
      </v-alert>
      <login-window-modal></login-window-modal>
      <premium-suspended></premium-suspended>
      <zoho-chat></zoho-chat>
      <v-container id="vue-main-container" fluid class="pa-6" style="background-color: #f1f1f1; min-height: calc(100vh - 48px)">
        <router-view :key="$route.path"></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<style>
  .hidden-vue-app .v-application--wrap {
    min-height: 0 !important;
  }

  .mobileview #vue-main-container {
    padding: 0 !important;
  }
</style>

<script>
  import LoginWindowModal from '@/components/User/LoginWindowModal';
  import PremiumSuspended from '@/components/Premium/PremiumSuspended';
  import ZohoChat from '@/components/Help/ZohoChat';

  const ua = window.navigator.userAgent || '';
  const isSlowBrowser = ua.indexOf('Trident/') > 0 || ua.indexOf('MSIE ') > 0;

  export default {
    components: {
      LoginWindowModal,
      PremiumSuspended,
      ZohoChat,
    },
    data() {
      return {
        isSlowBrowser,
      };
    },
    created() {
      this.$store.state.users.userPromise.then((user) => {
        // new version popup
        if (user.last_visit && moment(user.last_visit).isBefore('2022-04-25') && moment().isBefore('2022-06-06')) {
          this.$store.dispatch('ui/msgbox/open', { title: "HELP.NEW_VERSION_TITLE", body: "HELP.NEW_VERSION", buttons: { ok: "HELP.NEW_VERSION_BUTTON" } });
        }
      });
    },
  };
</script>
