<template>
  <modal :backdrop-close="false" @close="dismiss('cancel')">
    <section class="modal-with-tabs">
      <div v-if="title" class="modal-with-tabs-header primary--text pa-4" style="border-bottom: 3px solid">
        <span class="text-h5">{{ $t(title) }}</span>
      </div>
      <div class="px-6" style="max-height: 90vh; flex: 1 1 auto; overflow-y: auto;">
        <div v-if="body" class="py-4" v-html="$t(body)"></div>
        <v-divider></v-divider>
        <div :style="{ 'justify-content': buttons.ok && buttons.cancel ? 'flex-end' : 'center' }" class="display-flex my-3">
          <v-btn v-if="buttons.cancel" color="secondary" rounded large outlined @click="dismiss('cancel')">{{ $t(buttons.cancel) }}</v-btn>
          <v-btn v-if="buttons.ok" color="primary" rounded large class="ml-3" @click="close()">{{ $t(buttons.ok) }}</v-btn>
        </div>
      </div>
    </section>
  </modal>
</template>

<script>
  export default {
    props: {
      title: { type: String, default: "" },
      body: { type: String, default: "" },
      buttons: { type: Object, default: () => ({ ok: 'GLOBAL.OK' }) },
    },
    methods: {
      close() {
        this.$emit('close');
      },
      dismiss(reason) {
        this.$emit('dismiss', reason);
      },
    },
  };
</script>
