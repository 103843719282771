<template>
  <div v-if="displayPremiumSuspended">
    <modal :closable="false" :backdrop-opacity=".8">
      <section class="modal-with-tabs">
        <div class="modal-with-tabs-header errorred--text pa-4 justify-center" style="border-bottom: 3px solid">
          <span class="text-h6 uppercase font-weight-bold">{{ $t('PREMIUM.ACCOUNT_SUSPENDED') }}</span>
        </div>
        <div class="modal-with-tabs-body pa-6" style="margin-bottom: 6px;">
          <b>{{ $t('PREMIUM.ACCOUNT_EXPIRED_SINCE') }} {{ premiumExpireDate | moment('date') }}.</b>
          <div>
            <h3 style="color: #0169aa;font-weight: bold;">{{ $t('PREMIUM.CONTINUE_PREMIUM') }}</h3>
            <p>{{ $t('PREMIUM.ADD_SUBSCRIPTION') }}</p>
            <div class="text-center">
              <v-btn :href="`https://bubbleplan.net/auth.php?token=${api_token}&redirect=mon-compte%23premium`" color="accent" rounded large>{{ $t('PREMIUM.UPDATE_ACCOUNT') }}</v-btn>
            </div>
          </div>

          <div style="margin: 30px 0">
            <h3 style="color: #0169aa; font-weight: bold;">{{ $t('PREMIUM.CONTINUE_FREE') }}</h3>
            <div v-html="$t('PREMIUM.FREE_DESC')"></div>
            <div class="text-center">
              <v-btn :disabled="resetingFree.inprogress" color="secondary" rounded large outlined @click="resetFreeAccount()">{{ $t('PREMIUM.ACTIVATE_FREE') }}</v-btn>
              <p v-show="resetingFree.error">{{ resetingFree.error }}</p>
            </div>
          </div>

          <p class="mt-2" v-html="$t('PREMIUM.CONTACT_US')"></p>
        </div>
      </section>
    </modal>
  </div>
  <div v-else-if="displayPremiumExpired">
    <v-alert type="error" class="px-12 ma-0" style="border-radius: 0">
      {{ $t('PREMIUM.ACCOUNT_EXPIRED_SINCE') }} {{ premiumExpireDate | moment('date') }}. {{ $t('PREMIUM.ACCOUNT_WILL_BE_SUSPENDED') }}
    </v-alert>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        premiumExpireDate: null,
        resetingFree: { inprogress: false, error: "" },
        api_token: window.apiSrv.getAuthToken(),
      };
    },
    computed: {
      displayPremiumSuspended() {
        return this.$store.state.ui.displayPremiumSuspended;
      },
      displayPremiumExpired() {
        return this.$store.state.ui.displayPremiumExpired;
      },
    },
    created() {
      this.$store.state.users.userPromise.then(() => {
        const { user } = this.$store.state.users;
        this.premiumExpireDate = user.company && user.company.premiumExpireDate;
      });
    },
    methods: {
      resetFreeAccount() {
        this.resetingFree = { inprogress: true, error: "" };
        window.apiSrv.call(`companies/${this.$store.state.users.user.company_id}/resetfree`, 'index').then(() => {
          window.location.href = ""; // reload
        }).catch((message) => {
          this.resetingFree = { inprogress: false, error: `Error : ${message}` };
        });
      },
    },
  };
</script>
