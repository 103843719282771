<template>
  <v-card class="views-color-acr display-flex px-4 py-2 mt-2 mx-2">
    <!--      COLOR PICKER-->
    <div>
      <b class="ml-1">{{ $t('CUSTOM_VIEWS.LANES_COLORS') }}</b>
      <div v-for="(colorData, colorIndex) in colors" :key="colorIndex">
        <div class="display-flex py-2">
          <div :style="{ background: colorData.main, opacity: laneColorAcr(colorIndex, null) == toggledAccessRight ? '' : '.2' }" class="color-square pointer"
               @click="selectLaneColor(colorIndex, null)">
          </div>
          <div v-for="(shadeData, shadeIndex) in colorData.shades" :key="shadeIndex"
               :style="{ background: shadeData, opacity: laneColorAcr(colorIndex, shadeIndex) == toggledAccessRight ? '' : '.2' }" class="color-square pointer"
               @click="selectLaneColor(colorIndex, shadeIndex)">
          </div>
        </div>
      </div>
    </div>
    <v-divider vertical class="mx-2"></v-divider>
    <div class="ml-1">
      <b class="ml-1">{{ $t('CUSTOM_VIEWS.BUBBLES_COLORS') }}</b>
      <div v-for="(colorData, colorIndex) in colors" :key="colorIndex">
        <div class="display-flex py-2">
          <div :style="{ background: colorData.main, opacity: elementColorAcr(colorIndex, null) == toggledAccessRight ? '' : '.2' }" class="color-square pointer"
               @click="selectElementColor(colorIndex, null)">
          </div>
          <div v-for="(shadeData, shadeIndex) in colorData.shades" :key="shadeIndex"
               :style="{ background: shadeData, opacity: elementColorAcr(colorIndex, shadeIndex) == toggledAccessRight ? '' : '.2' }" class="color-square pointer"
               @click="selectElementColor(colorIndex, shadeIndex)">
          </div>
        </div>
      </div>
    </div>
    <v-divider vertical class="mx-2"></v-divider>
    <div>
      <b class="ml-2">{{ $t('CUSTOM_VIEWS.ICONS') }}</b>
      <div class="mt-1"></div>
      <div :style="{ opacity: elementIconAcr('null') == toggledAccessRight ? '' : '.2' }" class="pointer ml-2 mb-1" style="padding: 3px;"
           @click="selectElementIcon('null')">
        {{ $t('CUSTOM_VIEWS.WITHOUT_ICON') }}
      </div>
      <div style="display:flex; flex-flow: wrap;">
        <div v-for="(iconData, iconCode) in icons" :key="iconCode" :style="{ opacity: elementIconAcr(iconCode) == toggledAccessRight ? '' : '.2' }"
             class="text-center" style="padding: 3px 3px 10px;width:16.666666%; overflow: hidden">
          <v-icon :color="iconData.color" dense class="pointer" @click="selectElementIcon(iconCode)">{{ iconData.name }}</v-icon>
        </div>
      </div>
    </div>
  </v-card>
</template>

<style>
  .views-color-acr .color-square {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    margin-top: 2px;
  }
</style>

<script>
  import viewsMixin from './viewsMixin';

  export default {
    mixins: [viewsMixin],
    props: {
      acr: { type: Object, required: true },
      toggledAccessRight: { type: String, default: 'modify' },
    },
    computed: {
      colors() {
        return this.$store.state.planning.config.colors;
      },
      icons() {
        return this.$store.state.planning.config.icons;
      },
    },
    created() {
      this.initAcr();
    },
    updated() {
      this.initAcr();
    },
    methods: {
      initAcr() {
        // LR Universités defaults
        if (! this.acr.default) this.$set(this.acr, 'default', { access_right: 'none' });
        if (! this.acr.lanes) this.$set(this.acr, 'lanes', {});
        if (! this.acr.lanes.colors) this.$set(this.acr.lanes, 'colors', { default: { access_right: 'modify' } });

        if (! this.acr.elements) this.$set(this.acr, 'elements', {});
        if (! this.acr.elements.colors) this.$set(this.acr.elements, 'colors', { default: { access_right: 'modify' } });
        if (! this.acr.elements.icons) this.$set(this.acr.elements, 'icons', { default: { access_right: 'modify' } });
      },
      selectColor(type, colorIndex, shadeIndex) {
        const colorsAcr = this.acr[type].colors;
        const colorKey = `color${colorIndex}`;
        if (! colorsAcr[colorKey]) this.$set(colorsAcr, colorKey, {});
        const selectedColorAcr = colorsAcr[colorKey];
        if (shadeIndex == null) {
          if (selectedColorAcr.access_right == this.toggledAccessRight) {
            this.$delete(selectedColorAcr, 'access_right');
          } else {
            this.$set(selectedColorAcr, 'access_right', this.toggledAccessRight);
          }
        } else {
          const shadeKey = `shade${shadeIndex}`;
          if (! selectedColorAcr.shades) this.$set(selectedColorAcr, 'shades', {});
          if (! selectedColorAcr.shades[shadeKey]) this.$set(selectedColorAcr.shades, shadeKey, {});
          if (selectedColorAcr.shades[shadeKey].access_right == this.toggledAccessRight) {
            this.$delete(selectedColorAcr.shades, shadeKey);
          } else {
            this.$set(selectedColorAcr.shades[shadeKey], 'access_right', this.toggledAccessRight);
          }
        }
        if (! selectedColorAcr.access_right && _.isEmpty(selectedColorAcr.shades)) this.$delete(colorsAcr, colorKey);
        delete colorsAcr.default;
        colorsAcr.default = { access_right: _.isEmpty(colorsAcr) ? 'modify' : 'none' };
      },
      selectLaneColor(colorIndex, shadeIndex) {
        this.selectColor('lanes', colorIndex, shadeIndex);
        this.$emit('update');
      },
      selectElementColor(colorIndex, shadeIndex) {
        this.selectColor('elements', colorIndex, shadeIndex);
        this.$emit('update');
      },
      selectElementIcon(iconCode) {
        const iconsAcr = this.acr.elements.icons;
        if (! iconsAcr[iconCode]) this.$set(iconsAcr, iconCode, {});
        const selectedIconAcr = iconsAcr[iconCode];
        if (selectedIconAcr.access_right == this.toggledAccessRight) {
          this.$delete(iconsAcr, iconCode);
        } else {
          this.$set(selectedIconAcr, 'access_right', this.toggledAccessRight);
        }
        delete iconsAcr.default;
        iconsAcr.default = { access_right: _.isEmpty(iconsAcr) ? 'modify' : 'none' };
        this.$emit('update');
      },
    },
  };
</script>
