import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import store from '@/store';
import VueApp from '@/components/App';
import vuetify from './plugins/vuetify';
import router from './router';

require('@/js/vuefilters.js');
require('@/js/vuedirectives.js');
require('@/js/vuecomponents.js');

/* SENTRY */
if (window.location.hostname != 'localhost') {
  Sentry.init({
    Vue,
    dsn: 'https://840ce42962d84f0f9ef35ed5441bf12b@sentry.io/266350',
    release: 'planning@2022-04',
    logErrors: true,
    ignoreErrors: ["_clickOutside", "ShadowRoot", "lastMousedownWasOutside"],
    denyUrls: [/graph\.facebook\.com/i, /connect\.facebook\.net\/en_US\/all\.js/i, /eatdifferent\.com\.woopra-ns\.com/i, /static\.woopra\.com\/js\/woopra\.js/i,
      /extensions\//i, /safari-extension:/i, /chrome-extension:/i, /^chrome:\/\//i, /127\.0\.0\.1:4001\/isrunning/i, /webappstoolbarba\.texthelp\.com\//i, /metrics\.itunes\.apple\.com\.edgesuite\.net\//i],
  });
}

const app = new Vue({
  vuetify,
  router,
  store,
  i18n: store.state.lang.i18n,
  components: {
    VueApp,
  },
}).$mount('#vue-app');

store.dispatch('users/load');

export default app;
