<template>
  <div v-droppable="droppableConfig(lane)" :style="{ height: `${laneHeight}px` }" class="lane"
       @contextmenu="openNewElementMenu(lane, $event)">
    <!--    WORKLOADS     -->
    <lane-workloads :planning="planning" :lane="lane" :adapt-workloads-height="hideElements"></lane-workloads>
    <!--    END WORKLOADS     -->
    <planning-element-view v-for="el in laneElements" :id="`el${el.id}`" :key="el.id"
                           v-draggable="draggableConfig(el)" v-resizable="resizableConfig(el)" :el="el"
                           :style="elementStyle(el)" :title="el.display_title" :show-details-items="isSubLane ? subprojectsVisibleDetails : null"
                           :class="{ 'placeholder-el': el.isDragPlaceholder, selectable: isDynamic(el) }"
                           @select-color="selectColor(el)" @checklist-click="checklistClick(el, $event)" @progress-click="progressClick(el, $event)"
                           @dyn-subtasks-click="dynSubtasksClick(el, $event)"
                           @click.native.stop="elementClick($event, el)" @open-meeting="openMeeting(el)" @open-element="openElement($event)">
      <!-- ELEMENT MENU -->
      <template #hover-menu>
        <div :class="positionElementMenuRight(el) ? 'text-left' : 'text-right'"
             :style="{ left: positionElementMenuRight(el) ? `${el.getWidth()}px` : null, right: positionElementMenuRight(el) ? null : `${el.getWidth()}px`,
                       'margin-top': el.isType('macro', 'task') && el.hasIcon() ? '22px' : '0' }"
             class="element-menu"
             @click.stop>
          <div v-if="el.isType('macro', 'task') && isMiniElement(el)"
               :style="{ background: el.getMainColor(), color: 'white', 'right': positionElementMenuRight(el) ? 'auto' : '5px' }"
               class="text-left element-menu-title">
            {{ el.getTitle() || defaultTitle(el) }}
          </div>
          <div v-if="isDynamic(el)" :style="{ width: el.isType('macro') ? '30px' : '60px' }">
            <v-btn :title="$t(el.isType('macro', 'task') ? 'PLANNING.SELECT_ICON_AND_COLOR' : 'PLANNING.SELECT_ICON')"
                   icon x-small outlined color="midgrey lighten-1"
                   @click.stop="selectColor(el)">
              <v-icon color="accent" size="16">fas fa-tint</v-icon>
            </v-btn>
            <v-btn v-if="! view && ! el.isType('macro')" :title="$t('PLANNING.DEPENDENCIES')" icon x-small outlined color="midgrey lighten-1" @click.stop="openDependencies(el)">
              <v-icon color="secondary" size="16">fas fa-link</v-icon>
            </v-btn>
            <v-btn v-if="! el.isType('macro') && ! el.isFromTemplate()" :title="$t('PLANNING.DUPLICATE')" icon x-small outlined color="midgrey lighten-1" @click.stop="duplicateElement(el)">
              <v-icon color="info" size="16">far fa-copy</v-icon>
            </v-btn>
            <v-btn v-if="! el.isFromTemplate()" :title="$t('PLANNING.DELETE')" icon x-small outlined color="midgrey lighten-1" @click.stop="deleteElement(el)">
              <v-icon color="errorred" size="16">far fa-trash-alt</v-icon>
            </v-btn>
            <v-btn v-if="! accessRight.hideBubbleMeeting && el.isType('milestone')" :title="$t('MEETING.BUBBLE_MEETING')" icon x-small outlined color="midgrey lighten-1"
                   @click.stop="openMeeting(el)">
              <v-icon color="meetingprimary" size="16">fas fa-users</v-icon>
            </v-btn>
          </div>
        </div>
      </template>
      <!-- END ELEMENT MENU -->
      <!-- PLACEHOLDER INFO -->
      <div v-if="el.isDragPlaceholder || (el.isResizePlaceholder && el.isType('macro', 'task'))" class="placeholder-details">
        <div style="position:absolute;top: 0; right: 0; margin-right: 100%; padding-right: 5px" class="nobr">
          {{ el.getStartTime() | moment('mediumDateNoYear', true) }}
        </div>
        <div v-if="el.isType('macro', 'task')" style="position:absolute; top: 0; left: 0; margin-left: 100%; padding-left: 5px" class="nobr">
          {{ el.getEndTime() | moment('mediumDateNoYear', true) }}
        </div>
      </div>
      <!-- END PLACEHOLDER INFO -->
      <!-- UPDATE USER TOOLTIP -->
      <planning-element-view-modification-user v-if="el.modificationUser" :el="el"></planning-element-view-modification-user>
      <!-- END UPDATE USER TOOLTIP -->
    </planning-element-view>
  </div>
</template>

<style>
  .element-menu .element-menu-title {
    min-width: 50px;
    max-width: 200px;
    max-height: 64px;
    padding: 2px 3px;
    border-radius: 4px;
    margin-bottom: 3px;
    margin-right: 3px;
    overflow: hidden;
  }
</style>

<script>
  import { mapActions } from 'vuex';
  import clientConfig from '@/client_customs/config';
  import constants from '@/js/constants.js';
  import LaneWorkloads from '@/components/Dashboards/PlanningUsers/LaneWorkloads';
  import PlanningElementView from './PlanningElementView';
  import PlanningElementViewModificationUser from './PlanningElementViewModificationUser';
  import laneHeight from './mixins/laneHeight';
  import draggable from './mixins/draggable';
  import droppable from './mixins/droppable';
  import resizable from './mixins/resizable';
  import selectable from './mixins/selectable';

  export default {
    components: {
      LaneWorkloads,
      PlanningElementView,
      PlanningElementViewModificationUser,
    },
    mixins: [laneHeight, draggable, droppable, resizable, selectable],
    props: {
      planning: { type: Object, required: true },
      lane: { type: Object, required: true },
      dynamic: { type: Boolean, default: false },
      view: { type: Boolean, default: false },
      isSubLane: { type: Boolean, default: false },
      options: { type: Object, default: () => ({}) },
      events: { type: Object, default: () => ({}) },
    },
    data() {
      return {
        subprojectsVisibleDetails: this.$store.getters['users/accessRight/config'].subprojectsVisibleDetails,
      };
    },
    computed: {
      laneElements() {
        if (this.lane.hidden || this.hideElements) return [];
        const laneElements = this.$store.getters['planning/lanes/getLaneElements']({ planning: this.planning, laneId: this.lane.id });
        return laneElements.filter(el => el.visible);
      },
      hideElements() {
        return this.options.elements?.hideElements;
      },
      accessRight() { return this.$store.state.users.accessRight; },
    },
    methods: {
      isDynamic(el) {
        return this.dynamic && (! this.view || el.access_right == 'modify');
      },
      canEditEl(el) {
        return this.options.elements && this.options.elements.canEditEl && this.options.elements.canEditEl(el) || this.isDynamic(el);
      },
      canModifyLane(lane) {
        return this.dynamic && (! this.view || lane.access_right == 'modify');
      },
      openNewElementMenu(lane, event) {
        if (event.shiftKey) return; // shift to shorcut the menu
        event.preventDefault();
        if (! this.canModifyLane(this.lane)) return;
        const x = event.pageX - $(window).scrollLeft();
        const y = event.pageY - $(window).scrollTop();

        this.$store.state.ui.planning.newElementMenuPosition = { x, y };
        this.$store.state.ui.planning.newElementMenuLane = lane;
      },
      elementClick(event, el) {
        if (! this.canEditEl(el)) return;
        this.selectableClick(event, el);
        if (event.ctrlKey) return; // hold ctrl for selection only

        if (this.events.elementClick) {
          this.events.elementClick(el);
          return;
        }
        this.openElementDetails(el);
      },
      selectColor(el) {
        if (! this.canEditEl(el)) return;
        if (this.events.selectColor) {
          this.events.selectColor(el);
          return;
        }
        this.openColorPicker(el);
      },
      openDependencies(el) {
        if (! this.isDynamic(el)) return;
        this.$emit('open-dependencies', el);
      },
      duplicateElement(el) {
        if (clientConfig.elements && clientConfig.elements.customDuplicate) {
          clientConfig.elements.customDuplicate.cb.call(this, el);
          return;
        }
        this.addElement({ type: el.getType(), el });
      },
      openMeeting(el) {
        if (! this.isDynamic(el)) return;
        this.$emit('open-meeting', el);
      },
      elementStyle(el) {
        return {
          left: `${el.xposition}px`,
          width: `${el.getWidth()}px`,
          top: `${el.yposition}px`,
          cursor: this.canEditEl(el) ? 'pointer' : 'default',
        };
      },
      positionElementMenuRight(el) {
        let { wrapperData } = this.$store.state.ui.planning;
        if (! wrapperData.width) {
          this.$store.commit('ui/planning/updateWrapperData', $("#table-wrapper"));
          wrapperData = this.$store.state.ui.planning.wrapperData;
        }
        const elementFreeSpaceRight = (wrapperData.width + wrapperData.scrollLeft) - (el.xposition + el.getWidth());
        return elementFreeSpaceRight > 60;
      },
      defaultTitle(el) {
        return el.isType('macro') ? this.$t('PLANNING.NEW_MACRO_BUBBLE') : this.$t('PLANNING.NEW_BUBBLE');
      },
      isMiniElement(el) {
        return el.getWidth() <= constants.miniElementBreakWidth;
      },
      checklistClick(el, item) {
        if (! this.canEditEl(el)) return;
        if (this.events.checklistClick) {
          this.events.checklistClick(el, item);
          return;
        }
        this.startChangingElement();
        this.$set(item, 'checked', ! item.checked);
        // el.updateChecklistProgress();
        this.changingElement(el);
      },
      dynSubtasksClick(el, item) {
        if (! this.canEditEl(el)) return;
        this.startChangingElement();
        this.$set(item, 'checked', ! item.checked);
        el.updateChecklistProgress();
        this.changingElement(el);
      },
      progressClick(el, event) {
        if (! this.canEditEl(el)) return;
        if (this.events.progressClick) {
          this.events.progressClick(el, event);
          return;
        }
        this.startChangingElement();
        const fullWidth = $(event.currentTarget).width();
        if (! fullWidth) return;
        el.setProgress(Math.min(Math.round(event.offsetX / fullWidth * 10) * 10, 100));
        this.changingElement(el);
      },
      openElement(elId) {
        const el = this.planning.elements.find(item => item.id == elId);
        if (! el || ! this.canEditEl(el)) return;
        this.$store.dispatch('ui/planning/openElementDetails', el);
      },
      ...mapActions('planning/elements', ['startChangingElement', 'changingElement']),
      ...mapActions('ui/planning', ['openElementDetails', 'openColorPicker', 'addElement', 'deleteElement']),
    },
  };
</script>
