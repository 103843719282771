<template>
  <v-container id="manage" class="py-0">
    <v-card class="display-flex pa-4 pb-12" style="min-height: 70vh">
      <div> <!-- sticky not working with flex element -->
        <div style="position: sticky; top: 70px;">
          <v-tabs v-model="currentTabIndex" vertical slider-color="primary" background-color="white"
                  active-class="primary lighten-1 white--text">
            <v-tab class="manage-tab-pill" href="#users">
              <v-icon size="20" class="mr-2" style="color: inherit; transition: none;">fas fa-users</v-icon>
              <span class="hidden-sm-and-down" style="font-size: 16px">{{ $t('MANAGE.COMPANY_USERS') }}</span>
            </v-tab>
            <v-tab class="manage-tab-pill" href="#projects">
              <v-icon size="20" class="mr-2" style="color: inherit; transition: none; width: 24px">fas fa-folder-open</v-icon>
              <span class="hidden-sm-and-down" style="font-size: 16px">{{ $t('MANAGE.PROJECTS') }}</span>
            </v-tab>
            <v-tab class="manage-tab-pill" href="#configuration">
              <v-icon size="20" class="mr-2" style="color: inherit; transition: none; width: 24px">fas fa-cog</v-icon>
              <span class="hidden-sm-and-down" style="font-size: 16px">{{ $t('MANAGE.CONFIGURATION') }}</span>
            </v-tab>
          </v-tabs>
        </div>
      </div>

      <v-divider vertical class="mx-4 primary" style="border: 1px solid;"></v-divider>

      <v-tabs-items v-model="currentTabIndex" class="flex-grow-1" style="overflow: unset"> <!-- overflow: unset needed for sticky tabbars -->
        <!-- TAB USERS -->
        <v-tab-item :transition="false" :reverse-transition="false" value="users" eager>
          <manage-users :data-loaded="dataLoaded" :projects="projects" :opened-users="openedUsers"
                        :company-users="companyUsers" :has-organization="hasOrganization">
          </manage-users>
        </v-tab-item>

        <!-- TAB PROJECTS-->
        <v-tab-item :transition="false" :reverse-transition="false" value="projects" eager>
          <manage-projects :data-loaded="dataLoaded" :projects="projects" :opened-projects="openedProjects"></manage-projects>
        </v-tab-item>

        <!-- TAB CONFIGURATION -->
        <v-tab-item :transition="false" :reverse-transition="false" value="configuration">
          <manage-config :projects="projects"></manage-config>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<style>
  .manage-tab-pill {
    justify-content: flex-start;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    border-radius: 4px 12px 12px 4px;
    padding: 0 12px;
    min-width: 0;
  }
</style>

<script>
  import ProjectSrv from '@/components/Projects/ProjectSrv';
  import ManageUsers from './ManageUsers';
  import ManageProjects from './ManageProjects';
  import ManageConfig from './ManageConfig/ManageConfig';
  import ManageEventBus from './manage-event-bus';
  import manageMixin from './manageMixin';

  export default {
    components: {
      ManageUsers,
      ManageProjects,
      ManageConfig,
    },
    mixins: [manageMixin],
    data() {
      return {
        dataLoaded: { company: null, company_error: "", projects: null, projects_error: "" },
        companyUsers: [],
        projects: [],
        currentTabIndex: null,
        openedProjects: [],
        openedUsers: [],
        hasOrganization: null,
      };
    },
    watch: {
      $route() {
        this.selectTab();
      },
    },
    created() {
      this.selectTab();
      this.loadCompany();
      this.loadProjects();

      // Event Bus
      ManageEventBus.$on('open-project-from-id', () => {
        this.currentTabIndex = `projects`;
      });
      ManageEventBus.$on('update-project-tabs', (projectInfo) => {
        ManageEventBus.$emit('on-update-project-tabs', (projectInfo));
      });
      ManageEventBus.$on('update-user-tabs', (userInfo) => {
        ManageEventBus.$emit('on-update-user-tabs', (userInfo));
      });
      ManageEventBus.$on('update-opened-group', () => {
        ManageEventBus.$emit('on-update-opened-group');
      });
      ManageEventBus.$on('data-loaded-company', (bool) => {
        this.dataLoaded.company = bool;
      });
      ManageEventBus.$on('change-project-owner', () => {
        this.changeProjectOwner();
      });
      ManageEventBus.$on('reload', () => {
        this.reload();
      });
      ManageEventBus.$on('reload-projects', () => {
        this.loadProjects();
      });
    },
    beforeDestroy() {
      ManageEventBus.$off(); // remove all ManageEventBus listeners
    },
    methods: {
      selectTab() {
        if (this.$route.query.tab == 'users' || this.$route.query.tab == 'projects') {
          this.currentTabIndex = this.$route.query.tab;
          this.$router.replace({ query: {} });
          $(window).scrollTop(0);
        }
        if (this.$route.query.open) {
          setTimeout(() => {
            ManageEventBus.$emit('open-project-from-id', this.$route.query.open);
            this.$router.replace({ query: {} });
            $(window).scrollTop(0);
          }, 300);
        }
      },
      loadCompany() {
        return this.$store.dispatch('users/getMe').then(() => {
          this.companyUsers = this.$store.getters['users/getCompanyUsers'];
          this.organization_users = this.$store.getters['users/getOrganizationUsers'];
          this.hasOrganization = this.$store.state.users.accessRight.hasOrganization;
          this.dataLoaded.company = true;
        }).catch((message) => {
          this.dataLoaded.company_error = message || this.$t("GLOBAL.ERROR");
        });
      },
      loadProjects() {
        ProjectSrv.list().then((response) => {
          this.projects = response;
          this.dataLoaded.projects = true;
        }).catch((message) => {
          this.dataLoaded.projects_error = message || this.$t("GLOBAL.ERROR");
        });
      },
      reload() {
        this.openedProjects = [];
        this.openedUsers = [];
        this.loadCompany();
        this.loadProjects();
        this.$store.dispatch('users/groups/load');
        ManageEventBus.$emit('on-update-opened-group');
      },
      changeProjectOwner() {
        this.loadProjects();
      },
    },
  };
</script>
