<template>
  <v-container>
    <div v-if="! userLoaded && ! getMeError" class="text-center pa-4"><i class="fas fa-spinner fa-spin fa-2x fa-fw"></i></div>
    <div v-if="! userLoaded && getMeError" class="white pa-4">{{ $t('GLOBAL.ERROR') }} : {{ getMeError }}</div>
    <v-fade-transition>
      <div v-if="userLoaded">
        <v-row v-if="customDashboards">
          <v-col v-for="dashboard in customDashboards" :key="dashboard.to" xl="4" md="6" sm="8" cols="12">
            <v-card :to="dashboard.to" class="px-6 pt-6 pb-4 hover-elevation-6" style="height: 100%; text-decoration: none;">
              <div class="primary--text pb-2 mb-4" style="border-bottom: 2px solid; margin-right: -24px">
                <span class="text-h6">{{ dashboard.title }}</span>
              </div>
              <div class="pa-2"><img :src="dashboard.img" style="width:100%"></div>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row v-if="hasMonitoringPlan">
          <v-col xl="4" md="6" sm="8" cols="12">
            <v-card to="/dashboards/monitoringplan" class="px-6 pt-6 pb-4 hover-elevation-6" style="height: 100%; text-decoration: none;">
              <div class="primary--text pb-2 mb-4" style="border-bottom: 2px solid; margin-right: -24px">
                <span class="text-h6">{{ $t('MONITORING_PLAN.TITLE') }}</span>
              </div>
              <div v-html="$t('MONITORING_PLAN.DESCRIPTION')"></div>
              <div class="pa-2"><img src="../../img/monitoringplan.png" style="width:100%"></div>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <template v-if="! isPremium">
          <v-row>
            <v-col xl="4" md="6" sm="8" cols="12">
              <v-card to="/home" class="px-6 pt-6 pb-4 hover-elevation-6 display-flex" style="height: 100%; text-decoration: none; flex-direction: column">
                <div class="primary--text pb-2 mb-4" style="border-bottom: 2px solid; margin-right: -24px">
                  <span class="text-h6">{{ $t('HOME.PERSONAL_DASHBOARD') }}</span>
                </div>
                <div v-html="$t('HOME.PERSONAL_DASHBOARD_DESCRIPTION_TEXT')"></div>
                <div class="px-2 mt-auto"><img src="../../img/homeuser.png" style="width:100%;"></div>
              </v-card>
            </v-col>
          </v-row>
          <v-alert color="warningorange" type="warning" class="mt-4">
            {{ $t('PREMIUM.DASHBOARDS_IS_PREMIUM') }} &nbsp;
            <a :href="`https://bubbleplan.net/auth.php?token=${apiToken}&redirect=mon-compte%23premium`" target="_blank" class="nobr">{{ $t('PREMIUM.FREE_TRY') }}</a>
          </v-alert>
        </template>
        <v-row>
          <v-col xl="4" md="6" sm="8" cols="12">
            <v-card :to="isPremium ? '/analysis/progress' : ''" class="px-6 pt-6 pb-4 hover-elevation-6 display-flex" style="height: 100%; text-decoration: none; flex-direction: column">
              <div class="primary--text pb-2 mb-4" style="border-bottom: 2px solid; margin-right: -24px">
                <span class="text-h6">{{ $t('MONITORING_PROGRESS.TITLE') }}</span>
              </div>
              <div v-html="$t('MONITORING_PROGRESS.DESCRIPTION')"></div>
              <div class="px-2 mt-auto"><img src="../../img/kanban.png" style="width:100%"></div>
            </v-card>
          </v-col>
          <v-col xl="4" md="6" sm="8" cols="12">
            <v-card :to="isPremium ? '/analysis/planningusers' : ''" class="px-6 pt-6 pb-4 hover-elevation-6 display-flex" style="height: 100%; text-decoration: none; flex-direction: column">
              <div class="primary--text pb-2 mb-4" style="border-bottom: 2px solid; margin-right: -24px">
                <span class="text-h6">{{ $t('PLANNINGUSERS.USERS_PLANNING') }}</span>
              </div>
              <div v-html="$t('PLANNINGUSERS.USERS_PLANNING_DESCRIPTION_TEXT')"></div>
              <div class="px-2 mt-auto"><img src="../../img/planningusers.png" style="width:100%;"></div>
            </v-card>
          </v-col>
          <v-col xl="4" md="6" sm="8" cols="12">
            <v-card :to="isPremium ? '/analysis/actions' : ''" class="px-6 pt-6 pb-4 hover-elevation-6 display-flex" style="height: 100%; text-decoration: none; flex-direction: column">
              <div class="primary--text pb-2 mb-4" style="border-bottom: 2px solid; margin-right: -24px">
                <span class="text-h6">{{ $t('MONITORING_ACTIONS.TITLE') }}</span>
              </div>
              <div v-html="$t('MONITORING_ACTIONS.DESCRIPTION')"></div>
              <div class="px-2 mt-auto"><img src="../../img/monitoringactions.png" style="width:100%"></div>
            </v-card>
          </v-col>
          <v-col xl="4" md="6" sm="8" cols="12">
            <v-card :to="isPremium ? '/analysis/portfolio' : ''" class="px-6 pt-6 pb-4 hover-elevation-6 display-flex" style="height: 100%; text-decoration: none; flex-direction: column">
              <div class="primary--text pb-2 mb-4" style="border-bottom: 2px solid; margin-right: -24px">
                <span class="text-h6">{{ $t('PORTFOLIO.TITLE') }}</span>
              </div>
              <div v-html="$t('PORTFOLIO.DESCRIPTION')"></div>
              <div class="px-2 mt-auto"><img src="../../img/portfolio.png" style="width:100%"></div>
            </v-card>
          </v-col>
          <v-col xl="4" md="6" sm="8" cols="12">
            <v-overlay :value="! isBusiness" :opacity=".8" color="black" absolute class="ma-3" style="border-radius: 4px 12px 12px 12px">
              <v-alert color="warningorange" type="warning" class="mt-4">{{ $t('PREMIUM.BUSINESS.DASHBOARD_IS_BUSINESS') }} &nbsp;</v-alert>
            </v-overlay>
            <v-card :to="isBusiness ? '/analysis/personalcalendar' : ''" class="px-6 pt-6 pb-4 hover-elevation-6 display-flex" style="height: 100%; text-decoration: none; flex-direction: column">
              <div class="primary--text pb-2 mb-4" style="border-bottom: 2px solid; margin-right: -24px">
                <span class="text-h6">{{ $t('PERSONAL_CALENDAR.TITLE') }}</span>
              </div>
              <div v-html="$t('PERSONAL_CALENDAR.DESCRIPTION')"></div>
              <div class="px-2 mt-auto"><img src="../../img/personalcalendar.png" style="width:100%"></div>
            </v-card>
          </v-col>
          <v-col v-if="isPremium" xl="4" md="6" sm="8" cols="12">
            <v-card to="/home" class="px-6 pt-6 pb-4 hover-elevation-6 display-flex" style="height: 100%; text-decoration: none; flex-direction: column">
              <div class="primary--text pb-2 mb-4" style="border-bottom: 2px solid; margin-right: -24px">
                <span class="text-h6">{{ $t('HOME.PERSONAL_DASHBOARD') }}</span>
              </div>
              <div v-html="$t('HOME.PERSONAL_DASHBOARD_DESCRIPTION_TEXT')"></div>
              <div class="px-2 mt-auto"><img src="../../img/homeuser.png" style="width:100%;"></div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-fade-transition>
  </v-container>
</template>

<script>
  import clientConfig from '@/client_customs/config';

  export default {
    data() {
      return {
        loadingError: '',
        apiToken: window.apiSrv.getAuthToken(),
        customDashboards: clientConfig.dashboards && clientConfig.dashboards.list,
      };
    },
    computed: {
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
      isBusiness() { return this.$store.state.users.accessRight.isBusiness; },
      userLoaded() { return this.$store.state.users.user.id > 0; },
      getMeError() { return this.$store.state.users.getMeError; },
      hasMonitoringPlan() { return this.$store.state.users.accessRight.hasMonitoringPlan; },
    },
  };
</script>
