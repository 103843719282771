import Vue from 'vue';
import MsgBox from '@/components/MsgBox/MsgBox';
import vuetify from '@/js/plugins/vuetify';

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
    open({ rootState }, { title, body, buttons, parent }) {
      return new Promise((resolve, reject) => {
        const MsgBoxVM = new Vue({
          vuetify,
          i18n: rootState.lang.i18n,
          components: { MsgBox },
          data() {
            return { title, body, buttons };
          },
          methods: {
            close() {
              resolve();
              MsgBoxVM.$destroy();
            },
            dismiss(reason) {
              if (buttons && buttons.cancel) {
                reject(reason);
              } else {
                resolve();
              }
              MsgBoxVM.$destroy();
            },
          },
          template: `<msg-box :title="title" :body="body" :buttons="buttons" @close="close" @dismiss="dismiss"></msg-box>`,
        });
        MsgBoxVM.$mount();
        (parent || document.getElementById("vue-app") || document.body).appendChild(MsgBoxVM.$el);
      });
    },
  },
};
