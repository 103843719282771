<template>
  <div v-if="companyLoaded">
    <div v-if="canInviteUsers">
      <b v-if="! hasOrganization" class="primary--text text-uppercase">{{ $t('MANAGE.CURRENT_SUBSCRIPTIONS') }}</b>
      <div v-if="! hasOrganization" class="ml-6 mb-6 display-flex align-center">
        <div>
          <div>{{ $t('MANAGE.SUBSCRIPTIONS') }} {{ $t('ACR.USER') }} : <strong :class="{ 'errorred--text': maxUsersExceeded }">{{ users_count }}/{{ company.maxusers }}</strong></div>
          <div>{{ $t('MANAGE.SUBSCRIPTIONS') }} {{ $t('ACR.CONSULT') }} : <strong :class="{ 'errorred--text': maxConsultExceeded }">{{ consults_count }}/{{ company.maxconsults }}</strong></div>
        </div>
        <v-btn v-if="isPremium" :title="$t('PREMIUM.UPDATE_SUBSCRIPTION')" fab small class="ml-4" @click.stop="openPremium()">
          <v-icon :size="maxUsersExceeded ? 24 : 20" color="accent">fa fa-cart-plus</v-icon>
        </v-btn>
        <v-btn v-else :href="`https://bubbleplan.net/auth.php?token=${api_token}&redirect=mon-compte%23premium`" :title="$t('PREMIUM.UPDATE_SUBSCRIPTION')" fab small target="_blank" class="ml-4">
          <v-icon size="24" color="accent">fa fa-cart-plus</v-icon>
        </v-btn>
      </div>
      <div class="ml-6"><b class="primary--text">{{ $t('MANAGE.INVITE_USER') }} :</b></div>
      <div class="ml-6 pt-1 display-flex align-center">
        <v-text-field v-model="invitetojoin.email" :label="$t('GLOBAL.EMAIL')" :disabled="maxConsultReached" :success-messages="invitetojoin.message" :error-messages="invitetojoin.error"
                      type="email" style="max-width: 250px">
        </v-text-field>
        <v-select v-model="invitetojoin.access_right" :label="$t('MANAGE.ACCESS_RIGHT')" :disabled="maxConsultReached"
                  :items="[{ value: 'consult', text: $t('ACR.CONSULT') },
                           { value: 'user', text: $t('ACR.USER'), disabled: maxUsersReached },
                           { value: 'company_admin', text: $t('ACR.COMPANY_ADMIN'), disabled: maxUsersReached }]"
                  class="ml-4" style="max-width: 200px">
        </v-select>
        <v-btn :disabled="! invitetojoin.email || invitetojoin.sending" :loading="invitetojoin.sending" color="accent" rounded large class="ml-4"
               @click="inviteUser()">
          {{ $t('MANAGE.SEND_INVITE') }}
        </v-btn>
      </div>
      <div v-if="pending_invites.length" class="ml-6 mt-2">
        <b class="primary--text pointer" @click="show_pending_invites = ! show_pending_invites">
          <i :class="show_pending_invites ? 'fa-caret-down' : 'fa-caret-right'" class="fas" style="min-width: 1em"></i>
          {{ $tc('MANAGE.INVITES_PENDING', pending_invites.length, { count: pending_invites.length }) }}
        </b>
        <v-fade-transition hide-on-leave>
          <table v-if="show_pending_invites">
            <transition-group name="list-fade" tag="tbody">
              <tr v-for="invite in pending_invites" :key="invite.id" class="list-fade-item">
                <td>{{ invite.email }}</td>
                <td class="px-6">{{ { consult: $t('ACR.CONSULT'), user: $t('ACR.USER'), company_admin: $t('ACR.COMPANY_ADMIN') }[invite.access_right] }}</td>
                <td>{{ invite.date_of_creation | moment('calendar') }}</td>
                <td v-if="userIsAdmin" class="pl-6 pr-2">
                  <v-btn small icon class="ma-0" @click="deletePendingInvite(invite)"><v-icon color="errorred" small>fas fa-trash-alt</v-icon></v-btn>
                </td>
                <td v-if="userIsAdmin && invite.date_of_creation < today">
                  <v-btn :disabled="invitetojoin.sending" small icon class="ma-0" @click="resendPendingInvite(invite)"><v-icon color="accent" small>fas fa-paper-plane</v-icon></v-btn>
                </td>
              </tr>
            </transition-group>
          </table>
        </v-fade-transition>
      </div>
    </div>
    <div v-show="! isPremium" class="text-center mt-6">
      <div style="position: relative;display: inline-block;">
        <a :href="`https://bubbleplan.net/auth.php?token=${api_token}&redirect=mon-compte%23premium`" target="_blank">
          <img v-if="locale == 'fr'" :alt="$t('PREMIUM.INVITE_CONTRIBUTORS')" src="../../img/go-premium.jpg" style="width: 600px; max-width: 100%">
          <img v-else :alt="$t('PREMIUM.INVITE_CONTRIBUTORS')" src="../../img/go-premium-en.jpg" style="width: 600px; max-width: 100%">
          <div class="text-center" style="position: absolute; bottom: 5%; left: 0; right: 0;">
            <v-btn color="accent" rounded large style="min-width: 220px">{{ $t('PREMIUM.FREE_TRY') }}</v-btn>
          </div>
        </a>
      </div>
    </div>
    <v-divider v-if="canInviteUsers || company.maxusers <= 1" class="my-6"></v-divider>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        companyLoaded: null,
        company: {},
        company_users: [],
        pending_invites: [],
        invitetojoin: { email: "", access_right: "user", sending: null, message: "", error: "" },
        api_token: window.apiSrv.getAuthToken(),
        show_pending_invites: false,
        today: moment().format('YYYY-MM-DD'),
      };
    },
    computed: {
      consults_count() {
        return this.company_users.filter(item => item.access_right == 'consult').length;
      },
      users_count() {
        return this.company_users.length - this.consults_count;
      },
      maxUsersReached() {
        return this.users_count >= this.company.maxusers;
      },
      maxUsersExceeded() {
        return this.users_count > this.company.maxusers;
      },
      maxConsultReached() {
        return (this.consults_count >= this.company.maxconsults) && ((this.users_count + this.consults_count) >= (this.company.maxusers + this.company.maxconsults));
      },
      maxConsultExceeded() {
        return (this.consults_count > this.company.maxconsults) && ((this.users_count + this.consults_count) > (this.company.maxusers + this.company.maxconsults));
      },
      canInviteUsers() {
        return this.userIsAdmin || ! this.user.company;
      },
      locale() {
        return this.$store.state.lang.lang;
      },
      user() { return this.$store.state.users.user; },
      userIsAdmin() { return this.$store.state.users.accessRight.isAdmin; },
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
      hasOrganization() { return this.$store.state.users.accessRight.hasOrganization; },
    },
    watch: {
      maxUsersReached(newVal) {
        if (newVal) this.invitetojoin.access_right = "consult";
      },
    },
    created() {
      this.$store.state.users.userPromise.then(() => {
        if (! this.user.company) {
          this.company = { maxusers: 1, maxconsults: 2 };
        } else {
          this.company = this.user.company;
          if (this.userIsAdmin) this.loadPendingInvites();
        }
        this.company_users = this.$store.getters['users/getCompanyUsers'];
        this.companyLoaded = true;
      });
    },
    methods: {
      inviteUser() {
        if (! this.invitetojoin.email) return;
        this.invitetojoin.message = "";
        this.invitetojoin.error = "";
        if (! window.isValidEmailAddress(this.invitetojoin.email)) {
          this.invitetojoin.error = this.$t('MANAGE.INVALID_EMAIL');
          return;
        }
        this.invitetojoin.sending = true;
        window.apiSrv.call('companyinvites/invite', 'store', { email: this.invitetojoin.email, access_right: this.invitetojoin.access_right }).then((response) => {
          this.invitetojoin.message = (response.data && response.data.message) ? response.data.message : this.$t('MANAGE.INVITATION_SENT');
          this.invitetojoin.email = "";
          this.invitetojoin.sending = false;
          this.loadPendingInvites();
        }).catch((message) => {
          this.invitetojoin.error = message || this.$t('MANAGE.INVITATION_NOT_SENT');
          this.invitetojoin.sending = false;
        });
      },
      loadPendingInvites() {
        window.apiSrv.call('companyinvites/pending', 'index').then((response) => {
          this.pending_invites = response && response.data && response.data.invites || [];
          this.pending_invites.sort((a, b) => (a.date_of_creation < b.date_of_creation ? 1 : -1));
        }).catch(() => {});
      },
      deletePendingInvite(invite) {
        window.apiSrv.call(`companyinvites/pending`, 'destroy', invite.email).then(() => {
          const index = this.pending_invites.indexOf(invite);
          if (index > -1) this.pending_invites.splice(index, 1);
        }).catch((message) => {
          if (message) this.$store.dispatch('ui/msgbox/open', { title: "Error : Invitation was not deleted", body: message || "" });
        });
      },
      resendPendingInvite(invite) {
        this.invitetojoin.email = invite.email;
        this.invitetojoin.access_right = invite.access_right;
        this.inviteUser();
      },
      openPremium() {
        this.$store.commit('ui/navigation/setOpenedModal', 'premium-window');
      },
    },
  };
</script>
