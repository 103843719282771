<template>
  <div id="home">
    <div v-if="section == 'home'">
      <home-user></home-user>
    </div>
    <div v-if="section == 'projects'">
      <home-projects></home-projects>
    </div>
    <div v-if="section == 'dashboards'">
      <home-dashboards></home-dashboards>
    </div>
  </div>
</template>

<script>
  import HomeProjects from '@/components/Projects/HomeProjects';
  import HomeDashboards from './HomeDashboards';
  import HomeUser from './HomeUser';

  export default {
    components: {
      HomeProjects,
      HomeDashboards,
      HomeUser,
    },
    props: {
      section: { type: String, default: 'projects' },
    },
  };
</script>
