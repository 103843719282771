import PlanningElement from '@/models/PlanningElement';
import { getDefaultChecklistItem } from '@/models/helpers/defaultElements';

/* eslint object-curly-newline: 0 */
const getDefaultMeetingAction = () => ({ id: null, title: '', user_id: 0, due_date: null, completed_at: null, workload: 0, workload_used: 0 });

class Action {
  constructor({ type = 'meeting', id, element, item } = {}) {
    const originalItem = item || (type == 'planning' ? getDefaultChecklistItem() : getDefaultMeetingAction());
    if (! item && type == 'planning' && element) {
      const checklist = element.getChecklist() || [];
      checklist.push(originalItem);
      element.setChecklist(checklist);
    }
    this.data = angular.copy(originalItem);
    this.type = type;
    this.id = id || window.uuid();
    this.getElement = () => element || null;
    this.getOriginalItem = () => originalItem;
  }

  getData() {
    return this.data;
  }

  getCssClass() {
    let element = this.getElement();
    if (element) return element.getChecklistItemClass(this.getData());
    // meeting action
    element = new PlanningElement();
    return element.getChecklistItemClass({
      checked: this.getChecked(),
      duedate: this.getDuedate() || moment().add(1, 'year').format(),
    });
  }

  getPlanningId() {
    const element = this.getElement();
    if (! element) return null;
    return (element.getPlanning() || {}).id;
  }

  getLaneId() {
    const element = this.getElement();
    if (! element) return null;
    return element.getLaneId();
  }

  getMeeting() {
    if (this.type != 'meeting') return null;
    return this.data.meeting || null;
  }

  isNew() {
    return this.type == 'meeting' && ! this.getOriginalItem().id;
  }

  getTitle() {
    return this.data.title;
  }
  setTitle(value) {
    this.data.title = value;
    return this;
  }

  getUserId() {
    return this.data.user_id && +this.data.user_id || this.data.user_id;
  }
  setUser({ id, username }) {
    if (this.data.group_id) this.data.group_id = null;
    if (this.type == 'planning') {
      this.data.username = username || '';
    } else {
      this.data.user_name = username || '';
    }
    if (id) this.data.user_id = +id;
    return this;
  }

  getGroupId() {
    return this.data.group_id && +this.data.group_id || this.data.group_id;
  }
  setGroup({ id, username }) {
    if (this.type != 'planning') console.error('group not available for meeting actions');
    if (this.data.user_id) this.data.user_id = null;
    this.data.username = username || '';
    if (id) this.data.group_id = +id;
    return this;
  }

  getUsername() {
    if (this.type == 'meeting') {
      return this.data.user_name;
    }
    return this.data.username;
  }
  setUsername(value) { // only for virtual participants
    if (this.type == 'meeting') {
      if (this.data.user_id) this.data.user_id = null;
      this.data.user_name = value || '';
      return this;
    }
    if (this.data.user_id) this.data.user_id = null;
    if (this.data.group_id) this.data.group_id = null;
    this.data.username = value || '';
    return this;
  }

  getDuedate() {
    return this.type == 'planning' ? this.data.duedate : this.data.due_date;
  }
  setDuedate(value) {
    if (this.type == 'planning') {
      this.data.duedate = value;
    } else {
      this.data.due_date = value;
    }
    return this;
  }

  getChecked() {
    return this.type == 'planning' ? this.data.checked : !! this.data.completed_at;
  }
  setChecked(value) {
    if (this.type == 'planning') {
      this.data.checked = value;
    } else {
      this.data.completed_at = value ? new Date() : null;
    }
    return this;
  }

  getWorkload() {
    return this.data.workload;
  }
  setWorkload(value) {
    this.data.workload = value;
    return this;
  }

  getWorkloadUsed() {
    return this.data.workload_used;
  }
  setWorkloadUsed(value) {
    this.data.workload_used = value;
    return this;
  }

  getExtraField(field) {
    return this.data[field];
  }
  setExtraField(field, value) {
    this.data[field] = value;
    return this;
  }

  getStartTime() {
    if (this.type != 'planning') return null;
    const element = this.getElement();
    return element && element.getStartTime();
  }
  getEndTime() {
    const duedate = this.getDuedate();
    if (duedate) return moment(duedate);
    if (this.type != 'planning') return null;
    const element = this.getElement();
    return element && element.getEndTime();
  }

  reset() {
    this.data = angular.copy(this.getOriginalItem());
  }

  saveMeetingAction() {
    const action = _.extend(this.getOriginalItem(), this.getData());
    const apiOperation = action.id ? 'update' : 'store';
    return window.apiSrv.call('v2/meetings/actions', apiOperation, action).then((result) => {
      if (! action.id) action.id = result && result.data && result.data.action && result.data.action.id;
      window.notificationsSrv.callEvent('meeting.changeAction', action);
      this.reset();
    });
  }

  save() {
    if (this.type == 'meeting') return this.saveMeetingAction();
    const element = this.getElement();
    if (! element) return Promise.reject(new Error('missing element'));
    const planning = element.getPlanning();
    if (! planning) return Promise.reject(new Error('missing planning'));

    const oldState = _.extend(new PlanningElement(planning, element), { id: parseInt(element.id, 10) });
    _.extend(this.getOriginalItem(), angular.copy(this.getData()));
    const newState = _.extend(new PlanningElement(planning, element), { id: parseInt(element.id, 10) });

    const props = ['checklist'];
    oldState.updateChecklistProgress();
    newState.updateChecklistProgress();
    if (oldState.getProgress() != newState.getProgress()) props.push('progress'); // save progress when calculated progress changes (ie checked or weight)

    return newState.save(props).then(() => {
      window.notificationsSrv.callEvent('projectSaved', {
        planning_id: planning.id,
        planning_title: planning.meta && planning.meta.title,
      });
    });
  }

  delete() {
    const originalItem = this.getOriginalItem();
    if (this.type == 'meeting') return window.apiSrv.call('v2/meetings/actions', 'destroy', originalItem.id);

    const element = this.getElement();
    if (! element) return Promise.reject(new Error('missing element'));
    const planning = element.getPlanning();
    if (! planning) return Promise.reject(new Error('missing planning'));

    element.setChecklist(element.getChecklist().filter(item => item != originalItem));
    const newState = _.extend(new PlanningElement(planning, element), { id: parseInt(element.id, 10) });
    return newState.save(['checklist']).then(() => {
      window.notificationsSrv.callEvent('projectSaved', {
        planning_id: planning.id,
        planning_title: planning.meta && planning.meta.title,
      });
    });
  }
}

export default Action;
