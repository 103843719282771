<template>
  <v-select v-model="selectedColsList" :items="headers" :label="$t('MULTIPROJECTS.COLUMNS')"
            prepend-icon="fas fa-eye" hide-details multiple menu-props="offsetY">
    <template #selection="{ index }">
      <span v-if="index === 0">{{ selectedColsList.length }}/{{ headers.length }}</span>
    </template>
  </v-select>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    props: {
      headers: { type: Array, default: () => [] },
    },
    computed: {
      selectedColsList: {
        get() {
          return this.headers.filter(col => this.portfolioOptions.selectedCols[col.value]);
        },
        set(newVal) {
          this.headers.forEach((col) => {
            this.portfolioOptions.selectedCols[col.value] = newVal.includes(col.value);
          });
        },
      },
      ...mapState('multiprojects', ['portfolioOptions']),
    },
    watch: {
      headers: {
        handler() {
          this.headers.forEach((col) => {
            if (! Object.prototype.hasOwnProperty.call(this.portfolioOptions.selectedCols, col.value)) {
              this.$set(this.portfolioOptions.selectedCols, col.value, true);
            }
          });
        },
        immediate: true,
      },
    },
  };
</script>
