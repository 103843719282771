<template>
  <svg v-if="showSvg" class="dependencies-svg">
    <g v-for="dependenciesLink in visibleDependenciesLinks" v-show="! dependenciesLink.hide"
       :class="[{ 'invalid-dependency': dependenciesLink.invalid },
                { 'critical-path': ! dependenciesLink.invalid && displayCriticalPath && dependenciesLink.isCriticalPath }]">
      <path :d="dependenciesLink.linePath" class="dependency-path" />
      <circle :cx="dependenciesLink.el.x" :cy="dependenciesLink.el.y" r="5" class="dependency-startpoint" />
      <path :d="dependenciesLink.arrowPath" class="dependency-endarrow" />
    </g>
  </svg>
</template>

<style lang="scss">
  .dependencies-svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 105;
    pointer-events: none;

    .dependency-path {
      fill: transparent;
      stroke:rgba(55, 55, 55, .5);
      stroke-width: 2;
    }
    .dependency-startpoint {
      fill: rgba(55, 55, 55, .9);
    }
    .dependency-endarrow {
      fill: rgba(55, 55, 55, .9);
      stroke:rgba(55, 55, 55, .5);
      stroke-width: 1;
    }

    .invalid-dependency {
      .dependency-path {
        stroke:rgba(255, 0, 0, .5);
      }
      .dependency-startpoint {
        fill: rgba(255, 0, 0, .9);
      }
      .dependency-endarrow {
        fill: rgba(255, 0, 0, .9);
        stroke:rgba(255, 0, 0, .5);
      }
    }

    .critical-path {
      .dependency-path {
        stroke:rgba(255, 190, 51);
      }
      .dependency-startpoint {
        fill: rgba(255, 190, 51);
      }
      .dependency-endarrow {
        fill: rgba(255, 190, 51);
        stroke:rgba(255, 190, 51);
      }
    }
  }
</style>

<script>
  export default {
    props: {
      planning: { type: Object, required: true },
    },
    data() {
      return {
        svgOffset: { top: null, left: null },
        forceUpdateTab: [],
      };
    },
    computed: {
      showSvg() {
        return this.planning.config && this.planning.config.displayDependencies;
      },
      displayCriticalPath() {
        return this.planning.config && this.planning.config.displayCriticalPath;
      },
      dependenciesLinks() {
        if (this.$parent.$el) _.extend(this.svgOffset, this.$parent.$el.getBoundingClientRect());
        this.forceUpdateTab.forEach(() => {});
        const dependenciesLinks = [];
        this.planning.elements.forEach((el) => {
          (el.getDependencies() || []).filter(dependency => dependency.successor).forEach((dependency) => {
            dependenciesLinks.push(this.dependenciesCoordinates(el, dependency));
          });
        });
        return dependenciesLinks;
      },
      visibleDependenciesLinks() {
        return this.dependenciesLinks.filter(dependenciesLink => dependenciesLink.el);
      },
      reactiveProps() {
        return this.showSvg && this.planning.elements.filter(el => (el.getDependencies() || []).length).map(el => [el.xposition, el.getWidth(), el.modificationUser]);
      },
      displayDependenciesErrors() {
        return this.$store.state.ui.planning.displayDependenciesErrors;
      },
    },
    watch: {
      showSvg() {
        this.$nextTick(() => { this.forceUpdateTab = []; });
      },
      'planning.visibleTimeline.timelinecols': function () {
        this.$nextTick(() => { this.forceUpdateTab = []; });
      },
      reactiveProps() {
        this.$nextTick(() => { this.forceUpdateTab = []; });
      },
    },
    methods: {
      dependenciesCoordinates(el, dependency) {
        if (! dependency.successor) return null;
        const relatedEl = this.planning.elements.find(item => item.id == dependency.target_id);
        if (! relatedEl || ! el.visible || ! relatedEl.visible) return { hide: true };
        const elLaneIndex = (this.planning.lanes || []).findIndex(item => item.id === el.getLaneId());
        const relatedElLaneIndex = (this.planning.lanes || []).findIndex(item => item.id === relatedEl.getLaneId());
        if (elLaneIndex === -1 || relatedElLaneIndex === -1) return { hide: true };
        if (this.planning.lanes[elLaneIndex].hidden || this.planning.lanes[relatedElLaneIndex].hidden) return { hide: true };

        // Detect not respected dependencies (cycle references or due to locked date)
        const { visibleTimeline = {} } = this.planning;
        const invalid = this.displayDependenciesErrors && relatedEl.getStartTime().addWithWorkdays(-(dependency.delay || 0), 'days', visibleTimeline.workdays).isBefore(el.getEndTime());

        const isCriticalPath = relatedEl.getStartTime().diffWithWorkdays(el.getEndTime(), 'days', this.planning.visibleTimeline.workdays) == dependency.delay;

        const laneHeights = [0];
        let totalheight = 0;
        this.planning.lanes.forEach((lane) => { // makes it reactive to laneheight changes
          totalheight += lane.height + 2;
          laneHeights.push(totalheight);
        });

        const elTitleNode = document.querySelector(`#el${el.id} .element-title`);
        const elTitleBoundaries = elTitleNode && elTitleNode.getBoundingClientRect();
        const elTitleTop = elTitleBoundaries ? (elTitleBoundaries.top - this.svgOffset.top || 0) : (laneHeights[elLaneIndex] + el.yposition);
        const elTitleRight = elTitleBoundaries ? (elTitleBoundaries.right - this.svgOffset.left || 0) : (el.xposition + el.getWidth());
        const elTitleHeight = elTitleBoundaries ? elTitleBoundaries.height : 14.4; // fallback height estimation

        const relatedElTitleNode = document.querySelector(`#el${relatedEl.id} .element-title`);
        const relatedElTitleBoundaries = relatedElTitleNode && relatedElTitleNode.getBoundingClientRect();
        const relatedElTitleTop = relatedElTitleBoundaries ? (relatedElTitleBoundaries.top - this.svgOffset.top || 0) : (laneHeights[relatedElLaneIndex] + relatedEl.yposition);
        const relatedElTitleLeft = relatedElTitleBoundaries ? (relatedElTitleBoundaries.left - this.svgOffset.left || 0) : relatedEl.xposition;
        const relatedElTitleHeight = relatedElTitleBoundaries ? relatedElTitleBoundaries.height : 14.4; // fallback height estimation

        const coordinates = {
          el: {
            x: elTitleRight + (el.isType('milestone') ? 8 : 0),
            y: elTitleTop + elTitleHeight / 2,
          },
          relatedEl: {
            x: relatedElTitleLeft + (relatedEl.isType('milestone') ? -8 : 0),
            y: relatedElTitleTop + relatedElTitleHeight / 2,
          },
          invalid,
          isCriticalPath,
        };
        const arrowSize = 6;
        if (coordinates.el.x + 20 < coordinates.relatedEl.x) {
          coordinates.arrowPath = `M ${coordinates.relatedEl.x - 5} ${coordinates.relatedEl.y - arrowSize} v ${arrowSize * 2} l ${arrowSize * 1.5} ${-arrowSize} Z`;
          if (coordinates.el.y == coordinates.relatedEl.y) {
            coordinates.linePath = `M ${coordinates.el.x} ${coordinates.el.y} H ${coordinates.relatedEl.x}`;
          } else if (coordinates.el.y < coordinates.relatedEl.y) {
            coordinates.linePath = `M ${coordinates.el.x} ${coordinates.el.y} h 8 q 4 0 4 4 V ${coordinates.relatedEl.y - 4} q 0 4 4 4 H ${coordinates.relatedEl.x}`;
          } else {
            coordinates.linePath = `M ${coordinates.el.x} ${coordinates.el.y} h 8 q 4 0 4 -4 V ${coordinates.relatedEl.y + 4} q 0 -4 4 -4 H ${coordinates.relatedEl.x}`;
          }
        } else { // elements are too close to each other
          if (coordinates.el.y == coordinates.relatedEl.y) {
            coordinates.linePath = `M ${coordinates.el.x} ${coordinates.el.y} H ${coordinates.relatedEl.x}`;
            coordinates.arrowPath = `M ${coordinates.relatedEl.x - 5} ${coordinates.relatedEl.y - arrowSize} v ${arrowSize * 2} l ${arrowSize * 1.5} ${-arrowSize} Z`;
          } else if (coordinates.el.y < coordinates.relatedEl.y) {
            coordinates.linePath = `M ${coordinates.el.x} ${coordinates.el.y} H ${coordinates.relatedEl.x + 4} q 4 0 4 4 V ${relatedElTitleTop}`;
            coordinates.arrowPath = `M ${coordinates.relatedEl.x + 8 - arrowSize} ${relatedElTitleTop - arrowSize} h ${arrowSize * 2} l ${-arrowSize} ${arrowSize * 1.5} Z`;
          } else {
            if (coordinates.relatedEl.x - 4 - coordinates.el.x - 8 - 4 > 0) {
              coordinates.linePath = `M ${coordinates.el.x} ${coordinates.el.y} H ${coordinates.relatedEl.x - 4 - 8} q 4 0 4 -4 V ${coordinates.relatedEl.y + 4} q 0 -4 4 -4 H ${coordinates.relatedEl.x}`;
            } else {
              coordinates.linePath = `M ${coordinates.el.x} ${coordinates.el.y} h 8 q 4 0 4 -4 v ${-elTitleHeight / 2 + 4} q 0 -4 -4 -4 H ${coordinates.relatedEl.x - 4} q -4 0 -4 -4 V ${coordinates.relatedEl.y + 4} q 0 -4 4 -4 H ${coordinates.relatedEl.x}`;
            }
            coordinates.arrowPath = `M ${coordinates.relatedEl.x - 5} ${coordinates.relatedEl.y - arrowSize} v ${arrowSize * 2} l ${arrowSize * 1.5} ${-arrowSize} Z`;
          }
        }

        return coordinates;
      },
    },
  };
</script>
