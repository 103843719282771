<template>
  <div style="max-width: 580px">
    <b class="primary--text text-uppercase">{{ $t('MANAGE.CONFIG.SPLIT_PLANNING.TITLE') }}</b>
    <div>{{ $t('MANAGE.CONFIG.SPLIT_PLANNING.DESC') }}</div>
    <div class="ml-6 pt-1">
      <v-autocomplete v-model="selectedProjectId"
                      :items="projects" :item-text="(project) => project.title || $t('PLANNING.UNNAMED_PROJECT')" item-value="id"
                      :label="$t('MANAGE.CONFIG.SPLIT_PLANNING.PROJECT_TO_SPLIT')" :placeholder="selectedProjectId ? '' : $t('MULTIPROJECTS.NO_PROJECT_SELECTED')"
                      prepend-icon="fas fa-folder-open" hide-details menu-props="offsetY">
      </v-autocomplete>
      <div class="mt-2 display-flex align-center">
        <date-field v-model="splitdate" :label="$t('MANAGE.CONFIG.SPLIT_PLANNING.SPLIT_DATE')" format="dddd DD MMMM YYYY" readonly no-null hide-details prepend-icon="fas fa-calendar-alt"></date-field>
      </div>
      <v-checkbox v-model="disclaimer">
        <template #label>
          <span>
            {{ $t('MANAGE.CONFIG.SPLIT_PLANNING.DISCLAIMER') }}
            <b>{{ $t('MANAGE.CONFIG.SPLIT_PLANNING.DISCLAIMER2') }}</b>
          </span>
        </template>
      </v-checkbox>
      <v-btn :disabled="! btnEnabled" :loading="splitInProgress" rounded large color="accent" block @click="splitPlanning">
        {{ $t('MANAGE.CONFIG.SPLIT_PLANNING.SPLIT_ON') }} {{ splitdate | moment('mediumDate') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
  import ManageEventBus from '../manage-event-bus';

  export default {
    props: {
      projects: { type: Array, default: () => [] },
    },
    data() {
      return {
        selectedProjectId: null,
        disclaimer: false,
        splitInProgress: false,
        splitdate: moment().startOf('day'),
      };
    },
    computed: {
      btnEnabled() {
        return this.isBusiness && this.disclaimer && this.selectedProjectId && this.splitdate;
      },
      isBusiness() { return this.$store.state.users.accessRight.isBusiness; },
    },
    methods: {
      splitPlanning() {
        if (! this.btnEnabled) return; // anti html hack
        this.splitInProgress = true;
        window.apiSrv.call(`plannings/tasks/splitondate/${this.selectedProjectId}`, 'store', { split_date: this.splitdate.format() }).then((response) => {
          this.$store.dispatch('ui/msgbox/open', { title: this.$t('MANAGE.CONFIG.SPLIT_PLANNING.SPLIT_SUCCESSFUL'), body: response && response.data && response.data.message || "" });
          this.selectedProjectId = null;
          ManageEventBus.$emit('reload-projects');
        }).catch((message) => {
          if (message) this.$store.dispatch('ui/msgbox/open', { title: this.$t('MANAGE.CONFIG.SPLIT_PLANNING.ERROR_NOT_MODIFIED'), body: message || "" });
        }).then(() => {
          this.splitInProgress = false;
        });
      },
    },
  };
</script>
