<template>
  <div>
    <navigation>
      <v-spacer></v-spacer>
      <template v-if="isPremium">
        <v-btn :disabled="exporting.inprogress" rounded small depressed class="mx-1" style="height: 32px" @click="! exporting.inprogress && pdfExport()">
          <v-icon color="primary" small class="mr-2">{{ exporting.inprogress ? 'fas fa-spinner fa-spin' : 'far fa-file-pdf' }}</v-icon>
          {{ $t('NAV.EXPORT') }}
        </v-btn>
      </template>
    </navigation>

    <div v-if="isPremium" style="height: calc(100vh - 72px); margin-bottom: -24px; display: flex;flex-direction: column;">
      <v-progress-linear v-if="! loading.complete" :value="loading.counter.toLoad && loading.counter.loaded ? loading.counter.loaded / loading.counter.toLoad * 100 : 2"
                         color="accent" rounded>
      </v-progress-linear>

      <dashboards-filters v-if="loading.complete" :filters="['Projects', 'Users', 'Dates', 'Display']" class="flex-grow-0"></dashboards-filters>

      <div v-show="loading.complete" id="kanban-cols-wrapper" class="flex-grow-1 display-flex" style="overflow-x: hidden">
        <div class="display-flex flex-grow-1 py-4" style="overflow-x: auto; overflow-y: hidden;">
          <kanban-col v-for="col in kanbancols" :key="col.id" :planning="kanbanPlanning" :col="col"
                      @save-el="saveEl">
          </kanban-col>
        </div>
      </div>

      <!-- MODALS -->
      <modal v-if="openedElement" :backdrop="false" movable @close="closeElementDetails">
        <element-details :element="openedElement" @close="closeElementDetails($event)"></element-details>
      </modal>
      <modal v-if="openedColorPickerElement" :backdrop="false"
             :size="openedColorPickerElement.isType('milestone') ? 'sm' : 'md'" movable
             @close="closeColorPicker">
        <color-picker :target="openedColorPickerElement" :planning="openedColorPickerElement.getPlanning()" @close="closeColorPicker"></color-picker>
      </modal>
    </div>

    <div v-if="userLoaded && ! isPremium">{{ $t('PREMIUM.SECTION_IS_PREMIUM') }}</div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import Planning from '@/models/Planning';
  import Navigation from '@/components/Navigation/Navigation';
  import ElementDetails from '@/components/ElementDetails/ElementDetails';
  import ColorPicker from '@/components/ColorPicker/ColorPicker';
  import DashboardsFilters from '../DashboardsFilters/DashboardsFilters';
  import KanbanCol from './KanbanCol';

  export default {
    components: {
      Navigation,
      ElementDetails,
      ColorPicker,
      DashboardsFilters,
      KanbanCol,
    },
    data() {
      return {
        kanbancols: [
          { id: 'finished', label: "MONITORING_PROGRESS.FINISHED", color: 'successgreen', icon: 'fas fa-check-circle', orderBy: '-data.endtime' },
          { id: 'inprogress', label: "MONITORING_PROGRESS.IN_PROGRESS", color: 'primary', icon: 'fas fa-hourglass-half', orderBy: 'data.endtime' },
          { id: 'overdue', label: "MONITORING_PROGRESS.OVERDUE", color: 'errorred', icon: 'fas fa-exclamation-triangle', orderBy: '-data.endtime' },
          { id: 'future', label: "MONITORING_PROGRESS.FUTURE", color: 'secondary', icon: 'fas fa-rocket', orderBy: 'data.starttime' },
        ],
        exporting: { inprogress: false, success: false, error: false },
      };
    },
    computed: {
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
      userLoaded() { return this.$store.state.users.user.id > 0; },
      allPlanningElements() {
        // selected plannings -> elements
        const elements = [];
        (this.projects || []).forEach((data) => {
          if (this.selectedProjects.indexOf(data.id) == -1) return;
          (data.elements || []).forEach((el) => {
            this.$store.commit('multiprojects/formatElProjectData', { el, projectData: data });
            elements.push(el);
          });
        });
        return elements;
      },
      allSelectedUsers() {
        const allSelectedUsers = new Set();
        this.companyUsers.forEach((user) => {
          if (this.selectedCompanyUsers.includes(user.id)) allSelectedUsers.add(user.id);
        });
        this.deletedUsers.forEach((user) => {
          if (this.selectedDeletedUsers.includes(user.id)) allSelectedUsers.add(user.id);
        });
        this.virtualParticipants.forEach((user) => {
          if (this.selectedVirtualParticipants.includes(user.username)) allSelectedUsers.add(`vp${user.username}`);
        });
        return allSelectedUsers;
      },
      kanbanPlanning() {
        const planning = new Planning();
        if (! this.loading.complete || ! this.projects.length) return planning;
        planning.elements = this.allPlanningElements.filter((el) => {
          const elUsers = el.getUsers();
          if (elUsers && elUsers.some(user => ! user.group_id)) { // exclude groups from considered users
            if (! elUsers.find(user => this.allSelectedUsers.has(user.id || `vp${user.username}`))) return false;
          } else if (! this.filterOptions.unassigned) {
            return false;
          }
          if (this.filterOptions.el_type && this.filterOptions.el_type != 'both' && ! el.isType(this.filterOptions.el_type)) return false;
          if (this.selectedDates.starttime && el.getEndTime().isBefore(this.selectedDates.starttime)) return false;
          if (this.selectedDates.endtime && el.getStartTime().startOf('day').isAfter(this.selectedDates.endtime)) return false;
          return true;
        });
        planning.elements.forEach((el) => {
          this.$store.dispatch('multiprojects/formatEl', { el });
        });
        planning.config.setDesign();
        moment.locale(this.$store.state.lang.lang);
        return planning;
      },
      ...mapState('multiprojects', ['projects', 'selectedProjects', 'companyUsers', 'selectedCompanyUsers', 'virtualParticipants',
                                    'selectedVirtualParticipants', 'deletedUsers', 'selectedDeletedUsers']),
      ...mapState('multiprojects', ['loading', 'selectedDates', 'filterOptions']),
      ...mapState('ui/kanban', ['openedElement', 'openedColorPickerElement']),
    },
    created() {
      this.$store.commit('multiprojects/loadFilters', {
        dashboardName: this.$route.path.slice(1).replace(/\//g, '_'),
        selectedDisplay: {
          description: false, progress: true, checklist: true, users: true, links: false, budgets: false,
        },
        filterOptions: { el_type: 'task' },
      });
      this.$store.dispatch('multiprojects/load');
      this.$store.commit('ui/kanban/reset');
    },
    methods: {
      pdfExport() {
        _.extend(this.exporting, { inprogress: true, success: false, error: false });
        const $html = document.querySelector("html").cloneNode(true);
        const queryEl = $html.querySelector('#kanban-cols-wrapper') || document.createElement("div");
        queryEl.children.forEach(el => el.classList.add('px-4', 'mx-n4')); // space for inner elements box shadow
        $html.querySelector("body").innerHTML = `<div style="padding: 0 20px;">
        <h1 class="primary--text text-uppercase">${this.$t('MONITORING_PROGRESS.TITLE')}</h1>${queryEl.innerHTML}
        </div>`;
        $html.querySelector('body').classList.add('v-application');
        $html.querySelectorAll(".kanban").forEach(el => el.style.setProperty('width', 'calc(25% - 15px)'));
        $html.querySelectorAll(".warningorange").forEach(el => el.style.setProperty('background', '#ff9b1d'));
        $html.querySelectorAll(".successgreen").forEach(el => el.style.setProperty('background', '#00b100'));

        window.apiSrv.call('pdf', 'store', { html: $html.innerHTML, orientation: "landscape", footer: this.$t('MONITORING_PROGRESS.TITLE') }).then((response) => {
          if (response && response.data && response.data.pdfurl) {
            this.exporting.inprogress = false;
            this.exporting.success = true;
            setTimeout(() => { this.exporting.success = false; }, 3000);
            window.open(`${response.data.pdfurl}/${this.$t('MONITORING_PROGRESS.TITLE')}.pdf`, "_blank");
          }
        }).catch((message) => {
          this.exporting.inprogress = false;
          this.exporting.error = message || "Error : not exported";
        });
      },
      ...mapActions('ui/kanban', ['closeElementDetails', 'openColorPicker', 'closeColorPicker', 'saveEl']),
    },
  };
</script>
