<template>
  <section class="modal-with-tabs account-window">
    <div class="modal-with-tabs-header">
      <div class="pt-6 px-4" style="width: 100%; background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,.5) 100%);">
        <v-tabs v-model="openedTabIndex" background-color="transparent" dark slider-color="primary" style="margin-bottom: -2px;">
          <v-tab href="#profile">{{ $t('ACCOUNT.PROFILE') }}</v-tab>
          <v-tab href="#avatar">{{ $t('ACCOUNT.AVATAR') }}</v-tab>
          <v-tab href="#notifications">{{ $t('NOTIFICATIONS.NOTIFICATIONS') }}</v-tab>
          <v-tab v-if="displayIntegrations" href="#integrations">{{ $t('ACCOUNT.INTEGRATIONS') }}</v-tab>
        </v-tabs>
      </div>
    </div>
    <div class="modal-with-tabs-body">
      <v-tabs-items v-model="openedTabIndex">
        <!-- TAB PROFILE -->
        <v-tab-item value="profile">
          <account-profile @close="close"></account-profile>
        </v-tab-item>
        <!-- TAB AVATAR  -->
        <v-tab-item value="avatar">
          <account-avatar @close="close"></account-avatar>
        </v-tab-item>
        <!-- TAB NOTIFICATIONS  -->
        <v-tab-item value="notifications">
          <notifications-manager @close="close"></notifications-manager>
        </v-tab-item>
        <!-- TAB INTEGRATIONS  -->
        <v-tab-item v-if="displayIntegrations" value="integrations">
          <integrations-manager @close="close"></integrations-manager>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <div class="modal-with-tabs-footer"></div>
  </section>
</template>

<style>
  .account-window .modal-with-tabs-header {
    background-image: url('../../img/share.jpg');
    background-position: top center;
  }
  .account-window .modal-with-tabs-body {
    height: auto;
  }
</style>

<script>
  import AccountProfile from '@/components/Account/AccountProfile';
  import AccountAvatar from '@/components/Account/AccountAvatar';
  import NotificationsManager from '@/components/Notifications/NotificationsManager';
  import IntegrationsManager from '@/components/Integrations/IntegrationsManager.vue';

  export default {
    components: {
      AccountProfile,
      AccountAvatar,
      NotificationsManager,
      IntegrationsManager,
    },
    props: {
      initialTab: { type: String, default: null },
    },
    data() {
      return {
        openedTabIndex: this.initialTab,
      };
    },
    computed: {
      displayIntegrations() {
        const { availableIntegrations } = this.$store.getters['users/accessRight/config'];
        return ! (availableIntegrations && ! availableIntegrations.length);
      },
    },
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>
