import { outerHeight } from '@/components/Reusables/utils';
import PlanningLane from '@/models/PlanningLane';
import constants from '@/js/constants.js';

let undoState = [];

function insertNewLane(planning, newlane) {
  const lane = new PlanningLane(planning, newlane);
  planning.lanes.push(lane);
  return lane;
}

function set(planning, newlanes) {
  planning.lanes.splice(0, planning.lanes.length);
  while (newlanes.length) {
    const newlane = newlanes.shift();
    insertNewLane(planning, newlane);
  }
}

let idCounter = 1;
function nextId(lanes) {
  idCounter = Math.max(idCounter, Math.max(...lanes.map(item => item.id), 0)) + 1;
  return idCounter;
}

function undoredoAndSave(context, additionalUndoRedoFunctions) {
  const { rootState, dispatch } = context;
  if (angular.equals(rootState.planning.lanes, undoState)) return;
  const newState = rootState.planning.lanes.map(lane => angular.copy(lane));
  const oldState = undoState.map(lane => angular.copy(lane));
  dispatch('undoredo/add', {
    action: () => {
      set(rootState.planning, newState.map(lane => angular.copy(lane)));
      if (additionalUndoRedoFunctions && additionalUndoRedoFunctions.action) additionalUndoRedoFunctions.action();
    },
    rollback: () => {
      if (additionalUndoRedoFunctions && additionalUndoRedoFunctions.rollback) additionalUndoRedoFunctions.rollback();
      set(rootState.planning, oldState.map(lane => angular.copy(lane)));
    },
  }, { root: true });
  context.dispatch('planning/save', null, { root: true });
}

/* ******* */
/* GETTERS */
/* ******* */
function getLaneElements(state, getters, rootState) {
  return function ({ planning = rootState.planning, laneId }) {
    return planning.elements.filter(el => el.data.lane_id == laneId);
  };
}

function getLaneContentHeight(state, getters, rootState, rootGetters) {
  return function (laneId) {
    let planning;
    let lane = state.find(item => item.id == laneId);
    if (! lane) {
      const subprojectLane = rootGetters['subprojects/getLaneSubProject'](laneId);
      if (! subprojectLane || ! subprojectLane.lane) return 0;
      ({ lane, planning } = subprojectLane);
    }
    if (lane.hidden) {
      lane.contentHeight = 30;
      return lane.contentHeight;
    }
    const minheight = constants.laneMinHeight;// default min value
    let height = 0;
    const laneElements = getters.getLaneElements({ planning, laneId }) || [];
    const els = laneElements.filter(el => el.visible);
    if (! els.length) { // lane empty
      height = minheight;
    } else {
      PlanningLane.updateAllYposition(els);
      height = Math.max.apply(null, els.map(o => (o.yposition || 0) + (o.height || 0)));
    }
    lane.contentHeight = Math.ceil(Math.max(minheight, height) + constants.lanePadding + 1);
    return lane.contentHeight;
  };
}

function getLaneHeaderHeight(state) {
  return function (laneId) {
    const lane = state.find(item => item.id == laneId);
    if (lane && lane.hidden) return 0;
    const domEl = document.getElementById(`laneheader${laneId}`);
    if (! domEl) return 0;
    const originalHeight = domEl.style.height;
    const originalMinHeight = domEl.style['min-height'];
    domEl.style.height = "auto";
    domEl.style['min-height'] = "auto";
    const laneHeaderHeight = outerHeight(domEl);
    domEl.style.height = originalHeight;
    domEl.style['min-height'] = originalMinHeight;
    return laneHeaderHeight;
  };
}

function getLaneHeadersHeights(state) {
  const laneHeadersHeights = {};
  state.forEach((lane) => {
    // eslint-disable-next-line no-unused-vars
    const { label, contentHeight } = lane;
    laneHeadersHeights[lane.id] = outerHeight(document.getElementById(`laneheader${lane.id}`));
  });
  return laneHeadersHeights;
}

/* ******* */
/* ACTIONS */
/* ******* */
function addLane(context, newlane) {
  const { state: lanes, rootState } = context;
  undoState = lanes.map(lane => angular.copy(lane));
  const lane = insertNewLane(rootState.planning, newlane);
  lane.id = nextId(lanes);
  lane.label = newlane && newlane.label || rootState.lang.i18n && rootState.lang.i18n.t('CONSTANTS.NEW_LANE');
  undoredoAndSave(context);
  return lane;
}

async function deleteLane(context, index) {
  const { state: lanes, dispatch } = context;
  undoState = lanes.map(lane => angular.copy(lane));
  const lane = lanes[index];
  if (lane && lane.project_id) {
    dispatch('subprojects/removeByLaneId', lane.id, { root: true });
  }
  if (lane) {
    lanes.splice(index, 1);
  }
  const elementsUndoRedoFunctions = await dispatch('planning/deleteLanes', [lane], { root: true });
  undoredoAndSave(context, elementsUndoRedoFunctions);
}

async function updateAllLanes(context, { lanesToAdd, lanesToDelete, lanesToUpdate, lanesIdsOrder }) {
  const { state: lanes, dispatch, rootState } = context;
  undoState = lanes.map(lane => angular.copy(lane));

  // add
  lanesToAdd.forEach((newlane) => {
    const lane = new PlanningLane(rootState.planning, newlane);
    lane.id = nextId(lanes);
    lane.label = newlane && newlane.label || rootState.lang.i18n && rootState.lang.i18n.t('CONSTANTS.NEW_LANE');
    lanes.splice(lanesIdsOrder.indexOf(newlane.id), 0, lane);
  });

  // delete
  const realDeletedLanes = [];
  lanesToDelete.forEach((laneToDelete) => {
    const index = lanes.findIndex(item => item.id == laneToDelete.id);
    if (index == -1) return;
    const lane = lanes[index];
    if (lane && lane.project_id) {
      dispatch('subprojects/removeByLaneId', lane.id, { root: true });
    }
    if (lane) {
      lanes.splice(index, 1);
    }
    realDeletedLanes.push(lane);
  });
  const elementsUndoRedoFunctions = await dispatch('planning/deleteLanes', realDeletedLanes, { root: true });

  // update
  for (let i = 0; i < lanesToUpdate.length; i++) {
    const lane = lanes.find(item => item.id == lanesToUpdate[i].id);
    if (lane.hidden != lanesToUpdate[i].hidden) dispatch('setLaneHidden', { lane, hidden: lanesToUpdate[i].hidden });
    lane.set(lanesToUpdate[i].getAll());
  }

  // sort
  lanes.sort((a, b) => {
    const indexA = lanesIdsOrder.indexOf(a.id);
    const indexB = lanesIdsOrder.indexOf(b.id);
    if (indexA == -1 || indexB == -1) {
      return 0;
    }
    return indexA < indexB ? -1 : 1;
  });

  undoredoAndSave(context, elementsUndoRedoFunctions);
}

function startSortLanes(context) {
  const { state: lanes } = context;
  undoState = lanes.map(lane => angular.copy(lane));
}

function updateSortLanes(context) {
  undoredoAndSave(context);
}

function startChangingContent(context) {
  const { state: lanes } = context;
  undoState = lanes.map(lane => angular.copy(lane));
}

function changingContent(context) {
  const { state: lanes } = context;
  if (! angular.equals(lanes, undoState)) {
    undoredoAndSave(context);
  }
}

function setLaneHidden({ getters }, { lane, hidden = false }) {
  lane.hidden = hidden;
  if (lane.hidden) return;
  const laneElements = getters.getLaneElements({ laneId: lane.id }) || [];
  setTimeout(() => {
    laneElements.forEach((el) => {
      el.update();
      el.updateHeight();
    });
  });
}

export default {
  namespaced: true,
  state: [],
  getters: {
    getLaneElements,
    getLaneContentHeight,
    getLaneHeaderHeight,
    getLaneHeadersHeights,
  },
  actions: {
    addLane,
    deleteLane,
    updateAllLanes,
    startSortLanes,
    updateSortLanes,
    startChangingContent,
    changingContent,
    setLaneHidden,
  },
};
