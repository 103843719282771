<template>
  <div class="mx-auto" style="max-width: 400px">
    <form @submit.prevent="submitRegister">
      <div class="display-flex">
        <div style="flex: 1 1 auto">
          <v-text-field id="firstnameField" v-model="firstname" :label="$t('GLOBAL.FIRSTNAME')" hide-details prepend-icon="fas fa-user" autofocus tabindex="1" required>
          </v-text-field>
        </div>
        <v-spacer class="mx-2"></v-spacer>
        <div style="flex: 1 1 auto">
          <v-text-field id="lastnameField" v-model="lastname" :label="$t('GLOBAL.LASTNAME')" hide-details tabindex="2" required>
          </v-text-field>
        </div>
      </div>
      <v-text-field id="emailField" v-model="email" :label="$t('GLOBAL.EMAIL')" hide-details prepend-icon="fas fa-at" tabindex="3" autocomplete="email" required>
      </v-text-field>
      <v-text-field id="phoneField" v-model="phone" :label="$t('LOGIN.PHONE')" hide-details prepend-icon="fas fa-phone" tabindex="4" required>
      </v-text-field>
      <input v-model="lang" type="hidden">
      <v-text-field id="passwordField" v-model="password" :label="$t('GLOBAL.PASSWORD')" type="password" hide-details prepend-icon="fas fa-lock" tabindex="5" required>
      </v-text-field>
      <v-text-field id="passwordConfirmationField" v-model="passwordConfirmation" :label="$t('LOGIN.PASSWORD_CONFIRM')" type="password" hide-details prepend-icon=" " tabindex="6" required>
      </v-text-field>
      <v-checkbox required>
        <template #label>
          <i18n path="LOGIN.ACCEPT_CGV" tag="span">
            <a target="_blank" href="https://bubbleplan.net/cgv" style="text-decoration:underline" @click.stop>{{ $t('LOGIN.CGV') }}</a>
          </i18n>
        </template>
      </v-checkbox>

      <v-alert :value="!! error" type="error" class="mb-2">{{ error }}</v-alert>
      <div class="text-center">
        <v-btn :disabled="isSubmittingRegister" :loading="isSubmittingRegister" color="accent" rounded large type="submit" tabindex="7" class="ma-2">
          {{ $t('LOGIN.REGISTER') }}
        </v-btn>
      </div>
    </form>
    <div class="mt-6">
      <v-btn text block class="ma-0" @click.prevent="$emit('navigate-section', 'login')">{{ $t('LOGIN.ALREADY_HAVE_ACCOUNT') }}</v-btn>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        firstname: "",
        lastname: "",
        email: this.$route.query.registeremail,
        phone: "",
        password: "",
        passwordConfirmation: "",
        error: "",
        isSubmittingRegister: false,
      };
    },
    computed: {
      lang() {
        return this.$store.state.lang.lang;
      },
    },
    methods: {
      submitRegister() {
        this.error = "";
        if (! window.isValidEmailAddress(this.email)) {
          this.error = this.$t("LOGIN.ERROR_INVALID_EMAIL");
          return;
        }
        if (this.password !== this.passwordConfirmation) {
          this.error = this.$t("LOGIN.ERROR_PASSWORD_MATCH");
          return;
        }

        this.isSubmittingRegister = true;
        window.apiSrv.call('users', 'store', {
          firstname: this.firstname,
          lastname: this.lastname,
          email: this.email,
          phone: this.phone,
          lang: this.lang,
          password: this.password,
        }).then((response) => {
          const token = response && response.data && response.data.user && response.data.user.remember_token;
          window.apiSrv.setAuthToken(token);
          setTimeout(() => { window.location.href = ""; });
        }).catch((error) => {
          if (typeof error == 'object') {
            this.error = Object.keys(error).map((key) => {
              const messages = error[key];
              if (Array.isArray(messages)) return `- ${messages.join('\n- ')}`;
              return messages;
            }).join("\n");
          } else {
            this.error = error;
          }
          this.isSubmittingRegister = false;
        });
      },
    },
  };
</script>
