<template>
  <div v-if="! isPremium">
    <b><v-icon small class="mr-2">fas fa-share-alt</v-icon>{{ $t('ACCOUNT.INTEGRATIONS') }}</b>
    <div v-html="$t('PREMIUM.INTEGRATIONS_IS_PREMIUM')"></div>
  </div>
  <div v-else>
    <div v-if="! outlookIsConnected && ! gcalIsConnected">
      <b><v-icon small class="mr-2">fas fa-share-alt</v-icon>{{ $t('ACCOUNT.INTEGRATIONS') }}</b>
      <div v-if="outlookIsLoading && gcalIsLoading" class="ml-6"><i class="fas fa-spinner fa-spin fa-2x fa-fw"></i></div>
      <div v-else class="display-flex align-center">
        <img :alt="$t('INTEGRATIONS.MICROSOFT_OUTLOOK')" :title="$t('INTEGRATIONS.MICROSOFT_OUTLOOK')" src="../../img/integrations/outlook.png" style="max-height: 36px;">
        <img :alt="$t('INTEGRATIONS.GOOGLE_CALENDAR')" :title="$t('INTEGRATIONS.GOOGLE_CALENDAR')" src="../../img/integrations/gcal.png" class="ml-2" style="max-height: 36px;">
        <v-btn small rounded color="accent" class="mx-2" dark @click="openIntegrationsWindow()">
          {{ $t('INTEGRATIONS.CONNECT') }}
        </v-btn>
      </div>
    </div>
    <div v-if="outlookIsConnected" :style="{ opacity: outlookIsLoading ? .5 : '' }" class="mb-1">
      <b><v-icon small class="mr-2">fas fa-share-alt</v-icon>{{ $t('INTEGRATIONS.MICROSOFT_OUTLOOK') }}</b>
      <div class="display-flex align-center">
        <img src="../../img/integrations/outlook.png" style="max-height: 36px;">
        <div :style="{ 'pointer-events': outlookIsLoading ? 'none' : '' }" class="ml-2">
          <div v-if="outlookIsLoading">
            <i class="fas fa-spinner fa-spin fa-2x fa-fw"></i>
          </div>
          <div v-else class="display-flex align-center">
            <v-switch v-if="outlookIsConnected" :input-value="outlookStatus" hide-details class="ma-0 pt-0" @click="toggleOutlook">
              <template #label>
                <span class="small secondary--text">{{ outlookStatus ? $t('INTEGRATIONS.SYNCHRONIZED') : '' }}</span>
              </template>
            </v-switch>
            <div v-if="microsoftError" class="ml-2">
              <i class="fa fa-lg fa-exclamation-triangle danger"></i> {{ microsoftError }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="gcalIsConnected" :style="{ opacity: gcalIsLoading ? .5 : '' }" class="mb-1">
      <b><v-icon small class="mr-2">fas fa-share-alt</v-icon>{{ $t('INTEGRATIONS.GOOGLE_CALENDAR') }}</b>
      <div class="display-flex align-center">
        <img src="../../img/integrations/gcal.png" style="max-height: 36px;">
        <div :style="{ 'pointer-events': gcalIsLoading ? 'none' : '' }" class="ml-2">
          <div v-if="gcalIsLoading">
            <i class="fas fa-spinner fa-spin fa-2x fa-fw"></i>
          </div>
          <div v-else class="display-flex align-center">
            <v-switch v-if="gcalIsConnected" :input-value="gcalStatus" hide-details class="ma-0 pt-0" @click="toggleGcal">
              <template #label>
                <span class="small secondary--text">{{ gcalStatus ? $t('INTEGRATIONS.SYNCHRONIZED') : '' }}</span>
              </template>
            </v-switch>
            <div v-if="googleError" class="ml-2">
              <i class="fa fa-lg fa-exclamation-triangle danger"></i> {{ googleError }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      el: { type: Object, required: true },
    },
    data() {
      return {
        microsoftError: "",
        googleError: "",
      };
    },
    computed: {
      outlookIsConnected() {
        return this.$store.state.integrations.microsoft.outlook.connected;
      },
      outlookIsLoading() {
        return this.$store.state.integrations.microsoft.outlook.loading;
      },
      outlookStatus() {
        return this.$store.getters['integrations/microsoft/getElementStatus'](this.el);
      },
      gcalIsConnected() {
        return this.$store.state.integrations.google.gcal.connected;
      },
      gcalIsLoading() {
        return this.$store.state.integrations.google.gcal.loading;
      },
      gcalStatus() {
        return this.$store.getters['integrations/google/getElementStatus'](this.el);
      },
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
    },
    created() {
      if (this.outlookIsConnected === null) {
        this.$store.dispatch('integrations/microsoft/refreshConnection').catch((message) => {
          this.microsoftError = message || this.$t('GLOBAL.ERROR');
        });
      }
      if (this.gcalIsConnected === null) {
        this.$store.dispatch('integrations/google/refreshConnection').catch((message) => {
          this.googleError = message || this.$t('GLOBAL.ERROR');
        });
      }
    },
    methods: {
      toggleOutlook() {
        this.microsoftError = '';
        this.$store.dispatch('integrations/microsoft/setElementStatus', { el: this.el, sync: this.outlookStatus ? -1 : 1 }).then((response) => {
          if (this.outlookStatus) {
            return this.$store.dispatch('integrations/microsoft/postEvent', { el: this.el });
          }
          return response;
        }).catch((message) => {
          this.microsoftError = message || this.$t('GLOBAL.ERROR');
        });
      },
      toggleGcal() {
        this.googleError = '';
        this.$store.dispatch('integrations/google/setElementStatus', { el: this.el, sync: this.gcalStatus ? -1 : 1 }).then((response) => {
          if (this.gcalStatus) {
            return this.$store.dispatch('integrations/google/postEvent', { el: this.el });
          }
          return response;
        }).catch((message) => {
          this.googleError = message || this.$t('GLOBAL.ERROR');
        });
      },
      openIntegrationsWindow() {
        this.$emit('close');
        this.$store.commit('ui/navigation/setOpenedModal', 'integrations-manager');
      },
    },
  };
</script>
