<template>
  <v-container class="px-12 pt-6 pb-4">
    <!-- Firstname and Lastname -->
    <div class="display-flex">
      <v-text-field v-model="firstname" :label="$t('ACCOUNT.FIRSTNAME')" prepend-icon="fas fa-user"></v-text-field>
      <v-text-field v-model="lastname" :label="$t('ACCOUNT.LASTNAME')" class="ml-6"></v-text-field>
    </div>
    <!-- Phone number, language and e-mail -->
    <v-text-field v-model="phone" :label="$t('LOGIN.PHONE')" prepend-icon="fas fa-phone"></v-text-field>
    <v-select v-model="lang" :items="[{ value: 'fr', text: 'Français' }, { value: 'en', text: 'English' }]"
              :label="$t('ACCOUNT.EMAIL_LANGUAGE')" prepend-icon="fas fa-globe-europe">
    </v-select>
    <div class="display-flex">
      <v-text-field v-model="email" :label="$t('GLOBAL.EMAIL')" hide-details prepend-icon="fas fa-at" disabled></v-text-field>
      <v-btn v-show="displayEmailEditButton" color="primary" fab small class="mr-0" @click="changeEmail()">
        <v-icon small>fas fa-edit</v-icon>
      </v-btn>
    </div>
    <!-- Change e-mail Field-->
    <div v-show="displayChangeEmail" class="display-flex ml-6">
      <v-text-field v-model="newEmail" :label="$t('ACCOUNT.NEW_EMAIL')" :rules="[rules.email]"></v-text-field>
      <v-text-field v-model="check_password" :label="$t('GLOBAL.PASSWORD')" type="password" class="ml-6"></v-text-field>
    </div>
    <!-- Password -->
    <div class="display-flex mt-4">
      <v-text-field :label="$t('GLOBAL.PASSWORD')" type="password" value="**************" hide-details prepend-icon="fas fa-lock" disabled></v-text-field>
      <v-btn v-show="displayPasswordEditButton" color="primary" fab small class="mr-0" @click="changePassword()">
        <v-icon small>fas fa-edit</v-icon>
      </v-btn>
    </div>
    <!-- Field to change password -->
    <div v-show="displayChangePassword" class="display-flex ml-6">
      <v-text-field v-model="check_password" :label="$t('ACCOUNT.OLD_PASSWORD')" type="password"></v-text-field>
      <v-text-field v-model="newPassword" :label="$t('ACCOUNT.NEW_PASSWORD')" type="password" class="mx-6"></v-text-field>
      <v-text-field v-model="passwordConfirmation" :label="$t('ACCOUNT.CONFIRM_PASSWORD')" type="password"></v-text-field>
    </div>
    <div v-show="errorPassword" class="ml-6">
      <p style="color:red;">{{ errorPassword }}</p>
    </div>
    <v-divider class="mt-12"></v-divider>
    <div class="display-flex justify-end mt-4">
      <v-btn color="secondary" rounded large outlined class="mx-2" @click="close">{{ $t('GLOBAL.CANCEL') }}</v-btn>
      <v-btn :loading="loading" color="accent" rounded large class="mx-2" @click="updateProfile()">{{ $t('GLOBAL.SAVE') }}</v-btn>
    </div>
  </v-container>
</template>

<script>
  export default {
    data() {
      return {
        firstname: "",
        lastname: "",
        phone: "",
        email: "",
        newEmail: "",
        check_password: "",
        lang: "",
        newPassword: "",
        passwordConfirmation: "",
        displayChangePassword: false,
        displayPasswordEditButton: true,
        displayChangeEmail: false,
        displayEmailEditButton: true,
        errorPassword: "",
        loading: false,
        rules: {
          email: value => window.isValidEmailAddress(value) || this.$t('ACCOUNT.INVALID_EMAIL'),
        },
      };
    },
    computed: {
      user() { return this.$store.state.users.user; },
    },
    created() {
      this.$store.state.users.userPromise.then(() => {
        this.firstname = this.user.firstname;
        this.lastname = this.user.lastname;
        this.phone = this.user.phone;
        this.email = this.user.email;
        this.lang = this.user.lang;
      });
    },
    methods: {
      changeEmail() {
        this.displayEmailEditButton = false;
        this.displayChangeEmail = true;
      },
      changePassword() {
        this.displayPasswordEditButton = false;
        this.displayChangePassword = true;
      },
      close() {
        this.$emit('close');
      },
      updateProfile() {
        this.errorPassword = "";
        const data = {
          id: this.user.id,
          firstname: this.firstname,
          lastname: this.lastname,
          phone: this.phone,
          lang: this.lang,
        };
        if (this.newEmail) data.email = this.newEmail;
        if (this.check_password) data.check_password = this.check_password;
        if (this.newPassword) data.password = this.newPassword;
        if (this.passwordConfirmation) data.password_confirmation = this.passwordConfirmation;
        this.loading = true;
        window.apiSrv.call('users', 'update', data).then(() => {
          this.$store.commit('users/setUser', data);
          this.$emit('close');
        }).catch((message) => {
          this.loading = false;
          this.errorPassword = message;
        });
      },
    },
  };
</script>
