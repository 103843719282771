<template>
  <v-container>
    <div class="text-center mb-4">
      <v-btn color="secondary" rounded @click="addStep">
        <v-icon small class="mr-2">fas fa-plus</v-icon> {{ $t('MONITORING_PLAN.ADD_WAYPOINT') }}
      </v-btn>
    </div>
    <div v-if="steps.length">
      <div>
        <div class="display-flex align-center">
          <div class="display-flex align-center" style="font-weight: bold; width: 280px;">
            <button :style="hasPrevStep ? {} : {'color': 'gray', 'pointer-events': 'none'}" class="arrow-button" @click="toNextStep(-1)">
              <i class="fa fa-2x fa-caret-left"></i>
            </button>
            <date-field v-model="currentStepDate" hide-details input-class="my-0 mx-2 pa-0 text-center" no-null></date-field>
            <button :style="hasNextStep ? {} : {'color': 'gray', 'pointer-events': 'none'}" class="arrow-button" @click="toNextStep()">
              <i class="fa fa-2x fa-caret-right"></i>
            </button>
          </div>
          <v-spacer></v-spacer>
          <v-divider vertical class="ml-auto mr-2"></v-divider>
          <v-btn small icon outlined color="errorred" @click="deleteStep">
            <v-icon color="errorred" small>fas fa-trash-alt</v-icon>
          </v-btn>
        </div>
        <div class="mt-4">
          <div v-for="moodicon in ['sun', 'suncloud', 'cloud', 'storm']" :key="moodicon"
               :style="currentStep.mood == moodicon ? {'border': '3px solid green', padding: '6px'} : null" class="pointer vcenter moodicon-wrapper"
               @click="currentStep.mood == moodicon ? currentStep.mood = null : currentStep.mood = moodicon;">
            <div :class="'mood-icon-' + moodicon" :title="$t('MOOD.' + uppercase(moodicon))"></div>
          </div>
        </div>
      </div>
      <div class="row" style="margin-top: 15px">
        <div class="col-md-6">
          <div class="input-group">
            <label>{{ $t('MONITORING_PLAN.WAYPOINT') }}</label>
            <summernote v-model="currentStep.description" name="descriptionEditor" :default-content="defaultStepWaypoint" :config="{ height: '150px' }" lang="fr-FR"></summernote>
          </div>
        </div>
        <div class="col-md-6">
          <div class="input-group">
            <label>{{ $t('MONITORING_PLAN.BLOCKING_POINTS') }}</label>
            <summernote v-model="currentStep.issues" name="issuesEditor" :config="{ height: '150px' }" lang="fr-FR"></summernote>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center" style="margin: 15px; 0">
      <div class="text-center">
        <v-btn color="secondary" rounded large outlined class="mx-2" @click="$emit('close', 'cancel')">{{ $t('GLOBAL.CANCEL') }}</v-btn>
        <v-btn color="accent" rounded large class="mx-2" @click="saveSteps">{{ $t('GLOBAL.SAVE') }}</v-btn>
      </div>
    </div>
  </v-container>
</template>

<style scoped>
  [class^="mood-icon-"], [class*=" mood-icon-"] {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .arrow-button {
    height: 34px;
    outline-style: none;
    background-color: transparent;
    border: none;
    vertical-align: middle;
  }

  .moodicon-wrapper {
    border-radius: 50%;
    width: 64px;
    height: 64px;
    transition: .3s;
  }
  .moodicon-wrapper + .moodicon-wrapper {
    margin-left: 16px;
  }
</style>

<script>
  import clientConfig from '@/client_customs/config';
  import Summernote from '@/components/Reusables/Summernote';

  function deepcopy(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  export default {
    components: {
      Summernote,
    },
    props: {
      monitoringLane: { type: Object, required: true },
    },
    data() {
      const steps = deepcopy(this.monitoringLane.steps || []);
      return {
        level: this.monitoringLane.level,
        label: this.monitoringLane.label,
        steps,
        currentStepIndex: steps.length - 1,
        defaultStepWaypoint: clientConfig.projectsheet && clientConfig.projectsheet.defaultStepWaypoint || "",
        comments: deepcopy(this.monitoringLane.comments || []),
      };
    },
    computed: {
      currentStep() {
        const step = this.steps[this.currentStepIndex];
        if (this.steps.length && ! step) {
          this.currentStepIndex = this.steps.length - 1;
          return this.steps[this.currentStepIndex];
        }
        return step;
      },
      currentStepDate: {
        get() { return this.currentStep && this.currentStep.date; },
        set(newVal) {
          if (! newVal) return;
          const step = this.currentStep;
          step.date = newVal.format();
          this.steps.sort((a, b) => (moment(a.date).isBefore(b.date) ? -1 : 1));
          this.currentStepIndex = this.steps.indexOf(step);
        },
      },
      hasPrevStep() {
        return this.currentStepIndex >= 1;
      },
      hasNextStep() {
        return this.currentStepIndex < this.steps.length - 1;
      },
    },
    watch: {
      monitoringLane: {
        handler(monitoringLane) {
          this.level = monitoringLane.level;
          this.label = monitoringLane.label;
          this.steps = deepcopy(monitoringLane.steps || []);
          this.comments = deepcopy(monitoringLane.comments || []);
        },
        deep: true,
      },
    },
    methods: {
      toNextStep(amount = 1) {
        this.currentStepIndex += amount;
      },
      addStep() {
        const newStep = {
          description: "", issues: "", date: moment().format(), mood: null,
        };
        this.steps.push(newStep);
        this.steps.sort((a, b) => (moment(a.date).isBefore(b.date) ? -1 : 1));
        this.currentStepIndex = this.steps.indexOf(newStep);
      },
      deleteStep() {
        if (this.currentStep) this.steps.splice(this.currentStepIndex, 1);
        this.currentStepIndex = Math.min(this.currentStepIndex, this.steps.length - 1);
      },
      saveSteps() {
        this.$emit('update', {
          steps: deepcopy(this.steps),
        });
      },
      uppercase(str) {
        return str && str.toUpperCase();
      },
    },
  };
</script>
