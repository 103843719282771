<template>
  <div class="element-details-users">
    <div class="display-flex align-center mb-2">
      <div class="mr-2 text-center" style="flex: 0 0 24px;">
        <v-icon color="primary" small>fas fa-user</v-icon>
      </div>
      <label class="uppercase primary--text ma-0">{{ $t('ELEMENT-DETAIL.USERS') }}</label>
      <v-spacer></v-spacer>
      <v-divider vertical class="mx-2"></v-divider>
      <v-btn :title="$t(el.getConfig('show-users') ? 'ELEMENT-DETAIL.HIDE_ON_PLANNING' : 'ELEMENT-DETAIL.SHOW_ON_PLANNING')" icon small tabindex="-1"
             @click="el.getConfig()['show-users'] = ! el.getConfig()['show-users']">
        <v-icon small>{{ el.getConfig('show-users') ? 'fa-eye' : 'fa-eye-slash' }}</v-icon>
      </v-btn>
    </div>
    <div class="ml-6 pl-2">
      <template v-if="users.length">
        <table class="table table-condensed table-noborder">
          <tr v-for="(user, userIndex) in users">
            <td class="text-center" style="width:1px; padding-bottom: 3px">
              <template v-if="user.id">
                <img v-if="getUserById(user.id) && user.avatar" :src="user.avatar" style="max-height: 20px; border-radius: 50%;">
                <v-icon v-else :title="$tc('MULTIPROJECTS.DELETED_USER', 1)" size="14" class="color-inherit">fas fa-user-slash</v-icon>
              </template>
              <template v-else-if="user.group_id">
                <v-icon v-if="getGroupById(user.group_id)" size="14" class="color-inherit">far fa-users</v-icon>
                <v-icon v-else :title="$t('MULTIPROJECTS.DELETED_GROUP')" size="14" class="color-inherit">far fa-users-slash</v-icon>
              </template>
              <v-icon v-else size="14" class="color-inherit">fas fa-user</v-icon>
            </td>
            <td class="pl-2">
              <span v-if="user.group_id">{{ user.title }}</span>
              <span v-else>{{ user | username }}</span>
            </td>
            <td class="text-center" style="width: 40%">
              <v-progress-linear :class="{ pointer: ! isConsult }" :value="user.percent" color="successgreen" height="12" rounded style="flex: 1 1 auto;"
                                 @click="! isConsult && clickUserProgressBar(user, $event)">
                <div v-show="user.percent > 0" :style="{ 'margin-right': `${100 - user.percent}%` }"
                     class="white--text small display-flex align-center justify-center" style="height: 100%; transition: all .3s">
                  {{ user.percent }}%
                </div>
              </v-progress-linear>
            </td>
            <td v-if="! isConsult" class="text-right" style="width: 0px">
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn icon small outlined color="errorred" class="user-menu ml-2" v-on="on" @click="removeUser(userIndex)">
                    <v-icon small>far fa-trash-alt</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('ELEMENT-DETAIL.REMOVE_USER') }}</span>
              </v-tooltip>
            </td>
          </tr>
        </table>
        <hr v-if="! isConsult" class="mx-auto my-0" style="border-top: 1px solid #ccc">
      </template>
      <div v-if="! isConsult" class="display-flex align-center">
        <v-combobox ref="newUserAutocomplete" v-model="newUserSelected" :items="filteredCompanyUsers"
                    :filter="(item, queryText) => getNewUserLabel(item).toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1"
                    :label="$t('ELEMENT-DETAIL.ADD_USER')" :hint="$t('ELEMENT-DETAIL.ADD_USER_HINT')"
                    return-object clearable
                    @change="addUser" @update:search-input="newUserInput = $event">
          <template #item="{ parent, item }">
            <v-list-item-avatar>
              <img v-if="item.avatar" :src="item.avatar">
              <v-icon v-else class="color-inherit">{{ item.icon || 'fas fa-user' }}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="parent.genFilteredText(getNewUserLabel(item))"></v-list-item-title>
            </v-list-item-content>
          </template>
        </v-combobox>
        <v-btn :disabled="! newUserInput" color="accent" small rounded class="ml-2">{{ $t('ELEMENT-DETAIL.ADD') }}</v-btn>
      </div>
    </div>
  </div>
</template>

<style>
  .element-details-users .user-menu {
    visibility: hidden;
    opacity: 0;
    transition: opacity .3s;
  }
  .element-details-users tr:hover > td > .user-menu {
    visibility: visible;
    opacity: 1;
  }
</style>

<script>
  export default {
    props: {
      el: { type: Object, required: true },
    },
    data() {
      return {
        planning: this.el.getPlanning(),
        newUserSelected: null,
        newUserInput: '',
      };
    },
    computed: {
      isConsult() { return this.el.isType('macro'); },
      users() { return this.el.getUsers() || []; },
      organizationUsers() {
        return this.$store.getters['users/getOrganizationUsers'];
      },
      companyGroups() {
        return this.$store.getters['users/groups/getCompanyGroups'] || [];
      },
      getUserById() {
        return this.$store.getters['users/getUserById'];
      },
      getGroupById() {
        return this.$store.getters['users/groups/getGroupById'];
      },
      otherUsersList() {
        const otherUsers = [];
        this.planning.elements.forEach((element) => {
          (element.getUsers() || []).forEach((user) => {
            const username = user.username || user.email;
            if (! user.id && username && otherUsers.findIndex(item => item.username === username) == -1) {
              otherUsers.push({ username });
            }
          });
        });
        const { textUsers } = this.$store.getters['users/accessRight/config'];
        if (textUsers) {
          return otherUsers.concat(textUsers.filter(item => ! otherUsers.find(subitem => subitem.username == item.username)));
        }
        return otherUsers.sort((a, b) => (a.username < b.username ? -1 : 1));
      },
      // INLI
      dynamicsContacts() {
        return this.planning?.config?.customFields?.dyn_contacts || [];
      },
      // /INLI
      groupUsersList() {
        if (! this.isBusiness) return [];
        return this.companyGroups.map(item => ({
          group_id: item.id,
          title: item.title,
          icon: 'far fa-users',
        }));
      },
      filteredCompanyUsers() {
        return this.groupUsersList.concat(this.organizationUsers, this.dynamicsContacts).filter((user) => {
          if (user.group_id) return ! this.users.find(item => item.group_id == user.group_id);
          if (user.id) return ! this.users.find(item => item.id == user.id);
          return this.users.findIndex(item => item.username === user.username) == -1;
        });
      },
      usernames() {
        return this.organizationUsers.concat(this.dynamicsContacts).reduce((acc, user) => {
          acc[user.id || user.username] = this.$store.getters['users/getUsername'](user);
          return acc;
        }, {});
      },
      isBusiness() { return this.$store.state.users.accessRight.isBusiness; },
    },
    watch: {
      organizationUsers: {
        handler() { this.updateUsersData(); },
        immediate: true,
      },
      companyGroups: {
        handler() { this.updateUsersData(); },
        immediate: true,
      },
    },
    methods: {
      addUser(specificUser) {
        const user = specificUser || this.newUserSelected;
        if (! user) return;
        if (typeof user == 'object') { // user in company users or existing virtual participant
          if (! user.id && ! user.username && ! user.group_id) return;
          if (user.id && this.users.find(item => item.id == user.id)) return;
          if (user.username && this.users.find(item => item.username == user.username)) return;
          if (user.group_id && this.users.find(item => item.group_id == user.group_id)) return;
          let fields = ['username', 'msdyn_projectteamid'];
          if (user.id) fields = ['id', 'email', 'firstname', 'lastname', 'avatar', 'percent'];
          if (user.group_id) fields = ['group_id', 'title'];
          this.users.push({ ..._.pick(user, fields), percent: 0 });
        } else { // free text
          const username = this.newUserInput;
          if (! username) return;
          if (this.users.find(item => item.username == username)) return;
          this.users.push({ username, percent: 0 });
        }
        this.el.setUsers(this.users);
        this.newUserInput = '';
        this.$nextTick(() => {
          this.$refs.newUserAutocomplete.isMenuActive = false;
          this.newUserSelected = null;
        });
      },
      removeUser(index) {
        if (index > -1 && index < this.users.length) {
          this.users.splice(index, 1);
          this.el.setUsers(this.users);
        }
      },
      getNewUserLabel(item) {
        return item.group_id ? item.title : this.usernames[item.id || item.username];
      },
      clickUserProgressBar(user, event) {
        const fullWidth = event.currentTarget.clientWidth;
        if (! fullWidth) return;
        user.percent = Math.min(Math.round(event.offsetX / fullWidth * 10) * 10, 100);
      },
      updateUsersData() {
        if (! this.users.length) return;
        const users = this.users.map((user) => {
          const companyUser = user.id && this.getUserById(user.id);
          if (companyUser) {
            return {
              ...user,
              firstname: companyUser.firstname,
              lastname: companyUser.lastname,
              email: companyUser.email,
              avatar: companyUser.avatar,
            };
          }
          const companyGroup = user.group_id && this.getGroupById(user.group_id);
          if (companyGroup) {
            return {
              ...user,
              title: companyGroup.title,
            };
          }
          return user;
        });
        this.el.setUsers(users);
      },
    },
  };
</script>
