import { loadRemoteScript } from './helpers';

/* ONE DRIVE PICKER */
const pickerLink = `${window.location.protocol}//${window.location.host}${window.location.pathname}onedrive_picker_redirect.html`;
function loadPicker(cb) {
  loadRemoteScript('https://js.live.net/v7.2/OneDrive.js', 'lib-onedrive').then(() => {
    window.OneDrive.open({
      clientId: "170a7b74-9712-482f-8cc7-369c98a9f598",
      action: "share",
      multiSelect: true,
      advanced: {
        redirectUri: pickerLink,
      },
      success(response) {
        const files = [];
        (response?.value || []).forEach((file) => {
          const permission = file && file.permissions && file.permissions.filter(item => item.link && item.link.type == 'view')[0];
          const url = permission && permission.link && permission.link.webUrl;
          if (url) {
            files.push({
              type: "onedrive",
              url,
              name: file.name,
              icon: 'img/onedrive_icon.png',
            });
          }
        });
        if (cb) cb(files);
      },
      error: console.log,
    });
  });
}

export default {
  onedrive: {
    loadPicker,
  },
};
