<template>
  <div>
    <!-- TITLE -->
    <div class="display-flex align-center">
      <div class="mr-2 text-center" style="flex: 0 0 24px; line-height: 20px;">
        <span class="primary--text" style="font-family: Georgia, serif; font-size:20px">T</span>
      </div>
      <label for="title" class="uppercase primary--text ma-0">{{ $t('ELEMENT-DETAIL.TITLE') }}</label>
      <v-spacer></v-spacer>
      <v-divider vertical class="mx-2"></v-divider>
      <v-btn-toggle :value="titleStyle['font-weight']" class="elevation-1"
                    @change="changeTitleStyle('font-weight', $event)">
        <v-btn value="bold" text x-small tabindex="-1">
          <v-icon small>fa fa-bold fa-fw</v-icon>
        </v-btn>
      </v-btn-toggle>
      <v-btn-toggle :value="titleStyle['font-style']" class="elevation-1 ml-2"
                    @change="changeTitleStyle('font-style', $event)">
        <v-btn value="italic" text x-small tabindex="-1">
          <v-icon small>fa fa-italic fa-fw</v-icon>
        </v-btn>
      </v-btn-toggle>
    </div>
    <div class="ml-6 pl-2">
      <v-combobox v-if="titlesList" id="title" :search-input.sync="elTitle" :items="titlesList" :placeholder="$t('ELEMENT-DETAIL.ENTER_TITLE')" hide-details class="pt-0"></v-combobox>
      <v-text-field v-else id="title" v-model="elTitle" :placeholder="$t('ELEMENT-DETAIL.ENTER_TITLE')"
                    :readonly="el.isFromTemplate()" hide-details class="pt-0">
      </v-text-field>

      <client-custom-component v-if="customTypecode" :name="customTypecode.component" :el="el" :custom-field="customTypecode" class="mt-2"></client-custom-component>
    </div>

    <!-- DESCRIPTION -->
    <client-custom-component v-if="customDescription" :name="customDescription.component" :el="el" :custom-field="customDescription" class="mt-6"></client-custom-component>
    <template v-else>
      <div class="display-flex align-center mt-6">
        <div class="mr-2 text-center" style="flex: 0 0 24px;">
          <v-icon color="primary" small>fa fa-align-justify</v-icon>
        </div>
        <label for="description" class="uppercase primary--text ma-0">{{ $t('ELEMENT-DETAIL.DESCRIPTION') }}</label>
        <template v-if="el.isType('macro', 'task')">
          <v-spacer></v-spacer>
          <v-divider vertical class="mx-2"></v-divider>
          <v-btn-toggle :value="descriptionStyle['font-size'] || '1em'" mandatory class="elevation-1"
                        @change="changeDescriptionStyle('font-size', $event)">
            <v-btn value=".8em" text x-small tabindex="-1">
              <div class="fa-fw"><v-icon style="font-size: .8em">fa fa-font</v-icon></div>
            </v-btn>
            <v-btn value="1em" text x-small tabindex="-1">
              <div class="fa-fw"><v-icon style="font-size: 1em">fa fa-font</v-icon></div>
            </v-btn>
            <v-btn value="1.2em" text x-small tabindex="-1">
              <div class="fa-fw"><v-icon style="font-size: 1.2em">fa fa-font fa-lg</v-icon></div>
            </v-btn>
          </v-btn-toggle>
          <v-btn-toggle :value="descriptionStyle['text-align'] || 'left'" mandatory class="elevation-1 ml-2"
                        @change="changeDescriptionStyle('text-align', $event)">
            <v-btn value="left" text x-small tabindex="-1">
              <v-icon small>fa fa-align-left fa-fw</v-icon>
            </v-btn>
            <v-btn value="center" text x-small tabindex="-1">
              <v-icon small>fa fa-align-center fa-fw</v-icon>
            </v-btn>
            <v-btn value="right" text x-small tabindex="-1">
              <v-icon small>fa fa-align-right fa-fw</v-icon>
            </v-btn>
          </v-btn-toggle>
          <v-divider vertical class="mx-2"></v-divider>
          <v-btn :title="$t(el.getConfig()['show-description'] ? 'ELEMENT-DETAIL.HIDE_ON_PLANNING' : 'ELEMENT-DETAIL.SHOW_ON_PLANNING')" icon small tabindex="-1"
                 @click="el.getConfig()['show-description'] = ! el.getConfig()['show-description']">
            <v-icon small>{{ el.getConfig()['show-description'] ? 'fa-eye' : 'fa-eye-slash' }}</v-icon>
          </v-btn>
        </template>
      </div>
      <div class="ml-6 pl-2">
        <v-textarea id="description" v-model="elDescription" :placeholder="$t('ELEMENT-DETAIL.ENTER_DESCRIPTION')" auto-grow hide-details rows="2" class="pt-0">
        </v-textarea>
      </div>
    </template>

    <!-- DATES -->
    <element-details-header-dates :el="el" @close="close"></element-details-header-dates>
  </div>
</template>

<script>
  import clientConfig from '@/client_customs/config';
  import ElementDetailsHeaderDates from './ElementDetailsHeaderDates.vue';

  export default {
    components: {
      ElementDetailsHeaderDates,
    },
    props: {
      el: { type: Object, required: true },
    },
    data() {
      return {
        customDescription: clientConfig.elements && clientConfig.elements.customDescription,
        customTypecode: clientConfig.elements && clientConfig.elements.customTypecode,
        titlesList: clientConfig.elements && clientConfig.elements.titlesList,
      };
    },
    computed: {
      elTitle: {
        get() { return this.el.getTitle(); },
        set(newVal) {
          this.el.setTitle(newVal);
        },
      },
      titleStyle() {
        return this.el.getTitleStyle() || {};
      },
      elDescription: {
        get() { return this.el.getDescription(); },
        set(newVal) {
          this.el.setDescription(newVal);
        },
      },
      descriptionStyle() {
        return this.el.getDescriptionStyle() || {};
      },
    },
    created() {
      if (this.titlesList) {
        // vuetify search-input does not keep initial value
        const titleInitialValue = this.elTitle;
        setTimeout(() => {
          this.elTitle = titleInitialValue;
        });
      }
    },
    mounted() {
      setTimeout(() => {
        const titleInput = document.getElementById('title');
        if (titleInput) titleInput.focus(); // replace autofocus which is triggered unexpectedly
      });
    },
    methods: {
      close() {
        this.$emit('close');
      },
      changeTitleStyle(prop, newVal) {
        const titleStyle = this.el.getTitleStyle() || {};
        this.$set(titleStyle, prop, newVal);
        this.el.setTitleStyle(titleStyle);
      },
      changeDescriptionStyle(prop, newVal) {
        const descriptionStyle = this.el.getDescriptionStyle() || {};
        this.$set(descriptionStyle, prop, newVal);
        this.el.setDescriptionStyle(descriptionStyle);
      },
    },
  };
</script>
