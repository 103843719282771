<template>
  <section id="share" class="modal-with-tabs">
    <div class="modal-with-tabs-header share-window">
      <div class="pt-6 px-4" style="width: 100%; background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,.5) 100%);">
        <v-tabs v-model="openedTabIndex" background-color="transparent" dark slider-color="primary" style="margin-bottom: -2px;">
          <v-tab href="#contributors">{{ $t('SHARE.CONTRIBUTORS') }}</v-tab>
          <v-tab v-if="hasReadOnlyLink" href="#readonly">{{ $t('SHARE.INVITE_CUSTOMERS') }}</v-tab>
          <v-tab v-if="hasViews" href="#vues">{{ $t('SHARE.CUSTOM_VIEWS') }}</v-tab>
        </v-tabs>
      </div>
    </div>
    <div class="modal-with-tabs-body pa-6">
      <v-tabs-items v-model="openedTabIndex">
        <v-tab-item value="contributors">
          <div style="position: relative;">
            <manage-projects-project-acr :project="{ id: planning.id, access_right: planning.meta.access_right, owner_id: planning.meta.owner_id }"
                                         :shared-users-per-page="10" :link-to-manage="isPremium" hide-owner hide-notifications
                                         @close="close">
            </manage-projects-project-acr>
          </div>
          <v-divider class="my-6"></v-divider>
          <div class="pb-2">
            <div><b class="primary--text text-uppercase">{{ $t('GLOBAL.OPTIONS') }}</b></div>
            <div class="pointer ml-6 mt-1">
              <v-switch v-model="dragAndDropEnabled" hide-details class="ma-0">
                <template #label>
                  <span class="secondary--text">{{ $t('CONFIG.LOCK_DRAG_DROP') }}</span>
                </template>
              </v-switch>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item v-if="hasReadOnlyLink" value="readonly">
          <div><b class="primary--text text-uppercase">{{ $t('SHARE.INVITE_CUSTOMERS') }}</b></div>
          <div class="ml-6 mt-1">
            {{ $t('SHARE.INVITE_CUSTOMERS_DETAILS') }}
          </div>
          <div class="display-flex mx-6 mt-2">
            <v-text-field id="inputReadOnlyLink"
                          :placeholder="$t('SHARE.PREMIUM_FEATURE')" :value="readonlyLink" solo readonly @focus="$event.target.select()">
              <template #append>
                <v-tooltip v-if="isPremium" bottom>
                  <template #activator="{ on }">
                    <v-btn icon class="mr-0" v-on="on" @click="copyToClipboard()">
                      <v-icon v-show="! copyingToClipboard.success && ! copyingToClipboard.error" color="primary">fas fa-clipboard</v-icon>
                      <v-icon v-show="copyingToClipboard.success" color="successgreen">fas fa-check</v-icon>
                      <v-icon v-show="copyingToClipboard.error" color="errorred">fas fa-exclamation-triangle</v-icon>
                    </v-btn>
                  </template>
                  {{ copyingToClipboard.error ? $t('SHARE.COPY_FAILED') : $t('SHARE.COPY_CLIPBOARD') }}
                </v-tooltip>
                <v-btn v-else :href="$t('PREMIUM.OFFERS_LINK')" target="_blank" color="successgreen white--text" rounded small>
                  {{ $t('SHARE.GO_PREMIUM') }}
                </v-btn>
              </template>
            </v-text-field>
          </div>
          <template v-if="isPremium && isProjectAdmin">
            <v-divider class="my-6"></v-divider>
            <div><b class="primary--text text-uppercase">{{ $t('GLOBAL.OPTIONS') }}</b></div>
            <div class="ml-6 mt-1">
              <v-btn :disabled="resetLinkDisabled" rounded outlined class="my-1" @click="resetLink">{{ $t('SHARE.RESET_LINK') }}</v-btn>
            </div>
          </template>
        </v-tab-item>
        <v-tab-item v-if="hasViews" value="vues">
          <views-manager :planning="planning"></views-manager>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <div class="modal-with-tabs-footer"></div>
  </section>
</template>

<style>
  .modal-with-tabs-header.share-window {
    background-image: url('../../img/share.jpg');
    background-position: top center;
  }
</style>

<script>
  import { mapActions } from 'vuex';
  import ManageProjectsProjectAcr from '@/components/Manage/ManageProjectsProjectAcr';
  import ViewsManager from '@/components/ViewsManager/ViewsManager';

  export default {
    components: {
      ManageProjectsProjectAcr,
      ViewsManager,
    },
    props: {
      planning: { type: Object, required: true },
    },
    data() {
      return {
        openedTabIndex: null,
        copyingToClipboard: { success: false, error: false },
        resetLinkDisabled: false,
      };
    },
    computed: {
      dragAndDropEnabled: {
        get() {
          return ! this.planning.config.lockDragAndDrop;
        },
        set(newVal) {
          this.startChangingConfig();
          this.planning.config.lockDragAndDrop = ! newVal;
          this.changingConfig();
        },
      },
      readonlyLink() {
        if (this.isPremium && this.planning.meta && this.planning.meta.read_only_token) {
          return `${window.location.protocol}//${window.location.host}${window.location.pathname}#/viewer/${this.planning.id}?rotoken=${this.planning.meta.read_only_token}`;
        }
        return '';
      },
      hasReadOnlyLink() { return ! this.$store.getters['users/accessRight/config'].hideExtenalLink; },
      hasViews() { return this.$store.getters['users/accessRight/config'].hasViews; },
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
      isProjectAdmin() { return this.planning.meta.access_right == 'admin'; },
    },
    methods: {
      copyToClipboard() {
        const input = document.getElementById('inputReadOnlyLink');
        this.copyingToClipboard = { success: false, error: false };
        try {
          input.select();
          document.execCommand('selectAll');
          document.execCommand('copy');
          this.copyingToClipboard.success = true;
          setTimeout(() => { this.copyingToClipboard.success = false; }, 3000);
        } catch (e) {
          this.copyingToClipboard.error = true;
        }
      },
      resetLink() {
        this.resetLinkDisabled = true;
        this.$store.dispatch('ui/msgbox/open', {
          title: "SHARE.RESET_LINK",
          body: "SHARE.RESET_LINK_CONFIRMATION",
          buttons: { ok: "SHARE.RESET_LINK", cancel: "GLOBAL.CANCEL" },
        }).then(() => window.apiSrv.call(`sharedplannings/planning/${this.planning.id}/resettoken`).then((response) => {
          if (response && response.data && response.data.read_only_token && this.planning.meta) this.planning.meta.read_only_token = response.data.read_only_token;
        })).catch(() => {}).finally(() => {
          this.resetLinkDisabled = false;
        });
      },
      close() {
        this.$emit('close');
      },
      ...mapActions('planning/config', ['startChangingConfig', 'changingConfig']),
    },
  };
</script>
