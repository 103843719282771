import 'core-js/stable';
import 'regenerator-runtime/runtime';

require('script-loader!jquery/dist/jquery.min.js');
require('script-loader!jquery-ui-dist/jquery-ui.min.js');

require('expose-loader?exposes=angular!@/js/plugins/angular-functions.js');

require('bootstrap/dist/js/bootstrap.min.js'); // required by summernote
require('script-loader!summernote');
require('summernote/dist/lang/summernote-fr-FR.min.js');
require('mousetrap/mousetrap.min.js');

require('expose-loader?exposes=moment!moment');
require('expose-loader?exposes=_!underscore');
require('expose-loader?exposes=Backbone!backbone/backbone-min.js'); // required by tourist
require('tourist/tourist.min.js');

require('jquery-ui-dist/jquery-ui.css');
require('@fortawesome/fontawesome-pro/css/all.css');
require('summernote/dist/summernote.css');

require('script-loader!@/js/commonfunctions.js');
require('@/js/momentconfig.js');

/* GLOBAL SERVICES */
require('@/js/services/ApiSrv.js');
require('@/components/Notifications/NotificationsSrv.js');

/* TEST / DEBUG SERVICES */
// if (window.location.hostname == 'localhost') require('@/js/tests/PerformanceSrv.js');

// Launch Vue App
require('./vueApp.js');

require('@/css/vue.css');
require('@/css/app.css');
require('@/css/animations.css');
require('@/client_customs/Inli/inli.css');
