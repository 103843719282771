<template>
  <div class="pa-6">
    <div><b class="primary--text text-uppercase">{{ $t('PROJECT_SHEET.FILES_DESCRIPTION') }}</b></div>
    <div v-for="el in filteredLinksElements" class="mt-2 ml-6">
      <div class="small pointer" style="font-style: italic;" @click="openElement(el)">
        {{ el.getTitle() || $t('PLANNING.NEW_BUBBLE') }}
      </div>
      <ul class="list-unstyled" style="margin-left: 16px; margin-top: 2px">
        <li v-for="link in el.getLinks()" class="text-ellipsis" style="margin-bottom: 2px">
          <link-icon :link="link"></link-icon>
          <span :style="{'vertical-align': link.icon ? 'middle' : ''}">&nbsp;<a :href="link.url" target="_blank">{{ link.name || link.url }}</a></span>
        </li>
      </ul>
    </div>
    <div v-if="! filteredLinksElements.length" class="mt-2 ml-6 text-muted">{{ $t('PROJECT_SHEET.NO_FILE') }}</div>
  </div>
</template>

<script>
  import LinkIcon from '@/components/Planning/partials/LinkIcon';

  export default {
    components: {
      LinkIcon,
    },
    props: {
      planning: { type: Object, required: true },
    },
    data() {
      return {
      };
    },
    computed: {
      filteredLinksElements() {
        return this.planning.elements.filter(el => ! el.isType('macro') && el.getLinks() && el.getLinks().length)
          .sort((a, b) => (a.getLaneId() < b.getLaneId() ? -1 : 1));
      },
    },
    methods: {
      openElement(el) {
        this.$emit('open-element', el);
      },
    },
  };
</script>
