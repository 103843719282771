import { getDefaultConfig } from './helpers/defaultValues';
import { getDefaultColors, getDefaultIcons } from './helpers/defaultConfigData';
import helpers from './helpers/planningInitHelpers';
import '@/css/planningdesigns.scss';

class PlanningConfig {
  constructor(srcData) {
    const data = angular.copy(srcData) || {};

    if (data instanceof PlanningConfig) {
      _.extend(this, data);
    } else {
      helpers.updateLastVersionsConfig(data);
      angular.merge(this, getDefaultConfig(), data);
    }

    this.setColors();
  }

  set(data) {
    Object.keys(data || {}).forEach((key) => {
      this[key] = data[key];
    });
  }

  getAll() {
    const defaultColors = getDefaultColors();
    const defaultIcons = getDefaultIcons();
    const nonNullData = {};
    Object.keys(this).forEach((key) => { if (this[key] !== null) nonNullData[key] = this[key]; });
    nonNullData.colors = this.colors.map((item, index) => {
      if (! defaultColors[index]) return angular.copy(item);
      const destData = {};
      if (item.label != defaultColors[index].label) destData.label = item.label;
      if (! angular.equals(item.sublabels, defaultColors[index].sublabels)) destData.sublabels = item.sublabels.slice();
      return _.isEmpty(destData) ? null : destData; // do not send empty {} to api as it may be saved as []
    });
    nonNullData.icons = {};
    Object.keys(this.icons || {}).forEach((key) => {
      const icon = this.icons[key];
      if (! defaultIcons[key] || icon.label != defaultIcons[key].label) nonNullData.icons[key] = { label: icon.label };
    });
    if (_.isEmpty(nonNullData.icons)) delete nonNullData.icons;
    return nonNullData;
  }

  setColors() {
    this.design = this.design || 'flat';

    const colors = getDefaultColors();
    colors.forEach((color, index) => {
      if (! this.colors || ! this.colors[index]) return;
      if (this.colors[index].label) color.label = this.colors[index].label;
      if (this.colors[index].sublabels && this.colors[index].sublabels.length == 5) color.sublabels = this.colors[index].sublabels;
    });
    this.colors = colors;

    const icons = getDefaultIcons();
    Object.keys(icons).forEach((key) => {
      const icon = icons[key];
      if (this.icons && this.icons[key] && this.icons[key].label) icon.label = this.icons[key].label;
    });
    this.icons = icons;
  }

  /** ***************** */
  /** *  CUSTOM CSS  ** */
  /** ***************** */
  setDesign(designName, designcolor, logo) {
    const design = designName || this.design || 'flat';
    if (design !== 'flat' && design !== 'classic') return;
    this.design = design;

    this.designcolor = (typeof designcolor !== 'undefined') ? designcolor : this.designcolor || { main: '#0169aa', second: '#777777' };

    this.logo = (typeof logo !== 'undefined') ? logo : this.logo || '';

    document.body.classList.remove('planningdesign-flat', 'planningdesign-classic');
    document.body.classList.add(`planningdesign-${this.design}`);

    $(".customdesigncolor").remove();
    $(document.querySelector('head')).append(`<style class="customdesigncolor">
      .designcolor-main-bg {
        background: ${this.designcolor.main};
        color: ${window.contrastedTextColor(this.designcolor.main)};
        border-color: ${window.contrastedTextColor(this.designcolor.main)};
      }
      .designcolor-main-color {
        color: ${this.designcolor.main};
      }
      .designcolor-second-bg {
        background: ${this.designcolor.second};
        color: ${window.contrastedTextColor(this.designcolor.second)};
        border-color: ${window.contrastedTextColor(this.designcolor.second)};
      }
      .designcolor-second-color {
        color: ${this.designcolor.second};
      }
    </style>`);

    this.setColors();
  }
}

export default PlanningConfig;
