<template>
  <v-container class="pa-6">
    <label for="inputSubproject" class="control-label primary--text text-uppercase">{{ $t('SUBPROJECTS.INCLUDE_SUB-PROJECT') }}</label>
    <div class="px-6">
      <div class="small text-muted py-1">{{ $t('SUBPROJECTS.DESCRIPTION') }}</div>
      <v-autocomplete id="inputSubproject" v-model="newsubproject" :label="$t('PROJECTS.SEARCH_PROJECT_TITLE')" :items="projects"
                      :disabled="! projects.length" :loading="! projects.length" item-text="title" item-value="id" return-object>
      </v-autocomplete>
    </div>
    <div :style="{ opacity: newsubproject ? null : .3, 'pointer-events': newsubproject ? null : 'none' }" class="mt-2">
      <b class="primary--text text-uppercase">{{ $t('GLOBAL.OPTIONS') }}</b>
      <div class="px-6 py-1 display-flex">
        <div class="text-center" style="width: 32px; flex: 0 0 auto !important; margin-top: 22px;">
          <v-icon small color="primary">fas fa-th-list</v-icon>
        </div>
        <div class="ml-2">
          <v-select v-model="selectedLanesList" :items="availableLanes" :label="$t('EXPORT.LANES')" :placeholder="selectedLanesList.length ? '' : $t('IMPORT_TEMPLATE.SELECT_LANES')"
                    :disabled="! templateContent" :loading="newsubproject && ! templateContent" item-value="id" item-text="label" multiple hide-details>
            <template #prepend-item>
              <filter-toggle :selected-ids="selectedLanesList" :sublist-ids="availableLanesIds"
                             @update="selectedLanesList = $event">
                {{ $t('EXPORT.ALL_LANES') }} ({{ availableLanesIds.length }})
              </filter-toggle>
              <v-divider></v-divider>
            </template>
            <template #selection="{ item, index }">
              <span v-if="selectedLanesList.length == 1">{{ item.label }}</span>
              <span v-else-if="selectedLanesList.length == availableLanes.length && index === 0">{{ $t('EXPORT.ALL_LANES') }} ({{ availableLanes.length }})</span>
              <span v-else-if="index === 0">{{ selectedLanesList.length }}/{{ availableLanes.length }}</span>
            </template>
          </v-select>
        </div>
      </div>
    </div>
    <v-divider class="mt-6 mb-3"></v-divider>
    <div class="text-center">
      <v-btn :disabled="! newsubproject" color="accent" rounded @click="addNewSubproject()">
        {{ $t('SUBPROJECTS.ADD') }}
      </v-btn>
    </div>
  </v-container>
</template>

<script>
  import ProjectSrv from '@/components/Projects/ProjectSrv';

  export default {
    data() {
      return {
        newsubproject: null,
        projects: [],
        templateContent: null,
        selectedLanesList: [],
        loadingSubproject: false,
      };
    },
    computed: {
      availableLanes() {
        if (! this.templateContent) return [];
        return this.templateContent.lanes.map(item => ({ id: item.id, label: item.label }));
      },
      availableLanesIds() {
        return this.availableLanes.map(item => item.id);
      },
    },
    watch: {
      newsubproject(newVal) {
        if (! newVal) return;
        this.templateContent = null;
        this.loadingSubproject = true;
        ProjectSrv.get(newVal.id).then((content) => {
          this.templateContent = content;
        }).finally(() => {
          this.loadingSubproject = false;
        });
      },
      availableLanes() {
        this.selectedLanesList = this.availableLanes.map(item => item.id);
      },
    },
    created() {
      ProjectSrv.list().then((data) => {
        this.projects = data.sort((a, b) => (a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1));
      });
    },
    methods: {
      close() {
        this.$emit('close');
      },
      addNewSubproject() {
        if (! this.templateContent) return;
        const projectHiddenSublanesId = this.availableLanes.filter(item => ! this.selectedLanesList.find(id => id == item.id)).map(lane => lane.id);
        this.$store.dispatch('planning/lanes/addLane').then((lane) => {
          lane.project_hidden_sublanes = projectHiddenSublanesId;
          lane.project_id = this.newsubproject.id;
          lane.label = this.newsubproject.title;
          lane.project_rotoken = this.newsubproject.read_only_token;
          this.$store.dispatch('planning/save');
        });
        this.close();
      },
    },
  };
</script>
