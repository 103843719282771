<template>
  <div>
    <div id="budget-states-container" class="pr-2 pb-1" style="max-height: 150px; max-width: 300px; overflow: auto">
      <div v-for="state in budgetStateKeys" :key="state" class="display-flex align-end hovervisible-activator">
        <v-text-field v-model="budgetStates[state]" hide-details class="mt-0 pt-0" @change="save"></v-text-field>
        <v-btn icon small outlined color="errorred" class="hovervisible-item ml-2" @click="removeBudgetState(state)">
          <v-icon small>far fa-trash-alt</v-icon>
        </v-btn>
      </div>
    </div>
    <form class="display-flex align-end" style="max-width: 300px" @submit.prevent="addBudgetState()">
      <v-text-field v-model="newBudgetState" :label="$t('MANAGE.CONFIG.NEW_BUDGET_STATE')" hide-details></v-text-field>
      <v-btn :disabled="! newBudgetState" color="accent" rounded class="ml-2" type="submit">{{ $t('GLOBAL.ADD') }}</v-btn>
    </form>
  </div>
</template>

<script>
  import defaultConfig from '@/js/defaultConfig';

  export default {
    data() {
      const defaultBudgetStates = defaultConfig.budgetStates();

      return {
        newBudgetState: "",
        defaultBudgetStates,
        budgetStates: { ...defaultBudgetStates },
      };
    },
    computed: {
      computedConfig() {
        return this.$store.getters['users/accessRight/config'];
      },
      budgetStateKeys() {
        return Object.keys(this.budgetStates || {});
      },
    },
    watch: {
      'computedConfig.budgetStates': {
        handler(newVal) {
          this.budgetStates = Object.keys(newVal || {}).length ? { ...newVal } : { ...this.defaultBudgetStates };
        },
        immediate: true,
      },
    },
    methods: {
      setParam(prop, val) {
        this.$store.dispatch('users/accessRight/updateBusinessConfig', { [prop]: val });
      },
      save() {
        this.setParam('budgetStates', this.budgetStates);
      },
      addBudgetState() {
        if (! this.newBudgetState || this.budgetStateKeys.some(key => this.budgetStates[key] == this.newBudgetState)) return;
        let key = `c_${window.slugify(this.newBudgetState)}`;
        if (this.budgetStates[key]) { // key already taken
          let i = 2;
          while (this.budgetStates[`${key}${i}`]) i++;
          key = `${key}${i}`;
        }
        this.budgetStates[key] = this.newBudgetState;
        this.save();
        this.newBudgetState = "";
        setTimeout(() => { document.getElementById('budget-states-container').scrollTop = 9999999; });
      },
      removeBudgetState(state) {
        this.$store.dispatch('ui/msgbox/open', {
          title: this.$t("MANAGE.CONFIG.DELETE_BUDGET_STATE", { state: this.budgetStates[state] }),
          body: "MANAGE.CONFIG.DELETE_BUDGET_STATE_BODY",
          buttons: { ok: "GLOBAL.OK", cancel: "GLOBAL.CANCEL" },
        }).then(() => {
          delete this.budgetStates[state];
          if (! Object.keys(this.budgetStates).length) this.budgetStates = null;
          this.save();
        }).catch(() => {});
      },
    },
  };
</script>
