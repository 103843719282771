<template>
  <div>
    <div class="display-flex align-center mb-2">
      <div class="mr-2 text-center" style="flex: 0 0 24px;">
        <v-icon color="primary" small>fas fa-check-square</v-icon>
      </div>
      <label for="checklist" class="uppercase primary--text ma-0">{{ $t('ELEMENT-DETAIL.CHECKLIST') }}</label>
      <v-spacer></v-spacer>
      <v-divider vertical class="mx-2"></v-divider>
      <div class="pointer"
           @click="isPremium ? advancedChecklist = ! advancedChecklist : openMsgBox({ title: 'PREMIUM.PREMIUM_FEATURE', body: 'PREMIUM.ADVANCED_PLANIFICATION_IS_PREMIUM' })">
        <i :class="advancedChecklist ? 'fa-toggle-on successgreen--text text--lighten-1' : 'fa-toggle-off black--text'" class="fa mr-1"></i>{{ $t('ELEMENT-DETAIL.ADVANCED_PLANIFICATION') }}
      </div>
      <v-divider vertical class="mx-2"></v-divider>
      <v-btn :title="$t(el.getConfig()['show-checklist'] ? 'ELEMENT-DETAIL.HIDE_ON_PLANNING' : 'ELEMENT-DETAIL.SHOW_ON_PLANNING')" icon small tabindex="-1"
             @click="el.getConfig()['show-checklist'] = ! el.getConfig()['show-checklist']">
        <v-icon small>{{ el.getConfig()['show-checklist'] ? 'fa-eye' : 'fa-eye-slash' }}</v-icon>
      </v-btn>
    </div>
    <div style="margin-left: 8px;">
      <draggable :list="checklist" :options="{ handle: '.checklist-draggable-anchor' }" @update="updateChecklist">
        <element-details-checklist-item v-for="(item, itemIndex) in checklist" :key="itemIndex" class="mb-1 pl-2"
                                        :el="el" :checklist="checklist" :item="item" :advanced-checklist="advancedChecklist"
                                        @update-checklist="updateChecklist" @remove-checklist-item="removeChecklistItem(itemIndex)"
                                        @add-responsible="addResponsible(item, $event)">
        </element-details-checklist-item>
      </draggable>
    </div>
    <form class="display-flex align-center ml-6 pl-2" @submit.prevent="addChecklistItem()">
      <v-checkbox :disabled="true" hide-details class="pa-0 ma-0"></v-checkbox>
      <v-text-field id="checklist" v-model="addChecklistItemTitle" :label="$t('ELEMENT-DETAIL.NEW_CHECKLIST_ITEM')" style="flex: 1 1 100%"></v-text-field>
      <v-btn :disabled="! addChecklistItemTitle" color="accent" small rounded type="submit">{{ $t('ELEMENT-DETAIL.ADD') }}</v-btn>
    </form>
  </div>
</template>

<script>
  import draggable from 'vuedraggable';
  import clientConfig from '@/client_customs/config';
  import { getDefaultChecklistItem } from '@/models/helpers/defaultElements';
  import ElementDetailsChecklistItem from './ElementDetailsChecklistItem';

  export default {
    components: {
      draggable,
      ElementDetailsChecklistItem,
    },
    props: {
      el: { type: Object, required: true },
    },
    data() {
      const advancedChecklistAlwaysOn = clientConfig.elementDetails?.advancedChecklist;

      return {
        checklist: this.el.getChecklist() || [],
        addChecklistItemTitle: "",
        advancedChecklist: advancedChecklistAlwaysOn || !! (this.el.getChecklist() || []).find(item => item.user_id || item.group_id || item.username || item.workload || item.weight),
      };
    },
    computed: {
      assignActionsToCreator() {
        return this.$store.getters['users/accessRight/config'].assignActionsToCreator;
      },
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
    },
    created() {
      this.$store.state.users.userPromise.then(() => {
        if (! this.isPremium) this.advancedChecklist = false;
      });
    },
    updated() {
      const elCheckList = this.el.getChecklist();
      if (elCheckList) this.checklist = elCheckList;
    },
    methods: {
      addChecklistItem() {
        if (! this.addChecklistItemTitle) return;
        const checklistItem = { ...getDefaultChecklistItem(), title: this.addChecklistItemTitle };
        if (this.assignActionsToCreator) {
          this.addResponsible(checklistItem, this.$store.state.users.user);
          this.advancedChecklist = true;
        }
        this.checklist.push(checklistItem);
        this.updateChecklist();
        this.addChecklistItemTitle = "";
      },
      removeChecklistItem(index) {
        if (index > -1 && index < this.checklist.length) {
          this.checklist.splice(index, 1);
          this.updateChecklist();
        }
      },
      addResponsible(item, user) {
        if (! user) return; // click on input clear button
        if (user.group_id) {
          item.user_id = null;
          this.$set(item, 'group_id', user.group_id);
          this.$set(item, 'username', user.title);
        } else if (user.id) {
          item.user_id = user.id;
          item.group_id = null;
          this.$set(item, 'username', this.$store.getters['users/getUsername'](user, 'short'));
        } else if (user.username) {
          item.user_id = null;
          item.group_id = null;
          this.$set(item, 'username', user.username);
        }
        this.$emit('add-user', user);
      },
      updateChecklist() {
        this.el.setChecklist(this.checklist);
        // this.$emit('update-progress');
      },
      openMsgBox(data) {
        this.$store.dispatch('ui/msgbox/open', data);
      },
    },
  };
</script>
