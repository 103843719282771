export default {
  namespaced: true,
  state: {
    openedModal: null,
    projectNavigationHistory: null, // [{ type, path, rotoken, title }]
  },
  mutations: {
    setOpenedModal(state, val) {
      state.openedModal = val;
    },
  },
  getters: {
    getProjectNavigationHistory(state) {
      if (state.projectNavigationHistory) return state.projectNavigationHistory;
      try {
        state.projectNavigationHistory = JSON.parse(window.localStorageWrapper.getItem('project_navigation_history')) || [];
      } catch (e) {
        state.projectNavigationHistory = [];
      }
      return state.projectNavigationHistory;
    },
  },
  actions: {
    registerProjectNavigation({ state, getters }, navigation) {
      const { path, rotoken, title } = navigation;
      const pathCheck = /^\/(planning|viewer|planningview)\//i.exec(path);
      if (! pathCheck) return;

      state.projectNavigationHistory = getters.getProjectNavigationHistory.filter(item => item.path != path).slice(0, 2);
      state.projectNavigationHistory.unshift({ type: pathCheck[1], path, rotoken, title });

      window.localStorageWrapper.setItem('project_navigation_history', JSON.stringify(state.projectNavigationHistory.filter(item => ! item.rotoken))); // do not save rotoken in localstorage
    },
  },
};
