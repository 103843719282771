<template>
  <v-card class="px-6 pb-6" style="overflow: hidden;">
    <v-tabs v-model="tabIndex" background-color="white" height="64" slider-color="primary">
      <v-tab v-for="status in availableProjectStatusKeys" :key="status" :href="`#${status}projects`" :class="status ? 'px-2' : 'px-4'"
             style="text-transform: none; font-size: 16px">
        <template v-if="! status">
          <v-icon size="20" class="mr-2" style="color: inherit; transition: none">fas fa-folder-open</v-icon>
          {{ $t("PROJECTS.PROJECTS") }}
        </template>
        <template v-else>{{ projectStatusConfig[status].label }}</template>
      </v-tab>
      <v-tab :disabled="isConsultOnly" href="#new" class="px-4" style="text-transform: none; font-size: 16px">
        <v-icon size="20" class="mr-2" style="color: inherit; transition: none">fas fa-plus-circle</v-icon>
        {{ $t("PROJECTS.START_A_NEW_PROJECT") }}
      </v-tab>
      <v-tab :disabled="isConsultOnly" href="#archived" class="px-4" style="text-transform: none; font-size: 16px">
        <v-icon size="20" class="mr-2" style="color: inherit; transition: none">fas fa-archive</v-icon>
        {{ $t("PROJECTS.ARCHIVED") }}
      </v-tab>

      <!-- TAB PROJECTS -->
      <v-tab-item v-for="status in availableProjectStatusKeys" :key="status" :value="`${status}projects`"
                  :transition="false" :reverse-transition="false" style="border-top: 1px solid #ccc">
        <home-projects-list :data-loaded="data_loaded" :errors="errors" :filters="filters"
                            :projects="projectsByStatus[status]" :views="status ? [] : views"
                            :status-info="status ? projectStatusConfig[status] : {}"
                            @archive-project="archiveProject($event)"
                            @duplicate-project="duplicateProject($event)">
        </home-projects-list>
      </v-tab-item>
      <!-- TAB NEW PROJECT -->
      <v-tab-item :transition="false" :reverse-transition="false" :disabled="isConsultOnly" value="new" style="border-top: 1px solid #ccc">
        <v-container fluid class="pa-6">
          <div v-if="! data_loaded.projects && ! errors.loadProjects" class="text-center"><i class="fas fa-spinner fa-spin fa-2x fa-fw"></i></div>
          <div v-if="! data_loaded.projects && errors.loadProjects">{{ errors.loadProjects }}</div>
          <div v-if="data_loaded.projects && isMaxProjectsReached" class="text-center">
            <div><i class="fa fa-exclamation-circle fa-4x warning"></i></div>
            <div class="mt-2">{{ $t('PROJECTS.YOU_HAVE_REACHED_YOUR_MAXIMUM_PROJECTS') }}<br>{{ $t('PROJECTS.DELETE_AN_EXISTING_PROJECT') }}</div>
            <div>
              <a :href="$t('PREMIUM.OFFERS_LINK')" target="_blank">
                <v-btn color="accent" rounded large class="mt-4">{{ $t('PROJECTS.LEARN_ABOUT_PREMIUM') }}</v-btn>
              </a>
            </div>
          </div>
          <home-projects-newproject v-if="data_loaded.projects && ! isMaxProjectsReached && ! isConsultOnly" :projects="projects"></home-projects-newproject>
        </v-container>
      </v-tab-item>
      <!-- TAB ARCHIVED -->
      <v-tab-item :transition="false" :reverse-transition="false" :disabled="isConsultOnly" value="archived" style="border-top: 1px solid #ccc">
        <home-projects-filters :filters="filters" :projects="archivedprojects"></home-projects-filters>
        <v-divider class="my-4"></v-divider>
        <div v-show="! data_loaded.archivedprojects && ! errors.loadArchivedProjects" class="text-center"><i class="fas fa-spinner fa-spin fa-2x fa-fw"></i></div>
        <div v-show="! data_loaded.archivedprojects && errors.loadArchivedProjects">{{ errors.loadArchivedProjects }}</div>
        <div v-if="data_loaded.archivedprojects && ! archivedprojects.length">{{ $t('PROJECTS.NO_ARCHIVED_PROJECT') }}</div>
        <div v-show="data_loaded.archivedprojects && archivedprojects.length && ! isConsultOnly">
          <v-container class="pa-0 mt-n2 mb-3" style="max-width: calc(100% - 36px)"> <!-- only way to add margin around pagination ? -->
            <v-pagination v-if="filteredArchivedProjects.length > archivedProjectsPerPage" v-model="archivedProjectsCurrentPage" :length="archivedProjectsPaginationLength" circle></v-pagination>
          </v-container>
          <!-- GRID -->
          <v-container v-if="filters.display == 'grid'" fluid class="pa-0" style="max-width: 1785px">
            <v-row>
              <transition-group name="list-fade" tag="div" class="display-flex" style="flex-wrap: wrap; width: 100%">
                <v-col v-for="archivedproject in displayedFilteredArchivedProjects" :key="archivedproject.id" xl="3" lg="4" sm="6" cols="12" class="projectbox list-fade-item">
                  <v-lazy v-if="vLazyAvailable" :options="{ rootMargin: '200px' }" style="height: 190px">
                    <home-projects-project :project="archivedproject" archived @delete="deleteArchived(archivedproject)" @restore="restoreArchived(archivedproject)"></home-projects-project>
                  </v-lazy>
                  <home-projects-project v-else :project="archivedproject" archived @delete="deleteArchived(archivedproject)" @restore="restoreArchived(archivedproject)"></home-projects-project>
                </v-col>
              </transition-group>
            </v-row>
          </v-container>
          <!-- LIST -->
          <div v-if="filters.display == 'list'" class="px-4">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead class="text-uppercase">
                  <tr>
                    <th>{{ $t('NEWPROJECT.PROJECT_NAME') }}</th>
                    <th>{{ $t('PROJECTS.ARCHIVED_ON') }}</th>
                    <th>{{ $t('GLOBAL.OWNER') }}</th>
                    <th>{{ $t('GLOBAL.CREATION_DATE') }}</th>
                    <th>{{ $t('GLOBAL.ACCESS_RIGHT') }}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="archivedproject in displayedFilteredArchivedProjects" :key="archivedproject.id">
                    <td>{{ archivedproject.title || $t('PLANNING.UNNAMED_PROJECT') }}</td>
                    <td>{{ archivedproject.deleted_at | moment('calendar') }}</td>
                    <td>{{ translateApiText(archivedproject.owner_name) }}</td>
                    <td>{{ archivedproject.date_of_creation | moment('datetime') }}</td>
                    <td>{{ $t(uppercase('ACR.'+archivedproject.access_right)) }}</td>
                    <td>
                      <template v-if="archivedproject.access_right == 'admin'">
                        <v-btn :title="$t('PROJECTS.RESTORE')" small icon class="my-0" @click="restoreArchived(archivedproject)">
                          <v-icon color="accent" small>fas fa-trash-restore</v-icon>
                        </v-btn>
                        <v-btn :title="$t('PROJECTS.DELETE')" small icon class="my-0" @click="deleteArchived(archivedproject)">
                          <v-icon color="errorred" small>fas fa-trash-alt</v-icon>
                        </v-btn>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
  import Planning from '@/models/Planning';
  import ProjectSrv from '@/components/Projects/ProjectSrv';
  import HomeProjectsList from './HomeProjectsList';
  import HomeProjectsProject from './HomeProjectsProject';
  import HomeProjectsNewproject from './HomeProjectsNewproject';
  import HomeProjectsFilters from './HomeProjectsFilters';
  import HomeProjectsMixin from './HomeProjectsMixin';

  export default {
    components: {
      HomeProjectsList,
      HomeProjectsProject,
      HomeProjectsNewproject,
      HomeProjectsFilters,
    },
    mixins: [HomeProjectsMixin],
    data() {
      const filters = {
        company: null, company_id: null, categories: [], title: "", user: null, display: 'grid', sort: '-date_of_modification',
      };
      try {
        _.extend(filters, JSON.parse(window.sessionStorageWrapper.getItem('option.projectsFilters')) || null);
      } catch (e) { /* continue with defaults */ }

      return {
        data_loaded: { projects: false, archivedprojects: false },
        errors: { loadProjects: null, loadArchivedProjects: null },
        projects: [],
        views: [],
        filters,
        archivedprojects: [],
        tabIndex: null,
        archivedProjectsPerPage: 50,
        archivedProjectsCurrentPage: 1,
      };
    },
    computed: {
      projectStatusConfig() {
        return this.$store.getters['users/accessRight/config'].projectStatus || {};
      },
      projectStatusKeysSet() {
        const projectStatusKeys = [...Object.keys(this.projectStatusConfig), ''];
        return new Set(projectStatusKeys);
      },
      projectsByStatus() {
        const projectsByStatus = {};
        projectsByStatus[''] = []; // always displays tab projects

        this.projects.forEach((project) => {
          const status = this.projectStatusKeysSet.has(project.status) ? project.status : '';
          if (! projectsByStatus[status]) projectsByStatus[status] = [];
          projectsByStatus[status].push(project);
        });
        return projectsByStatus;
      },
      availableProjectStatusKeys() {
        const orderedStatus = ['', ...this.projectStatusKeysSet];
        return Object.keys(this.projectsByStatus).sort((a, b) => orderedStatus.indexOf(a) - orderedStatus.indexOf(b));
      },
      filteredArchivedProjects() {
        const filtered = this.archivedprojects.filter(project => this.filterProjectByCompany(project) && this.filterProjectByCategory(project)
          && this.filterProjectByTitle(project) && this.filterProjectByUser(project));
        if (this.filters.sort) {
          let { sort } = this.filters;
          let reverse = false;
          if (sort.startsWith('-')) {
            sort = sort.slice(1);
            reverse = true;
          }
          if (sort == 'date_of_modification') sort = 'deleted_at';
          if (sort == 'title') {
            filtered.sort((a, b) => (a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1));
          } else {
            filtered.sort((a, b) => (a[sort] < b[sort] ? -1 : 1));
          }
          if (reverse) filtered.reverse();
        }
        return filtered;
      },
      displayedFilteredArchivedProjects() {
        return this.filteredArchivedProjects.slice(this.archivedProjectsPerPage * (this.archivedProjectsCurrentPage - 1), this.archivedProjectsPerPage * this.archivedProjectsCurrentPage);
      },
      isMaxProjectsReached() {
        if (! this.data_loaded.projects) return true;
        const numberprojects = this.projects.length;
        const maxprojects = this.$store.state.users.accessRight.maxprojects || 0;
        if (numberprojects < maxprojects) return false;
        return true;
      },
      archivedProjectsPaginationLength() {
        return Math.ceil(this.filteredArchivedProjects.length / this.archivedProjectsPerPage);
      },
      vLazyAvailable() {
        return 'IntersectionObserver' in window;
      },
      isConsultOnly() { return this.$store.state.users.accessRight.isConsultOnly; },
    },
    watch: {
      filters: {
        handler() {
          if (this.filters) window.sessionStorageWrapper.setItem('option.projectsFilters', JSON.stringify(this.filters));
        },
        deep: true,
      },
      archivedProjectsPaginationLength() {
        if (this.archivedProjectsCurrentPage > this.archivedProjectsPaginationLength) this.archivedProjectsCurrentPage = 1;
      },
    },
    created() {
      this.$store.getters['users/getOrganizationPromise'].then((organization) => {
        if (organization) {
          const mylevel = (organization.getCompanies().find(item => item.id == this.$store.state.users.user.company_id) || {}).level;
          const maxlevel = Math.max(...organization.getCompanies().map(item => item.level), 0);
          if (mylevel < maxlevel) {
            if (! this.filters.company) this.filters.company = 'mine';
            return;
          }
        }
        this.filters.company = null;
      });
      this.loadProjects();
      this.loadArchivedProjects();

      this.$store.state.users.userPromise.then(() => {
        if (this.$store.getters['users/accessRight/config'].hasViews) this.loadViews();
      });
    },
    methods: {
      projectListLoaded() {
        if (! this.projects.length) {
          if (! this.data_loaded.archivedprojects) { setTimeout(this.projectListLoaded, 300); return; }
        }
        this.data_loaded.projects = true;

        this.$store.state.users.userPromise.then(() => {
          if (this.isConsultOnly) { // force access to consult
            this.projects.forEach((project) => {
              project.access_right = 'consult';
            });
          }
        });
      },
      loadProjects() {
        this.data_loaded.projects = false;
        ProjectSrv.list().then((results) => {
          this.projects = results;
          this.projectListLoaded();
        }).catch((message) => {
          this.errors.loadProjects = this.$t('GLOBAL.ERROR') + (message ? ` : ${message}` : '');
        });
      },
      loadArchivedProjects() {
        this.data_loaded.archivedprojects = false;
        ProjectSrv.listArchived().then((results) => {
          this.archivedprojects = results;
          this.data_loaded.archivedprojects = true;
        }).catch((message) => {
          this.errors.loadArchivedProjects = this.$t('GLOBAL.ERROR') + (message ? ` : ${message}` : '');
        });
      },
      loadViews() {
        window.apiSrv.call('views', 'index').then((response) => {
          this.views = response && response.data && response.data.views;
        });
      },
      archiveProject(project) {
        ProjectSrv.remove(project.id).then(() => {
          const index = (this.projects || []).findIndex(item => item.id === project.id);
          if (index >= 0) this.projects.splice(index, 1);
          setTimeout(this.loadArchivedProjects, 300);
        }).catch((message) => {
          this.$store.dispatch('ui/msgbox/open', { title: "GLOBAL.ERROR", body: `${message}` });
        });
      },
      restoreArchived(project) {
        if (this.isMaxProjectsReached) {
          this.$store.dispatch('ui/msgbox/open', { title: "PROJECTS.YOU_HAVE_REACHED_YOUR_MAXIMUM_PROJECTS", body: "PROJECTS.DELETE_AN_EXISTING_PROJECT" });
          return;
        }

        ProjectSrv.restoreArchived(project.id).then(() => {
          const index = (this.archivedprojects || []).findIndex(item => item.id === project.id);
          if (index >= 0) this.archivedprojects.splice(index, 1);
          setTimeout(this.loadProjects, 300);
        }).catch((message) => {
          this.$store.dispatch('ui/msgbox/open', { title: "GLOBAL.ERROR", body: `${message}` });
        });
      },
      deleteArchived(project) {
        this.$store.dispatch('ui/msgbox/open', {
          title: this.$t("PROJECTS.CONFIRM_DELETE", { project_name: project.title }),
          body: "PROJECTS.CONFIRM_DELETE_BODY",
          buttons: { ok: "GLOBAL.OK", cancel: "GLOBAL.CANCEL" },
        }).then(() => {
          ProjectSrv.removeArchived(project.id).then(() => {
            const index = (this.archivedprojects || []).findIndex(item => item.id === project.id);
            if (index >= 0) this.archivedprojects.splice(index, 1);
          }).catch((message) => {
            this.$store.dispatch('ui/msgbox/open', { title: "GLOBAL.ERROR", body: `${message}` });
          });
        }).catch(() => {});
      },
      duplicateProject(project) {
        if (this.isMaxProjectsReached) {
          this.$store.dispatch('ui/msgbox/open', { title: "PROJECTS.YOU_HAVE_REACHED_YOUR_MAXIMUM_PROJECTS", body: "PROJECTS.DELETE_AN_EXISTING_PROJECT" });
          return;
        }
        this.data_loaded.projects = false;
        ProjectSrv.get(project.id)
          .then((content) => {
            const newPlanning = new Planning(content);
            const data = {
              title: `${newPlanning.getTitle() || this.$t('PLANNING.UNNAMED_PROJECT')} ${this.$t("PROJECTS.COPY")}`,
              content: newPlanning.toProject().content,
              category: newPlanning.meta && newPlanning.meta.category,
              status: newPlanning.meta && newPlanning.meta.status,
            };
            return ProjectSrv.createNew(data);
          })
          .then((response) => {
            // copy access rights to new project
            const newid = response.data && response.data.planning && response.data.planning.id;
            return window.apiSrv.call(`sharedplannings/planning/${newid}/copy/${project.id}`, 'update');
          })
          .catch((message) => {
            this.$store.dispatch('ui/msgbox/open', { title: "GLOBAL.ERROR", body: `${message}` });
          })
          .finally(() => {
            setTimeout(this.loadProjects);
          });
      },
      uppercase(text) { return text.toUpperCase(); },
    },
  };
</script>
