import { mapActions } from 'vuex';

export default {
  computed: {
    macroElements() {
      const subels = this.macroSubTasksSheets(this.el, new Set());
      return subels;
    },
    macroStartTime() {
      if (! this.elSubTasks) return null; // el is not a macro
      if (! this.elSubTasks.length) return this.el.getStartTime(); // el is a macro but has no subtasks
      const subTasksStartTime = this.macroElements.map(subTask => subTask.getStartTime());
      const minSubTasksStartTime = moment.min(subTasksStartTime);
      return minSubTasksStartTime;
    },
    macroEndTime() {
      if (! this.elSubTasks) return null; // el is not a macro
      if (! this.elSubTasks.length) return this.el.getEndTime();
      const subTasksEndTime = this.macroElements.map(subTask => subTask.getEndTime());
      const maxSubTasksEndTime = moment.max(subTasksEndTime);
      return maxSubTasksEndTime;
    },
    macroProgress() {
      if (! this.elSubTasks) return null; // el is not a macro
      if (! this.elSubTasks.length) return 0; // default value
      const subTasksProgress = this.macroElements.map(subTask => subTask.getProgress());
      const averageSubTasksProgress = Math.round(subTasksProgress.reduce((a, b) => a + b, 0) / subTasksProgress.length);
      return averageSubTasksProgress;
    },
    macroUsers() {
      if (! this.elSubTasks) return null; // el is not a macro
      if (! this.elSubTasks.length) return null;
      const subTasksUsers = this.macroElements.map(subTask => subTask.getUsers());
      const subTasksAllUsers = [].concat(...subTasksUsers.filter(users => users && users.length));
      // Users
      const users = subTasksAllUsers.filter(user => user.id);
      const uniqueUsers = {};
      const usersOccurence = {};
      users.forEach((user) => {
        if (! uniqueUsers[user.id]) {
          uniqueUsers[user.id] = angular.copy(user);
          usersOccurence[user.id] = 0;
        }
        uniqueUsers[user.id].percent = Math.round((uniqueUsers[user.id].percent * usersOccurence[user.id] + user.percent) / (usersOccurence[user.id] + 1));
        usersOccurence[user.id] += 1;
      });
      // GroupUsers
      const groupUsers = subTasksAllUsers.filter(user => user.group_id);
      const uniqueGroupUsers = {};
      const groupUsersOccurence = {};
      groupUsers.forEach((user) => {
        if (! uniqueGroupUsers[user.group_id]) {
          uniqueGroupUsers[user.group_id] = angular.copy(user);
          groupUsersOccurence[user.group_id] = 0;
        }
        uniqueGroupUsers[user.group_id].percent = Math.round((uniqueGroupUsers[user.group_id].percent * groupUsersOccurence[user.group_id] + user.percent) / (groupUsersOccurence[user.group_id] + 1));
        groupUsersOccurence[user.group_id] += 1;
      });
      // FreeUsers
      const freeUsers = subTasksAllUsers.filter(user => user.username);
      const uniqueFreeUsers = {};
      const freeUsersOccurence = {};
      freeUsers.forEach((user) => {
        if (! uniqueFreeUsers[user.username]) {
          uniqueFreeUsers[user.username] = angular.copy(user);
          freeUsersOccurence[user.username] = 0;
        }
        uniqueFreeUsers[user.username].percent = Math.round((uniqueFreeUsers[user.username].percent * freeUsersOccurence[user.username] + user.percent) / (freeUsersOccurence[user.username] + 1));
        freeUsersOccurence[user.username] += 1;
      });

      const uniqueUsersVals = Object.values(uniqueUsers);
      const uniqueGroupUsersVals = Object.values(uniqueGroupUsers);
      const uniqueFreeUsersVals = Object.values(uniqueFreeUsers);
      const allUniqueUsers = uniqueUsersVals.concat(uniqueGroupUsersVals).concat(uniqueFreeUsersVals);
      return allUniqueUsers;
    },
    macroLinks() {
      if (! this.elSubTasks) return null; // el is not a macro
      if (! this.elSubTasks.length) return null;
      const subTasksLinks = this.macroElements.map(subTask => subTask.getLinks());
      const subTasksAllLinks = [].concat(...subTasksLinks.filter(links => links && links.length));
      const linksName = subTasksAllLinks.map(link => link.name);
      const uniqueLinks = subTasksAllLinks.filter(({ name }, index) => ! linksName.includes(name, index + 1));
      return uniqueLinks;
    },
    macroBudgets() {
      if (! this.elSubTasks) return null; // el is not a macro
      if (! this.elSubTasks.length) return null;
      const subTasksBudgets = this.macroElements.map(subTask => subTask.getBudgets());
      const subTasksAllBudgets = [].concat(...subTasksBudgets.filter(budgets => budgets && budgets.length));
      return subTasksAllBudgets;
    },
  },
  watch: {
    macroStartTime: {
      handler() {
        if (! this.el.isType('macro')) return;
        this.el.setStartTime(this.macroStartTime);
        this.el.update();
      },
    },
    macroEndTime: {
      handler() {
        if (! this.el.isType('macro')) return;
        this.el.setEndTime(this.macroEndTime);
        this.el.update();
      },
    },
    macroProgress: {
      handler() {
        if (! this.el.isType('macro')) return;
        this.el.setProgress(this.macroProgress);
        this.el.update();
        this.save();
      },
      immediate: true,
    },
    macroUsers: {
      handler() {
        if (! this.el.isType('macro')) return;
        this.el.setUsers(this.macroUsers);
        this.el.update();
        this.save();
      },
      immediate: true,
    },
    macroLinks: {
      handler() {
        if (! this.el.isType('macro')) return;
        this.el.setLinks(this.macroLinks);
        this.el.update();
        this.save();
      },
      immediate: true,
    },
    macroBudgets: {
      handler() {
        if (! this.el.isType('macro')) return;
        this.el.setBudgets(this.macroBudgets);
        this.el.update();
        this.save();
      },
      immediate: true,
    },
  },
  methods: {
    macroSubTasksSheets(el, macroSubEls) {
      if (macroSubEls.has(el.id)) return null; // browse node once
      macroSubEls.add(el.id);

      const subEls = [];
      const subTasksId = new Set(el.getSubTasks());
      const subTasks = this.subTasksData(subTasksId);
      subTasks.forEach((subTask) => {
        if (macroSubEls.has(subTask.id)) return null; // browse node once

        if (! subTask.getSubTasks()) {
          macroSubEls.add(subTask.id);
          subEls.push(subTask);
        } else {
          subEls.push(...this.macroSubTasksSheets(subTask, macroSubEls));
        }
      });
      return subEls;
    },
    subTasksData(subTasksId) {
      return this.planning.elements.filter(item => subTasksId.has(parseInt(item.id, 10)));
    },
    ...mapActions('planning', ['save']),
  },
};
