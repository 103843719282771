<template>
  <div class="action-item text-left hovervisible-activator">
    <div :class="isDraggable ? 'hovervisible-item' : 'invisible'" class="draggable-anchor grab" style="height: 30px;"></div>
    <i :class="action.getChecked() ? 'fa-check-square' : 'fa-square'" class="far fa-2x fa-fw pointer" style="font-size: 14px"
       @click="checkAction">
    </i>
    <div class="pointer px-2" style="flex-shrink: 2; width: 40%" @click.stop="startEditTitle(action.id)">
      <span v-if="editing.title != action.id" :title="laneLabelTooltip" :class="action.getCssClass()" class="action-item-text">{{ actionTitle || '&nbsp;' }}</span>
      <div v-else class="display-flex align-center">
        <v-text-field v-model="actionTitle" hide-details autofocus class="pa-0 ma-0 no-inner-input-padding" style="min-width: 140px" @keyup.enter="endEditTitle">
        </v-text-field>
        <i class="fas fa-check primary--text pointer" style="padding: 2px" @click.stop="endEditTitle"></i>
      </div>
      <div v-if="showActionSubtitle" class="text-muted small mb-1" @click.stop>
        <a :href="actionElement && actionElement.getPlanning() ? `${actionElement.getPlanning().getUrl()}?el=${parseInt(actionElement.id, 10)}` : ''" target="_blank" style="color: inherit">
          <i>{{ actionElement ? `${actionElement.getTitle()} (${actionElement.getEndTime().locale('fr').format('D MMM')})` : '' }}</i>
        </a>
      </div>
    </div>
    <div class="text-center hide-on-drag" style="width: 120px">
      <date-field v-model="actionDueDate" hide-details :placeholder="actionEndTime" input-class="pa-0 ma-0 no-inner-input-padding"></date-field>
    </div>
    <div class="col-xs-3 display-flex align-center justify-center pointer text-right px-2 hide-on-drag"
         @click.stop="startEditWorkload(action.id)">
      <i v-if="editing.workload != action.id" :title="workloadTitle" class="fas fa-weight-hanging"></i>
      <span class="ml-1"></span>
      <span v-if="editing.workload != action.id" :title="workloadTitle">
        {{ actionWorkload || 0 | number('0.[00]') }}&nbsp; <i class="fas fa-caret-right"></i> &nbsp;{{ actionWorkloadUsed || 0 | number('0.[00]') }}
      </span>
      <template v-else>
        <v-text-field v-model.number="actionWorkload" hide-details type="number" step="any" min="0"
                      class="pa-0 ma-0 no-inner-input-padding" style="padding: 2px; min-width: 40px" @keyup.enter="endEditWorkload">
        </v-text-field>
        &nbsp;<i class="fas fa-caret-right"></i>&nbsp;
        <v-text-field v-model.number="actionWorkloadUsed" hide-details autofocus type="number" step="any" min="0"
                      class="pa-0 ma-0 no-inner-input-padding" style="padding: 2px; min-width: 40px" @keyup.enter="endEditWorkload">
        </v-text-field>
        <i class="fas fa-check primary--text pointer" style="padding: 2px" @click.stop="endEditWorkload()"></i>
      </template>
    </div>
    <v-btn v-show="editing.workload != action.id" icon small class="hovervisible-item hide-on-drag" style="position: absolute; right: -8px" @click.stop="$emit('open-action')">
      <v-icon small style="width: auto">fas fa-edit</v-icon>
    </v-btn>
  </div>
</template>

<style>
  #actionsdashboard .sortable-drag.action-item, #actionsdashboard .sortable-ghost.action-item {
    align-items:flex-start;
    justify-content: flex-start;
  }
  .sortable-drag .hide-on-drag, .sortable-ghost .hide-on-drag {
    display: none;
  }

  #actionsdashboard .action-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    line-height: 1.2;
  }
</style>

<script>
  import clientConfig from '@/client_customs/config';
  import workloadMixin from '@/components/Workload/workloadMixin';

  export default {
    mixins: [workloadMixin],
    props: {
      action: { type: Object, required: true },
      editing: { type: Object, default: () => ({}) },
      isDraggable: { type: Boolean, default: false },
    },
    data() {
      return {
        showActionSubtitle: clientConfig.dashboards && clientConfig.dashboards.actionsDashboard && clientConfig.dashboards.actionsDashboard.showActionSubtitle || false,
      };
    },
    computed: {
      workloadTitle() {
        const { workloadInHours } = this.$store.getters['users/accessRight/config'];
        return workloadInHours ? this.$t('ELEMENT-DETAIL.WORKLOAD_HOURS_INFORMATION') : this.$t('ELEMENT-DETAIL.WORKLOAD_INFORMATION');
      },
      actionTitle: {
        get() { return this.action.getTitle(); },
        set(newVal) { this.action.setTitle(newVal); },
      },
      actionWorkload: {
        get() {
          return this.workloadToDisplay(this.action.getWorkload());
        },
        set(newVal) {
          this.action.setWorkload(this.workloadToStore(newVal));
        },
      },
      actionWorkloadUsed: {
        get() {
          return this.workloadToDisplay(this.action.getWorkloadUsed());
        },
        set(newVal) {
          this.action.setWorkloadUsed(this.workloadToStore(newVal));
        },
      },
      locale() {
        return this.$store.state.lang.lang;
      },
      actionEndTime() {
        if (! this.action.getEndTime()) return null; // off-project actions
        return this.action.getEndTime().format(this.locale == 'en' ? 'YYYY-MM-DD' : 'DD/MM/YYYY'); // cf DateField format
      },
      actionDueDate: {
        get() { return this.action.getDuedate(); },
        set(newVal) {
          if (! newVal) return;
          this.action.setDuedate(newVal.format());
          this.saveAction();
        },
      },
      actionElement() {
        return this.action.getElement();
      },
      laneLabelTooltip() {
        if (! this.actionElement) return null;
        const project = this.actionElement.getPlanning();
        if (! project) return null;
        const lane = (project.lanes || []).find(item => item.id == this.actionElement.getLaneId());
        if (lane && lane.label) return `↪ ${lane.label}\n${this.actionElement.getTitle() || ''}`;
        return null;
      },
    },
    watch: {
      'editing.title': function (newId, oldId) {
        if (oldId == this.action.id) this.action.reset();
      },
      'editing.workload': function (newId, oldId) {
        if (oldId == this.action.id) this.action.reset();
      },
    },
    methods: {
      startEditWorkload(id) {
        this.$emit('set-editing', { prop: 'workload', id });
      },
      endEditWorkload() {
        this.$emit('set-editing', { prop: 'workload', id: null });
        this.saveAction();
      },
      startEditTitle(id) {
        this.$emit('set-editing', { prop: 'title', id });
      },
      endEditTitle() {
        this.$emit('set-editing', { prop: 'title', id: null });
        this.saveAction();
      },
      checkAction() {
        this.action.setChecked(! this.action.getChecked());
        this.saveAction();
      },
      saveAction() {
        this.$store.commit('ui/saving/start');
        this.action.save().then(() => {
          this.$store.commit('ui/saving/success');
        }).catch((message) => {
          this.$store.commit('ui/saving/error', message);
        });
      },
    },
  };
</script>
