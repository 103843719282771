<template>
  <div>
    <div v-show="! dataLoaded.projects && ! dataLoaded.projects_error" class="text-center"><i class="fas fa-spinner fa-spin fa-2x fa-fw"></i></div>
    <div v-if="! dataLoaded.projects && dataLoaded.projects_error">{{ dataLoaded.projects_error }}</div>
    <div v-show="dataLoaded.projects && ! projects.length">{{ $t('MANAGE.NO_PROJECT') }}</div>
    <div v-show="dataLoaded.projects && projects.length">
      <div class="table-responsive">
        <table class="table table-striped">
          <thead style="text-transform: uppercase">
            <tr>
              <th>{{ $t('MANAGE.PROJECT_NAME') }}</th>
              <th>{{ $t('MANAGE.OWNER') }}</th>
              <th>{{ $t('MANAGE.CREATION_DATE') }}</th>
              <th>{{ $t('MANAGE.LAST_MODIFICATION') }}</th>
              <th>{{ $t('MANAGE.DEFAULT_PRIVACY') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="project in sortedProjects" :key="project.id" class="pointer" @click="openProject(project)">
              <td>{{ project.title || $t('PLANNING.UNNAMED_PROJECT') }}</td>
              <td>{{ translateApiText(project.owner_name) }}</td>
              <td>{{ project.date_of_creation | moment('datetime') }}</td>
              <td>{{ project.date_of_modification | moment('calendar') }} {{ $t('GLOBAL.BY') }} {{ translateApiText(project.last_modification_user_name) }}</td>
              <td>{{ privacyTexts[project.privacy] | ucfirst }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      dataLoaded: { type: Object, default: () => {} },
      projects: { type: Array, default: () => [] },
    },
    data() {
      return {
        privacyTexts: {
          company: this.$t('ACR.COMPANY'),
          'company visible': this.$t('ACR.COMPANY_VISIBLE'),
          private: this.$t('ACR.PRIVATE'),
        },
      };
    },
    computed: {
      sortedProjects() {
        return _.sortBy(this.projects, 'date_of_modification').reverse();
      },
    },
    methods: {
      openProject(project) {
        this.$emit('open-project', project);
      },
      translateApiText(text) {
        return text && text.substr(0, 4) == "API." ? this.$t(text) : text;
      },
    },
  };
</script>
