/* ********************************** */
/* Plannings to be used in dashboards */
/* data can be altered from original  */
/* do not use in planning page        */
/* ********************************** */

import Vue from 'vue';
import store from '@/store';
import Planning from '@/models/Planning';
import ProjectSrv from '@/components/Projects/ProjectSrv';

export default {
  namespaced: true,
  modules: {},
  state: {
    byId: {},
    loadingCounter: { toLoad: 0, loaded: 0, loadingDate: null, allPlanningsList: [] },
  },
  mutations: {
    add(state, { planningId, projectContent }) {
      // status readonly (remove if handled by api in the future)
      const projectStatusConfig = store.getters['users/accessRight/config'].projectStatus || {};
      const status = projectContent && projectContent.meta && projectContent.meta.status || '';
      if (projectStatusConfig[status] && projectStatusConfig[status].readonly) projectContent.meta.access_right = 'consult';

      // exclude macro elements
      projectContent.elements = projectContent.elements.filter(el => el.type != 'macro');

      if (state.byId[planningId]) { // keep ref for multiprojects state.projects = plannings
        const planning = state.byId[planningId];
        planning.elements.forEach((el) => {
          el.dashboard_id = el.id;
          el.id = el.o_id;
        });
        planning.update(projectContent);
        planning.elements.forEach((el) => {
          if (el.dashboard_id) {
            el.id = el.dashboard_id;
          } else { // new el
            el.o_id = el.id;
            el.id = `${el.id}.${window.uuid()}`;
            el.project_id = planning.id;
          }
          delete el.dashboard_id;
        });
      } else {
        const planning = new Planning(projectContent);
        planning.elements.forEach((el) => {
          el.o_id = el.id; // save original id -> should replace the parseInt
          el.id = `${el.id}.${window.uuid()}`;
          el.project_id = planning.id;
        });
        Vue.set(state.byId, planningId, planning);
      }
    },
    addElementToPlanning(state, { planningId, el }) {
      const planning = state.byId[planningId];
      if (! planning) return;
      el.o_id = el.id;
      el.id = `${el.id}.${window.uuid()}`;
      el.project_id = planning.id;
      planning.elements.push(el);
    },
  },
  actions: {
    // load is now only used in MonitoringPlan
    load({ state, commit }, { planningId, rotoken }) {
      return ProjectSrv.get(planningId, rotoken).then((projectContent) => {
        delete state.byId[planningId]; // regenerate fresh new Planning to avoid potential bugs
        commit('add', { planningId, projectContent });
        return state.byId[planningId];
      });
    },
    loadAllPlannings({ state, commit }) {
      state.loadingCounter.toLoad = 0;
      state.loadingCounter.loaded = 0;
      const projectStatusConfig = store.getters['users/accessRight/config'].projectStatus || {};
      return ProjectSrv.getAll({ modifiedSince: state.loadingCounter.loadingDate, loadingCounter: state.loadingCounter }).then((projectContents) => {
        projectContents.forEach((projectContent) => {
          const planningId = projectContent.id;
          delete state.byId[planningId]; // regenerate fresh new Planning to avoid potential bugs
          if (projectStatusConfig[projectContent.meta.status]?.nodashboards) return;
          commit('add', { planningId, projectContent });
        });
        const dashboardPlannings = [];
        state.loadingCounter.allPlanningsList.forEach((planningId) => {
          const planning = state.byId[planningId];
          if (! planning || projectStatusConfig[planning.meta.status]?.nodashboards) return;
          dashboardPlannings.push(planning);
        });
        return dashboardPlannings; // [all plannings with status != nodashboards, macro elements excluded]
      });
    },
    loadPlanningsQuery({ state, commit }, params) {
      return ProjectSrv.getPlanningsQuery(params).then((projectContents) => {
        const projectStatusConfig = store.getters['users/accessRight/config'].projectStatus || {};
        const queryDashboardPlannings = [];
        projectContents.forEach((projectContent) => {
          if (projectStatusConfig[projectContent.meta.status]?.nodashboards) return;
          const planningId = projectContent.id;
          commit('add', { planningId, projectContent });
          queryDashboardPlannings.push(state.byId[planningId]);
        });
        return queryDashboardPlannings; // [query plannings with status != nodashboards, macro elements excluded]
      });
    },
  },
};
