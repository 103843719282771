<template>
  <v-toolbar flat class="my-4">
    <template v-if="filters.company">
      <v-btn-toggle v-model="filters.company" mandatory>
        <v-btn :title="$t('PROJECTS.FILTER_MY_COMPANY')" text value="mine" height="36">
          {{ $t('PROJECTS.MY_COMPANY') }}
        </v-btn>
        <v-btn :title="$t('PROJECTS.FILTER_ORGANIZATION')" text value="all" height="36">
          {{ $t('PROJECTS.ORGANIZATION') }}
        </v-btn>
      </v-btn-toggle>
      <v-select v-if="filters.company == 'all'" v-model="filters.company_id" :items="organizationCompanies"
                item-text="name" item-value="id" hide-details class="ml-2 pt-0">
      </v-select>
      <v-divider vertical class="mx-2"></v-divider>
    </template>
    <v-btn-toggle v-model="filters.display" mandatory>
      <v-btn :title="$t('PROJECTS.VIEW_GRID')" text value="grid" height="36">
        <v-icon class="mx-1" small>fas fa-th</v-icon>
      </v-btn>
      <v-btn :title="$t('PROJECTS.VIEW_TABLE')" text value="list" height="36">
        <v-icon class="mx-1" small>fas fa-list-ul</v-icon>
      </v-btn>
    </v-btn-toggle>
    <div class="mx-2"></div>
    <v-btn-toggle v-model="filters.sort" mandatory>
      <v-btn :title="$t('PROJECTS.SORT_BY_MODIFICATION')" text value="-date_of_modification" height="36">
        <v-icon class="mx-1" small>far fa-clock</v-icon>
      </v-btn>
      <v-btn :title="$t('PROJECTS.SORT_BY_TITLE_ASC')" text value="title" height="36">
        <v-icon class="mx-1" small>fas fa-sort-alpha-down</v-icon>
      </v-btn>
      <v-btn :title="$t('PROJECTS.SORT_BY_TITLE_DESC')" text value="-title" height="36">
        <v-icon class="mx-1" small>fas fa-sort-alpha-up</v-icon>
      </v-btn>
    </v-btn-toggle>
    <v-divider vertical class="mx-2 mx-lg-4"></v-divider>
    <template v-if="projectCategories.length">
      <v-autocomplete v-model="filters.categories" :items="projectCategories" :label="$tc('PROJECTS.CATEGORY', 2)" prepend-icon="fas fa-tags"
                      multiple hide-details clearable menu-props="offset-y" style="max-width: 200px">
        <template #selection="{ item, index }">
          <project-category v-if="filters.categories.length == 1" :category="item" class="pr-2" style="max-width: 100%; margin: -2px"></project-category>
          <span v-else-if="index === 0" class="pr-2">{{ filters.categories.length }}/{{ projectCategories.length }}</span>
        </template>
        <template #item="{ item }">
          <project-category :category="item" pointer></project-category>
        </template>
      </v-autocomplete>
      <v-divider vertical class="mx-2 mx-lg-4"></v-divider>
    </template>
    <v-combobox :search-input.sync="filters.title" :items="titleFilterProjects"
                :label="$t('PROJECTS.SEARCH_PROJECT_TITLE')"
                prepend-icon="fas fa-search" hide-details clearable
                item-text="title" item-value="title">
    </v-combobox>
    <v-divider vertical class="mx-2 mx-lg-4"></v-divider>
    <select-user v-model="filters.user" :items="projectOwners"
                 :label="$t('PROJECTS.SEARCH_PROJECT_OWNER')"
                 prepend-icon="fas fa-user" hide-details>
    </select-user>
  </v-toolbar>
</template>

<script>
  import ProjectCategory from '@/components/Projects/ProjectCategory';
  import HomeProjectsMixin from './HomeProjectsMixin';

  export default {
    components: {
      ProjectCategory,
    },
    mixins: [HomeProjectsMixin],
    props: {
      filters: { type: Object, required: true },
      projects: { type: Array, default: () => ([]) },
    },
    computed: {
      projectOwners() {
        return (this.filters.company == 'all' ? this.organizationUsers : this.companyUsers).filter(user => this.projects.find(item => item.owner_id == user.id))
          .map(user => ({
            id: user.id,
            avatar: user.avatar,
            username: this.$store.getters['users/getUsername'](user),
          }));
      },
      companyUsers() { return this.$store.getters['users/getCompanyUsers']; },
      organizationUsers() { return this.$store.getters['users/getOrganizationUsers']; },
      organizationCompanies() {
        const { organization } = this.$store.state.users.user;
        if (! organization) return [];
        const mylevel = (organization.getCompanies().find(item => item.id == this.$store.state.users.user.company_id) || {}).level;
        return [{ id: null, name: this.$t('GLOBAL.ALL') }].concat(organization.getCompanies().filter(item => item.level >= mylevel));
      },
      titleFilterProjects() {
        return this.projects.filter(project => this.filterProjectByCompany(project) && this.filterProjectByUser(project));
      },
      projectCategories() {
        const projectCategories = _.union(
          ...this.projects.map(project => project.category && project.category.split(',')),
          this.filters.categories || [], // add categories saved in filter
        );
        return projectCategories.sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1));
      },
    },
    created() {
      // vuetify search-input does not keep initial value
      const titleInitialValue = this.filters.title;
      if (titleInitialValue) {
        setTimeout(() => {
          this.filters.title = titleInitialValue;
        });
      }
    },
  };
</script>
