/* eslint-disable import/prefer-default-export */

export function loadRemoteScript(url, id) {
  if (id && document.getElementById(id)) return Promise.resolve();
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.onload = () => {
      resolve();
    };
    script.async = true;
    script.id = id;
    script.src = url;
    document.head.appendChild(script);
  });
}
