<template>
  <div>
    <!-- mcs_typecode -->
    <v-select v-model="values.val" label="Type de tâche" :items="mscTypecodeItems" :readonly="el.isFromTemplate()" hide-details
              @input.once="updateEl">
    </v-select>
  </div>
</template>

<script>
  export default {
    props: {
      el: { type: Object, required: true },
      customField: { type: Object, required: true },
    },
    data() {
      const { dataField } = this.customField;
      return {
        dataField,
        values: this.el.getCustomFields()?.[dataField] || {},
        mscTypecodeItems: [
          { text: 'Préparation/Présentation de l\'opération', value: '803750001' },
          { text: 'Préparation/Réunion de lancement', value: '803750002' },
          { text: 'Préparation/Grille de loyers et surfaces', value: '803750003' },
          { text: 'Execution/Plans techniques', value: '803750004' },
          { text: 'Execution/Echantillons', value: '803750005' },
          { text: 'Execution/Fiche équipements', value: '803750006' },
          { text: 'Execution/Logement témoin', value: '803750007' },
          { text: 'Execution/Suivi contractuel', value: '803750008' },
          { text: 'Execution/Echéancier', value: '803750009' },
          { text: 'Execution/Commercialisation', value: '803750010' },
          { text: 'Livraison/Opérations préalables à la livraison', value: '803750011' },
          { text: 'Livraison/Levée de réserves d\'OPL', value: '803750012' },
          { text: 'Livraison/Livraison', value: '803750013' },
          { text: 'Livraison/Contrats', value: '803750014' },
          { text: 'Livraison/Copropriété', value: '803750015' },
          { text: 'Livraison/Documents contractuels - 2 mois', value: '803750000' },
          { text: 'Livraison/Documents contractuels - 1 mois', value: '803750017' },
          { text: 'Livraison/Documents contractuels livraison', value: '803750018' },
          { text: 'Clôture/Levée des réserves de livraison', value: '803750016' },
          { text: 'Clôture/Réunion de clôture', value: '803750019' },
          { text: 'Clôture/Doc contractuels à + 60 jours', value: '803750020' },
          { text: 'Clôture/Doc contractuels à + 120 jours', value: '803750021' },
          { text: 'Clôture/GPA', value: '803750022' },
        ],
      };
    },
    methods: {
      updateEl() {
        const customFields = this.el.getCustomFields() || {};
        this.$set(customFields, this.dataField, this.values);
        this.el.setCustomFields(customFields);
      },
    },
  };
</script>
