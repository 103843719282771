<template>
  <div>
    <div v-show="! dataLoaded.company && ! dataLoaded.company_error" class="text-center"><i class="fas fa-spinner fa-spin fa-2x fa-fw"></i></div>
    <div v-if="! dataLoaded.company && dataLoaded.company_error">{{ dataLoaded.company_error }}</div>
    <div v-if="dataLoaded.company">
      <!-- Manage invites to join a company -->
      <manage-company-invites v-if="dataLoaded.company && ! userHasCompany"></manage-company-invites>

      <!-- Manage Subscriptions -->
      <manage-subscriptions></manage-subscriptions>

      <!-- Manage Account Users -->
      <div class="mb-6">
        <b class="primary--text text-uppercase">{{ $t('MANAGE.ACCOUNT_USERS') }}</b>
        <div class="table-responsive mt-2 mx-6">
          <table class="table table-striped mb-0">
            <thead class="text-uppercase">
              <tr>
                <th></th>
                <th>{{ $t('MANAGE.FIRSTNAME') }}</th>
                <th>{{ $t('MANAGE.LASTNAME') }}</th>
                <th>{{ $t('MANAGE.EMAIL') }}</th>
                <th class="text-center">{{ $t('MANAGE.ROLE') }}</th>
                <th v-show="userIsAdmin && companyUsers.length > 1" class="text-center">{{ $t('MANAGE.REMOVE') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in sortedCompanyUsers" :key="user.id">
                <td :class="{ 'pointer': openable_user = canIOpenUser(user) }" @click="openUser(user)">
                  <img :src="user.avatar" style="height:2em; border-radius: 50%;">
                </td>
                <td :class="{ 'strong pointer': openable_user }" @click="openUser(user)">{{ user.firstname | lowercase | ucfirst }}</td>
                <td :class="{ 'strong pointer': openable_user }" @click="openUser(user)">{{ user.lastname | lowercase | ucfirst }}</td>
                <td :class="{ 'pointer': openable_user }" @click="openUser(user)">{{ user.email }}</td>
                <td class="text-center">
                  <div v-if="companyUsers.length > 1 && canIEditUserRights(user)" :class="{ disabled: access_rights_disabled[user.id] }">
                    <i :class="{ 'selected success': user.access_right == 'consult' }" :title="$t('ACR.CONSULT')" class="fa fa-fw fa-eye access-right-icon"
                       @click="! access_rights_disabled[user.id] && setUserRights(user, 'consult')">
                    </i>
                    <i :class="{ 'selected success': user.access_right == 'user' }" :title="$t('ACR.USER')" class="fas fa-fw fa-pencil-alt access-right-icon"
                       @click="! access_rights_disabled[user.id] && setUserRights(user, 'user')">
                    </i>
                    <i :class="{ 'selected warning': user.access_right == 'company_admin' }" :title="$t('ACR.COMPANY_ADMIN')" class="fa fa-fw fa-unlock-alt access-right-icon"
                       @click="! access_rights_disabled[user.id] && setUserRights(user, 'company_admin')">
                    </i>
                  </div>
                  <template v-else>
                    {{ $t('ACR.' + uppercase(user.access_right)) }}
                  </template>
                </td>
                <td v-show="userIsAdmin && companyUsers.length > 1" class="text-center">
                  <i :title="$t('MANAGE.REMOVE_USER')" class="fa fa-fw fa-ban access-right-icon" @click="removeUser(user)"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ManageCompanyInvites from './ManageCompanyInvites';
  import ManageSubscriptions from './ManageSubscriptions';
  import ManageEventBus from './manage-event-bus';
  import manageMixin from './manageMixin';

  export default {
    components: {
      ManageCompanyInvites,
      ManageSubscriptions,
    },
    mixins: [manageMixin],
    props: {
      dataLoaded: { type: Object, default: () => {} },
      projects: { type: Array, default: () => [] },
      companyUsers: { type: Array, required: true },
      openedUsers: { type: Array, required: true },
    },
    data() {
      return {
        access_rights_disabled: [],
      };
    },
    computed: {
      userIsAdmin() { return this.$store.state.users.accessRight.isAdmin; },
      userHasCompany() { return !! this.$store.state.users.user.company; },
      sortedCompanyUsers() {
        return _.sortBy(this.companyUsers, 'firstname');
      },
    },
    created() {
      ManageEventBus.$on('on-remove-user', (user) => {
        this.removeUser(user);
      });
    },
    methods: {
      openUser(user) {
        this.$emit('open-user-from-id', user.id);
      },
      setUserRights(user, newRight) {
        let confirmPromise = Promise.resolve();
        if (user.id == this.$store.state.users.user.id) {
          confirmPromise = this.$store.dispatch('ui/msgbox/open', {
            title: user.email,
            body: `<i class="fa fa-2x fa-exclamation-triangle errorred--text"></i> &nbsp;${this.$t("MANAGE.LOSING_SPECIAL_RIGHTS")}`,
            buttons: { ok: "GLOBAL.OK", cancel: "GLOBAL.CANCEL" },
          });
        }
        confirmPromise.then(() => {
          this.$set(this.access_rights_disabled, user.id, true);
          const oldValue = user.access_right;
          user.access_right = newRight;
          window.apiSrv.call('users', 'update', { id: user.id, access_right: newRight }).then(() => {
            this.$set(this.access_rights_disabled, user.id, false);

            const userIndex = this.openedUsers.findIndex(item => item.id == user.id);
            if (userIndex > -1) {
              this.$emit('on-update-user-rights', userIndex);
            }
            if (user.id == this.$store.state.users.user.id) window.location.reload();
          }).catch((message) => {
            user.access_right = oldValue;
            this.$set(this.access_rights_disabled, user.id, false);
            if (message) this.$store.dispatch('ui/msgbox/open', { title: `${this.$t("GLOBAL.ERROR")} : ${this.$t("MANAGE.USER_RIGHT_NOT_MODIFIED")}`, body: message || "" });
          });
        }).catch(() => {});
      },
      removeUser(user) {
        const ownedProjects = this.projects.filter(item => item.owner_id == user.id);
        let confimMessage;
        if (user.id == this.$store.state.users.user.id) {
          confimMessage = `<i class="fa fa-2x fa-exclamation-triangle errorred--text"></i> &nbsp;${this.$t("MANAGE.LOSING_SPECIAL_RIGHTS")}`;
        } else if (ownedProjects.length) {
          const projectsCount = this.$tc('MANAGE.PROJECTS_COUNT', ownedProjects.length, { count: ownedProjects.length });
          const projectsCountThese = this.$tc('MANAGE.PROJECTS_COUNT_THESE', ownedProjects.length);
          const projectsCountTheir = this.$tc('MANAGE.PROJECTS_COUNT_THEIR', ownedProjects.length);
          confimMessage = this.$t('MANAGE.USER_IS_PROJECT_OWNER', { projectsCount, projectsCountThese, projectsCountTheir });
        } else {
          confimMessage = this.$t("MANAGE.USER_WILL_BE_REMOVED_FROM_COMPANY");
        }
        this.$store.dispatch('ui/msgbox/open', {
          title: this.$t("MANAGE.REMOVE_USER_FROM_COMPANY", { username: user.email }),
          body: confimMessage,
          buttons: { ok: "GLOBAL.OK", cancel: "GLOBAL.CANCEL" },
        }).then(() => {
          ManageEventBus.$emit('data-loaded-company', false);
          window.apiSrv.call('companies/users', 'destroy', user.id).then(() => {
            if (user.id == this.$store.state.users.user.id) {
              window.location.reload();
              return;
            }
            ManageEventBus.$emit('reload');
          }).catch((message) => {
            if (message) this.$store.dispatch('ui/msgbox/open', { title: `${this.$t("GLOBAL.ERROR")} : ${this.$t("MANAGE.USER_NOT_REMOVED")}`, body: message || "" });
            ManageEventBus.$emit('data-loaded-company', true);
          });
        }).catch(() => {});
      },
    },
  };
</script>
