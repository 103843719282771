<template>
  <div class="mx-auto" style="width: 80%">
    <div class="py-4">
      <v-radio-group v-model="design" row hide-details>
        <v-radio :label="$t('CONFIG.MODERN')" value="flat"></v-radio>
        <v-radio :label="$t('CONFIG.CLASSIC')" value="classic"></v-radio>
      </v-radio-group>
    </div>
    <div class="display-flex align-end">
      <div class="flex-grow-1">
        <label for="inputMainColor" aria-hidden="true" class="v-label v-label--active theme--light" style="font-size: 12px">
          {{ $t('CONFIG.MAIN_COLOR') }}
        </label>
        <v-tooltip :disabled="isPremium" bottom>
          <template #activator="{ on }">
            <input id="inputMainColor" v-model="designcolorMain" :disabled="! isPremium" type="color" class="form-control pointer" v-on="on">
          </template>
          <span>{{ $t('PREMIUM.PREMIUM_FEATURE') }}</span>
        </v-tooltip>
      </div>
      <div class="ml-6 flex-grow-1">
        <label for="inputSecondColor" aria-hidden="true" class="v-label v-label--active theme--light" style="font-size: 12px">
          {{ $t('CONFIG.SECOND_COLOR') }}
        </label>
        <v-tooltip :disabled="isPremium" bottom>
          <template #activator="{ on }">
            <input id="inputSecondColor" v-model="designcolorSecond" :disabled="! isPremium" type="color" class="form-control pointer" v-on="on">
          </template>
          <span>{{ $t('PREMIUM.PREMIUM_FEATURE') }}</span>
        </v-tooltip>
      </div>
      <v-btn icon class="ml-4" @click="resetDesignColors"><v-icon small>fas fa-undo</v-icon></v-btn>
    </div>
    <div class="py-4">
      <v-tooltip :disabled="isPremium" bottom>
        <template #activator="{ on }">
          <v-text-field v-model="logourl" :label="$t('CONFIG.CUSTOM_LOGO')" :hint="$t('CONFIG.OVERRIDE_LOGO')" :disabled="! isPremium" persistent-hint
                        type="url" placeholder="https://" v-on="on">
          </v-text-field>
        </template>
        <span>{{ $t('PREMIUM.PREMIUM_FEATURE') }}</span>
      </v-tooltip>
    </div>
    <div class="mt-6 text-center">
      <v-btn color="accent" rounded large @click="update()">{{ $t('CONFIG.APPLY_DESIGN') }}</v-btn>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      planning: { type: Object, required: true },
    },
    data() {
      return {
        design: this.planning.config.design,
        designcolorMain: this.planning.config.designcolor.main,
        designcolorSecond: this.planning.config.designcolor.second,
        logourl: this.planning.config.logo,
      };
    },
    computed: {
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
    },
    methods: {
      resetDesignColors() {
        this.designcolorMain = '#0169aa';
        this.designcolorSecond = '#777777';
      },
      update() {
        this.$emit('update', {
          design: this.design,
          designcolor: { main: this.designcolorMain, second: this.designcolorSecond },
          logourl: this.logourl,
        });
      },
    },
  };
</script>
