<template>
  <div v-if="elType == 'task' || elType == 'macro'">
    <table class="small" style="width: 100%">
      <tr>
        <td class="nobr" style="padding-right: 2px">
          {{ elStartTime | moment('mediumDateNoYear', true) }}
          <span v-if="elSchedule.start" style="color: rgba(0,0,0,0.65);">{{ elSchedule.start | moment('LT', true) }}</span>
        </td>
        <td class="nobr pull-right">
          {{ elEndTime | moment('mediumDateNoYear', true) }}
          <span v-if="elSchedule.end" style="color: rgba(0,0,0,0.65);">{{ elSchedule.end | moment('LT', true) }}</span>
        </td>
      </tr>
    </table>
  </div>
  <div v-else-if="elType == 'milestone'" class="date">
    {{ elStartTime | moment('mediumDateNoYear', true) }}
    <div v-if="elSchedule.start || elSchedule.end" class="small" style="font-weight: normal;">
      <span v-if="elSchedule.start">{{ elSchedule.start | moment('LT', true) }}</span>
      <span v-else>...</span>
      <span v-if="elSchedule.end">- {{ elSchedule.end | moment('LT', true) }}</span>
    </div>
  </div>
</template>

<style>
  .element.milestone .date {
    font-weight: bold;
    color: grey;
  }
</style>

<script>
  export default {
    props: {
      el: { type: Object, required: true },
    },
    computed: {
      elType() {
        return this.el.getType();
      },
      elStartTime() {
        return this.el.getStartTime();
      },
      elEndTime() {
        return this.el.getEndTime();
      },
      elSchedule() {
        return this.el.getSchedule();
      },
    },
  };
</script>
