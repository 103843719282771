<template>
  <v-autocomplete v-model="selectedGroupUsers"
                  :items="groupUsers"
                  :label="$t('GLOBAL.GROUPS')" :placeholder="selectedGroupUsers.length ? '' : $t('MULTIPROJECTS.NO_GROUP_SELECTED')"
                  item-text="title" item-value="group_id"
                  prepend-icon="fas far fa-users" hide-details multiple menu-props="offsetY"
                  @change="update">
    <template #prepend-item>
      <v-list-item @click.stop>
        <v-switch v-model="groupsOptions.hideUsers" :label="$t('MULTIPROJECTS.HIDE_USERS')" color="primary" hide-details class="mt-0" @change="update">
        </v-switch>
      </v-list-item>
      <filter-toggle :selected-ids="selectedGroupUsers" :sublist-ids="groupUserIds"
                     @update="selectedGroupUsers = $event; update()">
        {{ $t('MULTIPROJECTS.ALL_GROUPS_USERS') }} ({{ groupUserIds.length }})
      </filter-toggle>
      <v-divider></v-divider>
    </template>
    <template #selection="{ item, index }">
      <span v-if="selectedGroupUsers.length == 1">{{ item.title }}</span>
      <span v-else-if="selectedGroupUsers.length == groupUsers.length && index === 0" class="mr-2">{{ $t('MULTIPROJECTS.ALL_GROUPS_USERS') }} ({{ groupUsers.length }})</span>
      <span v-else-if="index === 0" class="mr-2">{{ selectedGroupUsers.length }}/{{ groupUsers.length }}</span>
    </template>
  </v-autocomplete>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    data() {
      return {
      };
    },
    computed: {
      selectedGroupUsers: {
        get() {
          return this.$store.state.multiprojects.selectedCompanyGroups;
        },
        set(newVal) {
          this.$store.state.multiprojects.selectedCompanyGroups.splice(0, this.$store.state.multiprojects.selectedCompanyGroups.length, ...newVal);
        },
      },
      groupUsers() {
        return (this.$store.getters['users/groups/getCompanyGroups'] || []).map(item => ({
          group_id: item.id,
          title: item.title,
          icon: 'far fa-users',
        }));
      },
      groupUserIds() {
        return this.groupUsers.map(item => item.group_id);
      },
      ...mapState('multiprojects', ['groupsOptions']),
    },
    methods: {
      update() {
        this.$emit('update');
      },
    },
  };
</script>
