<template>
  <v-container class="dependencies-manager pa-6">
    <v-row class="flex-nowrap">
      <v-col cols="4" style="position: relative; flex-shrink: 1">
        <div class="text-center" style="margin-bottom: 3px">
          <strong>{{ $t('DEPENDENCIES.PREDECESSORS') }} ({{ validDependencies.predecessors.length || 0 }})</strong>
        </div>
        <div v-for="relatedEl in validDependencies.predecessors" style="margin-bottom: 7px">
          <planning-element-view :el="displayableEl(relatedEl)" disable-mini-element
                                 @click.native="setMainEl(relatedEl)" @select-color="setMainEl(relatedEl)">
            <template #hover-menu>
              <div class="element-menu nobr" style="right:0; padding:0" @click.stop>
                <v-btn :title="$t('DEPENDENCIES.EDIT')" fab x-small outlined color="midgrey lighten-1" class="ma-0" @click="editDependency(relatedEl)">
                  <v-icon color="info" x-small>fas fa-edit</v-icon>
                </v-btn>
                <v-btn :title="$t('DEPENDENCIES.REMOVE')" fab x-small outlined color="midgrey lighten-1" class="ma-0" @click="deletePredecessor(relatedEl)">
                  <v-icon color="errorred" x-small>fas fa-trash-alt</v-icon>
                </v-btn>
              </div>
            </template>
          </planning-element-view>
        </div>
      </v-col>
      <v-col class="px-0"><v-icon color="secondary" small>fas fa-chevron-right</v-icon></v-col>
      <v-col cols="4" class="mb-2" style="max-height:100px; overflow:hidden; flex-shrink: 1">
        <div class="text-center" style="margin-bottom: 3px"><strong>&nbsp;</strong></div>
        <planning-element-view :el="displayedEl" disable-mini-element style="cursor: default">
        </planning-element-view>
      </v-col>
      <v-col class="px-0"><v-icon color="secondary" small>fas fa-chevron-right</v-icon></v-col>
      <v-col cols="4" style="flex-shrink: 1">
        <div class="text-center" style="margin-bottom: 3px">
          <strong>{{ $t('DEPENDENCIES.SUCCESSORS') }} ({{ validDependencies.successors.length || 0 }})</strong>
        </div>
        <div v-for="relatedEl in validDependencies.successors" style="margin-bottom:7px">
          <planning-element-view :el="displayableEl(relatedEl)" disable-mini-element
                                 @click.native="setMainEl(relatedEl)" @select-color="setMainEl(relatedEl)">
            <template #hover-menu>
              <div class="element-menu nobr" style="right:0; padding:0" @click.stop>
                <v-btn :title="$t('DEPENDENCIES.EDIT')" fab x-small outlined color="midgrey lighten-1" class="ma-0" @click="editDependency(relatedEl)">
                  <v-icon color="info" x-small>fas fa-edit</v-icon>
                </v-btn>
                <v-btn :title="$t('DEPENDENCIES.REMOVE')" fab x-small outlined color="midgrey lighten-1" class="ma-0" @click="deleteSuccessor(relatedEl)">
                  <v-icon color="errorred" x-small>fas fa-trash-alt</v-icon>
                </v-btn>
              </div>
            </template>
          </planning-element-view>
        </div>
      </v-col>
    </v-row>
    <v-divider class="my-4"></v-divider>
    <div>
      <label for="addDependency" class="primary--text text-uppercase">{{ $t('DEPENDENCIES.ADD_NEW') }}</label>
      <div class="ml-6 pt-2 display-flex align-center">
        <v-autocomplete id="addDependency" v-model="newTarget" :label="$t('DEPENDENCIES.LOOK_FOR_BUBBLE')"
                        :items="otherElements" :item-text="(el) => el.getTitle() || defaultTitle(el)" item-value="id" return-object
                        hide-details dense class="my-0" style="width: 50%"
                        @input="onNewTargetSelected">
          <template #item="data">
            <span class="text-truncate" style="max-width: 200px" v-html="data.parent.genFilteredText(data.item.getTitle() || defaultTitle(data.item))"></span>
            <small class="ml-2 text-muted">
              {{ data.item.getStartTime() | moment('mediumDateNoYear', true) }}
            </small>
            <small v-if="! data.item.isType('milestone')" class="text-muted">
              - {{ data.item.getEndTime() | moment('mediumDateNoYear', true) }}
            </small>
          </template>
        </v-autocomplete>
        <v-select v-model="isSuccessor" :items="[{ value: false, text: $t('DEPENDENCIES.PREDECESSOR') }, { value: true, text: $t('DEPENDENCIES.SUCCESSOR') }]"
                  hide-details dense class="mx-2 my-0" style="width: 30%">
        </v-select>
        <v-btn :disabled="! newTarget" color="accent" rounded style="width: 20%" @click="addDependency()">
          {{ $t(newTargetIsAlreadyDependency ? 'DEPENDENCIES.MODIFY' : 'DEPENDENCIES.ADD') }}
        </v-btn>
      </div>
      <div class="ml-6 display-flex align-center">
        <span>{{ $t('DEPENDENCIES.DELAY') }} :</span>
        <v-text-field v-model.number="newTargetDelay" type="number" class="mx-2" style="flex: 0 0 45px"></v-text-field>
        <span>{{ $tc('GLOBAL.DAYS', newTargetDelay) }}</span>
      </div>
      <div class="ml-6 text-muted">{{ $t('DEPENDENCIES.AUTOMATIC_UPDATE') }}</div>
    </div>

    <v-divider class="my-4"></v-divider>
    <div><b class="primary--text text-uppercase">{{ $t('GLOBAL.OPTIONS') }}</b></div>
    <div class="ml-6 my-2">
      <v-switch v-model="planning.config.displayDependencies" color="successgreen" hide-details class="ma-0">
        <template #label>
          <span class="secondary--text">{{ $t('DEPENDENCIES.DISPLAY_ON_PLANNING') }}</span>
        </template>
      </v-switch>
    </div>
    <div class="ml-6 my-2">
      <v-switch v-model="planning.config.displayCriticalPath" :disabled="! planning.config.displayDependencies" color="successgreen" hide-details class="ma-0">
        <template #label>
          <span class="secondary--text">{{ $t('DEPENDENCIES.DISPLAY_CRITICAL_PATH') }}</span>
        </template>
      </v-switch>
    </div>

    <v-divider class="my-4"></v-divider>
    <div><b class="primary--text text-uppercase">{{ $t('DEPENDENCIES.OPTIMIZATION') }}</b></div>
    <div class="ml-6 my-2">
      <v-btn :disabled="! canReduceElSuccessorsToMinimum" outlined color="successgreen" rounded @click="reduceElSuccessorsToMinimum()">
        {{ $t('DEPENDENCIES.REDUCE_TO_MINIMUM') }}
      </v-btn>
    </div>
  </v-container>
</template>

<style lang="scss">
  .dependencies-manager .element {
    position: relative;
    box-shadow: none;
    border-bottom: none !important;

    &.milestone {
      padding-top: 20px;
    }
  }
</style>

<script>
  import { mapActions } from 'vuex';
  import DependenciesSrv from '@/components/Dependencies/DependenciesSrv';
  import PlanningElementView from '@/components/Planning/PlanningElementView';

  export default {
    components: {
      PlanningElementView,
    },
    props: {
      planning: { type: Object, required: true },
      element: { type: Object, required: true },
    },
    data() {
      return {
        el: this.element,
        newTarget: null,
        isSuccessor: false,
        newTargetDelay: 0,
      };
    },
    computed: {
      displayedEl() {
        return this.displayableEl(this.el);
      },
      validDependencies() {
        const validDependencies = { predecessors: [], successors: [] };
        (this.el.getDependencies() || []).forEach((dependency) => {
          const targetEl = this.planning.elements.find(el => el.id == dependency.target_id);
          if (! targetEl) {
            this.deleteDependency(this.el, { id: dependency.target_id }, dependency.successor);
          } else if (dependency.successor) {
            validDependencies.successors.push(targetEl);
          } else {
            validDependencies.predecessors.push(targetEl);
          }
        });
        return validDependencies;
      },
      newTargetIsAlreadyDependency() {
        if (! this.newTarget || typeof this.newTarget !== 'object') return false;
        return (this.el.getDependencies() || []).findIndex(dependency => dependency.target_id == this.newTarget.id) > -1;
      },
      otherElements() {
        return this.planning.elements.filter(item => item.id != this.el.id && ! item.isType('macro'));
      },
      canReduceElSuccessorsToMinimum() {
        return !! (this.el.getDependencies() || []).length;
      },
    },
    methods: {
      displayableEl(el) {
        const displayableEl = angular.copy(el);
        displayableEl.ytextposition = 20;
        displayableEl.setConfig({ 'show-date': displayableEl.getConfig('show-date') });
        return displayableEl;
      },
      deleteDependency(fromEl, relatedEl, isSuccessor) {
        const dependencies = fromEl.getDependencies() || [];
        const index = dependencies.findIndex(dependency => dependency.target_id == relatedEl.id && ! dependency.successor == ! isSuccessor);
        if (index > -1) {
          dependencies.splice(index, 1);
          fromEl.setDependencies(dependencies);
        }
      },
      deletePredecessor(relatedEl) {
        this.deleteDependency(this.el, relatedEl, false);
        this.deleteDependency(relatedEl, this.el, true);
      },
      deleteSuccessor(relatedEl) {
        this.deleteDependency(this.el, relatedEl, true);
        this.deleteDependency(relatedEl, this.el, false);
      },
      addDependency() {
        if (! this.newTarget || typeof this.newTarget !== 'object' || this.newTarget.id == this.el.id) return;

        const elDependencies = this.el.getDependencies() || [];
        const newTargetDependencies = this.newTarget.getDependencies() || [];

        let index = elDependencies.findIndex(item => item.target_id == this.newTarget.id);
        if (index > -1) elDependencies.splice(index, 1);
        index = newTargetDependencies.findIndex(item => item.target_id == this.el.id);
        if (index > -1) newTargetDependencies.splice(index, 1);

        elDependencies.push({ target_id: this.newTarget.id, successor: this.isSuccessor, delay: this.newTargetDelay });
        this.el.setDependencies(elDependencies);
        newTargetDependencies.push({ target_id: this.el.id, successor: ! this.isSuccessor, delay: this.newTargetDelay });
        this.newTarget.setDependencies(newTargetDependencies);
        this.newTarget = null;
      },
      editDependency(relatedEl) {
        const dependencies = this.el.getDependencies() || [];
        const index = dependencies.findIndex(item => item.target_id == relatedEl.id);
        if (index == -1) return;
        this.newTarget = relatedEl;
        this.isSuccessor = dependencies[index].successor || false;
        this.newTargetDelay = dependencies[index].delay || 0;
      },
      setMainEl(newel) {
        this.el = newel;
      },
      defaultTitle(el) {
        if (el.isType('macro')) return this.$t('PLANNING.NEW_MACRO_BUBBLE');
        return el.isType('milestone') ? this.$t('PLANNING.MILESTONE') : this.$t('PLANNING.NEW_BUBBLE');
      },
      onNewTargetSelected(newTarget) {
        this.isSuccessor = newTarget.getStartTime().isAfter(this.el.getStartTime());
      },
      reduceElSuccessorsToMinimum() {
        const relatedEls = this.planning.elements.filter(item => item.getDependencies()?.length).map(el => angular.copy(el));
        const relatedEl = relatedEls.find(item => item.id === this.el.id);
        if (! relatedEl) return;
        this.$emit('close');

        const reducedElements = DependenciesSrv.calculateElSuccessors(new Set(), relatedEls, relatedEl);
        if (! reducedElements.size) { // nothing to change
          this.$store.dispatch('ui/msgbox/open', {
            title: this.$tc('DEPENDENCIES.REDUCE_TO_MINIMUM_CONFIRMATION_TITLE', 0),
            body: 'DEPENDENCIES.NO_ELEMENTS_TO_REDUCE_TO_MINIMUM',
            buttons: { ok: 'GLOBAL.OK' },
          });
          return;
        }
        const body = [...reducedElements].map(el => 
          `<div>- ${this.$t(el.isType('milestone') ? 'GLOBAL.MILESTONE' : 'GLOBAL.BUBBLE')} « ${el.getTitle()} »</div>`
        ).join('');
        this.$store.dispatch('ui/msgbox/open', {
          title: this.$tc('DEPENDENCIES.REDUCE_TO_MINIMUM_CONFIRMATION_TITLE', reducedElements.size),
          body,
          buttons: { ok: 'GLOBAL.OK', cancel: 'GLOBAL.CANCEL' },
        }).then(() => { // apply modifications
          this.startChangingElement();
          reducedElements.forEach((el) => {
            const realEl = this.$store.getters['planning/elements/getElement'](el.id);
            realEl.setStartTime(el.getStartTime());
            realEl.setEndTime(el.getEndTime());
            realEl.update();
          });
          this.changingElement();
        }).catch(() => {});
      },
      ...mapActions('planning/elements', ['startChangingElement', 'changingElement']),
    },
  };
</script>
