<template>
  <v-card class="table-responsive px-4 py-2 mt-2 mx-2">
    <table class="table">
      <thead style="text-transform: uppercase">
        <tr>
          <th>{{ $t('CUSTOM_VIEWS.LANE') }}</th>
          <th class="text-center">{{ $t('CUSTOM_VIEWS.ACCESS_RIGHT') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="lane in planning.lanes" :key="lane.id">
          <td>{{ lane.label }}</td>
          <td class="text-center nobr">
            <div :class="{ disabled: acr_btn_disabled.lanes[lane.id] }">
              <i :class="{'selected danger': (lane_acr = getLaneAcr(lane)) == 'none'}" :title="$t('ACR.NONE')" class="fa fa-fw fa-ban access-right-icon"
                 @click="! acr_btn_disabled.lanes[lane.id] && setAcr(lane, 'none')">
              </i>
              <i :class="{'selected success': lane_acr == 'consult'}" :title="$t('ACR.CONSULT')" class="fa fa-fw fa-eye access-right-icon"
                 @click="! acr_btn_disabled.lanes[lane.id] && setAcr(lane, 'consult')">
              </i>
              <i :class="{'selected success': lane_acr == 'modify'}" :title="$t('ACR.MODIFY')" class="fas fa-fw fa-pencil-alt access-right-icon"
                 @click="! acr_btn_disabled.lanes[lane.id] && setAcr(lane, 'modify')">
              </i>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </v-card>
</template>

<script>
  import viewsMixin from './viewsMixin';

  export default {
    mixins: [viewsMixin],
    props: {
      acr: { type: Object, required: true },
    },
    data() {
      return {
        acr_btn_disabled: { lanes: {} },
      };
    },
    computed: {
      planning() {
        return this.$store.state.planning;
      },
    },
    created() {
      this.initAcr();
    },
    updated() {
      this.initAcr();
    },
    methods: {
      initAcr() {
        // ITM defaults
        if (! this.acr.lanes) this.$set(this.acr, 'lanes', {});
        if (! this.acr.lanes.default) this.$set(this.acr.lanes, 'default', { access_right: 'none' });
      },
      setAcr(lane, newRight) {
        const { acr } = this;
        this.$set(this.acr_btn_disabled.lanes, lane.id, true);
        const oldValue = acr.lanes[lane.id];
        this.$set(acr.lanes, lane.id, _.extend(acr.lanes[lane.id] || {}, { access_right: newRight }));
        this.$emit('update', {
          onSuccess: () => {
            this.acr_btn_disabled.lanes[lane.id] = false;
          },
          onError: () => {
            this.$set(acr.lanes, lane.id, oldValue);
            this.acr_btn_disabled.lanes[lane.id] = false;
          },
        });
      },
    },
  };
</script>
