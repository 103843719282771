const maxUndo = 30;

/** ******** */
/* MUTATIONS */
/** ******** */
function commitAddUndo(state, newel) {
  state.undos.push(newel);
  if (state.undos.length > maxUndo) state.undos.shift();
}

function commitAddRedo(state, newel) {
  state.redos.push(newel);
}

function commitUndone(state) {
  const action = state.undos.pop();
  state.redos.push(action);
}

function commitRedone(state) {
  const action = state.redos.pop();
  state.undos.push(action);
}

function commitEmptyUndo(state) {
  state.undos.splice(0, state.undos.length);
}

function commitEmptyRedo(state) {
  state.redos.splice(0, state.redos.length);
}

/** ******** */
/* GETTERS */
/** ******** */
function isUndoable(state) {
  return state.undos.length !== 0;
}

function isRedoable(state) {
  return state.redos.length !== 0;
}

/** ******** */
/* ACTIONS */
/** ******** */
function undo({ commit, state, dispatch }) {
  const action = state.undos.last();
  if (! action) return;
  action.rollback();
  commit('undone');
  setTimeout(() => dispatch('planning/save', null, { root: true }));
}

function redo({ commit, state, dispatch }) {
  const action = state.redos.last();
  if (! action) return;
  action.execute();
  commit('redone');
  setTimeout(() => dispatch('planning/save', null, { root: true }));
}

/** Action Model */
function UndoRedoAction(execute, rollback) {
  this.execute = execute;
  this.rollback = rollback;
}

/** Add a new Rollabackable action */
function add({ commit }, { action, rollback }) {
  commit('addUndo', new UndoRedoAction(action, rollback));
  commit('emptyRedo');
}

function reset({ commit }) {
  commit('emptyUndo');
  commit('emptyRedo');
}

export default {
  namespaced: true,
  state: {
    undos: [],
    redos: [],
  },
  mutations: {
    addUndo: commitAddUndo,
    addRedo: commitAddRedo,
    undone: commitUndone,
    redone: commitRedone,
    emptyUndo: commitEmptyUndo,
    emptyRedo: commitEmptyRedo,
  },
  getters: {
    isUndoable,
    isRedoable,
  },
  actions: {
    undo,
    redo,
    add,
    reset,
  },
};
