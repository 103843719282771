<template>
  <v-container id="projectsheet" fluid class="pa-6">
    <div class="ml-8">
      <div class="display-flex align-center">
        <b class="text-h6 primary--text">{{ planning.getTitle() || $t('PLANNING.UNNAMED_PROJECT') }}</b>
        <template v-if="isPremium">
          <v-divider vertical class="ml-auto mr-2 export-hidden"></v-divider>
          <v-btn :disabled="exporting.inprogress" icon small class="export-hidden" @click="! exporting.inprogress && pdfExport()">
            <v-icon color="secondary" small>{{ exporting.inprogress ? 'fas fa-spinner fa-spin' : 'far fa-file-pdf' }}</v-icon>
          </v-btn>
        </template>
      </div>
      <div class="mt-1 display-flex">
        <project-sheet-thumbnail :planning="planning"></project-sheet-thumbnail>
        <div class="ml-4 flex-grow-1">
          <div :title="$t('MANAGE.OWNER')" class="display-flex align-center">
            <img v-if="owner && owner.avatar" :src="owner.avatar" class="mr-2" style="max-height: 20px;border-radius: 50%;">
            <span>{{ owner | username }}</span>
          </div>
          <project-sheet-category :planning="planning" class="mt-1"></project-sheet-category>
        </div>
      </div>
    </div>

    <v-divider class="my-4 ml-8"></v-divider>

    <project-sheet-description :planning="planning" @update="$emit('update', $event)"></project-sheet-description>

    <v-divider class="my-4 ml-8"></v-divider>

    <div class="display-flex align-center mb-2">
      <div class="mr-2 text-center" style="flex: 0 0 24px;">
        <v-icon color="primary" small>fas fa-flag</v-icon>
      </div>
      <b class="primary--text text-uppercase">{{ $t('PROJECT_SHEET.WAYPOINTS') }}</b>
      <template v-if="userIsProjectAdmin">
        <v-divider vertical class="ml-auto mr-2 export-hidden"></v-divider>
        <v-btn icon small class="export-hidden" @click="addStep">
          <v-icon color="secondary" small>fas fa-plus-circle</v-icon>
        </v-btn>
      </template>
    </div>
    <div class="ml-8">
      <div v-if="currentStep">
        <div v-if="stepEditing">
          <div class="display-flex align-center">
            <button :style="hasPrevStep ? {} : {'color': 'gray', 'pointer-events': 'none'}" class="arrow-button" @click="toNextStep(-1)">
              <i class="fa fa-2x fa-caret-left"></i>
            </button>
            <div class="mx-4 pb-1" style="max-width: 200px;">
              <date-field v-model="currentStepDate" hide-details no-null input-class="ma-0 pa-0"></date-field>
            </div>
            <button :style="hasNextStep ? {} : {'color': 'gray', 'pointer-events': 'none'}" class="arrow-button" @click="toNextStep()">
              <i class="fa fa-2x fa-caret-right"></i>
            </button>
            <v-spacer></v-spacer>
            <template v-if="userIsProjectAdmin">
              <v-divider vertical class="mx-2"></v-divider>
              <v-btn icon small @click="deleteStep">
                <v-icon color="errorred" small>fas fa-trash-alt</v-icon>
              </v-btn>
            </template>
          </div>
          <div class="mt-4">
            <div v-for="moodicon in ['sun', 'suncloud', 'cloud', 'storm']" :key="moodicon" :style="currentStep.mood == moodicon ? {'border': '3px solid green', padding: '6px'} : null"
                 class="pointer vcenter moodicon-wrapper" @click="currentStep.mood == moodicon ? currentStep.mood = null : currentStep.mood = moodicon">
              <div :class="'mood-icon-' + moodicon" :title="$t('MOOD.' + uppercase(moodicon))"></div>
            </div>
          </div>
          <div class="mt-4">
            <summernote v-model="currentStep.waypoint" name="waypointEditor" :default-content="defaultStepWaypoint" :config="{ height: '150px' }" lang="fr-FR"></summernote>
          </div>
          <div v-if="stepsExtraFields" class="mt-2" style="display: flex; flex-wrap: wrap; margin: 8px -8px 0">
            <div v-for="field in stepsExtraFields" :key="field.name" :style="{ width: (field.width || 1) * 100 + '%' }" style="padding: 0 8px; flex-grow: 1;">
              <i :class="field.icon"></i>&nbsp;
              <b>{{ field.title }}</b>
              <div class="title-underline"></div>
              <summernote v-model="currentStep[field.name]" :name="`waypointEditor-${field.name}`" :config="{ height: '150px' }" lang="fr-FR"></summernote>
            </div>
          </div>
          <div class="text-center">
            <v-btn color="secondary" rounded large outlined class="mx-2" @click="resetSteps">{{ $t('GLOBAL.CANCEL') }}</v-btn>
            <v-btn color="accent" rounded large class="mx-2" @click="saveSteps">{{ $t('GLOBAL.SAVE') }}</v-btn>
          </div>
        </div>
        <div v-else>
          <div class="display-flex align-center">
            <button :style="hasPrevStep ? {} : {'color': 'gray', 'pointer-events': 'none'}" class="arrow-button" @click="toNextStep(-1)">
              <i class="fa fa-2x fa-caret-left"></i>
            </button>
            <span class="mx-4">{{ currentStep.date | moment('mediumDate') }}</span>
            <button :style="hasNextStep ? {} : {'color': 'gray', 'pointer-events': 'none'}" class="arrow-button" @click="toNextStep()">
              <i class="fa fa-2x fa-caret-right"></i>
            </button>
            <template v-if="userIsProjectAdmin">
              <v-divider vertical class="ml-auto mr-2 export-hidden"></v-divider>
              <v-btn icon small class="ma-0 export-hidden" @click="stepEditing = ! stepEditing">
                <v-icon color="secondary" small>fas fa-edit</v-icon>
              </v-btn>
            </template>
          </div>
          <div class="display-flex">
            <div v-if="currentStep.mood" class="moodicon-wrapper" style="margin-right: 16px; flex-shrink: 0;">
              <div :class="'mood-icon-' + currentStep.mood" :title="$t('MOOD.' + uppercase(currentStep.mood))"></div>
            </div>
            <div class="summernote-html" v-html="currentStep.waypoint"></div>
          </div>
          <div v-if="stepsExtraFields" style="display: flex; flex-wrap: wrap; margin: 8px -8px 0">
            <div v-for="field in stepsExtraFields" v-if="hasContent(currentStep[field.name])" :key="field.name"
                 :style="{ width: (field.width || 1) * 100 + '%' }" class="mb-1 px-2" style="flex-grow: 1;">
              <i v-if="field.icon" :class="field.icon" class="mr-2"></i>
              <b class="primary--text">{{ field.title }}</b>
              <div class="title-underline"></div>
              <div class="summernote-html" v-html="currentStep[field.name]"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-divider class="my-4 ml-8"></v-divider>

    <div class="display-flex align-center mb-2">
      <div class="mr-2 text-center" style="flex: 0 0 24px;">
        <v-icon color="primary" small>fas fa-paperclip</v-icon>
      </div>
      <b class="primary--text text-uppercase">{{ $t('PROJECT_SHEET.PROJECT_SHEET_FILES') }}</b>
    </div>
    <div class="ml-8 pt-1">
      <div>
        <ul class="list-unstyled">
          <li v-for="file in files" class="file-item display-flex align-center" style="margin-bottom: 2px">
            <i :class="file.mime | mimeIcon" class="fa-fw mr-1"></i>
            <a :href="file.url" class="text-ellipsis" target="_blank">{{ file.name || file.display_filename }}</a>
            <template v-if="userIsProjectAdmin">
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn icon small outlined color="secondary" class="ml-4 file-item-trash" v-on="on" @click="editLinkName(file)">
                    <v-icon small>far fa-edit</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('GLOBAL.RENAME') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn icon small outlined color="errorred" class="mx-2 file-item-trash" v-on="on" @click="deleteFile(file)">
                    <v-icon small>far fa-trash-alt</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('GLOBAL.DELETE') }}</span>
              </v-tooltip>
            </template>
          </li>
        </ul>
        <file-upload v-if="userIsProjectAdmin" ref="fileUpload" input-id="file-project-sheet" class="export-hidden" @uploaded="fileUploaded"></file-upload>
        <!--              ADD LINK FORM-->
        <form v-if="userIsProjectAdmin" class="display-flex align-center" style="position: relative" @submit.prevent="addLink()">
          <v-text-field v-model="linkurl" :label="$t('ELEMENT-DETAIL.ADD_LINK')" placeholder="http://..." style="max-width: 300px"></v-text-field>
          <v-btn :disabled="! linkurl" color="accent" small rounded type="submit" class="ml-2">{{ $t('ELEMENT-DETAIL.ADD') }}</v-btn>
        </form>
      </div>
    </div>

    <v-divider class="my-4 ml-8"></v-divider>

    <div class="display-flex align-center mb-2">
      <div class="mr-2 text-center" style="flex: 0 0 24px;">
        <v-icon color="primary" small>fas fa-user</v-icon>
      </div>
      <b class="primary--text text-uppercase">{{ $t('PROJECT_SHEET.USERS') }}</b>
    </div>
    <div class="ml-8">
      <div>
        <div v-for="(user, id) in projectUsers" :key="id" class="vcenter" style="margin-right: 8px; margin-bottom: 8px; background: rgba(0,0,0,.07); padding: 2px 6px; border-radius: 10px">
          <img v-if="user.avatar" :src="user.avatar" style="max-height: 20px;border-radius: 50%;">
          <i v-else class="fas fa-user" style="margin: 0 3px"></i>
          &nbsp;<span class="vcenter">{{ user | username }}</span>
        </div>
      </div>
    </div>

    <v-divider class="my-4 ml-8"></v-divider>

    <div class="display-flex align-center mb-2">
      <div class="mr-2 text-center" style="flex: 0 0 24px;">
        <v-icon color="primary" small>fas fa-chart-bar</v-icon>
      </div>
      <b class="primary--text text-uppercase">{{ $t('PROJECT_SHEET.ACTIVITY') }}</b>
    </div>
    <div class="ml-8">
      <project-sheet-activity :planning="planning"></project-sheet-activity>
    </div>
  </v-container>
</template>

<style scoped>
  .arrow-button {
    height: 34px;
    outline-style: none;
    background-color: transparent;
    border: none;
    vertical-align: middle;
  }

  .moodicon-wrapper {
    border-radius: 50%;
    width: 64px;
    height: 64px;
    transition: .3s;
  }
  .moodicon-wrapper + .moodicon-wrapper {
    margin-left: 16px;
  }

  .file-item-trash {
    visibility: hidden;
    opacity: 0;
    transition: .3s;
  }
  .file-item:hover > .file-item-trash {
    visibility: visible !important;
    opacity: 1 !important;
  }
</style>

<script>
  import clientConfig from '@/client_customs/config';
  import FileUpload from '@/components/Reusables/FileUpload';
  import Summernote from '@/components/Reusables/Summernote';
  import ProjectSheetThumbnail from './ProjectSheetThumbnail';
  import ProjectSheetCategory from './ProjectSheetCategory';
  import ProjectSheetDescription from './ProjectSheetDescription';
  import ProjectSheetActivity from './ProjectSheetActivity';

  function deepcopy(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  export default {
    components: {
      FileUpload,
      Summernote,
      ProjectSheetThumbnail,
      ProjectSheetCategory,
      ProjectSheetDescription,
      ProjectSheetActivity,
    },
    props: {
      planning: { type: Object, required: true },
    },
    data() {
      const projectsheet = this.planning.projectsheet || {};
      const steps = deepcopy(projectsheet.steps || []);
      const userIsProjectAdmin = this.planning.meta.access_right == 'admin';
      return {
        userIsProjectAdmin,
        steps,
        currentStepIndex: steps.length - 1,
        stepEditing: false,
        defaultStepWaypoint: clientConfig.projectsheet && clientConfig.projectsheet.defaultStepWaypoint || "",
        files: deepcopy(projectsheet.files || []),
        linkurl: "",
        exporting: { inprogress: false, success: false, error: false },
      };
    },
    computed: {
      currentStep() { return this.steps[this.currentStepIndex]; },
      currentStepDate: {
        get() { return this.currentStep && this.currentStep.date; },
        set(newVal) {
          if (! newVal) return;
          const step = this.currentStep;
          step.date = newVal.format();
          this.steps.sort((a, b) => (moment(a.date).isBefore(b.date) ? -1 : 1));
          this.currentStepIndex = this.steps.indexOf(step);
        },
      },
      owner() {
        return this.$store.getters['users/getUserById'](this.planning.meta.owner_id);
      },
      hasPrevStep() {
        return this.currentStepIndex >= 1;
      },
      hasNextStep() {
        return this.currentStepIndex < this.steps.length - 1;
      },
      stepsExtraFields() {
        // [{"title":"Points d'attention","name":"issues","width":0.5},{"title":"Points de blocage à lever","name":"actions","width":0.5}]
        return this.$store.getters['users/accessRight/config'].projectsheet_steps_extrafields;
      },
      projectUsers() {
        const users = {};
        this.planning.elements.forEach((item) => {
          if (! item.getUsers()) return;
          item.getUsers().forEach((user) => {
            const id = user.id || user.username || user.email;
            users[id] = user;
          });
        });
        return users;
      },
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
    },
    created() {
      this.steps.forEach((step) => { step.id = step.id || window.uuid(); }); // init id for old versions
    },
    methods: {
      toNextStep(amount = 1) {
        this.currentStepIndex += amount;
      },
      addStep() {
        const newStep = { id: window.uuid(), waypoint: "", date: moment().format(), mood: null, updated_at: moment().format() };
        this.steps.push(newStep);
        this.steps.sort((a, b) => (moment(a.date).isBefore(b.date) ? -1 : 1));
        this.currentStepIndex = this.steps.indexOf(newStep);
        this.stepEditing = true;
      },
      deleteStep() {
        if (this.currentStep) this.steps.splice(this.currentStepIndex, 1);
        this.currentStepIndex = Math.min(this.currentStepIndex, this.steps.length - 1);
        if (! this.steps.length) this.saveSteps();
      },
      resetSteps() {
        const projectsheet = this.planning.projectsheet || {};
        this.steps = deepcopy(projectsheet.steps || []);
        this.currentStepIndex = Math.min(this.currentStepIndex, this.steps.length - 1);
        this.stepEditing = false;
      },
      saveSteps() {
        const projectsheet = this.planning.projectsheet || {};
        this.steps.forEach((step) => {
          if (! step.id) return;
          const originalStep = (projectsheet.steps || []).find(item => item.id == step.id);
          if (originalStep && ! angular.equals(originalStep, step)) step.updated_at = moment().format();
        });

        this.$emit('update', {
          steps: deepcopy(this.steps),
        });
        this.stepEditing = false;
      },
      fileUploaded(newFile) {
        if (! (newFile && newFile.response && newFile.response.file)) return;
        this.files.push(newFile.response.file);
        this.$emit('update', {
          files: deepcopy(this.files),
        });
      },
      addLink() {
        if (! this.linkurl) return;
        let name = this.linkurl.replace(/\/+$/, ''); // strip ending slashes
        name = name.substr(name.lastIndexOf('/') + 1);
        this.files.push({ type: 'link', url: this.linkurl, name });
        this.linkurl = "";
        this.$emit('update', {
          files: deepcopy(this.files),
        });
      },
      editLinkName(link) {
        const newName = window.prompt(this.$t('GLOBAL.RENAME'), link.name || link.display_filename);
        if (! newName) return;
        this.$set(link, 'name', newName);
        this.$emit('update', {
          files: deepcopy(this.files),
        });
      },
      deleteFile(file) {
        const index = this.files.indexOf(file);
        if (index == -1) return;
        this.files.splice(index, 1);
        this.$emit('update', {
          files: deepcopy(this.files),
        });
      },
      pdfExport() {
        _.extend(this.exporting, { inprogress: true, success: false, error: false });
        const $html = document.querySelector("html").cloneNode(true);
        const queryEl = $html.querySelector('#projectsheet') || document.createElement("div");
        $html.querySelector("body").innerHTML = `<div class="container container--fluid px-12">
          <h1 class="primary--text text-uppercase text-center mb-6">${this.$t('PROJECT_SHEET.PROJECT_SHEET')}</h1>${queryEl.innerHTML}
        </div>`;
        $html.querySelector('body').classList.add('v-application');
        $html.querySelectorAll(".export-hidden").forEach((el) => { el.parentNode.removeChild(el); });
        $html.querySelectorAll(".flex").forEach(el => el.style.setProperty('break-inside', 'avoid'));

        window.apiSrv.call('pdf', 'store', { html: $html.innerHTML, orientation: "portrait", footer: `${this.$t('PROJECT_SHEET.PROJECT_SHEET')} - ${this.planning.getTitle()}` }).then((response) => {
          if (response && response.data && response.data.pdfurl) {
            this.exporting.inprogress = false;
            this.exporting.success = true;
            setTimeout(() => { this.exporting.success = false; }, 3000);
            window.open(`${response.data.pdfurl}/${this.$t('PROJECT_SHEET.PROJECT_SHEET')}.pdf`, "_blank");
          }
        }).catch((message) => {
          this.exporting.inprogress = false;
          this.exporting.error = message || "Error : not exported";
        });
      },
      uppercase(str) {
        return str && str.toUpperCase();
      },
      hasContent(html) {
        return !! window.html2text(html || "").trim();
      },
    },
  };
</script>
