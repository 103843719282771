/* eslint no-unused-vars: 0 */
import store from '@/store';

export default {
  namespaced: true,
  modules: {},
  state: {
    outlook: {
      loading: null,
      connected: null,
      config: { sync_miletones: null, sync_tasks: null },
      elements: null,
      url: null,
      last_refresh: null,
    },
  },
  getters: {
    getElementStatus: state => ((el) => {
      const { outlook } = state;
      if (! outlook.connected) return null;
      const planning = el.getPlanning();
      if (! planning) return null;
      const elementConfig = outlook.elements.find(item => item.element_id == el.id && item.planning_id == planning.id);
      if (elementConfig && elementConfig.sync) {
        return elementConfig.sync == 1;
      }
      if (! el.getUsers() || ! el.getUsers().find(item => item.id == store.state.users.user.id)) return false;
      return outlook.config[el.isType('task') ? 'sync_tasks' : 'sync_milestones'] || false;
    }),
  },
  actions: {
    refreshConnection({ state }) {
      const { outlook } = state;
      if (outlook.connected && moment().diff(outlook.last_refresh, 'minutes') < 2) return Promise.resolve(outlook);
      outlook.loading = true;
      return window.apiSrv.call('integrations/microsoft', undefined, undefined, true).then((response) => {
        outlook.connected = response && response.data && response.data.connected || false;
        _.extend(outlook.config, response && response.data && response.data.config || {});
        outlook.elements = response && response.data && response.data.elements || [];
        outlook.url = response && response.data && response.data.url || "";
        outlook.last_refresh = moment();
        return outlook;
      }).catch((message) => {
        outlook.connected = false;
        return Promise.reject(message);
      }).finally(() => {
        outlook.loading = false;
      });
    },

    setElementStatus({ state }, { el, sync }) {
      const { outlook } = state;
      const planning = el.getPlanning();
      if (! planning) return Promise.reject(new Error("missing planning"));
      outlook.loading = true;
      return window.apiSrv.call('integrations/microsoft/elements', 'store', { planning_id: planning.id, element_id: el.id, sync }).then((response) => {
        const elementConfigIndex = outlook.elements.findIndex(item => item.element_id == el.id && item.planning_id == planning.id);
        if (elementConfigIndex > -1) outlook.elements.splice(elementConfigIndex, 1);
        if (response && response.data && response.data.element) outlook.elements.push(response.data.element);
      }).finally(() => {
        outlook.loading = false;
      });
    },

    setConfig({ state }, data) {
      const { outlook } = state;
      outlook.loading = true;
      return window.apiSrv.call('integrations/microsoft/config', 'store', data).then((response) => {
        if (response && response.data && response.data.config) outlook.config = response.data.config;
      }).finally(() => {
        outlook.loading = false;
      });
    },

    postEvent(context, { el }) {
      const planning = el.getPlanning();
      return window.apiSrv.call('integrations/microsoft/events', 'store', { element: el.getAll(), planning: { id: planning.id, title: planning.getTitle(), url: planning.getUrl() } });
    },

    logout({ state }) {
      const { outlook } = state;
      outlook.loading = true;
      return window.apiSrv.call('integrations/microsoft/logout').then(() => {
        outlook.connected = false;
      }).finally(() => {
        outlook.loading = false;
      });
    },
  },
  mutations: {

  },
};
