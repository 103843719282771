<template>
  <div>
    <div class="display-flex align-center mb-2">
      <div class="mr-2 text-center" style="flex: 0 0 24px;">
        <v-icon color="primary" small>fas fa-align-justify</v-icon>
      </div>
      <b class="primary--text text-uppercase">{{ $t('PROJECT_SHEET.DESCRIPTION') }}</b>
      <template v-if="userIsProjectAdmin && ! descriptionEditing">
        <v-divider vertical class="ml-auto mr-2 export-hidden"></v-divider>
        <v-btn icon small class="ma-0 export-hidden" @click="descriptionEditing = ! descriptionEditing">
          <v-icon color="secondary" small>fas fa-edit</v-icon>
        </v-btn>
      </template>
    </div>
    <div class="ml-8">
      <div v-if="descriptionEditing">
        <summernote v-model="description" name="descriptionEditor" :default-content="defaultDescription" :config="{ height: '150px' }" lang="fr-FR"></summernote>
        <div class="text-center">
          <v-btn color="secondary" rounded large outlined class="mx-2" @click="resetDescription">{{ $t('GLOBAL.CANCEL') }}</v-btn>
          <v-btn color="accent" rounded large class="mx-2" @click="saveDescription">{{ $t('GLOBAL.SAVE') }}</v-btn>
        </div>
      </div>
      <div v-else class="summernote-html" v-html="description"></div>
      <div class="mt-4"></div>
      <div v-if="userIsProjectAdmin || targetEndtime || targetBudget">
        <v-divider class="my-2"></v-divider>
        <div class="display-flex align-center">
          <div v-if="userIsProjectAdmin || targetEndtime" :class="{ 'export-hidden': ! targetEndtime }" class="display-flex align-center flex-grow-1">
            <v-icon small class="mr-2" style="width: 12px; height: 32px;">fas fa-hourglass-end</v-icon>
            <template v-if="targetEndtimeEditing">
              <date-field v-model="targetEndtime" hide-details input-class="pa-0 ma-0" clearable></date-field>
              <v-btn color="accent" rounded small class="mx-2" @click="saveTarget('targetEndtime')">{{ $t('GLOBAL.SAVE') }}</v-btn>
              <v-btn color="secondary" rounded small outlined class="mr-2" style="min-width: 0" @click="resetTarget('targetEndtime')"><v-icon small>fas fa-times</v-icon></v-btn>
            </template>
            <template v-else-if="targetEndtime">
              <div>{{ $t('PROJECT_SHEET.TARGET_ENDTIME') }} : <span class="nobr">{{ targetEndtime | moment('date') }}</span></div>
              <v-btn v-if="userIsProjectAdmin" icon small class="ml-2 export-hidden" @click="targetEndtimeEditing = ! targetEndtimeEditing">
                <v-icon color="secondary" size="14">fas fa-edit</v-icon>
              </v-btn>
            </template>
            <template v-else>
              <v-btn color="secondary" small rounded outlined @click="targetEndtimeEditing = true">
                <v-icon size="14" class="mr-2">fas fa-plus-circle</v-icon>{{ $t('PROJECT_SHEET.TARGET_ENDTIME') }}
              </v-btn>
            </template>
          </div>
          <div v-if="userIsProjectAdmin || (targetEndtime && targetBudget)" class="mx-2"></div>
          <div v-if="userIsProjectAdmin || targetBudget" :class="{ 'export-hidden': ! targetBudget }" class="display-flex align-center flex-grow-1">
            <v-icon small class="mr-2" style="width: 12px; height: 32px;">fas fa-euro-sign</v-icon>
            <template v-if="targetBudgetEditing">
              <v-text-field v-model.number="targetBudget" type="number" hide-details class="pa-0 ma-0" style="max-width: 110px"></v-text-field>
              <v-btn color="accent" rounded small class="mx-2" @click="saveTarget('targetBudget')">{{ $t('GLOBAL.SAVE') }}</v-btn>
              <v-btn color="secondary" rounded small outlined class="mr-2" style="min-width: 0" @click="resetTarget('targetBudget')"><v-icon small>fas fa-times</v-icon></v-btn>
            </template>
            <template v-else-if="targetBudget">
              <div>{{ $t('PROJECT_SHEET.TARGET_BUDGET') }} : <span class="nobr">{{ targetBudget | number }}</span></div>
              <v-btn v-if="userIsProjectAdmin" icon small class="ml-2 export-hidden" @click="targetBudgetEditing = ! targetBudgetEditing">
                <v-icon color="secondary" size="14">fas fa-edit</v-icon>
              </v-btn>
            </template>
            <template v-else>
              <v-btn color="secondary" small rounded outlined @click="targetBudgetEditing = true">
                <v-icon size="14" class="mr-2">fas fa-plus-circle</v-icon>{{ $t('PROJECT_SHEET.TARGET_BUDGET') }}
              </v-btn>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import clientConfig from '@/client_customs/config';
  import Summernote from '@/components/Reusables/Summernote';

  export default {
    components: {
      Summernote,
    },
    props: {
      planning: { type: Object, required: true },
    },
    data() {
      const projectsheet = this.planning.projectsheet || {};
      const userIsProjectAdmin = this.planning.meta.access_right == 'admin';
      return {
        userIsProjectAdmin,
        description: projectsheet.description || "",
        descriptionEditing: ! userIsProjectAdmin ? false : ! projectsheet.description,
        defaultDescription: clientConfig.projectsheet && clientConfig.projectsheet.defaultDescription || "",
        targetBudget: projectsheet.target_budget || '',
        targetBudgetEditing: false,
        targetEndtime: projectsheet.target_endtime && moment(projectsheet.target_endtime) || null,
        targetEndtimeEditing: false,
      };
    },
    methods: {
      resetDescription() {
        const projectsheet = this.planning.projectsheet || {};
        this.description = projectsheet.description || "";
        this.descriptionEditing = false;
      },
      saveDescription() {
        this.$emit('update', {
          description: this.description,
        });
        this.descriptionEditing = false;
      },
      resetTarget(targetName) {
        const projectsheet = this.planning.projectsheet || {};
        if (targetName == 'targetBudget') {
          this.targetBudget = projectsheet.target_budget || '';
          this.targetBudgetEditing = false;
        }
        if (targetName == 'targetEndtime') {
          this.targetEndtime = projectsheet.target_endtime || null;
          this.targetEndtimeEditing = false;
        }
      },
      saveTarget(targetName) {
        if (targetName == 'targetBudget') {
          this.$emit('update', { target_budget: this.targetBudget });
          this.targetBudgetEditing = false;
        }
        if (targetName == 'targetEndtime') {
          this.$emit('update', { target_endtime: this.targetEndtime && this.targetEndtime.format() });
          this.targetEndtimeEditing = false;
        }
      },
    },
  };
</script>
