<template>
  <div v-if="displayLoginWindow">
    <modal :closable="false" :size="modalSize" :backdrop-opacity=".8">
      <login-window @navigate="navigate"></login-window>
    </modal>
  </div>
</template>

<script>
  import LoginWindow from './LoginWindow';

  export default {
    components: {
      LoginWindow,
    },
    data() {
      return {
        modalSize: 'sm',
      };
    },
    computed: {
      displayLoginWindow() {
        return this.$store.state.ui.displayLoginWindow;
      },
    },
    methods: {
      navigate(section) {
        this.modalSize = section == 'register' ? 'md' : 'sm';
      },
    },
  };
</script>
