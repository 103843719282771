<template>
  <div id="table-header" v-fix-to-top="{ placeholder: 'table-header-placeholder', topbar: '#topbar', xScroll: '#table-wrapper' }"
       class="notextselect"
       @mouseover="$store.state.ui.planning.hovertimeline = true" @mouseout="$store.state.ui.planning.hovertimeline = false">
    <div :style="{'margin-top': snapshot ? '8px' : ''}" :class="{ 'single-timeline': ! visibleTimeline.subtimelinecols.length }" class="timeline">
      <div v-for="(col, colIndex) in visibleTimeline.timelinecols" :style="{ width:col.width + 'px' }" :title="datesTitle(col)" class="col-header designcolor-main-bg">
        <span>{{ col.width >= 80 ? col.label : '&nbsp;' }}</span>
        <div v-if="dynamic" class="col-menu">
          <div v-if="! colIndex" :title="$t('PLANNING.ADD_NEW_COLUMN_BEFORE')" class="col-context-menu pull-left pointer" @click="addCol({ where: 'first', stepIndex: 0 })">
            <span class="fa fa-plus iconshadow"></span>
          </div>
          <div v-if="! colIndex" :title="$t('PLANNING.HIDE_COLUMN')" class="col-context-menu col-context-menu-hide pull-right pointer" @click="hideCol({ where: 'first', stepIndex: 0 })">
            <span class="fa fa-minus iconshadow"></span>
          </div>
          <div v-if="colIndex == visibleTimeline.timelinecols.length - 1"
               :title="$t('PLANNING.ADD_NEW_COLUMN_AFTER')" class="col-context-menu pull-right pointer"
               @click="addCol({ where: 'last', stepIndex: 0 })">
            <span class="fa fa-plus iconshadow"></span>
          </div>
          <div v-if="colIndex == visibleTimeline.timelinecols.length - 1"
               :title="$t('PLANNING.HIDE_COLUMN')" class="col-context-menu col-context-menu-hide pull-left pointer"
               @click="hideCol({ where: 'last', stepIndex: 0 })">
            <span class="fa fa-minus iconshadow"></span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="visibleTimeline.subtimelinecols.length" class="timeline-divider"></div>
    <div class="subtimeline">
      <div v-for="(subcol, subColIndex) in visibleTimeline.subtimelinecols" :style="{ width:subcol.width + 'px' }" :title="datesTitle(subcol)" class="col-header designcolor-second-bg">
        <span>{{ (! subcol.colspan || subcol.colspan > .6) ? subcol.label : '&nbsp;' }}</span>
        <div v-if="dynamic" class="col-menu">
          <div v-if="! subColIndex" :title="$t('PLANNING.ADD_NEW_COLUMN_BEFORE')" class="col-context-menu pull-left pointer" @click="addCol({ where: 'first', stepIndex: 1})">
            <span class="fa fa-plus iconshadow"></span>
          </div>
          <div v-if="! subColIndex" :title="$t('PLANNING.HIDE_COLUMN')" class="col-context-menu col-context-menu-hide pull-right pointer" @click="hideCol({ where: 'first', stepIndex: 1 })">
            <span class="fa fa-minus iconshadow"></span>
          </div>
          <div v-if="subColIndex == visibleTimeline.subtimelinecols.length - 1"
               :title="$t('PLANNING.ADD_NEW_COLUMN_AFTER')" class="col-context-menu pull-right pointer"
               @click="addCol({ where: 'last', stepIndex: 1})">
            <span class="fa fa-plus iconshadow"></span>
          </div>
          <div v-if="subColIndex == visibleTimeline.subtimelinecols.length - 1"
               :title="$t('PLANNING.HIDE_COLUMN')" class="col-context-menu col-context-menu-hide pull-left pointer"
               @click="hideCol({ where: 'last', stepIndex: 1 })">
            <span class="fa fa-minus iconshadow"></span>
          </div>
        </div>
      </div>
    </div>
    <planning-timeline-scrollbar v-if="! snapshot" :visible-timeline="visibleTimeline"></planning-timeline-scrollbar>
    <div v-if="showPresentTime" :style="{ left: visibleTimeline.presenttimeWidth + 'px' }" :title="$t('GLOBAL.TODAY')" class="presenttime">
      <i class="fa fa-caret-down"></i>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import PlanningTimelineScrollbar from './PlanningTimelineScrollbar';

  export default {
    components: {
      PlanningTimelineScrollbar,
    },
    props: {
      visibleTimeline: { type: Object, required: true },
      dynamic: { type: Boolean, default: false },
    },
    data() {
      return {
      };
    },
    computed: {
      showPresentTime() {
        return this.visibleTimeline.show_presenttime && this.visibleTimeline.presenttimeWidth > 0 && this.visibleTimeline.presenttimeWidth <= this.visibleTimeline.pxwidth;
      },
      snapshot() {
        return this.$store.state.ui.planning.snapshot;
      },
    },
    methods: {
      datesTitle(col) {
        return `${this.$options.filters.moment(col.starttime, 'mediumDateNoYear', true)} - ${this.$options.filters.moment(col.endtime, 'mediumDateNoYear', true)}`;
      },
      ...mapActions('planning/timeline', ['addCol', 'hideCol']),
    },
  };
</script>
