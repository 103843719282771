let undoState = {};

function undoredoMeta(context) {
  const { state: meta, dispatch } = context;
  if (angular.equals(meta, undoState)) return;
  const newState = angular.copy(meta);
  const oldState = angular.copy(undoState);
  dispatch('undoredo/add', {
    action: () => {
      meta.set(angular.copy(newState));
    },
    rollback: () => {
      meta.set(angular.copy(oldState));
    },
  }, { root: true });
  dispatch('planning/save', null, { root: true });
}

/* ACTIONS */
function startChangingMeta(context) {
  const { state: meta } = context;
  undoState = angular.copy(meta);
}

function changingMeta(context) {
  const { state: meta } = context;
  if (! angular.equals(meta, undoState)) {
    undoredoMeta(context);
  }
}

export default {
  namespaced: true,
  state: {},
  actions: {
    startChangingMeta,
    changingMeta,
  },
};
