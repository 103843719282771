/* eslint no-unused-vars: 0 */
import microsoft from './microsoft';
import google from './google';

export default {
  namespaced: true,
  modules: {
    microsoft,
    google,
  },
  state: {
  },
  getters: {

  },
  actions: {

  },
  mutations: {

  },
};
