import { getDefaultTimeline } from './helpers/defaultValues';
import helpers from './helpers/planningInitHelpers';

class PlanningTimeline {
  constructor(srcData) {
    const data = angular.copy(srcData) || {};

    if (data instanceof PlanningTimeline) {
      _.extend(this, data);
    } else {
      helpers.updateLastVersionsTimeline(data);
      _.extend(this, getDefaultTimeline(), data);
    }
  }

  getAll() {
    const nonNullData = {};
    Object.keys(this).forEach((key) => { if (this[key] !== null) nonNullData[key] = this[key]; });
    return nonNullData;
  }

  set(data) {
    _.extend(this, data);
    return this;
  }

  /** ********** */
  /* GET / SET */
  /** ********** */
  getHiddenBefore() {
    return this.hidden && this.hidden.before && moment(this.hidden.before);
  }
  setHiddenBefore(value) {
    if (moment.isMoment(value)) value = value.format();
    if (! this.hidden) this.hidden = {};
    this.hidden.before = value;
    return this;
  }

  getHiddenAfter() {
    return this.hidden && this.hidden.after && moment(this.hidden.after);
  }
  setHiddenAfter(value) {
    if (moment.isMoment(value)) value = value.format();
    if (! this.hidden) this.hidden = {};
    this.hidden.after = value;
    return this;
  }

  getColWidth() {
    return this.colwidth;
  }
  setColWidth(value) {
    this.colwidth = value;
  }

  getSteps() {
    return this.steps;
  }
  setSteps(value) {
    this.steps = value || getDefaultTimeline().steps.slice();
  }
  getLastStep() {
    return this.steps.last();
  }

  getWorkdays() {
    return this.workdays;
  }

  getTimelocale() {
    return this.timelocale || "";
  }

  getShowPresenttime() {
    return this.show_presenttime;
  }

  getShowTimegrid() {
    return this.show_timegrid;
  }

  /** ********** */
  /* HELPERS */
  /** ********** */
  static bestColWidth(starttime, endtime, steps, workdays) {
    const timelineduration = moment(endtime).diffWithWorkdays(starttime, 'minutes', workdays);

    // Auto timeline width calculation -> closest from possible values for 1800/cols number
    const timelinelength = moment.duration(1, (steps || []).last()).asMinutes();
    const nbcols = Math.ceil(timelineduration / timelinelength);
    const goal = 1800 / nbcols;

    return [100, 160, 230, 300].reduce((prev, curr) => (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev)); // 50 excluded from auto selection
  }

  bestColWidth(starttime, endtime, steps, workdays) {
    return PlanningTimeline.bestColWidth(starttime || this.starttime, endtime || this.endtime, steps || this.steps, workdays || this.workdays);
  }
}

export default PlanningTimeline;
