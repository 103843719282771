import lang from '@/store/lang';

const VueI18n = lang.state.i18n;

export default {
  projectStatus() {
    return {
      consolidation: {
        label: VueI18n.t('PROJECTS.STATUS.CONSOLIDATIONS'),
        readonly: false,
        nodashboards: true,
        template: false,
      },
      template: {
        label: VueI18n.t('PROJECTS.STATUS.TEMPLATES'),
        readonly: true,
        nodashboards: true,
        template: true,
      },
      draft: {
        label: VueI18n.t('PROJECTS.STATUS.DRAFTS'),
        readonly: false,
        nodashboards: true,
        template: false,
      },
      completed: {
        label: VueI18n.t('PROJECTS.STATUS.COMPLETED'),
        readonly: true,
        nodashboards: false,
        template: false,
      },
    };
  },
  budgetStates() {
    return {
      draft: VueI18n.t('ELEMENT-DETAIL.DRAFT'),
      quotation: VueI18n.t('ELEMENT-DETAIL.QUOTATION'),
      charged: VueI18n.t('ELEMENT-DETAIL.CHARGED'),
      paid: VueI18n.t('ELEMENT-DETAIL.PAID'),
    };
  },
};
