<template>
  <div :style="{ width: thumbnailEditing ? '250px' : '120px'}" class="project-thumbnail-btn-trigger">
    <img v-if="! thumbnailEditing" :src="planning.meta.imgurl" :alt="$t('PROJECT_SHEET.IMAGE_UNAVAILABLE')" class="project-thumbnail-img">
    <div class="project-thumbnail-btns">
      <v-btn v-if="! thumbnailEditing" small fab @click="thumbnailEditing=true">
        <v-icon color="primary" small>fas fa-edit</v-icon>
      </v-btn>
      <v-spacer class="my-1"></v-spacer>
      <v-btn v-if="! thumbnailEditing && ! isExportImg" small fab @click="deleteThumbnail()">
        <v-icon color="errorred" small>fas fa-trash-alt</v-icon>
      </v-btn>
    </div>

    <div v-if="thumbnailEditing">
      <div class="pb-3">
        <div style="display: inline-block; position: relative">
          <croppa v-model="croppa" :placeholder="$t('ACCOUNT.SELECT_IMAGE')" :placeholder-font-size="16" :zoom-speed="7" :file-size-limit="20971520"
                  :quality="1" accept="image/*" :width="250">
          </croppa>
          <div style="position: absolute; bottom: 0; right: -8px">
            <i :class="chosenFile ? 'pointer' : 'text-muted'" class="fa fa-lg fa-search-plus" @click="zoomIn()">
            </i><i :class="chosenFile ? 'pointer' : 'text-muted'" class="fa fa-lg fa-search-minus ml-2" @click="zoomOut()"></i>
          </div>
        </div>
      </div>
      <div class="display-flex justify-center">
        <v-btn color="secondary" rounded outlined @click="thumbnailEditing = false">{{ $t('GLOBAL.CANCEL') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn :disabled="! chosenFile" color="accent" rounded @click="uploadThumbnail()">
          <v-icon small class="mr-2">fas fa-upload</v-icon> {{ $t('GLOBAL.SAVE') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<style>
  .project-thumbnail-btn-trigger {
    position: relative;
    flex: 0 0 auto;
    transition: all .3s;
  }

  .project-thumbnail-btns {
    position: absolute;
    top: 5px;
    right: 5px;
    visibility: hidden;
    opacity: 0;
    transition: opacity .3s;
  }
  .project-thumbnail-btn-trigger:hover .project-thumbnail-btns {
    visibility: visible;
    opacity: 1;
  }

  .project-thumbnail-img {
    width: 100%;
    min-height: 50px;
    max-height: 120px;
    text-indent: 4px;
    border: .5px solid #eee;
  }
</style>

<script>
  import Croppa from 'vue-croppa';
  import 'vue-croppa/dist/vue-croppa.css';

  export default {
    components: {
      Croppa: Croppa.component,
    },
    props: {
      planning: { type: Object, required: true },
    },
    data() {
      return {
        croppa: {},
        thumbnailEditing: false,
      };
    },
    computed: {
      chosenFile() {
        return this.croppa.getChosenFile && this.croppa.getChosenFile();
      },
      isExportImg() {
        return ! this.planning.meta.imgurl || this.planning.meta.imgurl.startsWith(window.apiSrv.snapBaseUrl);
      },
    },
    methods: {
      zoomIn() {
        if (! this.chosenFile) return;
        this.croppa.zoomIn();
      },
      zoomOut() {
        if (! this.chosenFile) return;
        this.croppa.zoomOut();
      },
      uploadThumbnail() {
        if (! this.chosenFile) return;
        this.croppa.generateBlob((blob) => {
          if (! blob) return;
          blob.name = `${this.chosenFile.name.replace(/\..{3,4}$/, '')}.png`;
          window.apiSrv.uploadResource(blob).then((response) => {
            const id = response && response.data && response.data.file && response.data.file.id;
            if (! id) return Promise.reject(new Error('invalid upload'));
            return window.apiSrv.call('plannings', 'update', { id: this.planning.id, imageexport_name: response.data.file.url }).then(() => {
              this.planning.meta.imgurl = response.data.file.url;
            });
          });
          this.thumbnailEditing = false;
        }, 'image/png');
      },
      deleteThumbnail() {
        window.apiSrv.call('plannings', 'update', { id: this.planning.id, imageexport_name: "" }).then(() => {
          this.planning.meta.imgurl = "";
        });
      },
    },
  };
</script>
