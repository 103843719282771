<template>
  <section class="help-tips modal-with-tabs">
    <div class="modal-with-tabs-header primary--text pt-4 pb-2" style="border-bottom: 3px solid">
      <div class="display-flex align-center text-center" style="width: 100%">
        <div class="flex-grow-1">
          <h3 class="pointer darker-bg darker-bg-hover arrow-button ml-auto"
              @click="currentIndex = (currentIndex - 1 + slides.length) % slides.length">
            <i class="fa fa-arrow-left"></i>
          </h3>
        </div>
        <div class="flex-grow-1">
          <span class="text-h4">{{ $t('HELP.TIP') }} {{ (currentIndex + 1) + ' / ' + slides.length }}</span>
        </div>
        <div class="flex-grow-1">
          <h3 class="pointer darker-bg darker-bg-hover arrow-button"
              @click="currentIndex = (currentIndex + 1) % slides.length">
            <i class="fa fa-arrow-right"></i>
          </h3>
        </div>
      </div>
    </div>
    <div class="py-2 text-center" style="max-height: 90vh; flex: 1 1 auto; overflow-y: auto; margin-bottom: 6px">
      <img :src="slides[currentIndex].url" style="max-width:100%">
    </div>
  </section>
</template>

<style>
  .help-tips .arrow-button {
    height: 42px;
    width: 42px;
    line-height: 40px;
    border-radius: 50%;
    margin: 0;
  }

  .darker-bg {
    background: rgba(0,0,0,.15);
  }
  .darker-bg-hover:hover {
    background: rgba(0,0,0,.3);
  }
</style>

<script>
  const tips1 = require('./img/tips/1.jpg');
  const tips2 = require('./img/tips/2.jpg');
  const tips3 = require('./img/tips/3.jpg');
  const tips4 = require('./img/tips/4.jpg');
  const tips5 = require('./img/tips/5.jpg');
  const tips6 = require('./img/tips/6.jpg');

  export default {
    data() {
      return {
        currentIndex: 0,
        slides: [{
          url: tips1,
        }, {
          url: tips2,
        }, {
          url: tips3,
        }, {
          url: tips4,
        }, {
          url: tips5,
        }, {
          url: tips6,
        }],
      };
    },
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>
