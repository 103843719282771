<template>
  <div>
    <navigation>
      <v-spacer></v-spacer>
      <template v-if="isPremium">
        <v-btn rounded small depressed class="mx-1" style="height: 32px" @click="csvExport()">
          <v-icon color="primary" small class="mr-2">far fa-file-excel</v-icon>
          {{ $t('NAV.EXPORT') }} CSV
        </v-btn>
      </template>
      <template v-if="isPremium">
        <v-btn :disabled="exporting.inprogress" rounded small depressed class="mx-1" style="height: 32px" @click="! exporting.inprogress && pdfExport()">
          <v-icon color="primary" small class="mr-2">{{ exporting.inprogress ? 'fas fa-spinner fa-spin' : 'far fa-file-pdf' }}</v-icon>
          {{ $t('NAV.EXPORT') }} PDF
        </v-btn>
      </template>
    </navigation>

    <div v-if="isPremium">
      <v-progress-linear v-if="! loading.complete" :value="loading.counter.toLoad && loading.counter.loaded ? loading.counter.loaded / loading.counter.toLoad * 100 : 2"
                         color="accent" rounded>
      </v-progress-linear>

      <dashboards-filters v-if="loading.complete" :filters="['Projects', 'Dates']">
        <template #append-projects>
          <v-switch v-model="portfolioOptions.displayArchived" hide-details class="ml-4">
            <template #label><small style="line-height: 1.2em">{{ $t('MULTIPROJECTS.DISPLAY_ARCHIVED_PROJECTS') }}</small></template>
          </v-switch>
          <v-divider vertical class="mx-2 mx-lg-4"></v-divider>
          <project-owners-filter :archived-projects="archivedProjects"></project-owners-filter>
        </template>
        <template #default>
          <v-divider vertical class="mx-2 mx-lg-4"></v-divider>
          <portfolio-display-filter :headers="headers"></portfolio-display-filter>
        </template>
      </dashboards-filters>

      <v-container v-if="loading.complete" v-show="viewIsReady" id="portfolio-body" class="white" fluid>
        <div class="display-flex" style="flex-wrap: wrap">
          <v-card class="py-4 px-6 mx-2 mb-4 display-flex align-center">
            <div class="text-h6">
              <div class="text-h2 primary--text">{{ filteredProjects.length }}</div>
              {{ $tc('PORTFOLIO.PROJECTS_PLURAL', filteredProjects.length) }}
            </div>
            <div v-if="viewIsReady && allStats.moodData.total" class="ml-4"
                 style="position: relative; width: 150px; height: 150px; margin-right: -50px; margin-bottom: -50px">
              <chart-js :config="moodChart" :width="150" :height="150"></chart-js>
              <div style="position: absolute; top: 0; left: 0; width: 100px; height: 100px; pointer-events: none">
                <div v-for="(moodIcon, moodIndex) in moodIconOptions" v-if="allStats.moodData[moodIcon]"
                     :key="moodIndex" :style="chartMoodLabelPosition(moodIndex)"
                     style="position: absolute; width: 32px; height: 32px; padding: 0px 2px 2px">
                  <div :class="'mood-icon-' + moodIcon"></div>
                </div>
              </div>
            </div>
            <template v-if="viewIsReady && allStats.endtimeData.total">
              <v-divider vertical class="mx-4"></v-divider>
              <div class="text-h6">
                <div class="text-h2 primary--text">{{ allStats.endtimeData.overdue || 0 }}</div>
                {{ $tc('PORTFOLIO.OVERDUES_PLURAL', allStats.endtimeData.overdue) }}
              </div>
              <div class="ml-2" style="position: relative; width: 150px; height: 150px; margin-right: -50px; margin-bottom: -50px">
                <chart-js :config="endtimeChart" :width="150" :height="150"></chart-js>
              </div>
            </template>
            <template v-if="viewIsReady && allStats.budgetData.total">
              <v-divider vertical class="mx-4"></v-divider>
              <div class="text-h6">
                <div class="text-h2 primary--text">{{ allStats.budgetData.out || 0 }}</div>
                {{ $t('PORTFOLIO.OUT_OF_BUDGET') }}
              </div>
              <div class="ml-2" style="width: 150px; height: 150px; margin-right: -50px; margin-bottom: -50px">
                <chart-js :config="budgetChart" :width="150" :height="150"></chart-js>
              </div>
            </template>
          </v-card>
          <v-card class="py-4 px-6 mx-2 mb-4 display-flex align-center">
            <div class="text-h6">
              <div class="text-h2 primary--text">{{ allStats.actionsData.total || 0 }}</div>
              {{ $tc('PORTFOLIO.ACTIONS_PLURAL', allStats.actionsData.total) }}
            </div>
            <div v-if="viewIsReady && allStats.actionsData.total" class="ml-2" style="width: 150px; height: 150px; margin-right: -50px; margin-bottom: -50px">
              <chart-js :config="actionsChart" :width="150" :height="150"></chart-js>
            </div>
          </v-card>
          <v-card class="py-4 px-6 mx-2 mb-4 display-flex align-center">
            <div class="text-h6">
              <div class="text-h2 primary--text">{{ allStats.progressData.total || 0 }}</div>
              {{ $tc('PORTFOLIO.BUBBLES_PLURAL', allStats.progressData.total) }}
            </div>
            <div v-if="viewIsReady && allStats.progressData.total" class="ml-2" style="width: 150px; height: 150px; margin-right: -50px; margin-bottom: -50px">
              <chart-js :config="progressChart" :width="150" :height="150"></chart-js>
            </div>
          </v-card>
        </div>
        <v-data-table :headers="filteredHeaders" :items="filteredProjects" :custom-sort="customSort" :options="tableOptions"
                      :header-props="{ 'sort-icon': 'fas fa-caret-up ml-1' }" hide-default-footer must-sort show-expand single-expand
                      @update:options="savePortfolioTableOptions">
          <template #item="{ item: project, expand, isExpanded }">
            <portfolio-row :filtered-headers="filteredHeaders" :row-data="project" :is-expanded="isExpanded" @expand="expand(! isExpanded)">
            </portfolio-row>
          </template>
          <template #expanded-item="{ item: project }">
            <portfolio-row v-for="lane in projectLanes(project.id)" :key="lane.id" :filtered-headers="filteredHeaders" :row-data="lane" is-lane>
            </portfolio-row>
          </template>
        </v-data-table>
      </v-container>
      <div v-if="loading.complete && ! viewIsReady" class="text-center pa-4" style="font-size: 18px">
        <i class="fas fa-spinner fa-spin fa-2x fa-fw"></i>
      </div>
    </div>

    <div v-if="userLoaded && ! isPremium">{{ $t('PREMIUM.SECTION_IS_PREMIUM') }}</div>
  </div>
</template>

<style>
  .v-data-table thead th.sortable .v-icon.fa-caret-up {
    vertical-align: bottom;
  }

  #portfolio-body .v-data-table > .v-data-table__wrapper tbody tr:first-child:hover td:first-child {
      border-top-left-radius: 0px;
  }
</style>

<script>
  import { mapState } from 'vuex';
  import workloadMixin from '@/components/Workload/workloadMixin';
  import ProjectSrv from '@/components/Projects/ProjectSrv';
  import Navigation from '@/components/Navigation/Navigation';
  import ChartJs from '@/components/Reusables/ChartJs';
  import DashboardsFilters from '../DashboardsFilters/DashboardsFilters';
  import ProjectOwnersFilter from '../DashboardsFilters/ProjectOwnersFilter';
  import PortfolioDisplayFilter from '../DashboardsFilters/PortfolioDisplayFilter';
  import PortfolioRow from './PortfolioRow';
  import portfolioChartsMixin from './portfolioChartsMixin';

  export default {
    components: {
      Navigation,
      ChartJs,
      DashboardsFilters,
      ProjectOwnersFilter,
      PortfolioDisplayFilter,
      PortfolioRow,
    },
    mixins: [portfolioChartsMixin, workloadMixin],
    data() {
      const portfolioTableOptions = window.safeParseJSON(window.localStorageWrapper.getItem('portfolioTableOptions')) || {};

      return {
        headers: [
          { text: this.$t('PORTFOLIO.MOOD'), value: 'mood', width: '0px', class: 'px-2 nobr', csvCols: ['moodIcon'] },
          { text: this.$t('PORTFOLIO.CATEGORY'), value: 'category', class: 'px-3 nobr', csvCols: ['category'] },
          { text: this.$t('PORTFOLIO.PROJECT'), value: 'title', class: 'px-3 nobr', csvCols: ['title'] },
          { text: this.$t('PORTFOLIO.OWNER'), value: 'owner', class: 'px-3 nobr', csvCols: ['company', 'ownerUsername'] },
          { text: this.$t('PORTFOLIO.STATUS'), value: 'status', class: 'px-3 nobr', csvCols: ['updated_at', 'archived_at'] },
          { text: this.$t('PORTFOLIO.DURATION'), value: 'dates', class: 'px-3 nobr', csvCols: ['datesData.start', 'datesData.end'] },
          { text: this.$t('PORTFOLIO.ENDTIME'), value: 'endtime', class: 'px-3 nobr', csvCols: [] },
          {
            text: this.$t('PORTFOLIO.ACTIONS'),
            value: 'actions',
            class: 'px-3 nobr',
            csvCols: ['actionsData.total', 'actionsData.finished', 'actionsData.urgent', 'actionsData.overdue', 'actionsData.future'],
          },
          { text: this.$t('PORTFOLIO.WORKLOAD'), value: 'workload', class: 'px-3 nobr', csvCols: ['workloadData.estimated', 'workloadData.used'] },
          {
            text: this.$t('PORTFOLIO.PROGRESS'),
            value: 'progress',
            class: 'px-3 nobr',
            csvCols: ['progressData.total', 'progressData.finished', 'progressData.inprogress', 'progressData.overdue', 'progressData.future'],
          },
          { text: this.$t('PORTFOLIO.USERS'), value: 'users', class: 'px-3 nobr', csvCols: ['users'] },
          { text: this.$t('PORTFOLIO.BUDGET'), value: 'budget', class: 'px-3 nobr', csvCols: ['budget'] },
        ],
        archivedProjects: [],
        viewIsReady: false,
        tableOptions: {
          itemsPerPage: -1,
          sortBy: portfolioTableOptions.sortBy || ['title'],
          sortDesc: portfolioTableOptions.sortDesc || [false],
        },
        exporting: { inprogress: false, success: false, error: false },
      };
    },
    computed: {
      filteredHeaders() {
        return this.headers.filter(col => this.portfolioOptions.selectedCols[col.value]);
      },
      allProjects() {
        return this.projects.concat(this.archivedProjects).map((project) => {
          const projectsheetCurrentStep = project.projectsheet && project.projectsheet.steps && project.projectsheet.steps.last();
          const ownerData = this.$store.getters['users/getUserById'](project.meta ? project.meta.owner_id : project.owner_id);
          const company = this.company(project);
          const datesData = this.dates(project.elements);
          const actionsData = this.actions(project.elements);
          const workloadData = this.workload(project.elements);
          const progressData = this.progress(project.elements);
          const usersData = this.users(project.elements);
          const budgetData = this.budget(project.elements);
          return {
            id: project.id,
            title: (project.getTitle ? project.getTitle() : project.title) || this.$t('PLANNING.UNNAMED_PROJECT'),
            category: project.meta && project.meta.category || null,
            access_right: project.meta ? project.meta.access_right : project.access_right,
            owner: ownerData ? `${company}.${this.$store.getters['users/getUsername'](ownerData)}` : null,
            ownerData,
            ownerUsername: ownerData ? this.$store.getters['users/getUsername'](ownerData) : null,
            company,
            mood: (['storm', 'cloud', 'suncloud', 'sun'].indexOf(projectsheetCurrentStep && projectsheetCurrentStep.mood) + 1) || null,
            moodIcon: projectsheetCurrentStep && projectsheetCurrentStep.mood,
            waypoint: projectsheetCurrentStep && projectsheetCurrentStep.waypoint,
            status: project.deleted_at ? `0-${moment(project.deleted_at).format('YYYY-MM-DD')}` : moment(project.meta && project.meta.date_of_modification || null).format('YYYY-MM-DD'),
            updated_at: moment(project.meta && project.meta.date_of_modification || null),
            archived_at: project.deleted_at ? moment(project.deleted_at) : '',
            dates: datesData.start && datesData.end && moment.duration(datesData.end.diff(datesData.start)) || null,
            duration: datesData.start && datesData.end && moment.duration(datesData.end.diff(datesData.start)) || null,
            datesData,
            endtime: datesData.end && moment(datesData.end).format('YYYY-MM-DD') || null,
            endtimeTarget: project.projectsheet && project.projectsheet.target_endtime,
            actions: actionsData.total ? actionsData.finished / actionsData.total : null,
            actionsData,
            workload: workloadData.estimated ? workloadData.used / workloadData.estimated : null,
            workloadData,
            progress: progressData.total ? progressData.finished / progressData.total : null,
            progressData,
            users: Object.keys(usersData).length,
            usersData,
            budget: Object.keys(budgetData).reduce((acc, icon) => acc + (budgetData[icon].amount || budgetData[icon].amount_inprogress), 0),
            budgetData,
            budgetTarget: project.projectsheet && project.projectsheet.target_budget,
          };
        });
      },
      filteredProjects() {
        return this.allProjects.filter((project) => {
          if (! project.archived_at && ! this.selectedProjects.find(item => item == project.id)) return false;
          if (this.selectedDates.starttime || this.selectedDates.endtime) {
            if (project.archived_at && this.selectedDates.starttime && project.archived_at.isBefore(this.selectedDates.starttime)) return false;
            if (project.archived_at && this.selectedDates.endtime && project.archived_at.isAfter(this.selectedDates.endtime)) return false;
            let projectEndTime = project.endtime && moment(project.endtime);
            if (project.endtimeTarget) projectEndTime = projectEndTime ? moment.max(projectEndTime, moment(project.endtimeTarget)) : moment(project.endtimeTarget);
            if (! projectEndTime) return false;
            if (this.selectedDates.starttime && projectEndTime.isBefore(this.selectedDates.starttime)) return false;
            if (this.selectedDates.endtime && projectEndTime.startOf('day').isAfter(this.selectedDates.endtime)) return false;
          }
          if (project.archived_at && ! this.portfolioOptions.displayArchived) return false;
          if (project.ownerData && project.ownerData.id && ! this.portfolioOptions.selectedProjectOwners.find(item => item == project.ownerData.id)) return false;
          return true;
        });
      },
      allStats() {
        return this.filteredProjects.reduce((acc, project) => {
          if (project.moodIcon) {
            if (! acc.moodData[project.moodIcon]) acc.moodData[project.moodIcon] = 0;
            if (! acc.moodData.total) acc.moodData.total = 0;
            acc.moodData[project.moodIcon]++;
            acc.moodData.total++;
          }
          if (project.endtimeTarget && project.datesData.end) {
            if (! acc.endtimeData.total) acc.endtimeData.total = 0;
            const status = project.datesData.end.isAfter(project.endtimeTarget) ? 'overdue' : 'intime';
            acc.endtimeData[status] = (acc.endtimeData[status] || 0) + 1;
            acc.endtimeData.total = (acc.endtimeData.total || 0) + 1;
          }
          if (project.budgetTarget) {
            const maxBudget = Object.keys(project.budgetData).reduce((maxVal, icon) => Math.max(maxVal, project.budgetData[icon].amount_inprogress, project.budgetData[icon].amount), 0);
            const status = maxBudget <= project.budgetTarget ? 'in' : 'out';
            acc.budgetData[status] = (acc.budgetData[status] || 0) + 1;
            acc.budgetData.total = (acc.budgetData.total || 0) + 1;
          }
          Object.keys(project.actionsData).forEach((key) => {
            if (! acc.actionsData[key]) acc.actionsData[key] = 0;
            acc.actionsData[key] += project.actionsData[key];
          });
          Object.keys(project.progressData).forEach((key) => {
            if (! acc.progressData[key]) acc.progressData[key] = 0;
            acc.progressData[key] += project.progressData[key];
          });
          return acc;
        }, { moodData: {}, endtimeData: {}, budgetData: {}, actionsData: {}, progressData: {} });
      },
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
      userLoaded() { return this.$store.state.users.user.id > 0; },
      ...mapState('multiprojects', ['loading', 'projects', 'selectedProjects', 'selectedDates', 'portfolioOptions']),
    },
    watch: {
      'loading.complete': function (newVal) {
        if (! newVal) {
          this.viewIsReady = false;
        } else {
          this.$store.state.users.userPromise.then(() => {
            this.viewIsReady = true;
          });
        }
      },
    },
    created() {
      this.$store.commit('multiprojects/loadFilters', { dashboardName: this.$route.path.slice(1).replace(/\//g, '_') });
      this.$store.dispatch('multiprojects/load');
      this.loadArchivedProjects();
    },
    methods: {
      projectLanes(projectId) {
        const project = this.projects.find(item => item.id == projectId);
        return (project && project.lanes || []).map((lane) => {
          const laneElements = this.laneElements(project, lane);
          const datesData = this.dates(laneElements);
          const actionsData = this.actions(laneElements);
          const workloadData = this.workload(laneElements);
          const progressData = this.progress(laneElements);
          const usersData = this.users(laneElements);
          const budgetData = this.budget(laneElements);
          return {
            id: lane.id,
            project_id: project.id,
            title: lane.label,
            duration: datesData.start && datesData.end && moment.duration(datesData.end.diff(datesData.start)) || null,
            datesData,
            endtime: datesData.end && moment(datesData.end).format('YYYY-MM-DD') || null,
            actionsData,
            workloadData,
            progressData,
            usersData,
            budgetData,
          };
        });
      },
      customSort(items, [index], [isDescending]) {
        // https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/mixins/data-iterable.js
        if (index === null) return items;
        return items.sort((a, b) => {
          let sortA = a[index];
          let sortB = b[index];
          // Check if both cannot be evaluated
          if (sortA === null && sortB === null) {
            return 0;
          }
          if (sortA === null) return 1;
          if (sortB === null) return -1;
          if (isDescending) {
            [sortA, sortB] = [sortB, sortA];
          }
          // Check if both are numbers
          /* eslint no-restricted-globals:0 */
          if (! isNaN(sortA) && ! isNaN(sortB)) {
            return sortA - sortB;
          }
          [sortA, sortB] = [sortA, sortB].map(s => (
            (s || '').toString().toLocaleLowerCase()
          ));
          if (sortA > sortB) return 1;
          if (sortA < sortB) return -1;
          return 0;
        });
      },
      dates(elements) {
        let projectstart;
        let projectend;
        (elements || []).forEach((el) => {
          projectstart = projectstart ? moment.min(projectstart, el.getStartTime()) : el.getStartTime();
          projectend = projectend ? moment.max(projectend, el.getEndTime()) : el.getEndTime();
        });
        return { start: projectstart, end: projectend };
      },
      company(project) {
        if (! this.$store.state.users.user.organization) return null;
        if (project.company_id) {
          return (this.$store.state.users.user.organization.getCompanies().find(item => item.id == project.company_id) || {}).name || '';
        }

        const ownerId = project.meta && project.meta.owner_id;
        if (! ownerId) return '';
        let companyName = '';
        this.$store.state.users.user.organization.forEachSubOrga(this.$store.state.users.user.organization, (orga) => {
          if (! orga || ! orga.company) return;
          if (orga.company.users && orga.company.users.find(user => user.id == ownerId)) {
            companyName = orga.company.name;
          }
        });
        return companyName;
      },
      actions(elements) {
        const stats = {
          finished: 0,
          urgent: 0,
          overdue: 0,
          future: 0,
          total: 0,
        };
        (elements || []).forEach((el) => {
          if (! el.getChecklist()) return;
          el.getChecklist().forEach((action) => {
            const color = el.getChecklistItemClass(action);
            if (action.checked) {
              stats.finished++;
            } else if (color === 'red') {
              stats.overdue++;
            } else if (color === 'orange') {
              stats.urgent++;
            } else {
              stats.future++;
            }
            stats.total++;
          });
        });
        return stats.total ? stats : {};
      },
      workload(elements) {
        const stats = {
          estimated: 0,
          used: 0,
        };
        (elements || []).forEach((el) => {
          if (! el.getChecklist()) return;
          el.getChecklist().forEach((action) => {
            stats.used += this.workloadToDisplay(action.workload_used || 0);
            stats.estimated += this.workloadToDisplay(action.workload || action.workload_used || 0);
          });
        });
        stats.estimated = Math.round(stats.estimated * 100) / 100;
        stats.used = Math.round(stats.used * 100) / 100;
        return (stats.estimated || stats.used) ? stats : {};
      },
      progress(elements) {
        const stats = {
          finished: 0,
          inprogress: 0,
          overdue: 0,
          future: 0,
          total: 0,
        };
        (elements || []).forEach((el) => {
          if (! el.isType('task')) return;
          let id;
          if (el.getProgress() == 100) {
            id = 'finished';
          } else if (el.getEndTime().isBefore()) {
            id = 'overdue';
          } else if (el.getStartTime().isAfter()) {
            id = 'future';
          } else {
            id = 'inprogress';
          }
          stats[id]++;
          stats.total++;
        });
        return stats.total ? stats : {};
      },
      users(elements) {
        const users = {};
        (elements || []).forEach((item) => {
          if (! item.getUsers()) return;
          item.getUsers().forEach((user) => {
            if (! user.id) return;
            users[user.id] = this.$store.getters['users/getUserById'](user.id) || user;
          });
        });
        return users;
      },
      budget(elements) {
        const filteredBudgetElements = (elements || []).filter((el) => {
          const elBudgets = el.getBudgets();
          if (! elBudgets) return false;
          if (! elBudgets.some(budget => budget.amount || budget.amount_inprogress)) return false;
          return true;
        });

        const budgetByIcons = {};
        filteredBudgetElements.forEach((el) => {
          (el.getBudgets() || []).forEach((budget) => {
            const icon = budget.icon || '';
            if (! budgetByIcons[icon]) budgetByIcons[icon] = { amount: 0, amount_inprogress: 0 };
            budgetByIcons[icon].amount += +budget.amount || 0;
            budgetByIcons[icon].amount_inprogress += +budget.amount_inprogress || 0;
          });
        });
        return budgetByIcons;
      },
      loadArchivedProjects() {
        this.archivedProjects = [];
        ProjectSrv.listArchived().then((results) => {
          this.archivedProjects = results;
        });
      },
      laneElements(project, lane) {
        return this.$store.getters['planning/lanes/getLaneElements']({ planning: project, laneId: lane.id });
      },
      savePortfolioTableOptions(options) {
        window.localStorageWrapper.setItem('portfolioTableOptions', JSON.stringify({ sortBy: options.sortBy, sortDesc: options.sortDesc }));
      },
      csvExport() {
        const separator = ';';
        const filename = `${this.$t('PORTFOLIO.TITLE')}.csv`;
        const csvCols = this.filteredHeaders.map(col => col.csvCols || []).reduce((acc, val) => acc.concat(val), []); // reduce(...) could be replaced by flat() with ie polyfill

        let csvContent = `${csvCols.map(name => this.$t(`PORTFOLIO.CSV.${name.toUpperCase()}`)).join(separator)}\n`;
        this.filteredProjects.forEach((project) => {
          const dataString = csvCols.map((key) => {
            const subKeys = key.split('.');
            let val = project[subKeys[0]];
            for (let i = 1; i < subKeys.length; i++) {
              val = val[subKeys[i]];
            }
            if (moment.isMoment(val)) return val.isValid() ? val.format('L') : '';
            if (key == 'moodIcon' && val) return this.$t(`MOOD.${val.toUpperCase()}`);
            return val;
          }).join(separator).replace(/"/g, '""');
          csvContent += `${dataString}\n`;
        });
        if ((navigator.appName == 'Microsoft Internet Explorer') || ((navigator.appName == 'Netscape') && (new RegExp("Trident/.*rv:([0-9]{1,}[.0-9]{0,})").exec(navigator.userAgent) != null))) {
          // IE WORKAROUND
          const IEwindow = window.open();
          IEwindow.document.write(`sep=${separator}\r\n${csvContent}`);
          IEwindow.document.close();
          IEwindow.document.execCommand('SaveAs', true, filename);
          IEwindow.close();
        } else {
          const encodedUri = encodeURI(`data:text/csv;charset=utf-8,\uFEFF${csvContent}`).replace(/#/g, '%23');
          const link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", filename);
          document.body.appendChild(link); // Required for FF
          link.click();
          document.body.removeChild(link);
        }
      },
      pdfExport() {
        _.extend(this.exporting, { inprogress: true, success: false, error: false });
        const $html = document.querySelector("html").cloneNode(true);

        // Clone the canvas
        const originalCanvas = document.querySelectorAll('canvas');
        $html.querySelectorAll("canvas").forEach((el, index) => {
          const image = new Image();
          image.src = originalCanvas[index].toDataURL("image/png");
          image.style.width = '100%';
          el.outerHTML = image.outerHTML;
        });

        $html.querySelector('head').innerHTML += '<style>body { width: 1400px ! important; }</style>';
        const title = `<h1 class="primary--text text-uppercase mb-4">${this.$t('PORTFOLIO.TITLE')}</h1>`;
        const queryEl = $html.querySelector('#portfolio-body') || document.createElement("div");
        $html.querySelector('body').innerHTML = `<div style="padding: 0 20px;">${title}${queryEl.innerHTML}</div>`;
        $html.querySelector('body').classList.add('v-application');
        $html.querySelectorAll(".export-hidden").forEach((el) => { el.parentNode.removeChild(el); });
        $html.querySelectorAll(".v-data-table thead th:first-child").forEach(el => el.style.setProperty('padding', '0px')); // first column (expand)
        $html.querySelectorAll("tr").forEach(el => el.style.setProperty('break-inside', 'avoid'));
        $html.querySelectorAll(".warningorange").forEach(el => el.style.setProperty('background', '#ff9b1d'));
        $html.querySelectorAll(".successgreen").forEach(el => el.style.setProperty('background', '#00b100'));

        window.apiSrv.call('pdf', 'store', { html: $html.innerHTML, orientation: "landscape", footer: this.$t('PORTFOLIO.TITLE') }).then((response) => {
          if (response && response.data && response.data.pdfurl) {
            this.exporting.inprogress = false;
            this.exporting.success = true;
            setTimeout(() => { this.exporting.success = false; }, 3000);
            window.open(`${response.data.pdfurl}/${this.$t('PORTFOLIO.TITLE')}.pdf`, "_blank");
          }
        }).catch((message) => {
          this.exporting.inprogress = false;
          this.exporting.error = message || "Error : not exported";
        });
      },
    },
  };
</script>
