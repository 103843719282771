<template>
  <div v-if="waitForGlobalUpdateComplete" :title="`${$t('PROJECTS.LAST_MODIFICATION_BY')} ${modificationUsername}`" :style="tootipDynamicStyle" class="white--text"
       style="position: absolute; bottom: 0; right: 0; padding: 2px 5px; background: rgba(0,0,0,.6); transition: opacity .3s; z-index: 1">
    <i v-if="showSpinner" class="fas fa-spinner fa-spin" style="vertical-align: middle; margin-right: 2px"></i>
    <img v-if="modificationUser" :src="modificationUser.avatar" style="max-height: 1.8em; border-radius: 50%;">
  </div>
</template>

<script>
  export default {
    props: {
      el: { type: Object, required: true },
    },
    data() {
      return {
        tootipDynamicStyle: { margin: this.el.isType('milestone') ? '0 3px 1px 0' : null, opacity: null },
        showSpinner: true,
        waitForGlobalUpdateComplete: false,
      };
    },
    computed: {
      modificationUser() {
        return this.$store.getters['users/getUserById'](this.el.modificationUser);
      },
      modificationUsername() {
        return this.$store.getters['users/getUsername']({ id: this.el.modificationUser }, 'short');
      },
    },
    mounted() {
      setTimeout(() => { this.waitForGlobalUpdateComplete = true; });
      const elNode = document.getElementById(`el${this.el.id}`);
      if (! elNode) return;
      elNode.style.transition = 'all 1s';
      const updateHeightInterval = setInterval(() => {
        this.el.updateHeight();
      }, 300);
      setTimeout(() => {
        this.showSpinner = false;
        elNode.style.transition = 'unset';
        clearInterval(updateHeightInterval);
      }, 2000);
      setTimeout(() => {
        this.tootipDynamicStyle.opacity = 0;
        setTimeout(() => { this.el.modificationUser = null; }, 300);
      }, 4500);
    },
  };
</script>
