let config = {};
config = require(`./inli`);
config = config.default || config;

const integration = decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]integration(?:\\=([^&]*))?)?.*$", "i"), "$1"));
if (integration == 'microsoftteams') {
  config = angular.merge({}, require(`./integrations/microsoftteams`).default, config);
}

export default config;
