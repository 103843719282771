var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-combobox',{attrs:{"value":_vm.categoryTags,"items":_vm.projectCategoryTags,"label":_vm.$tc('PROJECTS.CATEGORY', 2),"hint":_vm.$t('PROJECTS.SELECT_CATEGORY'),"delimiters":[','],"multiple":"","clearable":"","hide-selected":"","persistent-placeholder":"","persistent-hint":"","dense":"","hide-no-data":""},on:{"input":_vm.updateTags},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [(item)?_c('v-chip',_vm._b({staticStyle:{"max-width":"150px","margin-top":"2px","margin-bottom":"2px"},style:({ 'background-color': _vm.getCategoryTagColor(item).bg, color: _vm.getCategoryTagColor(item).color }),attrs:{"input-value":selected,"title":item,"small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"text-ellipsis pr-2"},[_vm._v(_vm._s(item))]),_vm._v(" "),_c('v-icon',{style:({ color: _vm.getCategoryTagColor(item).color }),attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v("fas fa-times-circle")])],1):_vm._e()]}},{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('v-chip',{staticClass:"pointer",staticStyle:{"max-width":"300px"},style:({ 'background-color': _vm.getCategoryTagColor(item).bg, color: _vm.getCategoryTagColor(item).color }),attrs:{"title":item,"small":""}},[_c('span',{staticClass:"text-ellipsis"},[_vm._v(_vm._s(item))])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }