<template>
  <v-container :class="mobileview ? 'py-0' : 'pb-6'" fluid class="home-user" style="max-width: 1185px;">
    <div v-show="! errors && ! mobileview" class="mb-2">
      <v-fade-transition>
        <div v-if="user.id" class="px-2 display-flex align-center" style="flex-wrap: wrap">
          <img :src="user.avatar" :title="$t('ACCOUNT.CHANGE_AVATAR')" class="pointer mr-4" style="width: 50px; height: 50px; border-radius: 50%;"
               @click="openAccountAvatar()">
          <h3 class="uppercase my-0">{{ $t('HOME.WELCOME') }} {{ user.firstname }}</h3>
          <div v-if="user.company && user.company.logo" class="ml-auto" style="height: 50px; line-height: 50px">
            <img :src="user.company.logo" style="height: 100%; max-width: 200px">
          </div>
        </div>
      </v-fade-transition>
    </div>
    <div v-if="! dashboardInfo && ! errors" class="pa-4 text-center" style="font-size:18px"><i class="fas fa-spinner fa-spin fa-2x fa-fw"></i></div>
    <div v-if="errors">{{ errors }}</div>
    <v-fade-transition>
      <v-row v-if="dashboardInfo" class="mt-0">
        <v-col v-if="! dashboardInfo.statistics.plannings_count && ! dashboardInfo.statistics.meetings_count && ! mobileview" cols="12">
          <v-card>
            <v-card-title class="justify-center" style="border-bottom: 2px solid #0169aa;">
              <h3 class="primary--text">{{ $t('HOME.FIRST_STEPS') }}</h3>
            </v-card-title>
            <v-card-text class="mx-auto pt-4" style="max-width: 620px">
              <div v-html="$t('HOME.FIRST_STEPS_MESSAGE')"></div>
              <div class="text-center">
                <v-btn :disabled="disableStartDemoProject" color="accent" rounded large @click="startDemoProject()">{{ $t('HOME.START_FIRST_PROJECT') }}</v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col :style="{ opacity: (dashboardInfo.statistics.plannings_count + dashboardInfo.statistics.meetings_count) ? '' : .5 }" md="7" sm="6" cols="12">
          <v-row>
            <v-col v-if="hasMeeting" cols="12">
              <v-card>
                <v-card-title class="pl-0">
                  <div class="display-flex justify-center align-center mx-2" style="flex: 0 0 32px">
                    <v-icon color="meetingprimary" dense>fa fa-users</v-icon>
                  </div>
                  <a href="./meeting/" class="text-h6 meetingprimary--text" style="color:inherit">{{ $t('MEETING.BUBBLE_MEETING') }}</a>
                  <v-tooltip left max-width="200">
                    <template #activator="{ on }">
                      <v-icon color="info" class="ml-auto mt-auto" v-on="on">far fa-question-circle</v-icon>
                    </template>
                    <span>{{ $t('HOME.BUBBLE_MEETING_EXPLANATIONS') }}</span>
                  </v-tooltip>
                </v-card-title>
                <div class="ml-12" style="border-bottom: 2px solid rgb(255, 155, 29);"></div>
                <v-card-text class="pl-12">
                  <div v-if="! dashboardBubbleMeetings.length" class="muted--text">
                    {{ $t('HOME.NO_MEETING_ACTIVITY') }}
                  </div>
                  <v-row v-else>
                    <v-col v-for="meeting in dashboardBubbleMeetings" :key="meeting.id" md="6" cols="12" class="text-center">
                      <v-card :href="`./meeting/#/meeting/${meeting.id}`" color="midgrey lighten-3"
                              class="elevation-1 hover-elevation-6" style="height: 100%; color:inherit; text-decoration: none">
                        <v-card-text class="display-flex" style="flex-direction: column">
                          {{ meeting.title || $t('MEETING.UNNAMED_MEETING') }}
                          <div v-if="meeting.date" class="muted--text">{{ meeting.date | moment('datetime') }}</div>
                          <div>
                            <a v-if="meeting.actions_count" :href="`./meeting/#/meeting/${meeting.id}`" :title="$t('HOME.MEETINGACTIONS_ICON_EXPLANATION')" class="link">
                              <span style="color: #484848">{{ meeting.actions_count }} <i class="fa fa-check-square"></i></span>
                            </a>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card>
                <v-card-title class="pl-0">
                  <div class="display-flex justify-center align-center mx-2" style="flex: 0 0 32px">
                    <v-icon color="primary" dense>fa fa-folder-open</v-icon>
                  </div>
                  <router-link to="/projects" class="text-h6 primary--text">{{ $t('HOME.MY_CURRENT_PROJECTS') }}</router-link>
                  <v-tooltip left max-width="200">
                    <template #activator="{ on }">
                      <v-icon color="info" class="ml-auto" v-on="on">far fa-question-circle</v-icon>
                    </template>
                    <span>{{ $t('HOME.PROJECTS_EXPLANATIONS') }}</span>
                  </v-tooltip>
                </v-card-title>
                <div class="ml-12" style="border-bottom: 2px solid #0169aa;"></div>
                <v-card-text class="pl-12">
                  <div v-if="! dashboardInfo.plannings.length" class="muted--text">
                    {{ $t('HOME.NO_PLANNING_ACTIVITY') }}
                  </div>
                  <v-row v-else class="mt-n2">
                    <v-col v-for="project in orderedPlannings" :key="project.id" md="6" cols="12">
                      <router-link :to="project.url" style="color:inherit; text-decoration: none">
                        <project-preview :project="project" :thumbnail="project.imgurl" :thumbnail-percent="30">
                          <template #body-details><div class="pb-4"></div></template>
                          <template #bottom>
                            <div class="text-center">
                              <router-link v-if="project.notifications_count" :to="`${project.url}?open=notifications`" :title="$t('HOME.NOTIFICATION_ICON_EXPLANATION')" class="link">
                                <span class="errorred--text"><i class="fas fa-bell"></i> {{ project.notifications_count }}</span>
                              </router-link>
                              <router-link v-if="project.actions_late_count" :to="`${project.url}?open=actions`" :title="$t('HOME.LATE_ICON_EXPLANATION')" class="link">
                                <span class="errorred--text"><i class="fas fa-check-square"></i> {{ project.actions_late_count }}</span>
                              </router-link>
                              <router-link v-if="project.actions_urgent_count" :to="`${project.url}?open=actions`" :title="$t('HOME.URGENT_ICON_EXPLANATION')" class="link">
                                <span class="orange"><i class="fas fa-check-square"></i> {{ project.actions_urgent_count }}</span>
                              </router-link>
                              <router-link v-if="project.tasks_count" :to="project.url" :title="$t('HOME.TASKS_ICON_EXPLANATION')" class="link">
                                <span class="primary--text"><i class="fas fa-calendar-alt"></i> {{ project.tasks_count }}</span>
                              </router-link>
                              <router-link v-if="project.milestones_count" :to="project.url" :title="$t('HOME.MILESTONES_ICON_EXPLANATION')" class="link">
                                <span class="primary--text"><i class="fa icon-diamonds"></i> {{ project.milestones_count }}</span>
                              </router-link>
                            </div>
                          </template>
                        </project-preview>
                      </router-link>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <home-user-tasks :elements="dashboardInfo.elements" :non-assigned-tasks="dashboardInfo.non_assigned_tasks" @save-el="saveEl"></home-user-tasks>
            </v-col>
          </v-row>
        </v-col>
        <v-col :style="{ opacity: (dashboardInfo.statistics.plannings_count + dashboardInfo.statistics.meetings_count) ? '' : .5 }" md="5" sm="6" cols="12">
          <v-row>
            <v-col cols="12">
              <home-user-milestones :elements="dashboardInfo.elements" @save-el="saveEl"></home-user-milestones>
            </v-col>
            <v-col cols="12">
              <home-user-actions :actions="dashboardInfo.actions" :dashboard-elements="dashboardInfo.elements" @save-el="saveEl"></home-user-actions>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-fade-transition>
  </v-container>
</template>

<style>
  .home-user ul {
    margin-bottom: 0;
    padding: 0 0 16px !important;
    list-style: none;
  }
  .home-user li {
    padding: 2px 0;
  }

  .home-user .link {
    padding: 0 2px;
    border: 1px solid transparent;
    border-radius: 4px;
    transition: all .3s;
  }

  .home-user .link:hover {
    text-decoration: none;
    background-color: #e6e6e6;
    border-color: #ddd;
  }

  .home-user .project-preview:hover .project-preview-textbox {
    margin-left: 22% !important;
  }
  .home-user .project-preview:hover .project-preview-title {
    font-size: 114%;
  }
</style>

<script>
  import { mapState } from 'vuex';
  import Planning from '@/models/Planning';
  import PlanningElement from '@/models/PlanningElement';
  import ProjectSrv from '@/components/Projects/ProjectSrv';
  import ProjectPreview from '@/components/Projects/ProjectPreview';
  import HomeUserTasks from './HomeUserTasks';
  import HomeUserMilestones from './HomeUserMilestones';
  import HomeUserActions from './HomeUserActions';

  function projectUrl(projectId, projectTitle, isConsult) {
    return `/${isConsult ? 'viewer/' : 'planning/'}${projectId}/${window.slugify(projectTitle || "New project")}`;
  }

  export default {
    components: {
      ProjectPreview,
      HomeUserTasks,
      HomeUserMilestones,
      HomeUserActions,
    },
    data() {
      return {
        dashboardInfo: null,
        disableStartDemoProject: false,
        errors: "",
      };
    },
    computed: {
      orderedPlannings() {
        return this.dashboardInfo.plannings.slice().sort((a, b) => (a.priority < b.priority ? 1 : -1));
      },
      dashboardBubbleMeetings() {
        return this.dashboardInfo.bubble_meetings;
      },
      mobileview() {
        return this.$store.state.ui.mobileview;
      },
      hasMeeting() { return this.accessRight.hasMeeting; },
      ...mapState('users', ['user', 'accessRight']),
    },
    created() {
      window.apiSrv.call('dashboards/home', 'index').then((result) => {
        this.dashboardInfo = result.data.dashboard;
        (this.dashboardInfo.plannings || []).forEach((planning) => {
          this.$set(planning, 'imgurl', ((planning.imageexport_name || '').startsWith('http') ? '' : window.apiSrv.snapBaseUrl) + planning.imageexport_name);
          planning.url = projectUrl(planning.id, planning.title, planning.access_right == 'consult');
          planning.priority = 1000 * (planning.notifications_count || 0) + 100 * (planning.actions_late_count || 0) + 10 * (planning.actions_urgent_count || 0);
          planning.priority += (planning.milestones_count || 0) + (planning.tasks_count || 0);
        });

        const plannings = {};
        ['elements', 'non_assigned_tasks'].forEach((prop) => {
          if (! this.dashboardInfo[prop]) return;
          this.dashboardInfo[prop] = this.dashboardInfo[prop].map((item) => {
            const meta = item.meta || {}; // project_id, project_title, project_access_right, lane_label, status
            if (! plannings[meta.project_id]) plannings[meta.project_id] = new Planning();
            const planning = plannings[meta.project_id];
            planning.id = meta.project_id;
            planning.meta = { ...planning.meta, title: meta.project_title, access_right: meta.project_access_right };
            const element = new PlanningElement(planning, item);
            delete element.data.meta;
            Object.keys(meta).forEach((metaProp) => { element[metaProp] = meta[metaProp]; });
            element.id = `${element.id}.${window.uuid()}`;
            element.setConfig({
              ...element.getConfig(),
              "show-description": false,
              "show-dates": false,
              "show-progress": true,
              "show-checklist": true,
              "show-users": false,
              "show-links": false,
              "show-budgets": false,
              "show-projecttitle": false,
            });
            return element;
          });
        });
      }).catch((message) => {
        this.errors = message;
      });
    },
    methods: {
      openAccountAvatar() {
        this.$store.commit('ui/navigation/setOpenedModal', 'account-avatar');
      },
      startDemoProject() {
        this.disableStartDemoProject = true;
        ProjectSrv.getTemplate('welcome').then((template) => {
          const newPlanning = new Planning(ProjectSrv.projectFromTemplate({ template, startdate: moment() }));
          const data = {
            title: this.$t('HOME.WELCOME_PROJECT_TITLE'),
            content: newPlanning.toProject().content,
          };
          return ProjectSrv.createNew(data).then((response) => {
            this.$router.push({ path: `/planning/${response.data.planning.id}`, query: { tour: 1 } });
          });
        }).catch(() => {
          this.disableStartDemoProject = false;
          this.$store.dispatch('ui/msgbox/open', { title: "GLOBAL.ERROR", body: 'Unable to load template.' });
          console.error('Unable to load template.');
        });
      },
      saveEl({ el, props = ['checklist', 'progress'], oldStateParam }) {
        const planning = el.getPlanning();
        const newState = _.extend(new PlanningElement(planning, el), { id: parseInt(el.id, 10) });
        newState.save(props).then(() => {
          window.notificationsSrv.callEvent('projectSaved', {
            planning_id: planning.id,
            planning_title: planning.meta && planning.meta.title,
          });
        });
      },
    },
  };
</script>
