<template>
  <v-list-item :dense="dense" @click="toggle">
    <v-list-item-action :class="{ 'ml-7': indent }">
      <v-icon :color="selectedSublistIds.length == sublistIds.length ? 'primary' : ''">
        {{ selectedSublistIds.length == sublistIds.length ? 'fas fa-check-square' : selectedSublistIds.length ? 'fas fa-minus-square' : 'far fa-square' }}
      </v-icon>
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title>
        <slot name="default"></slot>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
  export default {
    props: {
      selectedIds: { type: Array, required: true },
      sublistIds: { type: Array, required: true },
      indent: { type: Boolean, default: false },
      dense: { type: Boolean, default: false },
    },
    computed: {
      selectedSublistIds() {
        return _.intersection(this.selectedIds, this.sublistIds);
      },
    },
    methods: {
      toggle() {
        if (this.selectedSublistIds.length == this.sublistIds.length) {
          this.$emit('update', _.without(this.selectedIds, ...this.sublistIds));
        } else {
          this.$emit('update', _.union(this.selectedIds, this.sublistIds));
        }
      },
    },
  };
</script>
