<template>
  <div>
    <home-projects-filters :filters="filters" :projects="projects"></home-projects-filters>
    <v-divider class="my-4"></v-divider>
    <div v-if="! dataLoaded.projects && ! errors.loadProjects" style="text-align:center; width: 100%"><i class="fas fa-spinner fa-spin fa-2x fa-fw"></i></div>
    <div v-if="! dataLoaded.projects && errors.loadProjects">{{ errors.loadProjects }}</div>
    <div v-if="dataLoaded.projects && ! projects.length">{{ $t('PROJECTS.NO_PROJECT_AVAILABLE') }}</div>
    <div v-show="dataLoaded.projects && projects.length">
      <v-container class="pa-0 mt-n2 mb-3" style="max-width: calc(100% - 36px)"> <!-- only way to add margin around pagination ? -->
        <v-pagination v-if="filteredProjects.length > projectsPerPage" v-model="projectsCurrentPage" :length="projectsPaginationLength" circle></v-pagination>
      </v-container>
      <!-- GRID -->
      <v-container v-if="filters.display == 'grid'" fluid class="pa-0" style="max-width: 1785px">
        <v-row>
          <transition-group name="list-fade" tag="div" class="display-flex" style="flex-wrap: wrap; width: 100%">
            <template v-if="projectsCurrentPage == 1">
              <v-col v-for="view in filteredViews" :key="`view${view.id}`" lg="2" sm="3" cols="12" class="projectbox list-fade-item">
                <home-projects-project :project="view" view></home-projects-project>
              </v-col>
            </template>
            <v-col v-for="project in displayedFilteredProjects" :key="`project${project.id}`" xl="3" lg="4" sm="6" cols="12" class="projectbox list-fade-item">
              <v-lazy v-if="vLazyAvailable" :options="{ rootMargin: '200px' }" style="height: 190px">
                <home-projects-project :project="project" :status-info="statusInfo"
                                       @duplicate="duplicateProject(project)"
                                       @archive="archiveProject(project)">
                </home-projects-project>
              </v-lazy>
              <home-projects-project v-else :project="project" :status-info="statusInfo"
                                     @duplicate="duplicateProject(project)"
                                     @archive="archiveProject(project)">
              </home-projects-project>
            </v-col>
          </transition-group>
        </v-row>
      </v-container>
      <!-- LIST -->
      <div v-if="filters.display == 'list'" class="px-4">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead class="text-uppercase">
              <tr>
                <th>{{ $t('NEWPROJECT.PROJECT_NAME') }}</th>
                <th>{{ $t('GLOBAL.LAST_MODIFICATION') }}</th>
                <th>{{ $t('GLOBAL.OWNER') }}</th>
                <th>{{ $t('GLOBAL.CREATION_DATE') }}</th>
                <th>{{ $t('GLOBAL.ACCESS_RIGHT') }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <template v-if="projectsCurrentPage == 1">
                <tr v-for="view in filteredViews" :key="`view${view.id}`" class="pointer" @click="goToView(view)">
                  <td><em>Vue personnalisée</em> &nbsp;{{ view.title }}</td>
                  <td>{{ view.project.date_of_modification | moment('calendar') }} {{ $t('GLOBAL.BY') }} {{ translateApiText(view.project.last_modification_user_name) }}</td>
                  <td></td>
                  <td>{{ view.created_at | moment('datetime') }}</td>
                  <td></td>
                  <td></td>
                </tr>
              </template>
              <tr v-for="project in displayedFilteredProjects" :key="`project${project.id}`" class="pointer" @click="goToProject(project)">
                <td>{{ project.title || $t('PLANNING.UNNAMED_PROJECT') }}</td>
                <td>{{ project.date_of_modification | moment('calendar') }} {{ $t('GLOBAL.BY') }} {{ translateApiText(project.last_modification_user_name) }}</td>
                <td>{{ translateApiText(project.owner_name) }}</td>
                <td>{{ project.date_of_creation | moment('datetime') }}</td>
                <td>{{ $t(uppercase('ACR.'+project.access_right)) }}</td>
                <td class="nobr" @click.stop>
                  <v-btn :to="projectLink(project, true)" :title="$t('PROJECTS.READ_ONLY')" small icon class="my-0">
                    <v-icon color="secondary" small>fas fa-eye</v-icon>
                  </v-btn>
                  <v-btn :to="`manage?open=${project.id}`" :title="$t('PROJECTS.MANAGE_ACCESS')" small icon class="my-0">
                    <v-icon color="warningorange darken-2" small>fas fa-lock</v-icon>
                  </v-btn>
                  <template v-if="project.access_right == 'admin'">
                    <v-btn :title="$t('PROJECTS.DUPLICATE')" small icon class="my-0" @click="duplicateProject(project)">
                      <v-icon color="secondary" small>fas fa-copy</v-icon>
                    </v-btn>
                    <v-btn :title="$t('PROJECTS.ARCHIVE')" small icon class="my-0" @click="archiveProject(project)">
                      <v-icon color="errorred" small>fas fa-archive</v-icon>
                    </v-btn>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import HomeProjectsFilters from './HomeProjectsFilters';
  import HomeProjectsProject from './HomeProjectsProject';
  import HomeProjectsMixin from './HomeProjectsMixin';

  export default {
    components: {
      HomeProjectsProject,
      HomeProjectsFilters,
    },
    mixins: [HomeProjectsMixin],
    props: {
      dataLoaded: { type: Object, default: () => {} },
      errors: { type: Object, default: () => {} },
      filters: { type: Object, default: () => {} },
      projects: { type: Array, default: () => [] },
      views: { type: Array, default: () => [] },
      statusInfo: { type: Object, default: () => {} },
    },
    data() {
      return {
        projectsPerPage: 50,
        projectsCurrentPage: 1,
      };
    },
    computed: {
      filteredProjects() {
        const filtered = this.projects.filter(project => this.filterProjectByCompany(project) && this.filterProjectByCategory(project)
          && this.filterProjectByTitle(project) && this.filterProjectByUser(project));
        if (this.filters.sort) {
          let { sort } = this.filters;
          let reverse = false;
          if (sort.startsWith('-')) {
            sort = sort.slice(1);
            reverse = true;
          }
          if (sort == 'title') {
            filtered.sort((a, b) => (a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1));
          } else {
            filtered.sort((a, b) => (a[sort] < b[sort] ? -1 : 1));
          }
          if (reverse) filtered.reverse();
        }
        return filtered;
      },
      displayedFilteredProjects() {
        return this.filteredProjects.slice(this.projectsPerPage * (this.projectsCurrentPage - 1), this.projectsPerPage * this.projectsCurrentPage);
      },
      filteredViews() {
        const filtered = this.views.filter(project => this.filterProjectByCategory(project) && this.filterProjectByTitle(project));
        if (this.filters.sort) {
          let { sort } = this.filters;
          if (sort == '-date_of_modification') {
            filtered.sort((a, b) => ((a.project && a.project.date_of_modification) < (b.project && b.project.date_of_modification) ? 1 : -1));
          } else {
            let reverse = false;
            if (sort.startsWith('-')) {
              sort = sort.slice(1);
              reverse = true;
            }
            filtered.sort((a, b) => (a[sort] < b[sort] ? -1 : 1));
            if (reverse) filtered.reverse();
          }
        }
        return filtered;
      },
      projectsPaginationLength() {
        return Math.ceil(this.filteredProjects.length / this.projectsPerPage);
      },
      vLazyAvailable() {
        return 'IntersectionObserver' in window;
      },
    },
    watch: {
      projectsPaginationLength() {
        if (this.projectsCurrentPage > this.projectsPaginationLength) this.projectsCurrentPage = 1;
      },
    },
    methods: {
      duplicateProject(project) {
        this.$emit('duplicate-project', project);
      },
      archiveProject(project) {
        this.$emit('archive-project', project);
      },
      goToView(view) {
        const path = `/planningview/${view.id}/${window.slugify(view.title || this.$t('PLANNING.UNNAMED_VIEW'))}`;
        this.$router.push({ path, query: {} });
      },
      goToProject(project, isConsult = this.statusInfo.readonly || project.access_right == 'consult') {
        const path = `/${isConsult ? 'viewer' : 'planning'}/${project.id}/${window.slugify(project.title || this.$t('PLANNING.UNNAMED_PROJECT'))}`;
        this.$router.push({ path, query: {} });
      },
      uppercase(text) { return text.toUpperCase(); },
    },
  };
</script>
