<template>
  <div>
    <v-progress-linear :value="elProgress" color="successgreen" height="12"
                       @click.stop="el.isType('task') ? $emit('progress-click', $event) : ''">
      <div :style="{ 'margin-right': `${100 - elProgress}%` }" class="white--text small text-center">{{ elProgress }}%</div>
    </v-progress-linear>
  </div>
</template>

<script>
  export default {
    props: {
      el: { type: Object, required: true },
    },
    computed: {
      elProgress() {
        return this.el.getProgress();
      },
    },
  };
</script>
