<template>
  <div>
    <div v-if="isMiniElement">
      <i :class="`budget-${elBudgets[0].icon || ''}`"></i> {{ elBudgets.length }}
    </div>
    <template v-else>
      <template v-for="(budgets, state) in elBudgetsByStates">
        <div v-for="(budget, icon) in budgets" class="vcenter" style="padding: 1px">
          <span class="nobr">
            <span :class="`budget-${icon || ''}`"></span>
            <span v-if="budget.amount_inprogress && budget.amount">{{ budget.amount_inprogress | number }}&nbsp;/</span>
            <span v-if="budget.amount_inprogress && ! budget.amount">{{ budget.amount_inprogress | number }}</span>
            <span v-if="budget.amount">{{ budget.amount | number }}</span>
          </span>
          <span v-if="budgetStates[state]">({{ budgetStates[state] | lowercase }})</span>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
  export default {
    props: {
      el: { type: Object, required: true },
      isMiniElement: { type: Boolean, default: false },
    },
    computed: {
      elBudgets() {
        return this.el.getBudgets() && this.el.getBudgets().filter(budget => budget.amount || budget.amount_inprogress);
      },
      budgetStates() {
        return this.$store.getters['users/accessRight/config'].budgetStates;
      },
      elBudgetsByStates() {
        if (! this.elBudgets) return null;
        const budgetStateKeysSet = new Set(Object.keys(this.budgetStates));
        const budgetsByStates = {};
        this.elBudgets.forEach((budget) => {
          if (! budget.amount && ! budget.amount_inprogress) return;
          const state = budgetStateKeysSet.has(budget.state) ? budget.state : '';
          const icon = budget.icon || '';
          if (! budgetsByStates[state]) budgetsByStates[state] = {};
          if (! budgetsByStates[state][icon]) budgetsByStates[state][icon] = { amount: 0, amount_inprogress: 0 };
          budgetsByStates[state][icon].amount += budget.amount || 0;
          budgetsByStates[state][icon].amount_inprogress += budget.amount_inprogress || 0;
        });
        return budgetsByStates;
      },
    },
  };
</script>
