<!-- Isolate this component to avoid rerendering PlanningElementView on every selection change
==> Huge performance gap -->

<template>
  <article :class="{ selected: elIsSelected }">
    <slot></slot>
  </article>
</template>

<script>
  export default {
    props: {
      el: { type: Object, required: true },
    },
    computed: {
      elIsSelected() {
        return this.$store.state.selection.indexOf(this.el.id) > -1;
      },
    },
  };
</script>
