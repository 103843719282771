<template>
  <v-hover v-slot="{ hover: hoverElement }">
    <planning-element-view-selected :el="el" :class="[elType, elementClass]" :style="{ 'z-index' : hoverElement ? 110 : null }" class="element">
      <div v-if="elType == 'task' || elType == 'macro'">
        <!--ICON-->
        <div v-if="elIcon" :style="{ 'border-color': elMainColor }" :class="elType == 'macro' ? 'macro-icon' : 'task-icon'">
          <v-icon :color="elIcon.color" :title="elIconLabel" size="16" class="fa-fw iconshadow" style="vertical-align: initial; cursor: inherit"
                  @click.stop="$emit('select-color')">
            {{ elIcon.name }}
          </v-icon>
        </div>

        <div :style="{ background: elSecondaryColor, borderColor: elMainColor }" class="elementmain">
          <!--HEADER-->
          <div :style="[{ background: elMainColor }, elTitleStyle]" :class="{ 'mini-element-title': isMiniElement }" class="element-title">
            <!-- span needed for z-index with macro -->
            <span>{{ elTitle }}</span>
            <div v-if="showDetails.projecttitle && el.display_title" class="font-italic small mt-1 text-ellipsis">{{ el.display_title }}</div>
          </div>

          <!--CONTENT-->
          <div v-if="showDetails.description || showDetails.subTasks || showDetails.dynSubtasks || showDetails.checklist" class="elementcontent">
            <div v-if="showDetails.description" :style="elDescriptionStyle" :class="{ 'shrink-item' : isMiniElement }" class="pre-wrap">
              <div v-if="isMiniElement"><i class="fa fa-lg fa-align-center"></i></div>
              <div v-else>{{ elDescription }}</div>
            </div>
            <!-- Macro Sub tasks -->
            <div v-if="elType == 'macro' && showDetails.subTasks" :class="{ 'shrink-item' : isMiniElement }" class="display-flex flex-wrap">
              <div v-if="isMiniElement"><i class="fa fa-list"></i> {{ elSubTaskIds.size }}</div>
              <template v-else>
                <div v-for="subTask in elSubTasks" class="ma-1">
                  <planning-element-view :el="subTask" class="macro-sub-task"
                                         style="position: static; background: none; max-width: 100px"
                                         @click.native.stop="$emit('open-element', subTask.id)">
                  </planning-element-view>
                </div>
              </template>
            </div>
            <!-- Task Dynamics Subtasks -->
            <client-custom-component v-if="elType == 'task' && showDetails.dynSubtasks" name="inli-planning-element-view-sub-tasks"
                                     :el="el" :is-mini-element="isMiniElement" :class="{ 'shrink-item' : isMiniElement }"
                                     @dyn-subtasks-click="$emit('dyn-subtasks-click', $event)">
            </client-custom-component>
            <v-divider v-if="showDetails.dynSubtasks && showDetails.checklist" class="my-1"></v-divider>
            <!-- Task checklist -->
            <planning-element-view-checklist v-if="elType == 'task' && showDetails.checklist" :el="el" :el-checklist-filtered="elChecklistFiltered"
                                             :is-mini-element="isMiniElement" :class="{ 'shrink-item' : isMiniElement }"
                                             @checklist-click="$emit('checklist-click', $event)">
            </planning-element-view-checklist>
          </div>

          <!--FOOTER-->
          <planning-element-view-dates v-if="showDetails.dates" :el="el" class="users-bar">
          </planning-element-view-dates>
          <planning-element-view-users v-if="showDetails.users" :el="el" :is-mini-element="isMiniElement"
                                       :class="{ 'shrink-item' : isMiniElement }" class="users-bar">
          </planning-element-view-users>
          <planning-element-view-links v-if="showDetails.links" :el="el" :is-mini-element="isMiniElement"
                                       :class="{ 'shrink-item' : isMiniElement }" class="users-bar">
          </planning-element-view-links>
          <planning-element-view-budget v-if="showDetails.budgets" :el="el" :is-mini-element="isMiniElement"
                                        :class="{ 'shrink-item' : isMiniElement }" class="users-bar">
          </planning-element-view-budget>

          <planning-element-synthesis-bar v-if="showDetails.synthesis" :el="el" class="users-bar"></planning-element-synthesis-bar>

          <planning-element-view-progress v-if="showDetails.progress" :el="el" class="users-bar pa-0 ma-0"
                                          @progress-click="$emit('progress-click', $event)">
          </planning-element-view-progress>

          <client-custom-component v-for="customField in customFields" v-if="customField.elComponent" :key="`cf_${customField.dataField}`"
                                   :name="customField.elComponent" :el="el" :custom-field="customField">
          </client-custom-component>
        </div>
      </div>

      <div v-if="elType == 'milestone'">
        <div :style="{ 'min-height': el.ytextposition + 'px', 'margin-top': -el.ytextposition + 'px' }" class="milestone-icon">
          <v-icon :color="elIcon.color" :title="elIconLabel" size="16" class="fa-fw iconshadow" style="vertical-align: initial; cursor: inherit"
                  @click.stop="$emit('select-color')">
            {{ elIcon.name }}
          </v-icon>
          <div :class="elIcon.color+'--text'" class="milestone-icon-dash"></div>
        </div>
        <planning-element-view-dates v-if="showDetails.date" :el="el">
        </planning-element-view-dates>
        <div :style="elTitleStyle">
          <div class="element-title">
            {{ elTitle }}
            <div v-if="showDetails.projecttitle && el.display_title" class="font-italic small mt-1 text-ellipsis">{{ el.display_title }}</div>
          </div>
        </div>
        <!--FOOTER-->
        <div>
          <planning-element-view-users v-if="showDetails.users" :el="el" :is-mini-element="isMiniElement"
                                       :class="{ 'shrink-item' : isMiniElement }" class="users-bar">
          </planning-element-view-users>
          <planning-element-view-links v-if="showDetails.links" :el="el" :is-mini-element="isMiniElement"
                                       :class="{ 'shrink-item' : isMiniElement }" class="users-bar">
          </planning-element-view-links>
          <div v-if="el.getMeetingId()" class="users-bar" style="text-transform: uppercase; text-align: center; font-weight: bold">
            <a style="color: inherit" @click.stop="$emit('open-meeting', el)">{{ $t('PLANNING.MEETING') }}</a>
          </div>

          <client-custom-component v-for="customField in customFields" v-if="customField.elComponent" :key="`cf_${customField.dataField}`"
                                   :name="customField.elComponent" :el="el" :custom-field="customField">
          </client-custom-component>
        </div>
      </div>
      <slot></slot>
      <v-scale-transition origin="top left">
        <slot v-if="hoverElement" name="hover-menu"></slot>
      </v-scale-transition>
    </planning-element-view-selected>
  </v-hover>
</template>

<style>
  .element.task .element-title.mini-element-title, .element.macro .element-title.mini-element-title {
    font-size: 9px;
    white-space: nowrap;
    padding: 0 2px;
  }

  article.element .shrink-item {
    min-height: 16px;
    padding: 3px 0;
    text-align: center !important;
    font-size: 11px !important;
  }

  .element .element-menu.scale-transition-enter-active, .element .element-menu.scale-transition-leave-active {
    transition-duration: .2s !important;
  }
</style>

<script>
  import clientConfig from '@/client_customs/config';
  import constants from '@/js/constants.js';
  import PlanningElementViewSelected from './PlanningElementView/PlanningElementViewSelected';
  import PlanningElementViewBudget from './PlanningElementView/PlanningElementViewBudget';
  import PlanningElementViewChecklist from './PlanningElementView/PlanningElementViewChecklist';
  import PlanningElementViewDates from './PlanningElementView/PlanningElementViewDates';
  import PlanningElementViewLinks from './PlanningElementView/PlanningElementViewLinks';
  import PlanningElementViewProgress from './PlanningElementView/PlanningElementViewProgress';
  import PlanningElementViewUsers from './PlanningElementView/PlanningElementViewUsers';
  import PlanningElementSynthesisBar from './PlanningElementView/PlanningElementViewSynthesisBar';
  import macroElement from './mixins/macroElement';

  export default {
    name: 'PlanningElementView',
    components: {
      PlanningElementViewSelected,
      PlanningElementViewBudget,
      PlanningElementViewChecklist,
      PlanningElementViewDates,
      PlanningElementViewLinks,
      PlanningElementViewProgress,
      PlanningElementViewUsers,
      PlanningElementSynthesisBar,
    },
    mixins: [macroElement],
    props: {
      el: { type: Object, required: true },
      showDetailsItems: { type: Array, default: null },
      disableMiniElement: { type: Boolean, default: false },
    },
    data() {
      return {
        planning: this.el.getPlanning(),
        lastHeightUpdateElData: null,
        customFields: clientConfig.elements && clientConfig.elements.customFields,
      };
    },
    computed: {
      elType() {
        return this.el.getType();
      },
      elementClass() {
        if (this.elType == 'milestone' || ! this.planning?.visibleTimeline) return null;
        const { starttime: timelineStart, endtime: timelineEnd } = this.planning.visibleTimeline;
        if (this.el.getStartTime().isBefore(timelineStart)) return 'element-overflow-start';
        if (this.el.getEndTime().isAfter(timelineEnd)) return 'element-overflow-end';
        return null;
      },
      elMainColor() {
        return this.el.getMainColor();
      },
      elSecondaryColor() {
        return this.el.getSecondaryColor();
      },
      defaultTitle() {
        if (this.elType == 'macro') return this.$t('PLANNING.NEW_MACRO_BUBBLE');
        return this.elType == 'milestone' ? this.$t('PLANNING.MILESTONE') : this.$t('PLANNING.NEW_BUBBLE');
      },
      elTitle() {
        return this.el.getTitle() || this.defaultTitle;
      },
      elTitleStyle() {
        return this.el.getTitleStyle();
      },
      elDescriptionStyle() {
        return this.el.getDescriptionStyle();
      },
      elSubTaskIds() {
        return new Set(this.el.getSubTasks());
      },
      elSubTasks() {
        if (! this.elSubTaskIds.size) return null;
        return this.planning.elements.filter(item => this.elSubTaskIds.has(parseInt(item.id, 10))).map((el) => {
          const subEl = angular.copy(el);
          subEl.setConfig({
            "show-description": false, "show-progress": true, "show-checklist": false, "show-users": false,
            "show-dates": true, "show-links": false, "show-budgets": false, "show-subTasks": false, "show-projecttitle": false,
          });
          subEl.setIconId(null);
          return subEl;
        }).sort((a, b) => {
          if (a.getType() != b.getType()) return a.isType('macro') ? -1 : 1;
          return (a.getStartTime() < b.getStartTime() ? -1 : 1);
        });
      },
      elIcon() {
        return this.el.hasIcon() && this.el.getIcon();
      },
      elIconLabel() {
        return this.elIcon ? (this.elIcon.label || this.$t(`ICONS.${this.el.getIconId().toUpperCase()}`)) : '';
      },
      elDescription() {
        return this.el.getDescription();
      },
      elChecklistFiltered() {
        let checklist = this.el.getChecklist() || [];
        if (this.el.filter_user) {
          checklist = checklist.filter((item) => { // remove other users actions
            if (! item.user_id && ! item.group_id && ! item.username) return true; // unassigned action
            if (item.user_id) return item.user_id == this.el.filter_user;
            if (item.group_id) return `group${item.group_id}` == this.el.filter_user;
            return `vp${item.username}` == this.el.filter_user;
          });
        }
        return checklist;
      },
      elLinks() {
        return this.el.getLinks();
      },
      elUsers() {
        return this.el.getUsers();
      },
      elBudgets() {
        return this.el.getBudgets() && this.el.getBudgets().filter(budget => budget.amount || budget.amount_inprogress);
      },
      elProgress() {
        return this.el.getProgress();
      },
      showDetails() {
        let keys = ['description', 'subTasks', 'checklist', 'users', 'links', 'budgets', 'progress', 'dates', 'date', 'projecttitle'];
        const showDetails = {};
        if (this.showDetailsItems) keys = this.showDetailsItems;
        keys.forEach((key) => {
          const config = this.showDetailsItems ? true : this.el.getConfig(`show-${key}`);
          if (key == 'description') showDetails[key] = config && this.elDescription;
          else if (key == 'subTasks') showDetails[key] = config && this.elSubTasks && this.elSubTasks.length;
          else if (key == 'checklist') showDetails[key] = config && this.elChecklistFiltered.length;
          else if (key == 'users') showDetails[key] = config && this.elUsers && this.elUsers.length;
          else if (key == 'links') showDetails[key] = config && this.elLinks && this.elLinks.length;
          else if (key == 'budgets') showDetails[key] = config && this.elBudgets && this.elBudgets.length;
          else if (key == 'progress') showDetails[key] = (config || config === undefined) && this.elProgress;
          else if (['dates', 'date', 'projecttitle', 'synthesis'].includes(key)) showDetails[key] = config;
        });
        // INLI
        showDetails.dynSubtasks = ((this.el.getCustomFields() || {}).dyn_subtasks || []).length;
        // /INLI
        return showDetails;
      },
      isMiniElement() {
        return ! this.disableMiniElement && this.el.getWidth() <= constants.miniElementBreakWidth;
      },
    },
    watch: {
      'el.data': {
        handler() {
          this.$nextTick(() => this.$nextTick(() => this.throttledUpdateHeight(this.el))); // double nextTick to wait for subcomponents like v-menu in links
        },
        deep: true,
      },
    },
    methods: {
      throttledUpdateHeight: _.throttle(function (el) {
        const elData = _.extend(el.getAll(), { starttime: null, endtime: null });
        if (angular.equals(this.lastHeightUpdateElData, elData)) return;
        this.lastHeightUpdateElData = angular.copy(elData);
        el.updateHeight();
      }, 300),
    },
  };
</script>
