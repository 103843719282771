<template>
  <div style="max-width: 580px">
    <b class="primary--text text-uppercase">{{ $t('MANAGE.CONFIG.REPLACE_USER.TITLE') }}</b>
    <div>{{ $t('MANAGE.CONFIG.REPLACE_USER.DESC') }}</div>
    <div class="ml-6 pt-1">
      <v-autocomplete v-model="selectedProjects"
                      :items="projects" :item-text="(project) => project.meta && project.meta.title || $t('PLANNING.UNNAMED_PROJECT')" item-value="id"
                      :label="$t('MANAGE.CONFIG.REPLACE_USER.PROJECTS_TO_REPLACE')" :placeholder="selectedProjects.length ? '' : $t('MULTIPROJECTS.NO_PROJECT_SELECTED')"
                      prepend-icon="fas fa-folder-open" hide-details multiple menu-props="offsetY"
                      @focus.once="loadPlanningsContent">
        <template v-if="! loadingPlanningContent" #prepend-item>
          <filter-toggle :selected-ids="selectedProjects" :sublist-ids="projectIds"
                         @update="selectedProjects = $event">
            {{ $t('MULTIPROJECTS.ALL_PROJECTS') }} ({{ projectIds.length }})
          </filter-toggle>
          <v-divider></v-divider>
        </template>
        <template #selection="{ item, index }">
          <span v-if="selectedProjects.length == 1" class="mr-2">{{ item.meta && item.meta.title || $t('PLANNING.UNNAMED_PROJECT') }}</span>
          <span v-else-if="selectedProjects.length == projects.length && index === 0" class="mr-2">{{ $t('MULTIPROJECTS.ALL_PROJECTS') }} ({{ projects.length }})</span>
          <span v-else-if="index === 0" class="mr-2">{{ selectedProjects.length }}/{{ projects.length }}</span>
        </template>
        <template #no-data>
          <div v-if="loadingPlanningContent" class="text-center mt-2" style="height: 40px"><i class="fas fa-spinner fa-spin fa-2x fa-fw"></i></div>
        </template>
      </v-autocomplete>
      <div class="mt-2 display-flex align-center">
        <v-select v-model="replace.type" :items="replaceOptions.slice(0, -1)" :label="$t('MANAGE.CONFIG.REPLACE_USER.REPLACE')" prepend-icon="fas fa-user"
                  hide-details class="mr-4" style="max-width: 220px">
        </v-select>
        <v-select v-if="replace.type == 'username'" v-model="replace.username" :items="selectedPlanningsUsers.virtuals"
                  :disabled="! selectedProjects.length" item-text="username" hide-details menu-props="offsetY">
        </v-select>
        <v-select v-else-if="replace.type == 'user'" v-model="replace.user" :items="selectedPlanningsUsers.users" :item-text="username" item-value="id"
                  :disabled="! selectedProjects.length" hide-details menu-props="offsetY">
        </v-select>
        <v-select v-else-if="replace.type == 'group'" v-model="replace.group" :items="selectedPlanningsUsers.groups" item-text="title" item-value="group_id"
                  :disabled="! selectedProjects.length" hide-details menu-props="offsetY">
        </v-select>
      </div>
      <div class="mt-1 display-flex align-center">
        <v-select v-model="replaceBy.type" :items="replaceOptions" :label="$t('MANAGE.CONFIG.REPLACE_USER.BY')" prepend-icon="fas fa-arrow-right"
                  hide-details class="mr-4" style="max-width: 220px">
        </v-select>
        <v-text-field v-if="replaceBy.type == 'username'" v-model="replaceBy.username" :disabled="! selectedProjects.length" hide-details autocomplete="nope">
        </v-text-field>
        <v-select v-else-if="replaceBy.type == 'user'" v-model="replaceBy.user" :items="companyUsers" :item-text="username" item-value="id"
                  :disabled="! selectedProjects.length" hide-details menu-props="offsetY">
        </v-select>
        <v-select v-else-if="replaceBy.type == 'group'" v-model="replaceBy.group" :items="companyGroups" item-text="title" item-value="id"
                  :disabled="! selectedProjects.length" hide-details menu-props="offsetY">
        </v-select>
      </div>
      <v-checkbox v-model="disclaimer">
        <template #label>
          <span>
            {{ $t('MANAGE.CONFIG.REPLACE_USER.DISCLAIMER') }}
            <b>{{ $t('MANAGE.CONFIG.REPLACE_USER.DISCLAIMER2') }}</b>
          </span>
        </template>
      </v-checkbox>
      <v-btn :disabled="! btnEnabled" :loading="massReplaceInProgress" rounded large color="accent" block @click="massReplaceUsers">
        {{ $tc('MANAGE.CONFIG.REPLACE_USER.REPLACE_IN_PROJECTS', selectedProjects.length) }}
      </v-btn>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        loadingPlanningContent: false,
        projects: [],
        selectedProjects: [],
        replace: {
          type: 'username',
          user: null,
          group: null,
          username: '',
        },
        replaceBy: {
          type: 'user',
          user: null,
          group: null,
          username: '',
        },
        disclaimer: false,
        massReplaceInProgress: false,
      };
    },
    computed: {
      replaceOptions() {
        return [
          { value: 'username', text: this.$tc('MANAGE.CONFIG.REPLACE_USER.VIRTUAL_PARTICIPANT') },
          { value: 'user', text: this.$t('MANAGE.CONFIG.REPLACE_USER.REGISTERED_USER') },
          { value: 'group', text: this.$t('GLOBAL.GROUP') },
          { value: 'remove', text: this.$t('MANAGE.CONFIG.REPLACE_USER.REMOVE_USER') },
        ];
      },
      companyUsers() {
        return this.$store.getters['users/getOrganizationUsers'];
      },
      companyGroups() {
        return this.$store.getters['users/groups/getCompanyGroups'];
      },
      btnEnabled() {
        return this.isBusiness && this.disclaimer && this.selectedProjects.length && this.replace[this.replace.type] && (this.replaceBy.type == 'remove' || this.replaceBy[this.replaceBy.type]);
      },
      isBusiness() { return this.$store.state.users.accessRight.isBusiness; },
      projectIds() {
        return this.projects.map(item => item.id);
      },
      planningUsers() {
        return this.projects.reduce((acc, planning) => {
          if (! planning || ! planning.elements) return acc;
          acc[planning.id] = planning.elements.reduce((planningAcc, el) => {
            if (el.getUsers()) planningAcc.push(...el.getUsers());
            return planningAcc;
          }, []);
          return acc;
        }, {});
      },
      selectedPlanningsUsers() {
        const selectedPlanningsUsers = { users: {}, groups: {}, virtuals: {} };
        this.selectedProjects.forEach((projectId) => {
          (this.planningUsers[projectId] || []).forEach((user) => {
            if (user.id && ! selectedPlanningsUsers.users[user.id]) {
              selectedPlanningsUsers.users[user.id] = user;
              return;
            }
            if (user.group_id && ! selectedPlanningsUsers.groups[user.group_id]) {
              selectedPlanningsUsers.groups[user.group_id] = user;
              return;
            }
            if (user.username && ! selectedPlanningsUsers.virtuals[user.username]) {
              selectedPlanningsUsers.virtuals[user.username] = user;
            }
          });
        });
        return {
          users: Object.keys(selectedPlanningsUsers.users).map(item => selectedPlanningsUsers.users[item]).sort((a, b) => (this.username(a) < this.username(b) ? -1 : 1)),
          groups: Object.keys(selectedPlanningsUsers.groups).map(item => selectedPlanningsUsers.groups[item]).sort((a, b) => (a.title?.toLowerCase() < b.title?.toLowerCase() ? -1 : 1)),
          virtuals: Object.keys(selectedPlanningsUsers.virtuals).map(item => selectedPlanningsUsers.virtuals[item]).sort((a, b) => (a.username.toLowerCase() < b.username.toLowerCase() ? -1 : 1)),
        };
      },
    },
    methods: {
      loadPlanningsContent() {
        this.loadingPlanningContent = true;
        this.$store.dispatch('multiprojects/plannings/loadPlanningsQuery', { users: true, access_right: 'admin' }).then((plannings) => {
          this.projects = plannings.sort((a, b) => {
            if (a && a.meta && b && b.meta) return (a.meta.title || "").toLowerCase() < (b.meta.title || "").toLowerCase() ? -1 : 1;
            return a && a.meta ? -1 : 1;
          });
          this.loadingPlanningContent = false;
        });
      },
      username(user) {
        return this.$options.filters.username(user);
      },
      massReplaceUsers() {
        if (! this.btnEnabled) return; // anti html hack
        this.massReplaceInProgress = true;
        window.apiSrv.call('plannings/tasks/massreplaceusers', 'store', {
          planning_ids: this.selectedProjects,
          replace_type: this.replace.type,
          replace_user_id: this.replace[this.replace.type],
          replace_by_type: this.replaceBy.type,
          replace_by_user_id: this.replaceBy[this.replaceBy.type],
        }).then((response) => {
          this.$store.dispatch('ui/msgbox/open', { title: this.$t('MANAGE.CONFIG.REPLACE_USER.REPLACEMENT_SUCCESSFUL'), body: response && response.data && response.data.message || "" });
          this.replace.user = null;
          this.replace.group = null;
          this.replace.username = '';
          this.loadPlanningsContent();
        }).catch((message) => {
          if (message) this.$store.dispatch('ui/msgbox/open', { title: this.$t('MANAGE.CONFIG.REPLACE_USER.ERROR_NOT_MODIFIED'), body: message || "" });
        }).then(() => {
          this.massReplaceInProgress = false;
        });
      },
    },
  };
</script>
