<template>
  <div :class="{ newauthor: newauthor, lastofauthor: lastofauthor }" class="exchange-line">
    <div v-if="newday" class="text-center primary--text" style="padding:6px;">
      <strong class="small">{{ exchange.date | moment('calendar') }}</strong>
    </div>

    <div v-if="newauthor" :title="exchange.user.email" class="avatar-tag">
      <img :src="exchange.user.avatar" class="avatar">
    </div>
    <div class="comment-block">
      <div v-if="newauthor">
        <strong>{{ exchange.user | username | defaultText('Anonym') }}</strong> <span class="text-muted small">{{ exchange.date | moment('time') }}</span>
      </div>

      <div class="chatText">
        <div>
          <div v-if="exchange.text" ref="textcontent" style="display:inline-block" v-html="enrichedContent(exchange.text)"></div>
          <span v-if="exchange.edition_date && exchange.edition_date !== exchange.date" :title="exchange.edition_date | moment('calendarOrDatetime')" class="text-muted small">
            ({{ $t('EXCHANGECENTER.EDITED') }})
          </span>
          <span v-if="exchange.waiting" style="margin-left: 7px"><i class="fas fa-spinner fa-spin fa-fw"></i></span>
        </div>
        <div v-if="exchange.file" class="file-box">
          <div><a :href="exchange.file.url" target="_blank">{{ exchange.file.name }}</a></div>
          <img v-if="exchange.file.type == 'image'" :src="exchange.file.url" class="file-img" @load="$emit('autoscroll')">
        </div>
        <div v-if="me && exchange.user.id == me.id" class="chatText-menu">
          <v-btn :title="$t('EXCHANGECENTER.EDIT_MESSAGE')" icon small class="ma-0" @click="$emit('edit')">
            <v-icon small>fas fa-edit</v-icon>
          </v-btn>
          <v-btn :title="$t('EXCHANGECENTER.DELETE_MESSAGE')" icon small class="ma-0" @click="$emit('delete')">
            <v-icon color="errorred" small>far fa-trash-alt</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
  .chatContent .exchange-line {
    position: relative;
  }
  .chatContent .exchange-line.newauthor {
    margin-top: 15px;
  }

  .chatContent .avatar-tag {
    position: absolute;
    width: 40px;
    height: 40px;
  }

  .chatContent .avatar {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
  }

  .chatContent .comment-block {
    margin-left: 47px;
  }
  .chatContent .exchange-line.newauthor .comment-block {
    min-height: 40px;
  }

  .chatContent .chatText {
    position: relative;
    z-index: 100;
    padding-left: 10px;
    background: #eee;
  }
  .chatContent .exchange-line.newauthor .chatText {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding-top: 4px;
  }
  .chatContent .exchange-line.lastofauthor .chatText {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-bottom: 4px;
  }
  .chatContent .chatText:hover {
    background: #d9d9d9;
  }
  .chatContent .chatText .file-box {
    display: inline-block;
    border-radius: 3px;
    border: 1px solid #777;
    padding: 7px;
    margin: 2px 0px;
  }
  .chatContent .chatText .file-img {
    max-height: 120px;
    max-width: 100%;
    margin-top: 2px;
  }
  .chatContent .chatText-menu {
    position: absolute;
    top: 0px;
    right: 4px;
    display: none;
  }
  .chatText:hover .chatText-menu {
    display: block;
  }
</style>

<script>
  import emojis from './emojis';

  const rEmojis = new RegExp(`:(${emojis.join("|")}):`, "g");

  export default {
    props: {
      exchange: { type: Object, required: true },
      prevExchange: { type: Object, default: null },
      nextExchange: { type: Object, default: null },
    },
    computed: {
      newauthor() {
        return ! (this.prevExchange && this.exchange.user.id == this.prevExchange.user.id && moment(this.exchange.date).diff(this.prevExchange.date, 'minutes') < 60);
      },
      lastofauthor() {
        return ! (this.nextExchange && this.exchange.user.id == this.nextExchange.user.id && moment(this.nextExchange.date).diff(this.exchange.date, 'minutes') < 60);
      },
      newday() {
        return ! (this.prevExchange && moment(this.exchange.date).format('YYYY MM DD') == moment(this.prevExchange.date).format('YYYY MM DD'));
      },
      me() {
        return this.$store.state.users.user;
      },
    },
    mounted() {
      const vm = this;
      $(this.$refs.textcontent).delegate("a[data-select]", "click", function (event) {
        event.preventDefault();
        const $this = $(this);
        vm.$emit(`select-${$this.data('select')}`, $this.data('value'));
      });
    },
    beforeDestroy() {
      $(this.$refs.textcontent).undelegate();
    },
    methods: {
      enrichedContent(text) {
        let enrichedText = text || "";
        enrichedText = enrichedText.replace(/(^|\s)(@[^ ]*)\b/g, '$1<a class="pointer" data-select="at" data-value="$2">$2</a>'); // @something
        enrichedText = enrichedText.replace(/(^|\s)(#[^ ]*)\b/g, '$1<a class="pointer" data-select="hashtag" data-value="$2">$2</a>'); // #something
        enrichedText = enrichedText.replace(/(https?:\/\/[a-zA-Z0-9._-]{4,})/gi, '<a href="$1" target="_blank">$1</a>'); // http(s)://
        enrichedText = enrichedText.replace(/:\)/g, ':smile:').replace(/;\)/g, ':wink:')
          .replace(rEmojis, (match, txt) => `<i class='emoji emoji_${txt}' title=':${txt}:'>${txt}</i>`);
        return enrichedText;
      },
    },
  };
</script>
