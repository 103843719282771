<template>
  <v-container class="pa-6">
    <b class="primary--text text-uppercase">{{ $t('IMPORT_TEMPLATE.TITLE') }}</b>
    <div class="px-6">
      <div class="small text-muted py-1">{{ $t('IMPORT_TEMPLATE.DESCRIPTION') }}</div>
      <v-autocomplete id="inputSubproject" v-model="selectedProject" :label="$t('PROJECTS.SEARCH_PROJECT_TITLE')" :items="projectsAvailableAsTemplate"
                      :disabled="! projects.length" :loading="! projects.length" item-text="title" item-value="id" return-object>
      </v-autocomplete>
    </div>
    <div :style="{ opacity: selectedProject ? null : .3, 'pointer-events': selectedProject ? null : 'none' }" class="mt-2">
      <b class="primary--text text-uppercase">{{ $t('GLOBAL.OPTIONS') }}</b>
      <div class="px-6">
        <div class="display-flex">
          <div class="text-center" style="width: 32px; flex: 0 0 auto !important; margin-top: 20px;">
            <v-icon small color="primary">fas fa-calendar-alt</v-icon>
          </div>
          <div class="display-flex ml-2 flex-grow-1">
            <v-checkbox v-model="changeDates" color="primary" class="flex-grow-1">
              <template #label>
                <span class="secondary--text">{{ $t('IMPORT_TEMPLATE.ADJUST_DATES') }}</span>
              </template>
            </v-checkbox>
            <date-field v-model="startdate" :label="$t('TIMELINE.START_DATE')" :disabled="! changeDates" format="dddd DD MMMM YYYY" readonly no-null hide-details></date-field>
          </div>
        </div>
        <div class="display-flex">
          <div class="text-center" style="width: 32px; flex: 0 0 auto !important; margin-top: 22px;">
            <v-icon small color="primary">fas fa-th-list</v-icon>
          </div>
          <div class="ml-2">
            <v-select v-model="selectedLanesList" :items="availableLanes" :label="$t('EXPORT.LANES')" :placeholder="selectedLanesList.length ? '' : $t('IMPORT_TEMPLATE.SELECT_LANES')"
                      :disabled="! templateContent" :loading="selectedProject && ! templateContent" item-value="id" item-text="label" multiple hide-details>
              <template #prepend-item>
                <filter-toggle :selected-ids="selectedLanesList" :sublist-ids="availableLanesIds"
                               @update="selectedLanesList = $event">
                  {{ $t('EXPORT.ALL_LANES') }} ({{ availableLanesIds.length }})
                </filter-toggle>
                <v-divider></v-divider>
              </template>
              <template #selection="{ item, index }">
                <span v-if="selectedLanesList.length == 1">{{ item.label }}</span>
                <span v-else-if="selectedLanesList.length == availableLanes.length && index === 0">{{ $t('EXPORT.ALL_LANES') }} ({{ availableLanes.length }})</span>
                <span v-else-if="index === 0">{{ selectedLanesList.length }}/{{ availableLanes.length }}</span>
              </template>
            </v-select>
            <v-switch v-model="mergeLanes" :true-value="0" :false-value="null" color="primary" hide-details>
              <template #label>
                <span class="secondary--text">{{ $t('IMPORT_TEMPLATE.MERGE_LANES') }}</span>
              </template>
            </v-switch>
          </div>
        </div>

        <v-expansion-panels v-if="templateContent" v-model="mergeLanes" class="mt-3 ml-4">
          <v-expansion-panel>
            <v-expansion-panel-content>
              <div class="pt-2 table-responsive">
                <table class="table mb-0" style="z-index: 2000; position: relative">
                  <thead>
                    <tr>
                      <td class="text-center" style="width: 50%"><b class="text-uppercase">{{ $t('IMPORT_TEMPLATE.TEMPLATE_LANE') }}</b></td>
                      <td class="text-center"><v-icon color="secondary">fas fa-caret-right</v-icon></td>
                      <td class="text-center" style="width: 50%"><b class="text-uppercase">{{ $t('IMPORT_TEMPLATE.DESTINATION_LANE') }}</b></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="lane in selectedLanes" :key="lane.id">
                      <td>{{ lane.label }}</td>
                      <td><v-icon color="secondary">fas fa-caret-right</v-icon></td>
                      <td><v-select v-model="lane.destination" :items="destinationLanes" hide-details class="ma-0 pa-0"></v-select></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
    <v-divider class="mt-6 mb-3"></v-divider>
    <div class="text-center">
      <v-btn :disabled="! templateContent || importing" :loading="importing" color="accent" rounded @click="importTemplate()">
        {{ $t('IMPORT_TEMPLATE.IMPORT') }}
      </v-btn>
    </div>
  </v-container>
</template>

<script>
  import clientConfig from '@/client_customs/config';
  import Planning from '@/models/Planning';
  import ProjectSrv from '@/components/Projects/ProjectSrv';

  export default {
    props: {
      planning: { type: Object, required: true },
    },
    data() {
      return {
        projects: [],
        selectedProject: null,
        templateContent: null,
        changeDates: clientConfig.importTemplate && clientConfig.importTemplate.changeDates && true || false,
        startdate: moment(),
        selectedLanesList: [],
        mergeLanes: clientConfig.importTemplate && clientConfig.importTemplate.mergeLanes ? 0 : null,
        loadingSubproject: false,
        importing: false,
      };
    },
    computed: {
      projectsAvailableAsTemplate() {
        const projects = clientConfig.projects && clientConfig.projects.filterTemplateName ? this.projects.filter(clientConfig.projects.filterTemplateName) : this.projects.slice();
        return projects.sort((a, b) => (a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1));
      },
      availableLanes() {
        if (! this.templateContent) return [];
        return this.templateContent.lanes.map(item => ({ id: item.id, label: item.label, destination: null }));
      },
      availableLanesIds() {
        return this.availableLanes.map(item => item.id);
      },
      selectedLanes() {
        if (this.selectedLanesList === "") this.selectedLanesList = this.availableLanes.map(item => item.id); // IE is sometimes receiving empty string for newVal. No idea why.
        return this.availableLanes.filter(item => this.selectedLanesList.find(id => id == item.id));
      },
      destinationLanes() {
        const destinationLanes = this.planning.lanes.map(lane => ({ value: lane.id, text: lane.label }));
        destinationLanes.unshift({ value: null, text: this.$t('IMPORT_TEMPLATE.CREATE_LANE') });
        return destinationLanes;
      },
    },
    watch: {
      selectedProject(newVal) {
        if (! newVal) return;
        this.templateContent = null;
        this.loadingSubproject = true;
        ProjectSrv.get(newVal.id).then((content) => {
          this.templateContent = content;
        }).finally(() => {
          this.loadingSubproject = false;
        });
      },
      availableLanes() {
        this.selectedLanesList = this.availableLanes.map(item => item.id);
      },
    },
    created() {
      ProjectSrv.list().then((data) => {
        this.projects = data;
      });
    },
    methods: {
      close() {
        this.$emit('close');
      },
      importTemplate() {
        if (! this.templateContent) return;
        const templateContent = { ...this.templateContent };
        templateContent.lanes = templateContent.lanes.filter(lane => this.selectedLanes.find(item => item.id == lane.id));
        templateContent.elements = templateContent.elements.filter(el => this.selectedLanes.find(item => item.id == el.lane_id));
        const template = new Planning(ProjectSrv.projectFromTemplate({ template: templateContent, startdate: this.changeDates && this.startdate }));
        const promises = [];
        this.importing = true;
        setTimeout(() => {
          const elementsMap = new Map();

          template.lanes.forEach((templateLane) => {
            const selectedLane = this.selectedLanes.find(item => item.id == templateLane.id);
            if (! selectedLane) return;

            let newLanePromise;
            if (this.mergeLanes === 0 && selectedLane.destination) {
              newLanePromise = Promise.resolve(this.planning.lanes.find(lane => lane.id == selectedLane.destination));
            } else {
              newLanePromise = this.$store.dispatch('planning/lanes/addLane', templateLane);
            }
            newLanePromise = newLanePromise.then((newlane) => {
              const templateLaneElements = template.elements.filter(templateEl => templateEl.getLaneId() == templateLane.id);
              const argstab = templateLaneElements.map(templateEl => ({ type: templateEl.getType(), lane: newlane, el: templateEl }));
              return this.$store.dispatch('planning/elements/addElements', { argstab }).then((newels) => {
                newels.forEach((newel, elIndex) => {
                  newel.setLaneId(newlane.id);
                  elementsMap.set(templateLaneElements[elIndex], newel);
                });
              });
            });
            promises.push(newLanePromise);
          });
          if (promises.length) {
            Promise.all(promises).then(() => {
              // restore dependencies
              const elementsMapIds = {};
              elementsMap.forEach((newel, templateEl) => {
                elementsMapIds[templateEl.id] = newel.id;
              });
              elementsMap.forEach((newel, templateEl) => {
                const dependencies = templateEl.getDependencies();
                if (! dependencies) return;
                const newDependencies = [];
                dependencies.forEach((dependency) => {
                  const targetId = elementsMapIds[dependency.target_id];
                  if (! targetId) return;
                  dependency.target_id = targetId;
                  newDependencies.push(dependency);
                });
                if (newDependencies.length) newel.setDependencies(newDependencies);
              });

              this.$store.dispatch('planning/save', null);
            });
          }
          this.importing = false;

          this.close();
        });
      },
    },
  };
</script>
