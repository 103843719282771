<template>
  <v-container class="microsoftteams-config text-center" style="max-width: 500px; position: relative;">
    <template v-if="! displayLoginWindow">
      <!-- Projects List -->
      <div v-if="dataLoaded" class="px-3">
        <label class="primary--text text-uppercase">{{ $t('INTEGRATIONS.MICROSOFTTEAMS.SELECT_PROJECT') }}</label>
        <v-btn-toggle v-model="accessRight" mandatory rounded>
          <v-btn value="modify" class="button-multiline py-1" style="width: 50%">
            <v-icon small class="mr-2">fas fa-pencil-alt</v-icon>
            <span style="white-space: normal;">{{ $t('INTEGRATIONS.MICROSOFTTEAMS.ACCESS_RIGHT_MODIFY') }}</span>
          </v-btn>
          <v-btn value="consult" class="button-multiline py-1" style="width: 50%">
            <v-icon small class="mr-2">fas fa-eye</v-icon>
            <span style="white-space: normal;">{{ $t('INTEGRATIONS.MICROSOFTTEAMS.ACCESS_RIGHT_READ_ONLY') }}</span>
          </v-btn>
        </v-btn-toggle>
        <project-preview v-for="project in projects" :key="project.id" :project="project" :thumbnail="project.imgurl" :thumbnail-percent="30"
                         :class="{ 'microsoftteams-project-selected': selectedProject == project }" class="pointer my-4" @click.native="selectProject(project)">
        </project-preview>
        <v-btn v-if="! projects.length" :href="createProjectLink" target="_blank" text block>{{ $t('INTEGRATIONS.MICROSOFTTEAMS.CREATE_PROJECT') }}</v-btn>
      </div>
      <div v-else>
        <div class="py-6"><i class="fas fa-spinner fa-spin fa-3x"></i></div>
      </div>
    </template>
    <div v-else-if="displayLoginBtn" class="pt-10">
      <!-- Login Button -->
      <div class="mb-6">
        <img src="../../../img/bubble-plan-logo-color.png" style="height: 42px;">
      </div>
      <v-btn type="submit" color="primary" rounded large @click="displayLoginBtn = false">
        {{ $t('INTEGRATIONS.MICROSOFTTEAMS.CONNECT_ACCOUNT') }}
      </v-btn>
      <div v-if="createAccountLink" class="mt-6">
        <v-btn :href="createAccountLink" target="_blank" text block>{{ $t('LOGIN.CREATE_ACCOUNT') }}</v-btn>
      </div>
    </div>
    <div v-else>
      <!-- Login Window -->
      <login-window></login-window>
    </div>
  </v-container>
</template>

<style>
  .microsoftteams-config .project-preview .project-preview-textbox {
    transition: all .3s;
  }
  .microsoftteams-config .project-preview:hover .project-preview-textbox {
    margin-left: 22% !important;
    padding-left: calc(4% + 8px);
    padding-right: calc(4% + 8px);
  }

  .microsoftteams-project-selected {
    border: 2px solid #00b100;
  }

  .microsoftteams-config .modal-with-tabs, .microsoftteams-config .login-body {
    max-height: none !important;
  }

  .microsoftteams-config .button-multiline {
    font-size: 0.75rem;
    height: auto !important;
  }
  .microsoftteams-config .button-multiline .v-btn__content {
    flex: 1 1 auto;
  }
</style>

<script>
  import clientConfig from '@/client_customs/config';
  import ProjectSrv from '@/components/Projects/ProjectSrv';
  import ProjectPreview from '@/components/Projects/ProjectPreview';
  import LoginWindow from '@/components/User/LoginWindow';

  export default {
    components: {
      ProjectPreview,
      LoginWindow,
    },

    data() {
      return {
        microsoftTeams: {},
        projects: [],
        displayLoginBtn: true,
        displayLoginWindow: false,
        dataLoaded: false,
        selectedProject: null,
        accessRight: 'modify',
        createAccountLink: clientConfig.login && clientConfig.login.createAccountLink,
      };
    },
    computed: {
      createProjectLink() {
        const baseUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname || '/'}`;
        return `${baseUrl}#/projects`;
      },
    },
    created() {
      import('@microsoft/teams-js').then((teams) => {
        this.microsoftTeams = teams;
        this.microsoftTeams.initialize();
        // Get projects list
        ProjectSrv.list(true).then((data) => {
          this.projects = data;
          this.dataLoaded = true;
        }).catch(() => {
          this.displayLoginWindow = true;
        });
      });
    },
    methods: {
      selectProject(project) {
        this.selectedProject = project;
        this.updateTeamsParams(project);
        this.microsoftTeams.settings.setValidityState(true);
      },
      updateTeamsParams(project) {
        this.microsoftTeams.settings.registerOnSaveHandler((saveEvent) => {
          const baseUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname || '/'}`;
          const routePath = this.accessRight == 'consult' ? `#/viewer/${project.id}` : `#/planning/${project.id}`;
          const routeQuery = this.accessRight == 'consult' ? `?rotoken=${project.read_only_token}` : '';
          this.microsoftTeams.settings.setSettings({
            entityId: `planning${project.id}`,
            suggestedDisplayName: project.title || this.$t('PLANNING.UNNAMED_PROJECT'),
            websiteUrl: `${baseUrl}${routePath}/${window.slugify(project.title || 'New project')}${routeQuery}`,
            contentUrl: `${baseUrl}?integration=microsoftteams${routePath}${routeQuery}`,
            removeUrl: `${baseUrl}?integration=microsoftteams#/integrations/microsoftteams/deletion`,
          });
          saveEvent.notifySuccess();
        });
      },
    },
  };
</script>
