import Vue from 'vue';
import VueI18n from 'vue-i18n';
import EN from '@/lang/en.json';
import FR from '@/lang/fr.json';
import vuetifyFR from '@/lang/vuetifyFR';
import vuetifyEN from '@/lang/vuetifyEN';
import clientConfig from '@/client_customs/config';

Vue.use(VueI18n);

const initLang = window.localStorageWrapper.getItem('lang') || 'en';
const i18n = new VueI18n({
  locale: initLang,
  fallbackLocale: 'en',
  messages: {
    en: { ...vuetifyEN, ...angular.merge(EN, clientConfig.lang && clientConfig.lang.en) },
    fr: { ...vuetifyFR, ...angular.merge(FR, clientConfig.lang && clientConfig.lang.fr) },
  },
});

const availableLanguages = ['en', 'fr'];
const loadedLanguages = ['en', 'fr'];

function getFirstBrowserLanguage() {
  const nav = window.navigator;
  const browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'];
  // support for HTML 5.1 "navigator.languages"
  if (_.isArray(nav.languages)) {
    for (let i = 0; i < nav.languages.length; i++) {
      const language = nav.languages[i];
      if (language && language.length) {
        return language;
      }
    }
  }
  // support for other well known properties in browsers
  for (let i = 0; i < browserLanguagePropertyKeys.length; i++) {
    const language = nav[browserLanguagePropertyKeys[i]];
    if (language && language.length) {
      return language;
    }
  }
  return null;
}

function setLang(state, lang) {
  state.lang = lang;
  state.i18n.locale = lang;
  window.localStorageWrapper.setItem('lang', lang);
  document.querySelector('html').setAttribute('lang', lang);
}

function loadLang({ state, commit }, langParam = 'en') {
  const lang = availableLanguages.includes(langParam) ? langParam : 'en';
  if (state.i18n.locale !== lang) {
    if (! loadedLanguages.includes(lang)) {
      return import(/* webpackChunkName: "lang-[request]" */ `@/lang/${lang}.json`).then((msgs) => {
        state.i18n.setLocaleMessage(lang, { [lang]: msgs });
        loadedLanguages.push(lang);
        commit('setLang', lang);
        return lang;
      });
    }
    commit('setLang', lang);
    return Promise.resolve(lang);
  }
  commit('setLang', lang);
  return Promise.resolve(lang);
}

function loadLocale({ dispatch }, langParam = window.localStorageWrapper.getItem('lang')) {
  let lang = availableLanguages.includes(langParam) ? langParam : '';
  if (! lang) {
    const browserLanguage = (getFirstBrowserLanguage() || '').slice(0, 2);
    lang = availableLanguages.includes(browserLanguage) ? browserLanguage : '';
  }
  lang = lang || 'en';
  dispatch('loadLang', lang);
}

export default {
  namespaced: true,
  state: {
    i18n,
    lang: initLang,
  },
  mutations: {
    setLang,
  },
  getters: {
    getCategoryColor() { // TODO : MOVE TO CONFIG
      return (category) => {
        if (! category) return 'fff';
        return clientConfig.categoryColors && clientConfig.categoryColors[category] || window.str2color(category);
      };
    },
  },
  actions: {
    loadLang,
    loadLocale,
  },
};
