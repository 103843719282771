import Vue from 'vue';
import PlanningTimeline from '@/models/PlanningTimeline';
import clientConfig from '@/client_customs/config';

let undoState = {};

function updateVisibleTimeline(context) {
  const { state: timeline, rootState } = context;
  const mainStep = timeline.getLastStep();
  const hidden = {
    before: timeline.getHiddenBefore(),
    after: timeline.getHiddenAfter(),
  };

  // Locale
  const timelocale = timeline.getTimelocale();
  moment.locale(timelocale);

  const timelineFormats = clientConfig.timeline && clientConfig.timeline.timelineFormats || {
    en: [
      { years: 'YYYY', quarters: '[Q]Q YYYY', months: 'MMMM YY', weeks: '[W]w YYYY', days: 'dd DD-MM-YYYY' },
      { years: 'YYYY', quarters: '[Q]Q YYYY', months: 'MMM', weeks: '[W]w', days: 'dd DD' },
    ],
    fr: [
      { years: 'YYYY', quarters: '[T]Q YYYY', months: 'MMMM YY', weeks: '[S]w YYYY', days: 'dd DD-MM-YYYY' },
      { years: 'YYYY', quarters: '[T]Q YYYY', months: 'MMM', weeks: '[S]w', days: 'dd DD' },
    ],
  };

  let timelineFormat;
  if (timeline.formats) {
    timelineFormat = timeline.formats;
  } else if ((timelocale).substr(0, 2) == 'fr') {
    timelineFormat = timelineFormats.fr;
  } else {
    timelineFormat = timelineFormats.en;
  }

  //  ** Minute per pixel ** // set basis for col medium width
  const mainStepDuration = moment("2015-06-01T00:00:00").add(1, mainStep).diffWithWorkdays("2015-06-01T00:00:00", 'minutes', timeline.getWorkdays());
  const minuteperpx = mainStepDuration / timeline.getColWidth();

  // ** Cols **
  const timelineCols = [];

  for (let stepIndex = 0; stepIndex < timeline.getSteps().length; stepIndex++) {
    const stepUnit = timeline.getSteps()[stepIndex];
    timelineCols[stepIndex] = [];

    let submoment = moment(hidden.before);
    submoment.locale(timelocale);
    let widthRest = 0;
    const workdays = timeline.getWorkdays();
    while (submoment.isBefore(hidden.after, 'minute')) {
      const nextsubmoment = moment.min(moment(submoment).addWithWorkdays(1, stepUnit, workdays).startOf(stepUnit), hidden.after);
      const label = submoment.format(timelineFormat[stepIndex][stepUnit]);
      const endtime = moment.min(moment(submoment).endOf(stepUnit).addWithWorkdays(1, 'second', workdays), moment(hidden.after));
      const colspan = endtime.diffWithWorkdays(submoment, 'minutes', workdays) / mainStepDuration;
      const decimalWidth = colspan * timeline.getColWidth() + widthRest;
      const width = Math.round(decimalWidth);
      widthRest = decimalWidth - width;

      if (width > 0) timelineCols[stepIndex].push({ label, colspan: Math.round(colspan * 10000) / 10000, width, starttime: submoment.format(), endtime: endtime.format() });
      submoment = nextsubmoment;
    }
  }

  const timelinecols = timelineCols[0];
  const subtimelinecols = timelineCols[1] || [];

  // ** Pixel width **
  const timelinepxwidth = timelinecols.reduce((acc, col) => acc + col.width, 0);
  const subtimelinepxwidth = subtimelinecols.reduce((acc, col) => acc + col.width, 0);

  // Adjust timeline width to subtimelinewidth (due to width rounding; should not be more than 1px thanks to widthRest)
  let pxwidth;
  if (subtimelinecols.length) {
    let adjustment = subtimelinepxwidth - timelinepxwidth;
    for (let i = timelinecols.length - 1; i >= 0; i--) {
      timelinecols[i].width += adjustment;
      if (timelinecols[i].width >= 0) break;
      adjustment = timelinecols[i].width;
      timelinecols[i].width = 0;
    }
    pxwidth = subtimelinepxwidth;
  } else {
    pxwidth = timelinepxwidth;
  }

  // ** Presenttime Width **
  const presenttimeWidth = Math.round(moment().diffWithWorkdays(hidden.before, 'minutes', timeline.getWorkdays()) / minuteperpx);

  Vue.set(rootState.planning, 'visibleTimeline', {
    starttime: hidden.before.format(),
    endtime: hidden.after.format(),
    pxwidth,
    minuteperpx,
    timelinecols,
    subtimelinecols,
    presenttimeWidth,
    workdays: timeline.getWorkdays(),
    show_presenttime: timeline.getShowPresenttime(),
    show_timegrid: timeline.getShowTimegrid(),
  });
}

/** ******* */
/** INIT * */
/** ******* */
function set(context, newVals) {
  const { state: timeline, dispatch } = context;
  if (Object.getOwnPropertyNames(newVals).length === 0) newVals = new PlanningTimeline();
  timeline.set(newVals);

  if (! timeline.getColWidth()) timeline.setColWidth(timeline.bestColWidth());

  updateVisibleTimeline(context);
  dispatch('planning/changeTimeline', timeline, { root: true });
}

function undoredoCols(context, oldConfig) {
  const { state: timeline, rootState, dispatch } = context;
  if (angular.equals(timeline, undoState) && (! oldConfig || angular.equals(rootState.planning.config, oldConfig))) return;
  const newState = angular.copy(timeline);
  const oldState = angular.copy(undoState);
  let newConfig;
  if (oldConfig) {
    newConfig = angular.copy(rootState.planning.config);
  }
  dispatch('undoredo/add', {
    action: () => {
      if (oldConfig) dispatch('planning/config/set', angular.copy(newConfig), { root: true });
      set(context, angular.copy(newState));
    },
    rollback: () => {
      if (oldConfig) dispatch('planning/config/set', angular.copy(oldConfig), { root: true });
      set(context, angular.copy(oldState));
    },
  }, { root: true });
  dispatch('planning/save', null, { root: true });
}

/* ACTIONS */
function addCol(context, { where, stepIndex }) {
  const { state: timeline, dispatch } = context;
  undoState = angular.copy(timeline);

  let mainStep;
  if (typeof stepIndex == 'number' && stepIndex >= 0 && stepIndex < timeline.getSteps().length) {
    mainStep = timeline.getSteps()[stepIndex];
  } else {
    mainStep = timeline.getLastStep();
  }

  if (where == 'first') {
    const newHorizon = timeline.getHiddenBefore().startOf(mainStep);
    if (! newHorizon.isBefore(timeline.getHiddenBefore(), 'minute')) newHorizon.addWithWorkdays(-1, mainStep, timeline.getWorkdays());
    timeline.setHiddenBefore(newHorizon);
  } else {
    const newHorizon = timeline.getHiddenAfter().endOf(mainStep);
    if (! newHorizon.isAfter(timeline.getHiddenAfter(), 'minute')) newHorizon.addWithWorkdays(1, mainStep, timeline.getWorkdays());
    timeline.setHiddenAfter(newHorizon);
  }

  updateVisibleTimeline(context);
  dispatch('planning/changeTimeline', timeline, { root: true });
  undoredoCols(context);

  if (where == "first") {
    $("#table-wrapper").scrollLeft(0);
  } else {
    setTimeout(() => { $("#table-wrapper").scrollLeft(99999); }, 0);
  }
}

function hideCol(context, { where, stepIndex }) {
  const { state: timeline, dispatch } = context;
  undoState = angular.copy(timeline);

  let mainStep;
  if (typeof stepIndex == 'number' && stepIndex >= 0 && stepIndex < timeline.getSteps().length) {
    mainStep = timeline.getSteps()[stepIndex];
  } else {
    mainStep = timeline.getLastStep();
  }

  if (where == 'first') {
    const newHorizon = timeline.getHiddenBefore().addWithWorkdays(1, mainStep, timeline.getWorkdays()).startOf(mainStep);
    timeline.setHiddenBefore(newHorizon);
  } else {
    const newHorizon = timeline.getHiddenAfter().addWithWorkdays(-1, mainStep, timeline.getWorkdays()).endOf(mainStep);
    timeline.setHiddenAfter(newHorizon);
  }

  updateVisibleTimeline(context);
  dispatch('planning/changeTimeline', timeline, { root: true });
  undoredoCols(context);
}

function updateTimeline(context, newVals) {
  const { state: timeline } = context;
  undoState = angular.copy(timeline);
  set(context, newVals);
  undoredoCols(context);
}

export default {
  namespaced: true,
  state: {},
  actions: {
    addCol,
    hideCol,
    updateTimeline,
    set,
  },
};
