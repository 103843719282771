<template>
  <v-row no-gutters class="flex-nowrap">
    <v-col style="min-width: 0; max-height: 90vh; min-height: 404px; padding-bottom: 6px; padding-top: 6px; background-color: #f9f9f9; border-radius: 6px;">
      <div class="element-details-content">
        <div class="small text-muted text-center" style="margin-bottom: 6px">{{ $t('ELEMENT-DETAIL.CHANGES_ARE_SAVED_AUTOMATICALLY') }}</div>
        <v-card id="edcard-header" key="header" class="list-fade-item">
          <element-details-header :el="el" @close="close"></element-details-header>
        </v-card>
        <v-card id="edcard-sub-tasks" key="sub-tasks" class="list-fade-item"><macro-element-details-sub-tasks :el="el"></macro-element-details-sub-tasks></v-card>
        <v-card id="edcard-progress" key="progress" class="list-fade-item"><element-details-progress :el="el"></element-details-progress></v-card>
        <v-card id="edcard-users" key="users" class="list-fade-item"><element-details-users :el="el"></element-details-users></v-card>
        <v-card id="edcard-links" key="links" class="list-fade-item"><element-details-links :el="el"></element-details-links></v-card>
        <v-card id="edcard-alerts" key="alerts" class="list-fade-item"><element-details-alerts :el="el"></element-details-alerts></v-card>
        <v-card id="edcard-budgets" key="budgets" class="list-fade-item"><element-details-budget :el="el"></element-details-budget></v-card>
        <v-card key="comments" class="list-fade-item"><element-details-comments :el="el"></element-details-comments></v-card>
      </div>
    </v-col>

    <!--      COLUMN MENU-->
    <v-col style="width: 52px; flex: 0 0 52px; display: flex; flex-direction: column; max-height: 90vh; min-height: 404px;">
      <div class="element-details-menu" style="flex: 1; display: flex; flex-direction: column;">
        <div>
          <v-tooltip left>
            <template #activator="{ on }">
              <v-btn depressed block class="px-1 my-1" v-on="on" @click="displayCard('sub-tasks')">
                <v-icon small>fas fa-fw fa fa-sitemap</v-icon>
              </v-btn>
            </template>
            {{ $t('ELEMENT-DETAIL.SUB_TASKS') }}
          </v-tooltip>
          <v-tooltip left>
            <template #activator="{ on }">
              <v-btn depressed block class="px-1 my-1" v-on="on" @click="displayCard('progress')">
                <v-icon small>fas fa-fw fa-long-arrow-alt-right</v-icon>
              </v-btn>
            </template>
            {{ $t('ELEMENT-DETAIL.PROGRESS') }}
          </v-tooltip>
          <v-tooltip left>
            <template #activator="{ on }">
              <v-btn depressed block class="px-1 my-1" v-on="on" @click="displayCard('users')">
                <v-icon small>fas fa-fw fa-user</v-icon>
              </v-btn>
            </template>
            {{ $t('ELEMENT-DETAIL.USERS') }}
          </v-tooltip>
          <v-tooltip left>
            <template #activator="{ on }">
              <v-btn depressed block class="px-1 my-1" v-on="on" @click="displayCard('links')">
                <v-icon small>fas fa-fw fa-paperclip</v-icon>
              </v-btn>
            </template>
            {{ $t('ELEMENT-DETAIL.ATTACHMENTS') }}
          </v-tooltip>
          <v-tooltip left>
            <template #activator="{ on }">
              <v-btn depressed block class="px-1 my-1" v-on="on" @click="displayCard('alerts')">
                <v-icon small>fas fa-fw fa-alarm-exclamation</v-icon>
              </v-btn>
            </template>
            {{ $t('ELEMENT-DETAIL.ALERTS') }}
          </v-tooltip>
          <v-tooltip left>
            <template #activator="{ on }">
              <v-btn depressed block class="px-1 my-1" v-on="on" @click="displayCard('budgets')">
                <v-icon small>fas fa-fw fa-euro-sign</v-icon>
              </v-btn>
            </template>
            {{ $t('ELEMENT-DETAIL.BUDGET') }}
          </v-tooltip>
        </div>

        <div class="mt-auto mb-2 text-center">
          <v-tooltip left>
            <template #activator="{ on }">
              <v-btn icon small outlined color="accent" v-on="on" @click="close('select-color')">
                <v-icon :color="el.getIcon().color || 'accent'" small>{{ el.getIcon().name || 'fa-tint' }}</v-icon>
              </v-btn>
            </template>
            {{ $t('PLANNING.SELECT_ICON_AND_COLOR') }}
          </v-tooltip>
          <v-menu :close-on-content-click="false" :max-width="200" top offset-y left>
            <template #activator="{ on: onMenu }">
              <v-tooltip left>
                <template #activator="{ on: onTooltip }">
                  <v-btn small color="secondary" icon outlined class="mt-2" v-on="{ ...onMenu, ...onTooltip }">
                    <v-icon small>far fa-cog</v-icon>
                  </v-btn>
                </template>
                {{ $t('ELEMENT-DETAIL.LANE_PRIORITY_DELETE') }}
              </v-tooltip>
            </template>
            <element-details-positioning-menu :el="el" :options="options" @close="close($event)"></element-details-positioning-menu>
          </v-menu>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<style>
  .element-details-content .sub-task-menu {
    visibility: hidden;
    opacity: 0;
    transition: opacity .3s;
  }
  .element-details-content tr:hover > td > .sub-task-menu {
    visibility: visible;
    opacity: 1;
  }
</style>

<script>
  import EventBus from '@/js/event-bus.js';
  import macroElement from '@/components/Planning/mixins/macroElement';
  import ElementDetailsHeader from '../ElementDetailsHeader';
  import ElementDetailsAlerts from '../ElementDetailsAlerts';
  import ElementDetailsComments from '../ElementDetailsComments';
  import MacroElementDetailsSubTasks from './MacroElementDetailsSubTasks';
  import ElementDetailsProgress from '../ElementDetailsProgress';
  import ElementDetailsUsers from '../ElementDetailsUsers';
  import ElementDetailsLinks from '../ElementDetailsLinks';
  import ElementDetailsBudget from '../ElementDetailsBudget';
  import ElementDetailsPositioningMenu from '../ElementDetailsPositioningMenu';

  const ua = window.navigator.userAgent;
  const isSlowBrowser = ua.indexOf('Trident/') > 0 || ua.indexOf('MSIE ') > 0;

  export default {
    components: {
      ElementDetailsHeader,
      ElementDetailsAlerts,
      ElementDetailsComments,
      MacroElementDetailsSubTasks,
      ElementDetailsProgress,
      ElementDetailsUsers,
      ElementDetailsLinks,
      ElementDetailsBudget,
      ElementDetailsPositioningMenu,
    },
    mixins: [macroElement],
    props: {
      element: { type: Object, required: true },
      options: { type: Object, default: () => ({}) },
    },
    data() {
      return {
        el: angular.copy(this.element),
        initialStateEl: angular.copy(this.element),
        planning: this.element.getPlanning(),
      };
    },
    computed: {
      elSubTasks() {
        return this.planning.elements.filter(item => this.elSubTasksId.has(parseInt(item.id, 10)));
      },
      elSubTasksId() {
        return new Set(this.el.getSubTasks());
      },
      elPriority: {
        get() {
          return this.el.getPriority();
        },
        set(newVal) {
          this.el.setPriority(newVal || null);
        },
      },
    },
    watch: {
      el: {
        handler: _.debounce(function () {
          this.element.reset(this.el.getAll());
        }, isSlowBrowser ? 500 : 100),
        deep: true,
      },
    },
    created() {
      EventBus.$on('project-updated', this.onProjectUpdate);
    },
    beforeDestroy() {
      EventBus.$off('project-updated', this.onProjectUpdate);
    },
    methods: {
      onProjectUpdate(modifications) { // project updated in background by another user or instance
        if (! modifications.elements[this.element.id]) return;
        const updatedElement = this.element;
        if (! updatedElement.getPlanning().elements.find(item => item.id == updatedElement.id)) {
          this.close();
          return;
        }
        if (angular.equals(updatedElement.getAll(), this.initialStateEl.getAll())) {
          updatedElement.set(this.el.getAll());
          updatedElement.modificationUser = null;
        } else {
          this.initialStateEl = angular.copy(updatedElement);
          this.el = this.initialStateEl;
        }
      },
      displayCard(prop) {
        const displayNode = document.getElementById(`edcard-${prop}`);
        if (displayNode && displayNode.scrollIntoView) setTimeout(() => displayNode.scrollIntoView({ behavior: 'smooth' }));
      },
      close(reason) {
        this.$emit('close', reason);
      },
    },
  };
</script>
