import store from '@/store';

class ApiStoredComment {
  constructor(data) {
    this.id = data.id;
    this.planning_id = data.planning_id;
    this.element_id = data.element_id;
    this.meeting_id = data.meeting_id;
    this.user = store.getters['users/getUserById'](data.user_id) || { id: data.user_id };
    this.date = moment(data.date_of_creation).format();
    this.text = data.text;
    this.file = data.file ? { name: data.file.display_filename, type: data.file.mime.split('/')[0], url: data.file.url } : null;
    this.edition_date = moment(data.date_of_modification).format();
  }

  getData() {
    return this;
  }

  editMessage(newtext) {
    return window.apiSrv.call('comments', 'update', { id: this.id, text: newtext });
  }

  store() {
    return window.apiSrv.call('comments', 'store', this).then((response) => {
      this.id = response && response.data && response.data.comment && response.data.comment.id || 0;
      return Promise.resolve({ data: { comment: this } });
    });
  }

  delete() {
    return window.apiSrv.call('comments', 'destroy', this.id);
  }

  static loadExchanges(config) {
    let api = { url: 'comments/meeting', id: config.meeting_id };
    if (config.planning_id) {
      if (config.element_id) {
        api = { url: `comments/planning/${config.planning_id}/element`, id: config.element_id };
      } else {
        api = { url: 'comments/planning', id: config.planning_id };
      }
    }
    return window.apiSrv.call(api.url, 'show', { id: api.id }).then((result) => {
      const data = result.data || {};
      const exchanges = [];
      (data.comments || []).forEach((comment) => {
        if (config.element_id && comment.element_id != config.element_id) return;
        if (! config.element_id && comment.element_id) return;
        exchanges.push(new ApiStoredComment(comment));
      });
      exchanges.sort((o1, o2) => (o1.date > o2.date ? 1 : -1));
      return Promise.resolve({ data: { exchanges } });
    });
  }
}

export default ApiStoredComment;
