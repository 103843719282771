<template>
  <div class="display-flex" style="flex-grow: 1; position: relative;">
    <!-- CHECKBOX-->
    <div class="display-flex align-center">
      <v-checkbox v-model="hidden" :title="hidden ? $t('CONFIG.SHOW_LANE_AND_CHILDREN') : $t('CONFIG.HIDE_LANE_AND_CHILDREN')" :true-value="false" :false-value="true"
                  :color="laneContrastedTextColor" :dark="laneContrastedTextColor == 'white'"
                  hide-details on-icon="far fa-check-square" class="mx-1 my-0 pa-0"
                  @change="checkboxUpdate(lane)">
      </v-checkbox>
    </div>
    <!-- LANE TEXT-->
    <div class="display-flex align-center py-1 mr-1" style="flex-grow: 1">
      <textarea-autosize v-if="textareaReady" v-model="lane.label" v-contentautoselect :style="lane.getLabelStyle()" spellcheck="false" rows="1"
                         style="width: 100%; background: transparent; resize: none; border: none; text-align: center"
                         @focus.native="startChangingContent(lane); setConfigLaneLabelSelected(lane.id, true)"
                         @blur.native="changingContent(lane); setConfigLaneLabelSelected(lane.id, false)">
      </textarea-autosize>
    </div>
    <!-- LANE ICONS-->
    <div class="display-flex align-center pa-1" style="background: rgba(0,0,0,0.3);">
      <!-- INDENT LANE-->
      <div class="display-flex align-center" style="width: 12px">
        <v-btn v-show="lane.level" :title="$t('PLANNING.INDENT_LANE')" icon x-small style="width: 8px"
               @click="setConfigLaneLevel(lane, lane.level - 1)">
          <v-icon small color="white" style="width: 8px">fas fa-caret-left</v-icon>
        </v-btn>
      </div>
      <div class="display-flex align-center" style="width: 12px">
        <v-btn v-show="lane.level <= 1" :title="$t('PLANNING.INDENT_LANE')" icon x-small style="width: 8px"
               @click="setConfigLaneLevel(lane, lane.level + 1)">
          <v-icon small color="white" style="width: 8px">fas fa-caret-right</v-icon>
        </v-btn>
      </div>
      <v-spacer class="mr-1"></v-spacer>
      <!-- SHOW AND HIDE LANE-->
      <v-btn :title="hidden ? $t('PLANNING.SHOW_LANE') : $t('PLANNING.HIDE_LANE')" icon x-small
             @click="updateConfigLane(lane)">
        <v-icon size="13" color="white">{{ hidden ? 'fas fa-eye' : 'fas fa-eye-slash' }}</v-icon>
      </v-btn>
      <v-spacer class="mr-1"></v-spacer>
      <!-- COLOR-->
      <v-btn :title="$t('PLANNING.SELECT_COLOR')" icon x-small @click="selectConfigLaneColor(lane)">
        <v-icon color="successgreen" size="14">fas fa-tint</v-icon>
      </v-btn>
      <v-spacer class="mr-1"></v-spacer>
      <!-- BOLD TOGGLE BUTTON-->
      <v-btn-toggle :value="(lane.getLabelStyle() || {})['font-weight']"
                    @change="toggleConfigLaneLabelStyle(lane, 'font-weight')">
        <v-btn value="bold" text x-small class="pa-0" style="min-width: 20px">
          <v-icon size="13">fas fa-bold</v-icon>
        </v-btn>
      </v-btn-toggle>
      <!-- ITALIC TOGGLE BUTTON-->
      <v-btn-toggle :value="(lane.getLabelStyle() || {})['font-style']" class="ml-1"
                    @change="toggleConfigLaneLabelStyle(lane, 'font-style')">
        <v-btn value="italic" text x-small class="pa-0" style="min-width: 20px">
          <v-icon size="13">fas fa-italic</v-icon>
        </v-btn>
      </v-btn-toggle>
      <v-spacer class="mx-1"></v-spacer>
      <!-- DELETE LANE-->
      <delete-lane-popover :lane="lane" @delete="deleteConfigLane(lane)"></delete-lane-popover>
    </div>
    <!-- ADD LANE-->
    <div :style="{ top: lastOfBlock ? 'calc(100% + 4px)' : '100%' }" style="position: absolute; right: -24px; transform: translateY(-50%)">
      <v-btn :title="$t('PLANNING.ADD_LANE')" icon small rounded class="ma-0"
             @click="addConfigLane(lane)">
        <v-icon size="16" color="successgreen">fas fa-plus-circle</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import TextareaAutosize from '@/components/Reusables/TextareaAutosize';
  import DeleteLanePopover from '@/components/Planning/partials/DeleteLanePopover';

  export default {
    components: {
      TextareaAutosize,
      DeleteLanePopover,
    },
    props: {
      lane: { type: Object, required: true },
      lastOfBlock: { type: Boolean, default: false },
    },
    data() {
      return {
        laneLabelSelected: {},
        textareaReady: false,
      };
    },
    computed: {
      hidden: {
        get() { return !! this.lane.getHidden(); },
        set(newVal) {
          this.lane.setHidden(newVal);
        },
      },
      laneContrastedTextColor() {
        let contrastedTextColor = this.lane.getMainColorContrastedTextColor();
        if (! contrastedTextColor) {
          const planning = this.lane.getPlanning();
          const maincolor = planning && planning.config && planning.config.designcolor && planning.config.designcolor.main;
          if (maincolor) contrastedTextColor = window.contrastedTextColor(maincolor);
        }
        return contrastedTextColor || 'white';
      },
    },
    mounted() {
      setTimeout(() => {
        this.textareaReady = true;
      });
    },
    methods: {
      setConfigLaneLevel(lane, level) {
        this.startChangingContent(lane);
        lane.level = Math.max(0, Math.min(2, level));
        this.changingContent(lane);
      },
      updateConfigLane(lane) {
        if (this.hidden == true) {
          this.startChangingContent(lane);
          this.setLaneHidden({ lane, hidden: false });
          this.changingContent(lane);
        } else {
          this.startChangingContent(lane);
          this.setLaneHidden({ lane, hidden: true });
          this.changingContent(lane);
        }
      },
      selectConfigLaneColor(lane) {
        this.$emit('selectConfigLaneColor', lane);
      },
      checkboxUpdate(lane) {
        this.$emit('checkboxUpdate', lane);
      },
      addConfigLane(lane) {
        this.$emit('addConfigLane', lane);
      },
      deleteConfigLane(lane) {
        this.$emit('deleteConfigLane', lane);
      },
      toggleConfigLaneLabelStyle(lane, prop) {
        const activeValue = { 'font-weight': 'bold', 'font-style': 'italic' }[prop] || '';
        this.startChangingContent(lane);
        const laneLabelStyle = lane.getLabelStyle() || {};
        this.$set(laneLabelStyle, prop, laneLabelStyle[prop] == activeValue ? 'normal' : activeValue);
        lane.setLabelStyle(laneLabelStyle);
        this.changingContent(lane);
      },
      setConfigLaneLabelSelected(laneId, val) {
        this.$set(this.laneLabelSelected, laneId, val);
      },
      ...mapActions('planning/lanes', ['startChangingContent', 'setLaneHidden', 'changingContent']),
    },
  };
</script>
