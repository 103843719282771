import clientConfig from '@/client_customs/config';

function getAvailableTemplates() {
  const lang = window.localStorageWrapper.getItem('lang') || 'en';
  return clientConfig.availableTemplates || [
    { id: 'welcome', altLangs: ['fr'], title: lang == 'fr' ? "Exemple : Bienvenue sur Bubble Plan" : "Example : Welcome to Bubble Plan !" },
    { id: 'change_management', title: "Exemple : Gestion du changement" },
    { id: 'sortie_produit', title: "Exemple : Lancement Produit" },
    { id: 'stocks', title: "Exemple : Fiabilisation des stocks" },
    { id: 'wedding', title: "Exemple : Préparation de Mariage" },
  ];
}

function listTemplates() {
  return Promise.resolve({ data: getAvailableTemplates() });
}

function getTemplate(id) {
  const template = getAvailableTemplates().find(item => item.id == id);
  if (! template) return Promise.reject(new Error('invalid template'));
  const lang = window.localStorageWrapper.getItem('lang') || 'en';
  const langSuffix = template.altLangs && template.altLangs.includes(lang) ? `_${lang}` : '';
  return import(`@/seeds/template_${id}${langSuffix}.json`);
}

function projectFromTemplate({ template, startdate, enddate, steps, scaleDates } = {}) {
  template = angular.copy(template || {});
  if (scaleDates && (! startdate || ! enddate || ! moment(startdate).isBefore(enddate))) return template; // invalid parameters
  if (steps && steps.length) {
    template.timeline = template.timeline || {};
    template.timeline.steps = steps;
  }
  let templatestart;
  let templateend;
  (template.elements || []).forEach((el) => {
    templatestart = templatestart ? moment.min(templatestart, moment(el.starttime)) : moment(el.starttime);
    templateend = templateend ? moment.max(templateend, moment(el.endtime)) : moment(el.endtime);
  });
  if (! templateend || ! templatestart) return template;
  if (! startdate) startdate = templatestart;
  startdate = moment(startdate).hours(templatestart.hours()).minutes(templatestart.minutes()).seconds(templatestart.seconds());

  let scalling = 1;
  if (scaleDates && templateend != templatestart) {
    enddate = moment(enddate).hours(templateend.hours()).minutes(templateend.minutes()).seconds(templateend.seconds());
    scalling = enddate.diff(startdate, 'minutes', true) / templateend.diff(templatestart, 'minutes', true);
  } else {
    enddate = moment(startdate).add(templateend.diff(templatestart, 'minutes', true), 'minutes');
  }

  const startdateUtcOffset = startdate.utcOffset();
  const templatestartUtcOffset = templatestart.utcOffset();
  (template.elements || []).forEach((el) => {
    const elStarttime = moment(el.starttime);
    el.starttime = moment(startdate).add(scalling * elStarttime.diff(templatestart, 'minutes', true), 'minutes');
    let timeZoneShift = (elStarttime.utcOffset() - templatestartUtcOffset) - (el.starttime.utcOffset() - startdateUtcOffset);
    el.starttime.add(timeZoneShift, 'minutes');
    el.starttime = el.starttime.hours(elStarttime.hours()).minutes(elStarttime.minutes()).seconds(elStarttime.seconds()).format();

    const elEndtime = moment(el.endtime);
    el.endtime = moment(startdate).add(scalling * elEndtime.diff(templatestart, 'minutes', true), 'minutes');
    timeZoneShift = (elEndtime.utcOffset() - templatestartUtcOffset) - (el.endtime.utcOffset() - startdateUtcOffset);
    el.endtime.add(timeZoneShift, 'minutes');
    el.endtime = el.endtime.hours(elEndtime.hours()).minutes(elEndtime.minutes()).seconds(elEndtime.seconds()).format();

    (el.checklist || []).forEach((action) => {
      if (action.duedate) {
        const actionDuedate = moment(action.duedate);
        action.duedate = moment(startdate).add(scalling * actionDuedate.diff(templatestart, 'minutes', true), 'minutes');
        timeZoneShift = (actionDuedate.utcOffset() - templatestartUtcOffset) - (action.duedate.utcOffset() - startdateUtcOffset);
        action.duedate.add(timeZoneShift, 'minutes');
        action.duedate = action.duedate.hours(actionDuedate.hours()).minutes(actionDuedate.minutes()).seconds(actionDuedate.seconds()).format();
      }
      action.checked = false;
      action.workload_used = null;
    });
    if (el.progress) el.progress = 0;
  });

  if (template.timeline && template.timeline.steps && template.timeline.steps.length) {
    const substep = template.timeline.steps.last();
    const timemargin = moment.duration(1, substep).as('minutes') / 3;
    _.extend(template.timeline, {
      hidden: {
        before: moment(startdate).add(-timemargin, 'minutes').startOf(substep).format(),
        after: moment(enddate).add(timemargin, 'minutes').endOf(substep).format(),
      },
    });
  }

  return template;
}

function listArchived() {
  return window.apiSrv.call('plannings/archived', 'index').then((response) => {
    const list = [];
    (response.data?.plannings || []).forEach((planning) => {
      try {
        planning.imgurl = (planning.imageexport_name.startsWith('http') ? '' : window.apiSrv.snapBaseUrl) + planning.imageexport_name;
        planning.date_of_modification = moment(planning.date_of_modification).toDate();
        planning.date_of_creation = moment(planning.date_of_creation).toDate();
        planning.deleted_at = moment(planning.deleted_at).toDate();
        list.push(planning);
      } catch (e) {
        console.log(`error loading planning ${e}`);
      }
    });
    return list;
  });
}

function list(silent) {
  return window.apiSrv.call('plannings', 'index', null, silent).then((response) => {
    const list = [];
    (response.data?.plannings || []).forEach((planning) => {
      try {
        planning.imgurl = (planning.imageexport_name.startsWith('http') ? '' : window.apiSrv.snapBaseUrl) + planning.imageexport_name;
        planning.date_of_modification = moment(planning.date_of_modification).toDate();
        planning.date_of_creation = moment(planning.date_of_creation).toDate();
        planning.title = window.html2text(planning.title);
        list.push(planning);
      } catch (e) {
        console.log(`error loading planning ${e}`);
      }
    });
    return list;
  });
}

function getContentMeta(planning) {
  return {
    title: window.html2text(planning.title),
    imgurl: (planning.imageexport_name.startsWith('http') ? '' : window.apiSrv.snapBaseUrl) + planning.imageexport_name,
    isOwnerPremium: planning.isOwnerPremium,
    access_right: planning.access_right,
    category: planning.category,
    status: planning.status,
    notifications: planning.notifications,
    companylogo: planning.companylogo,
    read_only_token: planning.read_only_token,
    owner_id: planning.owner_id,
    updated_by: planning.last_modification_user_id,
    date_of_modification: planning.date_of_modification,
  };
}

function get(id, rotoken) {
  const params = { id };
  if (rotoken) params.rotoken = rotoken;
  return window.apiSrv.call('plannings', 'show', params).then((response) => {
    const planning = response.data && response.data.planning || {};
    const content = typeof planning.content == 'object' ? planning.content : JSON.parse(planning.content);
    content.id = planning.id;
    content.meta = getContentMeta(planning);
    content.projectsheet = typeof planning.projectsheet == 'object' ? planning.projectsheet : JSON.parse(planning.projectsheet || '{}');
    return content;
  });
}

function getView({ viewId, viewrotoken, planningId, planningRotoken }) {
  let url = `views/${viewId}/planning`;
  if (planningId) url += `/${planningId}`;
  const params = _.extend({}, viewrotoken && { viewrotoken }, planningRotoken && { rotoken: planningRotoken });
  return window.apiSrv.call(url, 'index', params).then((response) => {
    const planning = response.data && response.data.planning || {};
    const content = typeof planning.content == 'object' ? planning.content : JSON.parse(planning.content);
    content.id = planning.id;
    content.meta = getContentMeta(planning);
    content.projectsheet = typeof planning.projectsheet == 'object' ? planning.projectsheet : JSON.parse(planning.projectsheet || '{}');
    return content;
  });
}

function getAll({ modifiedSince, loadingCounter = {} } = {}) {
  const url = 'plannings/show/all';
  return window.apiSrv.call(url, 'index', { ...(modifiedSince && { modified_since: modifiedSince }) }).then(async (response) => {
    const plannings = response.data.plannings || [];
    _.extend(loadingCounter, {
      loadingDate: response.data.$loading_date,
      allPlanningsList: response.data.$all_plannings_list,
      toLoad: response.data.$total,
      loaded: (loadingCounter.loaded || 0) + plannings.length,
    });
    let $nextPageUrl = response.data && response.data.$next_page_uri;
    while ($nextPageUrl) {
      // eslint-disable-next-line no-await-in-loop, no-loop-func
      const nextPlannings = await window.apiSrv.call($nextPageUrl.replace(/^v1\//, ''), 'index').then((response2) => {
        const plannings2 = response2.data && response2.data.plannings || [];
        loadingCounter.loaded += plannings2.length;
        $nextPageUrl = response2.data && response2.data.$next_page_uri;
        return plannings2;
      });
      plannings.push(...nextPlannings);
    }
    return plannings.map((planning) => {
      const content = typeof planning.content == 'object' ? planning.content : JSON.parse(planning.content);
      content.id = planning.id;
      content.meta = getContentMeta(planning);
      content.projectsheet = typeof planning.projectsheet == 'object' ? planning.projectsheet : JSON.parse(planning.projectsheet || '{}');
      return content;
    });
  });
}

function getPlanningsQuery(params) {
  return window.apiSrv.call('plannings/query', 'index', params).then((response) => {
    const plannings = response.data && response.data.plannings || [];
    return plannings.map((planning) => {
      const content = typeof planning.content == 'object' ? planning.content : JSON.parse(planning.content);
      content.id = planning.id;
      content.meta = getContentMeta(planning);
      content.projectsheet = typeof planning.projectsheet == 'object' ? planning.projectsheet : JSON.parse(planning.projectsheet || '{}');
      return content;
    });
  });
}

function createNew(save) {
  save = save || {};
  const data = {
    title: save.title || "",
    content: JSON.stringify(save.content),
    category: save.category || '',
    status: save.status || '',
    privacy: save.privacy,
  };
  if (save.owner_id) data.owner_id = save.owner_id;
  return window.apiSrv.call('plannings', 'store', data);
}

function savePlan(save, { contentPatch } = {}) {
  const data = {
    id: save.id,
    title: save.title,
    content: JSON.stringify(save.content),
  };
  if (contentPatch) {
    data.content_patch = contentPatch;
    if (save.content && save.content.elements && save.content.elements.length > 500) delete data.content; // future version : send patch only
  }
  return window.apiSrv.call('plannings', 'update', data);
}

function saveAdmin(save) { // owner_id, projectsheet
  const { id } = save;
  if (! id) return Promise.reject('id is missing');
  delete save.id;
  return window.apiSrv.call(`plannings/${id}/admin`, 'update', save);
}

function remove(id) {
  return window.apiSrv.call('plannings', 'destroy', id);
}

function removeArchived(id) {
  return window.apiSrv.call('plannings/archived', 'destroy', id);
}

function restoreArchived(id) {
  return window.apiSrv.call('plannings/archived', 'show', id);
}

export default {
  list,
  get,
  getAll,
  getPlanningsQuery,
  save: savePlan,
  saveAdmin,
  listTemplates,
  getTemplate,
  projectFromTemplate,
  createNew,
  remove,
  listArchived,
  removeArchived,
  restoreArchived,
  getView,
};
