<template>
  <table class="table table-striped" style="margin-bottom:0">
    <thead style="text-transform: uppercase">
      <tr>
        <th>{{ $t('MANAGE.FIRSTNAME') }}</th>
        <th>{{ $t('MANAGE.LASTNAME') }}</th>
        <th>{{ $t('MANAGE.EMAIL') }}</th>
        <th class="text-center">{{ $t('MANAGE.ACCESS_RIGHT') }}</th>
        <th v-if="project.access_right == 'admin' && ! hideNotifications" class="text-center">
          {{ $t('NOTIFICATIONS.NOTIFICATIONS') }}&nbsp; <i :title="$t('PLANNINGDRAWER.ACTIVATE_NOTIFICATIONS')" class="fa fa-info-circle text-muted"></i>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="user in usersList">
        <td>{{ user.firstname | lowercase | ucfirst }}</td>
        <td>{{ user.lastname | lowercase | ucfirst }}</td>
        <td style="word-break: break-word;">{{ user.email }}</td>
        <td class="text-center">
          <div v-if="user.special_role">
            {{ $t('ACR.' + uppercase(user.access_right)) }} <span class="nobr">({{ $t('ACR.' + uppercase(user.special_role)) }})</span>
          </div>
          <div v-if="project.access_right !== 'admin' && ! user.special_role" class="nobr">
            <i :title="$t('ACR.NONE')" :class="{ 'errorred--text': user.access_right == 'none' }"
               :style="{ opacity: user.access_right == 'none' ? 1 : .5 }" class="fa fa-fw fa-ban">
            </i>
            <i :title="$t('ACR.CONSULT')" :class="{ 'successgreen--text': user.access_right == 'consult' }"
               :style="{ opacity: user.access_right == 'consult' ? 1 : .5 }" class="fa fa-fw fa-eye">
            </i>
            <i :title="$t('ACR.MODIFY')" :class="{ 'successgreen--text': user.access_right == 'modify', invisible: userIsConsultOnly(user) }"
               :style="{ opacity: user.access_right == 'modify' ? 1 : .5 }" class="fas fa-fw fa-pencil-alt">
            </i>
            <i :title="$t('ACR.ADMIN')" :class="{ 'warningorange--text': user.access_right == 'admin', invisible: userIsConsultOnly(user) }"
               :style="{ opacity: user.access_right == 'admin' ? 1 : .5 }" class="fa fa-fw fa-unlock-alt">
            </i>
          </div>

          <div v-if="project.access_right == 'admin' && ! user.special_role" :class="{ disabled: planningAccessRightsDisabled[user.id] }" class="nobr">
            <i :class="{ 'selected errorred--text': user.access_right == 'none' }" :title="$t('ACR.NONE')" class="fa fa-fw fa-ban access-right-icon"
               @click="! planningAccessRightsDisabled[user.id] && setPlanningUserRights(user, 'none')">
            </i>
            <i :class="{ 'selected successgreen--text': user.access_right == 'consult' }" :title="$t('ACR.CONSULT')" class="fa fa-fw fa-eye access-right-icon"
               @click="! planningAccessRightsDisabled[user.id] && setPlanningUserRights(user, 'consult')">
            </i>
            <i :class="{ 'selected successgreen--text': user.access_right == 'modify', invisible: userIsConsultOnly(user) }" :title="$t('ACR.MODIFY')" class="fas fa-fw fa-pencil-alt access-right-icon"
               @click="! planningAccessRightsDisabled[user.id] && setPlanningUserRights(user, 'modify')">
            </i>
            <i :class="{ 'selected warningorange--text': user.access_right == 'admin', invisible: userIsConsultOnly(user) }" :title="$t('ACR.ADMIN')" class="fa fa-fw fa-unlock-alt access-right-icon"
               @click="! planningAccessRightsDisabled[user.id] && setPlanningUserRights(user, 'admin')">
            </i>
          </div>
        </td>
        <td v-if="project.access_right == 'admin' && ! hideNotifications" class="text-center">
          <i v-show="user.access_right != 'none'" :class="user.notifications ? 'fa-toggle-on successgreen--text' : 'fa-toggle-off'"
             :style="{ opacity: notificationsDisabled[user.id] ? .3 : 1 }" class="fa fa-fw fa-lg pointer"
             @click="! notificationsDisabled[user.id] && toggleNotifications(user)">
          </i>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
  export default {
    props: {
      project: { type: Object, required: true },
      usersList: { type: Array, required: true },
      hideNotifications: { type: Boolean, default: false },
      planningAccessRightsDisabled: { type: Object, required: true },
      notificationsDisabled: { type: Object, required: true },
    },
    methods: {
      setPlanningUserRights(user, newRight) {
        this.$emit('set-planning-user-rights', { user, newRight });
      },
      toggleNotifications(user) {
        this.$emit('toggle-notifications', { user });
      },
      userIsConsultOnly(user) {
        return (this.$store.getters['users/getUserById'](user && user.id) || {}).access_right == 'consult';
      },
      uppercase(str) {
        return str && str.toUpperCase();
      },
    },
  };
</script>
