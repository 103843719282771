<template>
  <router-link :to="view ? viewLink(project) : projectLink(project, project.access_right == 'consult' || statusInfo.readonly)"
               :style="{ cursor: archived ? 'default' : null }" style="color:inherit; text-decoration:none;">
    <project-preview class="home-projects-project" :project="project" :thumbnail="view ? defaultBg : project.imgurl">
      <template v-if="view" #body>
        <div style="position: absolute; min-height: 50%; bottom: 0; width: 100%; padding: 8px 8px 2px; background: rgba(238,238,238,.95); display: flex; flex-direction: column">
          <div><strong>{{ project.title }}</strong></div>
          <div style="margin-top:3px">
            <i class="far fa-clock"></i> {{ project.project.date_of_modification | moment('calendar') }}
          </div>
          <div class="text-muted small">
            {{ $t('PROJECTS.LAST_MODIFICATION_BY') }} {{ translateApiText(project.project.last_modification_user_name) }}
          </div>
          <div class="text-center small" style="margin-top: auto">Vue personnalisée</div>
        </div>
      </template>
      <template v-else #body-details>
        <div v-if="! archived">
          <i class="far fa-clock"></i> {{ project.date_of_modification | moment('calendar') }}
          <div class="text-muted small">
            {{ $t('PROJECTS.LAST_MODIFICATION_BY') }} {{ translateApiText(project.last_modification_user_name) }}
          </div>
        </div>
        <div v-else class="text-muted small">
          {{ $t('PROJECTS.ARCHIVED_ON') }} {{ project.deleted_at | moment('date') }}
        </div>
      </template>
      <template #bottom>
        <div class="display-flex align-end">
          <div :title="$t('GLOBAL.OWNER')" class="display-flex align-center mr-auto">
            <img v-if="owner.avatar" :src="owner.avatar" class="mr-1" style="max-height: 20px; border-radius: 50%;">
            <i v-else class="fas fa-user mr-1" style="font-size: 14px"></i>
            {{ project.owner_name == 'API.ME' ? $t('API.ME') : (owner ? owner_username : project.owner_name) }}
          </div>
          <div v-if="projectNotifsCount" :title="$t('NOTIFICATIONS.NOTIFICATIONS')" class="errorred--text nobr mr-2">
            <i class="fas fa-bell"></i>&nbsp;{{ projectNotifsCount }}
          </div>
          <div v-if="project.access_right == 'admin' && ! statusInfo.readonly" :title="$t('PROJECTS.ADMIN_RIGHTS')">
            <i class="fas fa-fw fa-unlock-alt warningorange--text"></i>
          </div>
          <div v-else-if="project.access_right == 'modify' && ! statusInfo.readonly" :title="$t('PROJECTS.MODIFY_RIGHTS')">
            <i class="fas fa-fw fa-pencil-alt successgreen--text"></i>
          </div>
          <div v-else :title="$t('PROJECTS.READ_ONLY')">
            <i class="fas fa-fw fa-eye successgreen--text"></i>
          </div>
        </div>
      </template>

      <v-menu v-if="! userConsultOnly && ! view" offset-y left>
        <template #activator="{ on }">
          <v-btn icon small outlined color="midgrey" class="projectmenu" v-on="on" @click.prevent>
            <v-icon color="primary" small>fas fa-bars</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <template v-if="! archived">
            <v-list-item @click="openedProjectSheetProject = project">
              <span class="primary--text"><i class="fas fa-flag" style="width: 24px"></i>{{ $t('PROJECT_SHEET.PROJECT_SHEET') }}</span>
            </v-list-item>
            <v-menu open-on-hover offset-x nudge-top="8" nudge-left="8">
              <template #activator="{ on }">
                <v-list-item v-if="isBusiness" :disabled="! isProjectAdmin" v-on="on" @click.stop>
                  <div :class="isProjectAdmin ? 'secondary--text' : ''" class="flex-grow-1 display-flex align-center">
                    <span><i class="fas fa-columns" style="width: 24px"></i>{{ $t('PROJECTS.STATUS.STATUS') }}</span>
                    <v-icon v-if="isProjectAdmin" size="18" class="ml-auto">fas fa-caret-right</v-icon>
                  </div>
                </v-list-item>
              </template>
              <v-list>
                <v-list-item v-for="status in projectStatusKeys" v-if="status != (project.status || '')" :key="status" @click="updateProjectStatus(status)">
                  {{ status ? projectStatusConfig[status].label : $t('PROJECTS.STATUS.NONE') }}
                </v-list-item>
              </v-list>
            </v-menu>
            <v-list-item :to="`manage?open=${project.id}`">
              <span class="warningorange--text text--darken-2"><i class="fas fa-lock" style="width: 24px"></i>{{ $t('PROJECTS.MANAGE_ACCESS') }}</span>
            </v-list-item>
            <v-list-item class="px-1">
              <div v-if="statusInfo.readonly && ['admin', 'modify'].includes(project.access_right)" class="text-center" style="flex-grow: 1">
                <!-- LINK TO ACCESS IN MODIFICATION WHEN statusInfo.readonly -->
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-btn :to="projectLink(project)" icon v-on="on">
                      <v-icon size="14" color="successgreen">fas fa-pencil-alt</v-icon>
                    </v-btn>
                  </template>
                  {{ $t('PROJECTS.MODIFY') }}
                </v-tooltip>
              </div>
              <div v-else class="text-center" style="flex-grow: 1">
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-btn :disabled="project.access_right == 'consult'" :to="projectLink(project, true)" icon v-on="on">
                      <v-icon size="14" color="successgreen">fas fa-eye</v-icon>
                    </v-btn>
                  </template>
                  {{ $t('PROJECTS.READ_ONLY') }}
                </v-tooltip>
              </div>
              <v-divider vertical class="my-1"></v-divider>
              <div class="text-center" style="flex-grow: 1">
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-btn :disabled="! isProjectAdmin" icon v-on="on" @click="$emit('duplicate')">
                      <v-icon size="14" color="info">fas fa-copy</v-icon>
                    </v-btn>
                  </template>
                  {{ $t('PROJECTS.DUPLICATE') }}
                </v-tooltip>
              </div>
              <v-divider vertical class="my-1"></v-divider>
              <div class="text-center" style="flex-grow: 4">
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-btn :disabled="! isProjectAdmin" icon v-on="on" @click="$emit('archive')">
                      <v-icon size="14" color="errorred">fas fa-archive</v-icon>
                    </v-btn>
                  </template>
                  {{ $t('PROJECTS.ARCHIVE') }}
                </v-tooltip>
              </div>
            </v-list-item>
          </template>
          <template v-else>
            <v-list-item :disabled="! isProjectAdmin" @click="$emit('restore')">
              <span><i class="fas fa-reply" style="width: 24px"></i>{{ $t('PROJECTS.RESTORE') }}</span>
            </v-list-item>
            <v-list-item :disabled="! isProjectAdmin" @click="$emit('delete')">
              <span :class="isProjectAdmin ? 'errorred--text' : ''"><i class="fas fa-trash-alt" style="width: 24px"></i>{{ $t('PROJECTS.DELETE') }}</span>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
    </project-preview>
    <modal-project-sheet v-if="openedProjectSheetProject" :project="openedProjectSheetProject" :readonly="statusInfo.readonly" @close="openedProjectSheetProject = null">
    </modal-project-sheet>
  </router-link>
</template>

<style>
  .home-projects-project {
    height: 190px;
  }

  .home-projects-project.project-preview:hover .project-preview-textbox {
    margin-left: 33% !important;
  }

  .home-projects-project.project-preview:hover .project-preview-title {
    font-size: 145%;
    line-height: 1.2;
  }

  .home-projects-project .projectmenu {
    position:absolute;
    top: 6px;
    right: 6px;
  }
</style>

<script>
  import ModalProjectSheet from '@/components/ProjectSheet/ModalProjectSheet';
  import HomeProjectsMixin from './HomeProjectsMixin';
  import ProjectPreview from './ProjectPreview';

  const defaultBg = require('@/img/view_bg.jpg');

  export default {
    components: {
      ModalProjectSheet,
      ProjectPreview,
    },
    mixins: [HomeProjectsMixin],
    props: {
      project: { required: true, type: Object },
      archived: { type: Boolean, default: false },
      view: { type: Boolean, default: false },
      statusInfo: { type: Object, default: () => ({}) },
    },
    data() {
      return {
        userConsultOnly: this.$parent.userConsultOnly,
        openedProjectSheetProject: null,
        defaultBg,
      };
    },
    computed: {
      projectStatusConfig() {
        return this.$store.getters['users/accessRight/config'].projectStatus || {};
      },
      projectStatusKeys() {
        return [...Object.keys(this.projectStatusConfig), ''];
      },
      notifications() {
        return this.$store.state.notifications.all;
      },
      owner() {
        return this.$store.getters['users/getUserById'](this.project.owner_id) || {};
      },
      owner_username() {
        return this.$store.getters['users/getUsername'](this.owner, 'short');
      },
      projectNotifsCount() {
        return this.notifications.filter(notif => notif.section_type == 'planning' && notif.section_id == this.project.id && ! notif.seen_at).length;
      },
      isProjectAdmin() {
        return this.project.access_right == 'admin';
      },
      isBusiness() { return this.$store.state.users.accessRight.isBusiness; },
    },
    methods: {
      viewLink(view) {
        if (this.archived) return '';
        return `/planningview/${view.id}/${window.slugify(view.title || 'New view')}`;
      },
      updateProjectStatus(projectStatus) {
        setTimeout(() => {
          window.apiSrv.call(`plannings`, 'update', { id: this.project.id, status: projectStatus }).then(() => {
            this.project.status = projectStatus;
          }).catch((message) => {
            this.$store.dispatch('ui/msgbox/open', { title: "Error : Status was not modified", body: message || "" });
          });
        });
      },
      uppercase(text) { return text.toUpperCase(); },
    },
  };
</script>
