<template>
  <section id="notifications-manager" class="px-12 pt-6 pb-4">
    <div v-if="isLoading" class="text-center" style="height: 270px"><i class="fas fa-spinner fa-spin fa-2x fa-fw"></i></div>
    <template v-else>
      <b class="primary--text text-uppercase">{{ $t('NOTIFICATIONS.ACTIVATION') }}</b>
      <v-switch v-model="receiveNotifications" :label="$t('NOTIFICATIONS.RECEIVE_EMAILS')" class="mt-0"></v-switch>
      <div :class="{ 'tour-disabled': ! receiveNotifications }">
        <b class="primary--text text-uppercase">{{ $t('NOTIFICATIONS.EMAILS_FREQUENCY') }}</b>
        <v-radio-group v-model="frequency" class="mt-0">
          <v-radio :label="$t('NOTIFICATIONS.DAILY')" value="daily"></v-radio>
          <v-radio :label="$t('NOTIFICATIONS.HOURLY')" value="hourly"></v-radio>
          <v-radio :label="$t('NOTIFICATIONS.IMMEDIATELY')" value="immediately"></v-radio>
        </v-radio-group>
      </div>
      <v-divider></v-divider>
      <div class="display-flex justify-end mt-4">
        <v-btn color="secondary" rounded large outlined class="mx-2" @click="close">{{ $t('GLOBAL.CANCEL') }}</v-btn>
        <v-btn color="accent" rounded large class="mx-2" @click="save">{{ $t('GLOBAL.SAVE') }}</v-btn>
      </div>
    </template>
  </section>
</template>

<style>
  #notifications-manager .theme--light.v-label {
    color: #333;
  }
</style>

<script>
  export default {
    data() {
      return {
        receiveNotifications: true,
        frequency: 'daily',
        isLoading: true,
      };
    },
    created() {
      window.apiSrv.call('useroptions', 'show', 'notifications-email-frequency').then((response) => {
        const notificationsEmailFrequency = response && response.data && response.data.useroption && response.data.useroption.value || 'daily';
        this.receiveNotifications = notificationsEmailFrequency != 'none';
        if (this.receiveNotifications) this.frequency = notificationsEmailFrequency;
        this.isLoading = false;
      }).catch(() => this.close());
    },
    methods: {
      close() {
        this.$emit('close');
      },
      save() {
        const notificationsEmailFrequency = this.receiveNotifications ? this.frequency : 'none';
        window.apiSrv.call('useroptions', 'update', { id: 'notifications-email-frequency', value: notificationsEmailFrequency }).catch((message) => {
          if (message) this.$store.dispatch('ui/msgbox/open', { title: "Error : Notification parameters were not modified", body: message || "" });
        });
        this.close();
      },
    },
  };
</script>
