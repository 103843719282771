/* eslint-disable operator-assignment */
/* eslint-disable arrow-body-style */
export default {
  namespaced: true,
  state: {
    loading: false,
    loadedAt: "",
  },
  mutations: {
  },
  getters: {
    all: (state, getters, rootState, rootGetters) => {
      const workloads = [];
      rootGetters['multiprojects/getAllPlannings'].forEach((planning) => {
        if (planning.id == rootState.planning.id) planning = rootState.planning; // on planning page, link to rootState.planning for reactive update
        (planning.elements || []).forEach((el) => {
          (el.getChecklist() || []).forEach((checklistItem) => {
            if ((! checklistItem.user_id && ! checklistItem.group_id && ! checklistItem.username) || checklistItem.checked || ! checklistItem.workload) return;
            const starttime = moment.max(moment(), el.getStartTime()).startOf('day');
            let endtime = el.getEndTime();
            if (checklistItem.duedate) {
              const duedate = moment(checklistItem.duedate);
              if (duedate.isAfter()) {
                endtime = duedate.startOf('day');
              } else if (endtime.isAfter()) {
                endtime = moment(starttime).toNextWorkday().addWithWorkdays(1, 'day', true);
              }
            }
            const duration = endtime.diffWithWorkdays(starttime, 'minutes', true) / (60 * 24);
            let dailyWorkload = Math.max(0, checklistItem.workload - (checklistItem.workload_used || 0)) / duration;
            if (duration > 0 && dailyWorkload > 0) {
              let userId = checklistItem.user_id;
              if (! checklistItem.user_id && checklistItem.group_id) { // group workload
                const group = rootGetters['users/groups/getGroupById'](checklistItem.group_id);
                if (! group) return;
                userId = `group${group.id}`;
                dailyWorkload = dailyWorkload / (group.users.length || 1);
              } else if (! checklistItem.user_id) { // virtual participant
                userId = `vp${checklistItem.username}`;
              }

              workloads.push({
                planning_id: planning.id,
                el_id: el.o_id,
                starttime,
                endtime,
                user_id: userId,
                daily_workload: dailyWorkload,
              });
            }
          });
        });
      });
      return workloads;
    },
    isLoaded: (state) => {
      return !! state.loadedAt;
    },
    getUserWorkloads: (state, getters) => (userId) => {
      return getters.all.filter(item => item.user_id == userId);
    },
    getElUserWorkloads: (state, getters) => (planningId, elId, userId) => {
      return getters.all.filter(item => item.planning_id == planningId && item.el_id == elId && item.user_id == userId);
    },
    getPercentColorClass: () => (percent) => {
      if (percent > 100) return 'errorred';
      if (percent > 50) return 'warningorange';
      if (percent > 0) return 'successgreen';
      return '';
    },
    mergeWorkloads: () => (workloads) => {
      const mergedWorkloads = [];
      const workloadsDates = Array.prototype.concat.apply([], workloads.map(item => [item.starttime.format(), item.endtime.format()])).sort();
      for (let i = 0; i < workloadsDates.length - 1; i++) {
        if (workloadsDates[i] == workloadsDates[i + 1]) continue;
        const momentDate = moment(workloadsDates[i]);
        mergedWorkloads.push({
          starttime: momentDate,
          endtime: moment(workloadsDates[i + 1]),
          daily_workload: workloads.filter(item => item.starttime.isSameOrBefore(momentDate) && item.endtime.isAfter(momentDate)).reduce((a, b) => a + b.daily_workload, 0),
        });
      }
      return mergedWorkloads;
    },
  },
  actions: {
    load({ state, dispatch }) {
      state.loading = true;
      const modifiedAfter = state.loadedAt ? moment(state.loadedAt).format() : null;
      return dispatch('multiprojects/plannings/loadPlanningsQuery', { workload: true, modified_after: modifiedAfter }, { root: true }).then((plannings) => {
        state.loading = false;
        state.loadedAt = plannings.reduce((res, item) => (item.meta && item.meta.date_of_modification > res ? item.meta.date_of_modification : res), state.loadedAt);
      }).catch((error) => {
        console.error(error);
        state.loading = false;
      });
    },
  },
};
