<template>
  <div tabindex="-1" role="dialog" class="modal" @click.self="backdropClicked">
    <transition name="modal">
      <div v-show="isOpened" :class="`${sizeClass}${card ? ' v-card' : ''} ${contentClass}`" class="modal-container">
        <div v-if="movable" class="modal-draggable-anchor"></div>
        <button v-if="closable" :class="dark ? 'close-dark' : null" type="button" class="close close-icon" @click="close">&times;</button>
        <slot></slot>
      </div>
    </transition>
    <div ref="backdrop" :style="{ 'background-color' : backdropColor }" class="modal-mask"></div>
  </div>
</template>

<style>
  .modal-mask {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    transition: opacity .3s ease, background-color .3s ease;
    overflow-y: auto;
  }

  .modal-container {
    padding: 0;
    margin: 30px auto;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: transform .3s ease, opacity .3s ease;
    position: relative;
    width: auto;
  }

  @media (min-width: 768px) {
    .modal-sm {
      width: 300px;
    }
    .modal-md, .modal-lg {
      width: 600px;
    }
  }
  @media (min-width: 992px) {
    .modal-lg {
      width: 900px;
    }
  }
  @media (min-width: 1320px) {
    .modal-lg {
      width: 1200px;
    }
  }

  .close-dark {
    color: white !important;
    opacity: .8;
  }
  .close-dark:hover {
    opacity: 1;
  }

  /* TRANSITIONS */
  .modal-enter, .modal-leave-active {
    opacity: 0;
  }

  .modal-enter.modal-container, .modal-leave-active.modal-container {
    transform: scale(.7);
    transform-origin: center center;
  }
</style>

<script>
  import { outerHeight, outerWidth } from '@/components/Reusables/utils';
  import { removeFromDom, toggleBodyOverflow, getComputedStyle } from '@/components/Reusables/utils/domUtils';

  export default {
    props: {
      size: { type: String, default: 'md' },
      backdrop: { type: Boolean, default: true },
      backdropClose: { type: Boolean, default: true },
      closable: { type: Boolean, default: true },
      movable: { type: Boolean, default: false },
      target: { type: String, default: '' },
      alignDirection: { type: String, default: 'both' },
      keyboard: { type: Boolean, default: true },
      zOffset: { type: Number, default: 20 },
      attach: { type: String, default: '#vue-app' },
      dark: { type: Boolean, default: false },
      card: { type: Boolean, default: false },
      contentClass: { type: String, default: "" },
      backdropOpacity: { type: Number, default: null },
    },
    data() {
      return {
        closingallowed: false,
        isOpened: false,
      };
    },
    computed: {
      sizeClass() {
        return `modal-${this.size}`;
      },
      backdropColor() {
        if (! this.backdrop) return 'transparent';
        if (typeof this.backdropOpacity == 'number') return `rgba(0, 0, 0, ${this.backdropOpacity})`;
        return null;
      },
    },
    mounted() {
      this.isOpened = true; // start transition
      removeFromDom(this.$refs.backdrop);
      window.addEventListener('keyup', this.onKeyPress);
      if (this.target) {
        this.positionRelativeToTarget();
      }
      if (this.movable) {
        this.setMovable();
      }
      setTimeout(() => { this.closingallowed = true; }, 300);
      this.onShow();
    },
    beforeDestroy() {
      removeFromDom(this.$refs.backdrop);
      removeFromDom(this.$el);
      if (document.querySelectorAll(`.modal-mask`).length === 0) {
        toggleBodyOverflow(true);
      }
      window.removeEventListener('keyup', this.onKeyPress);
    },
    methods: {
      close() {
        if (! this.closingallowed) return;
        this.$emit('close');
      },
      backdropClicked() {
        if (this.backdropClose) {
          this.close();
        }
      },
      onKeyPress(event) {
        if (this.keyboard && this.closable && event.keyCode === 27) {
          this.close();
        }
      },
      onShow() {
        const modal = this.$el;
        const { backdrop } = this.$refs;
        const alreadyOpenModalNum = document.querySelectorAll(`.modal-mask`).length;
        document.body.appendChild(backdrop);
        if (this.attach) {
          const parentNode = document.querySelector(this.attach);
          if (parentNode) parentNode.appendChild(modal);
        }
        modal.style.display = 'block';
        modal.scrollTop = 0;
        backdrop.offsetHeight; // force repaint
        toggleBodyOverflow(false);
        // fix z-index for nested modals
        // no need to calculate if no modal is already open
        if (alreadyOpenModalNum > 0) {
          const modalBaseZ = parseInt(getComputedStyle(modal).zIndex, 10) || 1050; // 1050 is default modal z-Index
          const backdropBaseZ = parseInt(getComputedStyle(backdrop).zIndex, 10) || 1040; // 1040 is default backdrop z-Index
          const offset = alreadyOpenModalNum * this.zOffset;
          modal.style.zIndex = `${modalBaseZ + offset}`;
          backdrop.style.zIndex = `${backdropBaseZ + offset}`;
        }
        // z-index fix end

        const autofocusEl = this.$el.querySelector("[autofocus]");
        if (autofocusEl) setTimeout(() => { autofocusEl.focus(); });
      },
      positionRelativeToTarget() {
        if (! this.target) return;
        const vm = this;
        const direction = this.alignDirection;
        function updatePosition() {
          const target = document.querySelector(vm.target);
          if (! target) return;
          const modaldialog = vm.$el.querySelector('.modal-container');

          const boundingRect = target.getBoundingClientRect();
          const elOffset = { left: boundingRect.left, top: boundingRect.top };
          const elDim = { width: outerWidth(target), height: outerHeight(target) };
          const winDim = { width: window.innerWidth, height: window.innerHeight };
          const modalDim = { width: outerWidth(modaldialog), height: outerHeight(modaldialog) };

          modaldialog.style['margin-bottom'] = '8px';
          if (direction == 'horizontal' || direction == 'both') {
            let calculatedleft = elOffset.left + elDim.width + 20;
            if (calculatedleft + modalDim.width > winDim.width - 100) calculatedleft = elOffset.left - modalDim.width - 20;
            if (winDim.width < modalDim.width + 30) calculatedleft = 0; // very small screen
            else if (calculatedleft < 100) calculatedleft = elOffset.left > winDim.width - (elOffset.left + elDim.width) ? 30 : winDim.width - 30 - modalDim.width; // small screen
            modaldialog.style['margin-left'] = 0;
            modaldialog.style.left = `${calculatedleft}px`;
          }
          if (direction == 'vertical' || direction == 'both') {
            let calculatedtop = elOffset.top - modalDim.height / 2 + elDim.height / 2;
            if (calculatedtop + modalDim.height > winDim.height - 30) calculatedtop = winDim.height - 30 - modalDim.height;
            if (calculatedtop < 30) calculatedtop = 30;
            modaldialog.style['margin-top'] = 0;
            modaldialog.style.top = `${calculatedtop}px`;
          }
        }
        this.$nextTick(updatePosition);
      },
      setMovable() {
        const modaldialog = this.$el.querySelector('.modal-container');
        $(modaldialog).draggable({ handle: '.modal-draggable-anchor' });
      },
    },
  };
</script>
