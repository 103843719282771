import Vue from 'vue';

Vue.directive('contentautoselect', {
  inserted(el) {
    const element = $(el);
    element.bind("focus", () => {
      if (element.is("input") || element.is("textarea")) {
        element.select();
      } else {
        const range = document.createRange();
        range.selectNodeContents(element[0]);
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
      }
      // prevent mouseup from unselecting
      element.one("mouseup", (e) => {
        e.preventDefault();
      });
      element.one("mousedown", () => {
        element.off("mouseup");
      });
    });
  },
});

Vue.directive('draggable', {
  inserted(el, binding) {
    const element = $(el);
    element.draggable(binding.value || {});
  },
  update(el, binding) {
    if (binding.value && binding.oldValue && binding.value.disabled != binding.oldValue.disabled) {
      const element = $(el);
      element.draggable(binding.value.disabled ? "disable" : "enable");
    }
  },
});

Vue.directive('droppable', {
  inserted(el, binding) {
    const element = $(el);
    element.droppable(binding.value || {});
  },
  update(el, binding) {
    if (binding.value && binding.oldValue && binding.value.disabled != binding.oldValue.disabled) {
      const element = $(el);
      element.droppable(binding.value.disabled ? "disable" : "enable");
    }
  },
});

Vue.directive('resizable', {
  inserted(el, binding) {
    const element = $(el);
    element.resizable(binding.value || {});
  },
  update(el, binding) {
    if (binding.value && binding.oldValue && binding.value.disabled != binding.oldValue.disabled) {
      const element = $(el);
      element.resizable(binding.value.disabled ? "disable" : "enable");
    }
  },
});

Vue.directive('selectableArea', {
  inserted(el, binding) {
    let startX;
    const element = $(el);
    const options = binding.value || {};
    element.selectable(_.extend({}, options, {
      filter: options.filter || "*",
      distance: 5,
      start: (event, ui) => {
        startX = event.pageX;
        if (options.start) options.start(event, ui);
      },
      selecting(event) {
        if (event.pageX >= startX) {
          // left to right
          element.selectable("option", "tolerance", "fit");
        } else {
          element.selectable("option", "tolerance", "touch");
        }
      },
      stop() { element.selectable("option", "tolerance", "touch"); },
      selected: options.selected,
    }));
    element.mousedown(() => {
      if (document.activeElement) document.activeElement.blur();
    });
  },
  update(el, binding) {
    if (binding.value && binding.oldValue && binding.value.disabled != binding.oldValue.disabled) {
      const element = $(el);
      element.selectable(binding.value.disabled ? "disable" : "enable");
    }
  },
});

/**
* Create and drag element from button inside navigation
*/
Vue.directive('draggableButton', {
  inserted(el, binding, vnode) {
    const element = $(el);
    const options = binding.value || {};
    const type = options.type || "task";
    const cursorAt = { top: (type == 'milestone') ? 12 : 5, left: (type == 'milestone') ? 27 : 53 };

    function $t(key) {
      if (! vnode.context.$t) return key;
      return vnode.context.$t.call(vnode.context, key);
    }

    function createHelper(elType) {
      const width = (elType == 'milestone') ? 70 : 130;
      const helper = $(`<article class="element ${elType}" style="z-index:9999; width:${width}px">`);
      let content;
      if (elType == 'milestone') {
        content = $(`<div><div class="fa fa-fw icon-diamonds milestone-default-blue" style="font-size: 16px"></div><div style="font-weight:bold">${$t('PLANNING.MILESTONE')}</div></div>`);
      } else {
        content = $(`<div><div class="element-title task-default-blue"><div style="font-weight:bold">${$t('PLANNING.NEW_BUBBLE')}</div></div></div>`);
      }
      helper.append(content);
      return helper;
    }

    element.draggable({
      helper() { return createHelper(type); },
      appendTo: 'body',
      cursorAt,
      cancel: false,
      start(event, ui) {
        if (options.start) options.start(event, ui, type);
      },
      stop(event, ui) {
        if (options.stop) options.stop(event, ui, type);
      },
    });
  },
});

Vue.directive('inputAutoWidth', {
  inserted(el, binding) {
    const options = binding.value || {};
    el.$span = $('<span style="visibility: hidden; white-space: pre;"></span>');

    el.updateElementWidth = function (text) {
      const element = $(el);
      el.$span.html(text.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;'));
      element.append(el.$span);
      element.css('width', el.$span.width() + 30);
      el.$span.remove();
    };
    el.updateElementWidth(options.text);
  },
  update(el, binding) {
    const options = binding.value || {};
    const oldOptions = binding.oldValue || {};
    if (! options.text || options.text == oldOptions.text) return;
    el.updateElementWidth(options.text);
  },
});

Vue.directive('fixToTop', {
  inserted(el, binding) {
    const element = $(el);
    let offsetTop;
    let startTop;
    let startMarginLeft;
    let height;
    let width;
    let placeholder;
    const options = binding.value || {};

    if (options.placeholder) placeholder = element.siblings(`.${options.placeholder}`);
    let elementReady = false;

    function updateClip() {
      if (element.hasClass('fixed') && options.xScroll) {
        const xscroll = $(options.xScroll).scrollLeft();
        const marginLeft = xscroll + $(window).scrollLeft();
        element.css('clip', `rect(auto,${width + xscroll}px,${height + 10}px,${xscroll}px)`).css('margin-left', `${-marginLeft}px`);
      } else {
        element.css('clip', 'auto').css('margin-left', startMarginLeft);
      }
      if (element.hasClass('fixed')) {
        element.css('top', `${offsetTop}px`);
      } else {
        element.css('top', '');
      }
    }

    function updateElementVars() {
      offsetTop = (options.topbar ? $(options.topbar).height() : 0) || 0;
      startTop = Math.max(0, element.offset().top - offsetTop);
      startMarginLeft = element.css('margin-left');
      height = element.outerHeight();
      width = options.xScroll ? Math.min($(options.xScroll).width(), element.outerWidth()) : element.outerWidth();
      if (placeholder) placeholder.hide().css('height', height);
      elementReady = height > 0;
    }

    if (options.xScroll) {
      $(options.xScroll).bind('scroll', updateClip);
    }

    function onScroll() {
      if (! elementReady) return;
      if ($(window).scrollTop() > startTop) {
        if (! element.hasClass('fixed')) updateElementVars();
        element.addClass('fixed');
        if (placeholder) placeholder.show();
      } else {
        element.removeClass('fixed');
        if (placeholder) placeholder.hide();
      }
      updateClip();
    }

    $(window).bind('scroll', onScroll);

    function init() {
      updateElementVars();
      if (elementReady) {
        onScroll();
      } else {
        setTimeout(init, 300);
      }
    }
    setTimeout(init);
    el.onScroll = onScroll;
  },
  unbind(el) {
    $(window).off('scroll', el.onScroll);
  },
});
