const undoState = {};
// LOADING ALERTS
let loadtries = 10;

class Alert {
  constructor(el, { days, relative, relativeto, hour, sendothers } = {}) {
    this.days = days || 0;
    this.relative = relative || 'before';
    this.relativeto = relativeto || 'starttime';
    this.hour = hour || 9;
    this.sendothers = !! sendothers;
    this.updateEl(el);
  }

  updateEl(el) {
    this.element_id = el.id;
    this.element_title = el.getTitle();
    this.element_relativedate = el.data[this.relativeto];
    this.getPlanning = el.getPlanning;
  }

  getSendDate() {
    return moment(this.element_relativedate).startOf('day').hour(this.hour).add(this.relative == 'before' ? -this.days : this.days, 'days');
  }

  save() {
    const planning = this.getPlanning();
    if (! planning) return;
    const api = `plannings/${planning.id}/alerts`;
    const data = _.extend(angular.copy(this), {
      send_date: this.getSendDate().format(),
    });

    const thisalert = this;
    const action = this.id ? 'update' : 'store';
    window.apiSrv.call(api, action, data).then((response) => {
      thisalert.id = response.data.alert.id;
      undoState[thisalert.id] = angular.copy(thisalert);
    });
  }

  updateAndSave(el) {
    this.updateEl(el);
    if (this.id && angular.equals(this, undoState[this.id])) return;
    this.save();
  }

  remove() {
    if (! this.id) return;
    const planning = this.getPlanning();
    if (! planning) return;
    const api = `plannings/${planning.id}/alerts`;
    window.apiSrv.call(api, 'destroy', this.id);
  }

  static load(planning) {
    if (! planning || ! planning.id) return;
    const api = `plannings/${planning.id}/alerts`;

    window.apiSrv.call(api, 'index', null, true).then((response) => {
      (response.data.alerts || []).forEach((alertData) => {
        const el = (planning.elements || []).find(item => item.id == alertData.element_id);
        if (el) {
          const newAlert = new Alert(el, alertData);
          newAlert.id = alertData.id;
          if (! el.alerts) el.alerts = [];
          el.alerts.push(newAlert);
          undoState[newAlert.id] = angular.copy(newAlert);
        }
      });
      console.log('alerts loaded');
    }, (reason) => {
      console.log('alerts loading failed for ', reason);
      loadtries -= 1;
      if (loadtries >= 0) setTimeout(Alert.load, 3000);
    });
  }
}

export default Alert;
