let undoState = {};

function undoredoConfig({ state: config, dispatch }) {
  if (angular.equals(config, undoState)) return;
  const newState = angular.copy(config);
  const oldState = angular.copy(undoState);

  dispatch('undoredo/add', {
    action: () => {
      config.set(angular.copy(newState));
      config.setDesign();
    },
    rollback: () => {
      config.set(angular.copy(oldState));
      config.setDesign();
    },
  }, { root: true });
  dispatch('planning/save', null, { root: true });
}

function init({ state: config }) {
  config.setDesign();
}

function startChangingConfig({ state: config }) {
  undoState = angular.copy(config);
}
function changingConfig(context) {
  const { state: config } = context;
  if (! angular.equals(config, undoState)) {
    undoredoConfig(context);
  }
}

export default {
  namespaced: true,
  state: {},
  actions: {
    init,
    startChangingConfig,
    changingConfig,
  },
};
