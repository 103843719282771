<template>
  <v-menu v-if="isVisible" offset-y left>
    <template #activator="{ on }">
      <v-btn :class="{ 'v-btn--active': isActive }" text class="pl-1 pr-2" style="min-width: 0" v-on="on">
        <v-icon dark small>fas fa-caret-down</v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item v-for="(navigation, index) in projectNavigationHistoryFiltered" :key="index"
                   :to="navigation.path + (navigation.rotoken ? `?rotoken=${navigation.rotoken}` : '')">
        <i v-if="navigation.type == 'planningview'">Vue : &nbsp;</i>{{ navigation.title }}
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  export default {
    computed: {
      projectNavigationHistory() {
        return this.$store.getters['ui/navigation/getProjectNavigationHistory'];
      },
      projectNavigationHistoryFiltered() {
        const currentPath = this.$route.path;
        return this.projectNavigationHistory.filter(item => item.path != currentPath);
      },
      isVisible() {
        return !! this.projectNavigationHistoryFiltered.length;
      },
      isActive() {
        return this.$route.path.startsWith('/projects');
      },
    },
    watch: {
      isVisible: {
        handler(newVal) {
          this.$emit('is-visible', newVal);
        },
        immediate: true,
      },
    },
  };
</script>
