export default {
  methods: {
    canIOpenUser(user) {
      if (user.id == this.$store.state.users.user.id) return true;
      if (! this.userIsAdmin) return false;
      if (! this.$store.state.users.user.organization) return true;
      return this.$store.getters['users/getOrganizationSubCompanies'].find(company => (company.users || []).find(item => item.id == user.id)) && true || false;
    },
    canIEditUserRights(user) {
      if (['consult', 'user', 'company_admin'].indexOf(user.access_right) === -1) return false;
      return (this.userIsAdmin && this.canIOpenUser(user));
    },
    uppercase(str) {
      return str && str.toUpperCase();
    },
  },
};
