<template>
  <div v-if="lastStep && lastStep.mood && lastStep.mood != 'none'">
    <v-menu :disabled="! lastStep.description && ! lastStep.issues" :close-on-content-click="false" open-on-hover right offset-x content-class="monitoringplan-meteo-popover">
      <template #activator="{ on }">
        <div :class="'mood-icon-' + lastStep.mood" data-role="trigger"
             style="height: 50px; width: 60px; background-size: contain; background-position: center; background-repeat: no-repeat;"
             v-on="on">
        </div>
      </template>
      <v-card class="px-4 py-2">
        <div v-show="lastStep.description"><b>{{ $t('MONITORING_PLAN.WAYPOINT') }} :</b></div>
        <div v-html="lastStep.description"></div>
        <div v-show="lastStep.issues" :class="lastStep.description ? 'mt-2' : ''"><b>{{ $t('MONITORING_PLAN.BLOCKING_POINTS') }} :</b></div>
        <div v-html="lastStep.issues"></div>
      </v-card>
    </v-menu>
  </div>
</template>

<style>
  .monitoringplan-meteo-popover {
    z-index: 112 !important;
  }
</style>

<script>
  import { mapState } from 'vuex';

  export default {
    props: {
      lane: { type: Object, default: () => {} },
    },
    data() {
      return {
      };
    },
    computed: {
      monitoringLane() {
        return this.monitoringPlanByPlanningsByLanes[this.lane.getPlanning().id]
          && this.monitoringPlanByPlanningsByLanes[this.lane.getPlanning().id][this.lane.id] || {};
      },
      lastStep() {
        return this.monitoringLane && this.monitoringLane.steps && this.monitoringLane.steps.last();
      },
      ...mapState('monitoringplan', ['monitoringPlanByPlanningsByLanes']),
    },
  };
</script>
