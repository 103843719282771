<template>
  <v-card class="kanban">
    <div :class="`${col.color}--text`" class="py-2 pl-6" style="border-bottom: 3px solid">
      <v-icon :color="col.color" class="mr-2" small>{{ col.icon }}</v-icon>
      <span class="text-subtitle-1">{{ $t(col.label) }}</span>
    </div>
    <div class="px-2 mb-2 pt-2" style="overflow-y: auto; height: 100%">
      <transition-group name="list-fade" tag="div" style="position: relative;">
        <div v-for="el in colElements" :key="el.id" class="row list-fade-item" style="margin:0 0 16px; page-break-inside: avoid; width: 100%; transform: none">
          <div :title="el.getStartTime() | moment('mediumDate')" class="col-2 small" style="padding: 0">
            {{ el.getStartTime() | moment('mediumDateNoYear') }}
          </div>
          <div class="col-8" style="padding: 0 2px">
            <planning-element-view :id="`el${el.id}`" :el="el" :title="el.display_title || ''"
                                   :style="{ cursor: canEditEl(el) ? 'pointer' : 'default' }" style="position: relative" disable-mini-element
                                   @select-color="selectColor(el)" @checklist-click="checklistClick(el, $event)" @progress-click="progressClick(el, $event)"
                                   @dyn-subtasks-click="dynSubtasksClick(el, $event)"
                                   @click.native.stop="elementClick($event, el)">
              <template v-if="canEditEl(el) && col.id != 'finished'" #hover-menu>
                <div class="element-menu" style="left: 100%; width:25px">
                  <div style="position: absolute; bottom: 0;">
                    <v-btn :title="$t('MONITORING_PROGRESS.VALIDATE_PROGRESS')" icon small outlined color="midgrey lighten-1" class="ma-0" @click="validateProgress(el)">
                      <v-icon color="accent" size="16">fas fa-check</v-icon>
                    </v-btn>
                  </div>
                </div>
              </template>
            </planning-element-view>
          </div>
          <div v-if="! el.isType('milestone')" :title="el.getEndTime() | moment('mediumDate')" class="col-2 small text-right" style="padding: 0">
            {{ el.getEndTime() | moment('mediumDateNoYear') }}
          </div>
        </div>
      </transition-group>
      <div v-if="! colElements.length" class="text-muted">{{ $t('MONITORING_PROGRESS.NO_BUBBLE_AVAILABLE') }}</div>
    </div>
  </v-card>
</template>

<style>
  .kanban {
    width: 300px;
    max-width: 400px;
    margin-right: 15px;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
  }
  .kanban:last-child {
    margin-right: 0;
  }
  .kanban .milestone-icon {
    position: static !important;
    display: inline-block !important;
    vertical-align: middle !important;
    margin: 0 !important;
    min-height: 0 !important;
  }
  .kanban .milestone-icon + * {
    display: inline-block !important;
    vertical-align: middle !important;
    max-width: 100%;
  }
  .kanban .milestone-icon .milestone-icon-dash {
    display: none !important;
  }
</style>

<script>
  import { mapActions } from 'vuex';
  import PlanningElementView from '@/components/Planning/PlanningElementView';

  export default {
    components: {
      PlanningElementView,
    },
    props: {
      col: { type: Object, required: true }, // { id, color, icon, label, orderBy }
      planning: { type: Object, required: true },
    },
    data() {
      return {
      };
    },
    computed: {
      sortFunction() {
        if (this.col.orderBy == 'data.starttime') {
          return (a, b) => (a.getStartTime().isBefore(b.getStartTime()) ? -1 : 1);
        }
        if (this.col.orderBy == '-data.starttime') {
          return (a, b) => (b.getStartTime().isBefore(a.getStartTime()) ? -1 : 1);
        }
        if (this.col.orderBy == 'data.endtime') {
          return (a, b) => (a.getEndTime().isBefore(b.getEndTime()) ? -1 : 1);
        }
        if (this.col.orderBy == '-data.endtime') {
          return (a, b) => (b.getEndTime().isBefore(a.getEndTime()) ? -1 : 1);
        }
        return () => {};
      },
      colElements() {
        return this.planning.elements.filter(el => el.kanban_col == this.col.id).sort(this.sortFunction);
      },
    },
    methods: {
      canEditEl(el) {
        const planning = el.getPlanning();
        return planning && planning.meta && planning.meta.access_right && planning.meta.access_right != 'consult';
      },
      elementClick(event, el) {
        if (! this.canEditEl(el)) return;
        this.openElementDetails(el);
      },
      selectColor(el) {
        if (! this.canEditEl(el)) return;
        this.openColorPicker(el);
      },
      checklistClick(el, item) {
        if (! this.canEditEl(el)) return;
        const oldState = { checklist: angular.copy(el.getChecklist()), progress: el.getProgress() };
        this.$set(item, 'checked', ! item.checked);
        // el.updateChecklistProgress();
        this.$store.dispatch('multiprojects/formatEl', { el });
        this.$emit('save-el', { el, props: ['checklist', 'progress'], oldState });
      },
      dynSubtasksClick(el, item) {
        if (! this.canEditEl(el)) return;
        const oldState = { customFields: angular.copy(el.getCustomFields()), progress: el.getProgress() };
        this.$set(item, 'checked', ! item.checked);
        el.updateChecklistProgress();
        this.$store.dispatch('multiprojects/formatEl', { el });
        this.$emit('save-el', { el, props: ['customFields', 'progress'], oldState });
      },
      progressClick(el, event) {
        if (! this.canEditEl(el)) return;
        const oldState = { progress: el.getProgress() };
        const fullWidth = $(event.currentTarget).width();
        if (! fullWidth) return;
        el.setProgress(Math.min(Math.round(event.offsetX / fullWidth * 10) * 10, 100));
        if (el.getProgress() != oldState.progress) {
          this.$store.dispatch('multiprojects/formatEl', { el });
          this.$emit('save-el', { el, props: ['progress'], oldState });
        }
      },
      validateProgress(el) {
        if (! this.canEditEl(el)) return;
        const oldState = { progress: el.getProgress() };
        el.setProgress(100);
        if (el.getProgress() != oldState.progress) {
          this.$store.dispatch('multiprojects/formatEl', { el });
          this.$emit('save-el', { el, props: ['progress'], oldState });
        }
      },
      ...mapActions('ui/kanban', ['openElementDetails', 'openColorPicker']),
    },
  };
</script>
