<template>
  <section class="exchangecenter">
    <v-text-field v-if="config.search" ref="searchBar" v-model="searchFilter" :label="$t('EXCHANGECENTER.SEARCH')" solo height="36" hide-details
                  clearable prepend-inner-icon="fas fa-search" class="mb-2 mr-1 small-input" style="flex: 0 0 auto">
    </v-text-field>
    <div class="chatBox">
      <div ref="chatContent" class="chatContent">
        <div v-if="loading.inprogress && ! exchanges.length" style="text-align:center;"><i class="fas fa-spinner fa-spin fa-2x fa-fw"></i></div>
        <div v-if="loading.error" style="padding:15px">{{ loading.error }}</div>
        <div v-show="! loading.inprogress && ! loading.error && ! exchanges.length" class="small text-muted">{{ $t('EXCHANGECENTER.BE_FIRST_TO_LET_MESSAGE') }}</div>
        <exchange-center-line v-for="(exchange, index) in filteredExchanges" :key="index"
                              :exchange="exchange" :prev-exchange="filteredExchanges[index-1]" :next-exchange="filteredExchanges[index+1]"
                              @edit="editComment(exchange)" @delete="deleteComment(exchange)" @select-at="selectAt($event)" @select-hashtag="selectHashtag($event)" @autoscroll="autoscroll">
        </exchange-center-line>
      </div>

      <!-- New msg input bar -->
      <div>
        <form @submit.prevent="postMessage()">
          <v-text-field ref="newMsgInput" v-model="newMsg" :label="$t('EXCHANGECENTER.ENTER_YOUR_MESSAGE_HERE')" autocomplete="off" hide-details>
            <template v-if="config.files" #prepend>
              <v-btn :title="! isPremium ? $t('EXCHANGECENTER.UPLOAD_FILE_FOR_PREMIUM') : $t('EXCHANGECENTER.UPLOAD_FILE')" icon small @click="openFileDialog">
                <vue-upload-component ref="upload" v-model="newFiles" :post-action="uploadFileUrl" :headers="tokenHeaders"
                                      :multiple="false" :thread="5" :size="20 * 1024 * 1024" :disabled="! isPremium"
                                      :class="{ pointer: isPremium }" input-id="file-exchangecenter"
                                      @input-file="inputFile" @click.native.stop>
                  <v-icon color="secondary" small>fas fa-paperclip</v-icon>
                </vue-upload-component>
              </v-btn>
            </template>
            <template #append>
              <v-btn :title="$t('EXCHANGECENTER.SEND')" :disabled="! newMsg.length && ! (newFiles.length && newFiles[0].success)" icon small type="submit">
                <v-icon color="accent" small>fas fa-paper-plane</v-icon>
              </v-btn>
            </template>
          </v-text-field>
          <v-menu :value="atUsersOptions.length" :activator="newMsgInputNode" :open-on-click="false" :max-height="120" top offset-y>
            <v-list dense>
              <v-list-item v-for="atUser in atUsersOptions" :key="atUser.id" @click="onSelectAtUser(atUser); setFocus();">{{ atUser.atvalue }}</v-list-item>
            </v-list>
          </v-menu>
          <div class="text-muted small">
            <div v-for="file in newFiles" class="display-flex" style="overflow: hidden">
              <span>{{ file.name }}</span>
              <v-progress-linear :color="file.error ? 'errorred' : 'successgreen'" :title="fileErrorMessage(file) || (Math.round(file.progress * 1) + ' %')"
                                 :value="file.error ? 100 : (file.progress < 100 ? file.progress : 100)" height="16" rounded
                                 class="ml-2 white--text text-center" style="flex: 1 1 auto; min-width: 150px">
                <span class="ml-1">{{ fileErrorMessage(file) || (Math.round(file.progress * 1) + ' %') }}</span>
              </v-progress-linear>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<style>
  .exchangecenter {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .exchangecenter .chatBox {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  _:-ms-fullscreen, :root .exchangecenter .chatBox { /* IE 11 */
    min-height: 150px;
  }

  .exchangecenter .chatContent {
    flex-grow: 1;
    flex-basis: 100%;
    overflow-y: auto;
    padding-right:7px;
  }

  .file-uploads.file-uploads-html5 label {
    cursor: pointer;
  }
</style>

<script>
  import VueUploadComponent from 'vue-upload-component';
  import { deepcopy } from '@/components/Reusables/utils';
  import ExchangeCenterLine from './ExchangeCenterLine';
  import ApiStoredComment from './ApiStoredComment';
  import LocalStoredComment from './LocalStoredComment';

  require('@/css/emojis/emoji.min.css');

  export default {
    components: {
      ExchangeCenterLine,
      VueUploadComponent,
    },
    props: {
      options: { type: Object, default: () => {} },
      localcomments: { type: Array, default: null },
      parentvisible: { type: Boolean, default: true },
    },
    data() {
      const config = deepcopy(this.options) || {};
      if (typeof config.search == 'undefined') config.search = true;
      if (typeof config.files == 'undefined') config.files = true;
      const apiStored = config.planning_id || config.meeting_id;
      return {
        config,
        planning_id: config.planning_id,
        element_id: config.element_id,
        meeting_id: config.meeting_id,
        apiStored,
        Comment: apiStored ? ApiStoredComment : LocalStoredComment,
        exchanges: [],
        me: null,
        searchFilter: "",
        newMsg: "",
        preventPostMessage: false,
        newFiles: [],
        uploadFileUrl: `${window.apiSrv.host}v1/files`,
        tokenHeaders: window.apiSrv.getTokenHeaders(),
        loading: { inprogress: false, success: false, error: false },
        newMsgInputNode: null,
      };
    },
    computed: {
      filteredExchanges() {
        return this.exchanges.filter(item => JSON.stringify(item).toLowerCase().indexOf((this.searchFilter || "").toLowerCase().trim()) > -1);
      },
      companyusers() {
        const { user } = this.$store.state.users;
        if (! (user && user.company && user.company.users)) return [];
        return user.company.users.map(item => _.extend(angular.copy(item), { atvalue: `@${(item.firstname || item.email).toLowerCase()}` }));
      },
      atUsersOptions() {
        let lastword = this.newMsg.split(" ").last();
        if (! lastword || lastword.charAt(0) != '@') return [];
        lastword = lastword.toLowerCase();
        return this.companyusers.filter(user => user.atvalue.startsWith(lastword));
      },
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
    },
    watch: {
      parentvisible(newVal, oldVal) {
        if (newVal && ! oldVal) {
          this.autoscroll();
          if (this.config.autofocus) this.setFocus();
        }
      },
      filteredExchanges() {
        this.autoscroll();
      },
      localcomments() {
        this.loadExchanges();
      },
    },
    created() {
      this.loadExchanges();

      // real time updates
      if (this.apiStored) {
        const params = { target_type: 'exchangeCenter' };
        if (this.planning_id && this.element_id) {
          _.extend(params, { section_type: 'planning', section_id: this.planning_id, target_type: 'element', target_id: this.element_id, field_type: 'comment' });
        } else if (this.planning_id) {
          _.extend(params, { section_type: 'planning', section_id: this.planning_id, field_type: 'comment' });
        } else if (this.meeting_id) {
          _.extend(params, { section_type: 'meeting', section_id: this.meeting_id, field_type: 'comment' });
        }
        window.notificationsSrv.checkExchangeCenterUpdates(params, this.loadExchanges);
      }
    },
    mounted() {
      this.newMsgInputNode = this.$refs.newMsgInput.$el;
      if (this.config.autofocus) this.setFocus();
    },
    beforeDestroy() {
      if (this.apiStored) {
        const params = { target_type: 'exchangeCenter' };
        if (this.planning_id && this.element_id) {
          _.extend(params, { section_type: 'planning', section_id: this.planning_id, target_type: 'element', target_id: this.element_id, field_type: 'comment' });
        } else if (this.planning_id) {
          _.extend(params, { section_type: 'planning', section_id: this.planning_id, field_type: 'comment' });
        } else if (this.meeting_id) {
          _.extend(params, { section_type: 'meeting', section_id: this.meeting_id, field_type: 'comment' });
        }
        window.notificationsSrv.stopCheckingExchangeCenterUpdates(params);
      }
    },
    methods: {
      setFocus() {
        this.$refs.newMsgInput.focus();
      },
      autoscroll() {
        this.$nextTick(() => { $(this.$refs.chatContent).scrollTop(this.$refs.chatContent.scrollHeight); });
      },
      openFileDialog() {
        /* if you miss the upload component inside the button (mostly IE), open file dialog in js */
        if (! this.isPremium) return;
        const input = this.$refs.upload.$el.querySelector('input');
        if (input) input.click();
      },
      selectAt(at) {
        if (! this.config.search) return;
        this.searchFilter = at.substr(1);
        this.$refs.searchBar.focus();
      },
      selectHashtag(hashtag) {
        if (! this.config.search) return;
        this.searchFilter = hashtag;
        this.$refs.searchBar.focus();
      },
      onSelectAtUser(user) {
        const words = this.newMsg.split(" ");
        if (! words.length) return;
        words[words.length - 1] = user.atvalue;
        this.newMsg = words.join(" ");
        this.preventPostMessage = true;
        setTimeout(() => { this.preventPostMessage = false; }, 300);
      },
      loadExchanges() {
        this.loading = { inprogress: true };
        return this.Comment.loadExchanges(this.config, this.localcomments).then((response) => {
          this.exchanges = response && response.data && response.data.exchanges;
          this.loading = { inprogress: false, success: true };
        }).catch((message) => {
          this.loading = { inprogress: false, error: message || "Unknown error" };
        });
      },
      postMessage() {
        if (this.preventPostMessage) return;
        const file = this.newFiles[0];
        if (! this.newMsg && ! file) return;

        const comment = new this.Comment({
          planning_id: this.planning_id,
          element_id: this.element_id,
          meeting_id: this.meeting_id,
          text: this.newMsg,
        });

        if (file) {
          if (! file.success) return; // upload in progress
          comment.file_id = file.response && file.response.file && file.response.file.id || 0;
        }
        comment.store().then(() => {
          this.loadExchanges();
          this.$emit('add', comment.getData());
          if (comment.planning_id || comment.meeting_id) this.sendSocketNotif(comment);
        });

        const tempComment = {
          user: this.$store.state.users.user,
          date: moment().format(),
          text: this.newMsg || "",
          waiting: true,
        };
        this.exchanges = this.exchanges || [];
        this.exchanges.push(tempComment);
        this.newMsg = "";
        this.newFiles = [];
      },
      editComment(comment) {
        if (! comment.id) return;
        const newtext = prompt(this.$t("EXCHANGECENTER.EDIT_MESSAGE"), comment.text);
        if (newtext === null || newtext === comment.text) return;
        comment.waiting = true;
        comment.editMessage(newtext).then(() => {
          this.loadExchanges();
          this.$emit('update', comment.getData());
        }).catch((message) => {
          this.$store.dispatch('ui/msgbox/open', { title: "Error : message could not be edited", body: message || "" });
          comment.waiting = false;
        });
      },
      deleteComment(comment) {
        if (! comment.id) return;
        this.$store.dispatch('ui/msgbox/open', {
          title: "EXCHANGECENTER.DELETE_MESSAGE_CONFIRMATION",
          body: comment.text,
          buttons: { ok: "GLOBAL.OK", cancel: "GLOBAL.CANCEL" },
        }).then(() => {
          comment.waiting = true;
          comment.delete().then(() => {
            const index = this.exchanges.findIndex(item => item.id == comment.id);
            if (index > -1) this.exchanges.splice(index, 1);
            this.$emit('delete', comment.getData());
          }).catch((message) => {
            this.$store.dispatch('ui/msgbox/open', { title: "Error : message could not be deleted", body: message || "" });
          }).finally(() => {
            comment.waiting = false;
          });
        }).catch(() => {});
      },
      inputFile(newFile, oldFile) {
        // Automatic upload
        if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
          if (! this.$refs.upload.active) this.$refs.upload.active = true;
        }
      },
      fileErrorMessage(file) {
        if (! file.error) return "";
        if (file.error === 'size') return this.$t('EXCHANGECENTER.FILE_SIZE_LIMITED');
        if (file.response) return file.response.message || file.response.status;
        return file.error;
      },
      sendSocketNotif(comment) {
        const targetUsersAtvalues = comment.text && comment.text.match(/(^|\s)(@[^ ]*)\b/g) || [];
        const targetUsers = [];
        targetUsersAtvalues.forEach((targetUserAtValue) => {
          const atValue = targetUserAtValue.trim();
          const companyuser = this.companyusers.find(item => item.atvalue == atValue);
          if (companyuser) targetUsers.push(companyuser);
        });

        if (comment.planning_id && comment.element_id) {
          const el = this.$store.state.planning.elements.find(item => item.id == comment.element_id);
          window.notificationsSrv.callEvent('planning.commentElement', { id: comment.id, text: comment.text, el, target_users: targetUsers });
        } else if (comment.planning_id) {
          window.notificationsSrv.callEvent('exchangeCenter.newMessage', {
            id: comment.id, text: comment.text, file: comment.file ? comment.file.name : null, target_users: targetUsers, section_type: 'planning', section_id: comment.planning_id,
          });
        } else if (comment.meeting_id) {
          window.notificationsSrv.callEvent('exchangeCenter.newMessage', {
            id: comment.id, text: comment.text, file: comment.file ? comment.file.name : null, target_users: targetUsers, section_type: 'meeting', section_id: comment.meeting_id,
          });
        }
      },
    },
  };
</script>
