<template>
  <section class="modal-with-tabs">
    <div class="modal-with-tabs-header primary--text px-6 pt-4 pb-2 display-flex" style="border-bottom: 3px solid">
      <span class="text-h4">{{ $t('HELP.VIDEO_TUTOS') }}</span>
      <v-spacer></v-spacer>
      <v-btn href="https://www.youtube.com/channel/UCDb4feDzsa4fCd_vR5HZJgg" target="_blank" color="primary" rounded class="mx-2 text-uppercase">
        <v-icon size="14" class="mr-2">fas fa-external-link</v-icon>{{ $t('HELP.YOUTUBE_CHANNEL') }}
      </v-btn>
      <v-btn href="https://bubbleplan.net/blog" target="_blank" color="primary" rounded class="mx-2 text-uppercase">
        <v-icon size="14" class="mr-2">fas fa-external-link</v-icon>{{ $t('HELP.BLOG') }}
      </v-btn>
    </div>
    <v-container class="pa-0" style="height: calc(90vh - 75px); flex: 1 1 auto;">
      <v-row class="ma-0" style="height: 100%;">
        <v-col cols="4" style="height: 100%; overflow-y: auto;">
          <template v-for="(categoryName, category) in categories">
            <h4 class="primary--text">{{ categoryName }}</h4>
            <v-expansion-panels :key="category" focusable class="mb-8">
              <v-expansion-panel v-for="video in videos[category]" :key="video.youtubeId" @click.native="selectedVideo = video">
                <v-expansion-panel-header class="px-2" style="line-height: 1.3em">
                  <v-icon color="errorred" class="flex-grow-0 mr-2">fab fa-youtube</v-icon>
                  <div class="mr-1">
                    <b>{{ video.title }}</b>
                    <div class="mt-n1" style="position: relative; bottom: -8px">
                      <v-chip v-if="video.premium" :color="video.enterprise ? 'successgreen' : video.business ? 'successgreen' : 'warningorange'"
                              small text-color="white">
                        <v-icon color="white" class="mr-2">fas {{ video.enterprise ? 'fa-building' : video.business ? 'fa-rocket-launch' : 'fa-star' }}</v-icon>
                        <span v-if="video.enterprise">{{ $t('PREMIUM.ENTERPRISE_FEATURE') }}</span>
                        <span v-else-if="video.business">{{ $t('PREMIUM.BUSINESS_FEATURE') }}</span>
                        <span v-else>{{ $t('PREMIUM.PREMIUM_FEATURE') }}</span>
                      </v-chip>
                    </div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  {{ video.description }}
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </template>
        </v-col>
        <v-col cols="8" style="height: 100%">
          <iframe :src="'https://www.youtube.com/embed/' + selectedVideo.youtubeId" frameborder="0" gesture="media" allow="encrypted-media" allowfullscreen
                  style="width:100%; height: 100%">
          </iframe>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
  export default {
    data() {
      return {
        selectedVideo: null,
        categories: {
          starter: 'Prise en main',
          visual: 'Management Visuel',
          planification: 'Planification',
          management: 'Suivi de projet',
          dashboards: 'Tableaux de bord',
          templates: 'Modèles et sous-projets',
          business: 'Business',
        },
        videos: {
          starter: [
            {
              title: "GÉRER L'AXE TEMPOREL",
              description: "Comment organiser votre échelle de temps selon les particularités de votre projet."
                + " Prolonger un planning, modifier sa forme en fonction des projets, plutôt macro ou plutôt micro, ... : toutes les clés pour gérer parfaitement votre axe temporel.",
              youtubeId: 'AFREKaiqioY',
            },
            {
              title: "COMPRENDRE LES MENUS",
              description: "De nombreux menus et options sont disponibles dans notre outil collaboratif de gestion de projet."
                + " Découvrez où se cachent ces options, leur utilité; trouvez facilement vos fonctionnalités et ne ratez rien de Bubble Plan."
                + " Vous pouvez également télécharger notre guide utilisateur en PDF pour repérer facilement toutes les fonctions.",
              youtubeId: 'DUSnyGMrYFg',
            },
            {
              title: "CRÉER UNE BULLE OU UN JALON",
              description: "Découvrez les différentes manières d'ajouter facilement de nouveaux éléments à vos projets.",
              youtubeId: 'l_4lnOPWTo4',
            },
            {
              title: "GÉRER LES DATES ET LES DURÉES DES BULLES",
              description: "Découvrez les différentes manière d'ajouter et modifier les dates et les durées de vos bulles et jalons.",
              youtubeId: 'NY6zdOl_w7k',
            },
            {
              title: "GÉRER LES PIÈCES-JOINTES",
              description: "Idéales pour faire circuler et centraliser les informations, les pièces-jointes sont consultables à tout moment et par tous les intervenants.",
              youtubeId: 'UnZ_McitiwE',
            },
            {
              title: "COMPRENDRE LA MISE EN RETRAIT DES LIGNES",
              description: "Cette vidéo vous fait découvrir comment segmenter votre projet en utilisant la fonction d'indentation des lignes (mise en retrait).",
              youtubeId: 'cMOnYEBiqBE',
            },
            {
              title: "LA GESTION DES LIGNES DE VOS PROJETS",
              description: "Choix des types de lignes, structuration du planning, réorganisation rapide et groupée de vos lignes :"
                + " découvrez comment profiter au mieux des options de gestion des lignes. Certaines options sont réservées aux utilisateurs PREMIUM.",
              youtubeId: '_tOIM0HthKU',
            },
            {
              title: "CRÉER UN NOUVEAU PLANNING SUR BUBBLE PLAN",
              description: "Découvrez comment paramétrer un nouveau planning projet et lancez tous les nouveaux projets de votre périmètre d'activité.",
              youtubeId: '-Tml57sf0yM',
              premium: true,
            },
            {
              title: "SUPPRIMER UN PROJET BUBBLE PLAN",
              description: "Découvrez comment s'effectue la suppression définitive d'un planning projet.",
              youtubeId: 'Iq-YV0FXu44',
            },
          ],
          visual: [
            {
              title: "DEFINIR CE QUI EST VISIBLE SUR VOS PLANNINGS PROJETS",
              description: "La fonction de masquage, représentée par le petit œil dans Bubble Plan, est idéale pour structurer votre projet et en gérer l'affichage."
                + " Il est essentiel de choisir ce que peuvent voir les acteurs limités à la lecture seule ou ce que vous voulez montrer sur votre planning, pour qu'il soit clair et intuitif.",
              youtubeId: '7puEbVhTlGg',
            },
            {
              title: "COMMENT UTILISER LE MARQUEUR VERTICAL",
              description: "Le marqueur vertical coloriel apporte une toute nouvelle dimension graphique à vos projets."
                + " Renforcez le management visuel de vos projets grâce à ce nouveau repère visuel aux multiples utilisations.",
              youtubeId: 'xqMMREIdl4c',
            },
            {
              title: "LA MÉTÉO PROJET POUR VOS REPORTINGS",
              description: "Fonction intégrée dans votre Fiche Projet, la météo, aussi appelée \"point de suivi\","
                + " permet de donner une autre dimension en terme de management visuel et de reporting sur votre projet.",
              youtubeId: 'DJMGnR7O-s4',
            },
          ],
          planification: [
            {
              title: "COMPRENDRE LES INTERVENANTS",
              description: "Affiner vos process avec les intervenants et les nombreuses utilisations que vous pouvez en faire"
                + " Découvrez les différences entre les intervenants réels et virtuels et où les retrouver",
              youtubeId: 'K8hHyoo3ufg',
            },
            {
              title: "ORGANISER VOTRE CONTENU AVEC DES CHECKLISTS D'ACTIONS",
              description: "Cette vidéo vous montre les possibilités pour segmenter votre projet et créer des sous-tâches ou actions.",
              youtubeId: 'NoJzNY3iwR8',
            },
            {
              title: "COMPRENDRE LA GESTION DES ACTIONS",
              description: "Checklists, tâches, organisation des deadlines : des compétences indispensables en gestion de projet opérationnel."
                + " Découvrez comment fonctionne le système de gestion des actions de Bubble Plan. Créez vos tâches et détectez les impératifs, pour un suivi de projet efficace.",
              youtubeId: '0ObRhI_Ivkk',
            },
            {
              title: "LA PLANIFICATION AVANCÉE",
              description: "Découvrez comment franchir un nouveau palier sur la gestion des actions, des intervenants ou piloter votre charge de travail, grâce à l'option Planification avancée.",
              youtubeId: 'vOqQnjHq60E',
              premium: true,
            },
            {
              title: "CRÉER ET COMPRENDRE LES LIAISONS GANTT",
              description: "Découvrez comment ordonnancer votre planning avec des contraintes de réalisation, de type Gantt.",
              youtubeId: 'VUC_vrUkQnM',
            },
            {
              title: "GÉRER LE VERROUILLAGE D'ÉVÉNEMENTS INAMOVIBLES",
              description: "Dans une logique Gantt, il est souvent utile de pouvoir matérialiser des événements, des jalons, des phases qui sont imposées et ne devront pas bouger."
                + " Ces éléments incompressibles qui impactent votre projet et d'éventuels décalages.",
              youtubeId: 'NRl_JyYaKyM',
            },
            {
              title: "ALLER PLUS LOIN DANS L'AUTOMATISATION DES PROCESS SOUS GANTT",
              description: "Découvrez comment intégrer les contraintes projets et mettre en place des automatismes pour suivre vos impacts et piloter efficacement tous vos projets.",
              youtubeId: '0z7YbA5qKE4',
            },
            {
              title: "PLANIFIER UNE ALERTE SUR BUBBLE PLAN",
              description: "Planifiez une alerte, notion différente des notifications, sur une bulle ou un jalon, X jours avant ou après sa date limite, pour ne plus rien louper !"
                + " Découvrez comment les programmer selon vos préférences et restez averti par e-mail.",
              youtubeId: 'z_sSCPl9Krw',
            },
            {
              title: "GÉRER LES NOTIFICATIONS DE SES PROJETS",
              description: "Les notifications vous permettent d'être tenu au courant de l'avancée du projet et de rester engagé dans sa progression."
                + " Découvrez comment définir la fréquence de vos notifications et où les retrouver !",
              youtubeId: 'OFB0VwBHpF0',
            },
          ],
          management: [
            {
              title: "LES CATÉGORIES PROJETS",
              description: "Classer vos projets par catégorie est une manière de les identifier plus facilement et rapidement.",
              youtubeId: 'EwcJIHXGlQg',
            },
            {
              title: "LA FICHE PROJET",
              description: "Chaque planning profite d'une fonctionnalité de synthèse pour décrire et cadrer le projet, retrouver vos indicateurs clés et vos documents partagés."
                + " Une zone parfaite pour donner une vue d'ensemble à tous du projet et de son évolution. On y retrouve également les aspects de consolidation budgétaire de votre planning.",
              youtubeId: 'aG-H2IalpZc',
            },
            {
              title: "MODIFIER LA VIGNETTE DE SON PROJET",
              description: "Personnaliser la vignette de vos projets est une manière de les illustrer concrètement et de les identifier plus facilement.",
              youtubeId: 'GCq6eiX8Pkc',
            },
            {
              title: "PARTAGER UN PLANNING PROJET",
              description: "Outil de présentation par excellence, Bubble Plan vous propose de partager vos projets, en interne ou en externe, selon vos besoins et vos process."
                + " Via la gestion des droits de collaborateurs ou de membres du compte ou par le lien numérique dédié aux invités en lecture seule,"
                + " plusieurs options de partage de vos plannings sont disponibles."
                + " Une fonctionnalité disponible pour tous les utilisateurs, mais un partage plus limité pour les comptes FREEMIUM.",
              youtubeId: 'BvHEr6u_LdM',
            },
            {
              title: "EXPORTER VOTRE PLANNING PROJET",
              description: "CSV, Image, PDF, autant d'options d'export disponibles dans Bubble Plan. Certaines options sont réservées aux utilisateurs PREMIUM.",
              youtubeId: 'SkNDWwJ3Kbk',
            },
          ],
          dashboards: [
            {
              title: "LE TABLEAU DE BORD PERSONNEL BUBBLE PLAN",
              description: "Le tableau de bord Personnel, page d'accueil de votre espace Bubble Plan, synthétise tout ce qui vous concerne sur l'ensemble des projets auxquels vous participez."
                + " Actualités, nouvelles notifications, liste d'actions à réaliser, ... Un centre d'organisation opérationnel, indispensable à chaque utilisateur.",
              youtubeId: 'HN6dsq1oGtk',
            },
            {
              title: "LE TABLEAU DE BORD « PLANNING DES INTERVENANTS » ET SON PLAN DE CHARGE",
              description: "Comment optimiser la gestion des ressources du ou des projets avec un tableau de bord dédié aux équipes, intervenants et participants.",
              youtubeId: 'v7W9Ln8C_zs',
              premium: true,
            },
            {
              title: "LE TABLEAU DE BORD « SUIVI D'AVANCEMENT » POUR UN PILOTAGE OPÉRATIONNEL ET DÉCISIONNEL",
              description: "Pour identifier rapidement les avancements, les retards, au sein de vos projets, profitez d'une analyse rapide de Bubble Plan, grâce au tableau de bord Suivi d'avancement."
                + " Une vue Kanban où notre outil de gestion de projet travaille pour vous afin de trier directement vos éléments projets, pour un gain immédiat en productivité.",
              youtubeId: 'TaU4kKjqIWQ',
              premium: true,
            },
            {
              title: "LE TABLEAU DE BORD « GESTION DES ACTIONS »",
              description: "Profitez d'un pilotage multiple de vos actions grâce au tableau de bord Gestion des Actions et d'une gestion de projet opérationnelle."
                + " Créez, modifiez, validez et redistribuez les actions de vos projets depuis un seul et même endroit.",
              youtubeId: 'kQjcBIakMjw',
              premium: true,
            },
            {
              title: "LE TABLEAU DE BORD « PORTEFEUILLE DE PROJETS »",
              description: "Pilotage et macrovision sont souvent au coeur de la gestion de projet."
                + " Pour mieux suivre et manager l'ensemble des projets de votre périmètre, découvrez comment tout analyser avec le tableau de bord Portefeuille de Projets.",
              youtubeId: 'OC7bTf1RGsg',
              premium: true,
            },
            {
              title: "LE FILTRE PAR DÉFAUT DES TABLEAUX DE BORD",
              description: "Pour une utilisation optimale de vos tableaux de bord et ne pas reprogrammer à chaque fois,"
                + " le filtre par défaut de Bubble Plan sera votre meilleur allié, lors de chaque connexion.",
              youtubeId: 'IEkrmgLpZTY',
              premium: true,
            },
          ],
          templates: [
            {
              title: "COMMENT UTILISER LES MODÈLES/TEMPLATES DE PROJET",
              description: "Pour gagner du temps sur vos projets récurrents, découvrez le fonctionnement des modèles de projet sous Bubble Plan.",
              youtubeId: 'DZyXP-iwhIM',
              premium: true,
            },
            {
              title: "ORGANISER DES VUES « PLANNING GENERAL » AVEC LA FONCTIONNALITÉ SOUS PROJETS",
              description: "Pour superviser et bien piloter l'ensemble de vos projets, il est utile de pouvoir les consulter tous en même temps, en vue Planning."
                + " Cela peut également servir pour des plannings consolidés, par catégorie, par segment, ou autres, selon vos besoins.",
              youtubeId: 'yNd_PRmB-Y0',
              premium: true,
            },
          ],
          business: [
            {
              title: "LA GESTION DES ÉQUIPES",
              description: "Créez des groupes de personnes en fonction des équipes, des services, des compétences, ..."
                + " De nombreuses possibilités pour venir renforcer le travail collaboratif et gagner en expertise dans le pilotage de vos projets.",
              youtubeId: 't_noMlk_mu8',
              premium: true,
              business: true,
            },
            {
              title: "LA GESTION DE CHARGE AVANCÉE EN VERSION BUSINESS",
              description: "Visualisez la charge de travail de vos intervenants directement depuis les bulles et les tableaux de bord."
                + " Elle facilite la distribution des actions et permet d'équilibrer la charge de travail de chaque collaborateur.",
              youtubeId: 'QqIlGCXlycQ',
              premium: true,
              business: true,
            },
            {
              title: "LES VUES PERSONNALISÉES",
              description: "Découvrez le fonctionnement et les subtilités de la fonction Vues Personnalisées pour créer sur vos plannings projets"
                + " des filtres et accès spécifiques, selon vos contraintes.",
              youtubeId: 'XFb0ljW6TR8',
              premium: true,
              business: true,
            },
            {
              title: "LE TABLEAU DE BORD « AGENDA »",
              description: "Un tableau de bord dans lequel vos projets sont représentés sous la forme  d'un agenda, très pratique en gestion de projet opérationnelle.",
              youtubeId: '6Ia__cONgQI',
              premium: true,
              business: true,
            },
            {
              title: "LES PARAMÉTRAGES AUTONOMES",
              description: "Gérez les groupes d'équipes et vos problématiques d'intervenants, définissez vos intervenants virtuels ou l'affichage de vos sous-projets, ..."
                + " grâce à l'interface de paramétrage de la version BUSINESS.",
              youtubeId: 'E0PKNqfz1P0',
              premium: true,
              business: true,
            },
            {
              title: "VOTRE REPORTING AVEC LE TABLEAU DE BORD PLAN DE VEILLE",
              description: "Pour programmer un rapport automatique sur vos projets, vous disposez en version Premium ENTERPRISE d'un Plan de veille complètement paramétrable.",
              youtubeId: '0RnaRCv7JXU',
              premium: true,
              enterprise: true,
            },
          ],
        },
      };
    },
    created() {
      [this.selectedVideo] = this.videos.starter;
    },
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>
