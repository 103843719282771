<template>
  <div>
    <div v-if="isMiniElement">
      <i class="fa fa-paperclip fa-lg"></i> {{ elLinks.length }}
    </div>
    <template v-else>
      <span v-for="link in elLinks">
        <v-menu :disabled="! isPicture(link)" content-class="ml-2 rounded-sm" open-on-hover offset-x transition="scale-transition">
          <template #activator="{ on }">
            <span class="text-ellipsis mr-1" style="display: inline-block; max-width: min(100%, 120px)" v-on="on">
              <a v-if="link.url" :href="link.url" :title="link.name" target="_blank" @click.stop>
                <link-icon :link="link"></link-icon>
                <span :style="{'vertical-align': link.icon ? 'middle' : ''}">{{ link.name || link.url }}</span>
              </a>
            </span>
          </template>
          <div class="white" style="max-width: 200px">
            <img v-if="link.url" :src="link.url" :alt="$t('PLANNING.LINK_IMAGE_UNAVAILABLE')" style="width: 100%;">
          </div>
        </v-menu>
        <span v-if="! isLast(link, elLinks)"></span>
      </span>
    </template>
  </div>
</template>

<script>
  import LinkIcon from '@/components/Planning/partials/LinkIcon';

  export default {
    components: {
      LinkIcon,
    },
    props: {
      el: { type: Object, required: true },
      isMiniElement: { type: Boolean, default: false },
    },
    computed: {
      elLinks() {
        return this.el.getLinks();
      },
    },
    methods: {
      isPicture(link) {
        if (link.mime && link.mime.includes('image')) return true;
        return /(http)s?:(\/\/[^"']*\.(?:png|jpg|jpeg|gif|png|svg)$)/i.test(link.url);
      },
      isLast(item, tab) {
        return item == tab[tab.length - 1];
      },
    },
  };
</script>
