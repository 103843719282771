import Vue from 'vue';
import PlanningElement from '@/models/PlanningElement';

/** ********** */
/*  MUTATIONS  */
/** ********** */
export function commitAdd(elements, newel) {
  elements.push(newel);
}

export function commitRemove(elements, el) {
  // INLI
  if (el && ! el.isDragPlaceholder && el.isFromTemplate()) return;
  // /INLI
  const index = elements.indexOf(el);
  if (index > -1) elements.splice(index, 1);
}

export function commitEmpty(elements) {
  elements.splice(0, elements.length);
}

/** ********** */
/*   GETTERS   */
/** ********** */
let idCounter = 1;
export function nextId(elements) {
  idCounter = Math.max(idCounter, Math.max(...elements.map(item => item.id), 0)) + 1;
  return idCounter;
}

export function getElement(elements) {
  return id => elements.find(el => el.id == id);
}

/** ******** */
/*  ACTIONS  */
/** ******** */
/** ******* ADD ******** */
export function insertNewElement(context, newel) {
  const { commit, rootState } = context;
  const el = new PlanningElement(rootState.planning, newel);
  commit('add', el);
  el.update();
  Vue.nextTick(() => el.updateHeight());
  return el;
}

function addElementHelper(context, { type = 'task', lane, el, positionLeft }) {
  // INLI
  if (el && el.isFromTemplate()) return null;
  // /INLI
  const { getters, rootState } = context;
  const { planning } = rootState;
  // Add new element from type or Duplicate from el
  if (! lane) return false;

  const id = getters.nextId;

  let newel = {};
  if (el) {
    // Duplicate
    newel = insertNewElement(context, el.getAll());
    newel.setDependencies(null);
    newel.id = id;
  } else {
    // Create new
    newel = insertNewElement(context, { type });
    newel.id = id;
    newel.setLaneId(lane.id);
    newel.setStartTime(moment(planning.visibleTimeline.starttime).addWithWorkdays(0.2 * moment.duration(1, planning.timeline.steps[0]).as('minutes'), 'minutes', planning.timeline.workdays));

    newel.setWidth(0);
    const width = (type == 'milestone') ? 70 : 130; // Default size
    newel.resize(width, null);
  }

  // INLI specifics when creating element
  const customFields = newel.getCustomFields() || {};
  customFields.msdyn_projecttaskid = window.crypto.randomUUID();
  (customFields.dyn_subtasks || []).forEach((subtask) => {
    subtask.msdyn_projecttaskid = window.crypto.randomUUID();
  });
  newel.setCustomFields(customFields);

  if (typeof positionLeft !== 'undefined') {
    newel.move(lane.id, positionLeft);
  }

  newel.update();
  return newel;
}

export async function addElements(context, { argstab, silent, extraProps }) {
  const { commit, getters, dispatch } = context;
  if (! silent) await dispatch('startChangingElement');
  const newels = [];

  (argstab || []).forEach((args) => {
    const newel = addElementHelper(context, args);
    if (! newel) return;
    if (extraProps) _.extend(newel, extraProps);
    newels.push(newel);
  });
  if (silent) return newels;

  const addedEls = newels.map(el => angular.copy(el));
  dispatch('undoredo/add', {
    action: () => {
      addedEls.forEach((el) => {
        insertNewElement(context, angular.copy(el.getAll()));
      });
    },
    rollback: () => {
      addedEls.forEach((el) => {
        commit('remove', getters.getElement(el.id));
      });
    },
  }, { root: true });
  dispatch('planning/save', null, { root: true });
  return newels;
}

/** ******* DELETE ******** */
export async function deleteElements(context, { els, silent }) {
  // INLI
  els = els.filter(el => el.isDragPlaceholder || ! el.isFromTemplate());
  if (! els.length) return;
  // /INLI
  const { commit, getters, dispatch } = context;
  if (! silent) await dispatch('startChangingElement');
  (els || []).forEach((el) => {
    commit('remove', el);
  });
  if (silent) return;
  const deletedEls = angular.copy(els) || [];
  dispatch('undoredo/add', {
    action: () => {
      deletedEls.forEach((el) => {
        commit('remove', getters.getElement(el.id));
      });
    },
    rollback: () => {
      deletedEls.forEach((el) => {
        insertNewElement(context, angular.copy(el.getAll()));
      });
    },
  }, { root: true });
  dispatch('planning/save', null, { root: true });
}
