<template>
  <v-autocomplete v-bind="$attrs" :value="value" :items="dataList" :item-text="getListItemLabel" :multiple="multiple" return-object clearable
                  @input="$emit('input', $event)">
    <template #selection="{ item }">
      <span class="nobr">
        <v-avatar size="25px">
          <img v-if="item.avatar" :src="item.avatar">
          <v-icon v-else small class="color-inherit">{{ item.icon || 'fas fa-user' }}</v-icon>
        </v-avatar>
        <span class="text-ellipsis pl-2" style="max-width: calc(100% - 25px)">{{ getListItemLabel(item) }}</span>
      </span>
    </template>
    <template #item="{ parent, item }">
      <v-list-item-avatar>
        <img v-if="item.avatar" :src="item.avatar">
        <v-icon v-else class="color-inherit">{{ item.icon || 'fas fa-user' }}</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-html="parent.genFilteredText(getListItemLabel(item))"></v-list-item-title>
      </v-list-item-content>
      <user-workload-indicator v-if="isBusiness && workloads && (item.id || item.username)" :user="item" :starttime="workloads.starttime" :endtime="workloads.endtime"></user-workload-indicator>
    </template>
  </v-autocomplete>
</template>

<script>
  import UserWorkloadIndicator from '@/components/Workload/UserWorkloadIndicator';

  export default {
    components: {
      UserWorkloadIndicator,
    },
    inheritAttrs: false,
    props: {
      value: { default: null },
      items: { type: Array, default: null },
      excludes: { type: Array, default: () => [] },
      workloads: { type: Object, default: null },
      withGroups: { type: Boolean, default: false },
      withTextUsers: { type: Boolean, default: false },
      multiple: { type: Boolean, default: false },
    },
    data() {
      return {
        selectedUser: null,
        newUserTypeahead: null,
      };
    },
    computed: {
      companyUsers() {
        if (this.items) return this.items;
        return this.$store.getters['users/getOrganizationUsers'];
      },
      dataList() {
        let dataList = this.companyUsers.filter(user => ! this.excludes.includes(user.id));
        if (this.withGroups && this.groups) {
          const groupsList = this.groups.map(item => ({
            group_id: item.id,
            title: item.title,
            icon: 'far fa-users',
          }));
          dataList = groupsList.concat(dataList);
        }
        if (this.isBusiness && this.withTextUsers) {
          const { textUsers } = this.$store.getters['users/accessRight/config'];
          if (textUsers) dataList = dataList.concat(textUsers.sort((a, b) => (a.username < b.username ? -1 : 1)));
        }
        return dataList;
      },
      groups() {
        return this.isBusiness && this.$store.getters['users/groups/getCompanyGroups'];
      },
      isBusiness() { return this.$store.state.users.accessRight.isBusiness; },
    },
    methods: {
      getListItemLabel(item) {
        if (item.group_id) return item.title;
        if (item.id) return this.$store.getters['users/getUsername'](item);
        return item.username;
      },
    },
  };
</script>
