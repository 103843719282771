<template>
  <div>
    <div style="position: sticky; top: 50px; z-index: 1">
      <v-tabs v-model="currentTabIndex" background-color="white">
        <v-tab href="#account-users">
          <span class="d-inline-flex" style="text-transform: none; font-size: 16px">
            <v-icon size="16" class="mr-1" style="color: inherit; transition: none; width: 24px">fas fa-list</v-icon>
            {{ $t('MANAGE.COMPANY_USERS') }}
          </span>
        </v-tab>
        <v-tab v-if="hasOrganization" href="#organization">
          <span class="d-inline-flex" style="text-transform: none; font-size: 16px">
            <v-icon size="16" class="mr-2" style="color: inherit; transition: none; width: 24px">fas fa-sitemap</v-icon>
            {{ $t('MANAGE.ORGANIZATION_USERS') }}
          </span>
        </v-tab>
        <v-tab href="#groups">
          <span class="d-inline-flex" style="text-transform: none; font-size: 16px">
            <v-icon size="16" class="mr-1" style="color: inherit; transition: none; width: 24px">fas fa-users</v-icon>
            {{ $t('MANAGE.ACCOUNT_GROUPS') }}
          </span>
        </v-tab>
        <v-tab v-for="user in openedUsers" :key="user.id" :href="`#user${user.id}`">
          <span style="text-transform: none;">{{ user.username || (user.firstname + ' ' + user.lastname) }}</span>
        </v-tab>
      </v-tabs>
      <v-divider class="mb-5"></v-divider>
    </div>

    <v-tabs-items v-model="currentTabIndex" class="mx-3" style="overflow: unset">
      <!-- TAB ACCOUNT USERS -->
      <v-tab-item :transition="false" :reverse-transition="false" value="account-users" eager>
        <manage-users-list :data-loaded="dataLoaded" :projects="projects"
                           :company-users="companyUsers" :opened-users="openedUsers"
                           @on-update-user-rights="onUpdateUserRights" @open-user-from-id="openUserFromId">
        </manage-users-list>
      </v-tab-item>

      <!-- TAB ORGANIZATION -->
      <v-tab-item v-if="hasOrganization" :transition="false" :reverse-transition="false" value="organization" eager>
        <manage-users-organization @open-user-from-id="openUserFromId"></manage-users-organization>
      </v-tab-item>

      <!-- TAB GROUPS -->
      <v-tab-item :transition="false" :reverse-transition="false" value="groups" eager>
        <v-alert v-if="! hasGroups" color="warningorange" type="warning" class="mt-4">
          {{ $t('PREMIUM.BUSINESS.SECTION_IS_BUSINESS') }} &nbsp;
        </v-alert>
        <manage-users-groups v-else ref="manageGroups" @open-user-from-id="openUserFromId"></manage-users-groups>
      </v-tab-item>

      <!-- TAB OPEN USERS -->
      <v-tab-item v-for="user in openedUsers" :key="user.id" :transition="false" :reverse-transition="false" :value="`user${user.id}`">
        <button class="close" style="position: absolute; top: -16px; right: 0; z-index: 1; transition: opacity .3s" @click="closeUser(user)"><i class="fas fa-2x fa-times"></i></button>
        <manage-users-user-acr ref="userTabs" :user="user"></manage-users-user-acr>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
  import ManageUsersList from './ManageUsersList';
  import ManageUsersOrganization from './ManageUsersOrganization';
  import ManageUsersGroups from './ManageUsersGroups';
  import ManageUsersUserAcr from './ManageUsersUserAcr';
  import ManageEventBus from './manage-event-bus';
  import manageMixin from './manageMixin';

  export default {
    components: {
      ManageUsersList,
      ManageUsersOrganization,
      ManageUsersGroups,
      ManageUsersUserAcr,
    },
    mixins: [manageMixin],
    props: {
      dataLoaded: { type: Object, default: () => {} },
      projects: { type: Array, default: () => [] },
      companyUsers: { type: Array, required: true },
      openedUsers: { type: Array, required: true },
      hasOrganization: { type: Boolean, required: false },
    },
    data() {
      return {
        currentTabIndex: null,
      };
    },
    computed: {
      userIsAdmin() { return this.$store.state.users.accessRight.isAdmin; },
      hasGroups() { return this.$store.state.users.accessRight.hasGroups; },
    },
    created() {
      ManageEventBus.$on('on-update-opened-group', () => {
        if (this.$refs.manageGroups) this.$refs.manageGroups.openedGroup = null;
      });
      ManageEventBus.$on('on-update-user-tabs', (userInfo) => {
        this.updateUserTabs(userInfo);
      });
      ManageEventBus.$on('remove-user', (user) => {
        ManageEventBus.$emit('on-remove-user', user);
      });
    },
    methods: {
      openUser(user) {
        if (! this.canIOpenUser(user)) return;
        $(window).scrollTop(0);
        if (this.$refs.manageGroups) this.$refs.manageGroups.openedGroup = null;
        const userIndex = this.openedUsers.indexOf(user);
        if (userIndex == -1) this.openedUsers.push(user);
        this.$nextTick(() => {
          this.currentTabIndex = `user${user.id}`;
        });
      },
      openUserFromId(userId) {
        const user = this.companyUsers.find(item => item.id == userId);
        if (user) this.openUser(user);
      },
      closeUser(user) {
        const userIndex = this.openedUsers.indexOf(user);
        if (userIndex != -1) this.openedUsers.splice(userIndex, 1);
        if (this.openedUsers[userIndex]) {
          this.currentTabIndex = `user${this.openedUsers[userIndex].id}`;
        } else if (this.openedUsers[userIndex - 1]) {
          this.currentTabIndex = `user${this.openedUsers[userIndex - 1].id}`;
        } else {
          this.currentTabIndex = `account-users`;
        }
      },
      onUpdateUserRights(userIndex) {
        const userTab = this.$refs.userTabs[userIndex];
        if (userTab.onUpdateUserRights) userTab.onUpdateUserRights();
      },
      updateUserTabs({ userId, projectId, data }) {
        const userIndex = this.openedUsers.findIndex(item => item.id == userId);
        if (userIndex == -1) return;
        const userTab = this.$refs.userTabs[userIndex];
        if (userTab.onUpdateProjectRightsForUser) userTab.onUpdateProjectRightsForUser({ userId, projectId, data });
      },
    },
  };
</script>
