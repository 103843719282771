<template>
  <v-autocomplete v-model="selectedProjectOwners"
                  :items="projectOwnersList"
                  :label="$t('MULTIPROJECTS.OWNERS')" :placeholder="selectedProjectOwners.length ? '' : $t('MULTIPROJECTS.NO_OWNER_SELECTED')"
                  item-text="username" item-value="id"
                  prepend-icon="fas fa-user" hide-details multiple menu-props="offsetY"
                  @change="update">
    <template #prepend-item>
      <filter-toggle :selected-ids="selectedProjectOwners" :sublist-ids="projectOwnerIds"
                     @update="selectedProjectOwners = $event; update()">
        {{ $t('MULTIPROJECTS.ALL_OWNERS') }} ({{ projectOwnerIds.length }})
      </filter-toggle>
      <v-divider></v-divider>
      <template v-if="organizationCompanies.length">
        <v-list-item @click="expandOrganizationCompanies = ! expandOrganizationCompanies">
          <v-list-item-action class="justify-center">
            <v-icon :color="expandOrganizationCompanies ? 'primary' : ''">
              {{ expandOrganizationCompanies ? 'fas fa-caret-down' : 'fas fa-caret-right' }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title :class="expandOrganizationCompanies ? 'primary--text' : ''">{{ $t('PROJECTS.ORGANIZATION') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <template v-if="expandOrganizationCompanies">
          <filter-toggle v-for="company in organizationCompanies" :key="company.id"
                         :selected-ids="selectedProjectOwners" :sublist-ids="projectOwnerIdsByOrganizationCompany[company.id]" indent
                         @update="selectedProjectOwners = $event; update()">
            {{ company.name }} ({{ projectOwnerIdsByOrganizationCompany[company.id].length }})
          </filter-toggle>
        </template>
        <v-divider></v-divider>
      </template>
    </template>
    <template #item="{ parent, item }">
      <v-list-item-avatar class="mr-3">
        <img v-if="item.avatar" :src="item.avatar">
        <v-icon v-else class="color-inherit">{{ item.icon || 'fas fa-user' }}</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-html="parent.genFilteredText(item.username)"></v-list-item-title>
      </v-list-item-content>
    </template>
    <template #selection="{ item, index }">
      <span v-if="selectedProjectOwners.length == 1">{{ item.username }}</span>
      <span v-else-if="selectedProjectOwners.length == projectOwnersCount && index === 0" class="mr-2">{{ $t('MULTIPROJECTS.ALL_OWNERS') }} ({{ projectOwnersCount }})</span>
      <span v-else-if="index === 0" class="mr-2">{{ selectedProjectOwners.length }}/{{ projectOwnersCount }}</span>
    </template>
  </v-autocomplete>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    props: {
      archivedProjects: { type: Array, default: () => [] },
    },
    data() {
      return {
        expandOrganizationCompanies: false,
      };
    },
    computed: {
      selectedProjectOwners: {
        get() {
          return this.portfolioOptions.selectedProjectOwners;
        },
        set(newVal) {
          const newValArray = newVal === "" ? this.selectedProjectOwners : newVal; // IE is sometimes receiving empty string for newVal. No idea why.
          this.portfolioOptions.selectedProjectOwners.splice(0, this.portfolioOptions.selectedProjectOwners.length, ...newValArray);
        },
      },
      projectOwners() {
        const projectOwners = {};
        this.projects.concat(this.portfolioOptions.displayArchived ? this.archivedProjects : []).forEach((project) => {
          const ownerId = project.meta ? project.meta.owner_id : project.owner_id;
          if (ownerId && ! projectOwners[ownerId]) {
            const owner = this.$store.getters['users/getUserById'](ownerId);
            if (! owner) return; // owner can be null in very specific case when project owner is outside organization (ie planningshares have not been correctly updated / deleted)
            projectOwners[ownerId] = {
              id: ownerId,
              username: this.$store.getters['users/getUsername'](owner),
              avatar: owner.avatar,
            };
          }
        });
        return Object.keys(projectOwners).map(ownerId => projectOwners[ownerId]).sort((a, b) => (a.username.toLowerCase() < b.username.toLowerCase() ? -1 : 1));
      },
      projectOwnerIds() {
        return this.projectOwners.map(item => item.id);
      },
      organizationCompanies() {
        const { organization } = this.$store.state.users.user;
        if (organization && organization.getCompanies().length > 1) return organization.getCompanies().filter(item => item.users.length);
        return [];
      },
      projectOwnerIdsByOrganizationCompany() {
        return this.organizationCompanies.reduce((acc, company) => {
          acc[company.id] = company.users.filter(item => this.projectOwnerIds.includes(item.id)).map(item => item.id);
          return acc;
        }, {});
      },
      projectOwnersList() {
        let projectOwnersList = this.projectOwners;
        if (this.organizationCompanies.length) {
          projectOwnersList = [];
          for (let i = 0; i < this.organizationCompanies.length; i++) {
            const company = this.organizationCompanies[i];
            const projectOwners = company.users.map(user => this.projectOwners.find(item => item.id == user.id)).filter(item => item);
            if (! projectOwners.length) continue;
            projectOwnersList.push({ header: company.name }, { divider: true }, ...projectOwners);
          }
        }
        return projectOwnersList;
      },
      projectOwnersCount() {
        return this.projectOwners.length;
      },
      ...mapState('multiprojects', ['projects', 'portfolioOptions']),
    },
    created() {
      if (! this.selectedProjectOwners.length) this.selectedProjectOwners = this.projectOwners.map(item => item.id);
    },
    methods: {
      update() {
        this.$emit('update');
      },
    },
  };
</script>
