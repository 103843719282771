export function deepcopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function isDomElement(el) {
  return el && el.nodeType === Node.ELEMENT_NODE;
}

export function innerHeight(el) { // padding, no border, no margin
  if (! isDomElement(el)) return 0;
  const style = window.getComputedStyle(el);
  return Math.round(parseInt(style.getPropertyValue("height"), 10) - parseInt(style.getPropertyValue("border-top-width"), 10) - parseInt(style.getPropertyValue("border-bottom-width"), 10));
}

export function outerHeight(el) { // padding, border, no margin
  if (! isDomElement(el)) return 0;
  const style = window.getComputedStyle(el);
  return Math.round(parseInt(style.getPropertyValue("height"), 10));
}

export function outerWidth(el) { // padding, border, no margin
  if (! isDomElement(el)) return 0;
  const style = window.getComputedStyle(el);
  return Math.round(parseInt(style.getPropertyValue("width"), 10));
}
