import constants from '@/js/constants.js';
import { innerHeight } from '@/components/Reusables/utils';
import { getDefaultTask, getDefaultMilestone, getDefaultMacro } from './helpers/defaultElements';
import helpers from './helpers/planningInitHelpers';

/** ************** */
/* ELEMENT MODEL */
/** ************** */
function defaultByType(type) {
  if (type == 'macro') return getDefaultMacro();
  return (type == 'milestone' ? getDefaultMilestone() : getDefaultTask());
}

class PlanningElement {
  constructor(planning, srcData) {
    let data = angular.copy(srcData) || {};

    if (data instanceof PlanningElement) {
      _.extend(this, data);
      data = this.data;
    } else {
      const defaultProps = defaultByType(data.type);
      _.extend(this, defaultProps);
      data = angular.merge({}, angular.copy(this.data), data);
      helpers.updateLastVersionsElements(data);
    }

    this.set(data);
    if (! this.id) this.id = 0;
    this.getPlanning = function () {
      return planning;
    };
  }

  getAll() {
    const nonNullData = {};
    Object.keys(this.data).forEach((key) => { if (this.data[key] !== null) nonNullData[key] = this.data[key]; });
    return { id: this.id, ...nonNullData };
  }

  set(elData) {
    const data = elData || {};
    const self = this;
    ['id', 'access_right'].forEach((attr) => {
      if (data[attr]) {
        self[attr] = data[attr];
        delete data[attr];
      }
    });
    Object.keys(data).forEach((key) => {
      self.data[key] = data[key];
    });
    return this;
  }

  reset(data) {
    delete data.id;
    const defaultProps = defaultByType(data.type || this.type);
    _.extend(this.data, { ...defaultProps.data, ...data });
    return this;
  }

  /** ********** */
  /* GET / SET */
  /** ********** */
  getType() {
    return this.data.type;
  }
  setType(value) {
    this.data.type = value;
    return this;
  }
  isType(...args) {
    return args.includes(this.getType());
  }

  getLaneId() {
    return this.data.lane_id;
  }
  setLaneId(value) {
    this.data.lane_id = value;
    return this;
  }

  getTitle() {
    return this.data.title;
  }
  setTitle(value) {
    this.data.title = value;
    return this;
  }

  getTitleStyle() {
    return this.data.titleStyle;
  }
  setTitleStyle(value) {
    this.data.titleStyle = value;
    return this;
  }

  getDescription() {
    return this.data.html;
  }
  setDescription(value) {
    this.data.html = value;
    return this;
  }

  getDescriptionStyle() {
    return this.data.descriptionStyle;
  }
  setDescriptionStyle(value) {
    this.data.descriptionStyle = value;
    return this;
  }

  getStartTime() {
    return moment(this.data.starttime);
  }
  setStartTime(value) {
    if (moment.isMoment(value)) value = value.format();
    this.data.starttime = value;
    if (this.isType('milestone')) this.data.endtime = value;
    return this;
  }

  getEndTime() {
    return moment(this.data.endtime);
  }
  setEndTime(value) {
    if (moment.isMoment(value)) value = value.format();
    this.data.endtime = value;
    if (this.isType('milestone')) this.data.starttime = value;
    return this;
  }

  getSchedule() {
    const schedule = this.data.schedule || { start: null, end: null };
    return {
      start: schedule.start && moment(schedule.start),
      end: schedule.end && moment(schedule.end),
    };
  }
  setSchedule(value) {
    value = angular.copy(value);
    if (value && value.start && moment.isMoment(value.start)) value.start = value.start.format();
    if (value && value.end && moment.isMoment(value.end)) value.end = value.end.format();
    if (! value.start && ! value.end) value = null;
    this.data.schedule = value;
    return this;
  }

  getWidth() {
    return this.data.width;
  }
  setWidth(value) {
    this.data.width = value;
    return this;
  }

  getPriority() {
    return this.data.priority;
  }
  setPriority(value) {
    this.data.priority = value;
    return this;
  }

  getConfig(field) {
    if (field) return this.data.config && this.data.config[field];
    return this.data.config;
  }
  setConfig(field, value) {
    // setConfig({all}) or setConfig(field, value)
    this.data.config = this.data.config || {};
    if (value !== undefined) {
      this.data.config[field] = value;
    } else {
      this.data.config = field;
    }
    return this;
  }

  getProgress() {
    return this.data.progress;
  }
  setProgress(value) {
    this.data.progress = value;
    return this;
  }

  getDependencies() {
    return this.data.dependencies;
  }
  setDependencies(value) {
    this.data.dependencies = value;
    return this;
  }

  getSubTasks() {
    return this.data.subTasks;
  }
  setSubTasks(value) {
    this.data.subTasks = value;
    return this;
  }

  getUsers() {
    return this.data.users;
  }
  setUsers(value) {
    this.data.users = value;
    return this;
  }

  getChecklist() {
    return this.data.checklist;
  }
  setChecklist(value) {
    this.data.checklist = value;
    return this;
  }

  getLinks() {
    return this.data.links;
  }
  setLinks(value) {
    this.data.links = value;
    return this;
  }

  getBudgets() {
    return this.data.budgets;
  }
  setBudgets(value) {
    this.data.budgets = value;
    return this;
  }

  getColorId() {
    return this.data.color;
  }
  setColorId(value) {
    this.data.color = (typeof value == 'number') ? value : 0;
    return this;
  }
  getColorShadeId() {
    return this.data.colorShade;
  }
  setColorShadeId(value) {
    this.data.colorShade = (typeof value == 'number') ? value : "";
    return this;
  }
  getIconId() {
    return this.data.icon;
  }
  setIconId(value) {
    this.data.icon = value;
    return this;
  }

  getIsLocked() {
    return this.data.isLocked;
  }
  setIsLocked(value) {
    this.data.isLocked = value;
    return this;
  }

  getTimeBar() {
    return this.data.timeBar || { color: '' };
  }
  setTimeBar(value) {
    this.data.timeBar = value;
    return this;
  }

  getMeetingId() {
    return this.data.meeting_id;
  }
  setMeetingId(value) {
    this.data.meeting_id = value;
    return this;
  }

  getCustomFields() {
    return this.data.customFields;
  }

  setCustomFields(value) {
    this.data.customFields = value;
    return this;
  }

  // Inli specifics
  isFromTemplate() {
    return !! (this.getCustomFields() || {}).fromTemplate;
  }

  /** ******** */
  /* DISPLAY */
  /** ******** */
  getMainColor() {
    const { colors } = this.getPlanning().config;
    return colors[this.data.color] && (colors[this.data.color].shades && colors[this.data.color].shades[this.data.colorShade] || colors[this.data.color].main) || '#0169aa';
  }

  getSecondaryColor() {
    const { colors } = this.getPlanning().config;
    return colors[this.data.color] && colors[this.data.color].secondary || 'rgba(216,216,253,.5)';
  }

  hasIcon() {
    return !! this.data.icon;
  }
  getIcon() {
    const { icons } = this.getPlanning().config;
    return icons[this.data.icon] || {};
  }

  getChecklistItemClass(item) {
    item = item || {};
    if (item.checked) return 'muted--text';
    const deadline = item.duedate ? moment(item.duedate) : this.getEndTime();
    if (deadline.isBefore()) return 'red';
    if (deadline.add(-1, 'week').isBefore()) return 'orange';
    return 'black--text';
  }

  updateXposition() {
    const { visibleTimeline } = this.getPlanning();
    let result = this.getStartTime().diffWithWorkdays(moment(visibleTimeline.starttime), 'minutes', visibleTimeline.workdays) / visibleTimeline.minuteperpx;
    if (this.isType('milestone')) {
      result -= this.getWidth() / 2;
    } else {
      result = Math.max(result, 0);
    }
    this.xposition = Math.round(result);
  }

  updateHeight() {
    if (! this.visible) { this.height = 0; return; }
    const height = innerHeight(document.getElementById(`el${this.id}`)); // with padding, not borders
    this.height = Math.round(height || this.height || 0);
  }

  updateWidth() {
    const { visibleTimeline } = this.getPlanning();
    if (this.isType('milestone')) {
      this.visible = !! ((moment(visibleTimeline.starttime).isBefore(this.getStartTime()) && moment(visibleTimeline.endtime).isAfter(this.getStartTime())));
    } else {
      const starttime = moment.max(this.getStartTime(), moment(visibleTimeline.starttime));
      const endtime = moment.min(this.getEndTime(), moment(visibleTimeline.endtime).add(-1 * visibleTimeline.minuteperpx, 'minutes'));
      const duration = endtime.diffWithWorkdays(starttime, 'minutes', visibleTimeline.workdays);
      const realduration = moment(this.getEndTime()).diffWithWorkdays(this.getStartTime(), 'minutes', visibleTimeline.workdays);
      const result = Math.max(constants.taskMinWidth, duration / visibleTimeline.minuteperpx); // min resize
      if (result <= constants.taskMinWidth && duration < realduration) { this.visible = false; } else { this.visible = true; } // hide small overlaps at the edges
      this.setWidth(Math.round(result));
    }
  }

  update() {
    this.updateXposition();
    this.updateWidth();
  }

  /** ******** */
  /* ACTIONS */
  /** ******** */
  move(laneId, positionLeft) {
    const { visibleTimeline } = this.getPlanning();
    this.setLaneId(laneId);
    // Update start time & end time
    if (this.isType('milestone')) positionLeft += this.getWidth() / 2;
    const elduration = this.getEndTime().diffWithWorkdays(this.getStartTime(), 'minutes', visibleTimeline.workdays);
    this.setStartTime(moment(visibleTimeline.starttime).addWithWorkdays(positionLeft * visibleTimeline.minuteperpx, 'minutes', visibleTimeline.workdays));
    this.setEndTime(this.getStartTime().addWithWorkdays(elduration, 'minutes', visibleTimeline.workdays));
    this.update(); // needed when xposition is programmaticaly set back to initial value
  }

  resize(elWidth, handle) {
    const { visibleTimeline } = this.getPlanning();
    handle = handle || 'e';
    if (this.isType('macro', 'task')) {
      if (handle == 'e') {
        elWidth = Math.min(elWidth, visibleTimeline.pxwidth - this.xposition);
        this.setEndTime(this.getStartTime().addWithWorkdays(elWidth * visibleTimeline.minuteperpx, 'minutes', visibleTimeline.workdays));
      }
      if (handle == 'w') {
        elWidth = Math.min(elWidth, this.xposition + this.getWidth());
        this.setStartTime(this.getEndTime().addWithWorkdays(-elWidth * visibleTimeline.minuteperpx, 'minutes', visibleTimeline.workdays));
      }
    }
    this.setWidth(elWidth);
    this.update(); // update xposition
  }

  updateChecklistProgress() {
    if (! this.isType('task')) return;
    let totalWeight = 0;
    let weight = 0;
    (this.getCustomFields()?.dyn_subtasks || []).forEach((item) => {
      const itemWeight = item.weight === 0 ? 0 : (item.weight || 1);
      if (item.checked) weight += itemWeight;
      totalWeight += itemWeight;
    });
    this.setProgress(totalWeight ? Math.round(100 * weight / totalWeight) : 0);
  }

  /** ***** */
  /* SAVE */
  /** ***** */
  save(props, viewId, action) {
    const el = this;
    let data = { id: el.id };
    if (props && props.length) {
      props.forEach((prop) => {
        data[prop] = el.data[prop];
      });
    } else {
      data = el.getAll();
    }
    const api = viewId ? (`views/${viewId}/elements`) : (`plannings/${el.getPlanning().id}/elements`);
    return window.apiSrv.call(api, action || 'update', data).then((response) => {
      const savedElement = response && response.data && response.data.element;
      if (savedElement) this.set(savedElement);
    });
  }
}

export default PlanningElement;
