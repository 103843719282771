var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dataCount)?_c('div',{class:_vm.isSmallWidth ? 'small-width' : null,staticStyle:{"display":"flex","flex-wrap":"wrap","align-items":"center","justify-content":"center","padding":"0"}},[(_vm.dataCount.description)?_c('div',[_c('v-menu',{attrs:{"disabled":! _vm.hasPopover,"close-on-content-click":false,"open-on-hover":"","auto":"","offset-y":"","z-index":"130","open-delay":"150","content-class":"synthesis-popover-container"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('div',{staticClass:"synthesis-item"},[_c('i',{staticClass:"fa fa-align-center fa-lg"})])])]}}],null,false,1462057854)},[_vm._v(" "),_c('div',{staticClass:"synthesis-popover"},[_c('span',{staticClass:"pre"},[_vm._v(_vm._s(_vm.description))])])])],1):_vm._e(),_vm._v(" "),(_vm.dataCount.customDescription)?_c('div',[_c('v-menu',{attrs:{"disabled":! _vm.hasPopover,"close-on-content-click":false,"open-on-hover":"","auto":"","offset-y":"","z-index":"130","open-delay":"150","content-class":"synthesis-popover-container"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('div',{staticClass:"synthesis-item"},[_c('i',{staticClass:"fa fa-align-center fa-lg"})])])]}}],null,false,1462057854)},[_vm._v(" "),_c('div',{staticClass:"synthesis-popover",domProps:{"innerHTML":_vm._s(_vm.customDescription)}})])],1):_vm._e(),_vm._v(" "),(_vm.dataCount.checklist)?_c('div',[_c('v-menu',{attrs:{"disabled":! _vm.hasPopover,"close-on-content-click":false,"open-on-hover":"","auto":"","offset-y":"","z-index":"130","open-delay":"150","content-class":"synthesis-popover-container"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('div',{staticClass:"synthesis-item"},[_vm._v(_vm._s(_vm.dataCount.checklist)+" "),_c('i',{staticClass:"fa fa-check-square"})])])]}}],null,false,37920613)},[_vm._v(" "),_c('div',{staticClass:"synthesis-popover"},_vm._l((_vm.checklist),function(item){return (! item.checked)?_c('div',[_c('i',{staticClass:"far fa-square",class:_vm.el.getChecklistItemClass(item)}),_vm._v(" "),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(item.title))])]):_vm._e()}),0)])],1):_vm._e(),_vm._v(" "),(_vm.dataCount.users)?_c('div',[_c('v-menu',{attrs:{"disabled":! _vm.hasPopover,"close-on-content-click":false,"open-on-hover":"","auto":"","offset-y":"","z-index":"130","open-delay":"150","content-class":"synthesis-popover-container"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('div',{staticClass:"synthesis-item"},[_vm._v(_vm._s(_vm.dataCount.users)+" "),_c('i',{staticClass:"fas fa-user",staticStyle:{"margin-left":"-1px"}})])])]}}],null,false,3746875058)},[_vm._v(" "),_c('div',{staticClass:"synthesis-popover"},_vm._l((_vm.users),function(user){return _c('div',{staticClass:"nobr"},[(user.avatar)?_c('img',{staticStyle:{"height":"18px","width":"18px","border-radius":"50%"},attrs:{"src":user.avatar}}):_c('span',{staticClass:"fa-fw",class:user.group_id ? 'far fa-users' : 'fas fa-user',staticStyle:{"font-size":"14px","vertical-align":"middle","top":"0"}}),_vm._v(" "),_c('span',{staticClass:"ml-1",staticStyle:{"vertical-align":"middle"}},[(user.group_id)?_c('span',[_vm._v(_vm._s(user.title))]):_c('span',[_vm._v(_vm._s(_vm._f("username")(user,'short')))]),_vm._v(" "),(user.percent)?_c('span',[_vm._v(" ("+_vm._s(user.percent)+"%)")]):_vm._e()])])}),0)])],1):_vm._e(),_vm._v(" "),(_vm.dataCount.attachments)?_c('div',[_c('v-menu',{attrs:{"disabled":! _vm.hasPopover,"close-on-content-click":false,"open-on-hover":"","auto":"","offset-y":"","z-index":"130","open-delay":"150","content-class":"synthesis-popover-container"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('div',{staticClass:"synthesis-item"},[_vm._v(_vm._s(_vm.dataCount.attachments)+" "),_c('i',{staticClass:"fa fa-paperclip fa-lg"})])])]}}],null,false,3091569056)},[_vm._v(" "),_c('div',{staticClass:"synthesis-popover"},_vm._l((_vm.attachments),function(link){return _c('div',{staticClass:"nobr text-ellipsis",staticStyle:{"max-width":"120px"}},[(link.url)?_c('a',{staticClass:"white--text",attrs:{"href":link.url,"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_c('link-icon',{attrs:{"link":link}}),_vm._v(" "),_c('span',{style:({'vertical-align': link.icon ? 'middle' : ''})},[_vm._v(_vm._s(link.name || link.url))])],1):_vm._e()])}),0)])],1):_vm._e(),_vm._v(" "),(_vm.dataCount.budgets)?_c('div',{staticClass:"display-flex"},[_c('v-menu',{attrs:{"disabled":! _vm.hasPopover,"close-on-content-click":false,"open-on-hover":"","auto":"","offset-y":"","z-index":"130","open-delay":"150","content-class":"synthesis-popover-container"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('div',{staticClass:"synthesis-item"},[_vm._v(_vm._s(_vm.dataCount.budgets)+" "),_c('i',{class:("budget-" + (_vm.budgets[0].icon || '') + " fa-lg")})])])]}}],null,false,1322329318)},[_vm._v(" "),_c('div',{staticClass:"synthesis-popover"},[_vm._l((_vm.elBudgetsByStates),function(elBudgets,state){return _vm._l((elBudgets),function(budget,icon){return _c('div',{staticClass:"nobr"},[_c('span',{class:("budget-" + (icon || ''))}),_vm._v(" "),(budget.amount_inprogress && budget.amount)?_c('span',[_vm._v(_vm._s(_vm._f("number")(budget.amount_inprogress))+" /")]):_vm._e(),_vm._v(" "),(budget.amount_inprogress && ! budget.amount)?_c('span',[_vm._v(_vm._s(_vm._f("number")(budget.amount_inprogress)))]):_vm._e(),_vm._v(" "),(budget.amount)?_c('span',[_vm._v(_vm._s(_vm._f("number")(budget.amount)))]):_vm._e(),_vm._v(" "),(_vm.budgetStates[state])?_c('span',[_vm._v("("+_vm._s(_vm._f("lowercase")(_vm.budgetStates[state]))+")")]):_vm._e()])})})],2)])],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }