<template>
  <div class="content"> <!-- .content is snap selector-->
    <div v-if="! planning.visibleTimeline && ! errors" style="text-align: center; padding: 15px; font-size: 18px;">
      <i class="fas fa-spinner fa-spin fa-2x fa-fw"></i>
    </div>
    <div v-if="errors" style="padding: 15px 20px;">
      {{ errors }}
    </div>
    <transition name="fade">
      <v-card v-show="planning.visibleTimeline" class="px-2 py-6 elevation-3" style="overflow: hidden">
        <div v-if="planning.visibleTimeline">
          <div class="customlogo">
            <img v-if="planning.meta.isOwnerPremium" :src="planning.config.logo || planning.meta.companylogo">
          </div>
          <!--    Planning Title-->
          <h3 v-if="canModifyPlanning" v-input-auto-width="{ text: planning.meta.title }" class="planning-title hovervisible-activator">
            <input v-model="planning.meta.title" v-contentautoselect :placeholder="$t('PLANNING.UNNAMED_PROJECT')"
                   class="input-underlined" style="width:100%; padding-right: 26px" spellcheck="false"
                   @focus="startChangingMeta" @blur="changingMeta">
            <i class="fas fa-pencil-alt text-muted hovervisible-item" style="position: absolute;top: 0;right: 0;transition: .3s;pointer-events: none;"></i>
          </h3>
          <h3 v-if="! canModifyPlanning" class="planning-title" style="width: auto">{{ planning.meta.title }}</h3>
          <client-custom-component v-if="customHeaderComponent" :name="customHeaderComponent" :planning="planning"></client-custom-component>
        </div>
        <div class="planning-body">
          <!--  LANE HEADERS COLUMN-->
          <planning-lane-headers :planning="planning" :dynamic="dynamic" :view="view" :dashboard="dashboard"></planning-lane-headers>
          <!--  END LANE HEADERS-->
          <planning-sub-lane-headers v-if="hasSubprojects" :planning="planning"></planning-sub-lane-headers>
          <!--  TABLE WRAPPER-->
          <div id="table-wrapper">
            <div v-if="planning.visibleTimeline" id="planning-table" :style="{ width: planning.visibleTimeline.pxwidth + 'px' }">
              <!--      TABLE HEADER-->
              <planning-timeline :visible-timeline="planning.visibleTimeline" :dynamic="dynamic && ! view"></planning-timeline>
              <div class="table-header-placeholder"></div>
              <!--      END TABLE HEADER-->
              <!--      TABLE BODY      -->
              <planning-pool ref="PlanningPool" :planning="planning" :dynamic="dynamic" :options="options" :events="events" :view="view">
              </planning-pool>
              <!--      END TABLE BODY-->
            </div>
          </div>
          <!--  END TABLE WRAPPER-->

          <div class="hide-scrollbar"></div>
        </div>
        <v-menu v-model="$store.state.ui.planning.newElementMenuLane"
                :position-x="$store.state.ui.planning.newElementMenuPosition.x" :position-y="$store.state.ui.planning.newElementMenuPosition.y" fixed
                content-class="new-element-menu">
          <v-list dense>
            <v-list-item @click.prevent="addElementFromMenu('task')">{{ $t('PLANNING.NEW_BUBBLE') }}</v-list-item>
            <v-list-item @click.prevent="addElementFromMenu('milestone')">{{ $t('PLANNING.NEW_MILESTONE') }}</v-list-item>
            <v-list-item v-if="isBusiness" @click.prevent="addElementFromMenu('macro')">{{ $t('PLANNING.NEW_MACRO_BUBBLE') }}</v-list-item>
          </v-list>
        </v-menu>
        <div class="clearfix"></div>
      </v-card>
    </transition>

    <!--EXCHANGE CENTER-->
    <planning-drawer v-if="canModifyPlanning" v-show="planning" :planning="planning"></planning-drawer>
    <planning-drawer-consult v-else-if="! view && ! dashboard" v-show="planning" :planning="planning"></planning-drawer-consult>

    <debug-zone v-if="! dashboard && planning.visibleTimeline" class="export-hidden" style="margin-top: 30px">
      <div>
        <select v-if="subprojects.length" v-model="debugscope.planning" style="-webkit-appearance: auto; border-style: dotted;">
          <option v-for="item in [null, ...subprojects]" :value="item">{{ (item || planning).getTitle() }}</option>
        </select>
        <p><span v-for="(val, label) in debugscope.tabs"><a href @click.prevent="debugscope.selectedtab = label">{{ label }}</a>&nbsp;&nbsp;&nbsp;</span></p>
        <pre v-for="(val, label) in debugscope.tabs" v-if="debugscope.selectedtab == label">{{ label }} : {{ (debugscope.planning || planning)[val] }}</pre>
      </div>
    </debug-zone>
  </div>
</template>

<style>
  .new-element-menu {
    z-index: 200 !important;
  }
</style>

<script>
  import clientConfig from '@/client_customs/config';
  import DebugZone from '@/components/Debug/DebugZone';
  import PlanningDrawer from '@/components/PlanningDrawer/PlanningDrawer';
  import PlanningDrawerConsult from '@/components/PlanningDrawer/PlanningDrawerConsult';
  import { mapActions, mapGetters } from 'vuex';
  import PlanningLaneHeaders from './PlanningLaneHeaders';
  import PlanningSubLaneHeaders from './PlanningSubLaneHeaders';
  import PlanningTimeline from './PlanningTimeline';
  import PlanningPool from './PlanningPool';

  export default {
    components: {
      DebugZone,
      PlanningDrawer,
      PlanningDrawerConsult,
      PlanningLaneHeaders,
      PlanningSubLaneHeaders,
      PlanningTimeline,
      PlanningPool,
    },
    props: {
      planning: { type: Object, required: true },
      dynamic: { type: Boolean, default: false },
      view: { type: Boolean, default: false },
      dashboard: { type: Boolean, default: false },
      options: { type: Object, default: undefined },
      events: { type: Object, default: undefined },
    },
    data() {
      return {
        $wrapper: null,
        debugscope: {
          planning: null,
          selectedtab: 'Elements',
          tabs: {
            Lanes: 'lanes', Timeline: 'timeline', visibleTimeline: 'visibleTimeline', Elements: 'elements', Config: 'config', Meta: 'meta', ProjectSheet: 'projectsheet',
          },
        },
      };
    },
    computed: {
      canModifyPlanning() {
        return this.dynamic && ! this.view;
      },
      errors() {
        return this.$store.state.ui.planning.errors;
      },
      customHeaderComponent() {
        return clientConfig.planningContent && clientConfig.planningContent.customHeaderComponent;
      },
      subprojects() {
        return this.$store.getters['subprojects/getAll'];
      },
      isBusiness() { return this.$store.state.users.accessRight.isBusiness; },
      ...mapGetters('planning', ['hasSubprojects']),
    },
    created() {
      this.$store.commit('ui/planning/reset', { view: this.view, dynamic: this.dynamic });
      this.$store.dispatch('ui/planning/resetSaving');
      this.$store.dispatch('undoredo/reset');
      this.$store.commit('subprojects/reset');
      this.$store.dispatch('selection/resetSelection');
    },
    mounted() {
      this.$wrapper = $("#table-wrapper");
      this.$wrapper.on('scroll', _.debounce(() => {
        this.$nextTick(() => this.$store.commit('ui/planning/updateWrapperData', this.$wrapper));
      }, 300));
    },
    methods: {
      addElementFromMenu(type) {
        const menuOffset = $(`.new-element-menu`).offset();
        this.addElement({ type, x: menuOffset.left, y: menuOffset.top, laneId: this.$store.state.ui.planning.newElementMenuLane?.id });
      },
      ...mapActions('planning/meta', ['startChangingMeta', 'changingMeta']),
      ...mapActions('ui/planning', ['addElement']),
    },
  };
</script>
