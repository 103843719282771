<template>
  <div v-if="userIsProjectAdmin || category" :class="{ 'export-hidden': ! category }" class="mt-2 display-flex align-start">
    <v-icon :title="$tc('PROJECTS.CATEGORY', 2)" small class="mr-1 mt-2" style="margin-left: 2px">fas fa-tags</v-icon>
    <!-- Edit Category -->
    <div v-if="categoryEditing" class="display-flex flex-grow-1">
      <project-category-edit v-model="category" :project-categories="projectCategories"></project-category-edit>
      <v-btn color="accent" rounded small class="mx-2" @click="saveCategory">{{ $t('GLOBAL.SAVE') }}</v-btn>
      <v-btn color="secondary" rounded small outlined style="min-width: 0" @click="resetCategory"><v-icon small>fas fa-times</v-icon></v-btn>
    </div>
    <!-- Category List -->
    <template v-else-if="category">
      <project-category :category="planning.meta.category" style="max-width: 300px; display: flex; flex-wrap: wrap"></project-category>
      <v-btn v-if="userIsProjectAdmin" icon small class="ml-1 export-hidden" @click="categoryEditing = ! categoryEditing">
        <v-icon color="secondary" size="14">fas fa-edit</v-icon>
      </v-btn>
    </template>
    <!-- No category -->
    <template v-else>
      <v-btn color="secondary" small rounded outlined class="my-1" @click="categoryEditing = true">
        <v-icon size="14" class="mr-2">fas fa-plus-circle</v-icon>{{ $tc('PROJECTS.CATEGORY') }}
      </v-btn>
    </template>
  </div>
</template>

<script>
  import ProjectCategory from '@/components/Projects/ProjectCategory';
  import ProjectCategoryEdit from '@/components/Projects/ProjectCategoryEdit';

  export default {
    components: {
      ProjectCategory,
      ProjectCategoryEdit,
    },
    props: {
      planning: { type: Object, required: true },
    },
    data() {
      return {
        category: this.planning.meta?.category,
        categoryEditing: false,
        projectCategories: [],
      };
    },
    computed: {
      userIsProjectAdmin() {
        return this.planning.meta.access_right == 'admin';
      },
    },
    watch: {
      categoryEditing(newVal) {
        if (! newVal) return;
        window.apiSrv.call('plannings/categories', 'index').then((response) => {
          this.projectCategories = response.data && response.data.categories || [];
        });
      },
    },
    methods: {
      saveCategory() {
        setTimeout(() => {
          window.apiSrv.call(`plannings`, 'update', { id: this.planning.id, category: this.category }).then(() => {
            this.planning.meta.category = this.category;
          }).catch((message) => {
            this.$store.dispatch('ui/msgbox/open', { title: "Error : Category was not modified", body: message || "" });
            this.category = this.planning.meta?.category;
          });
          this.categoryEditing = false;
        });
      },
      resetCategory() {
        this.category = this.planning.meta?.category;
        this.categoryEditing = false;
      },
    },
  };
</script>
