export default {
  data() {
    return {
      laneHeaderHeight: 0,
    };
  },
  computed: {
    laneContentHeight() {
      this.laneElements.map(el => el.height); // reactive to el height
      this.lane.contentHeight = this.$store.getters['planning/lanes/getLaneContentHeight'](this.lane.id);
      return this.lane.contentHeight;
    },
    laneHeight() {
      if (! this.laneHeaderHeight) this.updateLaneHeaderHeight();
      this.lane.height = Math.max(this.laneContentHeight, this.laneHeaderHeight);
      return this.lane.height;
    },
    laneHeaderHeightReactiveProperties() {
      return { label: this.lane.label, monitoringplan: this.lane.monitoringplan, hidden: this.lane.hidden };
    },
  },
  watch: {
    laneHeaderHeightReactiveProperties: {
      handler() { this.updateLaneHeaderHeight(); },
      deep: true,
    },
  },
  methods: {
    updateLaneHeaderHeight() {
      this.$nextTick(() => {
        this.laneHeaderHeight = this.$store.getters['planning/lanes/getLaneHeaderHeight'](this.lane.id);
      });
    },
  },
};
