import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    monitoringPlanByPlanningsByLanes: {
      // planningId: {
      //   laneId: laneMonitoringPlan
      // }
    },
  },
  getters: {
  },
  mutations: {
    setMonitoringLane(state, { lane, monitoringLane }) {
      const laneId = lane.id;
      const planningId = lane.getPlanning().id;
      if (! state.monitoringPlanByPlanningsByLanes[planningId]) Vue.set(state.monitoringPlanByPlanningsByLanes, planningId, {});
      Vue.set(state.monitoringPlanByPlanningsByLanes[planningId], laneId, monitoringLane);
    },
  },
  actions: {
    loadMonitoringPlan({ state }, { planning }) {
      window.apiSrv.call('dashboards/monitoringplan', 'show', planning.id, true).then((response) => {
        const monitoringPlanning = response && response.data && response.data.planning || {};
        (planning.lanes || []).forEach((lane) => {
          if (! state.monitoringPlanByPlanningsByLanes[planning.id]) Vue.set(state.monitoringPlanByPlanningsByLanes, planning.id, {});
          const laneMonitoringPlan = (monitoringPlanning.monitoring_lanes || []).find(item => item.id == lane.id);
          if (laneMonitoringPlan) Vue.set(state.monitoringPlanByPlanningsByLanes[planning.id], lane.id, laneMonitoringPlan);
        });
      }).catch((error) => {
        console.log("Error loading monitoringplan : ", error);
      });
    },
  },
};
