<template>
  <div>
    <span class="small text-muted">{{ $t('PLANNINGDRAWER.FIND_HERE_NOTIFICATIONS') }}</span>
    <v-tooltip bottom max-width="300">
      <template #activator="{ on }">
        <v-switch v-model="receiveNotifications" :disabled="receiveNotificationsIsDisabled" hide-details class="mt-0" v-on="on"
                  @change="! receiveNotificationsIsDisabled && saveReceiveNotifications()">
          <small slot="label">{{ $t('PLANNINGDRAWER.ACTIVATE_NOTIFICATIONS') }}</small>
        </v-switch>
      </template>
      {{ $t('PLANNINGDRAWER.ACTIVATE_NOTIFICATIONS_DESC') }}
    </v-tooltip>

    <div v-for="notif in planningNotifications" class="mt-4">
      <i :style="{ visibility: notif.seen_at ? 'hidden' : '' }" class="fa fa-fw fa-circle green" style="font-size: 9px"></i>
      <span :title="notif.user && notif.user.email || ''" class="nobr">
        <img v-if="notif.user && notif.user.avatar" :src="notif.user.avatar" style="max-height: 20px;border-radius: 50%;margin: -1px 0;">
        <span v-else class="fas fa-user fa-fw" style="font-size: 14px; vertical-align: middle; top:0"></span>
      </span>
      &nbsp;<a v-if="notifHasTarget(notif)" href="" @click.prevent="openNotifTarget(notif)">{{ notif.message }}</a>
      <span v-if="! notifHasTarget(notif)">{{ notif.message }}</span> &nbsp;<span class="small text-muted">{{ notif.date_of_creation | moment('calendar') }}</span>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      planning: { type: Object, required: true },
    },
    data() {
      return {
        receiveNotificationsIsDisabled: false,
      };
    },
    computed: {
      notifications() {
        return this.$store.state.notifications.all;
      },
      planningNotifications() {
        return this.notifications.filter(notif => notif.section_type == 'planning' && notif.section_id == this.planning.id)
          .sort((a, b) => (a.date_of_creation < b.date_of_creation ? 1 : -1));
      },
      receiveNotifications: {
        get() {
          return this.planning.meta.notifications;
        },
        set(newVal) {
          this.planning.meta.notifications = newVal;
        },
      },
    },
    methods: {
      notifHasTarget(notif) {
        if (notif.section_type == 'planning' && notif.section_id == this.planning.id) {
          if (notif.target_type == 'element' && notif.target_id) {
            const el = this.planning.elements.find(item => item.id == notif.target_id);
            return el && true;
          }
          if (notif.target_type == 'exchangeCenter') return true;
        }
        return false;
      },
      openNotifTarget(notif) {
        if (notif.section_type == 'planning' && notif.section_id == this.planning.id && notif.target_type == 'element' && notif.target_id) {
          const el = this.planning.elements.find(item => item.id == notif.target_id);
          if (el) this.$emit('open-element', el);
        } else if (notif.section_type == 'planning' && notif.section_id == this.planning.id && notif.target_type == 'exchangeCenter') {
          this.$emit('open-tab', 'exchanges');
        }
      },
      saveReceiveNotifications() {
        this.receiveNotificationsIsDisabled = true;
        window.apiSrv.call(`sharedplannings/planning/${this.planning.id}/user`, 'update', { id: this.$store.state.users.user.id, notifications: this.receiveNotifications }).then(() => {
          this.receiveNotificationsIsDisabled = false;
        }).catch((message) => {
          this.receiveNotifications = ! this.receiveNotifications;
          this.receiveNotificationsIsDisabled = false;
          if (message) this.$store.dispatch('ui/msgbox/open', { title: "Error", body: message });
        });
      },
    },
  };
</script>
