<template>
  <div style="pointer-events: none; top: -8px" class="timeline-grid-container">
    <!-- Time Grid -->
    <template v-if="showTimeGrid">
      <div class="timeline-grid-container">
        <div v-for="col in visibleTimeline.timelinecols" :style="{ width: col.width + 'px' }"
             class="timeline-grid-block" style="border-color: rgba(51, 51, 51, .25)">
        </div>
      </div>
      <div class="timeline-grid-container">
        <div v-for="subcol in visibleTimeline.subtimelinecols" :style="{ width: subcol.width + 'px' }"
             class="timeline-grid-block">
        </div>
      </div>
    </template>
    <!-- Time Bar -->
    <div v-for="timeBarBlock in planningElementsTimeBarBlocks" :class="{ 'timebar-block-task': timeBarBlock.isMacroOrTask }"
         :style="{ left: timeBarBlock.xposition, width: timeBarBlock.width, 'border-color': timeBarBlock.color }"
         class="timebar-block">
      <div v-if="timeBarBlock.width" :style="{ background : timeBarBlock.color }"></div>
    </div>
  </div>
</template>

<style>
  .timeline-grid-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0px;
    right: 0;
  }

  .timeline-grid-block {
    display: inline-block;
    height: 100%;
    border-right: 1px solid rgba(51, 51, 51, .1);
  }

  .timebar-block {
    position: absolute;
    top: 0;
    bottom: 0;
    border-right: 2px solid;
    margin-left: -1.25px;
  }

  .timebar-block.timebar-block-task {
    border-left: 1px solid;
    border-right: 1px solid;
    margin-left: -1px;
  }

  .timebar-block.timebar-block-task > div {
    width: calc(100% + 2px);
    height: 100%;
    opacity: .45;
    margin-left: -1px;
  }
</style>

<script>
  export default {
    props: {
      planning: { type: Object, required: true },
    },

    computed: {
      visibleTimeline() {
        return this.planning.visibleTimeline;
      },
      showTimeGrid() {
        return this.visibleTimeline.show_timegrid;
      },
      planningElementsTimeBarBlocks() {
        const timeBars = [];
        this.planning.elements.forEach((el) => {
          const { color } = el.getTimeBar();
          if (! color) return;
          const isMacroOrTask = el.isType('macro', 'task');
          const width = isMacroOrTask ? `${el.getWidth() + 1}px` : null;
          const xposition = `${el.xposition + (! isMacroOrTask ? (el.getWidth() / 2 - 0.25) : 0)}px`;
          timeBars.push({ color, xposition, width, isMacroOrTask });
        });
        return timeBars;
      },
    },
  };
</script>
