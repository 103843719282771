<template>
  <v-autocomplete v-model="selectedProjects"
                  :items="projects" :item-text="(project) => project.meta && project.meta.title || $t('PLANNING.UNNAMED_PROJECT')"
                  :label="$t('MULTIPROJECTS.PROJECTS')" :placeholder="selectedProjects.length ? '' : $t('MULTIPROJECTS.NO_PROJECT_SELECTED')" item-value="id"
                  prepend-icon="fas fa-folder-open" hide-details multiple menu-props="offsetY"
                  @change="update">
    <template #prepend-item>
      <filter-toggle :selected-ids="selectedProjects" :sublist-ids="projectIds"
                     @update="selectedProjects = $event; update()">
        {{ $t('MULTIPROJECTS.ALL_PROJECTS') }} ({{ projectIds.length }})
      </filter-toggle>
      <v-divider></v-divider>
      <template v-if="projectCategories.length">
        <v-list-item @click="expandProjectCategories = ! expandProjectCategories">
          <v-list-item-action class="justify-center">
            <v-icon :color="expandProjectCategories ? 'primary' : ''">
              {{ expandProjectCategories ? 'fas fa-caret-down' : 'fas fa-caret-right' }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title :class="expandProjectCategories ? 'primary--text' : ''">{{ $tc('PROJECTS.CATEGORY', 2) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <template v-if="expandProjectCategories">
          <filter-toggle v-for="category in projectCategories" :key="category"
                         :selected-ids="selectedProjects" :sublist-ids="projectIdsByCategory[category]" indent dense
                         @update="selectedProjects = $event; update()">
            <project-category :category="category" pointer></project-category> <span style="vertical-align: middle">({{ projectIdsByCategory[category].length }})</span>
          </filter-toggle>
        </template>
        <v-divider></v-divider>
      </template>
    </template>
    <template #selection="{ item, index }">
      <span v-if="selectedProjects.length == 1" class="mr-2">{{ item.meta && item.meta.title || $t('PLANNING.UNNAMED_PROJECT') }}</span>
      <span v-else-if="selectedProjects.length == projects.length && index === 0" class="mr-2">{{ $t('MULTIPROJECTS.ALL_PROJECTS') }} ({{ projects.length }})</span>
      <span v-else-if="index === 0" class="mr-2">{{ selectedProjects.length }}/{{ projects.length }}</span>
    </template>
  </v-autocomplete>
</template>

<script>
  import { mapState } from 'vuex';
  import ProjectCategory from '@/components/Projects/ProjectCategory';

  export default {
    components: {
      ProjectCategory,
    },
    data() {
      return {
        expandProjectCategories: false,
      };
    },
    computed: {
      selectedProjects: {
        get() {
          return this.$store.state.multiprojects.selectedProjects;
        },
        set(newVal) {
          const newValArray = newVal === "" ? this.selectedProjects : newVal; // IE is sometimes receiving empty string for newVal. No idea why.
          this.$store.state.multiprojects.selectedProjects.splice(0, this.$store.state.multiprojects.selectedProjects.length, ...newValArray);
        },
      },
      projectIds() {
        return this.projects.map(item => item.id).filter(item => item);
      },
      projectIdsByCategory() {
        return this.projects.reduce((acc, project) => {
          if (project.meta && project.meta.category) {
            project.meta.category.split(',').forEach((tag) => {
              if (! acc[tag]) acc[tag] = [];
              acc[tag].push(project.id);
            });
          }
          return acc;
        }, {});
      },
      projectCategories() {
        return Object.keys(this.projectIdsByCategory);
      },
      ...mapState('multiprojects', ['projects']),
    },
    methods: {
      update() {
        this.$emit('update');
      },
    },
  };
</script>
