<template>
  <div v-if="loading" class="display-flex align-center">
    <v-icon :size="small ? 12 : 24">fas fa-spinner fa-spin</v-icon>
  </div>
  <div v-else-if="userIndicators && ! (hideZero && ! userIndicators.average)" class="display-flex align-center" style="position: relative">
    <v-icon :color="userIndicators.averageBg" :size="small ? 12 : 24">fas fa-circle</v-icon>
    <v-menu :close-on-content-click="false" open-on-hover tile :right="! bottom" :offset-x="! bottom" :bottom="bottom" :offset-y="bottom">
      <template #activator="{ on }">
        <div class="white--text display-flex align-center justify-center ma-n1"
             style="position: absolute; top: 0px; left: 0; bottom: 0; right: 0; font-size: 12px"
             v-on="on">
          <span v-if="! small">{{ userIndicators.average }}</span>
        </div>
      </template>
      <v-card class="px-4 py-2">
        <div class="text-center">{{ $t('MODULE_HR.AVERAGE_WORKLOAD') }} :  {{ userIndicators.average }} %</div>
        <div class="mt-4 mb-1 mx-1" style="height: 30px; width: 200px; position: relative; background: rgba(0, 0, 0, .2)">
          <div v-for="(workload, workloadIndex) in userIndicators.workloadsData"
               :title="workloadBarTitle(workload)"
               :class="workload.bg" :style="{ left: `${workload.xposition}px`, width: `${workload.width}px` }"
               class="white--text display-flex align-center justify-center nobr" style="height: 100%; position: absolute; opacity: .7; cursor: default">
            <span v-if="workload.width >= 30">{{ workload.percent }}%</span>
            <div v-if="! workloadIndex || (userIndicators.workloadsData[workloadIndex - 1].xposition + 30 <= workload.xposition)"
                 class="black--text workload-indicator-date-label">
              {{ workload.starttime | moment('mediumDateNoYear') }}
            </div>
            <div v-if="workloadIndex == userIndicators.workloadsData.length - 1"
                 class="black--text workload-indicator-date-label" style="left: 100%">
              {{ workload.endtime | moment('mediumDateNoYear') }}
            </div>
          </div>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>

<style>
  .workload-indicator-date-label {
    position: absolute;
    top: -12px;
    left: 0;
    font-size: 9px;
    font-weight: bold;
    transform: translateX(-50%);
    white-space: nowrap;
  }
</style>

<script>
  export default {
    props: {
      user: { type: Object, default: null },
      starttime: { type: [Object, String], default: null },
      endtime: { type: [Object, String], default: null },
      small: { type: Boolean, default: false },
      bottom: { type: Boolean, default: false },
      hideZero: { type: Boolean, default: false },
    },
    computed: {
      start() {
        return this.starttime ? moment.max(this.starttime, moment()) : moment();
      },
      end() {
        return this.endtime ? moment(this.endtime) : moment().add(1, 'month');
      },
      userIndicators() {
        if (! this.user) return null;
        const { start, end } = this;
        if (end.isBefore(start)) return null;
        if (end.isSame(start)) end.add(1, 'minute');
        const userWorkloads = this.$store.getters['workloads/getUserWorkloads'](this.user.id || `vp${this.user.username}`).filter((workload) => {
          return workload.endtime.isAfter(start) && workload.starttime.isBefore(end);
        });
        if (! userWorkloads.length) userWorkloads.push({ starttime: start, endtime: end, daily_workload: 0 });

        const mergedWorkloads = this.$store.getters['workloads/mergeWorkloads'](userWorkloads).filter((workload) => {
          // filter again needed because merging can create new workloads outside of boundaries
          return workload.endtime.isAfter(start) && workload.starttime.isBefore(end);
        });
        if (mergedWorkloads[0].starttime.isSameOrBefore(start)) { // add start and end boundaries
          mergedWorkloads[0].starttime = start;
        } else {
          mergedWorkloads.unshift({ starttime: start, endtime: mergedWorkloads[0].starttime, daily_workload: 0 });
        }
        if (mergedWorkloads[mergedWorkloads.length - 1].endtime.isSameOrAfter(end)) {
          mergedWorkloads[mergedWorkloads.length - 1].endtime = end;
        } else {
          mergedWorkloads.push({ starttime: mergedWorkloads[mergedWorkloads.length - 1].endtime, endtime: end, daily_workload: 0 });
        }
        let totalLoad = 0;
        let days = 0;
        mergedWorkloads.forEach((workload) => {
          const duration = moment(workload.endtime).toNextWorkday().diffWithWorkdays(workload.starttime, 'minutes', true) / (60 * 24);
          totalLoad += duration * workload.daily_workload;
          days += duration;
        });
        const average = Math.round(100 * totalLoad / days);
        const averageBg = this.$store.getters['workloads/getPercentColorClass'](average);

        return {
          average,
          averageBg,
          workloadsData: mergedWorkloads.map((workload) => {
            const minuteperpx = end.diffWithWorkdays(start, 'minutes', true) / 200;
            const percent = Math.round(100 * workload.daily_workload);
            const bg = this.$store.getters['workloads/getPercentColorClass'](percent);

            const xposition = Math.round(Math.max(0, moment(workload.starttime).diffWithWorkdays(moment(start), 'minutes', true) / minuteperpx));
            const starttime = moment.max(workload.starttime, moment(start));
            const endtime = moment.min(workload.endtime, moment(end));
            const duration = endtime.diffWithWorkdays(starttime, 'minutes', true);
            const width = Math.round(Math.max(0, duration / minuteperpx));

            return {
              bg,
              percent,
              xposition,
              width,
              starttime,
              endtime,
            };
          }),
        };
      },
      loading() {
        return this.$store.state.workloads.loading;
      },
    },
    created() {
      if (! this.$store.getters['workloads/isLoaded'] && ! this.loading) {
        this.$store.dispatch('workloads/load');
      }
    },
    methods: {
      workloadBarTitle(workload) {
        const momentFilter = this.$options.filters.moment;
        return `${momentFilter(workload.starttime, 'mediumDateNoYear')} - ${momentFilter(workload.endtime, 'mediumDateNoYear')}\n${workload.percent} %`;
      },
    },
  };
</script>
