<template>
  <div>
    <div class="display-flex align-center hovervisible-activator">
      <div class="hovervisible-item checklist-draggable-anchor grab mr-2"></div>
      <v-checkbox v-model="item.checked" :ripple="false" hide-details class="pa-0 ma-0" color="successgreen" tabindex="-1" @change="updateChecklist"></v-checkbox>
      <v-text-field v-model="item.title" hide-details class="pa-0 ma-0" style="flex: 1 1 100%"></v-text-field>
      <span v-show="item.duedate" :class="checklistItemClass" class="small nobr mx-2 hovervisible-activator-2" style="position: relative">
        {{ item.duedate | moment('mediumDateNoYear') }}
        <i :title="$t('ELEMENT-DETAIL.REMOVE_DUE_DATE') + ' : ' + $options.filters.moment(item.duedate, 'date')"
           class="hovervisible-item-2 fa fa-times fa-lg fa-fw pointer" style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); color: red;"
           @click="item.duedate = null">
        </i>
      </span>
      <v-menu ref="itemDueDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y left min-width="290px">
        <template #activator="{ on: onMenu }">
          <v-tooltip bottom>
            <template #activator="{ on: onTooltip }">
              <v-btn v-show="! item.duedate" icon small outlined color="accent" tabindex="-1" v-on="{ ...onMenu, ...onTooltip }">
                <v-icon small>far fa-calendar-plus</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('ELEMENT-DETAIL.ADD_DUE_DATE') }}</span>
          </v-tooltip>
        </template>
        <v-date-picker v-model="item.duedate" :locale="locale" :picker-date.sync="duedatePickerFirstDate" first-day-of-week="1" color="accent" no-title scrollable
                       @input="$refs.itemDueDateMenu.isActive = false">
        </v-date-picker>
      </v-menu>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn icon small outlined color="errorred" class="hovervisible-item ml-2" v-on="on" @click="removeChecklistItem">
            <v-icon small>far fa-trash-alt</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('ELEMENT-DETAIL.DELETE_CHECKLIST_ITEM') }}</span>
      </v-tooltip>
    </div>
    <transition name="collapse-top">
      <div v-show="advancedChecklist && isPremium" class="ml-6 pl-4 display-flex align-center">
        <div class="ml-2" style="max-width: 145px">
          <div v-if="item.user_id" class="display-flex align-center mt-1">
            <user-workload-indicator v-if="isBusiness" :user="user" :starttime="starttime" :endtime="endtime"
                                     small bottom hide-zero class="mr-1">
            </user-workload-indicator>
            <div class="display-flex align-center text-ellipsis hovervisible-activator" style="position: relative;">
              <template v-if="user">
                <img v-if="user.avatar" :src="user.avatar" style="max-height: 20px; border-radius: 50%;">
                <div class="ml-1">{{ user | username('short') }}</div>
              </template>
              <template v-else>
                <i :title="$tc('MULTIPROJECTS.DELETED_USER', 1)" class="fas fa-user-slash fa-fw" style="font-size: 14px"></i>
                <span v-if="item.username" class="ml-1">{{ item.username }}</span>
              </template>
              <i :title="$t('ELEMENT-DETAIL.REMOVE_RESPONSIBLE')"
                 class="hovervisible-item fa fa-times fa-lg fa-fw pointer" style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); color: red;"
                 @click="item.user_id = null; item.username = null">
              </i>
            </div>
          </div>
          <div v-else-if="item.group_id" class="display-flex align-center mt-1">
            <div class="display-flex align-center text-ellipsis hovervisible-activator" style="position: relative;">
              <template v-if="group">
                <i class="far fa-users fa-fw" style="font-size: 14px"></i>
                <span class="ml-1">{{ group.title }}</span>
              </template>
              <template v-else>
                <i :title="$t('MULTIPROJECTS.DELETED_GROUP')" class="far fa-users-slash fa-fw" style="font-size: 14px"></i>
                <span class="ml-1">{{ item.username }}</span>
              </template>
              <i :title="$t('ELEMENT-DETAIL.REMOVE_RESPONSIBLE')"
                 class="hovervisible-item fa fa-times fa-lg fa-fw pointer" style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); color: red;"
                 @click="item.group_id = null; item.username = null;">
              </i>
            </div>
          </div>
          <div v-else-if="item.username" class="display-flex align-center mt-1">
            <user-workload-indicator v-if="isBusiness" :user="{ username: item.username }" :starttime="starttime" :endtime="endtime"
                                     small bottom hide-zero class="mr-1">
            </user-workload-indicator>
            <div class="display-flex align-center text-ellipsis hovervisible-activator" style="position: relative;">
              <span class="fas fa-user fa-fw" style="font-size: 14px"></span>
              <div class="ml-1">{{ item.username }}</div>
              <i :title="$t('ELEMENT-DETAIL.REMOVE_RESPONSIBLE')"
                 class="hovervisible-item fa fa-times fa-lg fa-fw pointer" style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); color: red;"
                 @click="item.username = null">
              </i>
            </div>
          </div>
          <template v-else>
            <select-user :placeholder="$t('ELEMENT-DETAIL.RESPONSIBLE')" :workloads="{ starttime, endtime }"
                         with-groups with-text-users hide-details class="ma-0 pa-0" style="width: 145px"
                         @input="$emit('add-responsible', $event)">
            </select-user>
          </template>
        </div>
        <div class="ml-2 display-flex align-baseline">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <i class="fas fa-weight-hanging mt-1 ml-2 mr-1" v-on="on"></i>
            </template>
            <span class="pre">{{ workloadInHours ? $t('ELEMENT-DETAIL.WORKLOAD_HOURS_INFORMATION') : $t('ELEMENT-DETAIL.WORKLOAD_INFORMATION') }}</span>
          </v-tooltip>
          <v-text-field :value="itemWorkload"
                        :placeholder="$t('ELEMENT-DETAIL.WORKLOAD_ESTIMATED')" :title="$t('ELEMENT-DETAIL.WORKLOAD_ESTIMATED')"
                        hide-details type="number" step="any" min="0" class="pa-0 ma-0 small-placeholder"
                        @change="itemWorkload = $event">
          </v-text-field>
          <span class="mx-1"><i class="fa fa-caret-right"></i></span>
          <v-text-field :value="itemWorkloadUsed"
                        :placeholder="$t('ELEMENT-DETAIL.WORKLOAD_USED')" :title="$t('ELEMENT-DETAIL.WORKLOAD_USED')"
                        hide-details type="number" step="any" min="0" class="pa-0 ma-0 small-placeholder"
                        @change="itemWorkloadUsed = $event">
          </v-text-field>
        </div>
        <div class="display-flex align-baseline" style="flex: 0 0 60px">
          <v-tooltip bottom max-width="150">
            <template #activator="{ on }">
              <i class="fas fa-chart-pie mt-1 ml-2 mr-1" v-on="on"></i>
            </template>
            <span>{{ $t('ELEMENT-DETAIL.WEIGHTS') }}</span>
          </v-tooltip>
          <v-tooltip right>
            <template #activator="{ on }">
              <v-text-field v-model.number="item.weight" placeholder="1" hide-details type="number"
                            step="any" min="0" class="pa-0 ma-0" v-on="on" @change="updateChecklist">
              </v-text-field>
            </template>
            <span>{{ itemWeight(item.weight) }}%</span>
          </v-tooltip>
        </div>
      </div>
    </transition>
    <hr v-show="advancedChecklist && isPremium" class="ml-12 mt-4 mb-1" style="border-top: 1px solid #ccc">
  </div>
</template>

<style>
  .checklist-draggable-anchor {
    background: #ccc;
    background: url(../../img/bg-anchor.png) 1px 0, #ccc;
    flex: 0 0 8px;
    height: 28px;
  }

  .small-placeholder ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-size: .75em;
  }
  .small-placeholder ::-moz-placeholder { /* Firefox 19+ */
    font-size: .75em;
  }
  .small-placeholder :-ms-input-placeholder { /* IE 10+ */
    font-size: .75em;
  }
  .small-placeholder :-moz-placeholder { /* Firefox 18- */
    font-size: .75em;
  }
</style>

<script>
  import workloadMixin from '@/components/Workload/workloadMixin';
  import UserWorkloadIndicator from '@/components/Workload/UserWorkloadIndicator';

  export default {
    components: {
      UserWorkloadIndicator,
    },
    mixins: [workloadMixin],
    props: {
      el: { type: Object, required: true },
      checklist: { type: Array, default: () => [] },
      item: { type: Object, required: true },
      advancedChecklist: { type: Boolean, default: false },
    },
    data() {
      return {
        duedatePickerFirstDate: this.el.getStartTime().format('yyyy-MM-DD'),
      };
    },
    computed: {
      locale() {
        return this.$store.state.lang.lang;
      },
      user() {
        const user = this.$store.getters['users/getUserById'](this.item.user_id);
        if (! user) return null;
        this.$set(this.item, 'username', this.$store.getters['users/getUsername'](user, 'short')); // in case of username update
        return user;
      },
      group() {
        const group = this.$store.getters['users/groups/getGroupById'](this.item.group_id);
        if (! group) return null;
        this.$set(this.item, 'username', group.title); // in case of title update
        return group;
      },
      checklistItemClass() {
        return this.el.getChecklistItemClass(this.item);
      },
      starttime() {
        return this.el.getStartTime();
      },
      endtime() {
        return this.item.duedate || this.el.getEndTime();
      },
      itemWorkload: {
        get() {
          return this.workloadToDisplay(this.item.workload);
        },
        set(newVal) {
          const val = typeof newVal == 'string' ? newVal.replace(',', '.') : newVal;
          this.item.workload = this.workloadToStore(parseFloat(val) || null);
        },
      },
      itemWorkloadUsed: {
        get() {
          return this.workloadToDisplay(this.item.workload_used);
        },
        set(newVal) {
          const val = typeof newVal == 'string' ? newVal.replace(',', '.') : newVal;
          this.item.workload_used = this.workloadToStore(parseFloat(val) || null);
        },
      },
      workloadInHours() {
        return this.$store.getters['users/accessRight/config'].workloadInHours;
      },
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
      isBusiness() { return this.$store.state.users.accessRight.isBusiness; },
    },
    methods: {
      itemWeight(weightParam) {
        const weight = weightParam === 0 ? 0 : (weightParam || 1);
        return Math.round(100 * weight / this.checklist.reduce((a, b) => a + (b.weight === 0 ? 0 : (b.weight || 1)), 0));
      },
      removeChecklistItem() {
        this.$emit('remove-checklist-item');
      },
      updateChecklist() {
        this.$emit('update-checklist');
      },
    },
  };
</script>
