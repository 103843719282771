<template>
  <div class="project-preview">
    <div class="project-preview-thumbnailbox">
      <img v-if="thumbnail" :src="thumbnailUrl" :style="{ minWidth: `${thumbnailPercent * 5 + 2}%` }" class="project-preview-thumbnail" @error="thumbnailUrl = defaultBg">
      <project-category :category="project.category" class="project-preview-category" :style="{ width: `${thumbnailPercent}%` }"></project-category>
    </div>
    <slot name="body">
      <div :style="{ marginLeft: `${thumbnailPercent}%` }" class="project-preview-textbox">
        <div class="project-preview-title"><strong>{{ project.title || $t('PLANNING.UNNAMED_PROJECT') }}</strong></div>
        <div style="margin-top: 8px">
          <slot name="body-details"></slot>
        </div>
        <div class="project-preview-bottombar">
          <slot name="bottom"></slot>
        </div>
      </div>
    </slot>
    <slot></slot>
  </div>
</template>

<style>
  .project-preview {
    border-radius: 4px 12px 12px 12px;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    transition: box-shadow .25s;
    position: relative;
    height: 100%;
    overflow: hidden;
    background: white;
  }

  .project-preview-thumbnailbox {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-bottom-left-radius: 10px;
    overflow: hidden;
  }

  .project-preview-thumbnail {
    transform: scale(0.2);
    transform-origin: 0 0;
    min-height: 500%;
    filter: brightness(90%);
  }

  .project-preview-category {
    position: absolute;
    bottom: 1px;
    text-align: center;
    background: rgba(238, 238, 238,.5);
  }

  .project-preview-textbox {
    position: relative;
    color: #333;
    padding: 8px;
    height: 100%;
    transition: margin-left .5s;
    background: rgba(238, 238, 238, 0.95);
  }

  .project-preview-title {
    padding-right: 30px;
    transition: all .5s;
  }

  .project-preview-bottombar {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 2px 8px;
    width: 100%;
    color: #333;
  }
</style>

<script>
  import ProjectCategory from './ProjectCategory';

  const defaultBg = require('@/img/view_bg.jpg');

  export default {
    components: {
      ProjectCategory,
    },
    props: {
      project: { type: Object, required: true },
      thumbnail: { type: String, default: '' },
      thumbnailPercent: { type: Number, default: 50 },
    },
    data() {
      return {
        thumbnailUrl: this.thumbnail,
        defaultBg,
      };
    },
    watch: {
      thumbnail() {
        this.thumbnailUrl = this.thumbnail;
      },
    },
  };
</script>
