<template>
  <v-list>
    <v-list-item v-if="! el.isFromTemplate()">
      <v-btn small color="error" rounded outlined block @click="close('delete')">
        <v-icon small class="mr-2">far fa-trash-alt</v-icon>{{ $t('ELEMENT-DETAIL.DELETE_ELEMENT') }}
      </v-btn>
    </v-list-item>
    <template v-if="options.canChangeLane">
      <v-divider></v-divider>
      <v-tooltip left>
        <template #activator="{ on }">
          <v-list-item v-on="on">
            <div class="display-flex align-center">
              <div class="mr-2 text-center" style="flex: 0 0 24px;">
                <v-icon color="primary" small>fas fa-th-list</v-icon>
              </div>
              <v-select v-model="elLaneId" :items="lanes" item-text="label" item-value="id" hide-details class="pt-0 mt-0"></v-select>
            </div>
          </v-list-item>
        </template>
        {{ $t('PLANNING.LANE') }}
      </v-tooltip>
    </template>
    <template v-if="options.canSetPriority">
      <v-divider></v-divider>
      <v-tooltip left>
        <template #activator="{ on }">
          <v-list-item v-on="on">
            <div class="display-flex align-center flex-grow-1">
              <div class="mr-2 text-center" style="flex: 0 0 24px;">
                <v-icon color="primary" small>fas fa-sliders-v</v-icon>
              </div>
              {{ $t('ELEMENT-DETAIL.PRIORITY') }}
              <div class="ml-auto text-center" style="min-width: 23px">
                <v-btn icon x-small><v-icon @click="elPriority = (elPriority || 0) + 1">fas fa-caret-up</v-icon></v-btn>
                <div style="margin: -11px 0 -9px"><small>{{ elPriority || 'auto' }}</small></div>
                <v-btn icon x-small><v-icon @click="elPriority = (elPriority || 0) - 1">fas fa-caret-down</v-icon></v-btn>
              </div>
            </div>
          </v-list-item>
        </template>
        <span class="pre">{{ $t('ELEMENT-DETAIL.PRIORITY_ON_PLANNING') }}</span>
      </v-tooltip>
    </template>
  </v-list>
</template>

<script>
  export default {
    props: {
      el: { type: Object, required: true },
      options: { type: Object, default: () => ({}) },
    },
    data() {
      return {
        planning: this.el.getPlanning(),
      };
    },
    computed: {
      lanes() {
        return this.planning.lanes.filter(lane => ! lane.project_id);
      },
      elLaneId: {
        get() {
          return this.el.getLaneId();
        },
        set(newVal) {
          this.el.setLaneId(newVal);
        },
      },
      elPriority: {
        get() {
          return this.el.getPriority();
        },
        set(newVal) {
          this.el.setPriority(newVal);
        },
      },
    },
    methods: {
      close(reason) {
        this.$emit('close', reason);
      },
    },
  };
</script>
