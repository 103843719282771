<template>
  <vue-upload-component ref="upload" v-model="files" :drop="true" :post-action="uploadFileUrl" :headers="tokenHeaders"
                        :multiple="true" :thread="5" :size="20 * 1024 * 1024" :input-id="inputId"
                        style="width: 100%" @input-file="inputFile">
    <div :class="mouseOverDropbox && $refs.upload && $refs.upload.dropActive ? 'on-dragover' : ''" class="filedropbox"
         @dragenter="mouseOverDropbox = true" @dragleave="mouseOverDropbox = false">
      <div style="pointer-events: none;">
        <div style="padding: 0 12px">
          <div v-for="file in files" :key="file.id" style="display: flex">
            <i :class="file.type | mimeIcon" style="margin-top: 3px" class="fa-fw"></i>
            <span class="ml-2">{{ file.name }}</span>
            <div :type="file.error ? 'danger' : 'success'" :value="file.progress || 0" class="ml-2 progress" style="flex-grow: 1; min-width: 150px" animate="true">
              <div :class="file.error ? 'progress-bar-danger' : 'progress-bar-success'"
                   :style="{width: file.error ? '100%' : (file.progress < 100 ? file.progress : 100) + '%'}"
                   class="progress-bar" role="progressbar">
                {{ errorMessage(file) || (Math.round(file.progress * 1) + ' %') }}
              </div>
            </div>
          </div>
        </div>
        <h3 style="margin:0"><i class="fa fa-plus fa-fw"></i><span>&nbsp; {{ $t('PROJECT_SHEET.ADD_FILE') }}</span></h3>
      </div>
      <i v-show="files.length" class="fa fa-lg fa-trash" style="position: absolute; bottom: 20px; right: 10px; opacity: .6" @click.stop.prevent="$refs.upload.clear()"></i>
    </div>
  </vue-upload-component>
</template>

<style scoped>
  .filedropbox {
    background: #F8F8F8;
    border: 5px dashed #DDD;
    text-align: center;
    padding: 20px 0;
    margin-bottom: 10px;
    cursor: pointer;
  }
  .on-dragover {
    border-color: #0169aa;
  }
</style>

<script>
  import VueUploadComponent from 'vue-upload-component';

  export default {
    components: {
      VueUploadComponent,
    },
    props: {
      inputId: { type: String, default: null },
    },
    data() {
      return {
        files: [],
        mouseOverDropbox: false,
        uploadFileUrl: `${window.apiSrv.host}v1/files`,
        tokenHeaders: window.apiSrv.getTokenHeaders(),
      };
    },
    methods: {
      inputFile(newFile, oldFile) {
        // Automatic upload
        if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
          if (newFile) this.$refs.upload.update(newFile, { name: newFile.name.replace(/'/g, "’") });
          if (! this.$refs.upload.active) this.$refs.upload.active = true;
        }
        // File upload is success
        if (newFile && newFile.success && (! oldFile || ! oldFile.success)) {
          this.$emit('uploaded', newFile);
          this.$refs.upload.remove(newFile);
        }
      },
      errorMessage(file) {
        if (! file.error) return "";
        if (file.error === 'size') return this.$t('EXCHANGECENTER.FILE_SIZE_LIMITED');
        if (file.response) return file.response.message || file.response.status;
        return file.error;
      },
    },
  };
</script>
