<template>
  <form role="form" @submit.prevent>
    <v-row>
      <v-col md="6" cols="12" class="mb-6">
        <v-row no-gutters>
          <v-col class="display-flex" cols="12">
            <div class="text-center mt-6" style="width: 32px; flex: 0 0 auto !important;">
              <v-icon color="primary" small>fas fa-pencil-alt</v-icon>
            </div>
            <div class="flex-grow-1">
              <v-text-field v-model="projectTitle" :label="$t('NEWPROJECT.PROJECT_NAME')" :placeholder="$t('NEWPROJECT.ENTER_PROJECT_NAME')"
                            :hide-details="!! titleTags" autofocus autocomplete="nope">
              </v-text-field>
              <div v-if="titleTags" class="display-flex">
                <v-select v-for="(titleTagsOptions, index) in titleTags" :key="index" v-model="titleTagsValues[index]" :items="titleTagsOptions" class="mr-2">
                </v-select>
              </div>
            </div>
          </v-col>
          <v-col class="display-flex" cols="12" md="6">
            <div class="text-center mt-6" style="width: 32px; flex: 0 0 auto !important;">
              <v-icon small color="primary">fas fa-calendar-alt</v-icon>
            </div>
            <date-field v-model="startdate" :label="$t('TIMELINE.START_DATE')" format="dddd DD MMMM YYYY" readonly no-null nudge-details></date-field>
          </v-col>
          <v-col class="display-flex" cols="12" md="6">
            <div style="width: 32px; flex: 0 0 auto !important;"></div>
            <date-field v-model="enddate" :label="$t('TIMELINE.HORIZON_DATE')" :disabled="template.id && ! template.scale_dates ? true : false"
                        format="dddd DD MMMM YYYY" readonly no-null nudge-details>
            </date-field>
          </v-col>
          <v-col class="display-flex" cols="12" md="6">
            <div class="text-center mt-6" style="width: 32px; flex: 0 0 auto !important;">
              <v-icon small color="primary">fas fa-arrows-alt-h</v-icon>
            </div>
            <v-select v-model="timeline" :items="availableTimelineOptions" :label="$t('TIMELINE.TIMELINE')"></v-select>
          </v-col>
          <v-col class="display-flex" cols="12" md="6">
            <div style="width: 32px; flex: 0 0 auto !important;"></div>
            <v-select v-model="subtimeline" :items="availableSubtimelineOptions" :label="$t('TIMELINE.SUB_TIMELINE')"></v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="6" cols="12">
        <v-expansion-panels v-model="showAdvancedOptions" class="elevation-0 px-9">
          <v-expansion-panel>
            <v-expansion-panel-header>{{ $t('NEWPROJECT.ADVANCED_OPTIONS') }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row no-gutters>
                <v-col class="display-flex" cols="12">
                  <div class="text-center mt-6" style="width: 32px; flex: 0 0 auto !important;">
                    <v-icon color="primary" small>fas fa-user</v-icon>
                  </div>
                  <select-user v-model="owner" :items="companyNonConsultUsers" :disabled="! userIsAdmin"
                               :label="$t('NEWPROJECT.OWNER')" :placeholder="owner ? '' : $t('NEWPROJECT.CHOOSE_OWNER')">
                  </select-user>
                </v-col>
                <v-col class="display-flex" cols="12">
                  <div class="text-center mt-6" style="width: 32px; flex: 0 0 auto !important;">
                    <v-icon color="primary" small>fas fa-tags</v-icon>
                  </div>
                  <!-- Project Category Edit -->
                  <project-category-edit v-model="category" :project-categories="projectCategories" class="pt-3 mb-4"></project-category-edit>
                </v-col>
                <v-col class="display-flex" cols="12">
                  <div class="text-center mt-6" style="width: 32px; flex: 0 0 auto !important;">
                    <v-icon color="primary" small>fas fa-lock</v-icon>
                  </div>
                  <v-select v-model="privacy" :items="privacyOptions" :label="$t('NEWPROJECT.PRIVACY')">
                  </v-select>
                </v-col>
                <v-col class="display-flex" cols="12">
                  <div class="text-center mt-6" style="width: 32px; flex: 0 0 auto !important;">
                    <v-icon color="primary" small>fas fa-copy</v-icon>
                  </div>
                  <div class="flex-grow-1">
                    <v-select id="inputTemplate" v-model="template.id" :items="templateOptions" :label="$t('NEWPROJECT.TEMPLATE')">
                    </v-select>
                    <transition name="collapse-top">
                      <v-autocomplete v-show="template.id == 'user_project'" ref="inputTemplateText" v-model="template.user_project"
                                      :label="$t('NEWPROJECT.TEMPLATE_PROJECT')" :placeholder="$t('NEWPROJECT.SEARCH_PROJECT_BY_NAME')"
                                      :items="projectsAvailableAsTemplate" item-text="title" return-object dense>
                      </v-autocomplete>
                    </transition>
                    <transition name="collapse-top">
                      <v-checkbox v-show="template.id" v-model="template.scale_dates" :label="$t('NEWPROJECT.AUTOADAPT_TO_HORIZON_DATE')" color="primary" hide-details
                                  class="mt-0">
                      </v-checkbox>
                    </transition>
                  </div>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <div class="text-center mt-6">
      <v-btn :disabled="createProjectDisabled" rounded color="accent" large style="min-width: 300px;" @click="createProject()">
        {{ $t('NEWPROJECT.CREATE_PROJECT') }}
      </v-btn>
    </div>
  </form>
</template>

<script>
  import Planning from '@/models/Planning';
  import ProjectSrv from '@/components/Projects/ProjectSrv';
  import clientConfig from '@/client_customs/config';
  import ProjectCategoryEdit from '@/components/Projects/ProjectCategoryEdit';

  const clientConfigProjects = clientConfig.projects || {};

  export default {
    components: {
      ProjectCategoryEdit,
    },
    props: {
      projects: { type: Array, default: () => [] },
    },
    data() {
      return {
        projectTitle: "",
        titleTags: clientConfigProjects.titleTags,
        titleTagsValues: clientConfigProjects.titleTags ? clientConfigProjects.titleTags.map(item => item[0]) : null,
        startdate: moment().startOf('month'),
        enddate: moment().add(8, 'months').endOf('month'),
        timeline: 'quarters',
        subtimeline: 'months',
        showAdvancedOptions: clientConfigProjects.showAdvancedOptions ? 0 : null,
        category: '',
        privacy: clientConfigProjects.defaultPrivacy || 'company',
        template: { id: '', title: '', scale_dates: clientConfigProjects.templateScaleDates !== false },
        projectTemplates: null,
        createProjectDisabled: false,
        owner: null,
        timelineOptions: [
          { value: 'years', text: this.$t('TIMELINE.YEARS') },
          { value: 'quarters', text: this.$t('TIMELINE.QUARTERS') },
          { value: 'months', text: this.$t('TIMELINE.MONTHS') },
          { value: 'weeks', text: this.$t('TIMELINE.WEEKS') },
          { value: 'days', text: this.$t('TIMELINE.DAYS') },
          { value: 'none', text: this.$t('TIMELINE.NONE') },
        ],
        privacyOptions: [
          { value: 'company', text: `${this.$options.filters.ucfirst(this.$t('NEWPROJECT.PRIVACY_COMPANY'))} (${this.$t('NEWPROJECT.DEFAULT')})` },
          { value: 'company visible', text: this.$options.filters.ucfirst(this.$t('NEWPROJECT.PRIVACY_COMPANY_VISIBLE')) },
          { value: 'private', text: this.$options.filters.ucfirst(this.$t('NEWPROJECT.PRIVACY_PRIVATE')) },
        ],
      };
    },
    computed: {
      subcompaniesUsers() {
        if (this.$store.state.users.user.organization) {
          const subcompanies = this.$store.getters['users/getOrganizationSubCompanies'];
          return subcompanies.map(company => company.users).reduce((acc, val) => acc.concat(val), []); // reduce(...) could be replaced by flat() with ie polyfill
        }
        return this.$store.getters['users/getCompanyUsers'];
      },
      companyNonConsultUsers() {
        return this.subcompaniesUsers.filter(user => user.access_right != 'consult');
      },
      availableTimelineOptions() {
        return this.timelineOptions.filter(item => item.value != 'none');
      },
      availableSubtimelineOptions() {
        return this.timelineOptions.slice(this.timelineOptions.findIndex(item => item.value == this.timeline) + 1);
      },
      projectCategories() {
        return this.projects.map(project => project.category);
      },
      projectStatusConfig() {
        return this.$store.getters['users/accessRight/config'].projectStatus || {};
      },
      projectStatusKeysSet() {
        const projectStatusKeys = [...Object.keys(this.projectStatusConfig), ''];
        return new Set(projectStatusKeys);
      },
      projectsTemplatesConfig() {
        const templates = [];
        this.projects.forEach((project) => {
          const status = this.projectStatusKeysSet.has(project.status) ? project.status : '';
          if (! status) return;
          if (! this.projectStatusConfig[status].template) return;
          templates.push(project);
        });
        return templates;
      },
      templateOptions() {
        const options = [{ value: '', text: this.$t('NEWPROJECT.NONE') }, { value: 'user_project', text: this.$t('NEWPROJECT.FROM_EXISTING_PROJECT') }];
        if (this.projectsTemplatesConfig.length) { // user project template
          this.projectsTemplatesConfig.forEach((projectTemplate) => {
            options.push({ value: `user_project${projectTemplate.id}`, text: `Template : ${projectTemplate.title}` });
          });
        } else if (this.projectTemplates) {
          this.projectTemplates.forEach((projectTemplate) => {
            options.push({ value: projectTemplate.id, text: projectTemplate.title });
          });
        }
        return options;
      },
      projectsAvailableAsTemplate() {
        const projects = clientConfigProjects.filterTemplateName ? this.projects.filter(clientConfigProjects.filterTemplateName) : this.projects.slice();
        return projects.sort((a, b) => (a.title < b.title ? -1 : 1));
      },
      userIsAdmin() { return this.$store.state.users.accessRight.isAdmin; },
    },
    watch: {
      startdate(newValue) {
        if (! newValue) return;
        if (moment(this.enddate).isBefore(newValue)) this.enddate = moment(newValue).add(1, this.timeline);
      },
      enddate(newValue) {
        if (! newValue) return;
        if (newValue.isBefore(this.startdate)) this.startdate = moment(newValue).add(-1, this.timeline);
      },
      timeline() {
        this.subtimeline = this.availableSubtimelineOptions[0].value;
      },
      'template.id': function (templateId) {
        if (templateId == 'user_project') setTimeout(() => { this.$refs.inputTemplateText.focus(); }, 500);
      },
    },
    created() {
      this.$store.state.users.userPromise.then(() => {
        this.owner = this.$store.state.users.user;
      });

      ProjectSrv.listTemplates().then((response) => {
        this.projectTemplates = response.data;
      });
    },
    methods: {
      createProject() {
        this.createProjectDisabled = true;
        const startdate = moment(this.startdate).startOf('day');
        const enddate = moment(this.enddate).endOf('day');
        const steps = [this.timeline];
        if (this.subtimeline !== 'none') steps.push(this.subtimeline);

        const projectContentPromise = new Promise((resolvePromise, rejectPromise) => {
          if (this.template.id.startsWith('user_project')) { // user project template
            const templateId = this.template.id.replace('user_project', ''); // originalId
            if (templateId) {
              this.template.user_project = this.projectsTemplatesConfig.find(item => item.id == parseInt(templateId, 10));
            }
            if (typeof this.template.user_project != 'object') {
              rejectPromise(new Error('Invalid template selected.'));
            } else {
              ProjectSrv.get(this.template.user_project.id).then((content) => {
                resolvePromise({
                  title: this.projectTitle || this.template.user_project.title,
                  content: ProjectSrv.projectFromTemplate({ template: content, startdate, enddate, steps, scaleDates: this.template.scale_dates }),
                });
              }).catch(() => {
                rejectPromise(new Error('Unable to load template project.'));
              });
            }
          } else if (this.template.id) { // example template
            ProjectSrv.getTemplate(this.template.id).then((content) => {
              const templateProject = this.projectTemplates.filter(item => item.id == this.template.id)[0];
              resolvePromise({
                title: this.projectTitle || templateProject && templateProject.title,
                content: ProjectSrv.projectFromTemplate({ template: content, startdate, enddate, steps, scaleDates: this.template.scale_dates }),
              });
            }).catch(() => {
              rejectPromise(new Error('Unable to load template.'));
            });
          } else { // no template
            const content = {
              timeline: {
                hidden: {
                  before: startdate.startOf(steps.last()).format(),
                  after: enddate.endOf(steps.last()).format(),
                },
                steps,
              },
              lanes: [{
                label: this.$t('CONSTANTS.NEW_LANE'),
              }],
            };

            let timelocale = window.navigator.language || "";
            if (timelocale.toLowerCase().substr(0, 2) == "fr" && timelocale.toLowerCase() != "fr-ca") timelocale = "fr";
            if (timelocale) content.timeline.timelocale = timelocale;

            resolvePromise({
              title: this.projectTitle || this.$t('NEWPROJECT.MY_PROJECT'),
              content,
            });
          }
        });

        projectContentPromise.then((data) => {
          if (! data.content) return Promise.reject(new Error('No content found.'));

          const newPlanning = new Planning(data.content);
          newPlanning.timeline.setColWidth(newPlanning.timeline.bestColWidth());
          data.content = newPlanning.toProject().content;

          if (this.owner && this.owner.id) data.owner_id = this.owner.id;
          data.category = this.category || '';
          data.privacy = this.privacy;
          if (this.titleTags) data.title += ` ${this.titleTagsValues.map(item => `[${item}]`).join('')}`;

          return ProjectSrv.createNew(data).then((response) => {
            const id = response.data && response.data.planning && response.data.planning.id || 0;
            if (id) this.$router.push(`/planning/${id}/${window.slugify(data.title || "New project")}`);
            return response;
          }).catch(message => Promise.reject(message || "Unable to create project."));
        }).catch((error) => {
          this.$store.dispatch('ui/msgbox/open', { title: "GLOBAL.ERROR", body: `${error}` });
          this.createProjectDisabled = false;
        });
      },
      lowercase(str) {
        return str.toLowerCase();
      },
    },
  };
</script>
