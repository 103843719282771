<template>
  <v-app-bar v-if="! mobileview" id="topbar" app color="white" dense light style="z-index: 1000; border-bottom: 5px solid #0169aa !important; overflow-x: auto;">
    <router-link to="/home">
      <img :title="$t('NAV.HOME')" src="../../img/bubble-plan-logo-color.png" class="hidden-md-and-down mr-4" style="height: 42px;">
      <img :title="$t('NAV.HOME')" src="../../img/bubble-plan-logo-color.png" class="hidden-lg-and-up mr-1" style="height: 36px;">
    </router-link>

    <!--    SECTIONS NAVIGATION-->
    <v-toolbar-items class="mr-2">
      <v-btn :class="projectsHistoryIsVisible ? 'pr-1' : 'pr-2'" text to="/projects" class="pl-2 text-uppercase tour-projects">{{ $t('NAV.PROJECTS') }}</v-btn>
      <navigation-projects-history @is-visible="projectsHistoryIsVisible = $event"></navigation-projects-history>
      <v-menu v-if="config != 'home' && isPremium" offset-y>
        <template #activator="{ on }">
          <v-btn text class="px-2 text-uppercase tour-dashboards" v-on="on">
            <span>{{ $t('HOME.DASHBOARDS') }}</span>
            <v-icon dark small class="ml-1">fas fa-caret-down</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <template v-if="customDashboards">
            <v-list-item v-for="dashboard in customDashboards" :key="dashboard.to" :to="dashboard.to">{{ dashboard.title }}</v-list-item>
            <v-divider></v-divider>
          </template>
          <v-list-item v-if="accessRight.hasMonitoringPlan" to="/dashboards/monitoringplan">{{ $t('MONITORING_PLAN.TITLE') }}</v-list-item>
          <v-list-item to="/analysis/progress">{{ $t('MONITORING_PROGRESS.TITLE') }}</v-list-item>
          <v-divider></v-divider>
          <v-list-item to="/analysis/planningusers">{{ $t('PLANNINGUSERS.USERS_PLANNING') }}</v-list-item>
          <v-divider></v-divider>
          <v-list-item to="/analysis/actions">{{ $t('MONITORING_ACTIONS.TITLE') }}</v-list-item>
          <v-divider></v-divider>
          <v-list-item to="/analysis/portfolio">{{ $t('PORTFOLIO.TITLE') }}</v-list-item>
          <v-divider></v-divider>
          <template v-if="isBusiness">
            <v-list-item to="/analysis/personalcalendar">{{ $t('PERSONAL_CALENDAR.TITLE') }}</v-list-item>
            <v-divider></v-divider>
          </template>
          <v-list-item to="/home">{{ $t('NAV.HOME') }}</v-list-item>
        </v-list>
      </v-menu>
      <v-btn v-if="config == 'home' || ! isPremium" text to="/dashboards" class="px-2 text-uppercase tour-dashboards">
        {{ $t('HOME.DASHBOARDS') }}
      </v-btn>
      <v-btn v-if="accessRight.hasMeeting" href="./meeting" text color="meetingprimary" active-class="" class="px-2 text-uppercase" style="min-width: 0">
        <span class="hidden-md-and-down">{{ $t('MEETING.BUBBLE_MEETING') }}</span>
        <span class="hidden-lg-and-up">MEETING</span>
      </v-btn>
    </v-toolbar-items>

    <!-- TRANSCLUDE BAR -->
    <slot><v-spacer></v-spacer></slot>

    <!-- HOME MANAGE-->
    <template v-if="config == 'home' && ! accessRight.userConsultOnly">
      <v-btn to="/manage" rounded small depressed class="mx-1" style="height: 32px">
        <v-icon color="warningorange darken-2" small class="mr-2">fa fa-lock</v-icon>
        {{ $t('NAV.MANAGE') }}
      </v-btn>
    </template>

    <!-- ACCOUNT & HELP-->
    <navigation-help :config="config"></navigation-help>
    <navigation-account></navigation-account>

    <!--  LOGOUT SPLASH SCREEN -->
    <modal v-if="logoutIsOpened" :closable="false" :backdrop-opacity="logoutDone ? 1 : .8">
      <div style="position: fixed; left: 0; right: 0; z-index: 99999999;">
        <section class="text-center white--text" style="height: 100vh;">
          <div v-if="! logoutDone" class="pt-12">
            <h3>{{ $t('ACCOUNT.LOGGING_OUT') }}</h3><div class="pa-4"><i class="fas fa-spinner fa-spin fa-2x fa-fw"></i></div>
          </div>
          <div v-else class="pt-12"><h3>{{ $t('ACCOUNT.DISCONNECTED') }}</h3></div>
        </section>
      </div>
    </modal>
  </v-app-bar>
</template>

<style>
  #topbar {
    height: auto !important;
    overflow-y: hidden; /* IE */
  }
</style>

<script>
  import clientConfig from '@/client_customs/config';
  import NavigationProjectsHistory from './NavigationProjectsHistory';
  import NavigationHelp from './NavigationHelp';
  import NavigationAccount from './NavigationAccount';

  export default {
    components: {
      NavigationProjectsHistory,
      NavigationHelp,
      NavigationAccount,
    },
    props: {
      config: { type: String, default: '' }, // planning, projects, manage
      section: { type: String, default: '' }, // homepage section
    },
    data() {
      return {
        projectsHistoryIsVisible: null,
        customDashboards: clientConfig.dashboards && clientConfig.dashboards.list,
      };
    },
    computed: {
      accessRight() {
        return this.$store.state.users.accessRight;
      },
      isPremium() {
        return this.accessRight.isPremium;
      },
      isBusiness() { return this.$store.state.users.accessRight.isBusiness; },
      mobileview() {
        return this.$store.state.ui.mobileview;
      },
      logoutIsOpened() {
        return ['logout', 'logoutDone'].includes(this.$store.state.ui.navigation.openedModal);
      },
      logoutDone() {
        return this.$store.state.ui.navigation.openedModal == 'logoutDone';
      },
    },
  };
</script>
