export default {
  /* requires this.allStats */
  data() {
    return {
      moodIconOptions: ['sun', 'suncloud', 'cloud', 'storm'].reverse(),
      globalOptions: {
        legend: {
          display: false,
        },
        layout: {
          padding: {
            right: 50,
            bottom: 50,
          },
        },
        plugins: {
          datalabels: {
            color: 'white',
            formatter(value, context) {
              const percent = Math.round(value / context.dataset.total * 100);
              return percent ? `${percent}%` : '';
            },
          },
        },
      },
    };
  },
  computed: {
    moodChart() {
      return {
        type: 'pie',
        data: {
          labels: this.moodIconOptions.map(moodIcon => this.$t(`MOOD.${moodIcon.toUpperCase()}`)),
          datasets: [{
            data: this.moodIconOptions.map(moodIcon => this.allStats.moodData[moodIcon] || 0),
            backgroundColor: [
              this.$vuetify.theme.currentTheme.errorred,
              this.$vuetify.theme.currentTheme.warningorange,
              this.$vuetify.theme.currentTheme.warningorange,
              this.$vuetify.theme.currentTheme.successgreen,
            ],
            borderWidth: 0,
          }],
        },
        options: angular.merge({}, this.globalOptions, {
          plugins: {
            datalabels: {
              display: false,
            },
          },
        }),
      };
    },
    endtimeChart() {
      return {
        type: 'pie',
        data: {
          labels: ['PROJECT_SHEET.OVERDUE', 'PORTFOLIO.IN_TIME'].map((item) => { const text = this.$t(item); return text.charAt(0).toUpperCase() + text.slice(1); }),
          datasets: [{
            data: [this.allStats.endtimeData.overdue, this.allStats.endtimeData.intime],
            total: this.allStats.endtimeData.total,
            backgroundColor: [
              this.$vuetify.theme.currentTheme.errorred,
              this.$vuetify.theme.currentTheme.successgreen,
            ],
            borderWidth: 0,
          }],
        },
        options: angular.copy(this.globalOptions),
      };
    },
    budgetChart() {
      return {
        type: 'pie',
        data: {
          labels: ['PORTFOLIO.OUT_OF_BUDGET', 'PORTFOLIO.IN_BUDGET'].map((item) => { const text = this.$t(item); return text.charAt(0).toUpperCase() + text.slice(1); }),
          datasets: [{
            data: [this.allStats.budgetData.out, this.allStats.budgetData.in],
            total: this.allStats.budgetData.total,
            backgroundColor: [
              this.$vuetify.theme.currentTheme.errorred,
              this.$vuetify.theme.currentTheme.successgreen,
            ],
            borderWidth: 0,
          }],
        },
        options: angular.copy(this.globalOptions),
      };
    },
    actionsChart() {
      return {
        type: 'pie',
        data: {
          labels: ['OVERDUE', 'URGENT', 'FINISHED', 'FUTURE'].map((item) => { const text = this.$t(`PROJECT_SHEET.${item}`); return text.charAt(0).toUpperCase() + text.slice(1); }),
          datasets: [{
            data: [this.allStats.actionsData.overdue, this.allStats.actionsData.urgent, this.allStats.actionsData.finished, this.allStats.actionsData.future],
            total: this.allStats.actionsData.total,
            backgroundColor: [
              this.$vuetify.theme.currentTheme.errorred,
              this.$vuetify.theme.currentTheme.warningorange,
              this.$vuetify.theme.currentTheme.successgreen,
              this.$vuetify.theme.currentTheme.secondary,
            ],
            borderWidth: 0,
          }],
        },
        options: angular.copy(this.globalOptions),
      };
    },
    progressChart() {
      return {
        type: 'pie',
        data: {
          labels: ['OVERDUE', 'IN_PROGRESS', 'FINISHED', 'FUTURE'].map((item) => { const text = this.$t(`PROJECT_SHEET.${item}`); return text.charAt(0).toUpperCase() + text.slice(1); }),
          datasets: [{
            data: [this.allStats.progressData.overdue, this.allStats.progressData.urgent, this.allStats.progressData.finished, this.allStats.progressData.future],
            total: this.allStats.progressData.total,
            backgroundColor: [
              this.$vuetify.theme.currentTheme.errorred,
              this.$vuetify.theme.currentTheme.warningorange,
              this.$vuetify.theme.currentTheme.successgreen,
              this.$vuetify.theme.currentTheme.secondary,
            ],
            borderWidth: 0,
          }],
        },
        options: angular.copy(this.globalOptions),
      };
    },
  },
  methods: {
    chartLabelPosition(start, percent) {
      const angle = Math.PI * (0.5 - 2 * start - percent);
      const x = Math.cos(angle) / 2;
      const y = Math.sin(angle) / 2;
      return { top: `calc(${(1 - y) * 50}% - 16px)`, left: `calc(${(x + 1) * 50}% - 16px)` };
    },
    chartMoodLabelPosition(moodIndex) {
      const { moodData } = this.allStats;
      const moodIcon = this.moodIconOptions[moodIndex];
      const start = this.moodIconOptions.slice(0, moodIndex).reduce((tot, icon) => tot + (moodData[icon] || 0), 0) / moodData.total;
      const percent = moodData[moodIcon] / moodData.total;
      return this.chartLabelPosition(start, percent);
    },
  },
};
