<template>
  <!-- Projects & Groups Filters -->
  <dashboards-filters :filters="['Projects', 'Groups']" @update="update">
    <!-- GroupUsers Filter -->
    <template v-if="useGroups">
      <v-autocomplete v-model="selectedGroup" :label="$t('GLOBAL.GROUP')" :items="groupUsers" item-text="title" item-value="group_id"
                      prepend-icon="fas far fa-users" hide-details menu-props="offsetY" return-object
                      @change="update">
      </v-autocomplete>
      <v-divider vertical class="mx-2 mx-lg-4"></v-divider>
    </template>
    <!-- Users Filter -->
    <template v-else>
      <v-autocomplete v-model="selectedUser" :label="$t('PERSONAL_CALENDAR.PARTICIPANT')" :items="allUsers" item-text="username" item-value="id"
                      prepend-icon="fas fa-user" hide-details menu-props="offsetY" return-object
                      @change="update">
        <template #item="{ parent, item }">
          <v-list-item-avatar class="mr-3">
            <img v-if="item.avatar" :src="item.avatar">
            <v-icon v-else class="color-inherit">{{ item.icon || 'fas fa-user' }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="parent.genFilteredText(item.username)"></v-list-item-title>
          </v-list-item-content>
        </template>
      </v-autocomplete>
      <v-divider vertical class="mx-2 mx-xl-4"></v-divider>
    </template>
    <!-- Display Filter -->
    <template>
      <v-select v-model="selectedDisplayList" :items="selectedDisplayOptions"
                :label="$t('MULTIPROJECTS.DISPLAY')" :placeholder="selectedDisplayList.length ? '' : $t('PERSONAL_CALENDAR.NO_DISPLAY_SELECTED')"
                prepend-icon="fas fa-eye" hide-details multiple menu-props="offsetY"
                @change="update">
        <template #selection="{ item, index }">
          <v-chip v-if="selectedDisplayList.length <= 3">{{ item.text }}</v-chip>
          <span v-else-if="index === 0">{{ selectedDisplayList.length }}/{{ selectedDisplayOptions.length }}</span>
        </template>
      </v-select>
    </template>
  </dashboards-filters>
</template>

<script>
  import { mapState } from 'vuex';
  import DashboardsFilters from '../DashboardsFilters/DashboardsFilters';

  export default {
    components: {
      DashboardsFilters,
    },

    data() {
      return {
      };
    },
    computed: {
      selectedUser: {
        get() {
          for (let i = 0; i < this.selectedCompanyUsers.length; i++) {
            const user = this.companyUsers.find(item => item.id == this.selectedCompanyUsers[i]);
            if (user) return user;
          }
          for (let i = 0; i < this.selectedVirtualParticipants.length; i++) {
            const user = this.virtualParticipants.find(item => item.username == this.selectedVirtualParticipants[i]);
            if (user) return user;
          }
          return this.noParticipants;
        },
        set(newVal) {
          this.selectedCompanyUsers = [];
          this.selectedVirtualParticipants = [];
          if (! newVal) return;
          if (newVal.id) {
            this.selectedCompanyUsers.push(newVal.id);
          } else {
            this.selectedVirtualParticipants.push(newVal.username);
          }
        },
      },
      selectedCompanyUsers: {
        get() {
          return this.$store.state.multiprojects.selectedCompanyUsers;
        },
        set(newVal) {
          this.$store.state.multiprojects.selectedCompanyUsers.splice(0, this.$store.state.multiprojects.selectedCompanyUsers.length, ...newVal);
        },
      },
      allUsers() {
        return this.companyUsers.concat(this.virtualParticipants).concat(this.noParticipants);
      },
      selectedVirtualParticipants: {
        get() {
          return this.$store.state.multiprojects.selectedVirtualParticipants;
        },
        set(newVal) {
          this.$store.state.multiprojects.selectedVirtualParticipants.splice(0, this.$store.state.multiprojects.selectedVirtualParticipants.length, ...newVal);
        },
      },
      noParticipants() {
        return { username: this.$t('PERSONAL_CALENDAR.UNASSIGNED') };
      },
      selectedGroup: {
        get() {
          return this.selectedCompanyGroups[0];
        },
        set(newVal) {
          this.selectedCompanyGroups = [];
          this.selectedCompanyGroups.push(newVal.group_id);
        },
      },
      selectedCompanyGroups: {
        get() {
          return this.$store.state.multiprojects.selectedCompanyGroups;
        },
        set(newVal) {
          this.$store.state.multiprojects.selectedCompanyGroups.splice(0, this.$store.state.multiprojects.selectedCompanyGroups.length, ...newVal);
        },
      },
      groupUsers() {
        return (this.$store.getters['users/groups/getCompanyGroups'] || []).map(item => ({
          group_id: item.id,
          title: item.title,
          icon: 'far fa-users',
        }));
      },
      useGroups() {
        return this.isBusiness && !! (this.groupsOptions.useGroups && this.companyGroups);
      },
      companyGroups() {
        return this.$store.getters['users/groups/getCompanyGroups'];
      },
      isBusiness() { return this.$store.state.users.accessRight.isBusiness; },
      selectedDisplayOptions() {
        return [
          { value: 'milestone', text: this.$t('PERSONAL_CALENDAR.MILESTONE') },
          { value: 'shorttask', text: this.$t('PERSONAL_CALENDAR.SHORTTASK') },
          { value: 'longtask', text: this.$t('PERSONAL_CALENDAR.LONGTASK') },
          { value: 'planningactions', text: this.$t('PERSONAL_CALENDAR.PLANNING_ACTIONS') },
          { value: 'meetingactions', text: this.$t('PERSONAL_CALENDAR.MEETING_ACTIONS') },
          // { value: 'holidays', text: this.$t('PERSONAL_CALENDAR.HOLIDAYS') },
        ];
      },
      selectedDisplayList: {
        get() {
          return Object.keys(this.calendarDisplay).filter(key => this.calendarDisplay[key]);
        },
        set(newVal) {
          Object.keys(this.calendarDisplay).forEach((key) => {
            this.calendarDisplay[key] = newVal.includes(key);
          });
        },
      },
      ...mapState('multiprojects', ['filterOptions', 'companyUsers', 'virtualParticipants', 'calendarDisplay', 'groupsOptions']),
    },
    methods: {
      update() {
        this.filterOptions.unassigned = (this.selectedUser.username === this.noParticipants.username);
        this.$emit('update');
      },
    },
  };
</script>
