<template>
  <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :nudge-bottom="nudgeDetails ? -22 : null" offset-y
          transition="scale-transition" min-width="290px">
    <template #activator="{ on }">
      <v-text-field v-model="inputDate" v-bind="$attrs" :rules="[validateFormat]" :class="inputClass" autocomplete="off" v-on="on"
                    @click:clear="setUpdatedDate(null)">
      </v-text-field>
    </template>
    <v-date-picker ref="picker" v-model="calendarDate" :min="min" :max="max" :locale="locale" :allowed-dates="allowedDates" first-day-of-week="1" color="accent" no-title scrollable
                   @input="closeMenu">
    </v-date-picker>
  </v-menu>
</template>

<script>
  /*  Input: v-model (date) can be set to a moment or string
      Output: v-model (@input) will be a moment or null

      null = empty date string // Invalid formats (ie 35/12/2018) are not emitted
  */

  export default {
    inheritAttrs: false,
    model: {
      prop: 'date',
      event: 'input',
    },
    props: {
      date: { type: [String, Object], default: null },
      format: { type: String, default: '' },
      noNull: { type: Boolean, default: false },
      returnString: { type: Boolean, default: false },
      allowedDates: { type: Function, default: null },
      inputClass: { type: String, default: '' },
      startScreen: { type: String, default: '' },
      min: { type: String, default: null },
      max: { type: String, default: null },
      nudgeDetails: { type: Boolean, default: false },
    },
    data() {
      return {
        updatedDate: null,
        menu: null,
      };
    },
    computed: {
      locale() {
        return this.$store.state.lang.lang;
      },
      dateFormat() {
        if (this.format) return this.format;
        return this.locale == 'en' ? 'YYYY-MM-DD' : 'DD/MM/YYYY';
      },
      calendarDate: {
        get() {
          return this.updatedDate ? this.updatedDate.format("YYYY-MM-DD") : null;
        },
        set(newVal) {
          this.setUpdatedDate(moment(newVal).startOf('day'));
        },
      },
      inputDate: {
        get() {
          return this.updatedDate ? this.updatedDate.format(this.dateFormat) : null;
        },
        set(newVal) {
          if (! newVal) {
            this.setUpdatedDate(null);
            return;
          }
          if (! this.isFormatValid(newVal)) return;
          const newdate = moment(newVal, this.dateFormat);
          if (! newdate.isValid()) return;
          let loopLimit = 500;
          while (this.allowedDates && ! this.allowedDates(newdate) || ! loopLimit--) {
            newdate.add(1, 'day');
          }
          this.setUpdatedDate(newdate.startOf('day'));
        },
      },
    },
    watch: {
      date: {
        handler(newVal) {
          if (! newVal) { this.updatedDate = null; return; }
          this.updatedDate = moment(newVal).startOf('day').locale(this.locale);
        },
        immediate: true,
      },
      menu(val) {
        if (val && this.startScreen) this.$nextTick(() => { this.$refs.picker.activePicker = this.startScreen; });
      },
    },
    methods: {
      setUpdatedDate(newVal) {
        this.updatedDate = newVal;
        if (this.noNull && newVal === null) return;
        this.$emit('input', (this.updatedDate && this.returnString) ? this.updatedDate.format() : this.updatedDate);
      },
      closeMenu() {
        this.$refs.menu.isActive = false;
      },
      isFormatValid(str) {
        return moment(str, this.dateFormat, this.locale, true).isValid();
      },
      validateFormat(str) {
        if (! str) {
          return ! this.noNull || 'Date required';
        }
        return this.isFormatValid(str) || `Format : ${this.dateFormat}`;
      },
    },
  };
</script>
