import { mapActions } from 'vuex';

export default {
  computed: {
    selection() { return this.$store.state.selection; },
  },
  methods: {
    droppableConfig(lane) {
      if (! this.canModifyLane(lane)) return { disabled: true };
      const vm = this;
      return {
        disabled: lane.hidden,
        accept: '.element',
        drop(event, ui) {
          vm.dropSelection(event, ui, lane);
        },
        over(event, ui) {
          vm.dropOver(event, ui, lane);
        },
        out(event, ui) {
          vm.dropOut(event, ui, lane);
        },
      };
    },

    dropOver(event, ui, lane) {
      if (ui.helper.data('livedrag-active')) {
        $(`#el${ui.helper.data('placeholder_id')}`).show();
        const placeholderEl = this.planning.elements.find(el => el.id == ui.helper.data('placeholder_id'));
        if (placeholderEl) placeholderEl.setLaneId(lane.id);
      }
    },

    dropOut(event, ui, lane) {
      if (ui.helper.data('livedrag-active')) {
        const placeholderEl = this.planning.elements.find(el => el.id == ui.helper.data('placeholder_id'));
        if (placeholderEl && placeholderEl.getLaneId() == lane.id) $(`#el${ui.helper.data('placeholder_id')}`).hide();
      }
    },

    dropSelection(event, ui, lane) {
      const droppedTab = [];
      const draggedEl = this.planning.elements.find(el => el.id == ui.helper.data('id'));

      if (this.selection.length <= 1) {
        this.moveElements([[draggedEl, lane.id, ui.position.left]]);
        return;
      }

      // selection drag
      const offsetLane = (this.planning.lanes || []).findIndex(item => item.id === lane.id) - (this.planning.lanes || []).findIndex(item => item.id === draggedEl.getLaneId());

      this.selection.forEach((selectedId) => {
        const selectedEl = this.planning.elements.find(el => el.id == selectedId);
        if (! selectedEl) return;

        if (selectedEl.id == draggedEl.id) {
          // Dragged element with helper
          droppedTab.push([draggedEl, lane.id, ui.position.left]);
        } else {
          let newlaneIndex = (this.planning.lanes || []).findIndex(item => item.id === selectedEl.getLaneId()) + offsetLane;
          // if getting out of visible, stop to last visible lane
          while (offsetLane && (! this.planning.lanes[newlaneIndex])) newlaneIndex -= offsetLane / Math.abs(offsetLane);

          const selectedElLeftPosition = $(`#el${selectedEl.id}`).position().left;

          const newlane = this.planning.lanes[newlaneIndex];

          droppedTab.push([selectedEl, newlane.id, selectedElLeftPosition]);
        }
      });
      this.moveElements(droppedTab);
      this.$store.state.ui.planning.doNotUpdateSelection = false;
    },

    ...mapActions('planning/elements', ['moveElements']),
  },
};
