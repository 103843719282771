<template>
  <v-row no-gutters class="flex-nowrap">
    <v-col :style="expandMenu ? 'min-height: 380px;' : 'min-height: 430px;'"
           style="min-width: 0; max-height: 90vh; padding-bottom: 6px; padding-top: 6px; background-color: #f9f9f9; border-radius: 6px;">
      <div class="element-details-content">
        <div class="small text-muted text-center" style="margin-bottom: 6px">{{ $t('ELEMENT-DETAIL.CHANGES_ARE_SAVED_AUTOMATICALLY') }}</div>
        <transition-group name="list-fade" tag="div" style="position: relative">
          <v-card id="edcard-header" key="header" class="list-fade-item"><element-details-header :el="el" @close="close"></element-details-header></v-card>
          <v-card v-for="customField in customFields" v-show="customField.active" :id="`edcard-cf_${customField.dataField}`" :key="`cf_${customField.dataField}`" class="list-fade-item">
            <client-custom-component :name="customField.component" :el="el" :custom-field="customField"
                                     @update-progress="updateProgress" @add-users="addUsers($event)">
            </client-custom-component>
          </v-card>
          <v-card v-if="el.isType('task')" v-show="display.progress" id="edcard-progress" key="progress" class="list-fade-item"><element-details-progress :el="el"></element-details-progress></v-card>
          <v-card v-if="el.isType('task')" v-show="display.checklist" id="edcard-checklist" key="checklist" class="list-fade-item">
            <element-details-checklist :el="el" @add-user="addUser($event)"></element-details-checklist>
          </v-card>
          <v-card v-show="display.users" id="edcard-users" key="users" class="list-fade-item"><element-details-users ref="ElementDetailsUsers" :el="el"></element-details-users></v-card>
          <v-card v-show="display.links" id="edcard-links" key="links" class="list-fade-item"><element-details-links :el="el"></element-details-links></v-card>
          <v-card v-show="display.alerts" id="edcard-alerts" key="alerts" class="list-fade-item"><element-details-alerts :el="el"></element-details-alerts></v-card>
          <v-card key="comments" class="list-fade-item"><element-details-comments :el="el"></element-details-comments></v-card>
        </transition-group>
        <div class="small text-muted text-center my-n2">
          <template v-if="el.getCustomFields() && el.getCustomFields().mcs_tachemodeleid">
            tachemodeleid : {{ el.getCustomFields().mcs_tachemodeleid }}
          </template>
          <template v-else-if="el.getCustomFields() && el.getCustomFields().msdyn_projecttaskid">
            tachemodeleid : ∅ projecttaskid : {{ el.getCustomFields().msdyn_projecttaskid }}
          </template>
        </div>
      </div>
    </v-col>

    <!--      COLUMN MENU-->
    <v-col :style="expandMenu ? 'width: 138px; flex: 0 0 138px; min-height: 380px' : 'width: 52px; flex: 0 0 52px; min-height: 430px'"
           style="display: flex; flex-direction: column; max-height: 90vh">
      <div class="element-details-menu" style="flex: 1; display: flex; flex-direction: column;">
        <div class="mb-1 display-flex align-center" style="border-bottom: 1px solid #aaa">
          {{ expandMenu ? $t('ELEMENT-DETAIL.SHOW') : '&nbsp;' }}
          <v-tooltip left>
            <template #activator="{ on }">
              <v-btn :class="{ 'mr-auto': ! expandMenu }" icon x-small class="ml-auto" v-on="on" @click="expandMenu = ! expandMenu">
                <v-icon x-small>fas {{ expandMenu ? 'fa-chevron-right' : 'fa-chevron-left' }}</v-icon>
              </v-btn>
            </template>
            {{ expandMenu ? $t('ELEMENT-DETAIL.COLLAPSE_MENU') : $t('ELEMENT-DETAIL.EXPAND_MENU') }}
          </v-tooltip>
          <template v-if="! expandMenu">&nbsp;</template>
        </div>
        <div>
          <v-tooltip v-for="customField in customFields" v-if="customField.inMenu" :key="customField.dataField" :disabled="expandMenu" left>
            <template #activator="{ on }">
              <v-btn :color="customField.active ? 'primary' : null" depressed block class="px-1 my-1" v-on="on" @click="displayCardCustomField(customField)">
                <v-icon small :class="{ 'mr-2': expandMenu }">fa-fw {{ customField.icon }}</v-icon>
                <template v-if="expandMenu">{{ customField.label }}<v-spacer></v-spacer></template>
              </v-btn>
            </template>
            {{ customField.label }}
          </v-tooltip>
          <v-tooltip :disabled="expandMenu" left>
            <template #activator="{ on }">
              <v-btn v-if="el.isType('task')" :color="display.progress ? 'primary' : null" depressed block class="px-1 my-1" v-on="on" @click="displayCard('progress')">
                <v-icon small :class="{ 'mr-2': expandMenu }">fas fa-fw fa-long-arrow-alt-right</v-icon>
                <template v-if="expandMenu">{{ $t('ELEMENT-DETAIL.PROGRESS') }}<v-spacer></v-spacer></template>
              </v-btn>
            </template>
            {{ $t('ELEMENT-DETAIL.PROGRESS') }}
          </v-tooltip>
          <v-tooltip :disabled="expandMenu" left>
            <template #activator="{ on }">
              <v-btn v-if="el.isType('task')" :color="display.checklist ? 'primary' : null" depressed block class="px-1 my-1" v-on="on" @click="displayCard('checklist')">
                <v-icon small :class="{ 'mr-2': expandMenu }">fas fa-fw fa-check-square</v-icon>
                <template v-if="expandMenu">{{ $t('ELEMENT-DETAIL.CHECKLIST') }}<v-spacer></v-spacer></template>
              </v-btn>
            </template>
            {{ $t('ELEMENT-DETAIL.CHECKLIST') }}
          </v-tooltip>
          <v-tooltip :disabled="expandMenu" left>
            <template #activator="{ on }">
              <v-btn :color="display.users ? 'primary' : null" depressed block class="px-1 my-1" v-on="on" @click="displayCard('users')">
                <v-icon small :class="{ 'mr-2': expandMenu }">fas fa-fw fa-user</v-icon>
                <template v-if="expandMenu">{{ $t('ELEMENT-DETAIL.USERS') }}<v-spacer></v-spacer></template>
              </v-btn>
            </template>
            {{ $t('ELEMENT-DETAIL.USERS') }}
          </v-tooltip>
          <v-tooltip :disabled="expandMenu" left>
            <template #activator="{ on }">
              <v-btn :color="display.links ? 'primary' : null" depressed block class="px-1 my-1" v-on="on" @click="displayCard('links')">
                <v-icon small :class="{ 'mr-2': expandMenu }">fas fa-fw fa-paperclip</v-icon>
                <template v-if="expandMenu">{{ $t('ELEMENT-DETAIL.ATTACHMENTS') }}<v-spacer></v-spacer></template>
              </v-btn>
            </template>
            {{ $t('ELEMENT-DETAIL.ATTACHMENTS') }}
          </v-tooltip>
          <v-tooltip :disabled="expandMenu" left>
            <template #activator="{ on }">
              <v-btn :color="display.alerts ? 'primary' : null" depressed block class="px-1 my-1" v-on="on" @click="displayCard('alerts')">
                <v-icon small :class="{ 'mr-2': expandMenu }">fas fa-fw fa-alarm-exclamation</v-icon>
                <template v-if="expandMenu">{{ $t('ELEMENT-DETAIL.ALERTS') }}<v-spacer></v-spacer></template>
              </v-btn>
            </template>
            {{ $t('ELEMENT-DETAIL.ALERTS') }}
          </v-tooltip>
        </div>

        <div class="mt-auto mx-1 mb-2 display-flex flex-wrap align-end">
          <v-tooltip :top="expandMenu" :left="! expandMenu">
            <template #activator="{ on }">
              <v-btn :class="expandMenu ? null : 'mx-auto'" icon small outlined color="accent" v-on="on" @click="close('select-color')">
                <v-icon :color="el.getIcon().color || 'accent'" small>{{ el.getIcon().name || 'fa-tint' }}</v-icon>
              </v-btn>
            </template>
            {{ $t(el.isType('task') ? 'PLANNING.SELECT_ICON_AND_COLOR' : 'PLANNING.SELECT_ICON') }}
          </v-tooltip>
          <v-tooltip v-if="options.canDuplicateEl && ! el.isFromTemplate()" :top="expandMenu" :left="! expandMenu">
            <template #activator="{ on }">
              <v-btn :class="expandMenu ? 'ml-2' : 'mx-auto'" class="mt-2" color="info" icon small outlined v-on="on" @click="close('duplicate')">
                <v-icon small>far fa-copy</v-icon>
              </v-btn>
            </template>
            {{ $t('PLANNING.DUPLICATE') }}
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-menu :close-on-content-click="false" :max-width="200" :min-width="200" top offset-y left>
            <template #activator="{ on: onMenu }">
              <v-tooltip :top="expandMenu" :left="! expandMenu">
                <template #activator="{ on: onTooltip }">
                  <v-btn :class="expandMenu ? null : 'mx-auto'" color="secondary" icon small outlined class="mt-2" v-on="{ ...onMenu, ...onTooltip }">
                    <v-icon small>far fa-cog</v-icon>
                  </v-btn>
                </template>
                {{ $t('ELEMENT-DETAIL.LANE_PRIORITY_DELETE') }}
              </v-tooltip>
            </template>
            <element-details-positioning-menu :el="el" :options="options" @close="close($event)"></element-details-positioning-menu>
          </v-menu>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<style scoped>
  .list-fade-item {
    width: 100%;
  }
  .list-fade-enter {
    transition-property: opacity;
  }
  .list-fade-enter, .list-fade-leave-to {
    transform: translateY(-30px);
  }
</style>

<style>
  .element-details-content {
    height: 100%;
    padding: 3px 8px 8px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .element-details-content .v-card {
    padding: 12px;
    margin-bottom: 16px;
  }

  .element-details-menu {
    background: rgba(153, 153, 153, 0.15);
    padding: 44px 8px 0;
  }
</style>

<script>
  import EventBus from '@/js/event-bus.js';
  import clientConfig from '@/client_customs/config';
  import ElementDetailsHeader from './ElementDetailsHeader';
  import ElementDetailsProgress from './ElementDetailsProgress';
  import ElementDetailsChecklist from './ElementDetailsChecklist';
  import ElementDetailsUsers from './ElementDetailsUsers';
  import ElementDetailsLinks from './ElementDetailsLinks';
  import ElementDetailsAlerts from './ElementDetailsAlerts';
  import ElementDetailsComments from './ElementDetailsComments';
  import ElementDetailsPositioningMenu from './ElementDetailsPositioningMenu';

  const ua = window.navigator.userAgent;
  const isSlowBrowser = ua.indexOf('Trident/') > 0 || ua.indexOf('MSIE ') > 0;

  export default {
    components: {
      ElementDetailsHeader,
      ElementDetailsProgress,
      ElementDetailsChecklist,
      ElementDetailsUsers,
      ElementDetailsLinks,
      ElementDetailsAlerts,
      ElementDetailsComments,
      ElementDetailsPositioningMenu,
    },
    props: {
      element: { type: Object, required: true },
      options: { type: Object, default: () => ({}) },
    },
    data() {
      return {
        el: angular.copy(this.element),
        display: clientConfig.elementDetails?.display || {
          progress: false, checklist: false, users: false, links: false, budgets: false, alerts: false,
        },
        initialStateEl: angular.copy(this.element),
        customFields: clientConfig.elements?.customFields,
        expandMenu: this.$vuetify.breakpoint.name != 'xs',
      };
    },
    watch: {
      el: {
        handler: _.debounce(function () {
          this.element.reset(this.el.getAll());
          this.element.alerts = this.el.alerts;
        }, isSlowBrowser ? 500 : 100),
        deep: true,
      },
      expandMenu() {
        const elementDetailsOptions = JSON.stringify({ expandMenu: this.expandMenu });
        window.localStorageWrapper.setItem('elementDetailsOptions', elementDetailsOptions);
      },
    },
    created() {
      this.initDisplay();
      EventBus.$on('project-updated', this.onProjectUpdate);

      const elementDetailsOptions = window.safeParseJSON(window.localStorageWrapper.getItem('elementDetailsOptions'));
      if (elementDetailsOptions) this.expandMenu = elementDetailsOptions.expandMenu;
    },
    beforeDestroy() {
      EventBus.$off('project-updated', this.onProjectUpdate);
    },
    methods: {
      close(reason) {
        this.$emit('close', reason);
      },
      onProjectUpdate(modifications) { // project updated in background by another user or instance
        if (! modifications.elements[this.element.id]) return;
        const updatedElement = this.element;
        if (! updatedElement.getPlanning().elements.find(item => item.id == updatedElement.id)) {
          this.close();
          return;
        }
        if (angular.equals(updatedElement.getAll(), this.initialStateEl.getAll())) {
          updatedElement.set(this.el.getAll());
          updatedElement.modificationUser = null;
        } else {
          this.initialStateEl = angular.copy(updatedElement);
          this.el = angular.copy(updatedElement);
        }
      },
      initDisplay() {
        if (this.el.getProgress()) this.display.progress = true;
        if (this.el.getChecklist() && this.el.getChecklist().length) this.display.checklist = true;
        if (this.el.getUsers() && this.el.getUsers().length) this.display.users = true;
        if (this.el.getLinks() && this.el.getLinks().length) this.display.links = true;
        if (this.el.getBudgets() && this.el.getBudgets().some(budget => budget.amount || budget.amount_inprogress)) this.display.budgets = true;
        if (this.el.alerts && this.el.alerts.length) this.display.alerts = true;
        if (! this.display.progress && ! this.display.checklist && ! this.display.users && ! this.display.links && ! this.display.budgets && ! this.display.alerts) this.display.progress = true;
      },
      displayCard(prop) {
        this.display[prop] = ! this.display[prop];
        if (! this.display[prop]) return;
        const displayNode = document.getElementById(`edcard-${prop}`);
        if (displayNode && displayNode.scrollIntoView) setTimeout(() => displayNode.scrollIntoView({ behavior: 'smooth' }));
      },
      displayCardCustomField(customField) {
        customField.active = ! customField.active;
        if (! customField.active) return;
        const displayNode = document.getElementById(`edcard-cf_${customField.dataField}`);
        if (displayNode && displayNode.scrollIntoView) setTimeout(() => displayNode.scrollIntoView({ behavior: 'smooth' }));
      },
      addUser(user) {
        if (this.$refs.ElementDetailsUsers) {
          this.$refs.ElementDetailsUsers.addUser(user);
          this.display.users = true;
        }
      },
      addUsers(users) {
        (users || []).forEach((user) => { this.addUser(user); });
      },
      updateProgress() {
        this.el.updateChecklistProgress();
        if (this.el.getProgress()) this.display.progress = true;
      },
    },
  };
</script>
