/* eslint no-use-before-define: 0 */
import Vue from 'vue';
import PlanningLane from '@/models/PlanningLane';
import DependenciesSrv from '@/components/Dependencies/DependenciesSrv';
import { commitAdd, commitRemove, commitEmpty, nextId, getElement, insertNewElement, addElements, deleteElements } from './elements/addremove';

let undoState = [];

/** ********** */
/* UNDO/REDO */
/** ********** */
function getUndoRedoElementsFunctions(context) {
  const { state: elements } = context;
  if (angular.equals(elements, undoState)) return null;
  const newState = elements.map(el => angular.copy(el));
  const oldState = undoState.map(el => angular.copy(el));
  return {
    action: () => { set(context, newState.map(el => angular.copy(el))); },
    rollback: () => { set(context, oldState.map(el => angular.copy(el))); },
  };
}

function undoredoElements(context) {
  const { dispatch } = context;
  const undoRedoFunctions = getUndoRedoElementsFunctions(context);
  if (! undoRedoFunctions) return;
  dispatch('undoredo/add', undoRedoFunctions, { root: true });
  dispatch('planning/save', null, { root: true });
}

function undoredoElement(context, el) {
  const { dispatch } = context;
  const undoStateEl = undoState.find(item => item.id == el.id);
  if (! undoStateEl) { undoredoElements(context); return; }
  if (angular.equals(el.getAll(), undoStateEl.getAll())) return;
  const newState = angular.copy(el);
  const oldState = angular.copy(undoStateEl);
  dispatch('undoredo/add', {
    action: () => {
      el.reset(angular.copy(newState.getAll()));
      el.update();
    },
    rollback: () => {
      el.reset(angular.copy(oldState.getAll()));
      el.update();
    },
  }, { root: true });
  dispatch('planning/save', null, { root: true });
}

/** ***************** */
/* ELEMENTS TAB SET */
/** ***************** */
function set(context, newelements) {
  const { commit } = context;
  commit('empty');
  while (newelements.length) {
    const newel = newelements.shift();
    insertNewElement(context, newel);
  }
}

/** ***** */
/* EDIT */
/** ***** */
function startChangingElement({ state: elements }) {
  undoState = elements.map(el => angular.copy(el));
}

function changingElement(context, el) {
  const hasChanged = updateDependencies(context, el);
  if (hasChanged) return;
  if (el) {
    el.update();
    undoredoElement(context, el);
  } else {
    updateAllElements(context);
    undoredoElements(context);
  }
}

/** ******** */
/* DISPLAY */
/** ******** */
function updateAllElements({ state: elements }) {
  elements.forEach((el) => {
    el.update();
  });
}

/** ******** */
/* ACTIONS */
/** ******** */

/** ******* MOVE ******** */
async function moveElements({ dispatch }, argstab) {
  let el;
  argstab.forEach((args) => {
    if (args.length) {
      el = args.shift();
      el.move(...args);
    }
  });
  if (argstab.length == 1 && el) {
    setTimeout(() => { dispatch('changingElement', el); }); // timeout needed when xposition is programmaticaly set back to initial value
  } else {
    setTimeout(() => { dispatch('changingElement'); });
  }
}

/** ******* RESIZE ******** */
async function resizeElement({ dispatch }, { el, elWidth, handle }) {
  el.resize(elWidth, handle);
  dispatch('changingElement', el);
}

/** ******* SNAP ******** */
function setSnapBoundaries({ state: elements, rootState: { planning } }, ui) {
  const boundaries = {};
  if (planning.visibleTimeline?.show_timegrid) { // snap to grid
    let xposition = 0;
    planning.visibleTimeline.timelinecols.forEach((col) => {
      boundaries[xposition] = 'both';
      xposition += col.width;
    });
    boundaries[xposition] = 'left';
    xposition = 0;
    planning.visibleTimeline.subtimelinecols.forEach((col) => {
      boundaries[xposition] = 'both';
      xposition += col.width;
    });
    boundaries[xposition] = 'left';
  } else { // snap to elements
    elements.forEach((el) => {
      if (el.isType('milestone')) return;
      if (`el${el.id}` == ui.helper.attr('id')) return;
      if (boundaries[el.xposition] == 'right' || boundaries[el.xposition] == 'both') {
        boundaries[el.xposition] = 'both';
      } else {
        boundaries[el.xposition] = 'left';
      }
      const endposition = el.xposition + el.getWidth();
      if (boundaries[endposition] == 'left' || boundaries[endposition] == 'both') {
        boundaries[endposition] = 'both';
      } else {
        boundaries[endposition] = 'right';
      }
    });
  }

  ui.helper.data('ui-snap', {
    boundaries,
    parent_offset: ui.helper.parent().offset().left - $(".table-body").offset().left,
  });
}

function snapToBoundaries(context, ui) {
  const spacing = 2;
  const snapdata = ui.helper.data('ui-snap');
  if (! snapdata) return;
  const pos = { left: ui.position.left, right: ui.position.left + ui.helper.outerWidth() };

  const limits = Object.keys(snapdata.boundaries).map(limit => +limit);
  const [closestLeftBoundary] = limits.reduce((acc, limit) => {
    const distance = Math.abs(limit - pos.left - snapdata.parent_offset);
    if (distance < 5) acc.push({ limit, distance, border: snapdata.boundaries[limit] });
    return acc;
  }, []).sort((a, b) => (a.distance < b.distance ? -1 : 1));
  const [closestRightBoundary] = limits.reduce((acc, limit) => {
    const distance = Math.abs(limit - pos.right - snapdata.parent_offset);
    if (distance < 5) acc.push({ limit, distance, border: snapdata.boundaries[limit] });
    return acc;
  }, []).sort((a, b) => (a.distance < b.distance ? -1 : 1));
  if (! closestLeftBoundary && ! closestRightBoundary) return;

  if (ui.size) { // resize
    const handle = (ui.position.left !== ui.originalPosition.left) ? 'w' : 'e';
    if (handle == 'w' && closestLeftBoundary) {
      ui.size.width -= closestLeftBoundary.limit - pos.left - snapdata.parent_offset + (['right', 'both'].includes(closestLeftBoundary.border) ? spacing : 0);
      const gap = ui.size.width - ui.originalSize.width;
      ui.position.left = ui.originalPosition.left - gap;
    } else if (handle == 'e' && closestRightBoundary) {
      ui.size.width = closestRightBoundary.limit - pos.left - snapdata.parent_offset + (['left', 'both'].includes(closestRightBoundary.border) ? -spacing : 0);
    }
  } else if (closestLeftBoundary) { // drag snap start
      ui.position.left = closestLeftBoundary.limit - snapdata.parent_offset + (['right', 'both'].includes(closestLeftBoundary.border) ? spacing : 0);
  } else if (closestRightBoundary) { // drag snap end
    ui.position.left = pos.left + closestRightBoundary.limit - pos.right - snapdata.parent_offset + (['left', 'both'].includes(closestRightBoundary.border) ? -spacing : 0);
  }
}

/** ************* */
/* DEPENDENCIES */
/** ************* */
function updateDependencies(context, triggerEl) {
  const { state: elements, getters, rootState, dispatch } = context;
  const changedEls = DependenciesSrv.calculate(elements, rootState.planning.visibleTimeline);
  if (! changedEls.length) return false;

  function copyChangedElementsValues(newEls) {
    newEls.forEach((el) => {
      const realEl = getters.getElement(el.id);
      realEl.setStartTime(el.getStartTime());
      realEl.setEndTime(el.getEndTime());
      realEl.update();
    });
  }

  if (triggerEl && changedEls.find(item => item.id == triggerEl.id && item.getStartTime().isAfter(triggerEl.getStartTime()))) {
    const changedElsReverse = DependenciesSrv.calculate(elements, rootState.planning.visibleTimeline, true);
    dispatch('ui/msgbox/open', {
      title: "DEPENDENCIES.ADVANCE_PREDECESSORS",
      body: "DEPENDENCIES.DEPENDENCY_CONSTRAINT",
      buttons: { ok: "DEPENDENCIES.YES_ADVANCE", cancel: "DEPENDENCIES.NO_RESPECT_CONSTRAINT" },
    }, { root: true }).then(() => {
      copyChangedElementsValues(changedElsReverse);
      changingElement(context);
      const changedTriggerEl = triggerEl && changedElsReverse.find(item => item.id == triggerEl.id);
      if (changedTriggerEl) {
        const { constrainingLockedElement } = changedTriggerEl;
        dispatch('ui/msgbox/open', {
          title: "DEPENDENCIES.LOCKED_ELEMENT",
          body: rootState.lang.i18n.t("DEPENDENCIES.MOVE_LIMITED_BY_LOCKED_ELEMENTS", {
            lockedEl: constrainingLockedElement && constrainingLockedElement.getTitle(),
            lockedElDate: constrainingLockedElement && constrainingLockedElement.getStartTime().format('L'),
          }),
        }, { root: true });
      }
    }).catch(() => {
      copyChangedElementsValues(changedEls);
      changingElement(context);
    });
  } else {
    copyChangedElementsValues(changedEls);
    changingElement(context);
    const changedTriggerEl = triggerEl && changedEls.find(item => item.id == triggerEl.id);
    if (changedTriggerEl) {
      const { constrainingLockedElement } = changedTriggerEl;
      dispatch('ui/msgbox/open', {
        title: "DEPENDENCIES.LOCKED_ELEMENT",
        body: rootState.lang.i18n.t("DEPENDENCIES.MOVE_LIMITED_BY_LOCKED_ELEMENTS", {
          lockedEl: constrainingLockedElement && constrainingLockedElement.getTitle(),
          lockedElDate: constrainingLockedElement && constrainingLockedElement.getStartTime().format('L'),
        }),
      }, { root: true });
    }
  }

  return true;
}

/** ************ */
/** WORK DAYS * */
/** ************ */
function correctWorkdays(context) {
  const { state: elements, rootState } = context;
  if (! rootState.planning.timeline.workdays) return;
  elements.forEach((el) => {
    const starttime = el.getStartTime();
    if (starttime.day() % 6 === 0) {
      el.setStartTime(starttime.addWithWorkdays(1, 'minute', rootState.planning.timeline.workdays).startOf('day'));
    }
    const endtime = el.getEndTime();
    if (endtime.day() % 6 === 0) {
      el.setEndTime(endtime.addWithWorkdays(-1, 'minute', rootState.planning.timeline.workdays).endOf('day'));
    }
  });
}

/** ******* */
/* EVENTS */
/** ******* */
async function onDeleteLanes(context, lanes) { // Delete lanes elements and return undoredo functions (no undoredo nor saving here)
  const { commit, rootGetters, dispatch } = context;
  await dispatch('startChangingElement');
  lanes.forEach((lane) => {
    const laneElements = rootGetters['planning/lanes/getLaneElements']({ laneId: lane.id });
    laneElements.forEach((el) => {
      commit('remove', el);
    });
  });

  return getUndoRedoElementsFunctions(context);
}

async function onChangeTimeline(context, oldTimeline) {
  const { state: elements, rootState, dispatch } = context;
  if (rootState.planning.timeline.workdays && oldTimeline && ! oldTimeline.workdays) {
    await dispatch('startChangingElement');
    correctWorkdays(context);
    undoredoElements(context);
  }
  Vue.nextTick(() => {
    updateAllElements(context);
    Vue.nextTick(() => {
      elements.forEach((el) => {
        el.updateHeight();
      });
      rootState.planning.lanes.forEach((lane) => {
        PlanningLane.updateAllYposition(elements.filter(el => el.getLaneId() == lane.id));
      });
    });
  });
}

export default {
  namespaced: true,
  state: [],
  mutations: {
    add: commitAdd,
    remove: commitRemove,
    empty: commitEmpty,
  },
  getters: {
    nextId,
    getElement,
  },
  actions: {
    addElements,
    moveElements,
    resizeElement,
    setSnapBoundaries,
    snapToBoundaries,
    deleteElements,
    startChangingElement,
    changingElement,
    onDeleteLanes,
    onChangeTimeline,
  },
};
