import Vue from 'vue';

export default new Vue();

/*
  LIST OF EVENTS
  EventBus.$emit('project-updated', modifications); in Planning.vue
  -> ElementDetails.vue
  -> MacroElementDetails.vue

  EventBus.$emit('update-project'); in store/planning/planning.js
  -> Planning.vue
*/
