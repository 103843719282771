<template>
  <v-menu v-model="dropdownIsOpen" :close-on-content-click="false" offset-y nudge-bottom="4">
    <template #activator="{ on: onMenu }">
      <v-tooltip :disabled="dropdownIsOpen" bottom>
        <template #activator="{ on: onTooltip }">
          <v-btn fab small class="ml-2 tour-profile" v-on="{ ...onMenu, ...onTooltip }">
            <v-badge :value="unseenNotifications.length" overlap color="errorred" bottom offset-y="18">
              <template #badge>
                <v-icon color="white" size="10" style="width: auto; height: auto">fas fa-bell</v-icon>
              </template>
              <v-avatar size="40">
                <img v-if="avatar" :src="avatar" :alt="me.firstname || $t('NAV.ACCOUNT')">
                <v-icon v-else color="secondary">fas fa-user</v-icon>
              </v-avatar>
            </v-badge>
          </v-btn>
        </template>
        <span>{{ me.firstname || $t('NAV.ACCOUNT') }}</span>
      </v-tooltip>

      <!--  MODALS -->
      <modal v-if="accountAvatarIsOpened || notificationsManagerIsOpened || integrationsManagerIsOpened" dark @close="closeModals">
        <account-window :initial-tab="integrationsManagerIsOpened ? 'integrations' : notificationsManagerIsOpened ? 'notifications' : 'avatar'"
                        @close="closeModals">
        </account-window>
      </modal>
      <modal v-if="premiumWindowIsOpened" size="sm" @close="closeModals">
        <premium-window @close="closeModals"></premium-window>
      </modal>
    </template>
    <v-list dense class="pb-0">
      <v-list-item class="py-2">
        <v-list-item-avatar :title="$t('ACCOUNT.CHANGE_AVATAR')" size="48" class="ma-0 pointer" @click="dropdownIsOpen = false; openAccountAvatar()">
          <img :src="avatar" style="max-height: 50px; max-width: 50px; border-radius: 50%;">
        </v-list-item-avatar>
        <div class="ml-2">
          <div :title="me.email || ''"><strong>{{ me.firstname || '' }} {{ me.lastname || '' }}</strong></div>
          <div v-if="displayManageAccount">
            <a :href="`https://bubbleplan.net/auth.php?token=${api_token}&redirect=mon-compte`" target="_blank">{{ $t('ACCOUNT.MANAGE') }}</a>
          </div>
        </div>
      </v-list-item>
      <v-divider></v-divider>
      <navigation-account-notifications @close="dropdownIsOpen = false"></navigation-account-notifications>
      <v-list-item v-if="hasNoUploadedAvatar" @click="dropdownIsOpen = false; openAccountAvatar()">{{ $t('ACCOUNT.CHANGE_AVATAR') }}</v-list-item>
      <v-list-item v-if="displayIntegrations" @click="dropdownIsOpen = false; openIntegrationsManager()">{{ $t('ACCOUNT.INTEGRATIONS') }}</v-list-item>
      <v-divider></v-divider>
      <v-list-item :title="$t('NAV.MANAGE')" to="/manage" active-class="" @click.native="dropdownIsOpen = false">
        <v-list-item-content class="d-block">
          <div>{{ $t('NAV.MANAGE') }}</div>
          <v-list-item-subtitle>{{ me.company && me.company.name || "FREE" }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn :title="$t('PREMIUM.UPDATE_SUBSCRIPTION')" fab small @click.prevent="openPremium()">
            <v-icon color="accent">fas fa-cart-plus</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider v-if="! isPremium"></v-divider>
      <v-list-item v-if="! isPremium" :href="`https://bubbleplan.net/auth.php?token=${api_token}&redirect=mon-compte%23premium`" target="_blank">
        <b>{{ $t('ACCOUNT.UPGRADE') | uppercase }}</b>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item>
        <span class="nobr mr-2">{{ $t('ACCOUNT.LANGUAGE') }} :</span>
        <v-select v-model="lang" :items="[{ value: 'en', text: 'English' }, { value: 'fr', text: 'Français' }]"
                  hide-details class="ma-0 pa-0" style="width: 120px;">
        </v-select>
      </v-list-item>
      <v-divider></v-divider>
      <div class="display-flex">
        <v-list-item target="_blank" href="./meeting">
          <b class="meetingprimary--text text-uppercase nobr">{{ $t('MEETING.BUBBLE_MEETING') }}</b>
        </v-list-item>
      </div>
      <v-divider></v-divider>
      <v-list-item @click="dropdownIsOpen = false; logout()">
        <div class="ml-auto">{{ $t('ACCOUNT.LOG_OUT') }} <i class="fas fa-sign-out-alt ml-2"></i></div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  import clientConfig from '@/client_customs/config';
  import AccountWindow from '@/components/Account/Account.vue';
  import PremiumWindow from '@/components/Premium/PremiumWindow.vue';
  import NavigationAccountNotifications from './NavigationAccountNotifications';

  export default {
    components: {
      AccountWindow,
      PremiumWindow,
      NavigationAccountNotifications,
    },
    data() {
      return {
        dropdownIsOpen: null,
        api_token: window.apiSrv.getAuthToken(),
        displayManageAccount: ! clientConfig.hideManageAccount,
      };
    },
    computed: {
      lang: {
        get() {
          return this.$store.state.lang.lang;
        },
        set(newLang) {
          this.$store.dispatch('lang/loadLang', newLang);
        },
      },
      me() {
        return this.$store.state.users.user;
      },
      avatar() {
        return this.me && this.me.avatar;
      },
      hasNoUploadedAvatar() {
        return this.avatar && this.avatar.endsWith('?d=mm');
      },
      unseenNotifications() {
        return this.$store.getters['notifications/unseen'];
      },
      accountAvatarIsOpened() {
        return this.$store.state.ui.navigation.openedModal == 'account-avatar';
      },
      notificationsManagerIsOpened() {
        return this.$store.state.ui.navigation.openedModal == 'notifications-manager';
      },
      integrationsManagerIsOpened() {
        return this.$store.state.ui.navigation.openedModal == 'integrations-manager';
      },
      premiumWindowIsOpened() {
        return this.$store.state.ui.navigation.openedModal == 'premium-window';
      },
      displayIntegrations() {
        const { availableIntegrations } = this.$store.getters['users/accessRight/config'];
        return ! (availableIntegrations && ! availableIntegrations.length);
      },
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
    },
    methods: {
      openAccountAvatar() {
        this.$store.commit('ui/navigation/setOpenedModal', 'account-avatar');
      },
      openPremium() {
        this.$store.commit('ui/navigation/setOpenedModal', 'premium-window');
      },
      openIntegrationsManager() {
        this.$store.commit('ui/navigation/setOpenedModal', 'integrations-manager');
      },
      closeModals() {
        this.$store.commit('ui/navigation/setOpenedModal', null);
      },
      logout() {
        this.$store.commit('ui/navigation/setOpenedModal', 'logout');
        window.apiSrv.logout().then(() => {
          this.$store.commit('users/reset');
          if (clientConfig.sso) {
            this.$store.commit('ui/navigation/setOpenedModal', 'logoutDone');
          } else {
            window.location.href = "";
          }
        }).catch(() => {
          this.closeModals();
          this.$store.commit('users/reset');
          window.location.href = "";
        });
      },
    },
  };
</script>
