<template>
  <div>
    <div class="display-flex align-center">
      <img :style="{ filter: integration.state == 'connected' ? '' : 'grayscale(100%)',
                     opacity: ['loading'].indexOf(integration.state) > -1 ? .5 : 1 }"
           src="../../img/integrations/calendar.png" alt="calendar" style="width: 40px">
      <b class="primary--text text-uppercase ml-2">{{ $t('INTEGRATIONS.WEBCALENDAR.TITLE') }}</b>
    </div>
    <div class="ml-12 mb-4">
      <div v-if="integration.state == 'inactive'">
        <v-btn color="accent" rounded @click="activateLink()">
          <v-icon class="mr-2" small>fas fa-plus</v-icon> {{ $t('INTEGRATIONS.WEBCALENDAR.ACTIVATE') }}
        </v-btn>
      </div>
      <div v-if="integration.state == 'loading'">
        <v-icon color="primary">fas fa-spinner fa-spin fa-2x fa-fw</v-icon>
      </div>
      <div v-if="integration.state == 'connected'" class="display-flex align-center">
        <v-icon color="successgreen">fa fa-check</v-icon>
        <span class="ml-2">{{ $t('INTEGRATIONS.WEBCALENDAR.LINK_ACTIVE') }}</span>
        <v-spacer></v-spacer>
        <v-divider vertical class="mr-2"></v-divider>
        <v-btn color="errorred" rounded outlined @click="deleteLink()">
          {{ $t('INTEGRATIONS.WEBCALENDAR.RESET_LINK') }}
        </v-btn>
      </div>
      <div v-if="integration.state == 'error'" class="display-flex align-center">
        <v-icon color="errorred">fas fa-exclamation-triangle</v-icon>
        <span class="ml-2" style="line-height: 1.2;">{{ integration.error }}</span>
      </div>
    </div>
    <div v-if="webcalToken" class="ml-12">
      <!-- INPUT LINK -->
      <v-text-field v-if="linkWebCalendar" id="inputWebCalendarLink" class="mt-6" :value="linkWebCalendar" solo readonly @focus="$event.target.select()">
        <template #append>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn icon class="mr-0" v-on="on" @click="copyToClipboard()">
                <v-icon v-show="! copyingToClipboard.success && ! copyingToClipboard.error" color="primary">fas fa-clipboard</v-icon>
                <v-icon v-show="copyingToClipboard.success" color="successgreen">fas fa-check</v-icon>
                <v-icon v-show="copyingToClipboard.error" color="errorred">fas fa-exclamation-triangle</v-icon>
              </v-btn>
            </template>
            {{ copyingToClipboard.error ? $t('SHARE.COPY_FAILED') : $t('SHARE.COPY_CLIPBOARD') }}
          </v-tooltip>
        </template>
      </v-text-field>
      <!-- INPUT LINK -->
    </div>
    <div v-else class="ml-12">
      {{ $t('INTEGRATIONS.WEBCALENDAR.BENEFITS') }}
      <div v-if="! isPremium" class="mt-2 strong" v-html="$t('PREMIUM.WEBCALENDAR_IS_PREMIUM')"></div>
    </div>
  </div>
</template>

<script>

  export default {
    data() {
      return {
        integration: {
          state: 'loading',
          error: null,
        },
        copyingToClipboard: { success: false, error: false },
        webcalToken: null,
      };
    },
    computed: {
      linkWebCalendar() {
        if (! this.webcalToken) return '';
        const subdomain = window.location.hostname.replace(/(.*)\.bubbleplan\.net/i, '$1');
        let linkWebCalendar = `webcal://api.bubbleplan.net/v1/webcal/calendar/${this.webcalToken}`;
        if (subdomain != window.location.hostname && subdomain != 'app') linkWebCalendar += `?subdomain=${subdomain}`;
        return linkWebCalendar;
      },
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
    },
    created() {
      if (this.isPremium) {
        this.loadIntegration();
      }
    },
    methods: {
      copyToClipboard() {
        const input = document.getElementById('inputWebCalendarLink');
        this.copyingToClipboard = { success: false, error: false };
        try {
          input.select();
          document.execCommand('selectAll');
          document.execCommand('copy');
          this.copyingToClipboard.success = true;
          setTimeout(() => { this.copyingToClipboard.success = false; }, 3000);
        } catch (e) {
          this.copyingToClipboard.error = true;
        }
      },
      loadIntegration() {
        window.apiSrv.call('webcal/token').then((response) => {
          this.webcalToken = response.data.token;
          this.integration = {
            state: this.webcalToken ? 'connected' : 'inactive',
          };
        }).catch((error) => {
          this.integration = {
            state: 'error',
            error,
          };
        });
      },
      activateLink() {
        this.integration.state = 'loading';
        window.apiSrv.call('webcal/token', 'store').then((response) => {
          this.webcalToken = response.data.token;
          this.integration = {
            state: this.webcalToken ? 'connected' : 'inactive',
          };
        }).catch((error) => {
          this.integration = {
            state: 'error',
            error,
          };
        });
      },
      deleteLink() {
        this.$store.dispatch('ui/msgbox/open', {
          title: "INTEGRATIONS.WEBCALENDAR.RESET_LINK_TITLE",
          body: "INTEGRATIONS.WEBCALENDAR.RESET_LINK_BODY",
          buttons: { ok: "INTEGRATIONS.WEBCALENDAR.RESET_LINK", cancel: "GLOBAL.CANCEL" },
        }).then(() => {
          this.integration.state = 'loading';
          window.apiSrv.call('webcal', 'destroy', 'token').then(() => {
            this.webcalToken = '';
            this.integration = {
              state: this.webcalToken ? 'connected' : 'inactive',
            };
          }).catch((error) => {
            this.integration = {
              state: 'error',
              error,
            };
          });
        }).catch(() => {});
      },
    },
  };
</script>
