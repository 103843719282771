import store from '@/store';
import socket from '@/js/socket';

/* Load and update user notifications */
function loadNotifications() {
  store.state.users.userPromise.then(() => {
    store.dispatch('notifications/load').catch(() => {});
  });
}
loadNotifications();

function seenNotifs(filters) {
  store.dispatch('notifications/seen', { filters }).catch(() => {});
}

/* ******************************* */
/* * PUSH NOTIFICATIONS * */
/* ******************************* */
//  function setUpPushNotifications() {
//    if (! ("Notification" in window) || ! navigator.serviceWorker) return;
//
//    navigator.serviceWorker.register('notifications-sw.js').then((registration) => {
//      const messaging = firebase.messaging();
//      messaging.useServiceWorker(registration);
//
//      messaging.requestPermission().then(() => {
//        console.log('Notification permission granted.');
//        messaging.getToken().then((currentToken) => {
//          if (currentToken) {
//            console.log(currentToken);
//            //          sendTokenToServer(currentToken);
//          } else {
//            // Show permission request.
//            console.log('No Instance ID token available. Request permission to generate one.');
//            // Show permission UI.
//            //          updateUIForPushPermissionRequired();
//          }
//        }).catch((err) => {
//          console.log('An error occurred while retrieving token. ', err);
//          //        showToken('Error retrieving Instance ID token. ', err);
//        });
//      }).catch((err) => {
//        console.log('Unable to get permission to notify.', err);
//      });
//      // Handle incoming messages. Called when:
//      // - a message is received while the app has focus
//      // - the user clicks on an app notification created by a service worker
//      //   `messaging.setBackgroundMessageHandler` handler.
//      messaging.onMessage((payload) => {
//        console.log('Push Message received. ', payload);
//        // ...
//      });
//    });
//  }

/* ********************************* */
/* * DESKTOP NOTIFICATIONS DISPLAY * */
/* ********************************* */
function printDesktopNotification(message, title, tag) {
  if (! store.state.users.user || ! store.state.users.user.company_id) return;

  if (! ("Notification" in window)) return;
  Notification.requestPermission((permission) => {
    if (permission != "granted") {
      return;
    }
    const notification = new Notification(title || 'Bubble Plan', {
      icon: 'https://bubbleplan.net/assets/img/logo-small.png',
      body: message,
      tag,
    });

    notification.onclick = function () {
      window.focus();
      this.close();
    };

    setTimeout(() => {
      notification.close();
    }, 10000);
  });
}

/* ************************************ */
/*  LIVE NOTIFICATIONS WITH WEBSOCKETS  */
/* ************************************ */
const notificationsCallbacks = { onNotification: _.throttle(loadNotifications, 60000) };
const reconnectCallbacks = { onNotification: _.throttle(loadNotifications, 60000) };
const instance = Math.random().toString(36).substr(2, 10);

function init() {
  store.state.users.userPromise.then((user) => {
    if (! user.company_id) return;
    socket.on('notif', (notif) => {
      // console.log('received notif', notif)
      if (! notif || (notif.environment != window.location.hostname) || (notif.created_by == store.state.users.user.id && notif.instance == instance)) return;
      if (! store.state.users.user.company_id || store.state.users.user.company_id != notif.company_id) return;

      Object.values(notificationsCallbacks).forEach((cb) => {
        cb(notif);
      });
    });
  });
}

init();
//  setUpPushNotifications();
store.dispatch('users/onUserChange', { notifications: init }); // when user logout and login again / change company_id

/* **************************** */
/* * OnNotification Callbacks * */
/* **************************** */
function equalFields(fields, obj1, obj2) {
  for (let i = 0; i < fields.length; i++) {
    if (obj1[fields[i]] != obj2[fields[i]]) return false;
  }
  return true;
}

socket.on('reconnect', reconnectCallbacks.onNotification);

function checkPlanningUpdates({ planningId, laneId = null }, onPlanningUpdate) {
  if (typeof onPlanningUpdate !== 'function') return;
  let key = `onPlanningUpdate${planningId}`;
  if (laneId) key += `.${laneId}`; // when project includes itself as subproject, or subproject included multiple times
  let lastUpdateTimestamp = 0;
  notificationsCallbacks[key] = function (notif) {
    if (notif.action != 'projectSaved' || notif.section_type != 'planning' || notif.section_id != planningId) return;
    if (notif.timestamp < lastUpdateTimestamp) return;
    const message = `${notif.username} ${notif.message || store.state.lang.i18n.t('NOTIFICATION.HAS_MODIFIED')}`;
    printDesktopNotification(message, notif.planning_title, notif.action);
    lastUpdateTimestamp = notif.timestamp;
    onPlanningUpdate();
  };

  reconnectCallbacks[key] = _.throttle(onPlanningUpdate, 60000);
  socket.on('reconnect', reconnectCallbacks[key]);
}
function stopCheckingPlanningUpdates({ planningId = null, laneId = null } = {}) {
  let key = 'onPlanningUpdate';
  if (planningId) key += planningId; // planningId == null => delete all planning updates
  if (laneId) key += `.${laneId}`; // planningId + laneId => delete only this lane updates (eg subproject removed)

  Object.keys(notificationsCallbacks).forEach((item) => {
    if (item.startsWith(key)) delete notificationsCallbacks[item];
  });
  Object.keys(reconnectCallbacks).forEach((item) => {
    if (item.startsWith(key)) socket.off('reconnect', reconnectCallbacks[item] || (() => {}));
  });
}

function checkExchangeCenterUpdates(params, onExchangeCenterUpdate) {
  if (typeof onExchangeCenterUpdate !== 'function') return;
  const key = `onExchangeCenterUpdate${params.section_type || 'section'}${params.section_id}${params.target_type || 'target'}${params.target_id}`;
  let lastUpdateTimestamp = 0;
  notificationsCallbacks[key] = function (notif) {
    if (! equalFields(['section_type', 'section_id', 'target_type', 'target_id', 'field_type'], notif, params)) return;
    if (notif.timestamp < lastUpdateTimestamp) return;
    lastUpdateTimestamp = notif.timestamp;
    onExchangeCenterUpdate();
  };

  reconnectCallbacks[key] = onExchangeCenterUpdate;
  socket.on('reconnect', reconnectCallbacks[key]);
}
function stopCheckingExchangeCenterUpdates(params) {
  const key = `onExchangeCenterUpdate${params.section_type || 'section'}${params.section_id}${params.target_type || 'target'}${params.target_id}`;
  delete notificationsCallbacks[key];
  socket.off('reconnect', reconnectCallbacks[key] || (() => {}));
}

/* ********************* */
/* * NEW NOTIFICATIONS * */
/* ********************* */
function sendSocketNotif(notif) {
  socket.emit('notif', _.extend({
    created_by: store.state.users.user.id,
    username: store.state.users.user.firstname,
    company_id: store.state.users.user.company_id,
    environment: window.location.hostname,
    instance,
  }, notif));
}

/* ********** */
/* * EVENTS * */
/* ********** */
const notificationsEvents = {};
function subscribeEvent(eventName, callback) {
  notificationsEvents[eventName] = callback;
}

function callEvent(eventName, data) {
  if (notificationsEvents[eventName]) notificationsEvents[eventName](eventName, data);
}

/* realtime and mail notifications */
subscribeEvent('projectSaved', (event, data) => {
  sendSocketNotif({ section_type: 'planning', section_id: data.planning_id, action: 'projectSaved', planning_title: data.planning_title || "" });
});

subscribeEvent('planning.commentElement', (event, data) => {
  const commentId = data.id;
  const { el } = data;
  if (! el) return;
  const planning = el.getPlanning();
  if (! planning) return;
  const notif = {
    section_type: 'planning',
    section_id: planning.id,
    target_type: 'element',
    target_id: el.id,
    field_type: 'comment',
    field_id: commentId,
    action: 'add',
    planning_title: planning.meta && planning.meta.title || "",
  };
  if (data.target_users && data.target_users.length) {
    notif.user_ids = data.target_users.map(item => item.id);
    notif.priority = 10;
  }
  sendSocketNotif(notif);
});

subscribeEvent('exchangeCenter.newMessage', (event, data) => {
  const commentId = data.id;

  const notif = { target_type: 'exchangeCenter', field_type: 'comment', field_id: commentId };
  ['section_type', 'section_id', 'target_type', 'target_id'].forEach((field) => {
    if (data[field]) notif[field] = data[field];
  });
  sendSocketNotif(notif);
});

subscribeEvent('meeting.changeAction', (event, action) => {
  if (! action.id) return;
  const userIds = [action.user_id, action.owner_id].filter(item => item);
  const notif = { section_type: 'meeting', section_id: action.meeting_id, target_type: 'meetingAction', target_id: action.id, user_ids: userIds };
  if (! action.meeting_id) {
    notif.section_type = 'meetingAction';
    notif.section_id = action.id;
  }
  sendSocketNotif(notif);
});

window.notificationsSrv = {
  seenNotifs,
  callEvent,
  checkPlanningUpdates,
  stopCheckingPlanningUpdates,
  checkExchangeCenterUpdates,
  stopCheckingExchangeCenterUpdates,
};

export default window.notificationsSrv;
