<template>
  <div>
    <div class="display-flex align-center mb-2">
      <div class="mr-2 text-center" style="flex: 0 0 24px;">
        <v-icon color="primary" small>fa fa-comments</v-icon>
      </div>
      <label class="uppercase primary--text ma-0">{{ $t('ELEMENT-DETAIL.COMMENTS') }}</label>
    </div>
    <div class="ml-6 pl-2">
      <exchange-center v-if="isPremium" :options="{ planning_id: planning.id, element_id: el.id, search: false, files: false }"></exchange-center>
      <div v-if="! isPremium">
        {{ $t('PREMIUM.COMMENTS_IS_PREMIUM') }}<br>
        <a :href="$t('PREMIUM.OFFERS_LINK')" target="_blank">{{ $t('PREMIUM.GO_PREMIUM') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
  import ExchangeCenter from '@/components/ExchangeCenter/ExchangeCenter';

  export default {
    components: {
      ExchangeCenter,
    },
    props: {
      el: { type: Object, required: true },
    },
    data() {
      return {
        planning: this.el.getPlanning(),
      };
    },
    computed: {
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
    },
  };
</script>
