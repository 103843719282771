<template>
  <v-combobox :value="categoryTags" :items="projectCategoryTags" :label="$tc('PROJECTS.CATEGORY', 2)" :hint="$t('PROJECTS.SELECT_CATEGORY')"
              :delimiters="[',']" multiple clearable hide-selected persistent-placeholder persistent-hint dense hide-no-data
              @input="updateTags">
    <template #selection="{ attrs, item, parent, selected }">
      <v-chip v-if="item" v-bind="attrs" :style="{ 'background-color': getCategoryTagColor(item).bg, color: getCategoryTagColor(item).color }"
              :input-value="selected" :title="item" small style="max-width: 150px; margin-top: 2px; margin-bottom: 2px">
        <span class="text-ellipsis pr-2">{{ item }}</span>
        <v-icon :style="{ color: getCategoryTagColor(item).color }" small @click="parent.selectItem(item)">fas fa-times-circle</v-icon>
      </v-chip>
    </template>
    <template #item="{ index, item }">
      <v-chip :style="{ 'background-color': getCategoryTagColor(item).bg, color: getCategoryTagColor(item).color }"
              :title="item" small class="pointer" style="max-width: 300px">
        <span class="text-ellipsis">{{ item }}</span>
      </v-chip>
    </template>
  </v-combobox>
</template>

<script>
  export default {
    model: {
      prop: 'category',
      event: 'change',
    },
    props: {
      category: { type: String, default: null },
      projectCategories: { type: Array, default: () => [] },
    },
    data() {
      return {
        categoryTags: null,
      };
    },
    computed: {
      projectCategoryTags() {
        return this.categories2tags(this.projectCategories);
      },
    },
    watch: {
      category: {
        handler() {
          this.categoryTags = this.category ? this.category.split(',') : [];
        },
        immediate: true,
      },
    },
    methods: {
      categories2tags(categories) {
        const tags = new Set();
        categories.forEach((item) => {
          (item || '').split(',').forEach((subitem) => {
            const tag = subitem && subitem.trim();
            if (tag) tags.add(tag);
          });
        });
        return [...tags];
      },
      updateTags(newVal) {
        if (newVal.some(item => ! item || item.trim() != item || item.includes(',')) || new Set(newVal).size != newVal.length) { // trim items + ',' in copied text + unicity
          this.categoryTags = this.categories2tags(newVal);
          return;
        }
        const newCategory = newVal.join(',');
        this.$emit('change', newCategory);
      },
      getCategoryTagColor(tag) {
        const tagColor = this.$store.getters['lang/getCategoryColor'](tag);
        return { bg: `#${tagColor}`, color: window.contrastedTextColor(tagColor) };
      },
    },
  };
</script>
