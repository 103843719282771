<template>
  <div :style="{'border-color': lane.getMainColor() || planning.config.designcolor.main, height: `${laneHeight}px`}" style="border-style: solid; border-width: 2px 0;" class="lane">
    <template v-if="subplanning">
      <planning-lane-view v-for="(sublane, sublaneIndex) in subplanning.lanes" v-if="visibleSublanes[sublaneIndex].selected"
                          :id="`lane${lane.id}`" :key="sublane.id" :planning="subplanning" :lane="sublane"
                          :options="{ elements: { canEditEl } }" :events="{ elementClick, selectColor, checklistClick, progressClick }"
                          :dynamic="false" :view="view" :style="{ 'height': sublane.height + 'px', background: 'aliceblue' }" is-sub-lane>
      </planning-lane-view>
    </template>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import constants from '@/js/constants.js';
  import PlanningLaneView from './PlanningLaneView';
  import laneHeight from './mixins/laneHeight';

  export default {
    components: {
      PlanningLaneView,
    },
    mixins: [laneHeight],
    props: {
      planning: { type: Object, required: true },
      lane: { type: Object, required: true },
      dynamic: { type: Boolean, default: false },
      view: { type: Boolean, default: false },
    },
    data() {
      return {
        planningViewId: this.view ? +this.$route.params.id : null,
      };
    },
    computed: {
      laneContentHeight() { // overwrite laneHeight mixin
        if (! this.subplanning) return constants.laneMinHeight;
        const visibleSublanes = this.subplanning.lanes.filter((sublane, sublaneIndex) => this.visibleSublanes[sublaneIndex].selected);
        const height = visibleSublanes.reduce((prevHeight, lane) => prevHeight + lane.height, 0);
        return Math.max(height, constants.laneMinHeight) + 5;
      },
      subplanning() {
        return this.getSubprojectByLaneId()(this.lane.id);
      },
      visibleSublanes() {
        return this.subplanning.lanes.map((subprojectLane) => {
          const subprojectLaneOriginalId = parseInt(subprojectLane.id, 10);
          return {
            id: subprojectLane.id,
            label: subprojectLane.label,
            selected: ! (this.lane.project_hidden_sublanes && this.lane.project_hidden_sublanes.includes(subprojectLaneOriginalId)),
          };
        });
      },
    },
    watch: {
      'lane.project_id': function onChangeSubproject() {
        this.loadSubproject();
      },
      laneContentHeight: function onChangeLaneContentHeight(val) {
        this.lane.contentHeight = val;
      },
    },
    mounted() {
      this.loadSubproject().then(() => {
        window.notificationsSrv.checkPlanningUpdates({ planningId: this.lane.project_id, laneId: this.lane.id }, _.throttle(() => {
          this.loadSubproject(false);
        }, 2000));
      });
    },
    beforeDestroy() {
      window.notificationsSrv.stopCheckingPlanningUpdates({ planningId: this.lane.project_id, laneId: this.lane.id });
    },
    methods: {
      isDynamic(el) {
        return this.dynamic && (! this.view || el.access_right == 'modify');
      },
      canEditEl(el) {
        return this.isDynamic(el) && this.subplanning?.meta?.access_right && this.subplanning?.meta?.access_right != 'consult';
      },
      loadSubproject(retry = true) {
        return this.fetchSubproject(_.extend({ id: this.lane.project_id, laneId: this.lane.id }, this.view && { viewId: this.planningViewId })).then((planning) => {
          planning.elements.forEach((el) => {
            el.update();
          });
          setTimeout(() => {
            planning.elements.forEach((el) => {
              el.updateHeight();
            });
          });
        }).catch((error) => {
          if (! retry) return Promise.reject(error);
          return setTimeout(() => { this.loadSubproject(false); });
        });
      },
      elementClick(el) {
        const originalPlanningEl = this.$store.getters['ui/subplanning/getOriginalPlanningEl'](el);
        originalPlanningEl.dashboard_el = el;
        this.$store.commit('ui/planning/openElement', originalPlanningEl);
      },
      selectColor(el) {
        const originalPlanningEl = this.$store.getters['ui/subplanning/getOriginalPlanningEl'](el);
        originalPlanningEl.dashboard_el = el;
        this.$store.commit('ui/planning/openColorPickerElement', originalPlanningEl);
      },
      checklistClick(el, item) {
        const oldState = { checklist: angular.copy(el.getChecklist()), progress: el.getProgress() };
        this.$set(item, 'checked', ! item.checked);
        el.updateChecklistProgress();
        this.$store.dispatch('ui/subplanning/saveEl', { el, props: ['checklist', 'progress'], oldState });
      },
      progressClick(el, event) {
        const oldState = { progress: el.getProgress() };
        const fullWidth = $(event.currentTarget).width();
        if (! fullWidth) return;
        el.setProgress(Math.min(Math.round(event.offsetX / fullWidth * 10) * 10, 100));
        this.$store.dispatch('ui/subplanning/saveEl', { el, props: ['progress'], oldState });
      },
      ...mapActions('subprojects', ['fetchSubproject']),
      ...mapGetters('subprojects', ['getSubprojectByLaneId']),
    },
  };
</script>
