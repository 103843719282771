<template>
  <div v-if="isAdmin">
    <div style="position: sticky; top: 50px; z-index: 1">
      <v-tabs v-model="currentTabIndex" background-color="white">
        <v-tab href="#settings">
          <span class="d-inline-flex" style="text-transform: none; font-size: 16px">
            <v-icon size="16" class="mr-1" style="color: inherit; transition: none; width: 24px">fas fa-users-cog</v-icon>
            {{ $t('MANAGE.CONFIG.SETTINGS') }}
          </span>
        </v-tab>
        <v-tab href="#tools">
          <span class="d-inline-flex" style="text-transform: none; font-size: 16px">
            <v-icon size="16" class="mr-1" style="color: inherit; transition: none; width: 24px">fas fa-tools</v-icon>
            {{ $t('MANAGE.CONFIG.TOOLS') }}
          </span>
        </v-tab>
      </v-tabs>
      <v-divider class="mb-5"></v-divider>
    </div>

    <v-tabs-items v-model="currentTabIndex" class="mx-3">
      <!-- TAB SETTINGS -->
      <v-tab-item :transition="false" :reverse-transition="false" value="settings">
        <v-alert v-if="! isBusiness" color="warningorange" type="warning" class="mt-4">
          {{ $t('PREMIUM.BUSINESS.SECTION_IS_BUSINESS') }} &nbsp;
        </v-alert>
        <manage-config-params v-else></manage-config-params>
      </v-tab-item>

      <!-- TAB TOOLS -->
      <v-tab-item :transition="false" :reverse-transition="false" value="tools">
        <v-alert v-if="! isBusiness" color="warningorange" type="warning" class="mt-4">
          {{ $t('PREMIUM.BUSINESS.SECTION_IS_BUSINESS') }} &nbsp;
        </v-alert>
        <div style="position: relative">
          <v-overlay :value="! isBusiness" :opacity=".8" color="black" absolute class="mx-n5 mt-n2 mb-n5" style="border-radius: 4px 12px 12px 12px"></v-overlay>
          <manage-config-tools-replace-users></manage-config-tools-replace-users>
          <v-divider class="my-8"></v-divider>
          <manage-config-tools-split-planning :projects="projects" class="mb-8"></manage-config-tools-split-planning>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
  <v-alert v-else color="warningorange" type="warning" class="mt-4">
    {{ $t('PREMIUM.ONLY_FOR_ADMIN') }} &nbsp;
  </v-alert>
</template>

<script>
  import ManageConfigParams from './ManageConfigParams';
  import ManageConfigToolsReplaceUsers from './ManageConfigToolsReplaceUsers';
  import ManageConfigToolsSplitPlanning from './ManageConfigToolsSplitPlanning';

  export default {
    components: {
      ManageConfigParams,
      ManageConfigToolsReplaceUsers,
      ManageConfigToolsSplitPlanning,
    },
    props: {
      projects: { type: Array, default: () => [] },
    },
    data() {
      return {
        currentTabIndex: null,
      };
    },
    computed: {
      isAdmin() { return this.$store.state.users.accessRight.isAdmin; },
      isBusiness() { return this.$store.state.users.accessRight.isBusiness; },
    },
  };
</script>
