<template>
  <v-container fluid class="pa-6">
    <v-row>
      <v-col lg="4" md="6" cols="12">
        <v-card class="pa-4">
          <div class="display-flex align-center justify-center">
            <label for="separator" class="control-label">{{ $t('EXPORT.CSV_SEPARATOR') }}</label>
            <div class="ml-4" style="max-width: 50px">
              <v-text-field id="separator" v-model="csvSeparator" class="text-center"></v-text-field>
            </div>
          </div>
          <v-divider class="my-4"></v-divider>
          <div class="text-right">
            <v-btn color="accent" rounded large @click="exportCsv()">{{ $t('EXPORT.EXPORT') }}</v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import Planning from '@/models/Planning';
  import { mapGetters } from 'vuex';

  export default {
    props: {
      planning: { type: Object, required: true },
    },
    data() {
      return {
        csvSeparator: ";",
      };
    },
    computed: {
      subPlannings() {
        return this.planning.lanes.filter(lane => lane.project_id).map((lane) => {
          const subPlanning = new Planning(this.getSubprojectByLaneId()(lane.id));
          subPlanning.lanes = subPlanning.lanes.filter((subLane) => {
            const subprojectLaneOriginalId = parseInt(subLane.id, 10);
            return ! (lane.project_hidden_sublanes && lane.project_hidden_sublanes.includes(subprojectLaneOriginalId));
          }).map((subLane) => {
            subLane.label = `${lane.label} ↪ ${subLane.label}`;
            return subLane;
          });
          return subPlanning;
        });
      },
    },
    methods: {
      safeProjectName() {
        return this.planning.meta.title.replace(/<(?:.|\n)*?>/gm, ' ').replace(/&nbsp;/g, " ").replace(/&amp;/g, "&").replace(/&gt;/g, ">")
          .replace(/&lt;/g, "<")
          .replace(/\//g, ' ');
      },
      exportCsv() {
        const separator = this.csvSeparator || ";";
        const fields = ['lane', 'title', 'starttime', 'endtime', 'schedule.start', 'schedule.end', 'html', 'progress', 'checklist', 'users', 'links', 'budgets'];
        const ED = "ELEMENT-DETAIL.";
        const texts = ['PLANNING.LANE', `${ED}TITLE`, `${ED}FROM`, `${ED}TO`, `${ED}SCHEDULE_START`, `${ED}SCHEDULE_END`, `${ED}DESCRIPTION`, `${ED}PROGRESS`, `${ED}CHECKLIST`, `${ED}USERS`, `${ED}ATTACHMENTS`, `${ED}BUDGET`];
        let csvPlanning = `${texts.reduce((a, b) => (a ? a + separator : "") + this.$t(b), "")}\n${this.planning.toCsv(fields, separator)}`;
        this.subPlannings.forEach((subPlanning) => {
          csvPlanning += subPlanning.toCsv(fields, separator);
        });

        if ((navigator.appName == 'Microsoft Internet Explorer') || ((navigator.appName == 'Netscape') && (new RegExp("Trident/.*rv:([0-9]{1,}[.0-9]{0,})").exec(navigator.userAgent) != null))) {
          // IE WORKAROUND
          const IEwindow = window.open();
          IEwindow.document.write(`sep=${separator}\r\n${csvPlanning}`);
          IEwindow.document.close();
          IEwindow.document.execCommand('SaveAs', true, `${this.safeProjectName()}.csv`);
          IEwindow.close();
        } else {
          const encodedUri = encodeURI(`data:text/csv;charset=utf-8,\uFEFF${csvPlanning}`).replace(/#/g, '%23');
          const link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", `${this.safeProjectName()}.csv`);
          document.body.appendChild(link); // Required for FF
          link.click();
          document.body.removeChild(link);
        }
      },
      ...mapGetters('subprojects', ['getSubprojectByLaneId']),
    },
  };
</script>
