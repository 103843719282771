<template>
  <div>
    <div v-if="loading.inprogress" class="text-center ma-6" style="text-align: center;"><i class="fas fa-spinner fa-spin fa-2x fa-fw"></i></div>
    <div v-if="! loading.inprogress">
      <div class="display-flex align-center">
        <select v-model="selected_view" class="form-control" style="max-width: 70%">
          <option v-for="view in views" :key="view.id" :value="view">
            {{ view.id ? (view.title || $t('CUSTOM_VIEWS.UNTITLED')) : $t('CUSTOM_VIEWS.CREATE_VIEW') }}
          </option>
        </select>
        <v-btn v-show="selected_view.id" :title="$t('CUSTOM_VIEWS.TEST_VIEW')" :href="viewUrl" target="_blank" icon small class="ml-1">
          <v-icon color="primary" small>fas fa-external-link-alt</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn v-show="selected_view.id" :title="$t('CUSTOM_VIEWS.DELETE_VIEW')" icon small @click="removeView()">
          <v-icon color="errorred" small>fas fa-trash-alt</v-icon>
        </v-btn>
      </div>
      <v-divider class="my-4"></v-divider>
      <div>
        <label for="inputViewTitle" class="control-label primary--text">{{ $t('CUSTOM_VIEWS.VIEW_NAME') }}</label>
        <span class="ml-2">
          <i v-if="saving.inprogress" class="fa fa-fw fa-spinner fa-spin"></i>
          <i v-if="saving.success" :title="$t('CUSTOM_VIEWS.SAVED')" class="fa fa-fw fa-check green"></i>
          <span v-if="saving.error"><i class="fa fa-fw fa-exclamation-triangle red"></i> &nbsp;{{ saving.error }}</span>
        </span>
        <input id="inputViewTitle" v-model="selected_view.title" v-contentautoselect class="form-control" @change="selected_view.id && updateViewDebounced()">
        <div v-show="selected_view.id" class="mt-4">
          <label for="inputViewReadOnlyLink" class="control-label primary--text">{{ $t('CUSTOM_VIEWS.RO_LINK') }}</label>
          <div class="input-group">
            <input id="inputViewReadOnlyLink" v-contentautoselect :value="viewReadonlyRoot + selected_view.read_only_token"
                   type="text" readonly style="cursor:text" class="form-control disabled">
            <span class="input-group-btn">
              <button :title="$t('SHARE.COPY_CLIPBOARD')" class="btn btn-default" style="padding:6px" @click="copyToClipboard()">
                <i v-show="! copying.success && ! copying.error" class="fa fa-clipboard fa-fw fa-lg primary--text"></i>
                <i v-show="copying.success" class="fa fa-check green fa-fw fa-lg"></i>
                <i v-show="copying.error" :title="$t('SHARE.COPY_FAILED')" class="fa fa-exclamation-triangle red fa-fw fa-lg"></i>
              </button>
            </span>
          </div>
        </div>
        <div v-show="! selected_view.id" class="mt-2 text-center">
          <v-btn color="accent" rounded large @click="createView()">{{ $t('CUSTOM_VIEWS.CREATE_THE_VIEW') }}</v-btn>
        </div>
      </div>
      <div v-show="selected_view.id"> <!-- v-show and not v-if to load defaults acr from views-xxx-acr components -->
        <v-divider class="my-4"></v-divider>
        <div>
          <div>
            <b class="primary--text">{{ $t('CUSTOM_VIEWS.CONFIGURATION') }}</b>
            <span class="ml-2">
              <i v-if="saving.inprogress" class="fa fa-fw fa-spinner fa-spin"></i>
              <i v-if="saving.success" :title="$t('CUSTOM_VIEWS.SAVED')" class="fa fa-fw fa-check green"></i>
              <span v-if="saving.error"><i class="fa fa-fw fa-exclamation-triangle red"></i> &nbsp;{{ saving.error }}</span>
            </span>
          </div>
          <div v-if="configTabs.length > 1" class="mt-1">
            <a v-for="(tab, tabIndex) in configTabs" :key="tabIndex" :class="{ underlined: configTabsIndex == tabIndex }" href="" class="mr-2"
               @click.prevent="configTabsIndex = tabIndex">{{ configTabsTitles[tab] }}</a>
          </div>
          <views-lanes-acr v-if="configTabs.includes('lanes')" v-show="configTabs[configTabsIndex] == 'lanes'" :acr="selected_view.acr"
                           @update="updateView">
          </views-lanes-acr>
          <views-colors-acr v-if="configTabs.includes('colors')" v-show="configTabs[configTabsIndex] == 'colors'" :acr="selected_view.acr"
                            @update="updateViewDebounced">
          </views-colors-acr>
          <views-colors-acr v-if="configTabs.includes('consultcolors')" v-show="configTabs[configTabsIndex] == 'consultcolors'"
                            :acr="selected_view.acr" toggled-access-right="consult"
                            @update="updateViewDebounced">
          </views-colors-acr>
        </div>
        <v-divider class="my-4"></v-divider>
        <div>
          <div>
            <b class="primary--text">{{ $t('CUSTOM_VIEWS.AUTHORIZED_USERS') }}</b>
            <span class="ml-2">
              <i v-if="savingUsers.inprogress" class="fa fa-fw fa-spinner fa-spin"></i>
              <i v-if="savingUsers.success" :title="$t('CUSTOM_VIEWS.SAVED')" class="fa fa-fw fa-check green"></i>
              <span v-if="savingUsers.error"><i class="fa fa-fw fa-exclamation-triangle red"></i> &nbsp;{{ savingUsers.error }}</span>
            </span>
          </div>
          <v-row class="mt-2 mx-0">
            <v-col cols="6">
              <div style="border: 1px solid #ccc; border-radius: 4px; margin: -1px;">
                <div v-show="! selectedViewUsers.length" style="padding: 30px; text-align: center">{{ $t('CUSTOM_VIEWS.NO_USER_SELECTED') }}</div>
                <div v-show="selectedViewUsers.length" style="height: 254px; overflow-y: auto">
                  <div v-for="user in selectedViewUsers" :key="user.id" :title="user | username"
                       :style="{ opacity: user_disabled[user.id] ? '.5' : '' }" class="company-user">
                    <img :src="user.avatar">
                    <span class="text-muted">{{ user | username('short') }}</span>&nbsp;
                    <span class="pull-right" style="line-height: 32px;">
                      <i title="Retirer" class="fa fa-times fa-fw fa-lg red pointer" @click="! user_disabled[user.id] && removeViewUser(user)"></i>
                    </span>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="6">
              <div>
                <input v-model="filterCompanyUsers" :placeholder="`${$t('GLOBAL.SEARCH')}...`"
                       class="form-control" style="border-bottom-left-radius: 0; border-bottom-right-radius: 0">
                <div style="height: 220px; overflow-y: auto">
                  <div v-for="companyUser in notInViewUsers" :key="companyUser.id" :title="companyUser | username" class="company-user pointer"
                       @click="addViewUser(companyUser)">
                    <img :src="companyUser.avatar">
                    <span class="text-muted">{{ companyUser | username('short') }}</span>
                    <span class="pull-right">
                      <i class="fa fa-plus-circle primary--text" style="font-size: 24px; line-height: 32px;"></i>
                    </span>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ViewsLanesAcr from './ViewsLanesAcr';
  import ViewsColorsAcr from './ViewsColorsAcr';

  export default {
    components: {
      ViewsLanesAcr,
      ViewsColorsAcr,
    },
    props: {
      planning: { type: Object, required: true },
    },
    data() {
      return {
        views: null,
        selected_view: null,
        filterCompanyUsers: '',
        loading: { inprogress: false },
        saving: {},
        savingUsers: {},
        copying: { success: false, error: false },
        user_disabled: {},
        configTabsIndex: 0,
        configTabsTitles: { lanes: this.$t('CUSTOM_VIEWS.LANES'), colors: this.$t('CUSTOM_VIEWS.COLORS'), consultcolors: this.$t('CUSTOM_VIEWS.COLORS_CONSULT') },
      };
    },
    computed: {
      configTabs() {
        const config = this.$store.getters['users/accessRight/config'].views;
        if (config == 'lrUniversite') return ['colors'];
        if (config == 'lanes+colors') return ['lanes', 'colors'];
        if (config == 'lanes+colors+consultcolors') return ['lanes', 'colors', 'consultcolors'];
        return ['lanes', 'colors'];
      },
      viewUrl() {
        if (! this.selected_view) return '';
        return `${window.location.protocol}//${window.location.host}${window.location.pathname}#/planningview/${this.selected_view.id}/${window.slugify(this.selected_view.title || this.$t('CUSTOM_VIEWS.NEW_VIEW'))}`;
      },
      viewReadonlyRoot() {
        if (! this.selected_view) return '';
        return `${this.viewUrl}?viewrotoken=`;
      },
      selectedViewUsers() {
        if (! this.selected_view) return [];
        return this.selected_view.users.slice().sort((a, b) => ((a.firstname + a.lastname) < (b.firstname + b.lastname) ? -1 : 1));
      },
      notInViewUsers() {
        let notInViewUsers = this.$store.getters['users/getCompanyUsers'];
        if (this.selected_view) notInViewUsers = notInViewUsers.filter(user => ! this.selected_view.users.find(item => item.id == user.id));
        if (this.filterCompanyUsers) notInViewUsers = notInViewUsers.filter(user => user.firstname.indexOf(this.filterCompanyUsers) > -1 || user.lastname.indexOf(this.filterCompanyUsers) > -1);
        return notInViewUsers.slice().sort((a, b) => ((a.firstname + a.lastname) < (b.firstname + b.lastname) ? -1 : 1));
      },
    },
    watch: {
      selected_view() {
        if (! this.selected_view) return;
        if (! this.selected_view.users) this.$set(this.selected_view, 'users', []);
        if (! this.selected_view.acr || Array.isArray(this.selected_view.acr)) this.$set(this.selected_view, 'acr', {});
      },
    },
    created() {
      this.initLoad();
    },
    methods: {
      initLoad() {
        this.loading.inprogress = true;
        return window.apiSrv.call(`plannings/${this.planning.id}/views`, 'index').then((response) => {
          this.views = (response && response.data && response.data.views || []).sort((a, b) => (a.title < b.title ? -1 : 1));
          this.views.push({ title: `${this.planning.meta && this.planning.meta.title ? `${this.planning.meta.title} - ` : ''}${this.$t('CUSTOM_VIEWS.NEW_VIEW')}`, acr: {}, users: [] });
          this.selected_view = this.views.reduce((a, b) => (b.updated_at && moment(b.updated_at).isAfter(a.updated_at) ? b : a), this.views[0]);
          this.loading.inprogress = false;
        });
      },
      updateView({ onSuccess, onError } = {}) {
        const view = this.selected_view;
        if (! view.id) return Promise.reject(new Error("unsaved view"));
        this.saving = { inprogress: true, success: false, error: "" };
        return window.apiSrv.call('views', 'update', view).then(() => {
          this.saving.inprogress = false;
          this.saving.success = true;
          if (onSuccess) onSuccess();
        }).catch((error) => {
          this.saving.inprogress = false;
          this.saving.error = error;
          if (onError) onError(error);
          return Promise.reject(error);
        });
      },
      updateViewDebounced: _.debounce(function (params) {
        this.updateView(params);
      }),
      deleteView(view) {
        if (! view.id) return Promise.reject(new Error("unsaved view"));
        return window.apiSrv.call('views', 'destroy', view);
      },
      updateViewUser(view, user) {
        if (! view.id) return Promise.reject(new Error("unsaved view"));
        return window.apiSrv.call(`views/${view.id}/user/${user.id}`, 'store', user);
      },
      deleteViewUser(view, user) {
        if (! view.id) return Promise.reject(new Error("unsaved view"));
        return window.apiSrv.call(`views/${view.id}/user`, 'destroy', user);
      },
      copyToClipboard() {
        const input = $("#inputViewReadOnlyLink");
        this.copying = { success: false, error: false };
        try {
          input.select();
          document.execCommand('selectAll');
          document.execCommand('copy');
          this.copying.success = true;
          setTimeout(() => { this.copying.success = false; }, 3000);
        } catch (e) {
          this.copying.error = true;
        }
      },
      createView() {
        this.loading.inprogress = true;
        this.saving = {};
        this.savingUsers = {};
        this.selected_view.planning_id = this.planning.id;
        window.apiSrv.call('views', 'store', this.selected_view).then(() => {
          this.initLoad();
        }).catch((message) => {
          this.loading.inprogress = false;
          if (message) this.$store.dispatch('ui/msgbox/open', { title: "Error : View was not saved", body: message });
        });
      },
      removeView() {
        this.$store.dispatch('ui/msgbox/open', {
          title: "CUSTOM_VIEWS.CONFIRM_DELETE_VIEW",
          body: "CUSTOM_VIEWS.CANNOT_UNDO",
          buttons: { ok: "GLOBAL.OK", cancel: "GLOBAL.CANCEL" },
        }).then(() => {
          this.loading.inprogress = true;
          this.deleteView(this.selected_view).then(() => {
            this.initLoad();
          }).catch((message) => {
            this.loading.inprogress = false;
            if (message) this.$store.dispatch('ui/msgbox/open', { title: "Error : View was not deleted", body: message });
          });
        }).catch(() => {});
      },
      addViewUser(user) {
        this.selected_view.users.push(user);
        if (! this.selected_view.id) return;
        this.$set(this.user_disabled, user.id, true);
        this.savingUsers = { inprogress: true, success: false, error: "" };
        this.updateViewUser(this.selected_view, user).then(() => {
          this.savingUsers.success = true;
        }).catch((message) => {
          const index = this.selected_view.users.indexOf(user);
          if (index > -1) this.selected_view.users.splice(index, 1);
          this.savingUsers.error = message;
        }).finally(() => {
          this.user_disabled[user.id] = false;
          this.savingUsers.inprogress = false;
        });
      },
      removeViewUser(user) {
        if (! this.selected_view.id) {
          const index = this.selected_view.users.indexOf(user);
          if (index > -1) this.selected_view.users.splice(index, 1);
          return;
        }
        this.$set(this.user_disabled, user.id, true);
        this.savingUsers = { inprogress: true, success: false, error: "" };
        this.deleteViewUser(this.selected_view, user).then(() => {
          const index = this.selected_view.users.indexOf(user);
          if (index > -1) this.selected_view.users.splice(index, 1);
          this.savingUsers.success = true;
        }).catch((message) => {
          this.savingUsers.error = message;
        }).finally(() => {
          this.user_disabled[user.id] = false;
          this.savingUsers.inprogress = false;
        });
      },
    },
  };
</script>
