import io from 'socket.io-client';
import store from '@/store';

const socket = io('https://bubbleplan-socket.inli.fr', {
  autoConnect: false,
});

store.state.users.userPromise.then(() => {
  socket.io.opts.query = {
    token: store.state.users.user.jwt,
  };
  socket.open();
});

socket.on("welcome", (message) => {
  console.log(message);
});

export default socket;
