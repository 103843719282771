import { mapActions } from 'vuex';
import PlanningElement from '@/models/PlanningElement';

let lastPlaceholderMove = 0;
let livedragThrottledTimeout;

function movePlaceholder(planning, ui) {
  lastPlaceholderMove = Date.now();
  const placeholderEl = planning.elements.find(el => el.id == ui.helper.data('placeholder_id'));
  if (placeholderEl) placeholderEl.move(placeholderEl.getLaneId(), ui.position.left);
}

const livedragThrottled = _.throttle((planning, ui) => {
  if (livedragThrottledTimeout) clearTimeout(livedragThrottledTimeout);
  if (Date.now() - lastPlaceholderMove < 3000) {
    livedragThrottledTimeout = setTimeout(() => movePlaceholder(planning, ui));
    return;
  }
  movePlaceholder(planning, ui);
}, 150);

export default {
  computed: {
    selection() { return this.$store.state.selection; },
  },
  methods: {
    draggableConfig(el) {
      if (! this.isDynamic(el)) return { disabled: true };
      const vm = this;
      return {
        disabled: el.isType('macro') || el.getIsLocked() || (this.planning.config.lockDragAndDrop && this.planning.meta.access_right != 'admin'),
        cursor: 'move',
        revert: 'invalid',
        cancel: '.element-menu',
        delay: 0,
        helper: 'clone',
        start(event, ui) {
          vm.dragStart(event, ui, el);
        },
        drag(event, ui) {
          vm.dragSelection(event, ui, el);
        },
        stop(event, ui) {
          vm.dragStop(event, ui, el);
          const selection = ui.helper.data('ui-drag-selection');
          if (selection && selection.length) {
            selection.forEach((selectedId) => { // force refresh of css prop changed by jqueryui
              const selectedEl = vm.planning.elements.find(item => item.id == selectedId);
              if (selectedEl) {
                selectedEl.xposition--;
                selectedEl.yposition--;
                vm.elementStyle(el);
                vm.$nextTick(() => {
                  selectedEl.update();
                });
              }
            });
          }
        },
      };
    },

    dragStart(event, ui, el) {
      this.startChangingElement();
      ui.helper.addClass('is-dragging');
      ui.helper.data('id', el.id);
      ui.helper.data('ui-drag-prevPosition', ui.originalPosition);

      const notLockedSelection = this.selection.filter((selectedId) => {
        const selectedEl = this.planning.elements.find(item => item.id == selectedId);
        return selectedEl && ! selectedEl.getIsLocked();
      });
      if (notLockedSelection.length != this.selection.length) this.$store.dispatch('selection/resetSelection', notLockedSelection);
      if (this.selection.indexOf(el.id) == -1) this.$store.dispatch('selection/resetSelection', [el.id]);
      ui.helper.data('ui-drag-selection', angular.copy(this.selection));
      if (el.isType('task')) this.setSnapBoundaries(ui);

      const dragPlaceholders = this.planning.elements.filter(item => item.isDragPlaceholder);
      if (dragPlaceholders.length) this.deleteElements({ els: dragPlaceholders, silent: true });

      ui.helper.data('livedrag-active', this.selection.length <= 1); // only when dragging 1 element
      if (ui.helper.data('livedrag-active')) {
        const placeholderEl = new PlanningElement(this.planning, el); // create placeholder synchronously
        placeholderEl.setDependencies(null);
        placeholderEl.id = this.$store.getters['planning/elements/nextId'];
        this.$store.commit('planning/elements/add', placeholderEl);
        placeholderEl.isDragPlaceholder = true;
        el.visible = false;
        ui.helper.data('placeholder_id', placeholderEl.id);
      } else {
        $(`#el${el.id}`).css('left', '-1000px');
      }
    },

    dragSelection(event, ui, el) {
      if (el.isType('task')) this.snapToBoundaries(ui);

      if (ui.helper.data('livedrag-active')) {
        livedragThrottled(this.planning, ui);
      }

      if (this.selection.length <= 1) return;

      // selection drag
      const prevPosition = ui.helper.data('ui-drag-prevPosition');
      const newPosition = ui.position;
      const offsetleft = newPosition.left - prevPosition.left;
      const offsettop = newPosition.top - prevPosition.top;

      this.selection.forEach((id) => {
        const $selectedElement = $(`#el${id}`);
        if ($selectedElement && id != el.id) {
          $selectedElement.css('left', `+=${offsetleft}`);
          $selectedElement.css('top', `+=${offsettop}`);
        }
      });
      ui.helper.data('ui-drag-prevPosition', newPosition);
      this.$store.state.ui.planning.doNotUpdateSelection = true;
    },

    dragStop(event, ui) {
      setTimeout(() => { ui.helper.removeClass('is-dragging'); }, 300);
      const dragPlaceholders = this.planning.elements.filter(item => item.isDragPlaceholder);
      if (dragPlaceholders.length) this.deleteElements({ els: dragPlaceholders, silent: true });
    },

    ...mapActions('planning/elements', ['setSnapBoundaries', 'snapToBoundaries', 'deleteElements']),
  },
};
