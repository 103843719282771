import { loadRemoteScript } from './helpers';

const appId = "AIzaSyA1FLJxeWbOsnbC9UQAnzbnpQZoSGXndYo";
const scopes = ['https://www.googleapis.com/auth/calendar', 'https://www.googleapis.com/auth/drive'];
const discoveryDocs = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest", "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"];

function init() {
  return loadRemoteScript('https://apis.google.com/js/api.js', 'lib-gapi').then(() => {
    return new Promise((resolve) => {
      const { gapi } = window;
      gapi.load('client:auth2:picker', () => {
        gapi.client.init({
          apiKey: appId,
          discoveryDocs,
          clientId: '503703186697-os33d1hm69s9a714lk79enkds4atn7ts.apps.googleusercontent.com',
          scope: scopes.join(" "),
        }).then(() => {
          if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
            gapi.auth2.getAuthInstance().signOut();
          }
        }).catch(console.log)
        .then(() => {
          resolve();
        });
      });
    });
  });
}

function signInThen(cb) {
  if (window.gapi.auth2.getAuthInstance().isSignedIn.get()) {
    cb();
  } else {
    window.gapi.auth2.getAuthInstance().signIn().then(cb, console.log);
  }
}

/** ******** */
/*  DRIVE  */
/** ******** */
async function loadPicker(cb) {
  await init();
  const { gapi } = window;
  gapi.load('picker', () => {
    signInThen(() => {
      const { google } = window;
      const view = new google.picker.DocsView().setIncludeFolders(true);
      const picker = (new google.picker.PickerBuilder()).enableFeature(google.picker.Feature.NAV_HIDDEN)
        .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
        .enableFeature(google.picker.Feature.SUPPORT_TEAM_DRIVES)
        .setAppId(appId)
        .setOAuthToken(gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token)
        .addView(view)
        .addView(new google.picker.DocsUploadView())
        .setCallback((data) => {
          if (data.action == google.picker.Action.PICKED) {
            const files = [];
            (data.docs || []).forEach((file) => {
              files.push({
                type: "googledrive",
                url: file.url,
                name: file.name,
                icon: file.iconUrl,
              });
              gapi.client.drive.permissions.create({ fileId: file.id, role: 'reader', type: 'anyone' }).then(console.log);// share file
            });
            if (cb) cb(files);
          }
        })
        .build();
      picker.setVisible(true);
    });
  });
}

export default {
  drive: {
    loadPicker,
  },
};
