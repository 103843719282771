export default {
  methods: {
    filterProjectByCompany(project) {
      if (this.filters.company == 'mine' && project.company_id && project.company_id != this.me?.company_id) return false;
      if (this.filters.company == 'all' && this.filters.company_id && project.company_id != this.filters.company_id) return false;
      return true;
    },
    filterProjectByCategory(project) {
      if (! this.filters.categories || ! this.filters.categories.length) return true;
      const projectCategory = new Set(project.category && project.category.split(','));
      return this.filters.categories.some(tag => projectCategory.has(tag));
    },
    filterProjectByTitle(project) {
      return (project.title || "").toLowerCase().indexOf((this.filters.title || "").toLowerCase()) > -1;
    },
    filterProjectByUser(project) {
      if (! this.filters.user) return true;
      return project.owner_id == this.filters.user.id;
    },
    projectLink(project, isConsult) {
      if (this.archived) return '';
      return `/${(isConsult ? 'viewer/' : 'planning/') + project.id}/${window.slugify(project.title || "New project")}`;
    },
    translateApiText(text) {
      return text && text.substr(0, 4) == "API." ? this.$t(text) : text;
    },
  },
  computed: {
    me() { return this.$store.state.users.user; },
  },
};
