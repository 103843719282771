<template>
  <div v-if="planning.visibleTimeline" :class="{ 'single-timeline': ! planning.visibleTimeline.subtimelinecols.length }" class="lane-header-column">
    <template v-for="lane in planning.lanes">
      <v-hover :key="lane.id">
        <template #default="{ hover: hoverLaneLabel }">
          <div :class="{ 'sublane-header-subproject': lane.project_id }"
               :style="{ height: `${lane.height}px`, 'border-color': lane.project_id ? `${lane.getMainColor() || planning.config.designcolor.main} white` : null }"
               class="sublane-header">
            <template v-if="lane.project_id">
              <template v-if="! lane.hidden && subLanesLoaded[lane.id]">
                <div v-for="(sublane, sublaneIndex) in sublanes[lane.id]" v-if="visibleSublanes[lane.id][sublaneIndex].selected" :id="`laneheader${sublane.id}`" :key="sublane.id"
                     :style="{ height: `${sublane.height}px`, background: sublanesColor[sublane.id], color: sublane.getMainColorContrastedTextColor() || 'white' }"
                     class="lane-header">
                  <div :style="{ width: laneLevelWidth * sublane.level + 'px' }" class="lane-level-gap"></div>
                  <div class="lane-label">
                    <div :style="lane.labelStyle" class="pre" style="width: 100%; word-wrap: break-word;">{{ sublane.label }}</div>
                    <router-link v-if="sublane.project_id" :to="`/planning/${sublane.project_id}`">
                      <div class="subprojects-header-chip">
                        {{ $t('PLANNING.SUBPROJECT') }}
                      </div>
                    </router-link>
                  </div>
                </div>

                <v-menu v-model="laneDropdownIsOpened[lane.id]" :close-on-content-click="false" offset-y max-width="250">
                  <template #activator="{ on }">
                    <v-fade-transition>
                      <v-btn v-show="hoverLaneLabel || laneDropdownIsOpened[lane.id]" small depressed
                             color="rgba(0,0,0,0.6)" dark style="position: absolute; top:0; left: 0; width: 100%;"
                             v-on="on">
                        {{ $t('PLANNING.SELECT_LANES') }} <v-icon small class="ml-1">fas fa-caret-down</v-icon>
                      </v-btn>
                    </v-fade-transition>
                  </template>
                  <v-list dense>
                    <v-list-item v-for="visibleSublane in visibleSublanes[lane.id]" :key="visibleSublane.id" class="nobr">
                      <v-checkbox v-model="visibleSublane.selected" hide-details class="ma-0 pa-0" @change="toggleVisibleSublane(lane, visibleSublane)">
                        <template #label>
                          <span style="font-size: 14px">{{ visibleSublane.label }}</span>
                        </template>
                      </v-checkbox>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <div v-else-if="lane.hidden" class="text-center">
                <router-link :to="`/planning/${lane.project_id}`">
                  <div class="subprojects-header-chip">
                    {{ $t('PLANNING.SUBPROJECT') }}
                  </div>
                </router-link>
              </div>
              <div v-else class="text-muted" style="height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                <i class="fas fa-spinner fa-spin fa-2x fa-fw"></i>
              </div>
            </template>
          </div>
        </template>
      </v-hover>
    </template>
  </div>
</template>

<style>
  .sublane-header {
    position: relative;
    background: #eee;
    border-style: solid;
    border-width: 1px;
    border-right: none;
    border-color: white;
    border-left: 2px solid white;
    z-index: 200; /* for lane dropdown menu */
  }

  .sublane-header.sublane-header-subproject {
    border-top-width: 2px;
    border-bottom-width: 2px;
  }

  .sublane-header.sublane-header-subproject .lane-header:first-child .lane-label {
    border-top-width: 2px;
  }
  .sublane-header.sublane-header-subproject .lane-header:last-child .lane-label {
    border-bottom-width: 2px;
  }
</style>

<script>
  import constants from '@/js/constants.js';
  import { mapGetters, mapActions } from 'vuex';

  export default {
    props: {
      planning: { type: Object, required: true },
    },
    data() {
      return {
        laneLevelWidth: constants.laneLevelWidth,
        laneDropdownIsOpened: {},
      };
    },
    computed: {
      sublanes() {
        return this.planning.lanes.reduce((sublanes, lane) => {
          if (lane.project_id) {
            const subproject = this.getSubprojectByLaneId()(lane.id);
            sublanes[lane.id] = subproject && subproject.lanes;
          }
          return sublanes;
        }, {});
      },
      sublanesColor() {
        return Object.keys(this.sublanes).reduce((colors, laneId) => {
          if (this.sublanes[laneId]) {
            this.sublanes[laneId].forEach((sublane) => { colors[sublane.id] = sublane.getMainColor() || this.planning.config.designcolor.main; });
          }
          return colors;
        }, {});
      },
      visibleSublanes() {
        return Object.keys(this.sublanes).reduce((acc, laneId) => {
          const lane = this.planning.lanes.find(item => item.id == laneId);
          if (! this.sublanes[laneId]) return acc;
          acc[laneId] = this.sublanes[laneId].map((subprojectLane) => {
            const subprojectLaneOriginalId = parseInt(subprojectLane.id, 10);
            return {
              id: subprojectLane.id,
              label: subprojectLane.label,
              selected: ! (lane.project_hidden_sublanes && lane.project_hidden_sublanes.includes(subprojectLaneOriginalId)),
            };
          });
          return acc;
        }, {});
      },
      subLanesLoaded() {
        return this.planning.lanes.reduce((acc, lane) => {
          acc[lane.id] = (this.sublanes[lane.id] || []).find(item => item) && true || false;
          return acc;
        }, {});
      },
    },
    methods: {
      toggleVisibleSublane(lane, visibleSublane) {
        this.startChangingContent(lane);
        this.toggleHiddenSublane({ lane, sublane: this.sublanes[lane.id].find(sublane => sublane.id == visibleSublane.id) });
        this.changingContent(lane);
      },
      ...mapGetters('subprojects', ['getSubprojectByLaneId']),
      ...mapActions('subprojects', ['toggleHiddenSublane']),
      ...mapActions('planning/lanes', ['startChangingContent', 'changingContent']),
    },
  };
</script>
