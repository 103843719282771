<template>
  <section class="modal-with-tabs">
    <div class="modal-with-tabs-header config-window">
      <div class="pt-6 px-4" style="width: 100%; background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,.5) 100%);">
        <v-tabs v-model="openedTabIndex" background-color="transparent" dark slider-color="primary" style="margin-bottom: -2px;">
          <v-tab v-if="tabsContain('projectsheet')" href="#projectsheet">{{ $t('PROJECT_SHEET.PROJECT_SHEET') }}</v-tab>
          <v-tab v-if="tabsContain('files')" href="#files">{{ $t('PROJECT_SHEET.FILES') }}</v-tab>
          <v-tab v-if="tabsContain('timeline')" href="#timeline">{{ $t('CONFIG.TIMELINE') }}</v-tab>
          <v-tab v-if="tabsContain('lanes')" href="#lanes">{{ $t('CONFIG.LANES') }}</v-tab>
          <v-tab v-if="tabsContain('design')" href="#design">{{ $t('CONFIG.DESIGN') }}</v-tab>
          <v-tab v-for="customTab in customTabs" :key="customTab.id" :href="`#${customTab.id}`">{{ customTab.label }}</v-tab>
        </v-tabs>
      </div>
    </div>
    <div class="modal-with-tabs-body">
      <v-tabs-items v-model="openedTabIndex">
        <!--    TAB PROJECT SHEET -->
        <v-tab-item v-if="tabsContain('projectsheet')" value="projectsheet">
          <project-sheet :planning="planning" @update="updateProjectSheet"></project-sheet>
        </v-tab-item>

        <!--    TAB CONFIG FILES -->
        <v-tab-item v-if="tabsContain('files')" value="files">
          <config-files :planning="planning" @open-element="openElement"></config-files>
        </v-tab-item>

        <!--    TAB MILESTONES SYNTHESIS -->
        <v-tab-item v-for="customTab in customTabs" :key="customTab.id" :value="customTab.id">
          <client-custom-component :name="customTab.component" :planning="planning" :custom-tab="customTab"></client-custom-component>
        </v-tab-item>

        <!--    TAB CONFIG TIMELINE -->
        <v-tab-item v-if="tabsContain('timeline')" value="timeline">
          <config-timeline :timeline="planning.timeline" @update="updateTimeline"></config-timeline>
        </v-tab-item>

        <!--    TAB CONFIG LANES -->
        <v-tab-item v-if="tabsContain('lanes')" value="lanes">
          <config-lanes :planning="planning" @update="updateLanes"></config-lanes>
        </v-tab-item>

        <!--    TAB CONFIG DESIGN -->
        <v-tab-item v-if="tabsContain('design')" value="design">
          <config-design :planning="planning" @update="applyDesign"></config-design>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <div class="modal-with-tabs-footer"></div>
  </section>
</template>

<style>
  .modal-with-tabs-header.config-window {
    background-image: url('../../img/project-config.jpg');
    background-position: center center;
  }
</style>

<script>
  import { mapActions } from 'vuex';
  import clientConfig from '@/client_customs/config';
  import ProjectSheet from '@/components/ProjectSheet/ProjectSheet';
  import ConfigFiles from './ConfigFiles';
  import ConfigTimeline from './ConfigTimeline';
  import ConfigDesign from './ConfigDesign';
  import ConfigLanes from './ConfigLanes';

  export default {
    components: {
      ProjectSheet,
      ConfigFiles,
      ConfigTimeline,
      ConfigDesign,
      ConfigLanes,
    },
    props: {
      planning: { type: Object, required: true },
      tabs: { type: Array, default: null },
    },
    data() {
      return {
        openedTabIndex: null,
        customTabs: ! this.tabs && clientConfig.projectsheet && clientConfig.projectsheet.customTabs,
      };
    },
    computed: {
      availableTabs() {
        return this.tabs || ['projectsheet', 'files', 'budget'];
      },
    },
    watch: {
      openedTabIndex() {
        const optionName = this.tabs ? 'option.configTab' : 'option.projectsheetTab';
        window.sessionStorageWrapper.setItem(optionName, this.openedTabIndex);
      },
    },
    created() {
      const optionName = this.tabs ? 'option.configTab' : 'option.projectsheetTab';
      const configTab = window.sessionStorageWrapper.getItem(optionName);
      if (configTab && this.availableTabs.includes(configTab)) this.openedTabIndex = configTab;
    },
    methods: {
      tabsContain(tabName) {
        return this.availableTabs.includes(tabName);
      },
      close() {
        this.$emit('close');
      },
      updateProjectSheet(values) {
        _.extend(this.planning.projectsheet, values);
        this.planning.saveAdmin({ projectsheet: this.planning.projectsheet });
      },
      updateTimeline(values) {
        this.$store.dispatch('planning/timeline/updateTimeline', values);
        this.close();
      },
      applyDesign(values) {
        this.startChangingConfig();
        this.planning.config.setDesign(values.design, values.designcolor, values.logourl);
        this.changingConfig();
        this.close();
      },
      updateLanes(values) {
        this.updateAllLanes({
          lanesToAdd: values.addedLanes, // add
          lanesToDelete: values.deletedLanes, // delete
          lanesToUpdate: values.updatedLanes, // update
          lanesIdsOrder: values.configLanesIds, // sort
        });
        this.close();
      },
      openElement(el) {
        this.close();
        this.$emit('open-element', el);
      },
      ...mapActions('planning/config', ['startChangingConfig', 'changingConfig']),
      ...mapActions('planning/lanes', ['updateAllLanes']),
    },
  };
</script>
