<template>
  <v-menu v-model="dropdownIsOpen" offset-y nudge-bottom="8">
    <template #activator="{ on }">
      <v-btn depressed rounded small class="mx-1 tour-help" style="height: 32px; min-width: 0" v-on="on">
        <v-icon color="info" small>fas fa-question-circle</v-icon>
        <span class="hidden-md-and-down ml-2">{{ $t('NAV.HELP') }}</span>
      </v-btn>

      <!--  MODALS -->
      <modal v-if="helpTipsIsOpened" movable @close="closeModals">
        <help-tips @close="closeModals"></help-tips>
      </modal>
      <modal v-if="helpVideosIsOpened" size="lg" movable @close="closeModals">
        <help-videos @close="closeModals"></help-videos>
      </modal>
    </template>
    <v-list dense>
      <v-list-item :href="`https://bubbleplan.net/auth.php?token=${api_token}&redirect=questions-reponses-logiciel-gestion-projet`" target="_blank">
        {{ $t('HELP.FAQ') }}
      </v-list-item>
      <v-list-item @click="openTips()">{{ $t('HELP.TIPS') }}</v-list-item>
      <v-list-item @click="openVideoTutos()">{{ $t('HELP.VIDEO_TUTOS') }}</v-list-item>
      <v-list-item target="_blank" href="https://bubbleplan.net/assets/files/Bubble Plan - Guide rapide d'utilisation.pdf">{{ $t('HELP.USER_GUIDE') }}</v-list-item>
      <v-divider></v-divider>
      <v-list-item v-if="config == 'planning'" @click="playTour(1)">{{ $t('HELP.REPLAY_TUTORIAL') }}</v-list-item>
      <v-list-item v-if="config == 'planning'" @click="playTour(2)">{{ $t('HELP.ADVANCED_TUTORIAL') }}</v-list-item>
      <v-divider v-if="config == 'planning'"></v-divider>
      <v-list-item @click="openNewVersionMsgBox">{{ $t('HELP.NEW_FEATURES') }}</v-list-item>
      <v-divider></v-divider>
      <v-list-item href="mailto:contact@bubbleplan.net" target="_blank">
        <div>
          <div>{{ $t('HELP.CONTACT_US') }}</div>
          <v-list-item-subtitle>contact@bubbleplan.net</v-list-item-subtitle>
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  import HelpTips from '@/components/Help/HelpTips.vue';
  import HelpVideos from '@/components/Help/HelpVideos.vue';

  export default {
    components: {
      HelpTips,
      HelpVideos,
    },
    props: {
      config: { type: String, default: '' }, // planning, projects, manage
    },
    data() {
      return {
        dropdownIsOpen: null,
        api_token: window.apiSrv.getAuthToken(),
      };
    },
    computed: {
      helpTipsIsOpened() {
        return this.$store.state.ui.navigation.openedModal == 'help-tips';
      },
      helpVideosIsOpened() {
        return this.$store.state.ui.navigation.openedModal == 'help-videos';
      },
    },
    methods: {
      openTips() {
        this.$store.commit('ui/navigation/setOpenedModal', 'help-tips');
      },
      openVideoTutos() {
        this.$store.commit('ui/navigation/setOpenedModal', 'help-videos');
      },
      closeModals() {
        this.$store.commit('ui/navigation/setOpenedModal', null);
      },
      playTour(level) {
        this.$router.replace({ query: { tour: level || 1 } });
        this.$router.go();
      },
      openNewVersionMsgBox() {
        this.$store.dispatch('ui/msgbox/open', { title: 'HELP.NEW_VERSION_TITLE', body: 'HELP.NEW_VERSION', buttons: { ok: 'HELP.NEW_VERSION_BUTTON' } });
      },
    },
  };
</script>
