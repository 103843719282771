<template>
  <component :is="name" v-bind="$attrs" v-on="$listeners"></component>
</template>

<script>
  import clientConfig from '@/client_customs/config';

  export default {
    components: {
      ...clientConfig.clientCustomComponents,
    },
    inheritAttrs: false,
    props: {
      name: { type: String, required: true },
    },
  };
</script>
