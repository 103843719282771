<template>
  <div>
    <div class="display-flex align-center mb-2">
      <div class="mr-2 text-center" style="flex: 0 0 24px;">
        <v-icon color="primary" small>fas fa-long-arrow-alt-right</v-icon>
      </div>
      <label for="progress" class="uppercase primary--text ma-0">{{ $t('ELEMENT-DETAIL.PROGRESS') }}</label>
      <v-spacer></v-spacer>
      <v-divider vertical class="mx-2"></v-divider>
      <v-btn :title="$t(el.getConfig('show-progress') ? 'ELEMENT-DETAIL.HIDE_ON_PLANNING' : 'ELEMENT-DETAIL.SHOW_ON_PLANNING')" icon small tabindex="-1"
             @click="el.getConfig()['show-progress'] = ! el.getConfig()['show-progress']">
        <v-icon small>{{ el.getConfig('show-progress') ? 'fa-eye' : 'fa-eye-slash' }}</v-icon>
      </v-btn>
    </div>
    <div class="ml-6 pl-2 display-flex align-center">
      <v-progress-linear :class="{ pointer : ! isConsult }" :value="progress" color="successgreen" height="16" rounded class="mr-2" style="flex: 1 1 auto;"
                         @click="! isConsult && clickProgressBar($event)">
        <div v-show="progress > 0" :style="{ 'margin-right': `${100 - progress}%` }"
             class="white--text small display-flex align-center justify-center" style="height: 100%; transition: all .3s">
          {{ progress }}%
        </div>
      </v-progress-linear>
      <v-text-field v-if="! isConsult" id="progress" v-model.number="progress" type="number" min="0" max="100" step="10" suffix="%" hide-details
                    class="ml-2 mt-0 pt-0 mb-2" style="flex: 0 1 75px">
      </v-text-field>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      el: { type: Object, required: true },
    },
    data() {
      return {
      };
    },
    computed: {
      isConsult() { return this.el.isType('macro'); },
      progress: {
        get() {
          return parseInt(this.el.getProgress(), 10) || 0;
        },
        set(newVal) {
          this.el.setProgress(newVal);
        },
      },
    },
    methods: {
      clickProgressBar(event) {
        const fullWidth = event.currentTarget.clientWidth;
        if (! fullWidth) return;
        this.el.setProgress(Math.min(Math.round(event.offsetX / fullWidth * 10) * 10, 100));
      },
    },
  };
</script>
