<template>
  <v-card>
    <v-card-title class="pl-0">
      <div class="display-flex justify-center align-center mx-2" style="flex: 0 0 32px">
        <v-icon color="primary" dense>fas fa-check-square</v-icon>
      </div>
      <span class="text-h6 primary--text">{{ $t('HOME.MY_ACTIONS') }}</span>
      <v-tooltip left max-width="200">
        <template #activator="{ on }">
          <v-icon color="info" class="ml-auto" v-on="on">far fa-question-circle</v-icon>
        </template>
        <span>{{ $t('HOME.ACTIONS_EXPLANATIONS') }}</span>
      </v-tooltip>
    </v-card-title>
    <div class="ml-12" style="border-bottom: 2px solid #0169aa;"></div>
    <v-card-text class="pl-12">
      <div v-if="! actions.length" class="muted--text">
        {{ $t('HOME.NO_ACTION_ACTIVITY') }}
      </div>
      <div v-if="actions.length">
        <div v-for="actionList in actionLists" v-show="filteredActions[actionList.status].length" :key="actionList.status">
          <div :class="`${actionList.color}--text`" class="ml-1 mb-1 pb-1 display-flex align-center" style="border-bottom-width: 1px; border-bottom-style: solid">
            <v-icon :class="actionList.icon" small
                    class="mr-1" style="color: inherit; flex: 0 0 28px">
            </v-icon>
            {{ $t(actionList.label) }}
          </div>
          <div style="margin-left: 1px">
            <ul>
              <li v-for="action in filteredActions[actionList.status]" :title="getActionMetaTitle(action)">
                <div :class="getActionIsDone(action) ? 'muted--text' : `${actionList.color}--text`"
                     class="link display-flex align-center">
                  <v-btn :disabled="! canEditAction(action)" icon x-small class="mx-1" style="color: inherit" @click="actionClick(action)">
                    <v-icon small>far {{ getActionIsDone(action) ? 'fa-check-square' : 'fa-square' }}</v-icon>
                  </v-btn>
                  <span class="ml-1">
                    <span :class="{ 'text-decoration-line-through': getActionIsDone(action) }">
                      <b>{{ getActionDueDate(action) | moment('mediumDateNoYear') }}</b>
                      &nbsp;{{ getActionTitle(action) }}
                    </span>
                    <span v-if="section = getActionSection(action)" class="ml-2 muted--text">
                      <v-icon small>{{ action.type == 'planning' ? 'fas fa-folder-open' : 'fas fa-users' }}</v-icon>
                      <router-link :to="section.url" style="color: inherit">
                        <i class="small">{{ section.title }}</i>
                      </router-link>
                    </span>
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  import Planning from '@/models/Planning';
  import PlanningElement from '@/models/PlanningElement';

  export default {
    props: {
      actions: { type: Array, required: true },
      dashboardElements: { type: Array, default: () => ([]) },
    },
    data() {
      return {
        actionLists: [
          { status: 'late', label: "HOME.LATE", color: 'errorred', icon: 'fas fa-exclamation-triangle' },
          { status: 'urgent', label: "HOME.URGENT", color: 'warningorange', icon: 'fas fa-clock' },
          { status: 'current', label: "HOME.CURRENT", color: 'primary', icon: 'fas fa-hourglass-half' },
        ],
        elements: {},
      };
    },
    computed: {
      filteredActions() {
        return this.actionLists.reduce((result, actionList) => {
          const actionStatus = actionList.status;
          result[actionStatus] = this.actions.filter(action => action.meta.status == actionStatus).sort((a, b) => (a.due_date < b.due_date ? -1 : 1));
          return result;
        }, {});
      },
    },
    created() {
      /*
        this.elements acts as the elements database shared by reference -> auto synchronize actions with elements in kanban
        this is required within actions in case 2 actions come from the same task : without, each action.element would overwrite the other on saving
      */
      const elements = this.dashboardElements.reduce((acc, item) => {
        acc[`p${item.project_id}e${parseInt(item.id, 10)}`] = item;
        return acc;
      }, {});
      const plannings = {};
      this.actions.forEach((action) => {
        if (action.type == 'meeting') return;
        const { project_id: projectId, project_title: projectTitle } = action.meta;
        if (elements[`p${projectId}e${action.element.id}`]) return;
        if (! plannings[projectId]) plannings[projectId] = new Planning({ id: projectId, meta: { title: projectTitle } });
        const planning = plannings[projectId];
        elements[`p${projectId}e${action.element.id}`] = new PlanningElement(planning, action.element);
      });
      this.elements = elements;
    },
    methods: {
      canEditAction(action) {
        if (action.type == 'meeting') return true;
        return action.meta.project_access_right != 'consult';
      },
      getActionElement(action) {
        if (action.type == 'meeting') return null;
        return this.elements[`p${action.meta.project_id}e${action.element.id}`];
      },
      getActionItem(action) {
        if (action.type == 'meeting') return action.item;
        const checklist = this.getActionElement(action).getChecklist() || [];
        return checklist[action.item_index];
      },
      getActionTitle(action) {
        const item = this.getActionItem(action);
        return item.title;
      },
      getActionDueDate(action) {
        return action.due_date;
      },
      getActionIsDone(action) {
        const item = this.getActionItem(action);
        return action.type == 'planning' ? item.checked : !! item.completed_at;
      },
      getActionMetaTitle(action) {
        if (action.type != 'planning') return '';
        return `↪ ${action.meta.lane_label || ''}\n${action.element.title || ''}`;
      },
      getActionSection(action) {
        if (action.type == 'planning') {
          const { project_id: projectId, project_title: projectTitle, project_access_right: projectAccessRight } = action.meta;
          const isConsult = projectAccessRight == 'consult';
          return {
            url: `/${isConsult ? 'viewer/' : 'planning/'}${projectId}/${window.slugify(projectTitle || "New project")}?open=actions`,
            title: action.meta.project_title,
          };
        }
        return null;
      },
      actionClick(action) {
        const item = this.getActionItem(action);
        if (action.type == 'planning') {
          const el = this.getActionElement(action);
          const oldStateParam = { checklist: angular.copy(el.getChecklist()), progress: el.getProgress() };
          item.checked = ! item.checked;
          // el.updateChecklistProgress();
          this.$emit('save-el', { el, props: ['checklist', 'progress'], oldStateParam });
        } else {
          this.$set(item, 'completed_at', item.completed_at ? null : new Date());
          window.apiSrv.call('v2/meetings/actions', 'update', { id: action.id, completed_at: item.completed_at }).then((result) => {
            window.notificationsSrv.callEvent('meeting.changeAction', result.data.action);
          });
        }
      },
    },
  };
</script>
