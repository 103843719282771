<template>
  <div>
    <navigation>
      <v-spacer></v-spacer>
    </navigation>

    <template v-if="isBusiness">
      <v-progress-linear v-if="! loading.complete" :value="loading.counter.toLoad && loading.counter.loaded ? loading.counter.loaded / loading.counter.toLoad * 100 : 2"
                         color="accent" rounded>
      </v-progress-linear>

      <personal-calendar-filters v-if="loading.complete" @update="updateRange()"></personal-calendar-filters>

      <v-card v-if="loading.complete" v-show="viewIsReady && eventsList.length" id="personal-calendar" class="white px-2 py-1 elevation-3">
        <!-- CALENDAR HEADER -->
        <v-sheet height="64">
          <v-toolbar flat color="white">
            <!-- Today Btn-->
            <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">{{ $t('PERSONAL_CALENDAR.TODAY') }}</v-btn>
            <!-- Next and previous btns -->
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small>far fa-chevron-left</v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small>far fa-chevron-right</v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">{{ $refs.calendar.title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-radio-group v-model="calendarFormat" row hide-details>
              <v-radio :label="$t('PERSONAL_CALENDAR.DAY')" value="day"></v-radio>
              <v-radio :label="$t('PERSONAL_CALENDAR.WEEK')" value="week"></v-radio>
              <v-radio :label="$t('PERSONAL_CALENDAR.MONTH')" value="month"></v-radio>
            </v-radio-group>
          </v-toolbar>
        </v-sheet>

        <!-- CALENDAR BODY -->
        <v-sheet>
          <v-calendar ref="calendar" v-model="focus" color="primary" :events="eventsList" :event-color="event => event.color"
                      :type="calendarFormat" :event-height="34" :first-interval="dayStartTime" :interval-count="dayHours" :locale="locale"
                      @click:event="showEvent" @click:more="viewDay" @click:date="viewDay"
                      @change="updateRange">
            <template #event="{ event }">
              <div :title="event.title" class="display-flex fill-height">
                <v-icon v-if="event.icon" :color="event.icon.color" :title="event.icon.label" small class="mx-1" style="height: 34px;"
                        @click="checkAction(event) && $event.stopPropagation()">
                  {{ event.icon.name }}
                </v-icon>
                <planning-element-view v-if="event.el" :el="event.el" :show-details-items="['synthesis']"
                                       disable-mini-element style="position: static; color: #333; flex-grow: 1">
                </planning-element-view>
                <div v-else :class="event.labelClass" class="pl-1 display-flex align-center" style="height: 100%; flex-grow: 1; background: rgba(0, 0, 0, .1)">
                  {{ event.name }}
                </div>
              </div>
            </template>
            <!-- Month -->
            <template #day="{ date }">
              <personal-calendar-add-button :date="date" @open-action="openedAction = $event">
                <!-- @open-holiday="openedHoliday = $event" -->
              </personal-calendar-add-button>
            </template>
            <!-- Week and day -->
            <template #day-header="{ date }">
              <personal-calendar-add-button :date="date" @open-action="openedAction = $event">
                <!-- @open-holiday="openedHoliday = $event" -->
              </personal-calendar-add-button>
            </template>
          </v-calendar>
        </v-sheet>
      </v-card>
      <div v-if="loading.complete && ! viewIsReady" class="text-center pa-4" style="font-size: 18px">
        <i class="fas fa-spinner fa-spin fa-2x fa-fw"></i>
      </div>
      <v-card v-if="viewIsReady && ! eventsList.length" class="py-6 px-12">
        <div>{{ $t('PERSONAL_CALENDAR.NO_DATA_FOUND') }}</div>
      </v-card>

      <!-- MODALS -->
      <modal v-if="openedElement" :backdrop="false" :target="`#el${openedElement.id}`" movable @close="closeElementDetails">
        <element-details :element="openedElement" @close="closeElementDetails($event)"></element-details>
      </modal>
      <modal v-if="openedColorPickerElement" :backdrop="false" :target="`#el${openedColorPickerElement.id}`"
             :size="openedColorPickerElement.isType('milestone') ? 'sm' : 'md'" movable
             @close="closeColorPicker">
        <color-picker :target="openedColorPickerElement" :planning="planning" @close="closeColorPicker"></color-picker>
      </modal>
      <modal v-if="openedAction" card @close="closeOpenedAction">
        <open-action :action="openedAction" @close="closeOpenedAction"></open-action>
      </modal>
      <!-- <modal v-if="openedHoliday" card @close="closeOpenedHoliday">
        <personal-calendar-holidays :holiday="openedHoliday" @close="closeOpenedHoliday"></personal-calendar-holidays>
      </modal> -->
    </template>

    <div v-if="userLoaded && ! isBusiness">{{ $t('PREMIUM.BUSINESS.DASHBOARD_IS_BUSINESS') }}</div>
  </div>
</template>

<style>
  #personal-calendar .element .task-icon, #personal-calendar .element .milestone-icon {
   display: none;
  }

  #personal-calendar .v-calendar-daily_head-day-label, #personal-calendar .v-calendar-weekly__day-label {
    cursor: unset;
  }
</style>

<script>
  import { mapState, mapGetters } from 'vuex';
  import Navigation from '@/components/Navigation/Navigation';
  import ElementDetails from '@/components/ElementDetails/ElementDetails';
  import ColorPicker from '@/components/ColorPicker/ColorPicker';
  import OpenAction from '@/components/Dashboards/Actions/OpenAction';
  import PlanningElementView from '@/components/Planning/PlanningElementView';
  import PersonalCalendarFilters from './PersonalCalendarFilters';
  // import PersonalCalendarHolidays from './PersonalCalendarHolidays';
  import PersonalCalendarAddButton from './PersonalCalendarAddButton';

  export default {
    components: {
      Navigation,
      ElementDetails,
      ColorPicker,
      OpenAction,
      PlanningElementView,
      PersonalCalendarFilters,
      // PersonalCalendarHolidays,
      PersonalCalendarAddButton,
    },

    data() {
      return {
        focus: '',
        calendarFormat: 'week',
        events: [],
        selectedOpen: false,
        selectedEvent: {},
        planning: null,
        viewIsReady: false,
        openedAction: null,
        // openedHoliday: null,
        // eventHolidays: {},
        // allHolidays: [],
        selectedDay: '',
        dayDefaultStartTime: 8, // day start is 08:00am
        dayDefaultEndTime: 18, // day end is 18:00pm
        defaultDayHours: 10,
        // frenchHolidays: [],
      };
    },
    computed: {
      // elements of the day
      dayElements() {
        if (! this.selectedDay) return [];
        const els = this.allPlanningElements.filter((el) => {
          const elStartTime = el.getStartTime();
          const elEndTime = el.getEndTime();
          if (elStartTime.format('YYYY-MM-DD') !== this.selectedDay) {
            if (! (elStartTime.isBefore(moment(this.selectedDay)) && elEndTime.isAfter(moment(this.selectedDay)))) {
              return false;
            }
          }
          return true;
        });
        return els;
      },
      minStartTime() {
        const elements = this.dayElements.filter(el => this.selectedDay == el.getStartTime().format('YYYY-MM-DD'));
        const elsScheduleStart = elements.filter((el) => {
          if (! el.getSchedule().start) return false;
          return true;
        });
        const elsScheduleStartMoments = elsScheduleStart.map(el => el.getSchedule().start);
        const minStartTime = moment.min(elsScheduleStartMoments).hour();
        return minStartTime;
      },
      maxEndTime() {
        const elements = this.dayElements.filter(el => this.selectedDay == el.getEndTime().format('YYYY-MM-DD'));
        const elsScheduleEnd = elements.filter((el) => {
          if (! el.getSchedule().end) return false;
          return true;
        });
        const elsScheduleEndMoments = elsScheduleEnd.map(el => el.getSchedule().end);
        const maxEndTime = moment.max(elsScheduleEndMoments).hour();
        return maxEndTime;
      },
      // Selected day start time
      dayStartTime() {
        if (! this.dayElements.length) return 0;
        if (this.minStartTime < this.dayDefaultStartTime) return this.minStartTime; // if hour of an element less than 08:00am
        return this.dayDefaultStartTime; // 08:00am
      },
      // Selected day hours
      dayHours() {
        if (! this.dayElements.length) return null;
        const elsScheduleStart = this.dayElements.filter((el) => {
          if (! el.getSchedule().start) return false;
          return true;
        });
        if (! elsScheduleStart.length) return null; // if no elements has a start hour

        let dayHours = this.defaultDayHours;
        if (this.dayStartTime < this.dayDefaultStartTime && this.maxEndTime > this.dayDefaultEndTime) { // if hour of an element less than 8:00am and more than 6:00pm
          const diffStartTime = this.dayDefaultStartTime - this.dayStartTime;
          const diffEndTime = this.maxEndTime - this.dayDefaultEndTime;
          dayHours += (diffStartTime + diffEndTime);
        } else if (this.dayStartTime < this.dayDefaultStartTime) { // if hour of an element less than 8:00am
          const diffStartTime = this.dayDefaultStartTime - this.dayStartTime;
          dayHours += diffStartTime;
        } else if (this.maxEndTime > this.dayDefaultEndTime) { // if hour of an element more than 6:00pm
          const diffEndTime = this.maxEndTime - this.dayDefaultEndTime;
          dayHours += diffEndTime;
        }
        return dayHours;
      },
      // selected plannings elements
      allPlanningElements() {
        const elements = [];
        this.projects.forEach((data) => {
          if (this.selectedProjects.indexOf(data.id) == -1) return;

          (data.elements || []).forEach((el) => {
            // Users Filter
            if (! this.useGroups) {
              if (! this.filterOptions.unassigned) {
                if (! el.getUsers()) return;
                const elUsers = el.getUsers().map(user => user.id || user.username);
                if (! elUsers.includes(this.selectedUser)) return;
              } else if (el.getUsers() !== null) {
                const elUsers = el.getUsers().map(user => user.id || user.username);
                if (! elUsers.includes(this.selectedUser)) return;
              }
            } else { // GroupUsers Filter
              if (! el.getUsers()) return;
              const elGroups = el.getUsers().map(group => group.group_id);
              if (! elGroups.includes(this.selectedGroup)) return;
            }
            // Display Filter
            if (! this.calendarDisplay.milestone && el.getType() === "milestone") return;
            if (! this.calendarDisplay.shorttask && el.getType() === "task") {
              if (el.getEndTime().diff(el.getStartTime(), 'days') <= 3) return;
            }
            if (! this.calendarDisplay.longtask && el.getType() === "task") {
              if (el.getEndTime().diff(el.getStartTime(), 'days') > 3) return;
            }

            this.$store.commit('multiprojects/formatElProjectData', { el, projectData: data });
            elements.push(el);
          });
        });
        return elements;
      },
      // actions from selected plannings
      planningActions() {
        return this.$store.getters['multiprojects/planningActions'].filter((action) => {
          // Display Filter
          if (! this.calendarDisplay.planningactions) return false;
          // Users Filter
          if (! this.useGroups) {
            if (! this.filterOptions.unassigned) {
              if (! action.getUserId()) return false;
              if (action.getUserId() !== this.selectedUser) return false;
            } else if (action.getGroupId() || action.getUserId() && (action.getUserId() !== this.selectedUser)) {
              return false;
            }
          } else { // GroupUsers Filter
            if (! action.getGroupId()) return false;
            if (action.getGroupId() !== this.selectedGroup) return false;
          }

          return this.selectedProjects.includes(action.getPlanningId());
        });
      },
      // actions from meetings
      meetingActions() {
        return this.$store.state.multiprojects.meetingActions.filter((action) => {
          // GroupUsers Filter
          if (this.useGroups) return false;
          // Display Filter
          if (! this.calendarDisplay.meetingactions) return false;
          // Users Filter
          if (! this.filterOptions.unassigned) {
            if (! action.getUserId()) return false;
            if (action.getUserId() !== this.selectedUser) return false;
          } else if (action.getUserId() && (action.getUserId() !== this.selectedUser)) {
            return false;
          }
          return true;
        });
      },
      // Holidays
      // holidaysEvents() {
      //   // Display Filter
      //   if (! this.calendarDisplay.holidays) return [];
      //   return this.allHolidays;
      // },
      selectedUser() {
        return this.selectedCompanyUsers.concat(this.selectedVirtualParticipants)[0];
      },
      selectedGroup() {
        return this.selectedCompanyGroups[0];
      },
      useGroups() {
        return this.isBusiness && !! (this.groupsOptions.useGroups && this.companyGroups);
      },
      companyGroups() {
        return this.$store.getters['users/groups/getCompanyGroups'];
      },
      allActions() {
        return this.meetingActions.concat(this.planningActions);
      },
      locale() {
        this.updateRange();
        return this.$store.state.lang.lang;
      },
      eventsList() { return this.events; },
      userLoaded() { return this.$store.state.users.user.id > 0; },
      isBusiness() { return this.$store.state.users.accessRight.isBusiness; },
      ...mapState('multiprojects', ['loading', 'projects', 'selectedProjects', 'filterOptions', 'selectedCompanyUsers', 'companyUsers',
                                    'selectedVirtualParticipants', 'selectedCompanyGroups', 'groupsOptions', 'calendarDisplay']),
      ...mapState('ui/planning', ['openedElement', 'openedColorPickerElement']),
      ...mapGetters('multiprojects', ['getOriginalPlanningEl']),
    },
    watch: {
      'loading.complete': function (newVal) {
        this.viewIsReady = !! newVal;
      },
      calendarFormat() {
        const personalCalendarOptions = JSON.stringify({ calendarFormat: this.calendarFormat });
        window.localStorageWrapper.setItem('personalCalendarOptions', personalCalendarOptions);
      },
    },
    created() {
      this.loading.complete = false;
      const personalCalendarOptions = window.safeParseJSON(window.localStorageWrapper.getItem('personalCalendarOptions'));
      if (personalCalendarOptions && personalCalendarOptions.calendarFormat) this.calendarFormat = personalCalendarOptions.calendarFormat;

      this.$store.state.users.userPromise.then((user) => {
        this.$store.commit('multiprojects/loadFilters', {
          dashboardName: this.$route.path.slice(1).replace(/\//g, '_'),
          groupsOptions: { useGroups: false },
          selectedCompanyUsers: [user.id],
        });
        Promise.all([this.$store.dispatch('multiprojects/load'), this.$store.dispatch('multiprojects/loadMeetingActions')]);
      });
      // French holidays (20 years in the past and 5 years in the future)
      // this.getFrenchHolidays();

      // Get holidays datas from LocalStorage
      // const savedHolidays = localStorage.getItem('holidays');
      // this.allHolidays = savedHolidays ? JSON.parse(savedHolidays) : [];
    },
    mounted() {
      if (this.$refs.calendar) this.$refs.calendar.checkChange();
    },
    methods: {
      // async getFrenchHolidays() {
      //   try {
      //     const res = await fetch("https://calendrier.api.gouv.fr/jours-feries/metropole.json");
      //     if (! res.ok) throw new Error(res.status);
      //     const response = await res.json();

      //     Object.entries(response).forEach(([date, dayName]) => {
      //       this.frenchHolidays.push({
      //         id: `${window.uuid()}.${window.uuid()}`,
      //         type: 'holiday',
      //         name: dayName,
      //         icon: { color: 'white', label: 'Action', name: 'fas fa-store-slash' },
      //         start: date,
      //         end: date,
      //         color: '#5B8930',
      //         title: 'Jour férié',
      //         uneditable: true,
      //       });
      //     });
      //   } catch (error) {
      //     // console.log(error);
      //   }
      // },
      // saveHolidays() {
      //   localStorage.setItem('holidays', JSON.stringify(this.allHolidays));
      // },
      // closeOpenedHoliday(reason, holidayInfo) {
      //   const index = this.allHolidays.findIndex(item => item == this.openedHoliday);
      //   if (reason == 'save') {
      //     if (index > -1) {
      //       this.allHolidays.splice(index, 1);
      //       this.allHolidays.splice(index, 0, holidayInfo);
      //     } else {
      //       this.allHolidays.push(holidayInfo);
      //     }
      //   } else if (reason == 'delete') {
      //     if (index > -1) this.allHolidays.splice(index, 1);
      //   }
      //   if (reason) this.saveHolidays();
      //   this.openedHoliday = null;
      // },
      viewDay({ date }) {
        this.focus = date;
        this.calendarFormat = 'day';
        this.selectedDay = date;
      },
      setToday() {
        this.focus = '';
        this.selectedDay = this.focus;
      },
      prev() {
        this.$refs.calendar.prev();
        this.selectedDay = this.focus;
      },
      next() {
        this.$refs.calendar.next();
        this.selectedDay = this.focus;
      },
      showEvent({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event;
          if (this.selectedEvent.type === 'action') {
            const action = this.allActions.find(element => element.id === this.selectedEvent.id);
            this.openAction(action);
          } else if (this.selectedEvent.type === 'element') {
            const el = this.allPlanningElements.find(element => element.id === this.selectedEvent.id);
            this.planning = el.getPlanning();
            this.elementClick(el);
          }
          // else if (! this.selectedEvent.uneditable) { // holidays
          //   this.openedHoliday = this.selectedEvent;
          // }
          setTimeout(() => { this.selectedOpen = true; }, 10);
        };
        if (this.selectedOpen) {
          this.selectedOpen = false;
          setTimeout(open, 10);
        } else {
          open();
        }
        nativeEvent.stopPropagation();
      },
      openAction(action) {
        if (! action) return;
        this.openedAction = action;
      },
      closeOpenedAction(reason) {
        if (reason == 'save') {
          // todo : handle case when save failed
          if (this.openedAction.type == 'meeting' && ! this.$store.state.multiprojects.meetingActions.includes(this.openedAction)) {
            this.$store.state.multiprojects.meetingActions.push(this.openedAction);
          }
        } else if (reason == 'delete') {
          // todo : handle case when delete failed
          if (this.openedAction.type == 'meeting') {
            const index = this.$store.state.multiprojects.meetingActions.findIndex(item => item == this.openedAction);
            if (index > -1) this.$store.state.multiprojects.meetingActions.splice(index, 1);
          }
        } else {
          this.openedAction.reset();
        }
        this.openedAction = null;
      },
      elementClick(el) {
        const originalPlanningEl = this.getOriginalPlanningEl(el);
        originalPlanningEl.dashboard_el = el;
        this.$store.commit('ui/planning/openElement', originalPlanningEl);
      },
      checkAction(event) {
        if (event.type != 'action') return false;
        const { action } = event;
        action.setChecked(! action.getChecked());
        action.save();
        return true;
      },
      deleteElement(el) {
        const { openedElement } = this.$store.state.ui.planning;
        if (openedElement && openedElement.id == el.id) this.$store.commit('ui/planning/openElement', null);
        this.$store.dispatch('ui/kanban/deleteElement', el);
      },
      saveEl(params) {
        this.$store.dispatch('ui/kanban/saveEl', params);
      },
      closeElementDetails(reason) {
        const { openedElement } = this.$store.state.ui.planning;
        if (! openedElement) return;
        if (reason == 'delete') {
          this.deleteElement(openedElement.dashboard_el);
        } else {
          this.saveEl({ el: openedElement.dashboard_el, newState: openedElement.getAll() });
          if (reason == 'select-color') {
            this.selectColor(openedElement.dashboard_el);
          }
        }
        this.$store.commit('ui/planning/openElement', null);
      },
      selectColor(el) {
        const originalPlanningEl = this.getOriginalPlanningEl(el);
        originalPlanningEl.dashboard_el = el;
        this.$store.commit('ui/planning/openColorPickerElement', originalPlanningEl);
      },
      closeColorPicker() {
        const { openedColorPickerElement } = this.$store.state.ui.planning;
        if (! openedColorPickerElement) return;
        this.saveEl({ el: openedColorPickerElement.dashboard_el, newState: openedColorPickerElement.getAll() });
        this.$store.commit('ui/planning/openColorPickerElement', null);
      },
      updateRange() {
        const events = [];
        // All elements
        this.allPlanningElements.forEach((el) => {
          const elStartTime = el.getStartTime();
          const elEndTime = el.getEndTime();

          let elStart = elStartTime.format('YYYY-MM-DD');
          let elEnd = elEndTime.format('YYYY-MM-DD');
          const schedule = el.getSchedule();
          if (schedule.start) {
            elStart = elStartTime.hours(schedule.start.hours()).minutes(schedule.start.minutes()).format('YYYY-MM-DD HH:mm');
            if (schedule.end) {
              elEnd = elEndTime.hours(schedule.end.hours()).minutes(schedule.end.minutes()).format('YYYY-MM-DD HH:mm');
            } else {
              elEnd = elEndTime.format('YYYY-MM-DD HH:mm');
            }
          }
          events.push({
            el,
            id: el.id,
            type: 'element',
            name: el.getTitle() || this.$t('PLANNING.NEW_BUBBLE'),
            icon: el.hasIcon() && el.getIcon() || null,
            start: elStart,
            end: elEnd,
            color: el.getType() === 'task' ? el.getSecondaryColor() : '',
            title: el.getPlanning().getTitle(),
          });
        });
        // All actions
        this.allActions.forEach((action) => {
          const endTime = action.getEndTime();
          if (! endTime) return;
          let iconColor = action.getCssClass();
          iconColor = { red: 'errorred', orange: 'warningorange', 'black--text': 'black', 'muted--text': 'muted' }[iconColor] || iconColor;
          events.push({
            action,
            id: action.id,
            type: 'action',
            name: action.getTitle() || this.$t('MEETING.NEW_ACTION'),
            icon: { color: iconColor, label: 'Action', name: action.getChecked() ? 'far fa-check-square' : 'far fa-square' },
            start: endTime.format('YYYY-MM-DD'),
            color: "#00000022",
            labelClass: action.getCssClass(),
            title: '',
          });
        });
        // this.events = events.concat(this.frenchHolidays.concat(this.holidaysEvents));
        this.events = events;
      },
    },
  };
</script>
