<template>
  <div>
    <div class="display-flex align-center mb-2">
      <div class="mr-2 text-center" style="flex: 0 0 24px;">
        <v-icon color="primary" small>fas fa-paperclip</v-icon>
      </div>
      <label for="attachLink" class="uppercase primary--text ma-0">{{ $t('ELEMENT-DETAIL.ATTACHMENTS') }}</label>
      <v-spacer></v-spacer>
      <v-divider vertical class="mx-2"></v-divider>
      <v-btn :title="$t(el.getConfig()['show-links'] ? 'ELEMENT-DETAIL.HIDE_ON_PLANNING' : 'ELEMENT-DETAIL.SHOW_ON_PLANNING')" icon small tabindex="-1"
             @click="el.getConfig()['show-links'] = ! el.getConfig()['show-links']">
        <v-icon small>{{ el.getConfig()['show-links'] ? 'fa-eye' : 'fa-eye-slash' }}</v-icon>
      </v-btn>
    </div>
    <!-- LINKS -->
    <div v-if="! isConsult" class="ml-6 pl-2">
      <div v-for="(link, linkIndex) in links" class="link-item display-flex align-center">
        <link-icon :link="link"></link-icon>
        <a :href="link.url" :style="{ 'vertical-align': link.icon ? 'middle' : '' }" class="text-ellipsis" style="margin-left: 4px;" target="_blank">
          {{ link.name || link.url }}
        </a>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn icon small outlined color="secondary" class="link-menu ml-auto" v-on="on" @click="editLinkName(link)">
              <v-icon small>far fa-edit</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('GLOBAL.RENAME') }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn icon small outlined color="errorred" class="link-menu ml-2" v-on="on" @click="removeLink(linkIndex)">
              <v-icon small>far fa-trash-alt</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('ELEMENT-DETAIL.REMOVE_LINK') }}</span>
        </v-tooltip>
      </div>
      <hr v-show="links.length" class="mx-auto my-4" style="border-top: 1px solid #ccc">
      <!--              FILE DROP ZONE-->
      <file-upload v-if="isPremium && ! linksConfig.hideFileUpload" ref="fileUpload" input-id="file-element-details" @uploaded="fileUploaded"></file-upload>
      <div v-if="! isPremium" class="file-drop-box">
        {{ $t('ELEMENT-DETAIL.DROP_FILES_HERE') }}
        <div><a :href="$t('PREMIUM.OFFERS_LINK')" target="_blank"><b>{{ $t('PREMIUM.PREMIUM_FEATURE') }}</b></a></div>
      </div>
      <!--              ADD LINK FORM-->
      <form class="display-flex align-center" @submit.prevent="addLink()">
        <v-text-field id="attachLink" v-model="linkurl" :label="$t('ELEMENT-DETAIL.ADD_LINK')" placeholder="http://..."></v-text-field>
        <v-btn :disabled="! linkurl" color="accent" small rounded type="submit">{{ $t('ELEMENT-DETAIL.ADD') }}</v-btn>
      </form>
      <div class="display-flex align-center">
        <!--            DROPBOX BUTTON-->
        <v-btn v-if="! linksConfig.hideDropbox" small class="pa-0 ma-0" style="flex: 1 1 30%; max-width: 100px" tabindex="-1">
          <div id="choose-from-dropbox"></div>
        </v-btn>
        <v-spacer></v-spacer>
        <div v-if="! linksConfig.hideGoogleDrive" style="flex: 1 1 30%">
          <v-btn small class="ma-0" style="width: 100%" @click="chooseFromGoogledrive">
            <img src="../../img/googledrive.png" style="max-width: 100px">
          </v-btn>
        </div>
        <v-spacer></v-spacer>
        <div v-if="! linksConfig.hideOneDrive" style="flex: 1 1 30%">
          <v-btn small class="ma-0" style="width: 100%" @click="chooseFromOnedrive">
            <img src="../../img/onedrive.png" style="max-width: 100px">
          </v-btn>
        </div>
      </div>
    </div>
    <div v-else class="ml-6 pl-2 mb-1">
      <div v-for="link in links" class="link-item display-flex align-center">
        <link-icon :link="link"></link-icon>
        <a :href="link.url" :style="{ 'vertical-align': link.icon ? 'middle' : '' }" class="text-ellipsis" style="margin-left: 4px;" target="_blank">
          {{ link.name || link.url }}
        </a>
      </div>
    </div>
  </div>
</template>

<style>
  .link-menu {
    visibility: hidden;
    opacity: 0;
    transition: opacity .3s;
  }
  .link-item:hover .link-menu {
    visibility: visible;
    opacity: 1;
  }
</style>

<script>
  import MicrosoftSrv from '@/components/Integrations/MicrosoftSrv';
  import GoogleSrv from '@/components/Integrations/GoogleSrv';
  import FileUpload from '@/components/Reusables/FileUpload';
  import LinkIcon from '@/components/Planning/partials/LinkIcon';

  export default {
    components: {
      FileUpload,
      LinkIcon,
    },
    props: {
      el: { type: Object, required: true },
    },
    data() {
      return {
        linkurl: "",
      };
    },
    computed: {
      isConsult() { return this.el.isType('macro'); },
      links() { return this.el.getLinks() || []; },
      linksConfig() {
        return this.$store.getters['users/accessRight/config'].links;
      },
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
    },
    mounted() {
      setTimeout(() => {
        const vm = this;
        const el = document.getElementById('choose-from-dropbox');
        if (el && typeof window.Dropbox !== 'undefined') {
          const dropboxNode = window.Dropbox.createChooseButton({
            success(data) {
              const files = [];
              data.forEach((file) => {
                files.push({ type: "dropbox", url: file.link, name: file.name, icon: file.icon, thumbnailUrl: file.thumbnailLink });
              });
              vm.addLink(files);
            },
            multiselect: true,
          });
          _.extend(dropboxNode.style, {
            padding: 0, background: 'transparent', 'max-width': '100px', 'white-space': 'normal', border: 'none', 'font-size': '1.1em',
          });
          dropboxNode.innerHTML = '<span class="dropin-btn-status"></span>Dropbox';
          el.appendChild(dropboxNode);
        }
      });
    },
    methods: {
      removeLink(index) {
        if (index > -1 && index < this.links.length) {
          this.links.splice(index, 1);
          this.el.setLinks(this.links);
        }
      },
      addLink(files) {
        // Files from dropbox or onedrive. Files empty = url
        if (files) {
          files.forEach((file) => {
            this.links.push(file);
          });
        } else {
          if (! this.linkurl) return;
          let name = this.linkurl.replace(/\/+$/, ''); // strip ending slashes
          name = name.substr(name.lastIndexOf('/') + 1);
          this.links.push({ type: 'link', url: this.linkurl, name });
          this.linkurl = "";
        }
        this.el.setLinks(this.links);
      },
      editLinkName(link) {
        const newName = window.prompt(this.$t('ELEMENT-DETAIL.LINK_NAME'), link.name);
        if (! newName) return;
        link.name = newName;
      },
      fileUploaded(newFile) {
        if (! (newFile && newFile.response && newFile.response.file)) return;
        const { file } = newFile.response;
        this.links.push({ type: 'file', url: file.url, name: file.display_filename, mime: file.mime });
        this.el.setLinks(this.links);
      },
      chooseFromGoogledrive() {
        GoogleSrv.drive.loadPicker((files) => {
          this.addLink(files);
        });
      },
      chooseFromOnedrive() {
        MicrosoftSrv.onedrive.loadPicker((files) => {
          this.addLink(files);
        });
      },
    },
  };
</script>
