<template>
  <div>
    <div>
      <v-icon color="black" small style="vertical-align: baseline">fas fa-check-square</v-icon> &nbsp;{{ $t('PROJECT_SHEET.ACTIONS') }}
      <div v-if="actionStats" class="display-flex">
        <div v-for="(actionStat, id) in actionStats" v-if="actionStat.number" :key="id" :class="actionStat.class" :style="{width: actionStat.percent + '%'}"
             class="text-center" style="flex: 1 1 auto; height: 60px; justify-content: center; display: flex; flex-direction: column; min-width: 100px">
          <h3 style="margin: 0">{{ actionStat.number }}</h3>{{ $t(actionStat.label) }}
        </div>
      </div>
      <div v-else class="mt-2 text-muted">{{ $t('PROJECT_SHEET.NO_ACTION') }}</div>
    </div>
    <div v-if="workloadStats" class="mt-4">
      <v-icon color="black" small style="vertical-align: baseline">fas fa-weight-hanging</v-icon> &nbsp;{{ $t('ELEMENT-DETAIL.WORKLOAD') }}
      <div class="display-flex">
        <div v-for="(workloadStat, id) in workloadStats" v-if="workloadStat.number" :key="id"
             :class="workloadStat.class" :style="{width: workloadStat.percent + '%'}"
             class="text-center" style="flex: 1 1 auto; height: 60px; justify-content: center; display: flex; flex-direction: column; min-width: 100px">
          <h3 style="margin: 0">{{ workloadStat.number }} {{ workloadInHours ? $t('PROJECT_SHEET.HOURS_SHORT') : $t('PROJECT_SHEET.DAYS_SHORT') }}</h3>
          {{ $t(workloadStat.label) }}
        </div>
      </div>
    </div>
    <div class="mt-4">
      <v-icon color="successgreen" small style="vertical-align: baseline">fas fa-minus</v-icon> &nbsp;{{ $t('PROJECT_SHEET.PROGRESS') }}
      <div v-if="bubbleStats" class="display-flex">
        <div v-for="(bubbleStat, id) in bubbleStats" v-if="bubbleStat.number" :key="id"
             :class="bubbleStat.class" :style="{width: bubbleStat.percent + '%'}"
             class="text-center" style="flex: 1 1 auto; height: 60px; justify-content: center; display: flex; flex-direction: column; min-width: 100px">
          <h3 style="margin: 0">{{ bubbleStat.number }}</h3>{{ $t(bubbleStat.label) }}
        </div>
      </div>
      <div v-else class="mt-2 text-muted">{{ $t('PROJECT_SHEET.NO_BUBBLE') }}</div>
    </div>
  </div>
</template>

<script>
  import workloadMixin from '@/components/Workload/workloadMixin';

  export default {
    mixins: [workloadMixin],
    props: {
      planning: { type: Object, required: true },
    },
    data() {
      return {
      };
    },
    computed: {
      actionStats() {
        const stats = {
          finished: { label: "PROJECT_SHEET.FINISHED", class: "successgreen white--text", number: 0 },
          urgent: { label: "PROJECT_SHEET.URGENT", class: "warningorange  white--text", number: 0 },
          overdue: { label: "PROJECT_SHEET.OVERDUE", class: "errorred  white--text", number: 0 },
          future: { label: "PROJECT_SHEET.FUTURE", class: "midgrey", number: 0 },
        };
        this.planning.elements.forEach((el) => {
          if (! el.getChecklist()) return;
          el.getChecklist().forEach((action) => {
            const color = el.getChecklistItemClass(action);
            if (action.checked) {
              stats.finished.number++;
            } else if (color === 'red') {
              stats.overdue.number++;
            } else if (color === 'orange') {
              stats.urgent.number++;
            } else {
              stats.future.number++;
            }
          });
        });
        const total = stats.finished.number + stats.urgent.number + stats.overdue.number + stats.future.number;
        Object.keys(stats).forEach((id) => {
          stats[id].percent = stats[id].number / total * 100;
        });
        return total ? stats : null;
      },
      workloadStats() {
        let estimated = 0;
        let used = 0;
        this.planning.elements.forEach((el) => {
          if (! el.getChecklist()) return;
          el.getChecklist().forEach((action) => {
            used += this.workloadToDisplay(action.workload_used || 0);
            estimated += this.workloadToDisplay(action.workload || action.workload_used || 0);
          });
        });
        estimated = Math.round(estimated * 100) / 100;
        used = Math.round(used * 100) / 100;
        if (! estimated && ! used) return null;
        const remaining = Math.round((estimated - used) * 100) / 100;
        const stats = {
          used: { label: "PROJECT_SHEET.DAYS_USED", class: "successgreen white--text", number: Math.min(used, estimated) },
          remaining: { label: "PROJECT_SHEET.DAYS_REMAINING", class: "midgrey", number: Math.max(0, remaining) },
          exceeded: { label: "PROJECT_SHEET.DAYS_EXCEEDED", class: "errorred  white--text", number: Math.max(0, -remaining) },
        };
        Object.keys(stats).forEach((id) => {
          stats[id].percent = stats[id].number / estimated * 100;
        });
        return stats;
      },
      workloadInHours() {
        return this.$store.getters['users/accessRight/config'].workloadInHours;
      },
      bubbleStats() {
        const stats = {
          finished: { label: "PROJECT_SHEET.FINISHED", class: "successgreen white--text", number: 0 },
          inprogress: { label: "PROJECT_SHEET.IN_PROGRESS", class: "warningorange  white--text", number: 0 },
          overdue: { label: "PROJECT_SHEET.OVERDUE", class: "errorred  white--text", number: 0 },
          future: { label: "PROJECT_SHEET.FUTURE", class: "midgrey", number: 0 },
        };
        this.planning.elements.forEach((el) => {
          if (! el.isType('task')) return;
          let id;
          if (el.getProgress() == 100) {
            id = 'finished';
          } else if (el.getEndTime().isBefore()) {
            id = 'overdue';
          } else if (el.getStartTime().isAfter()) {
            id = 'future';
          } else {
            id = 'inprogress';
          }
          stats[id].number++;
        });
        const total = stats.finished.number + stats.inprogress.number + stats.overdue.number + stats.future.number;
        Object.keys(stats).forEach((id) => {
          stats[id].percent = stats[id].number / total * 100;
        });
        return total ? stats : null;
      },
    },
  };
</script>
