<template>
  <div :title="$t('NAV.NAVIGATE_TIMELINE')" :class="{ active: isActive, highlighted: alwaysHighlighted }" class="timeline-scrollbar">
    <div class="scrollbar-rail">
      <div class="scrollbar-visible-area">
        <div class="scrollable-timeline">
          <div class="ui-slider-handle display-flex align-center justify-center">
            <div class="scollbar-handle-content"><i class="fas fa-lg fa-arrows-alt-h"></i></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
  .timeline-scrollbar {
    position: relative;
    height: 8px;
    opacity: .6;
    filter: "alpha(opacity=50)";
    -ms-filter: "alpha(opacity=50)";
    transition: opacity .2s ease-in-out, height .2s ease-in-out;
    z-index: 1;
  }
  #table-header:hover .timeline-scrollbar, .timeline-scrollbar.highlighted, .timeline-scrollbar.active {
    opacity: 1;
  }

  .timeline-scrollbar .scrollbar-rail {
    height: 8px;
    background-color: #eee;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transition: height .2s ease-in-out;
    cursor: pointer;
  }
  #table-header:hover .timeline-scrollbar .scrollbar-rail, .timeline-scrollbar.highlighted .scrollbar-rail, .timeline-scrollbar.active .scrollbar-rail {
    height: 14px;
  }

  .timeline-scrollbar .scrollbar-visible-area {
    height: 100%;
    position: absolute;
    max-width: 100%;
    padding: 0 50px;
  }

  .timeline-scrollbar .scrollable-timeline {
    border: none;
    background: transparent;
    height: 100%;
  }

  .timeline-scrollbar .ui-slider-handle {
    border: none;
    outline: none;
    width: 100px;
    height: 100%;
    opacity: .75;
    border-radius: 5px;
    background-color: rgba(50,50,50,.4);
    position: absolute;
    top: 0;
    margin-left: -50px;
    cursor: pointer;
    transition: opacity .2s ease-in-out, left .2s ease-in-out, height .2s ease-in-out;
  }
  .timeline-scrollbar .ui-slider-handle:hover, .timeline-scrollbar.active .ui-slider-handle {
    opacity: 1;
  }
  .timeline-scrollbar .scollbar-handle-content {
    color: #333;
  }
</style>

<script>
  import clientConfig from '@/client_customs/config';

  export default {
    props: {
      visibleTimeline: { type: Object, required: true },
    },
    data() {
      return {
        halfHandleWidth: 50,
        isActive: false,
        alwaysHighlighted: clientConfig.timeline && clientConfig.timeline.alwaysHighlighted,
      };
    },
    watch: {
      'visibleTimeline.pxwidth': function () {
        setTimeout(this.updateHandle);
      },
    },
    mounted() {
      const vm = this;
      const element = $(".scrollable-timeline");
      const $visibleArea = element.parent();
      const $tableWrapper = $("#table-wrapper");
      const $planningTable = $("#planning-table");

      function updateScrollablePosition() {
        $visibleArea.css('left', $tableWrapper.scrollLeft()).css('width', $tableWrapper.width());
      }

      function updateValue() {
        element.slider("option", "value", $tableWrapper.scrollLeft() / ($planningTable.width() - $tableWrapper.width()) * 100 || 0);
        updateScrollablePosition();
      }

      let $handle;
      element.slider({
        slide(event, ui) {
          const transition = $handle.css('transition');
          $handle.css('transition', 'none');
          $tableWrapper.scrollLeft(ui.value / 100 * ($planningTable.width() - $tableWrapper.width()));
          updateScrollablePosition();
          $handle.css('transition', transition);
        },
        start() {
          vm.isActive = true;
        },
        stop() {
          vm.isActive = false;
        },
      });
      $handle = element.find(".ui-slider-handle");
      $handle.append($(".timeline-scrollbar .scollbar-handle-content"));

      $("#table-wrapper").on('scroll', _.throttle(() => {
        updateValue();
      }, 100));
      $visibleArea.on('click', function (event) {
        if (event.target != this) return;
        if (event.offsetX <= vm.halfHandleWidth) {
          element.slider("option", "value", 0);
          $("#table-wrapper").scrollLeft(0);
        } else if (($visibleArea.outerWidth() - event.offsetX) < vm.halfHandleWidth) {
          element.slider("option", "value", 100);
          $("#table-wrapper").scrollLeft(99999999);
        }
      });

      updateValue();
      setTimeout(this.updateHandle);
    },
    methods: {
      updateHandle() {
        const element = $(".scrollable-timeline");
        const $visibleArea = element.parent();
        const $handle = element.find(".ui-slider-handle");
        const twWidth = $("#table-wrapper").width();
        const ptWidth = $("#planning-table").width();
        const handleWidth = Math.max(100, Math.min(ptWidth, twWidth * twWidth / ptWidth));
        this.halfHandleWidth = Math.round(handleWidth / 2);
        $handle.css('width', handleWidth).css('margin-left', -this.halfHandleWidth);
        $visibleArea.css('padding-left', this.halfHandleWidth).css('padding-right', this.halfHandleWidth);
        element.slider("option", "disabled", handleWidth == ptWidth);
      },
    },
  };
</script>
