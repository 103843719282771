<template>
  <div v-if="debug" style="background: white; padding: 7px; margin-bottom: 40px">
    <h3>Debug</h3>
    <slot></slot>
  </div>
</template>

<script>
  import DebugSrv from './DebugSrv';

  export default {
    data() {
      return {
        debug: DebugSrv.debug,
      };
    },
  };
</script>
