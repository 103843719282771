<template>
  <div>
    <navigation>
      <v-divider vertical></v-divider>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn icon class="px-2" style="cursor: default;" v-on="on">
            <v-icon v-show="! saving.inprogress && ! saving.success && ! saving.error" size="18">fas fa-save</v-icon>
            <v-icon v-show="saving.inprogress" size="18">fa fa-lg fa-spinner fa-spin</v-icon>
            <v-icon v-show="saving.success" size="18">fa fa-lg fa-check success--text</v-icon>
            <v-icon v-show="saving.error" size="18">fa fa-lg fa-exclamation-triangle errorred--text</v-icon>
          </v-btn>
        </template>
        <span>
          <span v-show="! saving.inprogress && ! saving.success && ! saving.error">{{ $t('NAV.AUTOSAVE') }}</span>
          <span v-show="saving.inprogress">{{ $t('AUTOSAVE.SAVING') }}</span>
          <span v-show="saving.success">{{ $t('AUTOSAVE.SAVED') }}</span>
          <span v-show="saving.error">{{ $t('AUTOSAVE.SAVE_ERROR') }}</span>
        </span>
      </v-tooltip>
      <v-divider vertical></v-divider>
      <v-spacer></v-spacer>
      <template v-if="isPremium">
        <v-btn rounded small depressed class="mx-1" style="height: 32px" @click="csvExport()">
          <v-icon color="primary" small class="mr-2">far fa-file-excel</v-icon>
          {{ $t('NAV.EXPORT') }} CSV
        </v-btn>
      </template>
      <template v-if="isPremium">
        <v-btn :disabled="exporting.inprogress" rounded small depressed class="mx-1" style="height: 32px" @click="! exporting.inprogress && pdfExport()">
          <v-icon color="primary" small class="mr-2">{{ exporting.inprogress ? 'fas fa-spinner fa-spin' : 'far fa-file-pdf' }}</v-icon>
          {{ $t('NAV.EXPORT') }} PDF
        </v-btn>
      </template>
    </navigation>

    <div v-if="isPremium" id="actionsdashboard" style="height: calc(100vh - 72px); margin-bottom: -24px; display: flex;flex-direction: column;">
      <v-progress-linear v-if="! loading.complete" :value="loading.counter.toLoad && loading.counter.loaded ? loading.counter.loaded / loading.counter.toLoad * 100 : 2"
                         color="accent" rounded>
      </v-progress-linear>
      <template v-if="loading.complete">
        <actions-dashboard-filters class="flex-grow-0"></actions-dashboard-filters>
        <div v-if="hasActions && viewIsReady" id="actions-dashboard-card-wrapper" class="flex-grow-1 display-flex py-4"
             style="overflow-x: auto; overflow-y: hidden;">
          <actions-dashboard-card v-for="firstLevelId in firstLevelIds" :key="firstLevelId"
                                  :planning-actions="allActions" :group-by="actionsOptions.groupBy"
                                  :first-level-id="firstLevelId" :selected-action="selectedAction"
                                  @create-action="createAction" @open-action="openAction"
                                  @selected-action="selectedAction = $event"
                                  @update-original-el="updateOriginalEl">
          </actions-dashboard-card>
        </div>
        <v-card v-if="! hasActions && viewIsReady" class="flex-grow-1 text-center text-muted py-6 px-12">
          {{ $t('MONITORING_ACTIONS.NO_ACTION') }}
        </v-card>
        <div v-if="! viewIsReady" class="flex-grow-1 text-center pa-4" style="font-size: 18px">
          <i class="fas fa-spinner fa-spin fa-2x fa-fw"></i>
        </div>
      </template>
    </div>

    <div v-if="userLoaded && ! isPremium">{{ $t('PREMIUM.SECTION_IS_PREMIUM') }}</div>

    <!-- MODALS-->
    <modal v-if="openedAction" card @close="closeOpenedAction">
      <open-action :action="openedAction" @close="closeOpenedAction"></open-action>
    </modal>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import clientConfig from '@/client_customs/config';
  import Action from '@/models/Action';
  import Navigation from '@/components/Navigation/Navigation';
  import ActionsDashboardFilters from './ActionsDashboardFilters';
  import ActionsDashboardCard from './ActionsDashboardCard';
  import OpenAction from './OpenAction';

  export default {
    components: {
      Navigation,
      ActionsDashboardFilters,
      ActionsDashboardCard,
      OpenAction,
    },
    data() {
      return {
        allActions: [],
        openedAction: null,
        selectedAction: null,
        viewIsReady: true,
        exporting: { inprogress: false, success: false, error: false },
        customFilter: clientConfig.dashboards?.actionsDashboard?.customFilter,
      };
    },
    computed: {
      saving() {
        return this.$store.state.ui.saving;
      },
      selectedCompanyUsersWithDeleted() {
        return _.union(this.selectedCompanyUsers, this.selectedActionDeletedUsers);
      },
      firstLevelIds() {
        if (this.actionsOptions.groupBy == 'user') {
          const selectedCompanyUsersIds = this.selectedCompanyUsersWithDeleted.map(item => `${item}`);
          if (this.filterOptions.unassigned) selectedCompanyUsersIds.unshift('none');
          return selectedCompanyUsersIds.concat(this.selectedActionVirtualParticipants);
        }
        if (this.actionsOptions.groupBy == 'group') {
          const selectedCompanyGroupsIds = this.selectedCompanyGroups.map(item => `${item}`);
          return selectedCompanyGroupsIds;
        }
        return ['none'].concat(this.selectedProjects.filter(item => this.planningActions.find(action => action.getPlanningId() == item)).map(item => `${item}`));
      },
      planningActions() {
        // actions from selected plannings
        return this.$store.getters['multiprojects/planningActions'].filter((action) => {
          if (! action.getPlanningId()) return true;
          return this.selectedProjects.find(item => item == action.getPlanningId()) && true || false;
        });
      },
      filteredPlanningActions() {
        return this.planningActions.filter(this.filterAction);
      },
      filteredMeetingActions() {
        return this.meetingActions.filter(this.filterAction);
      },
      allComputedActions() {
        if (! this.loading.complete) return [];
        let allComputedActions = this.filteredMeetingActions.concat(this.filteredPlanningActions);
        if (this.customFilter) allComputedActions = allComputedActions.filter(action => this.customFilter.filterAction(action));
        return allComputedActions;
      },
      hasActions() {
        return this.allActions.length > 0;
      },
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
      userLoaded() { return this.$store.state.users.user.id > 0; },
      ...mapState('multiprojects', ['projects', 'selectedProjects', 'meetingActions', 'companyUsers', 'actionDeletedUsers', 'selectedCompanyUsers', 'selectedActionDeletedUsers',
                                    'selectedCompanyGroups', 'selectedActionVirtualParticipants', 'loading', 'selectedDates', 'filterOptions', 'actionsOptions']),
    },
    watch: {
      allComputedActions() {
        this.updateView();
      },
    },
    created() {
      const firstLoad = ! this.companyUsers.length;
      this.$store.commit('ui/saving/reset');
      this.$store.commit('multiprojects/loadFilters', { dashboardName: this.$route.path.slice(1).replace(/\//g, '_') });
      Promise.all([this.$store.dispatch('multiprojects/load'), this.$store.dispatch('multiprojects/loadMeetingActions')]).then(() => {
        if (! firstLoad) return;
        const usersWithActionSet = new Set(this.meetingActions.concat(this.planningActions).map(action => action.getUserId()).filter(item => item));
        for (let i = this.selectedCompanyUsersWithDeleted.length - 1; i >= 0; i--) {
          if (! usersWithActionSet.has(this.selectedCompanyUsersWithDeleted[i])) this.selectedCompanyUsersWithDeleted.splice(i, 1);
        }
      });
    },
    methods: {
      filterAction(action) {
        if (! this.actionsOptions.displayCompleted && action.getChecked()) return false;
        if (this.actionsOptions.withWorkloadOnly && ! action.getWorkload() && ! action.getWorkloadUsed()) return false;
        if (this.actionsOptions.title && ! window.slugify(action.getTitle()).includes(window.slugify(this.actionsOptions.title))) return false;
        const actionEndTime = action.getEndTime();
        const actionVirtualUser = ! action.getUserId() && ! action.getGroupId() && action.getUsername();
        if (this.selectedDates.starttime && actionEndTime && actionEndTime.isBefore(this.selectedDates.starttime)) return false;
        if (this.selectedDates.endtime && actionEndTime && actionEndTime.startOf('day').isAfter(this.selectedDates.endtime)) return false;
        if (! action.getUserId() && ! action.getGroupId() && ! actionVirtualUser && ! this.filterOptions.unassigned) return false;
        if (action.getUserId() && ! this.selectedCompanyUsersWithDeleted.find(item => item == action.getUserId())) return false;
        if (action.getGroupId() && ! this.selectedCompanyGroups.find(item => item == action.getGroupId())) return false;
        if (actionVirtualUser && ! this.selectedActionVirtualParticipants.find(item => item == action.getUsername())) return false;
        if (this.actionsOptions.groupBy == 'user' && action.getGroupId()) return false;
        if (this.actionsOptions.groupBy == 'group' && (action.getUserId() || actionVirtualUser || action.type !== 'planning')) return false;
        return true;
      },
      updateView() {
        if (Math.abs(this.allComputedActions.length - this.allActions.length) < 30) {
          this.allActions = this.allComputedActions;
          return;
        }
        // update viewIsReady indicator before loading DOM (long operation)
        this.viewIsReady = false;
        setTimeout(() => {
          this.allActions = this.allComputedActions;
          this.viewIsReady = true;
        });
      },
      openAction(action) {
        if (! action) return;
        this.openedAction = action;
      },
      createAction({ userId } = {}) {
        const newAction = new Action();
        if (userId) {
          newAction.setUser({ id: userId, username: this.$store.getters['users/getUsername']({ id: userId }, 'short') });
        }
        this.openedAction = newAction;
      },
      closeOpenedAction(reason) {
        if (reason == 'save') {
          // todo : handle case when save failed
          if (this.openedAction.type == 'meeting' && ! this.meetingActions.includes(this.openedAction)) this.meetingActions.push(this.openedAction);
        } else if (reason == 'delete') {
          // todo : handle case when delete failed
          if (this.openedAction.type == 'meeting') {
            const index = this.meetingActions.findIndex(item => item == this.openedAction);
            if (index > -1) this.meetingActions.splice(index, 1);
          }
        } else {
          this.openedAction.reset();
        }
        if (reason) this.updateOriginalEl(this.openedAction);
        this.openedAction = null;
      },
      updateOriginalEl(action) {
        // Update original planning element (for workloads)
        const originalPlanning = this.$store.getters['multiprojects/getPlanningById'](action.getPlanningId());
        const element = originalPlanning && (originalPlanning.elements || []).find(item => item.o_id == action.getElement().o_id);
        if (element) {
          this.$store.commit('multiprojects/updateOriginalEl', { el: element, data: action.getElement().getAll() });
        }
      },
      csvExport() {
        const separator = ';';
        const filename = `${this.$t('MONITORING_ACTIONS.TITLE')}.csv`;
        const csvCols = ['getTitle', 'getPlanningId', 'getLaneId', 'getElement', 'getUserId', 'getDuedate', 'getChecked', 'getWorkload', 'getWorkloadUsed'];

        let csvContent = `${csvCols.map(name => this.$t(`MONITORING_ACTIONS.CSV.${name.toUpperCase()}`)).join(separator)}\n`;
        this.allActions.forEach((action) => {
          const dataString = csvCols.map((key) => {
            let val = action[key];
            if (typeof val == 'function') val = action[key]();
            if (typeof val == 'boolean') val = val ? 'X' : '';
            if (key == 'getDuedate' && val) {
              const momentDate = moment(val);
              return momentDate.isValid() ? momentDate.format('L') : val;
            }
            if (key == 'getPlanningId' && val) {
              const project = this.projects.find(item => item.id == val);
              return project ? project.getTitle() : val;
            }
            if (key == 'getLaneId' && val) {
              const project = this.projects.find(item => item.id == action.getPlanningId());
              const lane = project?.lanes.find(item => item.id == val);
              return lane ? lane.label : val;
            }
            if (key == 'getElement' && val) {
              const actionElement = val;
              return actionElement ? actionElement.getTitle() : val;
            }
            if (key == 'getUserId' && val) {
              let companyUser = this.companyUsers.find(item => item.id == val);
              if (! companyUser) companyUser = this.actionDeletedUsers.find(item => item.id == val);
              return companyUser ? companyUser.username : val;
            }
            return val;
          }).join(separator).replace(/"/g, '""');
          csvContent += `${dataString}\n`;
        });
        if ((navigator.appName == 'Microsoft Internet Explorer') || ((navigator.appName == 'Netscape') && (new RegExp("Trident/.*rv:([0-9]{1,}[.0-9]{0,})").exec(navigator.userAgent) != null))) {
          // IE WORKAROUND
          const IEwindow = window.open();
          IEwindow.document.write(`sep=${separator}\r\n${csvContent}`);
          IEwindow.document.close();
          IEwindow.document.execCommand('SaveAs', true, filename);
          IEwindow.close();
        } else {
          const encodedUri = encodeURI(`data:text/csv;charset=utf-8,\uFEFF${csvContent}`).replace(/#/g, '%23');
          const link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", filename);
          document.body.appendChild(link); // Required for FF
          link.click();
          document.body.removeChild(link);
        }
      },
      pdfExport() {
        _.extend(this.exporting, { inprogress: true, success: false, error: false });
        const $html = document.querySelector("html").cloneNode(true);
        $html.querySelectorAll(".v-text-field__slot input").forEach(el => el.setAttribute('value', el.value));
        const queryEl = $html.querySelector('#actions-dashboard-card-wrapper') || document.createElement("div");
        queryEl.children.forEach(el => el.classList.add('px-4', 'mx-n4')); // space for inner elements box shadow
        $html.querySelector("body").innerHTML = `<div id="actionsdashboard" style="padding: 0 20px;">
        <h1 class="primary--text text-uppercase">${this.$t('MONITORING_ACTIONS.TITLE')}</h1>${queryEl.innerHTML}
        </div>`;
        $html.querySelector('body').classList.add('v-application');
        $html.querySelectorAll(".export-hidden").forEach((el) => { el.parentNode.removeChild(el); });
        $html.querySelectorAll(".actions-group").forEach((el) => {
          el.style.setProperty('margin', '15px');
          el.style.setProperty('box-shadow', '0px 0 1px 0px #ccc');
          el.style.setProperty('padding-bottom', '2px');
        });

        window.apiSrv.call('pdf', 'store', { html: $html.innerHTML, footer: this.$t('MONITORING_ACTIONS.TITLE') }).then((response) => {
          if (response && response.data && response.data.pdfurl) {
            this.exporting.inprogress = false;
            this.exporting.success = true;
            setTimeout(() => { this.exporting.success = false; }, 3000);
            window.open(`${response.data.pdfurl}/${this.$t('MONITORING_ACTIONS.TITLE')}.pdf`, "_blank");
          }
        }).catch((message) => {
          this.exporting.inprogress = false;
          this.exporting.error = message || "Error : not exported";
        });
      },
    },
  };
</script>
