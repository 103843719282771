export default {
  hideManageAccount: true,
  hidePremiumFeatures: true,
  mobileview: true,
  login: {
    integrationSource: 'microsoftteams',
    createAccountLink: 'https://bubbleplan.net/creer-un-compte-gratuit',
  },
  planningDrawer: {
    hideExchangeCenter: true,
    logoutButton: true,
  },
  integrations: {
    availableIntegrations: [],
  },
};
