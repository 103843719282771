import constants from '@/js/constants.js';
import { getDefaultLane } from './helpers/defaultValues';
import helpers from './helpers/planningInitHelpers';

class PlanningLane {
  constructor(planning, srcData) {
    const data = angular.copy(srcData) || {};

    if (data instanceof PlanningLane) {
      _.extend(this, data);
    } else {
      helpers.updateLastVersionsLanes(data);
      angular.merge(this, getDefaultLane(), data);
    }

    this.getPlanning = function () {
      return planning;
    };
  }

  getAll() {
    const nonNullData = {};
    const excludedKeys = ['height', 'contentHeight', 'getPlanning'];
    Object.keys(this).forEach((key) => { if (this[key] !== null && ! excludedKeys.includes(key)) nonNullData[key] = this[key]; });
    return _.extend({ id: this.id }, nonNullData);
  }

  set(data) {
    angular.merge(this, data);
    return this;
  }

  getLabelStyle() {
    return this.labelStyle;
  }
  setLabelStyle(newval) {
    this.labelStyle = newval;
    return this;
  }

  getColorId() {
    return this.color;
  }
  setColorId(value) {
    this.color = (typeof value == 'number') ? value : null;
    return this;
  }
  getColorShadeId() {
    return this.colorShade;
  }
  setColorShadeId(value) {
    this.colorShade = (typeof value == 'number') ? value : null;
    return this;
  }

  getHidden() {
    return this.hidden;
  }
  setHidden(newval) {
    this.hidden = newval;
    return this;
  }

  /** ******** */
  /* DISPLAY */
  /** ******** */
  getMainColor() {
    const { colors } = this.getPlanning().config;
    return colors[this.color] && (colors[this.color].shades && colors[this.color].shades[this.colorShade] || colors[this.color].main) || '';
  }

  getSecondaryColor() {
    const { colors } = this.getPlanning().config;
    return colors[this.color] && colors[this.color].secondary || '';
  }

  getMainColorContrastedTextColor() {
    const maincolor = this.getMainColor();
    return maincolor && window.contrastedTextColor(maincolor);
  }

  static updateAllYposition(els) {
    if (! els || ! els.length) return;
    const sortedEls = els.reduce((acc, el) => { if (el.visible) acc.push(angular.copy(el)); return acc; }, []).sort((a, b) => {
      if ((a.getPriority() || 0) != (b.getPriority() || 0)) { // higher priority at top
        return (a.getPriority() || 0) > (b.getPriority() || 0) ? -1 : 1;
      }
      if (a.getType() !== b.getType()) { // milestones at top, then macros
        if (b.isType('milestone')) return 1;
        if (a.isType('milestone', 'macro')) return -1;
        return 1;
      }
      return a.getStartTime().isSameOrAfter(b.getStartTime()) ? 1 : -1;
    });
    const prevEls = [];
    const { lanePadding, elementTopMargin, milestoneIconHeight } = constants;
    sortedEls.forEach((el) => {
      let ytextposition = milestoneIconHeight; // relative to milestone icon top
      let yposition = (el.isType('milestone') ? milestoneIconHeight : 0) + (lanePadding - elementTopMargin); // relative to lane top
      prevEls.sort((a, b) => a.yposition - b.yposition); // Possible colliders from top to bottom
      for (let index = 0; index < prevEls.length; index++) {
        const prevEl = prevEls[index];
        if ((prevEl.xposition <= el.xposition && prevEl.xposition + prevEl.getWidth() >= el.xposition) || (el.xposition <= prevEl.xposition && el.xposition + el.getWidth() >= prevEl.xposition)) {
          // Collider
          if ((prevEl.getType() == el.getType()) && (yposition + el.height + 2 * elementTopMargin <= prevEl.yposition)) break; // free gap
          yposition = Math.max(yposition, prevEl.yposition + prevEl.height);

          if (el.isType('milestone')) {
            if (prevEl.isType('milestone')) { // milestone grouping
              ytextposition = Math.max(ytextposition, prevEl.ytextposition + prevEl.height + elementTopMargin);
            } else { // milestone under task : add space for icon
              yposition += milestoneIconHeight;
            }
          }
        }
      }
      el.yposition = yposition + elementTopMargin;
      el.ytextposition = el.isType('milestone') ? ytextposition : undefined;
      const realEl = els.find(item => item.id == el.id);
      realEl.yposition = el.yposition;
      realEl.ytextposition = el.ytextposition;
      prevEls.push(el);
    });
  }
}

export default PlanningLane;
