<template>
  <div>
    <navigation>
      <v-spacer></v-spacer>
      <template v-if="isPremium">
        <v-btn :disabled="exporting.inprogress" rounded small depressed class="mx-1" style="height: 32px" @click="! exporting.inprogress && pdfExport()">
          <v-icon color="primary" small class="mr-2">{{ exporting.inprogress ? 'fas fa-spinner fa-spin' : 'far fa-file-pdf' }}</v-icon>
          {{ $t('NAV.EXPORT') }}
        </v-btn>
      </template>
    </navigation>

    <div v-if="isPremium" id="planningusers">
      <v-progress-linear v-if="! loading.complete" :value="loading.counter.toLoad && loading.counter.loaded ? loading.counter.loaded / loading.counter.toLoad * 100 : 2"
                         color="accent" rounded>
      </v-progress-linear>

      <dashboards-filters v-if="loading.complete" :filters="dashboardFilters" :manual-update="filtersManualUpdate" can-hide-elements
                          @update="computeUserProject" @update-display="updateDisplay">
      </dashboards-filters>
      <div v-if="planning && planning.elements && planning.elements.length" v-show="viewIsReady">
        <!-- viewIsReady in v-show to keep timeline scrolling on refresh -->
        <div id="viewer" style="background: transparent;">
          <planning-content :planning="planning" :options="{ elements: { canEditEl, hideElements: filterOptions.hide_elements }, elementDetails: { canDuplicateEl: true, canSetPriority: true } }"
                            :events="{ elementClick, closeElementDetails, selectColor, closeColorPicker, checklistClick, progressClick }" dashboard>
          </planning-content>
        </div>
      </div>
      <div v-if="loading.complete && ! viewIsReady" style="text-align: center; padding: 15px; font-size: 18px">
        <i class="fas fa-spinner fa-spin fa-2x fa-fw"></i>
      </div>

      <v-card v-if="viewIsReady && ! (planning && planning.elements && planning.elements.length)" class="py-6 px-12">
        <span v-if="useGroups && ! companyGroups.length">
          {{ $t('PLANNINGUSERS.CREATE_TEAMS_IN_SECTION') }} <router-link to="/manage">{{ $t('NAV.MANAGE') }}</router-link>
        </span>
        <span v-else>{{ $t('PLANNINGUSERS.SELECT_PROJECTS_WITH_USERS') }}</span>
      </v-card>
    </div>

    <div v-if="userLoaded && ! isPremium">{{ $t('PREMIUM.SECTION_IS_PREMIUM') }}</div>
  </div>
</template>

<style>
  #planningusers .content#viewer {
    padding-top: 0 !important;
  }
</style>

<script>
  import { mapState, mapGetters } from 'vuex';
  import Planning from '@/models/Planning';
  import Navigation from '@/components/Navigation/Navigation';
  import PlanningContent from '@/components/Planning/PlanningContent';
  import DashboardsFilters from '../DashboardsFilters/DashboardsFilters';

  export default {
    components: {
      Navigation,
      PlanningContent,
      DashboardsFilters,
    },
    data() {
      return {
        planning: null,
        viewIsReady: false,
        planningUsersOptions: { timeline: null, config: null, ...window.safeParseJSON(window.localStorageWrapper.getItem('planningUsersOptions')) },
        exporting: { inprogress: false, success: false, error: false },
      };
    },
    computed: {
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
      userLoaded() { return this.$store.state.users.user.id > 0; },
      allPlanningElements() {
        // selected plannings -> elements
        const elements = [];
        this.projects.forEach((data) => {
          if (this.selectedProjects.indexOf(data.id) == -1) return;

          (data.elements || []).forEach((el) => {
            this.$store.commit('multiprojects/formatElProjectData', { el, projectData: data });

            if (! el.getUsers() || ! el.getUsers().length) {
              const newel = angular.copy(el);
              newel.id = `${newel.id}.${window.uuid()}`;
              newel.setLaneId(-1);
              elements.push(newel);
              return;
            }

            (el.getUsers() || []).forEach((user) => {
              let laneIds = [user.id || `vp${user.username}`]; // no grouping : 1 lane = 1 user
              if (this.useGroups) {
                if (user.group_id) { // user is a group
                  laneIds = [`group${user.group_id}`];
                } else { // add to all user groups
                  laneIds = (this.getUserGroups(user) || []).map(group => `group${group.id}user${user.id}`);
                }
              }

              laneIds.forEach((laneId) => {
                const newel = angular.copy(el);
                newel.id = `${newel.id}.${window.uuid()}`;
                newel.setLaneId(laneId);
                if (user.id || user.group_id || user.username) {
                  // hide other users actions
                  newel.filter_user = user.id || (user.group_id && `group${user.group_id}`) || `vp${user.username}`;
                }
                elements.push(newel);
              });
            });
          });
        });
        return elements;
      },
      allGroupsLanes() {
        if (! this.useGroups) return null;
        let lanes = [];
        this.companyGroups.forEach((group) => {
          const groupLanes = [];
          (group.users || []).forEach((groupUser) => {
            if (this.groupsOptions.hideUsers) return;
            const user = this.companyUsers.find(item => item.id == groupUser.id);
            if (! user) return;

            groupLanes.push({
              id: `group${group.id}user${user.id}`,
              label: this.$store.getters['users/getUsername'](user),
              level: 1,
            });
          });
          if (this.selectedCompanyGroups.includes(group.id)) {
            lanes.push({
              id: `group${group.id}`,
              label: group.title,
              color: 2,
            });
            lanes = lanes.concat(groupLanes);
          }
        });
        return lanes;
      },
      allUsersLanes() {
        // selected users -> lanes
        const lanes = [];
        this.companyUsers.concat(this.deletedUsers, this.virtualParticipants).forEach((user) => {
          if (user.id) {
            if (! user.isDeleted) {
              if (this.selectedCompanyUsers.indexOf(user.id) == -1) return;
            } else if (this.selectedDeletedUsers.indexOf(user.id) == -1) {
              return;
            }
          } else if (this.selectedVirtualParticipants.indexOf(user.username) == -1) {
            return;
          }

          const laneId = user.id || `vp${user.username}`;
          lanes.push({
            id: laneId,
            label: this.$store.getters['users/getUsername'](user),
            color: user.id ? null : user.group_id ? 2 : 5,
          });
        });
        return lanes;
      },
      mergedWorkloadsByLaneId() {
        /* prepare workloadsByLaneId */
        const workloadsByLaneId = {};
        this.planning.elements.forEach((el) => {
          if (el.filter_user) {
            const elWorkloads = this.$store.getters['workloads/getElUserWorkloads'](el.project_id, el.o_id, el.filter_user);
            const laneId = el.getLaneId();
            if (! workloadsByLaneId[laneId]) workloadsByLaneId[laneId] = [];
            workloadsByLaneId[laneId].push(...elWorkloads);
          }
        });

        if (this.useGroups) {
          this.companyGroups.forEach((group) => {
            if (! group.users?.length) return;
            const groupUsersWorkloads = group.users.reduce((acc, user) => {
              const workloads = (workloadsByLaneId[`group${group.id}user${user.id}`] || []).map(workload => ({
                starttime: moment(workload.starttime),
                endtime: moment(workload.endtime),
                daily_workload: workload.daily_workload / group.users.length,
              }));
              return acc.concat(workloads);
            }, []);
            if (! workloadsByLaneId[`group${group.id}`]) workloadsByLaneId[`group${group.id}`] = [];
            workloadsByLaneId[`group${group.id}`].push(...groupUsersWorkloads);
          });
        }

        /* compile workloads */
        const mergedWorkloadsByLaneId = {};
        this.planning.lanes.forEach((lane) => {
          const laneWorkloads = workloadsByLaneId[lane.id] || [];
          mergedWorkloadsByLaneId[lane.id] = this.$store.getters['workloads/mergeWorkloads'](laneWorkloads);
        });
        return mergedWorkloadsByLaneId;
      },
      useGroups() {
        return this.isBusiness && !! (this.groupsOptions.useGroups && this.companyGroups);
      },
      companyGroups() {
        return this.$store.getters['users/groups/getCompanyGroups'];
      },
      getUserGroups() {
        return this.$store.getters['users/groups/getUserGroups'];
      },
      dashboardFilters() {
        return ['Projects', 'Groups', this.useGroups ? 'GroupUsers' : 'Users', 'Dates', 'Display'];
      },
      filtersManualUpdate() {
        return this.projects.length > 200;
      },
      isBusiness() { return this.$store.state.users.accessRight.isBusiness; },
      ...mapState('multiprojects', ['projects', 'selectedProjects', 'companyUsers', 'selectedCompanyUsers', 'selectedCompanyGroups',
                                    'virtualParticipants', 'selectedVirtualParticipants', 'deletedUsers', 'selectedDeletedUsers']),
      ...mapState('multiprojects', ['loading', 'selectedDates', 'filterOptions', 'groupsOptions']),
      ...mapGetters('multiprojects', ['getOriginalPlanningEl']),
    },
    watch: {
      'loading.complete': function (newVal) {
        if (! newVal) {
          this.viewIsReady = false;
        } else {
          this.$store.state.users.userPromise.then(() => {
            this.computeUserProject();
          });
        }
      },
      'planning.timeline': {
        handler(newVal) {
          this.planningUsersOptions.timeline = newVal;
          window.localStorageWrapper.setItem('planningUsersOptions', JSON.stringify(this.planningUsersOptions));
        },
        deep: true,
      },
      'planning.config': {
        handler(newVal) {
          this.planningUsersOptions.config = { ...newVal, colors: undefined, icons: undefined };
          window.localStorageWrapper.setItem('planningUsersOptions', JSON.stringify(this.planningUsersOptions));
        },
        deep: true,
      },
      'planning.timeline.hidden.before': function (newVal) {
        if (this.selectedDates.starttime) this.selectedDates.starttime = newVal ? moment(newVal) : null;
      },
      'planning.timeline.hidden.after': function (newVal) {
        if (this.selectedDates.endtime) this.selectedDates.endtime = newVal ? moment(newVal) : null;
      },
      'filterOptions.hide_elements': function (newVal) {
        if (newVal && this.planning?.elements) {
          this.planning.elements.forEach((el) => { el.height = null; });
        }
      },
    },
    created() {
      this.$store.commit('multiprojects/loadFilters', {
        dashboardName: this.$route.path.slice(1).replace(/\//g, '_'),
        selectedDisplay: { checklist: true },
      });
      this.$store.dispatch('multiprojects/load');
    },
    methods: {
      canEditEl(el) {
        const originalPlanning = this.$store.getters['multiprojects/getPlanningById'](el.project_id);
        return originalPlanning && originalPlanning.meta && originalPlanning.meta.access_right && originalPlanning.meta.access_right != 'consult';
      },
      saveEl(params) {
        const { el } = params;
        this.$store.dispatch('ui/kanban/saveEl', params).then((result) => {
          if (result == 'nothing to save') return;
          // update source planning element (for el copies & workloads)
          const originalPlanning = this.$store.getters['multiprojects/getPlanningById'](el.project_id);
          const originalPlanningElement = originalPlanning && (originalPlanning.elements || []).find(item => item.o_id == el.o_id);
          if (originalPlanningElement) {
            const originalElementUsers = angular.copy(originalPlanningElement.getUsers());
            this.$store.commit('multiprojects/updateOriginalEl', { el: originalPlanningElement, data: el.getAll() });
            if (! angular.equals(originalElementUsers, originalPlanningElement.getUsers())) {
              // users was changed -> need full refresh to update lanes
              this.computeUserProject();
              return;
            }
          }

          // update element copies on planningusers
          const planningUsersElements = this.planning.elements.filter(item => item.project_id == el.project_id && item.o_id == el.o_id);
          planningUsersElements.forEach((element) => {
            this.$store.commit('multiprojects/updateOriginalEl', { el: element, data: el.getAll() });
            element.update();
          });
        });
      },
      deleteElement(el) {
        const { openedElement } = this.$store.state.ui.planning;
        if (openedElement && openedElement.id == el.id) this.$store.commit('ui/planning/openElement', null);
        this.$store.dispatch('ui/kanban/deleteElement', el);
        const originalPlanning = this.$store.getters['multiprojects/getPlanningById'](el.project_id);
        const originalPlanningElementIndex = originalPlanning && (originalPlanning.elements || []).findIndex(item => item.o_id == el.o_id);
        if (originalPlanningElementIndex > -1) {
          originalPlanning.elements.splice(originalPlanningElementIndex, 1);
          this.computeUserProject();
        }
      },
      elementClick(el) {
        const originalPlanningEl = this.getOriginalPlanningEl(el);
        originalPlanningEl.dashboard_el = el;
        this.$store.commit('ui/planning/openElement', originalPlanningEl);
      },
      closeElementDetails(reason) {
        const { openedElement } = this.$store.state.ui.planning;
        if (! openedElement) return;
        if (reason == 'delete') {
          this.deleteElement(openedElement.dashboard_el);
        } else {
          this.saveEl({ el: openedElement.dashboard_el, newState: openedElement.getAll() });
          if (reason == 'select-color') {
            this.selectColor(openedElement.dashboard_el);
          } else if (reason == 'duplicate') {
            this.duplicateElement(openedElement.dashboard_el);
          }
        }
        this.$store.commit('ui/planning/openElement', null);
      },
      selectColor(el) {
        const originalPlanningEl = this.getOriginalPlanningEl(el);
        originalPlanningEl.dashboard_el = el;
        this.$store.commit('ui/planning/openColorPickerElement', originalPlanningEl);
      },
      closeColorPicker() {
        const { openedColorPickerElement } = this.$store.state.ui.planning;
        if (! openedColorPickerElement) return;
        this.saveEl({ el: openedColorPickerElement.dashboard_el, newState: openedColorPickerElement.getAll() });
        this.$store.commit('ui/planning/openColorPickerElement', null);
      },
      checklistClick(el, item) {
        const oldState = { checklist: angular.copy(el.getChecklist()), progress: el.getProgress() };
        this.$set(item, 'checked', ! item.checked);
        // el.updateChecklistProgress();
        this.saveEl({ el, props: ['checklist', 'progress'], oldState });
      },
      progressClick(el, event) {
        const oldState = { progress: el.getProgress() };
        const fullWidth = $(event.currentTarget).width();
        if (! fullWidth) return;
        el.setProgress(Math.min(Math.round(event.offsetX / fullWidth * 10) * 10, 100));
        this.saveEl({ el, props: ['progress'], oldState });
      },
      duplicateElement(el) {
        const originalPlanning = this.$store.getters['multiprojects/getPlanningById'](el.project_id); // with original lane_id
        const originalPlanningElement = originalPlanning && (originalPlanning.elements || []).find(item => item.o_id == el.o_id);
        if (! originalPlanningElement) return;
        const newEl = angular.copy(originalPlanningElement);
        newEl.setDependencies(null);
        newEl.save(null, null, 'store').then(() => { // create a new element via api / id is updated by api response
          // Send notification
          window.notificationsSrv.callEvent('projectSaved', { planning_id: originalPlanning.id, planning_title: originalPlanning.getTitle() });

          // Add element and reload
          this.$store.commit('multiprojects/plannings/addElementToPlanning', { planningId: originalPlanning.id, el: newEl });
          this.computeUserProject();
        }).catch((message) => {
          if (message) this.$store.dispatch('ui/msgbox/open', { title: 'MONITORING_PROGRESS.ERROR_NOT_MODIFIED', body: message || "" }, { root: true });
        });
      },
      getUserIndex(user) {
        let userIndex;
        if (user.id) {
          userIndex = this.selectedCompanyUsers.indexOf(user.id);
          if (userIndex == -1) return -1;
        } else {
          const username = this.$store.getters['users/getUsername'](user);
          userIndex = this.selectedVirtualParticipants.indexOf(username);
          if (userIndex == -1) return -1;
          userIndex += this.companyUsers.length;
        }
        return userIndex;
      },
      computeUserProject() {
        if (! this.loading.complete) return;
        if (! this.projects.length) return;
        const scrollY = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        this.viewIsReady = false;

        setTimeout(() => {
          const lanes = (this.allGroupsLanes || this.allUsersLanes).slice();
          if (! this.useGroups && this.filterOptions.unassigned) lanes.unshift({ id: -1, label: this.$t("PLANNINGUSERS.UNASSIGNED") });

          /* list elements */
          const elements = [];
          const laneIds = new Set(lanes.map(lane => lane.id));
          this.allPlanningElements.forEach((el) => {
            if (this.filterOptions.el_type && this.filterOptions.el_type != 'both' && ! el.isType(this.filterOptions.el_type)) return;
            if (! laneIds.has(el.getLaneId())) return;
            this.$store.dispatch('multiprojects/formatEl', { el });
            elements.push(el);
          });

          /* workloads */
          lanes.forEach((lane) => {
            lane.getWorkloads = () => this.mergedWorkloadsByLaneId[lane.id];
          });

          /* timeline */
          const defaultProjectData = new Planning();

          const timelineStep = (this.planning && this.planning.timeline && this.planning.timeline.steps
            || this.planningUsersOptions.timeline && this.planningUsersOptions.timeline.steps
            || defaultProjectData.timeline && defaultProjectData.timeline.steps).last();
          let starttime;
          let endtime;
          elements.forEach((el) => {
            starttime = starttime ? moment.min(el.getStartTime(), starttime) : el.getStartTime();
            endtime = endtime ? moment.max(el.getEndTime(), endtime) : el.getEndTime();
          });
          starttime = starttime || moment();
          endtime = endtime || moment();

          if (this.selectedDates.starttime && this.selectedDates.endtime) {
            starttime = moment(this.selectedDates.starttime);
            endtime = moment(this.selectedDates.endtime);
            if (endtime.isSameOrBefore(starttime)) {
              endtime = moment(starttime).add(2, timelineStep);
            }
          } else if (this.selectedDates.starttime) {
            starttime = moment(this.selectedDates.starttime);
            endtime = moment(moment.max(starttime, endtime));
            endtime = moment.min(endtime, moment(starttime).add(30, timelineStep)); // prevent very long planning
            endtime.add(1, timelineStep);
          } else if (this.selectedDates.endtime) {
            endtime = moment(this.selectedDates.endtime);
            if (timelineStep == 'days') {
              starttime = moment(moment.min(moment(endtime).add(-7, timelineStep), moment.max(starttime, moment())));
            } else {
              starttime = moment(moment.min(moment(endtime).add(-2, timelineStep), moment.max(starttime, moment())));
            }
            starttime = moment.max(starttime, moment(endtime).add(-30, timelineStep)); // prevent very long planning
            starttime.add(-1, timelineStep);
          } else {
            if (moment().isBefore(endtime)) {
              if (timelineStep == 'days') {
                starttime = moment.max(starttime, moment().add(-7, timelineStep));
              } else {
                starttime = moment.max(starttime, moment().add(-1, timelineStep));
              }
              endtime = moment.min(endtime, moment(starttime).add(30, timelineStep)); // prevent very long planning
            } else {
              starttime = moment(endtime).add(-6, timelineStep);
            }
            starttime.add(-1, timelineStep);
            endtime.add(1, timelineStep);
          }
          starttime.startOf(timelineStep);
          endtime.endOf(timelineStep);

          _.extend(defaultProjectData.timeline, {
            ...(this.projects[0].timeline && { timelocale: this.projects[0].timeline.timelocale }),
            colwidth: defaultProjectData.timeline.bestColWidth(starttime, endtime),
            workdays: true,
          }, this.planningUsersOptions.timeline);
          const timeline = _.extend(this.planning && this.planning.timeline || defaultProjectData.timeline, {
            hidden: {
              before: starttime.format(),
              after: endtime.format(),
            },
          });

          /* meta, config */
          _.extend(defaultProjectData.meta, this.projects[0].meta, {
            title: this.$t("PLANNINGUSERS.USERS_PLANNING"),
            category: '',
            isOwnerPremium: true,
          });
          _.extend(defaultProjectData.config, this.planningUsersOptions.config);

          this.planning = new Planning({
            config: this.planning && this.planning.config || defaultProjectData.config,
            meta: defaultProjectData.meta,
            timeline,
            lanes,
            elements,
          });

          // Locale
          moment.locale(this.planning.timeline.timelocale);
          setTimeout(() => {
            this.viewIsReady = true;
            this.$store.dispatch('planning/set', { planning: this.planning });
            setTimeout(() => window.scrollTo(0, scrollY));
          });
        });
      },
      updateDisplay() {
        // update existing planning without recomputing
        this.planning.elements.forEach((el) => {
          this.$store.dispatch('multiprojects/formatEl', { el });
        });
        this.$nextTick(() => {
          this.planning.elements.forEach((el) => { el.updateHeight(); });
        });
      },
      pdfExport() {
        _.extend(this.exporting, { inprogress: true, success: false, error: false });
        const $html = document.querySelector("html").cloneNode(true);
        let queryEl = $html.querySelector('#viewer .v-card') || document.createElement("div");
        $html.querySelector("body").innerHTML = `<div style="padding: 0 20px">${queryEl.innerHTML}</div>`;
        $html.querySelector('body').classList.add('v-application');
        $html.querySelectorAll(".export-hidden").forEach((el) => { el.parentNode.removeChild(el); });
        $html.querySelectorAll(".lane-header, .lane").forEach(el => el.style.setProperty('break-inside', 'avoid'));
        queryEl = document.querySelector("#planning-table");
        const planningWidth = queryEl ? parseFloat(getComputedStyle(queryEl, null).width.replace("px", "")) + 180 : 0;
        if (planningWidth) $html.querySelector("body").style.setProperty('width', `${planningWidth}px`);
        $html.querySelectorAll(".warningorange").forEach(el => el.style.setProperty('background', '#ff9b1d'));
        $html.querySelectorAll(".successgreen").forEach(el => el.style.setProperty('background', '#00b100'));

        window.apiSrv.call('pdf', 'store', { html: $html.innerHTML, orientation: "landscape", fullwidth: true, footer: this.$t('PLANNINGUSERS.USERS_PLANNING') }).then((response) => {
          if (response && response.data && response.data.pdfurl) {
            this.exporting.inprogress = false;
            this.exporting.success = true;
            setTimeout(() => { this.exporting.success = false; }, 3000);
            window.open(`${response.data.pdfurl}/${this.$t('PLANNINGUSERS.USERS_PLANNING')}.pdf`, "_blank");
          }
        }).catch((message) => {
          this.exporting.inprogress = false;
          this.exporting.error = message || "Error : not exported";
        });
      },
    },
  };
</script>
