<template>
  <div>
    <planning-navigation v-if="(dynamic && ! view) || userConnected" :config="view ? 'planningview' : dynamic ? 'planning' : ''"
                         :planning="planning" :planning-view-id="planningViewId">
    </planning-navigation>

    <div :id="(dynamic && ! view) ? 'planning' : 'viewer'">
      <planning-content :planning="planning" :dynamic="dynamic" :view="view" :options="{ elementDetails: { canSetPriority: true, canChangeLane: true } }"></planning-content>
    </div>
  </div>
</template>

<script>
  import ProjectSrv from '@/components/Projects/ProjectSrv';
  import Alert from '@/models/Alert';
  import Planning from '@/models/Planning';
  import EventBus from '@/js/event-bus.js';
  import PlanningNavigation from './PlanningNavigation';
  import PlanningContent from './PlanningContent';

  export default {
    components: {
      PlanningNavigation,
      PlanningContent,
    },
    props: {
      dynamic: { type: Boolean, default: false },
      view: { type: Boolean, default: false },
    },
    data() {
      return {
        planning: {},
        planningId: this.view ? null : +this.$route.params.id,
        planningViewId: this.view ? +this.$route.params.id : null,
      };
    },
    computed: {
      userConnected() {
        return this.$store.state.users.user.id > 0;
      },
    },
    created() {
      this.loadProject();
      EventBus.$on('update-project', this.updateProject);
    },
    beforeDestroy() {
      window.notificationsSrv.stopCheckingPlanningUpdates();
      EventBus.$off('update-project', this.updateProject);
    },
    methods: {
      loadProject() {
        const { rotoken } = this.$route.query;
        let request;
        if (this.view) {
          request = ProjectSrv.getView({ viewId: this.planningViewId });
        } else {
          request = ProjectSrv.get(this.planningId, rotoken);
        }
        request.then((content) => {
          this.planning = new Planning(content);
          if (! this.view && this.dynamic) {
            if (this.planning.meta && this.planning.meta.access_right == 'consult') {
              this.planning = null;
              this.$router.replace({ path: `/viewer/${this.planningId}`, query: this.$route.query });
              return;
            }
          }
          // broadcast event
          this.onProjectLoaded();
          this.onProjectFirstLoad();
        }).catch((message) => {
          this.$store.state.ui.planning.errors = `${message || "Something went wrong."}`;
          window.status = "screenshotready";
        });
      },
      updateProject() {
        const { rotoken } = this.$route.query;
        let request;
        if (this.view) {
          request = ProjectSrv.getView({ viewId: this.planningViewId });
        } else {
          request = ProjectSrv.get(this.planningId, rotoken);
        }
        if (this.dynamic) this.$store.dispatch('ui/planning/startSaving');
        request.then((content) => {
          const modifications = this.planning.update(content);
          this.planning.elements.filter(el => modifications.elements[el.id]).forEach((el) => { // add last modifier indication
            el.modificationUser = this.planning.meta.updated_by;
          });
          this.onProjectLoaded();
          EventBus.$emit('project-updated', modifications);
        }).catch(() => {}).finally(() => {
          if (this.dynamic) this.$store.dispatch('ui/planning/resetSaving');
        });
      },
      setScreenshotParams() {
        const urlparams = this.$route.query;
        if (! urlparams.snapshot) return;

        this.$store.state.ui.planning.snapshot = true;
        // timeline
        const timeline = angular.copy(this.$store.state.planning.timeline);
        timeline.show_presenttime = false;
        if (urlparams.steps) timeline.steps = urlparams.steps.split(',');
        if (urlparams.hiddenbefore && moment(urlparams.hiddenbefore).isValid()) timeline.setHiddenBefore(urlparams.hiddenbefore);
        if (urlparams.hiddenafter && moment(urlparams.hiddenafter).isValid()) timeline.setHiddenAfter(urlparams.hiddenafter);
        if (urlparams.colwidth) timeline.colwidth = urlparams.colwidth;
        this.$store.dispatch('planning/timeline/set', timeline);
        // lanes
        if (urlparams.laneshidden) {
          urlparams.laneshidden.split(',').forEach((laneId) => {
            const index = (this.$store.state.planning.lanes || []).findIndex(item => item.id === +laneId);
            if (index > -1) {
              this.$store.state.planning.lanes.splice(index, 1);
            }
          });
        }
      },
      onProjectFirstLoad() {
        // Navigation event
        this.$store.dispatch('ui/navigation/registerProjectNavigation', { path: this.$route.path, rotoken: this.$route.query.rotoken, title: this.planning.getTitle() });

        // Locale
        moment.locale(this.planning.timeline.timelocale);

        // Scroll to current time
        setTimeout(() => {
          if (this.planning.visibleTimeline.presenttimeWidth > 0 && this.planning.visibleTimeline.presenttimeWidth < this.planning.visibleTimeline.pxwidth) {
            $("#table-wrapper").scrollLeft(this.planning.visibleTimeline.presenttimeWidth - 0.25 * $("#table-wrapper").width());
          }
        }, 0);

        // Check update notificatons
        window.notificationsSrv.checkPlanningUpdates({ planningId: this.planning.id }, _.throttle(() => {
          this.updateProject();
        }, 2000));

        // Alerts
        setTimeout(() => {
          Alert.load(this.$store.state.planning);
        });

        // Screenshot mode
        setTimeout(() => {
          Promise.all(this.$store.state.subprojects.promises).then(() => {
            this.setScreenshotParams();
            setTimeout(() => { window.status = "screenshotready"; }, 300);
          });
        });

        /*  GUIDED TOUR */
        if (this.dynamic && this.$route.query.tour) {
          this.$nextTick(() => {
            import('@/components/Tour/TourSrv').then((TourSrv) => {
              TourSrv.launch(this.$router, this.$route.query.tour);
            });
          });
        }
      },
      onProjectLoaded() {
        this.$store.dispatch('undoredo/reset');
        setTimeout(() => {
          this.$store.dispatch('planning/set', { planning: this.planning });
          this.planning = this.$store.state.planning;
        });
      },
    },
  };
</script>
