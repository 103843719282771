<template>
  <div>
    <div class="display-flex align-center mt-6">
      <!-- ICON AND LABEL -->
      <div class="mr-2 text-center" style="flex: 0 0 24px;">
        <v-icon color="primary" small>fas fa-calendar-alt</v-icon>
      </div>
      <label class="uppercase primary--text ma-0">{{ $t(el.isType('milestone') ? 'ELEMENT-DETAIL.DATE' : 'ELEMENT-DETAIL.DATES') }}</label>
      <v-spacer></v-spacer>
      <v-divider vertical class="mx-2"></v-divider>
      <!-- OPTIONS -->
      <template v-if="! isConsult">
        <div class="pointer" @click="advancedOptions = ! advancedOptions">
          <i :class="advancedOptions ? 'fa-toggle-on successgreen--text text--lighten-1' : 'fa-toggle-off black--text'" class="fas mr-1"></i>{{ $t('ELEMENT-DETAIL.ADVANCED_OPTIONS') }}
        </div>
        <v-divider vertical class="mx-2"></v-divider>
      </template>
      <!-- SHOW DATES -->
      <v-btn v-if="el.isType('milestone')" :title="$t(el.getConfig()['show-date'] ? 'ELEMENT-DETAIL.HIDE_ON_PLANNING' : 'ELEMENT-DETAIL.SHOW_ON_PLANNING')" icon small tabindex="-1"
             @click="el.getConfig()['show-date'] = ! el.getConfig()['show-date']">
        <v-icon small>{{ el.getConfig()['show-date'] ? 'fas fa-eye' : 'fas fa-eye-slash' }}</v-icon>
      </v-btn>
      <v-btn v-else :title="$t(el.getConfig()['show-dates'] ? 'ELEMENT-DETAIL.HIDE_ON_PLANNING' : 'ELEMENT-DETAIL.SHOW_ON_PLANNING')" icon small tabindex="-1"
             @click="el.getConfig()['show-dates'] = ! el.getConfig()['show-dates']">
        <v-icon small>{{ el.getConfig()['show-dates'] ? 'fas fa-eye' : 'fas fa-eye-slash' }}</v-icon>
      </v-btn>
    </div>
    <!-- MILESTONE -->
    <div v-if="el.isType('milestone')" class="ml-6 pl-2 display-flex">
      <div style="flex: 1 1 50%">
        <date-field v-model="milestoneDate" :label="$t('ELEMENT-DETAIL.DATE')" :allowed-dates="allowedDates" no-null hide-details></date-field>
      </div>
      <div class="ml-8 display-flex align-center" style="flex: 1 1 50%">
        <time-field v-model="scheduleStart" :label="$t('ELEMENT-DETAIL.SCHEDULE')" :placeholder="$t('ELEMENT-DETAIL.SCHEDULE_START')" hide-details clearable></time-field>
        <div class="mx-2 mt-4">-</div>
        <time-field v-model="scheduleEnd" :placeholder="$t('ELEMENT-DETAIL.SCHEDULE_END')" hide-details clearable></time-field>
      </div>
    </div>
    <!-- TASK -->
    <div v-else-if="! isConsult" class="ml-6 pl-2">
      <div class="display-flex">
        <date-field v-model="taskStartTime" :label="$t('ELEMENT-DETAIL.FROM')" :allowed-dates="allowedDates" no-null hide-details></date-field>
        <div v-show="advancedOptions" style="max-width: 70px">
          <time-field v-model="scheduleStart" :label="$t('ELEMENT-DETAIL.SCHEDULE_AT')" :placeholder="$t('ELEMENT-DETAIL.SCHEDULE_START')" hide-details clearable></time-field>
        </div>
        <date-field v-model="taskEndTime" :label="$t('ELEMENT-DETAIL.TO')" :allowed-dates="allowedDates" no-null hide-details input-class="ml-4"></date-field>
        <div v-show="advancedOptions" style="max-width: 70px">
          <time-field v-model="scheduleEnd" :label="$t('ELEMENT-DETAIL.SCHEDULE_AT')" :placeholder="$t('ELEMENT-DETAIL.SCHEDULE_END')" hide-details clearable></time-field>
        </div>
      </div>
      <v-text-field v-model.number="taskDuration" :label="$t('ELEMENT-DETAIL.DURATION')"
                    :suffix="$tc(workdays ? 'GLOBAL.WORKDAYS' : 'GLOBAL.DAYS', taskDuration)" :style="{ maxWidth: workdays ? '150px': '100px' }"
                    hide-details type="number" min="1" class="mt-2" style="max-width: 120px">
      </v-text-field>
    </div>
    <!-- MACRO -->
    <div v-else class="ml-6 pl-2">
      <div class="display-flex">
        <div style="max-width: 120px">
          <v-text-field :value="taskStartTime.format('DD/MM/YYYY')" :label="$t('ELEMENT-DETAIL.FROM')" readonly no-null hide-details></v-text-field>
        </div>
        <div class="ml-4" style="max-width: 120px">
          <v-text-field :value="taskEndTime.format('DD/MM/YYYY')" :label="$t('ELEMENT-DETAIL.TO')" readonly no-null hide-details></v-text-field>
        </div>
        <div class="ml-4" :style="{ maxWidth: workdays ? '150px': '120px' }">
          <v-text-field :value="taskDuration" :label="$t('ELEMENT-DETAIL.DURATION')"
                        :suffix="$tc(workdays ? 'GLOBAL.WORKDAYS' : 'GLOBAL.DAYS', taskDuration)" readonly no-null hide-details>
          </v-text-field>
        </div>
      </div>
    </div>

    <transition v-if="! isConsult" name="collapse-top">
      <div v-show="advancedOptions" class="ml-6 pl-2">
        <v-divider class="my-4"></v-divider>
        <div class="display-flex">
          <!-- DATES LOCK -->
          <div style="flex: 1 1 50%">
            <b><v-icon small class="mr-2">fas fa-lock</v-icon>{{ $tc('ELEMENT-DETAIL.DATES_LOCK', el.isType('milestone') ? 1 : 2) }}</b>
            <v-switch v-model="isLocked" hide-details class="ma-0">
              <template #label>
                <span class="small secondary--text" style="line-height: 1.2">{{ $t(isLocked ? 'ELEMENT-DETAIL.DATES_LOCK_DESC' : 'ELEMENT-DETAIL.UNLOCKED') }}</span>
              </template>
            </v-switch>
          </div>
          <!-- TIME BAR COLORS -->
          <div style="flex: 1 1 50%">
            <b><v-icon small class="mr-2">fas fa-grip-lines-vertical</v-icon>{{ $t('ELEMENT-DETAIL.VERTICAL_BAR') }}</b>
            <div class="display-flex align-center flex-wrap mx-n1">
              <div class="timebar-color-block ma-1" style="border: 1px solid #7f8c8d;" @click="timeBarColor = null">
                <v-icon small class="ma-auto mt-1">fas fa-empty-set</v-icon>
              </div>
              <div v-for="color in planningColors" :class="{ 'elevation-2': timeBarColor == color.main }" :style="{ 'background': color.main }"
                   class="timebar-color-block ma-1"
                   @click="timeBarColor = color.main">
                <v-icon v-if="timeBarColor == color.main" color="white" small class="ma-auto mt-1">fas fa-check</v-icon>
              </div>
            </div>
          </div>
        </div>
        <element-integrations-manager v-if="displayIntegrations" :el="el" class="mt-4" @close="close"></element-integrations-manager>
      </div>
    </transition>
  </div>
</template>

<style>
  .timebar-color-block {
    width: 30px;
    height: 25px;
    cursor: pointer;
    display: flex;
  }
</style>

<script>
  import ElementIntegrationsManager from '@/components/Integrations/ElementIntegrationsManager';

  export default {
    components: {
      ElementIntegrationsManager,
    },
    props: {
      el: { type: Object, required: true },
    },
    data() {
      return {
        workdays: this.el.getPlanning().timeline.workdays,
        advancedOptions: false,
        planningColors: this.el.getPlanning().config.colors,
      };
    },
    computed: {
      isConsult() { return this.el.isType('macro'); },
      milestoneDate: {
        get() {
          return this.el.isType('milestone') && this.el.getStartTime() || null;
        },
        set(newVal) {
          this.el.setStartTime(newVal);
        },
      },
      scheduleStart: {
        get() {
          return this.el.getSchedule().start || null;
        },
        set(newVal) {
          this.el.setSchedule({
            start: newVal,
            end: this.scheduleEnd,
          });
          if (newVal && this.el.isType('macro', 'task')) {
            this.taskStartTime = moment(this.taskStartTime).hours(newVal.hours()).minutes(newVal.minutes()).seconds(0);
          }
        },
      },
      scheduleEnd: {
        get() {
          return this.el.getSchedule().end || null;
        },
        set(newVal) {
          this.el.setSchedule({
            start: this.scheduleStart,
            end: newVal,
          });
          if (newVal && this.el.isType('macro', 'task')) {
            this.taskEndTime = moment(this.taskEndTime).hours(newVal.hours()).minutes(newVal.minutes()).seconds(0);
          }
        },
      },
      taskStartTime: {
        get() {
          return (this.el.isType('macro', 'task')) && this.el.getStartTime() || null;
        },
        set(newVal) {
          const newdate = moment(newVal).startOf('day');
          if (this.scheduleStart) newdate.hours(this.scheduleStart.hours()).minutes(this.scheduleStart.minutes()).seconds(0);
          this.el.setStartTime(newdate);
          if (this.el.getEndTime().isBefore(newdate)) {
            this.scheduleEnd = null;
            this.taskEndTime = newdate;
          }
        },
      },
      taskEndTime: {
        get() {
          return this.el.isType('macro', 'task') && this.el.getEndTime() || null;
        },
        set(newVal) {
          const newdate = moment(newVal).endOf('day');
          if (this.scheduleEnd) newdate.hours(this.scheduleEnd.hours()).minutes(this.scheduleEnd.minutes()).seconds(0);
          this.el.setEndTime(newdate);
          if (newdate.isBefore(this.el.getStartTime())) {
            this.scheduleStart = null;
            this.taskStartTime = newdate;
          }
        },
      },
      taskDuration: {
        get() {
          return this.el.isType('macro', 'task') && this.duration(this.el.getStartTime(), this.el.getEndTime()) || null;
        },
        set(newVal) {
          const newDuration = parseInt(newVal, 10);
          if (newDuration < 1) return;
          const toadd = newDuration - this.duration(this.el.getStartTime(), this.el.getEndTime());
          if (toadd) this.taskEndTime = this.el.getEndTime().addWithWorkdays(toadd, 'days', this.workdays);
        },
      },
      isLocked: {
        get() { return this.el.getIsLocked(); },
        set(newVal) {
          this.el.setIsLocked(newVal);
        },
      },
      timeBarColor: {
        get() { return this.el.getTimeBar().color; },
        set(newVal) {
          this.el.setTimeBar(newVal ? Object.assign(this.el.getTimeBar(), { color: newVal }) : null);
        },
      },
      integrationActive() {
        const outlookStatus = this.$store.state.integrations.microsoft.outlook.connected && this.$store.getters['integrations/microsoft/getElementStatus'](this.el);
        return outlookStatus || this.$store.state.integrations.google.gcal.connected && this.$store.getters['integrations/google/getElementStatus'](this.el);
      },
      displayIntegrations() {
        const { availableIntegrations } = this.$store.getters['users/accessRight/config'];
        return ! (availableIntegrations && ! availableIntegrations.length);
      },
    },
    watch: {
      integrationActive: {
        handler(newVal) {
          if (newVal) this.advancedOptions = true;
        },
        immediate: true,
      },
    },
    created() {
      this.advancedOptions = this.el.getIsLocked() || this.timeBarColor || (this.el.isType('macro', 'task') && (this.scheduleStart || this.scheduleEnd));
    },
    methods: {
      close() {
        this.$emit('close');
      },
      duration(m1, m2) {
        return m2.diffWithWorkdays(m1, 'days', this.workdays) + 1;
      },
      allowedDates(val) {
        if (! this.workdays) return true;
        const date = moment(val);
        if (date.day() === 0 || date.day() === 6) return false;
        return true;
      },
    },
  };
</script>
